
import React from 'react'
import { Svg } from './Svg'

export interface CartProps {
  color?: string
  hoverColor?: string
  className?: string
}

export function Cart({ color, hoverColor, className }: CartProps): JSX.Element {

  return (
    <Svg strokeColor={color} strokeHoverColor={hoverColor} fillColor={color} fillHoverColor={hoverColor} className={className} width="100%" height="100%" viewBox="0 0 20 19" version="1.1">
      <g transform="matrix(0.899682,0,0,0.899682,-0.796182,-1.69619)">
        <path className='fill' d="M21.752,6.032C21.627,5.903 21.441,5.774 21.255,5.774L7.528,5.774C7.155,5.774 6.845,6.097 6.845,6.484C6.845,6.871 7.155,7.194 7.528,7.194L20.447,7.194L19.516,13.258C19.453,13.839 19.019,14.226 18.522,14.226L7.963,14.226L6.286,6.419L6.099,5.129C5.913,3.903 4.919,3 3.801,3L2.683,3C2.311,3 2,3.323 2,3.71C2,4.097 2.311,4.419 2.683,4.419L3.801,4.419C4.298,4.419 4.733,4.806 4.795,5.387L4.981,6.677L6.783,15.065C6.783,15.194 6.845,15.258 6.907,15.323C6.907,15.387 7.031,15.452 7.031,15.452C7.155,15.581 7.342,15.645 7.466,15.645L18.584,15.645C19.764,15.645 20.758,14.742 20.944,13.516L22,6.677C21.938,6.419 21.876,6.226 21.752,6.032Z" />
      </g>
      <g transform="matrix(0.899682,0,0,0.899682,-0.796182,-1.69619)">
        <path className='fill' d="M9.329,16.742C8.211,16.742 7.279,17.71 7.279,18.871C7.279,20.032 8.211,21 9.329,21C10.447,21 11.379,20.032 11.379,18.871C11.379,17.71 10.447,16.742 9.329,16.742ZM10.012,18.871C10.012,19.258 9.702,19.581 9.329,19.581C8.956,19.581 8.646,19.258 8.646,18.871C8.646,18.484 8.956,18.161 9.329,18.161C9.702,18.161 10.012,18.484 10.012,18.871Z" />
      </g>
      <g transform="matrix(0.899682,0,0,0.899682,-0.796182,-1.69619)">
        <path className='fill' d="M17.59,16.742C16.472,16.742 15.541,17.71 15.541,18.871C15.541,20.032 16.472,21 17.59,21C18.708,21 19.64,20.032 19.64,18.871C19.64,17.71 18.708,16.742 17.59,16.742ZM18.273,18.871C18.273,19.258 17.963,19.581 17.59,19.581C17.218,19.581 16.907,19.258 16.907,18.871C16.907,18.484 17.218,18.161 17.59,18.161C17.963,18.161 18.273,18.484 18.273,18.871Z" />
      </g>
    </Svg>
  )

}
    