import React from 'react'

import styled from 'styled-components'

import { Heading, Paragraph } from '@atoms/typography'

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export interface SectionErrorBoundaryProps {
  children: React.ReactNode
}

interface SectionErrorBoundaryState {
  hasError: boolean
  message: string | null
}

export class SectionErrorBoundary extends React.Component<SectionErrorBoundaryProps, SectionErrorBoundaryState> {

  constructor(props: SectionErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false, message: null }
  }

  static getDerivedStateFromError(e: Error): SectionErrorBoundaryState {
    return { hasError: true, message: e.message }
  }

  render(): React.ReactNode {
    if (this.state.hasError) {
      return (
        <Container>
          <Heading>Error</Heading>
          <Paragraph>
            <p>
              An error has occured rendering the section the error message is:
            </p>
            <p>
              {this.state.message}
            </p>
          </Paragraph>
        </Container>
      )
    }

    return this.props.children
  }

}
