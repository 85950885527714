import { CartFragment, CartItemFragment, ProductListFragment, CategoryFragment, ProductDetailsFragment, BaseCategoryFragment, BaseCategoryChildFragment, BreadcrumbFragment } from '@hooks/api'
import { ProductFilterInputs } from '@organisms/shop'
import { EntityTypeEnum } from '@uctypes/api/globalTypes'

export interface PageView {
  url: string
  title: string
  type: EntityTypeEnum
}

export interface CatalogueVars {
  productsPerPage: number
  totalProducts: number
  currentPage: number
  sortOrder: string
  sortDirection: 'asc' | 'desc'
  displayMode: 'grid' | 'list'
}

export abstract class EventPlugin {

  abstract hasViewedPage?(page: PageView): void
  abstract hasAddedPaymentMethod?(cart: CartFragment, method: string): void
  abstract hasAddedShippingMethod?(cart: CartFragment, method: string): void
  abstract hasAddedProductToCart?(cartItem: CartItemFragment, previoudCartItem?: CartItemFragment): void
  abstract hasRemovedProductFromCart?(cartItem: CartItemFragment, previoudCartItem?: CartItemFragment): void
  abstract hasBegunCheckout?(cart: CartFragment): void
  abstract hasEarnedFaithfulPoints?(amount: number): void
  abstract hasLoggedIn?(method: string): void
  abstract hasMadePurchase?(cart: CartFragment, orderId: string): void
  abstract hasPerformedSearch?(term: string, isSpellChecked: boolean, vars: CatalogueVars): void
  abstract hasFilteredSearch?(term: string, isSpellChecked: boolean, vars: CatalogueVars, filters: ProductFilterInputs): void
  abstract hasSelectedItem?(product: ProductListFragment, category?: CategoryFragment): void
  abstract hasSelectedPromotion?(): void
  abstract hasShared?(method: string, contentType: string, itemId: string): void
  abstract hasSignedUp?(method: string): void
  abstract hasSpentVirtualCurrency?(ammount: number, orderId: string): void
  abstract hasViewedCart?(cart: CartFragment): void
  abstract hasViewedProduct?(product: ProductDetailsFragment, breadcrumbs?: BreadcrumbFragment[]): void
  abstract hasViewedCatalogue?(category: CategoryFragment | BaseCategoryFragment | BaseCategoryChildFragment, products: ProductListFragment[], vars: CatalogueVars): void
  abstract hasFilteredCatalogue?(category: CategoryFragment | BaseCategoryFragment | BaseCategoryChildFragment, products: ProductListFragment[], vars: CatalogueVars, filters: ProductFilterInputs): void
  abstract hasViewedPromotion?(): void

}

export class UserEvents {

  static instance: UserEvents

  static shared(): UserEvents {
    if (!this.instance) {
      this.instance = new UserEvents()
    }
    return this.instance
  }

  plugins: EventPlugin[] = []

  registerPlugin(plugin: EventPlugin) {
    this.plugins.push(plugin)
  }

  hasViewedPage(page: PageView): void {
    this.plugins.forEach((plug) => plug?.hasViewedPage?.(page))
  }

  hasAddedPaymentMethod(cart: CartFragment, method: string): void {
    this.plugins.forEach((plug) => plug?.hasAddedPaymentMethod?.(cart, method))
  }

  hasAddedShippingMethod(cart: CartFragment, method: string): void {
    this.plugins.forEach((plug) => plug?.hasAddedShippingMethod?.(cart, method))
  }

  hasAddedProductToCart(cartItem: CartItemFragment, previoudCartItem?: CartItemFragment): void {
    this.plugins.forEach((plug) => plug?.hasAddedProductToCart?.(cartItem, previoudCartItem))
  }

  hasRemovedProductFromCart(cartItem: CartItemFragment, previoudCartItem?: CartItemFragment): void {
    this.plugins.forEach((plug) => plug?.hasRemovedProductFromCart?.(cartItem, previoudCartItem))
  }

  hasBegunCheckout(cart: CartFragment): void {
    this.plugins.forEach((plug) => plug?.hasBegunCheckout?.(cart))
  }

  hasEarnedFaithfulPoints(amount: number): void {
    this.plugins.forEach((plug) => plug?.hasEarnedFaithfulPoints?.(amount))
  }

  hasLoggedIn(method: string): void {
    this.plugins.forEach((plug) => plug?.hasLoggedIn?.(method))
  }

  hasMadePurchase(cart: CartFragment, orderId: string): void {
    this.plugins.forEach((plug) => plug?.hasMadePurchase?.(cart, orderId))
  }

  hasPerformedSearch(term: string, isSpellChecked: boolean, vars: CatalogueVars): void {
    this.plugins.forEach((plug) => plug?.hasPerformedSearch?.(term, isSpellChecked, vars))
  }

  hasFilteredSearch(term: string, isSpellChecked: boolean, vars: CatalogueVars, filters: ProductFilterInputs): void {
    this.plugins.forEach((plug) => plug?.hasFilteredSearch?.(term, isSpellChecked, vars, filters))
  }

  hasSelectedItem(product: ProductListFragment, category?: CategoryFragment): void {
    this.plugins.forEach((plug) => plug?.hasSelectedItem?.(product, category))
  }

  hasSelectedPromotion(): void {
    this.plugins.forEach((plug) => plug?.hasSelectedPromotion?.())
  }

  hasShared(method: string, contentType: string, itemId: string): void {
    this.plugins.forEach((plug) => plug?.hasShared?.(method, contentType, itemId))
  }

  hasSignedUp(method: string): void {
    this.plugins.forEach((plug) => plug?.hasSignedUp?.(method))
  }

  hasSpentVirtualCurrency(ammount: number, orderId: string): void {
    this.plugins.forEach((plug) => plug?.hasSpentVirtualCurrency?.(ammount, orderId))
  }

  hasViewedCart(cart: CartFragment): void {
    this.plugins.forEach((plug) => plug?.hasViewedCart?.(cart))
  }

  hasViewedProduct(product: ProductDetailsFragment, breadcrumbs?: BreadcrumbFragment[]): void {
    this.plugins.forEach((plug) => plug?.hasViewedProduct?.(product, breadcrumbs))
  }

  hasViewedCatalogue(category: CategoryFragment | BaseCategoryFragment | BaseCategoryChildFragment, products: ProductListFragment[], vars: CatalogueVars): void {
    this.plugins.forEach((plug) => plug?.hasViewedCatalogue?.(category, products, vars))
  }

  hasFilteredCatalogue(category: CategoryFragment | BaseCategoryFragment | BaseCategoryChildFragment, products: ProductListFragment[], vars: CatalogueVars, filters: ProductFilterInputs): void {
    this.plugins.forEach((plug) => plug?.hasFilteredCatalogue?.(category, products, vars, filters))
  }

  hasViewedPromotion(): void {
    this.plugins.forEach((plug) => plug?.hasViewedPromotion?.())
  }

}
