import React from 'react'

import styled, { useTheme } from 'styled-components'

import { Paragraph, Tag, Title } from '@atoms/typography'
import { ResponsivePXValue } from '@components/Theme'
import { ShippingActionFragment, ShippingMethodFragment, TempShippingActionFragment } from '@hooks/api/index'
import { SiteHelper } from '@lib/SiteHelper'

const Container = styled.div<{ active: boolean }>`
  display: flex;
  align-items: center;
  background-color: ${(props): string => props.active ? props.theme.colors.white.pampas : props.theme.colors.white.pureWhite};
  ${ResponsivePXValue('padding', { mobile: '24px', tablet: '24px', desktop: '24px' })}
  ${ResponsivePXValue('border-radius', { mobile: '0', tablet: '10px', desktop: '10px' })}
`

const CircleContainer = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  ${ResponsivePXValue('width', { mobile: '30px', tablet: '60px', desktop: '60px' })}
`

const Circle = styled.div<{ active: boolean }>`
  border-style: solid;
  cursor: pointer;
  ${ResponsivePXValue('width', { mobile: '16px', tablet: '20px', desktop: '20px' })}
  ${ResponsivePXValue('height', { mobile: '16px', tablet: '20px', desktop: '20px' })}
  ${ResponsivePXValue('border-radius', { mobile: '10px', tablet: '13px', desktop: '13px' })}
  ${(props): string => {
    if (props.active) {
      return `
        ${ResponsivePXValue('border-width', { mobile: '4px', tablet: '5px', desktop: '5px' })}
        border-color: ${props.theme.colors.green.greenVogue};
      `
    }
    return `
    ${ResponsivePXValue('border-width', { mobile: '1px', tablet: '2px', desktop: '2px' })}
    border-color: ${props.theme.colors.grey.silver};
  `
  }}
`

const TextContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`

export interface ShippingActionRowProps {
  action: TempShippingActionFragment
  selected: boolean
  onSelect: () => void
}

export function ShippingActionRow({ selected, action, onSelect }: ShippingActionRowProps): JSX.Element {

  const theme = useTheme()

  return (
    <Container active={selected}>
      <If condition={!!onSelect}>
        <CircleContainer onClick={onSelect} >
          <Circle active={selected} />
        </CircleContainer>
      </If>
      <TextContainer onClick={onSelect}>
        <Title variant='t8'>
          {action.title}
        </Title>
        <Paragraph color={theme.colors.grey.gunSmoke}>
          {action.description}
        </Paragraph>
      </TextContainer>
    </Container>
  )

}
