import React from 'react'

import styled, { useTheme } from 'styled-components'

import { Button, ButtonColors, Paragraph, ResponsiveImage } from '@atoms/index'
import { ResponsivePXValue } from '@components/Theme'
import { SpecialsChildCategoryFragment } from '@hooks/api'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  ${ResponsivePXValue('width', { mobile: '154px', tablet: '296px', desktop: '296px' })}
  ${ResponsivePXValue('height', { mobile: '186px', tablet: '262px', desktop: '262px' })}
  background-color: ${(props): string => props.theme.colors.white.pureWhite};
`

const ImageContainer = styled.div`
  width: 100%;
  flex-shrink: 0;
  ${ResponsivePXValue('height', { mobile: '96px', tablet: '146px', desktop: '146px' })}
`

const ContentContainer = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  ${ResponsivePXValue('padding', '16px')}
  
`

export interface SpecialCardProps {
  category: SpecialsChildCategoryFragment
}

export function SpecialCard({ category }: SpecialCardProps): JSX.Element {

  const theme = useTheme()

  const buttonColors: ButtonColors = {
    color: theme.colors.green.bottleGreen,
    backgroundColor: theme.colors.white.pureWhite,
    borderColor: theme.colors.green.bottleGreen,
    hoverColor: theme.colors.green.bottleGreen,
    hoverBackgroundColor: theme.colors.yellow.cornSilk,
    hoverBorderColor: theme.colors.green.bottleGreen,
  }

  return (
    <Container>
      <ImageContainer>
        <ResponsiveImage image={category.coverImage} objectFit='contain' />
      </ImageContainer>
      <ContentContainer>
        <Paragraph bold align='center'>{category.name}</Paragraph>
        <Button title='SHOP DEAL' href={category.canonicalUrl} colors={buttonColors} />
      </ContentContainer>
    </Container>
  )

}
