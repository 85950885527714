import React from 'react'

import styled from 'styled-components'

import { Heading, Paragraph } from '@atoms/typography'
import { ResponsivePXValue } from '@components/Theme'
import { PageSplitTextSectionFragment } from '@hooks/api/index'
import { HTML } from '@molecules/content'
import { DeviceContainer } from '@utility/DeviceContainer'

const Container = styled.div<{ backgroundColor: string}>`
  background-color: ${({ backgroundColor }): string => backgroundColor};
  width: 100%;
  ${ResponsivePXValue('height', { desktop: 'fit-content' })}
  ${ResponsivePXValue('padding', { mobile: '24px 16px', tablet: '32px 0px', desktop: '32px 0px' })}
  display: flex;
  align-items: center;
  gap: 16px;
  ${ResponsivePXValue('flex-direction', { mobile: 'column', tablet: 'column', desktop: 'row' })}
  `

const TitlesContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${ResponsivePXValue('align-items', { mobile: 'center', tablet: 'center', desktop: 'flex-start' })}
  ${ResponsivePXValue('justidy-content', { mobile: '', tablet: '', desktop: 'center' })}
  ${ResponsivePXValue('padding', { mobile: '0px', tablet: '0px 24px', desktop: '0px 24px' })}
  gap: 16px;
  `

const ContentContainer = styled.div`
  ${ResponsivePXValue('padding', { mobile: '0px', tablet: '0px 48px 0px 24px', desktop: '0px 48px 0px 24px' })}
`

export interface PageSplitTextSectionProps {
  section: PageSplitTextSectionFragment
}

export function PageSplitTextSection({ section }: PageSplitTextSectionProps): JSX.Element {

  const { backgroundColor, description, foregroundColor, subTitle, title } = section

  return (
    <Container backgroundColor={backgroundColor}>
      <DeviceContainer desktop tablet>
        <TitlesContainer>
          <Heading
            variant='h1'
            color={foregroundColor}
            align='center'>
            {title}
          </Heading>
          <Heading
            variant='h5'
            align='center'
            color={foregroundColor}>
            {subTitle}
          </Heading>
        </TitlesContainer>
      </DeviceContainer>
      <DeviceContainer mobile>
        <TitlesContainer>
          <Heading
            variant='h2'
            color={foregroundColor}
            align='center'>
            {title}
          </Heading>
          <Paragraph
            variant='p1'
            align='center'
            bold={true}
            color={foregroundColor}>
            {subTitle}
          </Paragraph>
        </TitlesContainer>
      </DeviceContainer>
      <DeviceContainer mobile>
        <ContentContainer>
          <HTML align='center'>
            {description}
          </HTML>
        </ContentContainer>
      </DeviceContainer>
      <DeviceContainer tablet desktop>
        <ContentContainer>
          <HTML align='left'>
            {description}
          </HTML>
        </ContentContainer>
      </DeviceContainer>
    </Container>
  )

}
