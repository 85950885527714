import React from 'react'

import styled from 'styled-components'

import { Paragraph, Skeleton, SkeletonNode } from '@atoms/index'
import { ResponsivePXValue } from '@components/Theme'
import { StockInfoFragment, WarehouseFragment } from '@hooks/api/index'
import { ProductStockStatus } from '@uctypes/api/globalTypes'

const StockContainer = styled.div`
  font-family: 'open-sans';
  display: flex;
  align-items: center;
  
  ${ResponsivePXValue('height', { mobile: '40px', tablet: '54px', desktop: '54px' })}
  ${ResponsivePXValue('font-size', { mobile: '12px', tablet: '14px', desktop: '14px' })}
  ${ResponsivePXValue('gap', { mobile: '8px', tablet: '8px', desktop: '8px' })}
`

const StockItem = styled.div`
  font-family: 'open-sans';
  font-weight: 600;
  background-color: ${(props): string => props.theme.colors.grey.athens};
  color: ${(props): string => props.theme.colors.grey.stormDust};

  ${ResponsivePXValue('font-size', { mobile: '10px', tablet: '12px', desktop: '12px' })}
  ${ResponsivePXValue('padding', { mobile: '4px', tablet: '8px', desktop: '8px' })}
`

interface ProductStockProps {
  sources: StockInfoFragment['sources']
  stockStatus: ProductStockStatus
  warehouse: WarehouseFragment
}

export function ProductStock({ sources, stockStatus, warehouse }: ProductStockProps): JSX.Element {

  if (sources.length === 0) {
    return (
      <StockContainer>
        <Skeleton direction='column' justify='flex-start' align='flex-start' gap='28px' height='auto'>
          <SkeletonNode shape='rounded' color='gallery' align='flex-start' justify='flex-start'
            height={{ mobile: '12px', tablet: '24px', desktop: '24px' }}
            variWidth={{ mobile: '30%-45%', tablet: '25%', desktop: '20%-50%' }}
          />
        </Skeleton>
      </StockContainer>
    )
  }

  let warehouseIndex: number

  return (
    <>
      <StockContainer>
        <Paragraph variant='p2' bold>
        Stock:
        </Paragraph>
        <For each='warehouse' of={sources || []} index='warehouseIndex'>
          <StockItem key={warehouseIndex}>
            {warehouse.location} ({stockStatus === ProductStockStatus.IN_STOCK ? warehouse.qty : 0})
          </StockItem>
        </For>
      </StockContainer>
    </>
  )

}
