import React from 'react'

import styled from 'styled-components'

import { Link, Paragraph, Skeleton, SkeletonNode } from '@atoms/index'
import { ResponsivePXValue } from '@components/Theme'
import { ProductDetailsFragment_SimpleProduct_ as SimpleDetails } from '@hooks/api/index'

type ProductDetailsFragmentBrand = SimpleDetails['brand']

const BrandContainer = styled.div`
  font-family: 'open-sans';
  
  ${ResponsivePXValue('font-size', { mobile: '12px', tablet: '14px', desktop: '14px' })}
`

interface ProductBrandProps {
  brand: ProductDetailsFragmentBrand
}

export function ProductBrand({ brand }: ProductBrandProps): JSX.Element {

  if (brand === undefined) {
    return (
      <Skeleton direction='column' justify='flex-start' align='flex-start' gap='28px' height='auto'>
        <SkeletonNode shape='rounded' color='gallery' align='flex-start' justify='flex-start'
          height={{ mobile: '12px', tablet: '24px', desktop: '24px' }}
          variWidth={{ mobile: '30%-45%', tablet: '25%', desktop: '20%-50%' }}
        />
      </Skeleton>
    )
  }

  let brandIndex: number

  return (
    <BrandContainer key={brandIndex}>
      <Choose>
        <When condition={!!brand?.path}>
          <Link href={brand.url} bold={false} display='inline-block'>{brand?.name}</Link>
        </When>
        <Otherwise>
          <Paragraph variant='p2'>{brand?.name}</Paragraph>
        </Otherwise>
      </Choose>
    </BrandContainer>
  )

}
