
import React from 'react'

import loadable from '@loadable/component'

import { PageLoader } from '@atoms/notifications'

import(/* webpackPrefetch: true */ '@pages/Search')

const Search = loadable(() => import(/* webpackChunkName: "search" */'@pages/Search'), {
  resolveComponent: (components) => components.Search,
})

export function SearchAsync(): JSX.Element {

  return <Search fallback={<PageLoader />} />

}
