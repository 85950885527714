import { useState, useEffect } from 'react'

import update from 'react-addons-update'

import { useConfig } from '@contexts/ConfigProvider'
import { Mutable } from '@uctypes/global'

export function useLoadingData<T>(args: { data: T, loading?: boolean, defaultData?: T | null }): Mutable<T> {

  const { data, loading = false, defaultData = null } = args

  const [state, setState] = useState<T | null>(defaultData)
  const config = useConfig()

  useEffect(() => {
    if (data && !loading) {
      setState((prevState) => update(prevState, {
        $set: data,
      }))
    } else if (!data && !loading) {
      setState(defaultData)
    }
  }, [data, loading])

  return config.isBrowser() ? data || state : loading ? defaultData : data

}
