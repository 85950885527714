import React from 'react'

import { ModalFormContainer } from '@atoms/layout/ModalFormContainer'
import { CartFragment, CheckoutFragment } from '@hooks/api'
import { Modal } from '@molecules/content/Modal'

import { FinalSummary } from './FinalSummary'

export interface SummaryModalProps {
  open: boolean
  onConfirm: () => void
  onCancel: () => void
  cart: CartFragment
  checkout: CheckoutFragment
  loading: boolean
}

export function SummaryModal({ open, onConfirm, onCancel, cart, checkout, loading }: SummaryModalProps): JSX.Element {

  return (
    <Modal open={open} title='Confirm Order' onClose={onCancel}>
      <ModalFormContainer>
        <FinalSummary onNext={onConfirm} onCancel={onCancel} cart={cart} checkout={checkout} loading={loading} />
      </ModalFormContainer>
    </Modal>
  )

}
