
import React from 'react'

import loadable from '@loadable/component'

import { PageLoader } from '@atoms/notifications'

import(/* webpackPrefetch: true */ '@pages/ValuesListing')

const ValuesListing = loadable(() => import(/* webpackChunkName: "brand" */'@pages/ValuesListing'), {
  resolveComponent: (components) => components.ValuesListing,
})

export function ValuesListingAsync(): JSX.Element {
  
  return <ValuesListing fallback={<PageLoader />} />

}
