import React, { useEffect, useState, useRef } from 'react'

import { useNavigate } from 'react-router'
import styled from 'styled-components'

import { APP_DEFAULT_STATE } from '@api/local/AppPlugin'
import { MENU_CATEGORIES_DEFAULT_STATE } from '@api/local/MenuCategoryPlugin'
import { NAVIGATION_DEFAULT_STATE, NavigationPlugin } from '@api/local/NavigationPlugin'
import { DesktopCategoryBarItem, Skeleton, SkeletonNode } from '@atoms/index'
import { ResponsivePXValue } from '@components/Theme'
import { useConfig } from '@contexts/ConfigProvider'
import { CategoryNavigationFragment, useGetAppQuery, useGetCategoryMenuQueryLazyQuery, useGetMenuCategoriesQuery, useGetNavigationQuery } from '@hooks/api/index'
import { DesktopCategoryMenu } from '@molecules/index'

const DEBOUCE_TIME = 300

const CategoryBar = styled.div`
  
`
const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  ${ResponsivePXValue('width', '1440px')}
  ${ResponsivePXValue('gap', '35px')}
  ${ResponsivePXValue('height', '56px')}
`

export function DesktopCategoryBar(): JSX.Element {

  const { data: menuCategoriesData = { menuCategories: { ...MENU_CATEGORIES_DEFAULT_STATE } } } = useGetMenuCategoriesQuery()
  const { data: navigationData = { navigation: { ...NAVIGATION_DEFAULT_STATE } } } = useGetNavigationQuery()
  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()
  const [fetchCategories, { data: menuData, loading: menuLoading }] = useGetCategoryMenuQueryLazyQuery()

  const [hovering, setHovering] = useState<string | null>(null)
  const container: React.RefObject<HTMLDivElement> = useRef()
  const navigate = useNavigate()
  const config = useConfig()
  const timer = useRef<NodeJS.Timeout | null>()

  const clearDebounce = (): void => {
    clearTimeout(timer?.current)
    timer.current = null
  }

  const _handleCategoryClicked = async (path: string): Promise<void> => {
    const client = await config.getClient()

    setHovering(null)
    if (navigationData.navigation.isTest) {
      NavigationPlugin.shared().setPath(client, path)
    } else {
      navigate(path)
    }
  }

  const _handleMouseEnter = (id: string) => {
    if (!timer?.current) {
      setHovering(id)
    }
    clearDebounce()
    timer.current = setTimeout(() => {
      setHovering(id)
    }, DEBOUCE_TIME)
  }

  const _handleContainerMouseEnter = () => {
    clearDebounce()
  }

  const _handleContainerMouseLeave = () => {
    clearDebounce()
    setHovering(null)
  }

  const _handlePageClick = (e: MouseEvent): void => {
    if (hovering && container.current && !container.current.contains(e.target as Element)) {
      clearDebounce()
      setHovering(null)
    }
  }

  useEffect(() => {
    return () => clearDebounce()
  }, [])

  useEffect(() => {
    document.addEventListener('mousedown', _handlePageClick)
    return () => document.addEventListener('mousedown', _handlePageClick)
  })

  useEffect(() => {
    if (appData?.app?.baseCategoryId) {
      fetchCategories({ variables: { rootCategoryId: appData.app.baseCategoryId } })
    }
  }, [appData?.app?.baseCategoryId])

  let mainNav: CategoryNavigationFragment
  let selectedNav: CategoryNavigationFragment | null = null
  if (hovering) {
    selectedNav = menuData?.categories?.items?.find((category) => category.uid === hovering)
  }

  const filteredCatgories = menuData?.categories?.items?.filter((category) => category.includeInMenu) || []
  const categories = filteredCatgories.length > 9 ? filteredCatgories.slice(0, 9) : filteredCatgories || []

  return (
    <CategoryBar>
      <Choose>
        <When condition={menuLoading || !appData.app.baseCategoryId}>
          <Skeleton loop={6} padding={{ mobile: '0', tablet: '16px 8px', desktop: '16px 8px' }} gap='16px'>
            <SkeletonNode
              color='pampas'
              height={{ mobile: '0', tablet: '26px', desktop: '26px' }}
              shape='rounded'
              variWidth={{ mobile: '0', tablet: '50%-90%', desktop: '50%-90%' }}/>
          </Skeleton>
        </When>
        <Otherwise>
          <Container
            ref={container}
            onMouseEnter={_handleContainerMouseEnter}
            onMouseLeave={_handleContainerMouseLeave}>
            <For each='mainNav' of={categories}>
              <DesktopCategoryBarItem
                key={mainNav.uid}
                title={mainNav.name}
                href={`/${mainNav.canonicalUrl}`}
                hovering={mainNav.uid === hovering}
                active={mainNav.uid === menuCategoriesData.menuCategories.currentTop}
                onMouseEnter={() => _handleMouseEnter(mainNav.uid)}
                onClick={() => _handleCategoryClicked(`/${mainNav.canonicalUrl}`)} />
            </For>
            <If condition={!!selectedNav}>
              <DesktopCategoryMenu mainNav={selectedNav} onSelect={_handleCategoryClicked} />
            </If>
          </Container>
        </Otherwise>
      </Choose>
    </CategoryBar>
  )

}
