
import React from 'react'
import { Svg } from './Svg'

export interface FilterProps {
  color?: string
  hoverColor?: string
  className?: string
}

export function Filter({ color, hoverColor, className }: FilterProps): JSX.Element {

  return (
    <Svg strokeColor={color} strokeHoverColor={hoverColor} fillColor={color} fillHoverColor={hoverColor} className={className} width="100%" height="100%" viewBox="0 0 20 13" version="1.1">
      <g transform="matrix(0.998962,0,0,0.998962,-1.98754,-5.73728)">
        <path className='fill' d="M9,16.75C9,16.551 9.079,16.36 9.22,16.22C9.36,16.079 9.551,16 9.75,16L14.25,16C14.449,16 14.64,16.079 14.78,16.22C14.921,16.36 15,16.551 15,16.75C15,16.949 14.921,17.14 14.78,17.28C14.64,17.421 14.449,17.5 14.25,17.5L9.75,17.5C9.551,17.5 9.36,17.421 9.22,17.28C9.079,17.14 9,16.949 9,16.75ZM6,12.25C6,12.051 6.079,11.86 6.22,11.72C6.36,11.579 6.551,11.5 6.75,11.5L17.25,11.5C17.449,11.5 17.64,11.579 17.78,11.72C17.921,11.86 18,12.051 18,12.25C18,12.449 17.921,12.64 17.78,12.78C17.64,12.921 17.449,13 17.25,13L6.75,13C6.551,13 6.36,12.921 6.22,12.78C6.079,12.64 6,12.449 6,12.25ZM3,7.75C3,7.551 3.079,7.36 3.22,7.22C3.36,7.079 3.551,7 3.75,7L20.25,7C20.449,7 20.64,7.079 20.78,7.22C20.921,7.36 21,7.551 21,7.75C21,7.949 20.921,8.14 20.78,8.28C20.64,8.421 20.449,8.5 20.25,8.5L3.75,8.5C3.551,8.5 3.36,8.421 3.22,8.28C3.079,8.14 3,7.949 3,7.75Z" />
      </g>
    </Svg>
  )

}
    