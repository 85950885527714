import React from 'react'

import { Config } from '@client/lib/Config'

const ConfigContext = React.createContext<Config>(null)

export function ConfigProvider({ config, children }: { config: Config, children: JSX.Element }): JSX.Element {
  return (
    <ConfigContext.Provider value={config}>
      {children}
    </ConfigContext.Provider>
  )
}

export const useConfig = (): Config => React.useContext(ConfigContext)
