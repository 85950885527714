import React from 'react'

import { ErrorBoundary } from 'react-error-boundary'
import styled from 'styled-components'

import { ResponsivePXValue } from '@components/Theme'
import { PageAccordionSectionFragment, PageBannerSectionFragment, PageBlockSectionFragment, PageCarouselSectionFragment, PageChipSectionFragment, PageExcerptSectionFragment, PageHeadingSectionFragment, PageHeroSectionFragment, PageIconSectionFragment, PageLinkSectionFragment, PageMarkupSectionFragment, PageMediaSectionFragment, PageMyDiscountsSectionFragment, PageMyShopSectionFragment, PageNotificationSectionFragment, PagePlainTextSectionFragment, PageProductSliderSectionFragment, PageQuestionSectionFragment, PageSectionFragment, PageSiteNoticeSectionFragment, PageSplitTextSectionFragment, PageTimetableSectionFragment } from '@hooks/api/index'
import { CustomerTypeEnum, PageUserStatus } from '@uctypes/api/globalTypes'

import { PageAccordionSection } from './PageAccordionSection'
import { PageBannerSection } from './PageBannerSection'
import { PageBlockSection } from './PageBlockSection'
import { PageCarouselSection } from './PageCarouselSection'
import { PageChipSection } from './PageChipSection'
import { PageExcerptSection } from './PageExcerptSection'
import { PageHeadingSection } from './PageHeadingSection'
import { PageHeroSection } from './PageHeroSection'
import { PageIconSection } from './PageIconSection'
import { PageLinkSection } from './PageLinkSection'
import { PageMarkupSection } from './PageMarkupSection'
import { PageMediaSection } from './PageMediaSection'
import { PageMyDiscountsSection } from './PageMyDiscountsSection'
import { PageMyShopSection } from './PageMyShopSection'
import { PageNotificationSection } from './PageNotificationSection'
import { PagePlainTextSection } from './PagePlainTextSection'
import { PageProductSliderSection } from './PageProductSliderSection'
import { PageQuestionSection } from './PageQuestionSection'
import { PageSiteNoticeSection } from './PageSiteNoticeSection'
import { PageSplitTextSection } from './PageSplitTextSection'
import { PageTimetableSection } from './PageTimetableSection'
import { SectionFallbackComponent } from './SectionFallbackComponent'
import { UnknownSection } from './UnknownSection'

const shouldShow = (device: boolean, userStatus: PageUserStatus, customerType?: CustomerTypeEnum): boolean => {
  if (device) {
    return userStatus === PageUserStatus.BOTH ||
    (userStatus === PageUserStatus.LOGGED_IN && customerType === CustomerTypeEnum.REGISTERED) ||
    (userStatus === PageUserStatus.LOGGED_OUT && customerType === CustomerTypeEnum.GUEST)
  }
  return false
}

const Container = styled.div<{ showOnMobile: boolean, showOnTablet: boolean, showOnDesktop: boolean, userStatus: PageUserStatus, customerType?: CustomerTypeEnum }>`
  width: 100%;
  overflow-x: hidden;
  ${(props): string => ResponsivePXValue('display', {
    mobile: !shouldShow(props.showOnMobile, props.userStatus, props.customerType) ? 'none' : undefined,
    tablet: !shouldShow(props.showOnTablet, props.userStatus, props.customerType) ? 'none' : undefined,
    desktop: !shouldShow(props.showOnDesktop, props.userStatus, props.customerType) ? 'none' : undefined,
  })}
`
export interface PageSectionProps {
  section: PageSectionFragment
  customerType?: CustomerTypeEnum
}

export const getSectionForData = (section: PageSectionFragment, showPlaceholder = false): JSX.Element => {
  return (
    <Choose>
      <When condition={section.__typename === 'PageBannerSection'}>
        <PageBannerSection
          key={section.id}
          section={section as unknown as PageBannerSectionFragment} />
      </When>
      <When condition={section.__typename === 'PageBlockSection'}>
        <PageBlockSection
          key={section.id}
          section={section as unknown as PageBlockSectionFragment} />
      </When>
      <When condition={section.__typename === 'PageLinkSection'}>
        <PageLinkSection
          key={section.id}
          section={section as unknown as PageLinkSectionFragment} />
      </When>
      <When condition={section.__typename === 'PageChipSection'}>
        <PageChipSection
          key={section.id}
          section={section as unknown as PageChipSectionFragment} />
      </When>
      <When condition={section.__typename === 'PageExcerptSection'}>
        <PageExcerptSection
          key={section.id}
          section={section as unknown as PageExcerptSectionFragment} />
      </When>
      <When condition={section.__typename === 'PageProductSliderSection'}>
        <PageProductSliderSection
          key={section.id}
          section={section as unknown as PageProductSliderSectionFragment} />
      </When>
      <When condition={section.__typename === 'PageMarkupSection'}>
        <PageMarkupSection
          key={section.id}
          section={section as unknown as PageMarkupSectionFragment} />
      </When>
      <When condition={section.__typename === 'PageAccordionSection'}>
        <PageAccordionSection
          key={section.id}
          section={section as unknown as PageAccordionSectionFragment} />
      </When>
      <When condition={section.__typename === 'PageHeadingSection'}>
        <PageHeadingSection
          key={section.id}
          showPlaceholder={showPlaceholder}
          section={section as unknown as PageHeadingSectionFragment} />
      </When>
      <When condition={section.__typename === 'PageQuestionSection'}>
        <PageQuestionSection
          key={section.id}
          section={section as unknown as PageQuestionSectionFragment} />
      </When>
      <When condition={section.__typename === 'PageMediaSection'}>
        <PageMediaSection
          key={section.id}
          section={section as unknown as PageMediaSectionFragment} />
      </When>
      <When condition={section.__typename === 'PageHeroSection'}>
        <PageHeroSection
          key={section.id}
          section={section as unknown as PageHeroSectionFragment} />
      </When>
      <When condition={section.__typename === 'PageSplitTextSection'}>
        <PageSplitTextSection
          key={section.id}
          section={section as unknown as PageSplitTextSectionFragment} />
      </When>
      <When condition={section.__typename === 'PageIconSection'}>
        <PageIconSection
          key={section.id}
          section={section as unknown as PageIconSectionFragment} />
      </When>
      <When condition={section.__typename === 'PagePlainTextSection'}>
        <PagePlainTextSection
          key={section.id}
          section={section as unknown as PagePlainTextSectionFragment} />
      </When>
      <When condition={section.__typename === 'PageSiteNoticeSection'}>
        <PageSiteNoticeSection
          key={section.id}
          section={section as unknown as PageSiteNoticeSectionFragment} />
      </When>
      <When condition={section.__typename === 'PageCarouselSection'}>
        <PageCarouselSection
          key={section.id}
          section={section as unknown as PageCarouselSectionFragment} />
      </When>
      <When condition={section.__typename === 'PageNotificationSection'}>
        <PageNotificationSection
          key={section.id}
          section={section as unknown as PageNotificationSectionFragment} />
      </When>
      <When condition={section.__typename === 'PageMyDiscountsSection'}>
        <PageMyDiscountsSection
          key={section.id}
          showPlaceholder={showPlaceholder}
          section={section as unknown as PageMyDiscountsSectionFragment} />
      </When>
      <When condition={section.__typename === 'PageMyShopSection'}>
        <PageMyShopSection
          key={section.id}
          showPlaceholder={showPlaceholder}
          section={section as unknown as PageMyShopSectionFragment} />
      </When>
      <When condition={section.__typename === 'PageTimetableSection'}>
        <PageTimetableSection
          key={section.id}
          section={section as unknown as PageTimetableSectionFragment} />
      </When>
      <Otherwise>
        <UnknownSection sectionType={section.__typename} />
      </Otherwise>
    </Choose>
  )
}

export function PageSection({ section, customerType }: PageSectionProps): JSX.Element {

  return (
    <ErrorBoundary FallbackComponent={SectionFallbackComponent}>
      <Container
        customerType={customerType}
        userStatus={section.userStatus}
        showOnMobile={section.shouldShow.mobile}
        showOnTablet={section.shouldShow.tablet}
        showOnDesktop={section.shouldShow.desktop}>
        {getSectionForData(section)}
      </Container>
    </ErrorBoundary>
  )
}
