import React from 'react'

import styled, { useTheme } from 'styled-components'

import { Icon, LocalIconEnums } from '@atoms/index'
import { ResponsivePXValue } from '@components/Theme'

const LabelElement = styled.div<{ color: string, backgroundColor: string, addRadius: boolean}>`

  background-color: ${(props): string => props.backgroundColor};
  color: ${(props): string => props.color};
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: open-sans;
  font-weight: 700;
  width: fit-content;
  
  ${(props): string => ResponsivePXValue('border-radius', props.addRadius ? '8px' : '0')};

  ${ResponsivePXValue('padding', { mobile: '4px 12px', tablet: '4px 12px', desktop: '4px 12px' })}
  ${ResponsivePXValue('font-size', { mobile: '10px', tablet: '10px', desktop: '12px' })}
  ${ResponsivePXValue('gap', { mobile: '6px', tablet: '6px', desktop: '8px' })}
  ${ResponsivePXValue('min-height', { mobile: '24px', tablet: '26px', desktop: '26px' })}
`

const Content = styled.div``

const Close = styled.div`
  ${ResponsivePXValue('width', '12px')}
  ${ResponsivePXValue('height', '12px')}
  &:hover {
    path {
      stroke: ${(props): string => props.theme.colors.white.pureWhite};
    }
  }
`
export interface LabelProps {
  children?: string
  color?: string
  radius?: boolean
  backgroundColor?: string
  onClose?: () => void
}

export function Label({ children, color, backgroundColor, onClose, radius = false }: LabelProps): JSX.Element {

  const theme = useTheme()

  return (
    <LabelElement
      color={color || theme.colors.white.floralWhite}
      addRadius={radius}
      backgroundColor={backgroundColor || theme.colors.green.watercourse}>
      <Content>
        {children}
      </Content>
      <If condition={!!onClose}>
        <Close onClick={onClose}>
          <Icon color={color || theme.colors.green.greenVogue} icon={LocalIconEnums.LARGE_CROSS} />
        </Close>
      </If>
    </LabelElement>
  )

}
