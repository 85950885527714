import React from 'react'

import styled, { useTheme } from 'styled-components'

import { Button } from '@atoms/buttons'
import { Paragraph, Tag, Title } from '@atoms/typography'
import { ResponsivePXValue } from '@components/Theme'
import { PargoLocationFragment, ShippingMethodFragment } from '@hooks/api/index'
import { SiteHelper } from '@lib/SiteHelper'
import { HTML } from '@molecules/index'

const Container = styled.div<{ active: boolean }>`
  display: flex;
  align-items: center;
  background-color: ${(props): string => props.active ? props.theme.colors.white.pampas : props.theme.colors.white.pureWhite};
  ${ResponsivePXValue('padding', { mobile: '24px', tablet: '24px', desktop: '24px' })}
  ${ResponsivePXValue('border-radius', { mobile: '0', tablet: '10px', desktop: '10px' })}
`

const CircleContainer = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  ${ResponsivePXValue('width', { mobile: '30px', tablet: '60px', desktop: '60px' })}
`

const Circle = styled.div<{ active: boolean }>`
  border-style: solid;
  cursor: pointer;
  ${ResponsivePXValue('width', { mobile: '16px', tablet: '20px', desktop: '20px' })}
  ${ResponsivePXValue('height', { mobile: '16px', tablet: '20px', desktop: '20px' })}
  ${ResponsivePXValue('border-radius', { mobile: '10px', tablet: '13px', desktop: '13px' })}
  ${(props): string => {
    if (props.active) {
      return `
        ${ResponsivePXValue('border-width', { mobile: '4px', tablet: '5px', desktop: '5px' })}
        border-color: ${props.theme.colors.green.greenVogue};
      `
    }
    return `
    ${ResponsivePXValue('border-width', { mobile: '1px', tablet: '2px', desktop: '2px' })}
    border-color: ${props.theme.colors.grey.silver};
  `
  }}
`

const TextContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`

const RightContainer = styled.div`
  display: flex;
  ${ResponsivePXValue('align-items', { mobile: 'flex-end', tablet: 'center', desktop: 'center' })}
  ${ResponsivePXValue('justify-content', { mobile: 'space-between', tablet: 'center', desktop: 'center' })}
  ${ResponsivePXValue('flex-direction', { mobile: 'column', tablet: 'row', desktop: 'row' })}
  ${ResponsivePXValue('align-self', { mobile: 'stretch', tablet: 'auto', desktop: 'auto' })}
  ${ResponsivePXValue('width', { mobile: '40%', tablet: 'auto', desktop: 'auto' })}
`

const Price = styled.div<{ active: boolean }>`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  ${ResponsivePXValue('padding', { mobile: '0 12px', tablet: '0 12px', desktop: '0 12px' })}
  ${ResponsivePXValue('height', { mobile: '24px', tablet: '36px', desktop: '36px' })}
  ${ResponsivePXValue('border-radius', { mobile: '16px', tablet: '16px', desktop: '16px' })}
  background-color: ${(props): string => props.active ? props.theme.colors.green.magicMint : props.theme.colors.grey.athens};
`

const ContentContaine = styled.div`
  width: 100%;
`

const ContentItem = styled.div`
  display: flex;
`

const ImageContainer = styled.div`
  ${ResponsivePXValue('display', { mobile: 'none' })}
  ${ResponsivePXValue('width', { mobile: '100%', tablet: '140px', desktop: '140px' })}
  ${ResponsivePXValue('height', { mobile: '160px', tablet: '120px', desktop: '120px' })}
  ${ResponsivePXValue('margin-right', { mobile: '16px', tablet: '16px', desktop: '16px' })}
`

const LocationImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: fill;
`

const PargoDetailsContainer = styled.div`
  flex-grow: 1;
  .spacer {
    ${ResponsivePXValue('margin-top', { mobile: '16px', tablet: '16px', desktop: '16px' })}
  }
`

const PargoInfoContainer = styled.div`
  display: flex;
  ${ResponsivePXValue('margin-top', { mobile: '16px', tablet: '16px', desktop: '24px' })}
  ${ResponsivePXValue('flex-direction', { mobile: 'column', tablet: 'row', desktop: 'row' })}
`

const RightPargoContainer = styled.div`
  display: flex;
  ${ResponsivePXValue('align-items', { mobile: 'flex-start', tablet: 'flex-start', desktop: 'flex-start' })}
  ${ResponsivePXValue('justify-content', { mobile: 'flex-end', tablet: 'flex-end', desktop: 'flex-end' })}
  ${ResponsivePXValue('width', { mobile: '40%', tablet: 'auto', desktop: 'auto' })}
  .change-button {
    ${ResponsivePXValue('width', { mobile: '100%' })}
    ${ResponsivePXValue('margin-top', { mobile: '24px' })}
  }
`

export interface DeliveryMethodRowProps {
  deliveryMethod: ShippingMethodFragment
  selected: boolean
  pargoLocation?: PargoLocationFragment
  onSelect: () => void
  onPargoLocationChange?: () => void
}

export function DeliveryMethodRow({ selected, deliveryMethod, pargoLocation, onSelect, onPargoLocationChange }: DeliveryMethodRowProps): JSX.Element {

  const theme = useTheme()

  return (
    <>
      <Container active={selected}>
        <If condition={!!onSelect}>
          <CircleContainer onClick={onSelect} >
            <Circle active={selected} />
          </CircleContainer>
        </If>
        <ContentContaine>
          <ContentItem>
            <TextContainer onClick={onSelect}>
              <Title variant='t8'>
                {deliveryMethod.carrierTitle}
              </Title>
              <Paragraph color={theme.colors.grey.gunSmoke}>
                <HTML>{deliveryMethod.methodTitle}</HTML>
              </Paragraph>
            </TextContainer>
            <RightContainer>
              <Price active={selected} onClick={onSelect} >
                <Tag variant='t2' color={theme.colors.grey.stormDust}>
                  {SiteHelper.formatCurrency(deliveryMethod.amount)}
                </Tag>
              </Price>
            </RightContainer>
          </ContentItem>
          <If condition={deliveryMethod.carrierCode.indexOf('pargo') !== -1 && !!pargoLocation}>
            <PargoInfoContainer>
              <ImageContainer onClick={onSelect}>
                <LocationImage src={pargoLocation.photo} />
              </ImageContainer>
              <PargoDetailsContainer onClick={onSelect}>
                <Paragraph bold>
                  {pargoLocation.storeName}
                </Paragraph>
                <Paragraph>
                  {pargoLocation.addressSms}
                </Paragraph>
                <Paragraph bold className='spacer'>
                  Trading hours
                </Paragraph>
                <Paragraph>
                  {pargoLocation.businessHours}
                </Paragraph>
              </PargoDetailsContainer>
              <RightPargoContainer>
                <Button size='medium' title='CHANGE' className='change-button' onClick={onPargoLocationChange} />
              </RightPargoContainer>
            </PargoInfoContainer>

          </If>
        </ContentContaine>

      </Container>
    </>

  )

}
