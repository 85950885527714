import { ApolloClient, FetchResult, FieldPolicy, MutationResult, NormalizedCacheObject, ServerError, gql, makeVar } from '@apollo/client'

import { Observable } from 'apollo-link'
import { ErrorResponse } from 'apollo-link-error'
import { DocumentNode, GraphQLError } from 'graphql'
import Cookies from 'js-cookie'
import { ParseError } from 'libphonenumber-js'
import update from 'react-addons-update'
import { v4 } from 'uuid'

import { CreateEmptyCartDocument, GetCustomerCartIdDocument, MergeCartsMutation, MergeCartsMutationDocument, CustomerQuery, CustomerQueryDocument, AuthFragment, CreateEmptyCartMutation } from '@hooks/api/index'
import { Config, ConfigPlugin } from '@lib/Config'
import { SiteHelper } from '@lib/SiteHelper'
import { CustomerTypeEnum, MobileOSTypeEnum } from '@uctypes/api/globalTypes'

import { AppPlugin } from './AppPlugin'
import { CartPlugin } from './CartPlugin'
import { GlobalModalTypeEnum, ModalPlugin } from './ModalPlugin'

const CUSTOMER_COOKIE_NAME = 'ftn-web'
const CART_COOKIE_NAME = 'ftn-web-cart'

enum AuthErrorType {
  NO_CART_ID = 'NO_CART_ID',
  INVALID_CART_ID = 'INVALID_CART_ID',
  USER_SESSION_ENDED = 'USER_SESSION_ENDED',
}

interface AuthError {
  messages: RegExp[]
  types: AuthErrorType[]
}

const authErrors: AuthError[] = [{
  messages: [
    /Variable "\$cartId" of non-null type/g,
  ],
  types: [
    AuthErrorType.NO_CART_ID,
  ],
}, {
  messages: [
    /Could not find a cart with ID/g,
  ],
  types: [
    AuthErrorType.INVALID_CART_ID,
  ],
}, {
  messages: [
    /The cart isn't active/g,
    /The cart isn’t active/g,
  ],
  types: [
    AuthErrorType.INVALID_CART_ID,
  ],
}, {
  messages: [
    /The current user cannot perform operations on cart/g,
    /operations on cart/g,
  ],
  types: [
    AuthErrorType.INVALID_CART_ID,
    AuthErrorType.USER_SESSION_ENDED,
  ],
}, {
  messages: [
    /The current customer isn’t authorized/g,
    /The current customer isn't authorized/g,
  ],
  types: [
    AuthErrorType.USER_SESSION_ENDED,
  ],
}]

const isBrowser = (): boolean => {
  return (typeof window !== 'undefined')
}

// The current customer isn’t authorized

export const AUTH_DEFAULT_STATE: AuthFragment = {
  id: v4(),
  cartId: null,
  token: null,
  __typename: 'Auth',
}

const _data = makeVar<AuthFragment>({ ...AUTH_DEFAULT_STATE })

export class AuthPlugin implements ConfigPlugin {

  static instance: AuthPlugin

  static shared(): AuthPlugin {
    if (!this.instance) {
      this.instance = new AuthPlugin()
    }
    return this.instance
  }

  client!: ApolloClient<NormalizedCacheObject>
  token: string | null = null
  cartId: string | null = null

  hasToken(): boolean {
    if (this.token) {
      return true
    }
    if (isBrowser() && Cookies.get(CUSTOMER_COOKIE_NAME)) {
      this.token = Cookies.get(CUSTOMER_COOKIE_NAME)
      return true
    }
    return false
  }

  getToken(): string {
    if (this.hasToken()) {
      return this.token
    }
    return null
  }

  setToken(token: string): void {
    this.token = token
    if (AppPlugin.shared().getMobileOSType() === MobileOSTypeEnum.APPLE) {
      window.webkit?.messageHandlers?.JSBridge?.postMessage({ ftn: 'true', type: 'SET_AUTH_TOKEN', token })
    } else if (isBrowser()) {
      Cookies.set(CUSTOMER_COOKIE_NAME, token)
    }
  }

  setImpersonationToken(token: string): void {
    this.token = token
    if (AppPlugin.shared().getMobileOSType() === MobileOSTypeEnum.APPLE) {
      window.webkit?.messageHandlers?.JSBridge?.postMessage({ ftn: 'true', type: 'SET_AUTH_TOKEN', token })
    } else if (isBrowser()) {
      Cookies.set(CUSTOMER_COOKIE_NAME, token)
    }
  }

  clearToken(): void {
    this.token = null
    if (AppPlugin.shared().getMobileOSType() === MobileOSTypeEnum.APPLE) {
      window.webkit?.messageHandlers?.JSBridge?.postMessage({ ftn: 'true', type: 'CLEAR_AUTH_TOKEN' })
    } else if (isBrowser()) {
      Cookies.remove(CUSTOMER_COOKIE_NAME)
    }
  }

  getCookieName(): string {
    return CUSTOMER_COOKIE_NAME
  }

  clear(): void {
    this.clearToken()
    this.clearCartId()
  }

  async clearCartId(): Promise<string | null> {
    if (isBrowser()) {
      this.clearCartIdOnLocalStorage()
      this.clearCartIdOnReactiveVar()
      const previousCartQuantities = JSON.parse(sessionStorage.getItem('CART_QUANTITIES') || '{}')
      sessionStorage.setItem('CART_QUANTITIES', JSON.stringify({}))
      sessionStorage.setItem('PREVIOUS_CART_QUANTITUES', JSON.stringify(previousCartQuantities))
      CartPlugin.shared().diffCartQuantities({}, previousCartQuantities)
      return await this.handleNullCartId()
    }
    return null
  }

  async handleNullCartId(shouldSet = true): Promise<string> {
    let cartId = this.getCartIdFromLocalStorage()
    if (!cartId) {
      cartId = await this.getCartIdFromServer()
    }
    if (!cartId) {
      cartId = await this.setCartIdOnServer()
    }
    if (shouldSet) {
      this.setCartIdOnLocalStorage(cartId)
      this.setCartIdOnReactiveVar(cartId)
    }
    return cartId
  }

  async handleInvalidCartId(shouldSet = true): Promise<string> {
    await this.clearCartId()
    return this.handleNullCartId(shouldSet)
  }

  generateCartId(): string {
    return SiteHelper.randomString(32)
  }

  setCartIdFromNativeApp(cartId: string): void {
    this.cartId = cartId
    this.setCartIdOnReactiveVar(cartId)
  }

  getCartIdFromLocalStorage(): string | null {
    if (AppPlugin.shared().getMobileOSType() === MobileOSTypeEnum.APPLE) {
      return this.cartId
    }
    return Cookies.get(CART_COOKIE_NAME) || null
  }

  setCartIdOnLocalStorage(cartId: string): void {
    if (AppPlugin.shared().getMobileOSType() === MobileOSTypeEnum.APPLE) {
      this.cartId = cartId
      window.webkit?.messageHandlers?.JSBridge?.postMessage({ ftn: 'true', type: 'SET_CART_ID', id: cartId })
    } else {
      Cookies.set(CART_COOKIE_NAME, cartId)
    }
  }

  clearCartIdOnLocalStorage(): void {
    if (AppPlugin.shared().getMobileOSType() === MobileOSTypeEnum.APPLE) {
      this.cartId = null
      window.webkit?.messageHandlers?.JSBridge?.postMessage({ ftn: 'true', type: 'CLEAR_CART_ID' })
    } else {
      Cookies.remove(CART_COOKIE_NAME)
    }
  }

  getCartIdFromReactiveVar(): string | null {
    return _data().cartId || null
  }

  setCartIdOnReactiveVar(cartId: string): void {
    _data(update(_data(), {
      cartId: {
        $set: cartId,
      },
    }))
  }

  clearCartIdOnReactiveVar(): void {
    _data(update(_data(), {
      cartId: {
        $set: null,
      },
    }))
  }

  async getCartIdFromServer(): Promise<string | null> {
    if (this.hasToken()) {
      try {
        const response = await this.client.query({
          fetchPolicy: 'network-only',
          errorPolicy: 'ignore',
          query: GetCustomerCartIdDocument,
        })
        if (response?.data?.customerCart) {
          return response.data.customerCart.id
        }
      } catch (e) {
        console.log(e)
      }
    }
    return null
  }

  async setCartIdOnServer(): Promise<string> {
    return this.createEmptyCart()
  }

  /*
    General flow
      1. User attempts to do something with cart
      2. Error is thrown and intercepted (depending one error log out user)
      3. If there is a cart id in storage
        1. Validate it, If it is valid set reactive variable (END)
      3. If there is no cart id in stirage
        1. Attempt to get customers cartId
          1. If you can
            1. Set it in storage
            2. Set it on reactive variable
          2. If failed create empty cart
            1. Set it in storage
            2. Set it on reactive variable

  */

  async createEmptyCart(): Promise<string> {
    const response: FetchResult<CreateEmptyCartMutation> = await this.client.mutate({
      errorPolicy: 'ignore',
      mutation: CreateEmptyCartDocument,
    })
    return response?.data?.createEmptyCart
  }

  async validateToken(): Promise<boolean> {
    try {
      const response = await this.client.query<CustomerQuery>({
        fetchPolicy: 'network-only',
        errorPolicy: 'ignore',
        query: CustomerQueryDocument,
        variables: {
          v: SiteHelper.randomString(10),
        },
      })
      if (response.data.currentCustomer?.customerType === CustomerTypeEnum.REGISTERED) {
        return true
      }
    } catch (e) { }
    return false
  }

  async mergeCarts(): Promise<void> {
    try {
      const currentCartId = this.getCartIdFromReactiveVar()
      const newCartId = await this.getCartIdFromServer()
      if (currentCartId && newCartId && currentCartId !== newCartId) {
        if (AuthPlugin.shared().hasToken()) {
          await this.client.mutate<MergeCartsMutation>({
            mutation: MergeCartsMutationDocument,
            variables: {
              sourceCartId: currentCartId,
              destinationCartId: newCartId,
            },
          })
        }
      }
      this.setCartIdOnLocalStorage(newCartId)
      this.setCartIdOnReactiveVar(newCartId)
      // TODO: update product cache
    } catch (e) {
      console.log('FAILED TO MERGE CARTS')
    }
  }

  async userIsCustomer(): Promise<boolean> {
    const response = await this.client.query({
      fetchPolicy: 'network-only',
      errorPolicy: 'ignore',
      query: CustomerQueryDocument,
      variables: {
        v: SiteHelper.randomString(10),
      },
    })
    if (response.data.currentCustomer?.customerType === CustomerTypeEnum.REGISTERED) {
      return true
    }
    return false
  }

  async configure(config: Config): Promise<void> {
    const client = await config.getClient()
    this.client = client
    const cartId = this.getCartIdFromLocalStorage()
    if (cartId) {
      this.setCartIdOnReactiveVar(cartId)
    }
  }

  headers(): { [k: string]: string } {
    return {
      Authorization: `Bearer ${this.getToken()}`,
    }
  }

  handleError(error: GraphQLError | null, networError: ServerError | ParseError | null, handler: ErrorResponse): void | Observable<FetchResult> {
    const { operation, forward } = handler
    const errorTypes: AuthErrorType[] = []
    if (error) {
      if (error.extensions?.code === 504) {
        AppPlugin.shared().setMaintenanceMode(true)
        return null
      }
      for (let e = 0; e < authErrors.length; e++) {
        const authError = authErrors[e]
        for (let r = 0; r < authError.messages.length; r++) {
          if (error.message.match(authError.messages[r])) {
            authError.types.forEach((errorType): void => {
              if (!errorTypes.includes(errorType)) {
                errorTypes.push(errorType)
              }
            })
          }
        }
      }
      if (errorTypes.includes(AuthErrorType.NO_CART_ID)) {
        return new Observable<FetchResult>((observer) => {
          let newCartId: string | null = null
          this.handleNullCartId(false)
            .then((cartId) => {
              newCartId = cartId
              operation.variables.cartId = cartId
            })
            .then(() => {
              const subscriber = {
                next: observer.next.bind(observer),
                error: observer.error.bind(observer),
                complete: () => {
                  observer.complete.bind(observer)
                  this.setCartIdOnLocalStorage(newCartId)
                  this.setCartIdOnReactiveVar(newCartId)
                },
              }
              // Retry last failed request
              forward(operation).subscribe(subscriber)
            })
            .catch((error: Error) => {
              // No refresh or client token available, we force user to login
              observer.error(error)
            })
        })
      } else if (errorTypes.includes(AuthErrorType.INVALID_CART_ID)) {
        return new Observable<FetchResult>((observer) => {
          let newCartId: string | null = null
          this.handleInvalidCartId(false)
            .then((cartId) => {
              newCartId = cartId
              operation.variables.cartId = cartId
            })
            .then(() => {
              const subscriber = {
                next: observer.next.bind(observer),
                error: observer.error.bind(observer),
                complete: () => {
                  observer.complete.bind(observer)
                  this.setCartIdOnLocalStorage(newCartId)
                  this.setCartIdOnReactiveVar(newCartId)
                },
              }
              // Retry last failed request
              forward(operation).subscribe(subscriber)
            })
            .catch((error: Error) => {
              // No refresh or client token available, we force user to login
              observer.error(error)
            })
        })
      } else if (errorTypes.includes(AuthErrorType.USER_SESSION_ENDED)) {
        return new Observable<FetchResult>((observer) => {
          let newCartId: string | null = null
          this.clearToken()
          this.userIsCustomer()
            .then(() => this.handleInvalidCartId(false))
            .then((cartId) => {
              newCartId = cartId
              operation.variables.cartId = cartId
            })
            .then(() => {
              const subscriber = {
                next: observer.next.bind(observer),
                complete: () => {
                  observer.complete.bind(observer)
                  this.setCartIdOnLocalStorage(newCartId)
                  this.setCartIdOnReactiveVar(newCartId)
                  this.client.refetchQueries({ include: [CustomerQueryDocument] })
                  ModalPlugin.shared().toggleGlobalModal(true, GlobalModalTypeEnum.LOG_IN)
                },
                error: () => {
                  observer.error.bind(observer)
                  this.setCartIdOnLocalStorage(newCartId)
                  this.setCartIdOnReactiveVar(newCartId)
                  this.client.refetchQueries({ include: [CustomerQueryDocument] })
                  ModalPlugin.shared().toggleGlobalModal(true, GlobalModalTypeEnum.LOG_IN)
                },
              }
              // Retry last failed request
              forward(operation).subscribe(subscriber)
            })
            .catch((error: Error) => {
              // No refresh or client token available, we force user to login
              observer.error(error)
            })
        })
      }
    } else if (networError) {
      console.log(`GOT NETWORK ERROR: ${networError.message}`)
    }

  }

  types = (): DocumentNode => gql`
    type Auth {
      id: ID!
      token: String
      cartId: String
    }
  `

  extensions = (): DocumentNode => gql`
    extend type Query {
      auth: Auth!
    }
  `

  queries = (): DocumentNode => gql`
    fragment AuthFragment on Auth {
      id
      token
      cartId
    }
    query GetAuth {
      auth @client {
        ... AuthFragment
      }
    }
  `

  fieldPolicies = (): { [k: string]: FieldPolicy } => ({
    auth: {
      read(): AuthFragment {
        const data = _data()
        return data
      },
    },
  })

}
