
import React from 'react'
import { Svg } from './Svg'

export interface SearchOutlineProps {
  color?: string
  hoverColor?: string
  className?: string
}

export function SearchOutline({ color, hoverColor, className }: SearchOutlineProps): JSX.Element {

  return (
    <Svg strokeColor={color} strokeHoverColor={hoverColor} fillColor={color} fillHoverColor={hoverColor} className={className} width="100%" height="100%" viewBox="0 0 20 20" version="1.1">
      <g transform="matrix(0.869927,0,0,0.869927,1.01621,1.00589)">
        <path d="M20.094,16.62C17.277,13.944 16.526,13.193 16.291,13.005C16.15,12.911 16.009,12.817 15.822,12.817C15.446,12.817 15.117,13.146 15.117,13.521C15.117,13.756 15.258,13.944 15.352,14.038L19.108,17.653C19.202,17.747 19.249,17.84 19.249,17.981C19.249,18.122 19.202,18.216 19.108,18.31L18.826,18.592C18.779,18.639 18.685,18.685 18.592,18.685C18.498,18.685 18.451,18.639 18.357,18.592L13.991,14.366C15.634,12.817 16.573,10.61 16.573,8.357C16.62,3.709 12.864,0 8.31,0C3.709,0 0,3.709 0,8.31C0,12.911 3.709,16.62 8.31,16.62C9.953,16.62 11.596,16.103 12.958,15.164L17.465,19.531C17.793,19.859 18.216,20 18.639,20C19.108,20 19.531,19.812 19.859,19.484L20.141,19.202C20.47,18.826 20.657,18.357 20.657,17.887C20.657,17.418 20.423,16.948 20.094,16.62ZM15.211,8.31C15.211,12.113 12.113,15.211 8.31,15.211C4.507,15.211 1.408,12.113 1.408,8.31C1.408,4.507 4.507,1.408 8.31,1.408C12.113,1.408 15.211,4.507 15.211,8.31Z" className='fill' />
      </g>
    </Svg>
  )

}
    