import React from 'react'

import styled, { useTheme } from 'styled-components'

import { ResponsiveImage } from '@atoms/images'
import { Title } from '@atoms/typography'
import { ResponsivePXValue } from '@components/Theme'
import { BaseCategoryFragment } from '@hooks/api/index'
import { HTML } from '@molecules/content'
import { DeviceContainer } from '@utility/DeviceContainer'

const Container = styled.div`
  position: relative;

  .category-title-standalone {
    ${ResponsivePXValue('margin-left', { mobile: '16px' })}
  }
  .category-title-mobile {
    ${ResponsivePXValue('margin', { mobile: '16px 16px 0' })}
  }
  /* .html {
    p:not(:first-child) {
      display: none;
    }
    ${ResponsivePXValue('max-height', { mobile: '100px', tablet: '150px', desktop: '150px' })}
    overflow: hidden;
    text-align: left !important;

    p {
      text-align: left !important;
    }
    a {
      text-decoration: none;
      ${ResponsivePXValue('font-size', { mobile: '12px', tablet: '14px', desktop: '14px' })}
      font-weight: 700;
      color: ${(props): string => props.theme.colors.black.pureBlack};
    }
    a:hover {
      text-decoration: underline;
      ${ResponsivePXValue('font-size', { mobile: '12px', tablet: '14px', desktop: '14px' })}
      font-weight: 700;
    }

  } */
`

const Header = styled.div`
  display: flex;
  position: relatvive;
  ${ResponsivePXValue('border-radius', { mobile: '0', tablet: '8px', desktop: '8px' })}
  ${ResponsivePXValue('height', { mobile: '212px', tablet: '400px', desktop: '400px' })}
  ${ResponsivePXValue('margin', { mobile: '0', tablet: '0 0 24px 0', desktop: '0 0 24px 0' })}

  background-color: ${(props): string => props.theme.colors.grey.swirl};
  .mobile-category-title {
    position: absolute;
    width: 100%;
    ${ResponsivePXValue('top', '16px')}
    ${ResponsivePXValue('left', '16px')}
  }
`

const CategoryDescription = styled.div`
  ${ResponsivePXValue('padding', '16px 16px 0px')}
  .html {
    ${ResponsivePXValue('overflow', { mobile: 'visible' })}
  }

  p {
    ${ResponsivePXValue('margin', { mobile: '0' })}
  }
`

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${ResponsivePXValue('margin', { mobile: '0', tablet: '110px 32px', desktop: '110px 32px' })}
  ${ResponsivePXValue('width', { mobile: 'auto', tablet: '55%', desktop: '55%' })}

  .category-title {
    ${ResponsivePXValue('margin', '0 0 16px 0')}
  }

`

const ImageContainer = styled.div`
  ${ResponsivePXValue('width', { mobile: '100%', tablet: '680px', desktop: '680px' })}
`

const TitleContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  ${ResponsivePXValue('padding', '64px')}
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
`

const NewImageContainer = styled.div`
  width: 100%;
  ${ResponsivePXValue('min-height', { mobile: '160px', tablet: '400px', desktop: '400px' })}
`

export interface BaseCategoryHeaderProps {
  category: BaseCategoryFragment
}

export function BaseCategoryHeader({ category }: BaseCategoryHeaderProps): JSX.Element {

  const theme = useTheme()

  return (
    <Container>
      <Choose>
        <When condition={!category?.image}>
          <Title variant='t1' className='category-title-standalone'>{category?.name}</Title>
        </When>
        <Otherwise>
          <NewImageContainer>
            <DeviceContainer mobile>
              <ResponsiveImage location={category?.image} objectFit='contain' />
              <Title variant='t1' className='category-title-mobile'>{category?.name}</Title>
            </DeviceContainer>
            <DeviceContainer desktop tablet>
              <ResponsiveImage location={category?.image} objectFit='contain' />
              <TitleContainer>
                <Title variant='t1' className='category-title' color={theme.colors.white.pureWhite}>{category?.name}</Title>
              </TitleContainer>
            </DeviceContainer>
          </NewImageContainer>
        </Otherwise>
      </Choose>

    </Container>
  )

  // return (
  //   <Container>
  //     <Header>
  //       <DeviceContainer mobile><Title variant='t1' className='mobile-category-title'>{category?.name}</Title></DeviceContainer>
  //       <DeviceContainer desktop tablet>
  //         <InfoContainer>
  //           <Title variant='t1' className='category-title'>{category?.name}</Title>
  //           <If condition={!!category?.description}>
  //             <HTML className='html'>
  //               {category?.description}
  //             </HTML>
  //           </If>
  //         </InfoContainer>
  //       </DeviceContainer>
  //       <ImageContainer>
  //         <ResponsiveImage location={category?.image} objectFit='contain' />
  //       </ImageContainer>
  //     </Header>
  //     <DeviceContainer mobile>
  //       <CategoryDescription>
  //         <HTML className='html'>
  //           {category?.description}
  //         </HTML>
  //       </CategoryDescription>
  //     </DeviceContainer>
  //   </Container>

  // )

}
