import React from 'react'

import styled from 'styled-components'

import { Paragraph } from '@atoms/index'
import { ResponsivePXValue } from '@components/Theme'
import { SearchTermFragment } from '@hooks/api/index'

const Container = styled.div`
  cursor: pointer;
  background-color: ${(props): string => props.theme.colors.white.pureWhite};
  ${ResponsivePXValue('height', '20px')}
  ${ResponsivePXValue('margin', '5px 0 0 0')}
  .text-element {
    padding: 0;
    margin: 0;
  }

`

export interface TermSearchItemProps {
  item: SearchTermFragment
  onClick?: (path: string) => void
}

export function TermSearchItem({ item, onClick }: TermSearchItemProps): JSX.Element {

  const _handleClick = (): void => {
    onClick?.(`/search?term=${item.queryText}&spellchecked=true`)
  }

  return (
    <Container onClick={_handleClick}>
      <Paragraph variant='p2' className='text-element'>{item.queryText}</Paragraph>
    </Container>
  )

}
