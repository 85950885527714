import React from 'react'

import styled from 'styled-components'

import { ResponsivePXValue } from '@components/Theme'

export const StoryBlock = styled.div`
  width: 100%;
  max-height: 95vh;
  padding-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`

const InputStoryContainer = styled.div`
  width: 100%;
  height: 40vh;
  display: flex;
  align-items: center;
  justify-content: center;
`

const InputContainer = styled.div`
  ${ResponsivePXValue('width', { mobile: '90vw', tablet: '60vw', desktop: '30vw' })}
`

export function InputStoryBlock({ children }: { children: JSX.Element }): JSX.Element {
  return (
    <InputStoryContainer>
      <InputContainer>
        {children}
      </InputContainer>
    </InputStoryContainer>
  )
}
