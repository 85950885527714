
import React from 'react'

import loadable from '@loadable/component'

import(/* webpackPrefetch: true */ '@pages/GiftVouchers')

const GiftVouchers = loadable(() => import(/* webpackChunkName: "me" */'@pages/GiftVouchers'), {
  resolveComponent: (components) => components.GiftVouchers,
})

export function GiftVouchersAsync(): JSX.Element {

  return <GiftVouchers fallback={<div>Loading...</div>} />

}
