
import React from 'react'
import { Svg } from './Svg'

export interface DoubleTickProps {
  color?: string
  hoverColor?: string
  className?: string
}

export function DoubleTick({ color, hoverColor, className }: DoubleTickProps): JSX.Element {

  return (
    <Svg strokeColor={color} strokeHoverColor={hoverColor} fillColor={color} fillHoverColor={hoverColor} className={className} width="100%" height="100%" viewBox="0 0 20 20" version="1.1">
      <g id="Group_969" transform="matrix(1.29333,0,0,1.1821,-3215.3,-193.221)">
        <path id="Path_1006" d="M2499.64,168.787L2499.57,168.718C2499.5,168.607 2499.37,168.54 2499.24,168.54C2499.1,168.54 2498.98,168.607 2498.91,168.718L2498.53,169.091L2494.4,173.228C2494.38,173.243 2494.37,173.258 2494.36,173.274L2494.31,173.337L2494.02,173.686L2493.75,173.324L2493.7,173.263L2493.68,173.232L2492.07,171.615C2491.99,171.536 2491.89,171.489 2491.78,171.486C2491.67,171.49 2491.57,171.537 2491.49,171.616C2491.39,171.718 2491.29,171.822 2491.18,171.927C2491.1,171.999 2491.05,172.104 2491.05,172.215C2491.05,172.305 2491.08,172.392 2491.14,172.46C2491.15,172.476 2491.17,172.491 2491.18,172.504C2492.09,173.417 2493.01,174.328 2493.92,175.237C2493.94,175.259 2493.97,175.277 2493.99,175.289C2494.01,175.294 2494.02,175.297 2494.04,175.298C2494.09,175.286 2494.14,175.256 2494.17,175.215C2495.29,174.095 2496.41,172.977 2497.53,171.86L2498.84,170.547L2499.83,169.553C2499.99,169.42 2500.01,169.179 2499.88,169.02C2499.87,169.003 2499.85,168.988 2499.83,168.974C2499.76,168.916 2499.7,168.854 2499.64,168.787Z" className='fill' />
        <g id="Group_968">
          <path id="Path_1007" d="M2490.86,173.339L2490.57,173.688L2490.3,173.327L2490.25,173.265C2490.24,173.255 2490.24,173.245 2490.23,173.235L2488.61,171.617C2488.54,171.537 2488.44,171.49 2488.33,171.487C2488.22,171.491 2488.12,171.538 2488.04,171.617C2487.94,171.719 2487.83,171.823 2487.73,171.928C2487.65,172 2487.6,172.105 2487.6,172.216C2487.6,172.306 2487.63,172.393 2487.69,172.461C2487.7,172.477 2487.72,172.492 2487.73,172.505C2488.64,173.419 2489.55,174.33 2490.47,175.238C2490.49,175.26 2490.51,175.278 2490.54,175.29C2490.55,175.296 2490.57,175.299 2490.59,175.299C2490.64,175.287 2490.69,175.258 2490.72,175.216L2491.78,174.157L2490.9,173.276L2490.86,173.339Z" className='fill' />
          <path id="Path_1008" d="M2494.08,171.864L2495.39,170.551C2495.72,170.22 2496.06,169.89 2496.39,169.557C2496.54,169.424 2496.57,169.183 2496.43,169.024C2496.42,169.007 2496.4,168.992 2496.39,168.978L2496.19,168.778L2496.12,168.709C2496.05,168.598 2495.92,168.531 2495.79,168.531C2495.71,168.531 2495.63,168.555 2495.57,168.599C2495.52,168.628 2495.49,168.665 2495.46,168.709L2495.08,169.081L2492.84,171.326C2493.14,171.62 2493.43,171.915 2493.72,172.211C2493.84,172.1 2493.96,171.984 2494.08,171.864Z" className='fill' />
        </g>
      </g>
    </Svg>
  )

}
    