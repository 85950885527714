
import React from 'react'

import loadable from '@loadable/component'

import { PageLoader } from '@atoms/notifications'

import(/* webpackPrefetch: true */ '@pages/ContactUs')

const ContactUs = loadable(() => import(/* webpackChunkName: "brand" */'@pages/ContactUs'), {
  resolveComponent: (components) => components.ContactUs,
})

export function ContactUsAsync(): JSX.Element {
  
  return <ContactUs fallback={<PageLoader />} />

}
