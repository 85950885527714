
import React from 'react'

import loadable from '@loadable/component'

import(/* webpackPrefetch: true */ '@pages/FAQ')

const FAQ = loadable(() => import(/* webpackChunkName: "FAQ" */'@pages/FAQ'), {
  resolveComponent: (components) => components.FAQ,
})

export function FAQAsync(): JSX.Element {

  return <FAQ fallback={<div>Loading...</div>} />

}
