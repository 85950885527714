import React from 'react'

import styled from 'styled-components'

import { APP_DEFAULT_STATE } from '@api/local/AppPlugin'
import { ResponsiveImage } from '@atoms/images'
import { Heading } from '@atoms/typography'
import { ResponsivePXValue } from '@components/Theme'
import { useGetAppQuery, PageBlockFragment } from '@hooks/api/index'
import { DeviceTypeEnum } from '@uctypes/api/globalTypes'

import { HTML } from './HTML'

const Container = styled.div<{ backgroundColor: string }>`
  display: flex;
  width: 100%;
  ${ResponsivePXValue('height', { mobile: '', tablet: '252px', desktop: '252px' })}
  ${ResponsivePXValue('flex-direction', { mobile: 'column', tablet: 'row', desktop: 'row' })}
  ${ResponsivePXValue('gap', { mobile: '', tablet: '24px', desktop: '24px' })}
  ${ResponsivePXValue('align-items', { mobile: 'center', tablet: 'center', desktop: 'center' })};
  ${ResponsivePXValue('padding', { mobile: '16px 0px 0px 0px', tablet: '16px', desktop: '16px' })}
  background-color: ${({ backgroundColor }): string => backgroundColor};

`

const IconContainer = styled.div`
  ${ResponsivePXValue('width', { mobile: '150px', tablet: '280px', desktop: '280px' })}
  ${ResponsivePXValue('height', { mobile: '150px', tablet: '300px', desktop: '300px' })}
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${ResponsivePXValue('padding', { mobile: '12px', tablet: '', desktop: '' })}
  ${ResponsivePXValue('gap', { mobile: '16px', tablet: '12px', desktop: '12px' })}

`

export interface PageAwardBlockProps {
  block: PageBlockFragment
}

export function PageAwardBlock({ block }: PageAwardBlockProps): JSX.Element {

  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()
  const { MOBILE } = DeviceTypeEnum
  const { image, backgroundColor, description, foregroundColor, title } = block

  return (
    <Container backgroundColor={backgroundColor}>
      <IconContainer>
        <ResponsiveImage image={image} objectFit='fill' />
      </IconContainer>
      <ContentContainer>
        <Heading
          variant={appData.app.deviceType === MOBILE ? 'h3' : 'h1'}
          color={foregroundColor}
          align={appData.app.deviceType === MOBILE ? 'center' : 'left'}>
          {title}
        </Heading>
        <HTML align={appData.app.deviceType === MOBILE ? 'center' : 'left'}>
          {description}
        </HTML>
      </ContentContainer>
    </Container>
  )
}
