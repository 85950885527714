
import React from 'react'
import { Svg } from './Svg'

export interface RadioActiveProps {
  color?: string
  hoverColor?: string
  className?: string
}

export function RadioActive({ color, hoverColor, className }: RadioActiveProps): JSX.Element {

  return (
    <Svg strokeColor={color} strokeHoverColor={hoverColor} fillColor={color} fillHoverColor={hoverColor} className={className} width="100%" height="100%" viewBox="0 0 20 20" version="1.1">
      <g transform="matrix(0.751129,0,0,0.751129,3.99097,3.99097)">
        <path className='stroke' d="M8,12C5.791,12 4,10.209 4,8L-4,8C-4,14.627 1.373,20 8,20L8,12ZM12,8C12,10.209 10.209,12 8,12L8,20C14.627,20 20,14.627 20,8L12,8ZM8,4C10.209,4 12,5.791 12,8L20,8C20,1.373 14.627,-4 8,-4L8,4ZM8,-4C1.373,-4 -4,1.373 -4,8L4,8C4,5.791 5.791,4 8,4L8,-4Z" />
      </g>
    </Svg>
  )

}
    