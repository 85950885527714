
import React from 'react'

import styled from 'styled-components'

export const Svg = styled.svg<{ strokeColor?: string, strokeHoverColor?: string, fillColor?: string, fillHoverColor?: string }>`
  .stroke {
    stroke: ${(props): string => props.strokeColor};
    fill: none;
  }
  .fill {
    fill: ${(props): string => props.fillColor};
    stroke: none;
  }
  .fill-stroke {
    stroke: ${(props): string => props.strokeColor};
    fill: ${(props): string => props.fillColor};
  }
  &:hover {
    .stroke {
      stroke: ${(props): string => props.strokeHoverColor || props.strokeColor};
      fill: none;
    }
    .fill {
      fill: ${(props): string => props.fillHoverColor || props.fillColor};
      stroke: none;
    }
    .fill-stroke {
      stroke: ${(props): string => props.strokeHoverColor || props.strokeColor};
      fill: ${(props): string => props.strokeHoverColor || props.strokeColor};
    }
  }
`
