import React from 'react'

import styled from 'styled-components'

import { Skeleton } from '@atoms/layout/Skeleton'
import { ResponsivePXValue } from '@components/Theme'
import { ProductCard } from '@molecules/store/ProductCard'

const Container = styled.div`
  width: 100%;
  position: relative;
  ${ResponsivePXValue('overflow', { mobile: 'hidden' })}
  ${ResponsivePXValue('padding', { mobile: '12px 0 12px 16px', tablet: '0 ', desktop: '0' })}
  ${ResponsivePXValue('margin-top', { tablet: '8px', desktop: '8px' })}

  .mobile-all {
    ${ResponsivePXValue('margin', '16px 0 0')}
    ${ResponsivePXValue('width', 'CALC(100% - 16px)')}
  }
`

const Spacer = styled.div`
  position: relative;
`

const Header = styled.div`

  display: flex;
  align-items: center;
  ${ResponsivePXValue('mobile', { mobile: 'flex-start', tablet: 'space-between', desktop: 'space-between' })}
  ${ResponsivePXValue('height', { mobile: '56px', tablet: '56px', desktop: '56px' })}
  ${ResponsivePXValue('gap', '8px')}

  ${ResponsivePXValue('margin', { mobile: '0 0 6px', tablet: '0 0 6px', desktop: '0 0 16px 0' })}

  .seperator {
    flex-grow: 1;
    position: revert;
    ${ResponsivePXValue('display', { mobile: 'none', tablet: 'none', desktop: 'flex' })}
  }
  .filter-title {
    margin: 0;
    ${ResponsivePXValue('flex-shrink', { desktop: '0' })}
  }
  .see-all {
    flex-shrink: 0;
    ${ResponsivePXValue('display', { mobile: 'none', tablet: 'none', desktop: 'flex' })}
  }

`

const CardContainer = styled.div`
  position: relative;
  display: flex;
  ${ResponsivePXValue('gap', {
    mobile: '8px',
    tablet: '8px',
    desktop: '16px',
  })}
  overflow: hidden;
`

export interface ProductSliderSkeletonProps {
  productCount: number
}

export function ProductSliderSkeleton({ productCount }: ProductSliderSkeletonProps): JSX.Element {

  let cardIndex: number

  return (
    <Container>
      <Header>
        <Skeleton
          height={{ mobile: '30px', tablet: '30px', desktop: '30px' }}
          padding={{ mobile: '8px', tablet: '8px', desktop: '8px' }}
          color='gallery'
          direction='row'
          align='flex-start'/>
      </Header>
      <Spacer/>
      <CardContainer>
        <For each='cardIndex' of={Array.from(Array(productCount).keys())}>
          <ProductCard key={cardIndex} />
        </For>
      </CardContainer>
    </Container>
  )

}
