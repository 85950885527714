
import React from 'react'
import { Svg } from './Svg'

export interface ListViewProps {
  color?: string
  hoverColor?: string
  className?: string
}

export function ListView({ color, hoverColor, className }: ListViewProps): JSX.Element {

  return (
    <Svg strokeColor={color} strokeHoverColor={hoverColor} fillColor={color} fillHoverColor={hoverColor} className={className} width="100%" height="100%" viewBox="0 0 20 20">
      <rect x="1" y="1" width="18" height="8" className='fill' />
      <rect x="1" y="11" width="18" height="8" className='fill' />
    </Svg>
  )

}
    