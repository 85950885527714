import React from 'react'

import { Svg } from './Svg'

export interface PaymentAmericanExpressColorProps {
  color?: string
  hoverColor?: string
  className?: string
}

export function PaymentAmericanExpressColor({ color, hoverColor, className }: PaymentAmericanExpressColorProps): JSX.Element {

  return (
    <Svg strokeColor={color} strokeHoverColor={hoverColor} fillColor={color} fillHoverColor={hoverColor} className={className} width="100%" height="100%" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" keepColor="true">
      <path d="M0 0H13.9V7.5L13.2 8.60004L13.9 9.60004V14H0V6.90002L0.400002 6.40002L0 5.90002V0V0Z" fill={color || '#016FD0'} />
      <path d="M2.69922 9.60004V7.40002H4.99921L5.19922 7.70001L5.49921 7.40002H13.8992V9.40002C13.8992 9.40002 13.6992 9.50004 13.3992 9.60004H8.79922L8.49921 9.30005V9.60004H7.59922V9C7.59922 9 7.49922 9.10004 7.19922 9.10004H6.79922V9.60004H5.39922L5.19922 9.30005L4.89922 9.60004H2.69922Z" fill="white" />
      <path d="M0 5.80011L0.5 4.6001H1.4L1.7 5.30011V4.6001H2.8L3 5.1001L3.2 4.6001H8.2V4.80011C8.2 4.80011 8.5 4.6001 8.9 4.6001H10.5L10.8 5.30011V4.6001H11.7L12 5.00006V4.6001H12.9V6.80011H12L11.8 6.40009V6.80011H10.4L10.3 6.50006H9.9L9.8 6.80011H8.8C8.4 6.80011 8.2 6.6001 8.2 6.6001V6.80011H6.8L6.5 6.50006V6.80011H1.3L1.2 6.50006H0.800003L0.700005 6.80011H0.100006V5.80011H0Z" fill="white" />
      <path d="M0.700005 4.90002L0 6.5H0.5L0.600006 6.20001H1.4L1.5 6.5H2L1.3 4.90002H0.700005ZM0.900002 5.30005L1.10001 5.90002H0.600006L0.900002 5.30005Z" fill={color || '#016FD0'} className={color ? '' : 'fill'} />
      <path d="M2 6.5V4.90002H2.7L3.10001 6L3.5 4.90002H4.10001V6.5H3.7V5.30005L3.3 6.5H2.8L2.4 5.30005V6.5H2Z" fill={color || '#016FD0'} className={color ? '' : 'fill'} />
      <path d="M4.30078 6.5V4.90002H5.60078V5.30005H4.70078V5.60004H5.60078V5.90002H4.70078V6.20001H5.60078V6.60004H4.30078V6.5Z" fill={color || '#016FD0'} className={color ? '' : 'fill'} />
      <path d="M5.80078 4.90002V6.5H6.20078V5.90002H6.40078L6.90078 6.5H7.40078L6.90078 5.90002C7.10078 5.90002 7.30078 5.70002 7.30078 5.40002C7.30078 5.10002 7.00078 4.90002 6.70078 4.90002H5.80078ZM6.30078 5.20001H6.80078C6.90078 5.20001 7.00078 5.30002 7.00078 5.40002C7.00078 5.50002 6.90078 5.60004 6.80078 5.60004H6.30078V5.20001Z" fill={color || '#016FD0'} className={color ? '' : 'fill'} />
      <path d="M7.9 6.5H7.5V4.90002H7.9V6.5Z" fill={color || '#016FD0'} className={color ? '' : 'fill'} />
      <path d="M8.8996 6.5C8.3996 6.5 8.09961 6.20001 8.09961 5.70001C8.09961 5.20001 8.3996 4.90002 8.8996 4.90002H9.3996V5.30005H8.8996C8.6996 5.30005 8.4996 5.50001 8.4996 5.70001C8.4996 6.00001 8.6996 6.10004 8.8996 6.10004H8.9996L8.8996 6.5Z" fill={color || '#016FD0'} className={color ? '' : 'fill'} />
      <path d="M9.79961 4.90002L9.09961 6.5H9.59961L9.69961 6.20001H10.4996L10.5996 6.5H11.0996L10.3996 4.90002H9.79961ZM10.0996 5.30005L10.2996 5.90002H9.79961L10.0996 5.30005Z" fill={color || '#016FD0'} className={color ? '' : 'fill'} />
      <path d="M11.0996 6.5V4.90002H11.5996L12.2996 5.90002V4.90002H12.6996V6.5H12.1996L11.4996 5.40002V6.5H11.0996Z" fill={color || '#016FD0'} className={color ? '' : 'fill'} />
      <path d="M2.90039 9.40002V7.80005H4.20039V8.20001H3.30039V8.5H4.20039V8.80005H3.30039V9.10004H4.20039V9.5H2.90039V9.40002Z" fill={color || '#016FD0'} className={color ? '' : 'fill'} />
      <path d="M9.5 9.40002V7.80005H10.8V8.20001H9.9V8.5H10.8V8.80005H9.9V9.10004H10.8V9.5H9.5V9.40002Z" fill={color || '#016FD0'} className={color ? '' : 'fill'} />
      <path d="M4.29922 9.40002L4.89922 8.60004L4.19922 7.80005H4.69922L5.09922 8.30005L5.49921 7.80005H5.99921L5.29922 8.60004L5.99921 9.40002H5.49921L5.09922 8.90002L4.69922 9.40002H4.29922Z" fill={color || '#016FD0'} className={color ? '' : 'fill'} />
      <path d="M6.19922 7.70007V9.30011H6.59922V8.80011H6.99921C7.39921 8.80011 7.59922 8.60007 7.59922 8.20007C7.59922 7.90007 7.39921 7.6001 6.99921 7.6001H6.19922V7.70007ZM6.59922 8.1001H7.09922C7.19922 8.1001 7.29922 8.20011 7.29922 8.30011C7.29922 8.40011 7.19922 8.50006 7.09922 8.50006H6.59922V8.1001Z" fill={color || '#016FD0'} className={color ? '' : 'fill'} />
      <path d="M7.80078 7.69995V9.29999H8.20078V8.69995H8.40078L8.90078 9.29999H9.40078L8.90078 8.69995C9.10078 8.69995 9.30078 8.49995 9.30078 8.19995C9.30078 7.89995 9.00078 7.69995 8.70078 7.69995H7.80078ZM8.20078 8.09998H8.70078C8.80078 8.09998 8.90078 8.19999 8.90078 8.29999C8.90078 8.39999 8.80078 8.49994 8.70078 8.49994H8.20078V8.09998Z" fill={color || '#016FD0'} className={color ? '' : 'fill'} />
      <path d="M11.0004 9.40002V9H11.8004C11.9004 9 12.0004 8.90002 12.0004 8.90002C12.0004 8.80002 11.9004 8.80005 11.8004 8.80005H11.4004C11.1004 8.80005 10.9004 8.60005 10.9004 8.30005C10.9004 8.00005 11.1004 7.80005 11.5004 7.80005H12.3004L12.1004 8.20001H11.4004C11.3004 8.20001 11.2004 8.30005 11.2004 8.30005C11.2004 8.40005 11.3004 8.40002 11.4004 8.40002H11.8004C12.2004 8.40002 12.3004 8.60002 12.3004 8.90002C12.3004 9.20002 12.1004 9.40002 11.8004 9.40002H11.0004Z" fill={color || '#016FD0'} className={color ? '' : 'fill'} />
      <path d="M12.5004 9.40002V9H13.3004C13.4004 9 13.5004 8.90002 13.5004 8.90002C13.5004 8.80002 13.4004 8.80005 13.3004 8.80005H12.9004C12.6004 8.80005 12.4004 8.60005 12.4004 8.30005C12.4004 8.00005 12.6004 7.80005 13.0004 7.80005H13.8004L13.6004 8.20001H12.9004C12.8004 8.20001 12.7004 8.30005 12.7004 8.30005C12.7004 8.40005 12.8004 8.40002 12.9004 8.40002H13.3004C13.7004 8.40002 13.8004 8.60002 13.8004 8.90002C13.8004 9.20002 13.6004 9.40002 13.3004 9.40002H12.5004Z" fill={color || '#016FD0'} className={color ? '' : 'fill'} />
    </Svg>
  )

}
