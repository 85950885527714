import React from 'react'

import styled, { useTheme } from 'styled-components'

import { Icon, LocalIconEnums } from '@atoms/index'
import { ResponsivePXValue } from '@components/Theme'

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  ${ResponsivePXValue('padding', '30px')}
`

const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  border-radius: 5%;
  width: 100%;

  ${ResponsivePXValue('max-width', '150px')}
  ${ResponsivePXValue('max-height', '150px')}
  ${ResponsivePXValue('padding', '30px')}

`

const SpinnerContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  border-radius: 5%;
  width: 100%;

  ${ResponsivePXValue('max-width', '180px')}
  ${ResponsivePXValue('max-height', '180px')}
`

export interface LoaderProps {
  color?: string
  className?: string
}

export function Loader({ color, className }: LoaderProps): JSX.Element {

  const theme = useTheme()
  const actualColor = color || theme.colors.grey.gunSmoke

  return (
    <Container className={className}>
      <IconContainer>
        <Icon icon={LocalIconEnums.LOGO} color={actualColor} />
      </IconContainer>
      <SpinnerContainer>
        <svg width="100%" height="100%" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
          <circle cx="50" cy="50" fill="none" stroke={actualColor} strokeWidth="2" r="37" strokeDasharray="174.35839227423352 60.119464091411174">
            <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
          </circle>
        </svg>
      </SpinnerContainer>
    </Container>

  )

}
