import React from 'react'

import styled from 'styled-components'

import { APP_DEFAULT_STATE } from '@api/local/AppPlugin'
import { LayoutGrid, LayoutRow, LayoutCol } from '@atoms/layout'
import { UltraContainer } from '@atoms/misc'
import { ResponsivePXValue } from '@components/Theme'
import { BreadcrumbFragment, ProductDetailsFragment, useGetAppQuery } from '@hooks/api'
import { UpSellWidget } from '@molecules/store'
import { AddSimpleProductToCart } from '@molecules/store/AddSimpleProductToCart'
import { ProductBreadCrumbs, ProductShare } from '@organisms/product'
import { MobileOSTypeEnum } from '@uctypes/api/globalTypes'
import { DeviceContainer } from '@utility/DeviceContainer'

import { ProductInformation } from './ProductInformation'

const Sticky = styled.div`
  position: sticky;
  ${ResponsivePXValue('top', '-50px')}
  ${ResponsivePXValue('margin-bottom', '16px')}
`

const Fixed = styled.div<{ bottom?: string }>`
  position: fixed;
  left: 0;
  width: 100%;
  z-index: 29;
  ${props => (props.bottom) ? ResponsivePXValue('bottom', props.bottom) : 0};
`

const StickyContainer = styled.div`
  position: relative;
  
  ${ResponsivePXValue('height', '100%')}
`

export interface SimpleProductDetailsProps {
  product: ProductDetailsFragment
  loading: boolean
  onBack: () => void
  overwriteBreadCrumbs?: BreadcrumbFragment[]
}

export function SimpleProductDetails({ product, loading, onBack, overwriteBreadCrumbs }: SimpleProductDetailsProps): JSX.Element {

  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()

  return (
    <UltraContainer>
      <LayoutGrid>
        <LayoutRow>
          <LayoutCol span={{ mobile: 10, tablet: 10, desktop: 9 }}>
            <ProductBreadCrumbs
              categories={product?.categories}
              breadcrumbs={overwriteBreadCrumbs}
              productName={product?.name}
              goBack={onBack}
            />
            <ProductInformation product={product} />
          </LayoutCol>
          <LayoutCol span={{ mobile: 0, tablet: 0, desktop: 3 }}>
            <StickyContainer>
              <Sticky>
                <ProductShare />
                <Choose>
                  <When condition={!!product}>
                    <AddSimpleProductToCart
                      product={product}
                      loading={loading} />
                    <UpSellWidget />
                  </When>
                </Choose>
              </Sticky>
            </StickyContainer>
          </LayoutCol>
          <DeviceContainer mobile>
            <Fixed bottom={(appData.app.mobileOSType === MobileOSTypeEnum.APPLE) ? '72px' : '56px'} className='gonative-ios-push'>
              <Choose>
                <When condition={!!product}>
                  <AddSimpleProductToCart
                    product={product}
                    loading={loading} />
                </When>
              </Choose>
            </Fixed>
          </DeviceContainer>
        </LayoutRow>
      </LayoutGrid>
    </UltraContainer>
  )

}
