
import React from 'react'
import { Svg } from './Svg'

export interface MinusProps {
  color?: string
  hoverColor?: string
  className?: string
}

export function Minus({ color, hoverColor, className }: MinusProps): JSX.Element {

  return (
    <Svg strokeColor={color} strokeHoverColor={hoverColor} fillColor={color} fillHoverColor={hoverColor} className={className} width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5 12H19" className='stroke' strokeWidth="2" strokeLinecap="square" strokeLinejoin="round" />
    </Svg>
  )

}
    