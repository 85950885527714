import React from 'react'

import RCForm, { FormInstance } from 'rc-field-form'
import { FormProps as RCFormProps } from 'rc-field-form/es/Form'
import styled from 'styled-components'
export * from 'rc-field-form/lib/interface'

export type FormValue = string | number | boolean | null

const KeyDownContainer = styled.div``

export interface FormInputProps {
  value?: FormValue | FormValue[]
  label: string
  showLabel?: boolean
  name?: string
  error?: string
  required?: boolean
  loading?: boolean
  disabled?: boolean
  onChange?: (value?: FormValue | FormValue[]) => void
}

interface ExtendedFormInstance<T> extends FormInstance<T> {
  focus: (fieldName: string) => void
}

export function useForm<T = any>(): ExtendedFormInstance<T> {
  const [form] = RCForm.useForm<T>() as unknown as ExtendedFormInstance<T>[]
  return form
}

export interface FormContextProperties {
  loading?: boolean
  disabled: boolean
  registerRef?: (fieldName: string, ref: React.RefObject<any>) => void
}

export const FormContext = React.createContext({ loading: false, disabled: false, registerRef: undefined })

export interface FormProps<T = any> extends RCFormProps {
  loading?: boolean
  disabled?: boolean
  children: React.ReactNode
  form: ExtendedFormInstance<T>
}

export function Form({ loading, disabled, children, ...rest }: FormProps): JSX.Element {

  const refs: { [k: string]: React.RefObject<any> } = {}

  rest.form.focus = (fieldName: string): void => {
    refs[fieldName]?.current?.focus?.()
  }

  const registerRef = (fieldName: string, ref: React.RefObject<any>): void => {
    refs[fieldName] = ref
  }

  const handleKeyDown = (e: React.KeyboardEvent): void => {
    if (e.key === "Tab") {
      const form = e.currentTarget
      const inputs = Array.from(form.querySelectorAll('input'))
      const index = inputs.indexOf(document.activeElement as HTMLInputElement)
      if (index === inputs.length - 1) {
        e.preventDefault();
        inputs[0].focus();
      }
    }
  }

  return (
    <RCForm {...rest} >
      <FormContext.Provider value={{ loading, disabled, registerRef }}>
        <KeyDownContainer onKeyDown={handleKeyDown}>
          {children}
        </KeyDownContainer>
      </FormContext.Provider>
    </RCForm>
  )

}
