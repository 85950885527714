import React from 'react'

import styled, { useTheme } from 'styled-components'

import { DeviceTypeEnum } from '@uctypes/api/globalTypes'

const Container = styled.div`
  flex-grow: 1;
  position: relative;
  width: 100%;
  height: 100%;
  .lazyload-wrapper {
    width: 100%;
    height: 100%;
  }
`

const PictureElement = styled.picture<{ objectFit: string, aspectRatio?: string }>`
  width: 100%;
  height: 100%;
  object-fit: ${(props): string => (props.objectFit ? props.objectFit : 'cover')};
  backface-visibility: hidden;
  transition: opacity 0.3s ease-in;
  aspect-ratio: ${(props): string => props.aspectRatio};
`

const ImageElement = styled.img<{ objectFit: string, aspectRatio?: string }>`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: ${(props): string => (props.objectFit ? props.objectFit : 'cover')};
  backface-visibility: hidden;
  aspect-ratio: ${(props): string => props.aspectRatio};
`

const Source = styled.source``

export interface ResponsiveImageData {
  mobile: string
  desktop: string
  tablet: string
  fallback: string
  alt: string
  skeleton?: boolean
}

export interface ResponsiveImageProps {
  location?: string
  image?: ResponsiveImageData
  objectFit?: string
  lazy?: boolean
  aspectRatio?: string
}

export function ResponsiveImage({ image, location, objectFit, aspectRatio, lazy = false }: ResponsiveImageProps): JSX.Element {

  const theme = useTheme()

  if (location) {
    return (
      <ImageElement
        src={location}
        alt='FTN'
        aspectRatio={aspectRatio}
        objectFit={objectFit} />
    )
  }

  if (typeof window !== 'undefined' && window.forcedRenderingDevice && image) {
    let imageSrc
    switch (window.forcedRenderingDevice) {
      case DeviceTypeEnum.MOBILE:
        imageSrc = image?.mobile
        break
      case DeviceTypeEnum.TABLET:
        imageSrc = image?.tablet
        break
      case DeviceTypeEnum.DESKTOP:
        imageSrc = image?.desktop
        break

      default:
        break
    }
    return (
      <Container>
        <PictureElement
          aspectRatio={aspectRatio}
          objectFit={objectFit}>
          <Source
            type='image/webp'
            srcSet={`${imageSrc}`} />
          <ImageElement
            src={image?.fallback}
            alt={image?.alt ?? 'FTN'}
            aspectRatio={aspectRatio}
            objectFit={objectFit} />
        </PictureElement>
      </Container>
    )
  }

  if (image) {
    return (
      <Container>
        <PictureElement
          aspectRatio={aspectRatio}
          objectFit={objectFit}>
          <Source
            type='image/webp'
            srcSet={`${image.mobile}`}
            media={`(max-width: ${theme.mobileImage}px)`} />
          <Source
            type='image/webp'
            srcSet={`${image.tablet}`}
            media={`(max-width: ${theme.tabletImage}px)`} />
          <Source
            type='image/webp'
            srcSet={`${image.desktop}`} />
          <ImageElement
            src={image.fallback}
            alt={image.alt ?? 'FTN'}
            aspectRatio={aspectRatio}
            objectFit={objectFit} />
        </PictureElement>
      </Container>
    )
  }

  return null
}
