import React from 'react'

import styled, { useTheme } from 'styled-components'

import { Button } from '@atoms/buttons'
import { ResponsiveImage } from '@atoms/images'
import { Divider, Skeleton, SkeletonNode } from '@atoms/layout'
import { Link, Paragraph, Title } from '@atoms/typography'
import { ResponsivePXValue } from '@components/Theme'
import { PageBannerFragment, useGetAppQuery } from '@hooks/api/index'
import { SiteHelper } from '@lib/SiteHelper'
import { DeviceTypeEnum, PageBannerLayoutEnum, PageLink } from '@uctypes/api/globalTypes'
import { DeviceContainer } from '@utility/DeviceContainer'

import { HTML } from './HTML'

const Container = styled.div<{ layout: PageBannerLayoutEnum, bgColor: string }>`
  ${ResponsivePXValue('position', { tablet: 'relative', desktop: 'relative' })}
  width: 100%;
  ${ResponsivePXValue('height', { mobile: '144px', tablet: '568px', desktop: '568px' })}
  display: flex;
  ${(props): string => props.layout === PageBannerLayoutEnum.IMAGE_LEFT 
  ? ResponsivePXValue('flex-direction', { mobile: 'row', tablet: 'column', desktop: 'column' }) 
  : ResponsivePXValue('flex-direction', { mobile: 'row-reverse', tablet: 'column', desktop: 'column' })}

  ${ResponsivePXValue('flex-direction', { mobile: 'row', tablet: 'column', desktop: 'column' })}
  ${ResponsivePXValue('align-items', { mobile: 'center', tablet: '', desktop: '' })}
  background-color: ${(props): string => props.bgColor};
`

const ImageContainer = styled.div`
  ${ResponsivePXValue('width', { mobile: '140px', tablet: '100%', desktop: '100%' })}
  ${ResponsivePXValue('height', { mobile: '100%', desktop: '100%' })}

  flex-shrink: 0;

`

const ContentContainer = styled.div<{ backgroundColor: string, foregroundColor: string, layout: PageBannerLayoutEnum, isMobile: boolean }>`
  ${ResponsivePXValue('position', { desktop: 'absolute' })}
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
  
  ${(props): string => props.layout === PageBannerLayoutEnum.IMAGE_RIGHT ? ResponsivePXValue('left', { tablet: '0', desktop: '0' }) : ResponsivePXValue('right', { tablet: '0', desktop: '0' })}
  ${(props): string => props.layout === PageBannerLayoutEnum.IMAGE_RIGHT ? ResponsivePXValue('left', { tablet: '0', desktop: '0' }) : ResponsivePXValue('right', { tablet: '0', desktop: '0' })}
  ${ResponsivePXValue('width', { desktop: '400px' })}
  ${ResponsivePXValue('height', { mobile: 'fit-content', desktop: '488px' })}
  ${ResponsivePXValue('margin', { desktop: '40px' })}
  ${ResponsivePXValue('padding', { mobile: '12px', tablet: '24px', desktop: '24px' })}
  ${ResponsivePXValue('gap', { mobile: '', desktop: '40px' })}
  color: ${(props): string => props.foregroundColor};
  background-color: ${(props): string => props.backgroundColor};
  ${ResponsivePXValue('border-radius', { mobile: '4px' })}
  ${ResponsivePXValue('flex', { mobile: '1', tablet: '', desktop: '' })}

  .mobileTitle {
    ${ResponsivePXValue('margin-bottom', { mobile: '8px', tablet: '', desktop: '' })}
  }

  .divider {
    ${(props): string => props.isMobile 
    && ResponsivePXValue('display', { mobile: 'none' })}
  }

`

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${ResponsivePXValue('gap', '8px')}
`

export interface PageFullImageBannerProps {
  banner: PageBannerFragment
}

export function PageFullImageBanner({ banner }: PageFullImageBannerProps): JSX.Element {

  const theme = useTheme()
  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()
  const { MOBILE } = DeviceTypeEnum
  const secondaryBackgroundColor = banner.secondaryBackgroundColor || theme.colors.white.pureWhite


  if (!banner) {
    return (
      <Skeleton color='pureWhite' justify='flex-start' padding={{ mobile: '10px', tablet: '24px', desktop: '24px' }} gap='24px'>
        <SkeletonNode
          color='gallery'
          shape='rounded'
          height={{ mobile: '320px', tablet: '488px', desktop: '400px' }}
          width={{ mobile: '100%', tablet: '488px', desktop: '400px' }}
        />
        <Skeleton loop={8} direction='column' justify='space-between' align='flex-start'>
          <SkeletonNode
            color='gallery'
            shape='rounded'
            height={{ mobile: '24px', tablet: '24px', desktop: '24px' }}
            variWidth={{ mobile: '50%-90%', tablet: '50%-90%', desktop: '50%-90%' }}/>
        </Skeleton>
      </Skeleton>
    )
  }

  const buttonColors = {
    color: banner.foregroundColor,
    backgroundColor: banner.backgroundColor,
    borderColor: banner.foregroundColor,
    hoverColor: banner.foregroundColor,
    hoverBackgroundColor: banner.backgroundColor,
    hoverBorderColor: banner.foregroundColor,
    disabledColor: SiteHelper.getOpaqueColor(banner.foregroundColor, 0.45),
    disabledBackgrondColor: banner.backgroundColor,
    disabledBorderColor: SiteHelper.getOpaqueColor(banner.foregroundColor, 0.45),
  }

  let link: PageLink
  let index: number
  
  const titleAlignment = banner.titleAlignment === 'LEFT'
    ? 'left'
    : banner.titleAlignment === 'RIGHT'
      ? 'right'
      :'center'

  const subTtitleAlignment = banner.subTitleAlignment === 'LEFT'
    ? 'left'
    : banner.subTitleAlignment === 'RIGHT'
      ? 'right'
      : 'center'

  return (
    <Container layout={banner.layout} bgColor={secondaryBackgroundColor}>
      <ImageContainer>
        <DeviceContainer desktop tablet>
          <ResponsiveImage image={banner.image} objectFit='cover' />
        </DeviceContainer>
        <DeviceContainer mobile>
          <ResponsiveImage image={banner.mobileImage ? banner.mobileImage : banner.image} objectFit='cover' />
        </DeviceContainer>
      </ImageContainer>
      <ContentContainer foregroundColor={banner.foregroundColor} backgroundColor={banner.backgroundColor} layout={banner.layout} isMobile={appData.app.deviceType === MOBILE}>
        <DeviceContainer desktop tablet>
          <Title variant='t1' className='title' align={titleAlignment} color={banner.foregroundColor}>{banner.title}</Title>
        </DeviceContainer>
        <DeviceContainer mobile>
          <Paragraph variant='p2' color={banner.foregroundColor} align={titleAlignment} className='mobileTitle' bold>{banner.title}</Paragraph>
        </DeviceContainer>
        <Divider color={banner.foregroundColor} className='divider'/>

        <If condition={!!banner.subTitle}>
          <DeviceContainer desktop tablet>
            <Title variant='t8' className='sub-title' align={subTtitleAlignment} color={banner.foregroundColor}>{banner.subTitle}</Title>
          </DeviceContainer>
        </If>
        <If condition={!!banner.description}>
          <HTML color={banner.foregroundColor}>
            {banner.description}
          </HTML>
        </If>
        <ButtonContainer>
          <DeviceContainer desktop tablet>
            <For each='link' index='index' of={banner.links || []}>
              <Button title={link.title} href={link.url} key={index} colors={buttonColors} />
            </For>
          </DeviceContainer>
          <DeviceContainer mobile>
            <For each='link' index='index' of={banner.links || []}>
              <Link href={link.url} key={index} decoration='underline' color={banner.foregroundColor}>
                {link.title}
              </Link>
            </For>
          </DeviceContainer>
        </ButtonContainer>
      </ContentContainer>
    </Container>
  )

}
