import React from 'react'

import styled, { useTheme } from 'styled-components'

import { Heading, Link, ResponsiveImage, ResponsiveImageData } from '@atoms/index'
import { ResponsivePXValue } from '@components/Theme'

const Container = styled.div`
  ${ResponsivePXValue('width', { mobile: '100px', tablet: '184px', desktop: '184px' })}
  ${ResponsivePXValue('border-radius', '2px')}

  display: flex;
  flex-grow: 0;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
`

const ImageContainer = styled.div<{$imageBackgroundColor: string}>`
  ${ResponsivePXValue('width', { mobile: '100px', tablet: '184px', desktop: '184px' })}
  ${ResponsivePXValue('height', { mobile: '100px', tablet: '150px', desktop: '150px' })}
  ${ResponsivePXValue('padding', { mobile: '16px', tablet: '24px', desktop: '24px' })}

  flex-shrink: 0;
  background: ${(props): string => props.$imageBackgroundColor || props.theme.colors.white.pureWhite};
`

const HeadingContainer = styled.div<{ $backgroundColor: string }>`
  width: 100%;
  flex-grow: 1;
  display:flex;
  align-items: center;
  justify-content: center;

  .link-style{
    white-space: pre-wrap;
    font-family: open-sans;
    font-weight: 700;
  }

  background: ${(props): string => props.$backgroundColor ? props.$backgroundColor : props.theme.colors.white.pureWhite};

  ${ResponsivePXValue('padding', '16px 0')}
`
export interface IconCardProps {
  title: string
  image: ResponsiveImageData
  titleColour?: string
  hoverColour?: string
  backgroundColor?: string
  href:string
}

export function IconCard({ title, image, titleColour = '', backgroundColor, href, hoverColour }: IconCardProps): JSX.Element {

  const theme = useTheme()

  if (!titleColour) {
    titleColour = theme.colors.green.bottleGreen
  }

  if (!hoverColour) {
    hoverColour = theme.colors.blue.curiousBlue
  }

  return (
    <Container>
      <ImageContainer $imageBackgroundColor={backgroundColor}>
        <ResponsiveImage image={image} objectFit='contain' />
      </ImageContainer>
      <HeadingContainer $backgroundColor={backgroundColor}>
        <Heading variant='h5' align='center' color={titleColour}>
          <Choose>
            <When condition={!!href}>
              <Link href={href}
                color={titleColour}
                hoverColor={hoverColour}
                bold={true}
                className={'link-style'}>{title}
              </Link>
            </When>
            <Otherwise>
              {title}
            </Otherwise>
          </Choose>
        </Heading>
      </HeadingContainer>
    </Container>
  )
}
