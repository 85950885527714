
import React from 'react'
import { Svg } from './Svg'

export interface PointsOutlineProps {
  color?: string
  hoverColor?: string
  className?: string
}

export function PointsOutline({ color, hoverColor, className }: PointsOutlineProps): JSX.Element {

  return (
    <Svg strokeColor={color} strokeHoverColor={hoverColor} fillColor={color} fillHoverColor={hoverColor} className={className} width="100%" height="100%" viewBox="0 0 89 88" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M75.553 42.614L45.853 12.914C44.665 11.726 43.015 11 41.2 11H18.1C14.47 11 11.5 13.97 11.5 17.6V40.7C11.5 42.515 12.226 44.165 13.447 45.386L43.147 75.086C44.335 76.274 45.985 77 47.8 77C49.615 77 51.265 76.274 52.453 75.053L75.553 51.953C76.774 50.765 77.5 49.115 77.5 47.3C77.5 45.485 76.741 43.802 75.553 42.614ZM47.8 70.433L18.1 40.7V17.6H41.2V17.567L70.9 47.267L47.8 70.433Z" className='fill' />
      <path d="M25.5235 29.9734C28.2573 29.9734 30.4735 27.7572 30.4735 25.0234C30.4735 22.2896 28.2573 20.0734 25.5235 20.0734C22.7897 20.0734 20.5735 22.2896 20.5735 25.0234C20.5735 27.7572 22.7897 29.9734 25.5235 29.9734Z" className='fill' />
      <path d="M33.4463 44.99C33.4463 46.871 34.2053 48.521 35.4263 49.775L46.9763 61.325L58.5263 49.775C59.696 48.4865 60.3251 46.7974 60.2831 45.0576C60.2411 43.3179 59.5313 41.6611 58.3008 40.4305C57.0702 39.2 55.4134 38.4902 53.6737 38.4482C51.9339 38.4062 50.2448 39.0353 48.9563 40.205L46.9763 42.185L44.9963 40.238C43.7753 38.984 42.0593 38.225 40.2113 38.225C36.4823 38.225 33.4463 41.261 33.4463 44.99Z" className='fill' />
    </Svg>
  )

}
    