import React, { useState, useEffect } from 'react'

import { sentenceCase } from 'change-case'
import styled from 'styled-components'

import { Heading, Paragraph } from '@atoms/typography'
import { ResponsivePXValue } from '@components/Theme'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export interface UnknownSectionProps {
  sectionType: string
}

export function UnknownSection({ sectionType }: UnknownSectionProps): JSX.Element {

  return (
    <Container>
      <Heading>Unknown Section Type {sentenceCase(sectionType)}</Heading>
      <Paragraph>Please create a component for this section type (<strong>{sectionType}</strong>)</Paragraph>
    </Container>
  )

}
