
import React from 'react'
import { Svg } from './Svg'

export interface HeartOutlineProps {
  color?: string
  hoverColor?: string
  className?: string
}

export function HeartOutline({ color, hoverColor, className }: HeartOutlineProps): JSX.Element {

  return (
    <Svg strokeColor={color} strokeHoverColor={hoverColor} fillColor={color} fillHoverColor={hoverColor} className={className} width="100%" height="100%" viewBox="0 0 20 19" version="1.1">
      <g transform="matrix(0.902395,0,0,0.902395,-0.828735,-1.72874)">
        <path className='fill' d="M18.717,3.539C17.412,2.892 15.913,2.828 14.478,3.345C13.435,3.733 12.587,4.38 11.935,5.22C11.283,4.38 10.435,3.733 9.392,3.345C8.087,2.828 6.588,2.892 5.218,3.539C3.784,4.186 2.74,5.35 2.284,6.837C1.893,8.066 1.697,10.006 3.132,12.528C4.631,15.18 6.848,17.184 11.544,20.871C11.674,20.935 11.804,21 12,21C12.13,21 12.326,20.935 12.457,20.871C17.217,17.12 19.434,15.18 20.869,12.528C22.303,10.006 22.107,8.001 21.716,6.837C21.195,5.35 20.151,4.186 18.717,3.539ZM18.13,4.768C19.173,5.285 20.021,6.126 20.347,7.225C20.803,8.583 20.542,10.2 19.63,11.881C18.325,14.274 16.173,16.15 12,19.513C7.761,16.15 5.675,14.274 4.371,11.881C3.458,10.2 3.197,8.648 3.653,7.225C3.979,6.126 4.762,5.285 5.87,4.768C6.457,4.509 7.044,4.38 7.631,4.38C8.087,4.38 8.609,4.444 9.066,4.638C10.109,5.026 10.957,5.802 11.478,6.837C11.609,7.096 11.804,7.225 12.13,7.225C12.391,7.225 12.652,7.096 12.783,6.837C13.239,5.802 14.087,5.026 15.195,4.638C16.043,4.25 17.152,4.315 18.13,4.768Z" />
      </g>
    </Svg>
  )

}
    