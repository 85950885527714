import React from 'react'

import styled from 'styled-components'
import { v4 } from 'uuid'

import { CheckoutPlugin } from '@api/local/CheckoutPlugin'
import { Title } from '@atoms/index'
import { ResponsivePXValue } from '@components/Theme'
import { CheckoutFragment, CreditCardInfoFragment } from '@hooks/api/index'
import { AddCreditCardForm, AddCreditCardFormData } from '@molecules/forms'
import { PaymentStepEnum } from '@uctypes/api/globalTypes'

const Container = styled.div`
  width: 100%;
  background-color: ${(props): string => props.theme.colors.white.pureWhite};
`

const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${ResponsivePXValue('padding', { mobile: '24px', tablet: '24px', desktop: '24px' })}
`

const FormContainer = styled.div`
  ${ResponsivePXValue('padding', { mobile: '0 24px', tablet: '0 24px', desktop: '0 24px' })}
`

export interface AddCreditCardProps {
  onCancel: () => void
  onSuccess: (card: CreditCardInfoFragment) => void
  onContinue: () => void
}

export function AddCreditCard({ onCancel, onSuccess, onContinue }: AddCreditCardProps): JSX.Element {

  const _handleAddSuccess = (creditCardInfo: CreditCardInfoFragment | null): void => {
    if (creditCardInfo) {
      onSuccess(creditCardInfo)
    }
  }

  return (
    <Container>
      <Header>
        <Title variant='t3'>
          New Credit Card
        </Title>
      </Header>
      <FormContainer>
        <AddCreditCardForm
          onCancel={onCancel}
          onSuccess={_handleAddSuccess}
          onContinue={onContinue} />
      </FormContainer>
    </Container>
  )

}
