import React from 'react'

import { Svg } from './Svg'

export interface PaymentEftColorProps {
  color?: string
  hoverColor?: string
  className?: string
}

export function PaymentEftColor({ color, hoverColor, className }: PaymentEftColorProps): JSX.Element {

  return (
    <Svg strokeColor={color} strokeHoverColor={hoverColor} fillColor={color} fillHoverColor={hoverColor} className={className} width="100%" height="100%" viewBox="0 0 40 40" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" space="preserve" serif="http://www.serif.com/" style={{ fillRule: 'evenodd', clipRule: 'evenodd', strokeLinejoin: 'round', strokeMiterlimit: '2' }} keepColor="true">
      <g transform="matrix(0.317625,0,0,0.253011,-70.7624,-4.83107)">
        <g id="Layer1">
          <path d="M236.753,66.523L225.934,129.565L257.761,129.707L260.645,113.34L244.667,113.149L245.584,106.265L253.67,106.045L256.546,90.488L249.151,90.252L249.868,83.149L265.145,82.865L267.728,66.657L236.753,66.523Z" fill={color || '#204B4A'} className={color ? '' : 'fill'} />
        </g>
      </g>
      <g id="Layer2" transform="matrix(0.525945,0,0,0.466631,-79.9132,-19.0783)">
        <g transform="matrix(0.603914,0,0,0.542209,17.3986,30.5321)">
          <path d="M272.338,66.95L262.069,128.903L277.905,129.165L281.744,106.896L289.81,106.785L292.746,90.458L284.571,90.436L285.721,82.821L301.258,82.905L304.048,67.155L272.338,66.95Z" fill={color || '#204B4A'} className={color ? '' : 'fill'} />
        </g>
        <g transform="matrix(0.603914,0,0,0.542209,17.3986,30.5321)">
          <path d="M307.584,66.648L304.913,82.826L315.184,83.4L307.895,129.36L323.918,129.761L331.645,83.694L342.68,83.552L345.572,66.728L307.584,66.648Z" fill={color || '#204B4A'} className={color ? '' : 'fill'} />
        </g>
      </g>
    </Svg>
  )

}
