import React from 'react'

import styled, { useTheme } from 'styled-components'

import { Button, ButtonColors, Heading, Paragraph, Message, LocalIconEnums } from '@atoms/index'
import { ResponsivePXValue } from '@components/Theme'
import { TextInput, Form, useForm, LogInModalDisplayType, LoginModalDisplayStatus } from '@molecules/index'

const Container = styled.div`
  width: 100%;

  .form-button {
    width: 100%;
    ${ResponsivePXValue('margin', '12px 0 0 0')}
  }

  .title {
    ${ResponsivePXValue('margin', '0 0 14px 0')}
  }
  .join {
    ${ResponsivePXValue('margin-bottom', '14px')}
  }

  .input {
    ${ResponsivePXValue('margin-bottom', '9px')}
  }

  .footer {
    ${ResponsivePXValue('padding', '24px 0 0 24px')}
  }
`

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${ResponsivePXValue('gap', '6px')}
  ${ResponsivePXValue('height', '68px')}
  ${ResponsivePXValue('margin-bottom', '-24px')}
`

export interface ResetEmailData {
  email: string
}

export interface ForgotPasswordData {
  email: string
  password: string
  rePassword: string
  token: string
}

export interface ForgotPasswordFormProps {
  onRequestResetPassword: (data: ResetEmailData) => void
  onResetPassword: (data: ForgotPasswordData) => void
  onSetDisplayType: (displayType: LogInModalDisplayType) => void
  displayStatus: LoginModalDisplayStatus
  errorMessage?: string
  token: string
}

export function ForgotPasswordForm({ onRequestResetPassword, onResetPassword, onSetDisplayType, displayStatus, errorMessage, token }: ForgotPasswordFormProps): JSX.Element {

  const form = useForm()
  const theme = useTheme()

  const btnColors: ButtonColors = {
    color: theme.colors.white.pureWhite,
    backgroundColor: theme.colors.black.pureBlack,
    borderColor: theme.colors.black.pureBlack,
    // Hover
    hoverColor: theme.colors.white.pureWhite,
    hoverBackgroundColor: theme.colors.black.pureBlack,
    hoverBorderColor: theme.colors.black.pureBlack,
    // Disable
    disabledColor: theme.colors.white.pureWhite,
    disabledBackgrondColor: theme.colors.grey.athens,
  }

  const buttonTitle = displayStatus === LoginModalDisplayStatus.INPUT || displayStatus === LoginModalDisplayStatus.LOADING
    ? 'SEND'
    : ''
  const buttonIcon = displayStatus === LoginModalDisplayStatus.SUCCESS
    ? LocalIconEnums.CHECK
    : false

  const loading = displayStatus === LoginModalDisplayStatus.LOADING
  const disabled = displayStatus === LoginModalDisplayStatus.SUCCESS

  return (
    <Container>
      <Heading className="title" variant='h3' >Forgot your password?</Heading>
      <Paragraph className="join" variant='p2' align='left'>{'Enter your email address and we\'ll send you a link to reset your password.'}</Paragraph>
      <Choose>
        <When condition={!!token}>
          <Form form={form} onFinish={(data) => onResetPassword({ ...data, token })} loading={loading} disabled={disabled}>
            <TextInput
              variant='email'
              showLabel
              label='Email address'
              name='email'
              placeholder='Email address'
              rules={[{ required: true, message: 'Please enter an email address' }]}
              wrapperClassName="input" />
            <TextInput
              variant='password'
              showLabel
              label='Password'
              name='password'
              placeholder='Password'
              rules={[{ required: true, message: 'Please enter an new password' }]}
              wrapperClassName="input" />
            <TextInput
              variant='password'
              showLabel
              label='Confirm password'
              name='rePassword'
              placeholder='Confirm password'
              rules={[{ required: true, message: 'Please confirm new password' }]}
              className="input" />
            <If condition={displayStatus === LoginModalDisplayStatus.ERROR}>
              <Message
                wrapperClassName='messageClass'
                backgroundColor={theme.colors.pink.bridesmaid}
                color={theme.colors.red.cinnabar}
                message={errorMessage}></Message>
            </If>
            <Button
              loading={loading}
              variant='primary'
              colors={btnColors}
              className='form-button'
              title={buttonTitle}
              icon={buttonIcon}
              onClick={() => disabled ? null : form.submit()} />
          </Form>
        </When>
        <Otherwise>
          <Form form={form} onFinish={onRequestResetPassword} loading={loading} disabled={disabled}>
            <TextInput
              variant='email'
              showLabel
              label='Email address'
              name='email'
              placeholder='Email address'
              rules={[{ required: true, message: 'Please enter an email address' }]}
              wrapperClassName="input" />
            <If condition={displayStatus === LoginModalDisplayStatus.ERROR}>
              <Message
                wrapperClassName='messageClass'
                backgroundColor={theme.colors.pink.bridesmaid}
                color={theme.colors.red.cinnabar}
                message={errorMessage} />
            </If>
            <Button
              loading={loading}
              variant='primary'
              colors={btnColors}
              className='form-button'
              title={buttonTitle}
              icon={buttonIcon}
              onClick={() => disabled ? null : form.submit()} />
          </Form>
        </Otherwise>
      </Choose>
      <Footer>
        <Paragraph display='inline' align='center' variant='p2'>Go back to </Paragraph>
        <Button
          disabled={loading || disabled}
          variant='text'
          title='Login'
          size='medium'
          className='text-button'
          onClick={() => onSetDisplayType(LogInModalDisplayType.LOG_IN)} />
      </Footer>
    </Container>
  )

}
