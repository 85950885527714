import { useState, useEffect, useRef, RefObject } from 'react'

export function useClickOutside<T>(initialIsVisible: boolean): { ref: RefObject<T>, isOutside: boolean, shouldMonitor: (monito: boolean) => void } {

  const [isOutside, shouldMonitor] = useState(initialIsVisible)
  const ref = useRef(null)

  const handleClickOutside = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target)) {
      shouldMonitor(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [])

  return { ref, isOutside: !isOutside, shouldMonitor }
}
