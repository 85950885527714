import React, { useState, useEffect } from 'react'

import update from 'react-addons-update'
import styled from 'styled-components'

import { Button, Message, Paragraph, ResponsiveImage, Title } from '@atoms/index'
import { ResponsivePXValue, theme } from '@components/Theme'
import { useRemoveItemFromCartMutation, CartItemFragment } from '@hooks/api/index'
import { Modal } from '@molecules/index'
import { DeviceContainer } from '@utility/DeviceContainer'

const Container = styled.div`
  ${ResponsivePXValue('width', { mobile: '100%', tablet: '500px', desktop: '500px' })}
  ${ResponsivePXValue('padding', { mobile: '16px', tablet: '24px', desktop: '24px' })}
`

const Products = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  ${ResponsivePXValue('gap', '12px')}
  ${ResponsivePXValue('margin', { mobile: '16px 0', tablet: '24px 0', desktop: '24px 0' })}
`

const ProductImage = styled.div<{ placeHolder: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'open-sans';
  font-weight: 700;
  
  ${(props) => ResponsivePXValue('border', `1px solid ${props.theme.colors.grey.pampas}`)}
  background-color: ${(props): string => props.placeHolder ? props.theme.colors.white.fantasy : props.theme.colors.white.pureWhite};
  color: ${(props): string => props.theme.colors.green.bottleGreen};

  ${ResponsivePXValue('font-size', '18px')}
  ${ResponsivePXValue('padding', '10px')}
  ${ResponsivePXValue('width', { mobile: '88px', tablet: '104px', desktop: '104px' })}
  ${ResponsivePXValue('height', { mobile: '88px', tablet: '104px', desktop: '104px' })}
`

const Controls = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  .button {
    ${ResponsivePXValue('margin-top', { mobile: '16px', tablet: '24px', desktop: '24px' })}
    ${ResponsivePXValue('width', { mobile: '100%', tablet: '220px', desktop: '220px' })}
  }

`

export interface OutOfStockModalProps {
  open: boolean
  cartId: string
  onClose?: () => void
  cartItems: Readonly<CartItemFragment[]>
}

interface OutOfStockModalState {
  loading: boolean
  error: string
}

const DEFAULT_STATE: OutOfStockModalState = {
  loading: false,
  error: '',
}

export function OutOfStockModal({ open, cartId, cartItems, onClose }: OutOfStockModalProps): JSX.Element {

  const [state, setState] = useState<OutOfStockModalState>({ ...DEFAULT_STATE })
  const [removeItemFromCart, { loading: removeLoading }] = useRemoveItemFromCartMutation()

  const _handleClose = (): void => {
    if (!state.loading) {
      onClose?.()
    }
  }

  const removeProduct = async (cartItem: CartItemFragment): Promise<void> => {
    try {
      await removeItemFromCart({
        variables: {
          cartId,
          cartItemUid: cartItem.uid,
        },
      })
      setState((prevState) => update(prevState, {
        newAmount: { $set: null },
      }))
    } catch (e) {
      setState((prevState) => update(prevState, {
        error: {
          $set: e.message,
        },
      }))
    }
  }

  const _removeProducts = async (): Promise<void> => {
    setState((prevState) => update(prevState, {
      loading: {
        $set: true,
      },
    }))
  }

  useEffect(() => {
    if (state.loading && cartItems.length) {
      removeProduct(cartItems[cartItems.length - 1])
    } else if (state.loading && !cartItems.length) {
      onClose?.()
    }
  }, [state.loading, cartItems.length])

  const $title = (
    <Title variant='t3'>
      Products out of stock
    </Title>
  )

  const mobileExtra = cartItems.length > 5 ? cartItems.length - 5 : 0
  const desktopExtra = cartItems.length > 3 ? cartItems.length - 3 : 0

  let cartItem!: CartItemFragment

  return (
    <Modal title={$title} open={open} onClose={_handleClose}>
      <Choose>
        <When condition={state.error !== ''}>
          <Message wrapperClassName='messageClass' backgroundColor={theme.colors.pink.bridesmaid} color={theme.colors.red.cinnabar} message={state.error}></Message>
        </When>
        <Otherwise>
          <Container>
            <Paragraph>
              The following products are out of stock and will be removed from your cart:
            </Paragraph>
            <DeviceContainer desktop tablet>
              <Products>
                <For each='cartItem' of={desktopExtra ? [...cartItems].splice(0, 3) : cartItems}>
                  <ProductImage key={cartItem.id} placeHolder={false}>
                    <ResponsiveImage image={cartItem.product.coverImage} />
                  </ProductImage>
                </For>
                <If condition={!!desktopExtra}>
                  <ProductImage placeHolder={true}>
                    +{desktopExtra}
                  </ProductImage>
                </If>
              </Products>
            </DeviceContainer>
            <DeviceContainer mobile>
              <Products>
                <For each='cartItem' of={mobileExtra ? [...cartItems].splice(0, 5) : cartItems}>
                  <ProductImage key={cartItem.id} placeHolder={false}>
                    <ResponsiveImage image={cartItem.product.coverImage} />
                  </ProductImage>
                </For>
                <If condition={!!mobileExtra}>
                  <ProductImage placeHolder={true}>
                    +{mobileExtra}
                  </ProductImage>
                </If>
              </Products>
            </DeviceContainer>
            <Controls>
              <Button
                className='button'
                variant='primary'
                title='OK'
                loading={state.loading}
                onClick={_removeProducts} />
            </Controls>
          </Container>
        </Otherwise>
      </Choose>
    </Modal>
  )

}
