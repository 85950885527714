import React from 'react'

import { useNavigate } from 'react-router'
import styled from 'styled-components'

import { SmallLoader, Icon, LocalIconEnums } from '@atoms/index'
import { ResponsivePXValue, theme } from '@components/Theme'
import { SiteHelper } from '@lib/SiteHelper'

export type ButtonVariant = 'ghost' | 'primary' | 'secondary' | 'tertiary' | 'layout' | 'direction' | 'nav' | 'action' | 'text' | 'logo' | 'cart' | 'plain'
export const buttonVariants = { Ghost: 'ghost', Primary: 'primary', Secondary: 'secondary', Tertiary: 'tertiary', Layout: 'layout', Direction: 'direction', Nav: 'nav', Action: 'action', Text: 'text', Logo: 'logo', Cart: 'cart', Plain: 'plain' }
export type ButtonSize = 'small' | 'medium' | 'large'
export const buttonSizes = { Small: 'small', Medium: 'medium', Large: 'large' }
export type ButtonShape = 'square' | 'round'
export const buttonShapes = { Square: 'square', Round: 'round' }
export interface ButtonColors {
  color?: string
  backgroundColor?: string
  borderColor?: string
  hoverColor?: string
  hoverBackgroundColor?: string
  hoverBorderColor?: string
  disabledColor?: string
  disabledBackgrondColor?: string
  disabledBorderColor?: string
}

const getSize = (size: ButtonSize, shape: ButtonShape, fullWidth: boolean, hasTitle: boolean): string => {
  if (!hasTitle) {
    if (size === 'large') {
      return `
        ${ResponsivePXValue('height', { mobile: '32px', tablet: '40px', desktop: '40px' })}
        ${ResponsivePXValue('width', { mobile: '32px', tablet: '40px', desktop: '40px' })}
      `
    } else if (size === 'medium') {
      return `
        ${ResponsivePXValue('height', { mobile: '32px', tablet: '32px', desktop: '32px' })}
        ${ResponsivePXValue('width', { mobile: '32px', tablet: '32px', desktop: '32px' })}
      `
    }
    return `
      ${ResponsivePXValue('height', { mobile: '24px', tablet: '22px', desktop: '22px' })}
      ${ResponsivePXValue('width', { mobile: '24px', tablet: '22px', desktop: '22px' })}
    `
  }
  if (fullWidth) {
    if (size === 'large') {
      return `
        ${ResponsivePXValue('height', { mobile: '32px', tablet: '40px', desktop: '40px' })}
        width: 100%;
      `
    } else if (size === 'medium') {
      return `
        ${ResponsivePXValue('height', { mobile: '32px', tablet: '32px', desktop: '32px' })}
        width: 100%;
      `
    }
    return `
      ${ResponsivePXValue('height', { mobile: '22px', tablet: '22px', desktop: '22px' })}
      width: 100%;
    `
  }
  if (size === 'large') {
    return `
      ${ResponsivePXValue('height', { mobile: '32px', tablet: '40px', desktop: '40px' })}
      width: fit-content;
    `
  } else if (size === 'medium') {
    return `
      ${ResponsivePXValue('height', { mobile: '32px', tablet: '32px', desktop: '32px' })}
      width: fit-content;
    `
  }
  return `
    ${ResponsivePXValue('height', { mobile: '22px', tablet: '22px', desktop: '22px' })}
    width: fit-content;
  `
}

const getBorderSize = (variant: ButtonVariant, size: ButtonSize): string => {
  if (variant === 'text' || variant === 'action' || variant === 'plain') {
    return ''
  }
  if (size === 'large') {
    return `
        ${ResponsivePXValue('border-width', '2px')}
      `
  } else if (size === 'medium') {
    return `
        ${ResponsivePXValue('border-width', '2px')}
      `
  }
  return `
      ${ResponsivePXValue('border-width', '1px')}
    `
}

const getIconSize = (size: ButtonSize): string => {
  if (size === 'large') {
    return `
      ${ResponsivePXValue('width', { mobile: '20px', tablet: '20px', desktop: '20px' })}
      ${ResponsivePXValue('height', { mobile: '20px', tablet: '20px', desktop: '20px' })}
    `
  } else if (size === 'medium') {
    return `
      ${ResponsivePXValue('width', { mobile: '16px', tablet: '16px', desktop: '16px' })}
      ${ResponsivePXValue('height', { mobile: '16px', tablet: '16px', desktop: '16px' })}
    `
  }
  return `
    ${ResponsivePXValue('width', { mobile: '12px', tablet: '12px', desktop: '12px' })}
    ${ResponsivePXValue('height', { mobile: '12px', tablet: '12px', desktop: '12px' })}
  `
}

const getBorderRadius = (size: ButtonSize, shape: ButtonShape): string => {
  if (shape === 'round') {
    if (size === 'large') {
      return ResponsivePXValue('border-radius', { mobile: '20px', tablet: '20px', desktop: '20px' })
    } else if (size === 'medium') {
      return ResponsivePXValue('border-radius', { mobile: '16px', tablet: '16px', desktop: '16px' })
    }
    return ResponsivePXValue('border-radius', { mobile: '11px', tablet: '11px', desktop: '11px' })
  }
  if (size === 'large') {
    return ResponsivePXValue('border-radius', { mobile: '3px', tablet: '3px', desktop: '3px' })
  } else if (size === 'medium') {
    return ResponsivePXValue('border-radius', { mobile: '2px', tablet: '2px', desktop: '2px' })
  }
  return ResponsivePXValue('border-radius', { mobile: '1px', tablet: '1px', desktop: '1px' })
}

const getTextPadding = (variant: ButtonVariant, size: ButtonSize): string => {
  if (variant === 'text' || variant === 'action' || variant === 'plain') {
    return ''
  }
  if (size === 'large') {
    return ResponsivePXValue('padding', { mobile: '0 16px', tablet: '0 16px', desktop: '0 16px' })
  } else if (size === 'medium') {
    return ResponsivePXValue('padding', { mobile: '0 16px', tablet: '0 16px', desktop: '0 16px' })
  }
  return ResponsivePXValue('padding', { mobile: '0 12px', tablet: '0 12px', desktop: '0 12px' })
}

const getColors = (colors: ButtonColors, disabled: boolean): string => {
  if (disabled) {
    return `
      color: ${colors.disabledColor};
      background-color: ${colors.disabledBackgrondColor};
      border-color: ${colors.disabledBorderColor};
    `
  }
  return `
    color: ${colors.color};
    background-color: ${colors.backgroundColor};
    border-color: ${colors.borderColor};

    &:hover {
      color: ${colors.hoverColor};
      background-color: ${colors.hoverBackgroundColor};
      border-color: ${colors.hoverBorderColor};
    }
  `
}

const getIconColors = (colors: ButtonColors, disabled: boolean): string => {
  if (disabled) {
    return `
      .stroke {
        stroke: ${colors.disabledColor};
        fill: none;
      }
      .fill {
        fill: ${colors.disabledColor};
        stroke: none;
      }
      .fill-stroke {
        fill: ${colors.disabledColor};
        stroke: ${colors.disabledColor};
      }
    `
  }
  return `
    .stroke {
      stroke: ${colors.color};
      fill: none;
    }
    .fill {
      fill: ${colors.color};
      stroke: none;
    }
    .fill-stroke {
      fill: ${colors.color};
      stroke: ${colors.color};
    }

    &:hover {
      .stroke {
        stroke: ${colors.hoverColor};
        fill: none;
      }
      .fill {
        fill: ${colors.hoverColor};
        stroke: none;
      }
      .fill-stroke {
        fill: ${colors.hoverColor};
        stroke: ${colors.hoverColor};
      }
    }
  `
}

const getFont = (variant: ButtonVariant, size: ButtonSize): string => {
  if (size === 'large') {
    return `
      font-weight: ${['text', 'plain', 'nav', 'action', 'layout'].includes(variant) ? '400' : '700'};
      font-family: open-sans;
      ${ResponsivePXValue('font-size', { mobile: '12px', tablet: '14px', desktop: '14px' })}
    `
  } else if (size === 'medium') {
    return `
      font-weight: ${['text', 'plain', 'nav', 'action', 'layout'].includes(variant) ? '400' : '700'};
      font-family: open-sans;
      ${ResponsivePXValue('font-size', { mobile: '12px', tablet: '12px', desktop: '12px' })}

    `
  }
  return `
    font-weight: ${['text', 'plain', 'nav', 'action', 'layout'].includes(variant) ? '400' : '600'};
    font-family: open-sans;
      ${ResponsivePXValue('font-size', { mobile: '10px', tablet: '10px', desktop: '10px' })}

  `
}

const ButtonDivElement = styled.div<{ $variant: ButtonVariant, $colors: ButtonColors, $size: ButtonSize, $shape: ButtonShape, $disabled: boolean, $fullWidth: boolean, $hasTitle: boolean }>`
  
  display: flex;
  align-items: center;
  justify-content: center;
  border-style: solid;
  cursor: pointer;
  ${(props): string => getSize(props.$size, props.$shape, props.$fullWidth, props.$hasTitle)}
  ${(props): string => getBorderSize(props.$variant, props.$size)}
  ${(props): string => getFont(props.$variant, props.$size)}
  ${(props): string => getBorderRadius(props.$size, props.$shape)}
  ${(props): string => getColors(props.$colors, props.$disabled)}
  ${(props): string => getIconColors(props.$colors, props.$disabled)}

`

const ButtonAnchorElement = styled.a<{ $variant: ButtonVariant, $colors: ButtonColors, $size: ButtonSize, $shape: ButtonShape, $disabled: boolean, $fullWidth: boolean, $hasTitle: boolean }>`
  
  display: flex;
  align-items: center;
  justify-content: center;
  border-style: solid;
  text-decoration: none;
  ${(props): string => getSize(props.$size, props.$shape, props.$fullWidth, props.$hasTitle)}
  ${(props): string => getBorderSize(props.$variant, props.$size)}
  ${(props): string => getFont(props.$variant, props.$size)}
  ${(props): string => getBorderRadius(props.$size, props.$shape)}
  ${(props): string => getColors(props.$colors, props.$disabled)}
  ${(props): string => getIconColors(props.$colors, props.$disabled)}

`

const CenterContainer = styled.div<{ $variant: ButtonVariant, $size: ButtonSize }>`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  ${(props): string => getTextPadding(props.$variant, props.$size)}
  text-decoration: ${(props): string => props.$variant === 'text' ? 'underline' : 'none'};
`

const TitleContainer = styled.div`
  position: relative;
`

const TitleSpan = styled.span<{ $isLoading: boolean }>`
  text-align: center;
  opacity: ${(props): number => props.$isLoading ? 0 : 1};
`

const LoadingContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const IconContainer = styled.div<{ $size: ButtonSize, $hasTitle: boolean }>`
  ${(props): string => props.$hasTitle ? ResponsivePXValue('margin-left', { mobile: '5px', tablet: '5px', desktop: '5px' }) : ''}
  ${(props): string => getIconSize(props.$size)}
`

const LeftIconContainer = styled.div<{ $size: ButtonSize, $colors: ButtonColors, $disabled: boolean }>`
  flex-shrink: 0;
  ${ResponsivePXValue('margin-left', { mobile: '11px', tablet: '11px', desktop: '11px' })}
  ${(props): string => getIconSize(props.$size)}
`

const RightIconContainer = styled.div<{ $size: ButtonSize, $colors: ButtonColors, $disabled: boolean }>`
  flex-shrink: 0;
  ${ResponsivePXValue('margin-right', { mobile: '11px', tablet: '11px', desktop: '11px' })}
  ${(props): string => getIconSize(props.$size)}
`

export const buttonVariantColors: { [k in ButtonVariant]: ButtonColors } = {
  ghost: {
    color: theme.colors.green.bottleGreen,
    backgroundColor: theme.colors.misc.transparent,
    borderColor: theme.colors.green.bottleGreen,
    hoverColor: theme.colors.white.pureWhite,
    hoverBackgroundColor: theme.colors.green.bottleGreen,
    hoverBorderColor: theme.colors.green.bottleGreen,
    disabledColor: SiteHelper.getOpaqueColor(theme.colors.green.bottleGreen, 0.45),
    disabledBackgrondColor: theme.colors.misc.transparent,
    disabledBorderColor: SiteHelper.getOpaqueColor(theme.colors.green.bottleGreen, 0.45),
  },
  primary: {
    color: theme.colors.white.pureWhite,
    backgroundColor: theme.colors.green.bottleGreen,
    borderColor: theme.colors.green.bottleGreen,
    hoverColor: theme.colors.green.bottleGreen,
    hoverBackgroundColor: theme.colors.misc.transparent,
    hoverBorderColor: theme.colors.green.bottleGreen,
    disabledColor: SiteHelper.getOpaqueColor(theme.colors.white.pureWhite, 0.45),
    disabledBackgrondColor: SiteHelper.getOpaqueColor(theme.colors.green.bottleGreen, 0.45),
    disabledBorderColor: theme.colors.misc.transparent,
  },
  secondary: {
    color: theme.colors.white.pureWhite,
    backgroundColor: theme.colors.green.greenVogue,
    borderColor: theme.colors.green.greenVogue,
    hoverColor: theme.colors.green.greenVogue,
    hoverBackgroundColor: theme.colors.misc.transparent,
    hoverBorderColor: theme.colors.green.greenVogue,
    disabledColor: SiteHelper.getOpaqueColor(theme.colors.white.pureWhite, 0.45),
    disabledBackgrondColor: SiteHelper.getOpaqueColor(theme.colors.green.greenVogue, 0.45),
    disabledBorderColor: theme.colors.misc.transparent,
  },
  tertiary: {
    color: theme.colors.white.pureWhite,
    backgroundColor: theme.colors.green.watercourse,
    borderColor: theme.colors.green.watercourse,
    hoverColor: theme.colors.green.watercourse,
    hoverBackgroundColor: theme.colors.misc.transparent,
    hoverBorderColor: theme.colors.green.watercourse,
    disabledColor: SiteHelper.getOpaqueColor(theme.colors.white.pureWhite, 0.45),
    disabledBackgrondColor: SiteHelper.getOpaqueColor(theme.colors.green.watercourse, 0.45),
    disabledBorderColor: theme.colors.misc.transparent,
  },
  layout: {
    color: theme.colors.green.greenVogue,
    backgroundColor: theme.colors.grey.gallery,
    borderColor: theme.colors.grey.gallery,
    hoverColor: theme.colors.white.fantasy,
    hoverBackgroundColor: theme.colors.grey.stormDust,
    hoverBorderColor: theme.colors.grey.stormDust,
    disabledColor: theme.colors.black.codGrey,
    disabledBackgrondColor: theme.colors.grey.silver,
    disabledBorderColor: theme.colors.grey.silver,
  },
  nav: {
    color: theme.colors.green.bottleGreen,
    backgroundColor: theme.colors.misc.transparent,
    borderColor: theme.colors.misc.transparent,
    hoverColor: theme.colors.green.greenVogue,
    hoverBackgroundColor: theme.colors.misc.transparent,
    hoverBorderColor: theme.colors.misc.transparent,
    disabledColor: theme.colors.grey.silver,
    disabledBackgrondColor: theme.colors.misc.transparent,
    disabledBorderColor: theme.colors.misc.transparent,
  },
  direction: {
    color: theme.colors.grey.silver,
    backgroundColor: SiteHelper.getOpaqueColor(theme.colors.white.pureWhite, 0.45),
    borderColor: theme.colors.misc.transparent,
    hoverColor: theme.colors.black.codGrey,
    hoverBackgroundColor: theme.colors.white.pureWhite,
    hoverBorderColor: theme.colors.misc.transparent,
    disabledColor: theme.colors.grey.silver,
    disabledBackgrondColor: SiteHelper.getOpaqueColor(theme.colors.white.pureWhite, 0.45),
    disabledBorderColor: theme.colors.misc.transparent,
  },
  action: {
    color: theme.colors.grey.silver,
    backgroundColor: theme.colors.misc.transparent,
    borderColor: theme.colors.misc.transparent,
    hoverColor: theme.colors.black.codGrey,
    hoverBackgroundColor: theme.colors.misc.transparent,
    hoverBorderColor: theme.colors.misc.transparent,
    disabledColor: theme.colors.grey.porcelain,
    disabledBackgrondColor: theme.colors.misc.transparent,
    disabledBorderColor: theme.colors.misc.transparent,
  },
  text: {
    color: theme.colors.blue.curiousBlue,
    backgroundColor: theme.colors.misc.transparent,
    borderColor: theme.colors.misc.transparent,
    hoverColor: theme.colors.blue.bahamaBlue,
    hoverBackgroundColor: theme.colors.misc.transparent,
    hoverBorderColor: theme.colors.misc.transparent,
    disabledColor: theme.colors.grey.silver,
    disabledBackgrondColor: theme.colors.misc.transparent,
    disabledBorderColor: theme.colors.misc.transparent,
  },
  logo: {
    color: theme.colors.green.watercourse,
    backgroundColor: theme.colors.misc.transparent,
    borderColor: theme.colors.misc.transparent,
    hoverColor: theme.colors.green.watercourse,
    hoverBackgroundColor: theme.colors.misc.transparent,
    hoverBorderColor: theme.colors.misc.transparent,
    disabledColor: theme.colors.grey.silver,
    disabledBackgrondColor: theme.colors.misc.transparent,
    disabledBorderColor: theme.colors.misc.transparent,
  },
  cart: {
    color: theme.colors.white.pureWhite,
    backgroundColor: theme.colors.green.deYork,
    borderColor: theme.colors.green.deYork,
    hoverColor: theme.colors.white.pureWhite,
    hoverBackgroundColor: theme.colors.green.deYork,
    hoverBorderColor: theme.colors.green.deYork,
    disabledColor: SiteHelper.getOpaqueColor(theme.colors.white.pureWhite, 0.45),
    disabledBackgrondColor: SiteHelper.getOpaqueColor(theme.colors.green.deYork, 0.45),
    disabledBorderColor: SiteHelper.getOpaqueColor(theme.colors.green.deYork, 0.45),
  },
  plain: {
    color: theme.colors.white.pureWhite,
    backgroundColor: theme.colors.misc.transparent,
    borderColor: theme.colors.misc.transparent,
    hoverColor: theme.colors.white.pureWhite,
    hoverBackgroundColor: theme.colors.misc.transparent,
    hoverBorderColor: theme.colors.misc.transparent,
    disabledColor: theme.colors.grey.silver,
    disabledBackgrondColor: theme.colors.misc.transparent,
    disabledBorderColor: theme.colors.misc.transparent,
  },
}

export interface ButtonProps {
  title?: string
  icon?: LocalIconEnums | boolean
  iconColor?: string
  variant?: ButtonVariant
  size?: ButtonSize
  shape?: ButtonShape
  leftIcon?: LocalIconEnums
  rightIcon?: LocalIconEnums
  onClick?: (e: React.MouseEvent<HTMLAnchorElement | HTMLDivElement, MouseEvent>) => void
  onLeftIconClick?: () => void
  onRightIconClick?: () => void
  href?: string
  target?: string
  fullWidth?: boolean
  loading?: boolean
  disabled?: boolean
  className?: string
  colors?: ButtonColors
  onHoverChange?: (hovering: boolean) => void
}

export function Button(props: ButtonProps): JSX.Element {

  const { variant = 'primary', size = 'large', shape = 'square', loading = false, disabled = false, icon, iconColor, title, leftIcon, rightIcon, onClick, onLeftIconClick, onRightIconClick, onHoverChange, href, target, fullWidth = false, className, colors } = props
  const navigate = useNavigate()

  const _handleLinkClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void => {
    if (onClick) {
      return onClick(e)
    } else if (href.indexOf('http') === -1) {
      e.preventDefault()
      navigate(href)
    }
  }

  const _handleDivClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
    if (onClick && !(disabled || loading)) {
      return onClick(e)
    }
  }

  const _handleLeftIconClicked = (e: React.MouseEvent<HTMLDivElement>): void => {
    if (onLeftIconClick) {
      e.stopPropagation()
      onLeftIconClick()
    }
  }

  const _handleRightIconClicked = (e: React.MouseEvent<HTMLDivElement>): void => {
    if (onRightIconClick) {
      e.stopPropagation()
      onRightIconClick()
    }
  }

  const color = colors || buttonVariantColors[variant]

  const __renderInner = (): JSX.Element => {
    return (
      <>
        <If condition={!!leftIcon}>
          <LeftIconContainer
            $size={size}
            $colors={color}
            $disabled={disabled || loading}
            className='button-left-icon'
            onClick={_handleLeftIconClicked}>
            <Icon
              icon={leftIcon}
              color={disabled ? color.disabledColor : color.color}
              hoverColor={disabled ? color.disabledColor : color.hoverColor} />
          </LeftIconContainer>
        </If>
        <CenterContainer $variant={variant} $size={size}>
          <TitleContainer>
            <TitleSpan $isLoading={loading && !icon}>
              {title}
            </TitleSpan>
            <Choose>
              <When condition={loading && !icon}>
                <LoadingContainer>
                  <IconContainer $size={size} $hasTitle={false}>
                    <SmallLoader color={color.disabledColor} />
                  </IconContainer>
                </LoadingContainer>
              </When>
            </Choose>
          </TitleContainer>
          <If condition={!!icon}>
            <IconContainer $size={size} $hasTitle={!!title}>
              <Choose>
                <When condition={loading}>
                  <SmallLoader color={color.disabledColor} />
                </When>
                <Otherwise>
                  <Icon icon={icon as LocalIconEnums} color={(iconColor) || ''} />
                </Otherwise>
              </Choose>
            </IconContainer>
          </If>
        </CenterContainer>
        <If condition={!!rightIcon}>
          <RightIconContainer
            $size={size}
            $colors={color}
            $disabled={disabled || loading}
            className='button-right-icon'
            onClick={_handleRightIconClicked}>
            <Icon
              icon={rightIcon}
              color={disabled ? color.disabledColor : color.color}
              hoverColor={disabled ? color.disabledColor : color.hoverColor} />
          </RightIconContainer>
        </If>
      </>
    )
  }

  return (
    <Choose>
      <When condition={!!href}>
        <ButtonAnchorElement
          $variant={variant}
          href={href}
          target={target}
          $colors={color}
          $size={size}
          $shape={shape}
          $disabled={disabled || loading}
          $fullWidth={fullWidth}
          $hasTitle={!!title}
          className={className}
          onClick={_handleLinkClick}
          onMouseEnter={() => onHoverChange?.(true)}
          onMouseLeave={() => onHoverChange?.(false)}>
          {__renderInner()}
        </ButtonAnchorElement>
      </When>
      <Otherwise>
        <ButtonDivElement
          $variant={variant}
          $colors={color}
          $size={size}
          $shape={shape}
          $disabled={disabled || loading}
          $fullWidth={fullWidth}
          $hasTitle={!!title}
          className={className}
          onClick={_handleDivClick}
          onMouseEnter={() => onHoverChange?.(true)}
          onMouseLeave={() => onHoverChange?.(false)}>
          {__renderInner()}
        </ButtonDivElement>
      </Otherwise>
    </Choose>
  )

}
