
import React from 'react'
import { Svg } from './Svg'

export interface EyeOnProps {
  color?: string
  hoverColor?: string
  className?: string
}

export function EyeOn({ color, hoverColor, className }: EyeOnProps): JSX.Element {

  return (
    <Svg strokeColor={color} strokeHoverColor={hoverColor} fillColor={color} fillHoverColor={hoverColor} className={className} width="100%" height="100%" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.3386 4.45625C14.5118 3.1775 13.439 2.09125 12.2365 1.31469C10.9061 0.454688 9.43739 0 7.98927 0C6.66052 0 5.35395 0.379688 4.10583 1.12844C2.83302 1.89188 1.67989 3.00719 0.678328 4.44312C0.565261 4.60541 0.50299 4.79764 0.499429 4.9954C0.495868 5.19315 0.551177 5.3875 0.658328 5.55375C1.48364 6.84531 2.54583 7.93313 3.72958 8.69906C5.06239 9.5625 6.49677 10 7.98927 10C9.44895 10 10.9208 9.54906 12.2455 8.69625C13.4474 7.92219 14.518 6.83187 15.3418 5.5425C15.4452 5.38012 15.4999 5.19146 15.4994 4.99892C15.4988 4.80638 15.443 4.61804 15.3386 4.45625ZM7.99989 8C7.40655 8 6.82653 7.82405 6.33318 7.49441C5.83983 7.16476 5.45532 6.69623 5.22825 6.14805C5.00119 5.59987 4.94178 4.99667 5.05754 4.41473C5.17329 3.83279 5.45901 3.29824 5.87857 2.87868C6.29813 2.45912 6.83268 2.1734 7.41462 2.05764C7.99656 1.94189 8.59976 2.0013 9.14794 2.22836C9.69612 2.45542 10.1647 2.83994 10.4943 3.33329C10.8239 3.82664 10.9999 4.40666 10.9999 5C10.999 5.79537 10.6826 6.5579 10.1202 7.12032C9.55779 7.68273 8.79526 7.99909 7.99989 8Z" className='fill' />
    </Svg>
  )

}
    