import React from 'react'

import styled from 'styled-components'

import { APP_DEFAULT_STATE } from '@api/local/AppPlugin'
import { ResponsiveImage } from '@atoms/images'
import { Heading } from '@atoms/typography'
import { ResponsivePXValue } from '@components/Theme'
import { useGetAppQuery, PageHeroSectionFragment } from '@hooks/api/index'
import { HTML } from '@molecules/content'
import { DeviceTypeEnum } from '@uctypes/api/globalTypes'
import { DeviceContainer } from '@utility/DeviceContainer'

const Container = styled.div<{ backgroundColor: string, containsImage: boolean }>`
  width: 100%;
  background: ${({ backgroundColor, containsImage }) => !containsImage && backgroundColor};
  ${({ containsImage }) => containsImage
    ? ResponsivePXValue('padding', { mobile: '0px', tablet: '0px', desktop: '0px' })
    : ResponsivePXValue('padding', { mobile: '24px 16px', tablet: '40px 32px', desktop: '40px 32px' })}

`

const TitleContainer = styled.div`
  ${ResponsivePXValue('margin-bottom', '16px')}
`

const SubtitleContainer = styled.div`
  ${ResponsivePXValue('margin-bottom', '16px')}
`

const DescriptionContainer = styled.div`

`

export interface PageHeroSectionProps {
  section: PageHeroSectionFragment
}

export function PageHeroSection({ section }: PageHeroSectionProps): JSX.Element {

  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()
  const { MOBILE } = DeviceTypeEnum

  const { title, subTitle, image, mobileImage, foregroundColor, description, backgroundColor } = section

  return (
    <Container
      backgroundColor={backgroundColor}
      containsImage={!!image}>
      <Choose>
        <When condition={!!image}>
          <DeviceContainer mobile>
            <ResponsiveImage image={mobileImage} />
          </DeviceContainer>
          <DeviceContainer tablet desktop>
            <ResponsiveImage image={image} />
          </DeviceContainer>
        </When>
        <Otherwise>
          <TitleContainer>
            <Heading
              variant='h1'
              align='center'
              color={foregroundColor}>
              {title}
            </Heading>
          </TitleContainer>
          <If condition={!!subTitle}>
            <SubtitleContainer>
              <Heading
                variant={appData.app.deviceType === MOBILE ? 'h5' : 'h2'}
                align='center'
                color={foregroundColor}>
                {subTitle}
              </Heading>
            </SubtitleContainer>
          </If>
          <If condition={!!description}>
            <DescriptionContainer>
              <HTML align='center'>
                {description}
              </HTML>
            </DescriptionContainer>
          </If>
        </Otherwise>
      </Choose>
    </Container>
  )

}
