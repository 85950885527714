
import React from 'react'
import { Svg } from './Svg'

export interface HamburgerMenuProps {
  color?: string
  hoverColor?: string
  className?: string
}

export function HamburgerMenu({ color, hoverColor, className }: HamburgerMenuProps): JSX.Element {

  return (
    <Svg strokeColor={color} strokeHoverColor={hoverColor} fillColor={color} fillHoverColor={hoverColor} className={className} width="100%" height="100%" viewBox="0 0 20 14" version="1.1">
      <g id="g4158" transform="matrix(1,0,0,1,-64.219,-3313.21)">
        <g transform="matrix(0.483085,0,0,0.483085,82.7406,3314.21)">
          <path className='fill' d="M0,2L-35.28,2C-35.833,2 -36.28,1.552 -36.28,1C-36.28,0.448 -35.833,0 -35.28,0L0,0C0.553,0 1,0.448 1,1C1,1.552 0.553,2 0,2" />
        </g>
        <g transform="matrix(0.483085,0,0,0.483085,82.7406,3319.48)">
          <path className='fill' d="M0,2L-35.28,2C-35.833,2 -36.28,1.552 -36.28,1C-36.28,0.448 -35.833,0 -35.28,0L0,0C0.553,0 1,0.448 1,1C1,1.552 0.553,2 0,2" />
        </g>
        <g transform="matrix(0.483085,0,0,0.483085,82.7406,3324.75)">
          <path className='fill' d="M0,2L-35.28,2C-35.833,2 -36.28,1.552 -36.28,1C-36.28,0.448 -35.833,0 -35.28,0L0,0C0.553,0 1,0.448 1,1C1,1.552 0.553,2 0,2" />
        </g>
      </g>
    </Svg>
  )

}
    