import React from 'react'

import { Svg } from './Svg'

export interface GreyEftProps {
  color?: string
  hoverColor?: string
  className?: string
}

export function GreyEft({ color, hoverColor, className }: GreyEftProps): JSX.Element {

  return (
    <Svg strokeColor={color} strokeHoverColor={hoverColor} fillColor={color} fillHoverColor={hoverColor} className={className} width="100%" height="100%" viewBox="0 0 22 9" fill="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <rect y="0.122894" width="21.8" height="8.4597" fill="url(#pattern0)"/>
      <defs>
        <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
          <use xlinkHref="#image0_1788_11223" transform="scale(0.0149254 0.0384615)"/>
        </pattern>
        <image id="image0_1788_11223" width="67" height="26" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEMAAAAaCAYAAADsS+FMAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAQ6ADAAQAAAABAAAAGgAAAABnaM5eAAADwklEQVRYCcVYv6sTQRB+FxISIWACSRAkglhoIwivUKyejb2FiJ0Wtv4B2glib2PxlGf3ihQ2FmJjKsXigfAaRUEhipAEEiEoISHxm3uZY3ezs3uXW/Ga3Z0f38zNzs7OXbRleZbLZXE8HlctrEykWq02iaJozkqj0ajG8zxjvV4fw8cIPh7Pg2PqFk0CrYfD4S0Y27XxstCAcwXyXdLp9XrHZrPZiOY5nx70Tw0GgzMYP+fEUtU7BXXFcwTiNs/zjMVi8QPrl8vlczzPOT4hffh4PieOpg68/bVggBhB6rImueGC0plVcVy2eZ5nBM470sd4Mw+OqQu8w7VgILVPmIIbrh+reiGzbbVh11X8vPNms/ltLRgAPZsXmPThcJdxQmdb6MIJP3vIjPlaAYXj1/gljPEtFO4bNHE5nU7fM9PlPDDvQO4Ly7pG+PaL+dCj4rz2QOYhiLZjLvoPne8EtBYM0O4Sw3xgfA+p1DXpadbz+fyCJNdoNJ4BeynxbfRVLeraeP1+v22jg/bS5792TOj6E4C0tJdkJLoj2zpZAyHZIDrs0OZKwXjl0iWeFgzX9Ye0/+EDc/CtRw+BeO3QycxC8W9KSqVS6avEY7p2TODcNqLLPG1EoH4jBTWatIBhZPLRtbrKNutuwdYuMNM2d/dardYjySbRF4vFJbyDVYT9sTJXRC0z4FyQZks1jCCedDmQloeXjPsLlzxkdgR+R6Br5CQYCESoZkvrLxwOao74Fmo365C1Fn+8275DJ2ElwQjVbMFwN0HHBOur6nrTuZptNgzXRyA25NCmY9KSYIARpNlSDa+yLUSn6E1zfASeNl+O19Rd8tw1JgUUjlsrPpTpK/GFC0TlqYZdzRZ06AV/qrqO+XMHL2ZhE6TiH3eXPn3iJ8HA3HreYOQBXvBpGjBTxtVsAfMGsO1XlwmUYo3NlIp//JWbAuKoz/gPzRa1xiEDIRZ/2PHeQhyouGa4rr9/1GztsQMhRlfxT3kLxW7ExwTR20GaWf2qVCoX8VfJyjOJZJirvqfZOjB1c67F4o9fj8nHnc9GHAwEQrz+wHvjA1H4dZ57su0jy4UY4aNU/DN9+xQAROctxPW3pe4CcMXfcu12+0+IICgYUvHP9O1T8Fx/ij3vVNsFHD3pt5zWoXpRPQIhi3/Bdf15/NDYeHlzF6zZBrnUPYtmQFh4jmOmL206JtJ5E8zbycDpMsfVGkPmE8uFGKn4Czi9rMcxojSrVqtlATA1meoFHIuvJKpD0vFT5VKDOwQl/yeTyTRrMP4CUj1mSu4BWM8AAAAASUVORK5CYII="/>
      </defs>
    </Svg>

  )

}
