
import React from 'react'
import { Svg } from './Svg'

export interface ArrowProps {
  color?: string
  hoverColor?: string
  className?: string
}

export function Arrow({ color, hoverColor, className }: ArrowProps): JSX.Element {

  return (
    <Svg strokeColor={color} strokeHoverColor={hoverColor} fillColor={color} fillHoverColor={hoverColor} className={className} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 85.04 85.04">
      <path className='stroke' strokeWidth="2" strokeLinecap="square" d="M35.92,33.42c-0.42,0.08-0.78,0.33-1.02,0.68c-0.49,0.73-0.29,1.73,0.44,2.22l9.3,6.21l-9.29,6.19 c-0.73,0.49-0.93,1.48-0.45,2.22c0.3,0.45,0.8,0.71,1.33,0.71c0.31,0,0.62-0.09,0.89-0.27l13.29-8.85l-13.29-8.87 C36.76,33.42,36.34,33.34,35.92,33.42z" />
    </Svg>
  )

}
    