import React from 'react'

import styled, { useTheme } from 'styled-components'

import { Link, Paragraph } from '@atoms/index'
import { ResponsivePXValue } from '@components/Theme'

const BreadCrumbElement = styled.div`
  ${ResponsivePXValue('margin', { desktop: '0 8px', tablet: '0 8px', mobile: '0 6px' })}
  ${ResponsivePXValue('font-size', { desktop: '10px', tablet: '10px', mobile: '8px' })}

  font-weight: 400;
  font-family: open-sans;
  &:hover {
    .breadcrumb-link {
      color: ${(props): string => props.theme.colors.blue.curiousBlue};
    }
  }
`
const Wrapper = styled.div``
export interface BreadCrumbProps {
  title: React.ReactNode
  href?: string | boolean
  color?: string
  onClick?: () => void
}

export function BreadCrumb({ title, href, color, onClick }: BreadCrumbProps): JSX.Element {

  const theme = useTheme()

  if (!color) {
    color = theme.colors.grey.stormDust
  }

  return (
    <BreadCrumbElement>
      <If condition={!!href}>
        <Link href={href as string} color={color} className='breadcrumb-link' onClick={onClick}>{title}</Link>
      </If>
      <If condition={!href}>
        <Paragraph variant='p3' color={color} className='breadcrumb-link' onClick={onClick}>{title}</Paragraph>
      </If>
    </BreadCrumbElement>
  )

}

const Container = styled.div`
  display: flex;
  align-items: center;
  ${ResponsivePXValue('height', { mobile: '38px', tablet: '62px', desktop: '62px' })}
  overflow-x: scroll;
  > * {
    &:first-child {
      ${ResponsivePXValue('margin-left', { mobile: '8px', tablet: '0', desktop: '0' })}
    }

    &:last-child {
      ${ResponsivePXValue('margin-right', { mobile: '24px', tablet: '0', desktop: '0' })}
    }
  }

  ::-webkit-scrollbar {
    display: none;
  }

  --mask: linear-gradient(to right, 
    rgba(0,0,0, 1) 0,   
    rgba(0,0,0, 1) 90%, 
    rgba(0,0,0, 0) 95%, 
    rgba(0,0,0, 0) 0
  ) 100% 50% / 100% 100% repeat-x;

  -webkit-mask: var(--mask); 
  mask: var(--mask);

`

const Seperator = styled.div`
  font-family: open-sans;
  font-weight: 400;
  color: ${(props): string => props.theme.colors.grey.stormDust};
  ${ResponsivePXValue('font-size', '10px')}
  ${ResponsivePXValue('margin-top', { mobile: '0', tablet: '3px', desktop: '3px' })}

`

export interface BreadCrumbsProps {
  children: JSX.Element[] | JSX.Element
  className?: string
}

export function BreadCrumbs({ children, className }: BreadCrumbsProps): JSX.Element {
  const $children: JSX.Element[] = []
  const $flattened = (Array.isArray(children) ? children.flat() : [children])
    .filter((child) => child)
  React.Children.forEach($flattened, ($child, index) => {
    $children.push(React.cloneElement($child, { ...$child.props, key: index }))
    if (index < $flattened.length - 1) {
      $children.push((
        <Wrapper key={`sep-${index}`} >
          <Seperator>&gt;</Seperator>
        </Wrapper>
      ))
    }
  })

  return (
    <Container className={className}>
      {$children}
    </Container>
  )

}
