import React, { useState, useEffect, ReactNode } from 'react'

import update from 'react-addons-update'
import styled from 'styled-components'

import { Icon, LocalIconEnums } from '@atoms/index'
import { ResponsivePXValue, theme } from '@components/Theme'

const ToggleWrapper = styled.div`
  position: relative;
`

const ToggleInputWrapper = styled.div`
  position: relative;
  display: inline-block;
`

const Check = styled.div`
  border-radius: 50%;
  background: ${(props) => props.theme.colors.white.pureWhite};
  transition: 0.4s ease;

  ${ResponsivePXValue('width', '30px')}
  ${ResponsivePXValue('height', '30px')}

  ${ResponsivePXValue('padding', '5px')}
  ${ResponsivePXValue('margin-left', '8px')}

  :before, :after {
    transition: 0.4s ease;
  }
`

const CheckBoxInput = styled.input`
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;

  ${ResponsivePXValue('min-width', '100px')}
  ${ResponsivePXValue('height', '45px')}

  :checked + label {
    background: ${(props) => props.theme.colors.green.watercourse};
  }
`

const CheckboxLabel = styled.label`
  background: ${(props) => props.theme.colors.grey.silver};
  display: flex;
  align-items: center;
  position: relative;
  transition: all 0.3s ease;
  transform-origin: 20% center;
  cursor: pointer;


  ${ResponsivePXValue('border-radius', '50px')}
  ${ResponsivePXValue('min-width', '100px')}
  ${ResponsivePXValue('height', '45px')}
`

const ChildrenContainer = styled.div`
  color: ${(props) => props.theme.colors.white.pureWhite};
  font-family: 'Open-sans';
  font-weight: 600;
  display: flex;
  justify-content: center;
  
  ${ResponsivePXValue('font-size', '10px')}
  ${ResponsivePXValue('margin-left', '8px')}
  ${ResponsivePXValue('margin-right', '14px')}
  ${ResponsivePXValue('min-width', '30px')}
`

export interface ToggleProps {
  onClick: (toggleVale: boolean) => void
  children?: ReactNode
  toggled?: boolean
}

interface ToggleStateInterface {
  toggled: boolean
}

const DEFAULT_STATE: ToggleStateInterface = {
  toggled: true,
}

export function Toggle({ onClick, children, toggled = true }: ToggleProps): JSX.Element {

  const [state, setState] = useState(DEFAULT_STATE)

  useEffect(() => {
    setState((prevState) => update(prevState, {
      toggled: { $set: toggled },
    }))
  }, [])

  const _handleClick = () => {
    setState((prevState) => update(prevState, {
      toggled: { $set: !prevState.toggled },
    }))
    if (onClick) {
      onClick(state.toggled)
    }
  }

  return (
    <>
      <ToggleWrapper>
        <ToggleInputWrapper>
          <CheckBoxInput type='checkbox' checked={state.toggled} readOnly id='toggle' onClick={() => _handleClick()} />
          <CheckboxLabel htmlFor='toggle'>
            <Check>
              <Choose>
                <When condition={state.toggled}>
                  <Icon color={theme.colors.black.bottleGreen} icon={LocalIconEnums.CHECK}/>
                </When>
                <Otherwise>
                  <Icon color={theme.colors.grey.silver} icon={LocalIconEnums.SMALL_CROSS}/>
                </Otherwise>
              </Choose>
            </Check>
            <ChildrenContainer>{children}</ChildrenContainer>
          </CheckboxLabel>
        </ToggleInputWrapper>
      </ToggleWrapper>
    </>
  )
}
