import React, { ReactNode } from 'react'

import styled from 'styled-components'

import { ResponsivePXValue } from '@components/Theme'

export type HeadingVariant = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'h7' | 'h8' | 'h9'
export type HeadingAlignment = 'left' | 'center' | 'right' | 'justify'
export type HeadingTransforms = 'none' | 'capitalize' | 'uppercase' | 'lowercase'
export type HeadingDecorations = 'none' | 'underline' | 'overline' | 'line-through'

const H1 = styled.h1<{ align: string, decoration: string, transform: string, color?: string }>`
  color: ${(props): string => props.color ? props.color : props.theme.colors.green.bottleGreen};
  text-align: ${(props): string => props.align};
  text-decoration: ${(props): string => props.decoration};
  text-transform: ${(props): string => props.transform};
  font-family: fortescue;
  font-weight: 700;
  ${ResponsivePXValue('font-size', { mobile: '18px', tablet: '32px', desktop: '38px' })}
  ${ResponsivePXValue('line-height', { mobile: '24px', tablet: '38px', desktop: '38px' })}

`

const H2 = styled.h2<{ align: string, decoration: string, transform: string, color?: string }>`
  color: ${(props): string => props.color ? props.color : props.theme.colors.green.bottleGreen};
  text-align: ${(props): string => props.align};
  text-decoration: ${(props): string => props.decoration};
  text-transform: ${(props): string => props.transform};
  text-transform: ${(props): string => props.transform};
  font-family: open-sans;
  font-weight: 700;
  ${ResponsivePXValue('font-size', { mobile: '16px', tablet: '28px', desktop: '28px' })}
  ${ResponsivePXValue('line-height', { mobile: '22px', tablet: '36px', desktop: '36px' })}
`

const H3 = styled.h3<{ align: string, decoration: string, transform: string, color?: string }>`
  color: ${(props): string => props.color ? props.color : props.theme.colors.green.bottleGreen};
  text-align: ${(props): string => props.align};
  text-decoration: ${(props): string => props.decoration};
  text-transform: ${(props): string => props.transform};
  font-family: fortescue;
  font-weight: 700;
  ${ResponsivePXValue('font-size', { mobile: '16px', tablet: '24px', desktop: '24px' })}
  ${ResponsivePXValue('line-height', { mobile: '22px', tablet: '30px', desktop: '30px' })}
`

const H4 = styled.h4<{ align: string, decoration: string, transform: string, color?: string }>`
  color: ${(props): string => props.color ? props.color : props.theme.colors.green.bottleGreen};
  text-align: ${(props): string => props.align};
  text-decoration: ${(props): string => props.decoration};
  text-transform: ${(props): string => props.transform};
  font-family: fortescue;
  font-weight: 700;
  ${ResponsivePXValue('font-size', { mobile: '14px', tablet: '20px', desktop: '20px' })}
  ${ResponsivePXValue('line-height', { mobile: '20px', tablet: '26px', desktop: '26px' })}
`

const H5 = styled.h5<{ align: string, decoration: string, transform: string, color?: string }>`
  color: ${(props): string => props.color ? props.color : props.theme.colors.green.bottleGreen};
  text-align: ${(props): string => props.align};
  text-decoration: ${(props): string => props.decoration};
  text-transform: ${(props): string => props.transform};
  font-family: open-sans;
  font-weight: 700;
  ${ResponsivePXValue('font-size', { mobile: '12px', tablet: '18px', desktop: '18px' })}
  ${ResponsivePXValue('line-height', { mobile: '18px', tablet: '24px', desktop: '24px' })}
`

const H6 = styled.h6<{ align: string, decoration: string, transform: string, color?: string }>`
  color: ${(props): string => props.color ? props.color : props.theme.colors.green.bottleGreen};
  text-align: ${(props): string => props.align};
  text-decoration: ${(props): string => props.decoration};
  text-transform: ${(props): string => props.transform};
  font-family: fortescue;
  font-weight: 700;
  ${ResponsivePXValue('font-size', { mobile: '12px', tablet: '18px', desktop: '18px' })}
  ${ResponsivePXValue('line-height', { mobile: '20px', tablet: '22px', desktop: '22px' })}
`

const H7 = styled.h6<{ align: string, decoration: string, transform: string, color?: string }>`
  color: ${(props): string => props.color ? props.color : props.theme.colors.green.bottleGreen};
  text-align: ${(props): string => props.align};
  text-decoration: ${(props): string => props.decoration};
  text-transform: ${(props): string => props.transform};
  font-family: fortescue;
  font-weight: 700;
  ${ResponsivePXValue('font-size', '16px')}
  ${ResponsivePXValue('line-height', '22px')}
  ${ResponsivePXValue('font-size', { mobile: '11px', tablet: '16px', desktop: '16px' })}
  ${ResponsivePXValue('line-height', { mobile: '16px', tablet: '22px', desktop: '22px' })}
`

const H8 = styled.h6<{ align: string, decoration: string, transform: string, color?: string }>`
  color: ${(props): string => props.color ? props.color : props.theme.colors.green.bottleGreen};
  text-align: ${(props): string => props.align};
  text-decoration: ${(props): string => props.decoration};
  text-transform: ${(props): string => props.transform};
  font-family: open-sans;
  font-weight: 700;
  ${ResponsivePXValue('font-size', { mobile: '10px', tablet: '14px', desktop: '14px' })}
  ${ResponsivePXValue('line-height', { mobile: '14px', tablet: '20px', desktop: '20px' })}
`

const H9 = styled.h1<{ align: string, decoration: string, transform: string, color?: string }>`
  color: ${(props): string => props.color ? props.color : props.theme.colors.green.bottleGreen};
  text-align: ${(props): string => props.align};
  text-decoration: ${(props): string => props.decoration};
  text-transform: ${(props): string => props.transform};
  font-family: open-sans;
  font-weight: 700;
  ${ResponsivePXValue('font-size', { mobile: '18px', tablet: '18px', desktop: '18px' })}
  ${ResponsivePXValue('line-height', { mobile: '26px', tablet: '26px', desktop: '26px' })}

`

export interface HeadingProps {
  children: ReactNode
  variant?: HeadingVariant
  align?: HeadingAlignment
  transform?: HeadingTransforms
  decoration?: HeadingDecorations
  color?: string
  skeleton?: boolean
  className?: string
  onClick?: () => void
}

export function Heading({
  children,
  variant = 'h1',
  align = 'left',
  transform = 'none',
  decoration = 'none',
  color,
  className,
  onClick,
}: HeadingProps): JSX.Element {

  switch (variant) {

    case 'h1':
      return (
        <H1
          align={align}
          decoration={decoration}
          transform={transform}
          color={color}
          className={className}
          onClick={onClick}>
          {children}
        </H1>
      )
    case 'h2':
      return (
        <H2
          align={align}
          decoration={decoration}
          transform={transform}
          color={color}
          className={className}
          onClick={onClick}>
          {children}
        </H2>
      )
    case 'h3':
      return (
        <H3
          align={align}
          decoration={decoration}
          transform={transform}
          color={color}
          className={className}
          onClick={onClick}>
          {children}
        </H3>
      )
    case 'h4':
      return (
        <H4
          align={align}
          decoration={decoration}
          transform={transform}
          color={color}
          className={className}
          onClick={onClick}>
          {children}
        </H4>
      )
    case 'h5':
      return (
        <H5
          align={align}
          decoration={decoration}
          transform={transform}
          color={color}
          className={className}
          onClick={onClick}>
          {children}
        </H5>
      )
    case 'h6':
      return (
        <H6
          align={align}
          decoration={decoration}
          transform={transform}
          color={color}
          className={className}
          onClick={onClick}>
          {children}
        </H6>
      )
    case 'h7':
      return (
        <H7
          align={align}
          decoration={decoration}
          transform={transform}
          color={color}
          className={className}
          onClick={onClick}>
          {children}
        </H7>
      )
    case 'h8':
      return (
        <H8
          align={align}
          decoration={decoration}
          transform={transform}
          color={color}
          className={className}
          onClick={onClick}>
          {children}
        </H8>
      )
    case 'h9':
      return (
        <H9
          align={align}
          decoration={decoration}
          transform={transform}
          color={color}
          className={className}
          onClick={onClick}>
          {children}
        </H9>
      )

  }

}
