import React from 'react'

import styled, { useTheme } from 'styled-components'

import { Heading, LineEllipsis, Paragraph, Title } from '@atoms/typography'
import { ResponsivePXValue } from '@components/Theme'
import { PageHeadingSectionFragment, useCustomerQuery, useGetAppQuery } from '@hooks/api/index'
import { HTML } from '@molecules/content'
import { CustomerTypeEnum, DeviceTypeEnum, PageHorizontalAlignment } from '@uctypes/api/globalTypes'
import { APP_DEFAULT_STATE } from '@api/local/AppPlugin'
import { Loader } from '@atoms/notifications'

const Placeholder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${ResponsivePXValue('padding', '32px')}
`

const Container = styled.div`
  width: 100%;
`

const HeadingContainer = styled.div<{foregroundColor: string }>`
  width: 100%;
  ${ResponsivePXValue('padding', { mobile: '12px 0px', tablet: '12px 0px', desktop: '12px 0px' })}

   color: ${(props): string => props.foregroundColor};
`

const DescriptionContainer = styled.div<{ alignment: string }>`
  width: 100%;
  display: flex;
  text-align: ${(props): string => props.alignment === PageHorizontalAlignment.LEFT
    ? 'left'
    : props.alignment === PageHorizontalAlignment.RIGHT
      ? 'right'
      : 'center'};
`
export interface PageHeadingSectionProps {
  section: PageHeadingSectionFragment
  showPlaceholder?: boolean
}

export function PageHeadingSection({ section, showPlaceholder = false }: PageHeadingSectionProps): JSX.Element {

  const { data: customerData, loading: customerLoading } = useCustomerQuery()
  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()
  const { foregroundColor, forUserMobileWelcomeMessage } = section
  const theme = useTheme()

  const alignment = section.horizontalAlignment === PageHorizontalAlignment.LEFT
    ? 'left'
    : section.horizontalAlignment === PageHorizontalAlignment.RIGHT
      ? 'right'
      : 'center'

  if (customerData?.currentCustomer?.customerType === CustomerTypeEnum.GUEST) {
    if (showPlaceholder) {
      return (
        <Placeholder>
          <Title variant='t3'>Page Heading Placeholder</Title>
          <Paragraph>There is no preview content for this section while the user is logged out</Paragraph>
        </Placeholder>
      )
    }
    return null
  }

  return (
    <Container>
      <Choose>
        <When condition={forUserMobileWelcomeMessage}>
          <HeadingContainer foregroundColor={section.foregroundColor}>
            <LineEllipsis
              variant={section.variant}
              text={`Welcome ${customerData?.currentCustomer?.firstname}`}
              maxLine={25}
              ellipsis='...'
              trimRight
              basedOn='letters'
              align={alignment}
              />
          </HeadingContainer>
        </When>
        <Otherwise>
          <HeadingContainer foregroundColor={section.foregroundColor}>
            <Heading
              variant={section.variant}
              color={section.foregroundColor}
              align={alignment}>
              {section.heading}
            </Heading>
          </HeadingContainer>
          <If condition={!!section.description}>
            <DescriptionContainer alignment={alignment}>
              <HTML color={section.foregroundColor}>
                {section.description}
              </HTML>
            </DescriptionContainer>
          </If>
        </Otherwise>
      </Choose>
    </Container>
  )

}
