import React, { Fragment, useEffect, useRef, useState } from 'react'

import { ApolloClient, NormalizedCacheObject, useApolloClient } from '@apollo/client'

import update from 'react-addons-update'
import { ErrorBoundary } from 'react-error-boundary'
import { useLocation } from 'react-router'
import styled from 'styled-components'

import { APP_DEFAULT_STATE } from '@api/local/AppPlugin'
import { GlobalModalTypeEnum, ModalPlugin } from '@api/local/ModalPlugin'
import { NAVIGATION_DEFAULT_STATE, NavigationPlugin } from '@api/local/NavigationPlugin'
import { ResponsivePXValue } from '@components/Theme'
import { useGetAppQuery, useGetNavigationQuery } from '@hooks/api/index'
import useIsomorphicLayoutEffect from '@hooks/UseIsomorphicLayoutEffect'
import { LogInModal } from '@molecules/modals'
import { WidgetSitewideBanner } from '@organisms/content/WidgetSitewideBanner'
import { DesktopCategoryBar, DesktopNavigationBar, MobileNavigationBar, PageFallbackComponent } from '@organisms/index'
import { BottomNavigationBar } from '@organisms/navigation/BottomNavigationBar'
import { Footer } from '@organisms/navigation/Footer'
import { MobileVerification } from '@organisms/user/MobileVerification'
import { GTMCart } from '@utility/GTMCart'
import { DeviceContainer } from '@utility/index'

const Container = styled.div`
  min-height: 100vh;
  min-height: -moz-available;
  min-height: -webkit-fill-available;
  min-height: fill-available;
  /* @Shak adding overflows to this container breaks sticky elements rather set max width like below */
  /* overflow-x: hidden; */
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${(props): string => props.theme.colors.white.pampas};

  li {
    outline: none !important;
  }
`

const Header = styled.div`

  width: 100%;
  z-index: 30;
  background-color: ${(props): string => props.theme.colors.white.pureWhite};
`

const FooterContainer = styled.div`
  width: 100%;
`

const Content = styled.div<{ headerHeight: number }>`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  /* Took this out for the position to work in the PDP page. side cart. */
  /* overflow: hidden; */
  width: 100%;
  align-items: center;
  min-height: CALC(100vh - ${(props): number => props.headerHeight}px);
  ${ResponsivePXValue('margin-bottom', { mobile: '56px' })}
`

const WidgetWrapper = styled.div``

interface DefaultProps {
  children: JSX.Element | JSX.Element[]
}

interface DefaultState {
  headerHeight: number
  notificationHeight: number
}

const DEFAULT_STATE = {
  headerHeight: 0,
  notificationHeight: 0,
}

export function Default({ children }: DefaultProps): JSX.Element {

  const [state, setState] = useState<DefaultState>({ ...DEFAULT_STATE })
  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()
  const headerRef: React.RefObject<HTMLDivElement> = useRef()
  const widgetRef: React.RefObject<HTMLDivElement> = useRef()
  const notificationRef: React.RefObject<HTMLDivElement> = useRef()
  const location = useLocation()
  const client = useApolloClient() as ApolloClient<NormalizedCacheObject>
  const { data: navigationData = { navigation: { ...NAVIGATION_DEFAULT_STATE } } } = useGetNavigationQuery()

  useIsomorphicLayoutEffect(() => {
    const headerHeight = headerRef?.current?.clientHeight ?? 0
    const widgetHeight = widgetRef?.current?.clientHeight ?? 0
    const notificationHeight = notificationRef?.current?.clientHeight ?? 0
    setState((prevState) => update(prevState, {
      headerHeight: { $set: headerHeight + widgetHeight },
      notificationHeight: { $set: notificationHeight },
    }))
    // TODO: update on toast header
  }, [widgetRef?.current?.clientHeight])

  useEffect(() => {
    // TODO: move this to navigation area
    NavigationPlugin.shared().setPath(client, location.pathname)
  }, [location])

  useEffect(() => {
    if (navigationData.navigation.preventScroll) {
      // document.body.style.position = 'fixed';
      document.body.style.overflowY = 'hidden'
      document.body.style.top = `-${window.scrollY}px`
    } else {
      const scrollY = document.body.style.top
      document.body.style.position = ''
      document.body.style.top = ''
      window.scrollTo(0, parseInt(scrollY || '0') * -1)
      document.body.style.overflowY = 'scroll'
    }
  }, [navigationData.navigation.preventScroll])

  useEffect(() => {
    if (window.location.href.includes('#login')) {
      ModalPlugin.shared().toggleGlobalModal(true, GlobalModalTypeEnum.LOG_IN)
    } else if (window.location.href.includes('#register')) {
      ModalPlugin.shared().toggleGlobalModal(true, GlobalModalTypeEnum.SIGN_UP)
    }
  }, [])

  return (
    <Fragment>
      <GTMCart />
      <LogInModal/>
      <MobileVerification />
      <If condition={!appData.app.isNativeApp}>
        <WidgetWrapper ref={widgetRef}>
          <WidgetSitewideBanner />
        </WidgetWrapper>
      </If>
      <Container>
        <Header ref={headerRef}>
          {/* @TODO This should be part of page builder notification blocks <CarbonNeutral ref={notificationRef} /> */}
          <DeviceContainer desktop tablet>
            <DesktopNavigationBar />
            <If condition={appData.app.showHeaderContent}>
              <DesktopCategoryBar />
            </If>
          </DeviceContainer>
          <DeviceContainer mobile>
            <MobileNavigationBar notificationHeight={state.notificationHeight} />
          </DeviceContainer>
        </Header>
        <If condition={appData.app.isNativeApp}>
          <WidgetWrapper ref={widgetRef}>
            <WidgetSitewideBanner />
          </WidgetWrapper>
        </If>
        <ErrorBoundary FallbackComponent={PageFallbackComponent}>
          <Content headerHeight={state.headerHeight} className='gonative-content-container'>
            {children}
          </Content>
        </ErrorBoundary>
        <If condition={appData.app.showFooter}>
          <FooterContainer>
            <Footer />
          </FooterContainer>
        </If>
        <If condition={appData.app.showBottomNav}>
          <DeviceContainer mobile>
            <BottomNavigationBar />
          </DeviceContainer>
        </If>
      </Container>
    </Fragment>
  )

}
