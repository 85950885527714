import { BaseCategoryChildFragment, BaseCategoryFragment, BreadcrumbFragment, BundleCartProductFragment, CartFragment, CartItemFragment, CategoryFragment, ProductDetailsFragment, ProductListFragment } from '@hooks/api'

import { GAEcommerceAddToCart, GAEcommerceBeginCheckout, GAEcommerceItem, GAEcommercePaymentInfo, GAEcommercePurchase, GAEcommerceShippingInfo, GAEcommerceViewCart, GAPageView, GTMBase } from './GTMBase'
import { EventPlugin } from './UserEvents'

const round = (num: number): number => {
  return Math.round(num * 100) / 100
}

export class GTM extends GTMBase implements EventPlugin {

  private productToItems(product: ProductListFragment): GAEcommerceItem[] {
    // TODO: ask about variant and how this should be logged?
    if (product.__typename === 'BundleProduct') {
      const items: GAEcommerceItem[] = []
      product.items.forEach((item) => {
        item.options.forEach((option) => {
          items.push({
            itemId: option.product.sku,
            itemName: option.product.name,
            discount: option.product.priceRange.minimumPrice.discount.amountOff,
            itemBrand: product.brand?.name,
            categories: product.categories.map((cat) => cat.name),
            price: option.product.priceRange.minimumPrice.finalPrice.value,
            itemVariant: product.name,
            quantity: 1,
          })
        })
      })
      return items
    } else if (product.__typename === 'ConfigurableProduct') {
      const items: GAEcommerceItem[] = product.variants.map((variant) => ({
        itemId: variant.product.sku,
        itemName: variant.product.name,
        discount: variant.product.priceRange.minimumPrice.discount.amountOff,
        itemBrand: product.brand?.name,
        categories: product.categories.map((cat) => cat.name),
        price: variant.product.priceRange.minimumPrice.finalPrice.value,
        itemVariant: variant.attributes.map((attrib) => attrib.label).join(', '),
        quantity: 1,
      }))
      return items
    } else {
      const item: GAEcommerceItem = {
        itemId: product.sku,
        itemName: product.name,
        discount: product.priceRange.minimumPrice.discount.amountOff,
        itemBrand: product.brand?.name,
        categories: product.categories.map((cat) => cat.name),
        price: product.priceRange.minimumPrice.finalPrice.value,
        quantity: 1,
      }
      return [item]
    }

  }

  private handleCartItemsChange(cartItem: CartItemFragment, previoudCartItem?: CartItemFragment): void {
    // TODO: Ask about this as the discount is a discount against the including tax value and bundle products only have a price with tax
    const actualQuantity = Math.abs(cartItem.quantity - previoudCartItem?.quantity || 0)
    const actualItemValue = cartItem.prices.priceIncludingTax.value
    // const actualItemValue = cartItem.prices.price.value
    const actualItemDiscount = round(cartItem.prices.totalItemDiscount.value / cartItem.quantity)
    const items: GAEcommerceItem[] = []
    if (cartItem.__typename === 'SimpleCartItem') {
      const item: GAEcommerceItem = {
        itemId: cartItem.product.sku,
        itemName: cartItem.product.name,
        discount: actualItemDiscount,
        categories: cartItem.product.categories.map((cat) => cat.name),
        itemBrand: cartItem.product.brand?.name,
        price: actualItemValue,
        quantity: actualQuantity,
      }
      items.push(item)
    } else if (cartItem.__typename === 'ConfigurableCartItem') {
      const item: GAEcommerceItem = {
        itemId: cartItem.configuredVariant.sku,
        itemName: cartItem.configuredVariant.name,
        discount: actualItemDiscount,
        categories: cartItem.product.categories.map((cat) => cat.name),
        itemBrand: cartItem.product.brand?.name,
        price: actualItemValue,
        quantity: actualQuantity,
      }
      items.push(item)
    } else if (cartItem.__typename === 'BundleCartItem') {
      cartItem.bundleOptions.forEach((bundleOption) => {
        bundleOption.values.forEach((value) => {
          const productItem = (cartItem.product as BundleCartProductFragment).items.find((item) => {
            return item.optionId === bundleOption.id
          })
          const product = productItem.options.find((option) => {
            return option.id === value.id
          }).product
          const item: GAEcommerceItem = {
            itemId: product.sku,
            itemName: product.name,
            discount: product.priceRange.minimumPrice.discount.amountOff,
            categories: cartItem.product.categories.map((cat) => cat.name),
            itemBrand: cartItem.product.brand?.name,
            price: product.priceRange.minimumPrice.finalPrice.value,
            quantity: actualQuantity * value.quantity,
          }
          items.push(item)
        })
      })
    }
    const ecommerce: GAEcommerceAddToCart = {
      currency: cartItem.prices.price.currency,
      value: round(actualItemValue * actualQuantity),
      items,
    }
    this.addToCart(ecommerce)
  }

  getBaseCartObject(cart: CartFragment): { currency: string, value: number, items: GAEcommerceItem[] } {
    const items: GAEcommerceItem[] = []
    cart.items.forEach((cartItem) => {
      const actualQuantity = cartItem.quantity
      const actualItemValue = cartItem.prices.priceIncludingTax.value
      // const actualItemValue = cartItem.prices.price.value
      const actualItemDiscount = round(cartItem.prices.totalItemDiscount.value / cartItem.quantity)

      if (cartItem.__typename === 'SimpleCartItem') {
        const item: GAEcommerceItem = {
          itemId: cartItem.product.sku,
          itemName: cartItem.product.name,
          discount: actualItemDiscount,
          categories: cartItem.product.categories.map((cat) => cat.name),
          itemBrand: cartItem.product.brand?.name,
          price: actualItemValue,
          quantity: actualQuantity,
        }
        items.push(item)
      } else if (cartItem.__typename === 'ConfigurableCartItem') {
        const item: GAEcommerceItem = {
          itemId: cartItem.configuredVariant.sku,
          itemName: cartItem.configuredVariant.name,
          discount: actualItemDiscount,
          categories: cartItem.product.categories.map((cat) => cat.name),
          itemBrand: cartItem.product.brand?.name,
          price: actualItemValue,
          quantity: actualQuantity,
        }
        items.push(item)
      } else if (cartItem.__typename === 'BundleCartItem') {
        cartItem.bundleOptions.forEach((bundleOption) => {
          bundleOption.values.forEach((value) => {
            const productItem = (cartItem.product as BundleCartProductFragment).items.find((item) => {
              return item.optionId === bundleOption.id
            })
            const product = productItem.options.find((option) => {
              return option.id === value.id
            }).product
            const item: GAEcommerceItem = {
              itemId: product.sku,
              itemName: product.name,
              discount: product.priceRange.minimumPrice.discount.amountOff,
              categories: cartItem.product.categories.map((cat) => cat.name),
              itemBrand: cartItem.product.brand?.name,
              price: product.priceRange.minimumPrice.finalPrice.value,
              quantity: round(actualQuantity * value.quantity),
            }
            items.push(item)
          })
        })
      }
    })
    const ecommerce: any = {
      currency: cart.prices.grandTotal.currency,
      value: cart.prices.grandTotal.value,
      items,
    }
    return ecommerce
  }

  hasViewedPage(page: GAPageView): void {
    try {
      this.pageView(page)
    } catch (e) {
      console.log(e)
    }
  }

  hasAddedPaymentMethod(cart: CartFragment, method: string): void {
    try {
      const ecommerce: GAEcommercePaymentInfo = {
        paymentType: method,
        coupon: cart.appliedCoupons?.length ? cart.appliedCoupons.join(',') : null,
        ...this.getBaseCartObject(cart),
      }
      this.addPaymentInfo(ecommerce)
    } catch (e) {
      console.log(e)
    }
  }

  hasAddedShippingMethod(cart: CartFragment, method: string): void {
    try {
      const ecommerce: GAEcommerceShippingInfo = {
        shippingType: method,
        coupon: cart.appliedCoupons?.length ? cart.appliedCoupons.join(',') : null,
        ...this.getBaseCartObject(cart),
      }
      this.addPaymentInfo(ecommerce)
    } catch (e) {
      console.log(e)
    }
  }

  hasAddedProductToCart(cartItem: CartItemFragment, previoudCartItem?: CartItemFragment): void {
    try {
      this.handleCartItemsChange(cartItem, previoudCartItem)
    } catch (e) {
      console.log(e)
    }
  }

  hasRemovedProductFromCart(cartItem: CartItemFragment, previoudCartItem?: CartItemFragment): void {
    try {
      this.handleCartItemsChange(cartItem, previoudCartItem)
    } catch (e) {
      console.log(e)
    }
  }

  hasBegunCheckout(cart: CartFragment): void {
    try {
      const ecommerce: GAEcommerceBeginCheckout = {
        coupon: cart.appliedCoupons?.length ? cart.appliedCoupons.join(',') : null,
        ...this.getBaseCartObject(cart),
      }
      this.beginCheckout(ecommerce)
    } catch (e) {
      console.log(e)
    }
  }

  hasEarnedFaithfulPoints(amount: number): void {
    try {
      this.earnVirtualCurrency({
        virtualCurrencyName: 'faithful-points',
        value: amount,
      })

    } catch (e) {
      console.log(e)
    }
  }

  hasGeneratedLead(): void {
    // try {

    // } catch (e) {
    //   console.log(e)
    // }
    // not sure what this would be used for?
  }

  hasLoggedIn(method: string): void {
    try {
      this.login({ method })
    } catch (e) {
      console.log(e)
    }
  }

  hasMadePurchase(cart: CartFragment, orderId: string): void {
    // // TODO:
    try {
      const ecommerce: GAEcommercePurchase = {
        transactionId: orderId,
        coupon: cart.appliedCoupons?.length ? cart.appliedCoupons.join(',') : null,
        ...this.getBaseCartObject(cart),
      }
      this.purchase(ecommerce)
    } catch (e) {
      console.log(e)
    }
  }

  hasBeenRefunded(): void {
    // try {

    // } catch (e) {
    //   console.log(e)
    // }
    // not sure what this would be used for?
  }

  hasPerformedSearch(term: string): void {
    try {
      this.search({ searchTerm: term })
    } catch (e) {
      console.log(e)
    }
  }

  hasSelectedContent(): void {
    // try {

    // } catch (e) {
    //   console.log(e)
    // }
    // not sure what this would be used for?
  }

  hasSelectedItem(product: ProductListFragment, category?: CategoryFragment): void {
    try {
      const items = this.productToItems(product)
      this.selectItem({
        itemListId: category?.uid,
        itemListName: category?.name,
        items: items.flat(),
      })
    } catch (e) {
      console.log(e)
    }
  }

  hasSelectedPromotion(): void {
    // not sure what this would be used for?
  }

  hasShared(method: string, contentType: string, itemId: string): void {
    try {
      this.share({
        method,
        contentType,
        itemId,
      })
    } catch (e) {
      console.log(e)
    }
  }

  hasSignedUp(method: string): void {
    try {
      this.signUp({ method })
    } catch (e) {
      console.log(e)
    }
  }

  hasSpentVirtualCurrency(ammount: number, orderId: string): void {
    try {
      this.spendVirtualCurrency({
        virtualCurrencyName: 'faithful-points',
        value: ammount,
        itemName: orderId,
      })
    } catch (e) {
      console.log(e)
    }
  }

  hasViewedCart(cart: CartFragment): void {
    // // TODO:
    try {
      const ecommerce: GAEcommerceViewCart = {
        ...this.getBaseCartObject(cart),
      }
      this.viewCart(ecommerce)
    } catch (e) {
      console.log(e)
    }
  }

  hasViewedProduct(product: ProductDetailsFragment, breadcrumbs?: BreadcrumbFragment[]): void {
    try {
      const items = this.productToItems(product)
      this.viewItem({
        currency: product.priceRange.minimumPrice.finalPrice.currency,
        value: product.priceRange.minimumPrice.finalPrice.value,
        items: items.flat(),
      })
    } catch (e) {
      console.log(e)
    }
  }

  hasViewedCatalogue(category: CategoryFragment | BaseCategoryFragment | BaseCategoryChildFragment, products: ProductListFragment[]): void {
    try {
      let items: GAEcommerceItem[] = []

      products.forEach((product) => {
        items = [
          ...items,
          ...this.productToItems(product),
        ]
      })

      this.viewItemList({
        itemListId: category.uid,
        itemListName: category.name,
        items,
      })
    } catch (e) {
      console.log(e)
    }
  }

  hasViewedPromotion(): void {
    // try {

    // } catch (e) {
    //   console.log(e)
    // }
    // not sure what this would be used for?
  }

}
