import React, { useEffect } from 'react'

import { useNavigate } from 'react-router'
import styled, { useTheme } from 'styled-components'

import { APP_DEFAULT_STATE, AppPlugin } from '@api/local/AppPlugin'
import { GlobalModalTypeEnum, ModalPlugin } from '@api/local/ModalPlugin'
import { Button } from '@atoms/buttons'
import { Icon, LocalIconEnums } from '@atoms/images'
import { DropDownContainer } from '@atoms/index'
import { Card, LayoutCol, LayoutGrid, LayoutRow } from '@atoms/layout'
import { UltraContainer } from '@atoms/misc'
import { Label, Loader } from '@atoms/notifications'
import { Heading, Paragraph, Tag } from '@atoms/typography'
import { ResponsivePXValue } from '@components/Theme'
import { useEvents } from '@contexts/GTMProvider'
import { useAddCouponCodeToCartMutation, useCartQuery, useRemoveCouponFromCartMutation, CartItemFragment, useCustomerQuery, useGetAppQuery, useCustomerRewardsQuery, useUseRewardPointsMutation } from '@hooks/api/index'
import { useCartId } from '@hooks/UseCartId'
import { Form, TextInput, useForm } from '@molecules/inputs'
import { DesktopProductCartCard, MobileCartCheckout, MobileProductCartCard } from '@molecules/store'
import { CartSummary } from '@molecules/store/CartSummary'
import { useSimpleToasts } from '@simple/toasts'
import { CustomerTypeEnum, EntityTypeEnum, MobileOSTypeEnum } from '@uctypes/api/globalTypes'
import { DeviceContainer } from '@utility/DeviceContainer'
import { SearchEngineOptimizationParams, SearchEngineOptimization } from '@utility/SearchEngineOptimization'

const Container = styled(UltraContainer)`
  ${ResponsivePXValue('padding-bottom', { mobile: '0', tablet: '114px', desktop: '114px' })}

  .drop-down-item {
    ${ResponsivePXValue('margin-top', '12px')}
  }

  .points-disclaimer {
    ${ResponsivePXValue('margin-top', { mobile: '16px', tablet: '16px', desktop: '16px' })}
  }

  .mobile-summary {
    ${ResponsivePXValue('margin-top', '4px')}
  }
`

const LoadingContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  ${ResponsivePXValue('height', { mobile: '100vh', tablet: '100vh', desktop: '100%' })}
`

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  ${ResponsivePXValue('gap', { mobile: '20px', tablet: '34px', desktop: '34px' })}
  ${ResponsivePXValue('margin', { mobile: '8px 16px', tablet: '24px 0 0', desktop: '24px 0 0' })}

  .breadcrumbs {
    ${ResponsivePXValue('display', { mobile: 'none', tablet: 'flex', desktop: 'flex' })}
  }

  .cart-heading {
    ${ResponsivePXValue('margin', { mobile: '0', tablet: '0', desktop: '12px 0' })}
    ${ResponsivePXValue('width', { mobile: '120px', tablet: '120px', desktop: 'auto' })}
  }

`

const Strong = styled.span`
  font-weight: 700;
  color: ${(props): string => props.theme.colors.green.bottleGreen};
`

const ProductsList = styled.div`

`

const PromoContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-wrap: nowrap;
  ${ResponsivePXValue('gap', '10px')}

  .promo-item {
    flex-grow: 1;
    margin: 0;
  }

  .input {
    ${ResponsivePXValue('height', { mobile: '30px', tablet: '30px', desktop: '30px' })}
    ${ResponsivePXValue('margin-top', { mobile: '5px', tablet: '5px', desktop: '5px' })}
    background-color: ${(props): string => props.theme.colors.grey.pampas};
  }
  
`

const PromoLabels = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  ${ResponsivePXValue('gap', '5px')}
  ${ResponsivePXValue('margin-top', '15px')}
`

const Fixed = styled.div<{ isApp: boolean, isIos: boolean }>`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: ${(props): string => props.theme.colors.white.pureWhite};
  ${props => (props.isIos && props.isApp) ? ResponsivePXValue('padding-bottom', '20px') : ''}
`

const DeliveryContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${ResponsivePXValue('gap', '6px')}
  ${ResponsivePXValue('height', '44px')}
  ${ResponsivePXValue('margin-top', '40px')}
`

const DeliveryIconContainer = styled.div`
  ${ResponsivePXValue('width', '30px')}
  ${ResponsivePXValue('height', '30px')}
`

const Empty = styled.div`

  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  ${ResponsivePXValue('margin-bottom', { desktop: '18px' })}
  .empty-text {
    margin: 0;
  }
`

const EmptyContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${ResponsivePXValue('padding', { mobile: '16px 0', tablet: '56px 0', desktop: '40px 0' })}
`

const BottomSpacer = styled.div`
  width: 100%;
  ${ResponsivePXValue('height', { mobile: '132px', tablet: '120px', desktop: '48px' })}
`

export interface CartProps {
  isModal?: boolean
}

export function Cart({ isModal = false }: CartProps): JSX.Element {

  const { cartId } = useCartId()
  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()
  const { data: customerData, loading: customerLoading } = useCustomerQuery()
  const { data: rewards, loading: rewardsLoading } = useCustomerRewardsQuery()
  const { data: cartData, loading: cartLoading } = useCartQuery({ variables: { cartId }, skip: !cartId })
  const [addCouponCodeToCart, { loading: addCouponLoading }] = useAddCouponCodeToCartMutation()
  const [useRewardPoints] = useUseRewardPointsMutation()
  const [removeCouponFromCart, { loading: removeCouponLoading }] = useRemoveCouponFromCartMutation()
  const theme = useTheme()
  const navigate = useNavigate()
  const couponForm = useForm()
  const pointsForm = useForm()
  const { addToast } = useSimpleToasts()
  const events = useEvents()

  const _handleApplyPromo = async ({ code }: { code: string }): Promise<void> => {
    try {
      if (customerData.currentCustomer.customerType === CustomerTypeEnum.GUEST) {
        ModalPlugin.shared().toggleGlobalModal(true, GlobalModalTypeEnum.LOG_IN)
      } else {
        await addCouponCodeToCart({
          variables: {
            input: {
              cartId: cartData.cart.id,
              couponCode: code,
            },
          },
        })
        couponForm.resetFields()
      }

    } catch (e) {
      addToast({
        message: e.message,
        appearance: 'error',
      })
    }
  }

  const _handleRemovePromo = async (): Promise<void> => {
    try {
      await removeCouponFromCart({
        variables: {
          input: {
            cartId: cartData.cart.id,
          },
        },
      })
      couponForm.resetFields()
    } catch (e) {
      addToast({
        message: e.message,
        appearance: 'error',
      })
    }
  }

  const _useRewardPoints = async ({ amount }: {amount: string}): Promise<void> => {
    try {
      const results = await useRewardPoints({
        variables: {
          input: {
            cartId: cartData.cart.id,
            points: parseFloat(amount),
          },
        },
      })
      addToast({
        message: results.data?.useRewardPoints?.response,
        appearance: 'success',
      })
      pointsForm.resetFields()
    } catch (e) {
      addToast({
        message: e.message,
        appearance: 'error',
      })
    }
  }

  const _handleLogin = (): void => {
    if (customerData.currentCustomer.customerType === CustomerTypeEnum.GUEST) {
      ModalPlugin.shared().toggleGlobalModal(true, GlobalModalTypeEnum.LOG_IN)
    }
  }

  useEffect(() => {
    if (!isModal) {
      AppPlugin.shared().hideFooter()
      return () => {
        AppPlugin.shared().showFooter()
      }
    } else {
      events.hasViewedPage({
        url: '/cart',
        title: 'Cart',
        type: EntityTypeEnum.PRODUCT,
      })
    }
  }, [])

  useEffect(() => {
    if (cartData?.cart) {
      events.hasViewedCart(cartData.cart)
    }
  }, [cartData?.cart])

  let cartItem: CartItemFragment
  let cartItemIndex: number
  let promo: { code: string }
  const loading = cartLoading || customerLoading

  const seo: SearchEngineOptimizationParams = {
    name: 'Cart',
    title: `Cart${cartData?.cart ? ` (${cartData.cart.totalQuantity})` : ''}`,
    meta: [], // <---- TODO
  }

  return (
    <Container>
      <SearchEngineOptimization seo={seo} />
      <LayoutGrid>
        <LayoutRow>
          <LayoutCol span={{ mobile: 10, tablet: 10, desktop: cartData?.cart?.items?.length ? 8 : 12 }}>
            <If condition={!isModal}>
              <HeaderContainer>
                <Heading variant='h3' className='cart-heading'>
                  Shopping Cart
                </Heading>
                <Paragraph color={theme.colors.grey.silver}>
                  ({cartData?.cart?.totalQuantity || 0}) Items
                </Paragraph>
              </HeaderContainer>
            </If>
            <Choose>
              <When condition={loading}>
                <LoadingContainer>
                  <Loader />
                </LoadingContainer>
              </When>
              <When condition={cartData?.cart?.items?.length === 0}>
                <Card>
                  <DeviceContainer mobile>
                    <EmptyContent>
                      <Empty>
                        <Paragraph variant='p3' className='empty-text'>Your cart is empty</Paragraph>
                      </Empty>
                      <Button variant='primary' size='small' title='CONTINUE SHOPPING' onClick={() => navigate('/')} />
                    </EmptyContent>
                  </DeviceContainer>
                  <DeviceContainer tablet desktop>
                    <EmptyContent>
                      <Empty>
                        <Paragraph variant='p1' className='empty-text'>Your cart is empty</Paragraph>
                      </Empty>
                      <Button variant='primary' size='medium' title='CONTINUE SHOPPING' onClick={() => navigate('/')} />
                    </EmptyContent>
                  </DeviceContainer>
                </Card>
              </When>
              <Otherwise>
                <DeviceContainer desktop tablet>
                  <ProductsList>
                    <For each='cartItem' of={cartData?.cart.items || []} index='cartItemIndex'>
                      <DesktopProductCartCard cartItem={cartItem} cart={cartData?.cart} key={cartItemIndex} />
                    </For>
                  </ProductsList>
                </DeviceContainer>
                <DeviceContainer mobile>
                  <ProductsList>
                    <For each='cartItem' of={cartData?.cart.items || []} index='cartItemIndex'>
                      <MobileProductCartCard cartItem={cartItem} cart={cartData?.cart} key={cartItemIndex} />
                    </For>
                  </ProductsList>
                  <CartSummary
                    className='mobile-summary'
                    includeCheckout={false}
                    cart={cartData?.cart}
                    customer={customerData?.currentCustomer} />
                </DeviceContainer>
              </Otherwise>
            </Choose>
          </LayoutCol>
          <LayoutCol span={{ mobile: 0, tablet: 0, desktop: cartData?.cart?.items?.length ? 4 : 0 }}>
            <DeliveryContainer>
              <If condition={cartData?.cart?.prices?.subTotal?.value > 400}>
                <DeliveryIconContainer>
                  <Icon icon={LocalIconEnums.CARBON_NEUTRAL} color={theme.colors.green.bottleGreen} />
                </DeliveryIconContainer>
                <Paragraph variant='p2'>
                  You&apos;ve earned free carbon neutral delivery or collection
                </Paragraph>
              </If>
            </DeliveryContainer>
            <Choose>
              <When condition={!!cartData?.cart}>
                <CartSummary cart={cartData.cart} customer={customerData?.currentCustomer} />
              </When>
            </Choose>
            <DropDownContainer
              className='drop-down-item'
              title='Promo Codes & Gift Vouchers'
              titleColor={theme.colors.green.bottleGreen}
              headerColor={theme.colors.green.magicMint}
              onClick={_handleLogin}>
              <Form
                loading={addCouponLoading || removeCouponLoading}
                form={couponForm}
                onFinish={_handleApplyPromo}>
                <PromoContainer>
                  <TextInput
                    name='code'
                    label='Promo Code'
                    className='input'
                    wrapperClassName='promo-item'
                    placeholder='Enter Promo Code' />
                  <Button
                    loading={addCouponLoading || removeCouponLoading}
                    title='APPLY'
                    variant='ghost'
                    size='medium'
                    className='promo-button'
                    onClick={couponForm.submit} />
                </PromoContainer>
              </Form>
              <If condition={!!cartData?.cart?.appliedCoupons?.length}>
                <PromoLabels>
                  <For each='promo' of={cartData?.cart?.appliedCoupons || []}>
                    <Label
                      key={promo.code}
                      onClose={_handleRemovePromo}
                      color={theme.colors.green.bottleGreen}
                      backgroundColor={theme.colors.green.magicMint}>
                      {promo.code}
                    </Label>
                  </For>
                </PromoLabels>
              </If>
            </DropDownContainer>
            <If condition={!rewardsLoading}>
              <DropDownContainer
                className='drop-down-item'
                contentClassName='points-drop-down'
                title='Spend Faithful points'
                titleColor={theme.colors.green.bottleGreen}
                headerColor={theme.colors.green.snowFlurry}
                onClick={_handleLogin}>

                <Tag variant='t2' className='points-text' align='center'>
                  <Choose>
                    <When condition={rewards?.rewards?.balance === undefined}>
                      Please log in to view your Faithful points
                    </When>
                    <Otherwise>
                      You have <Strong>{(rewards?.rewards?.balance) ? rewards?.rewards?.balance : '0'}</Strong> Faithful points
                    </Otherwise>
                  </Choose>
                </Tag>

                <If condition={rewards?.rewards?.balance > 0}>
                  <Form form={pointsForm} onFinish={_useRewardPoints}>
                    <PromoContainer>
                      <TextInput
                        name='amount'
                        label='Faithful Points'
                        className='input'
                        wrapperClassName='promo-item'
                        placeholder='Enter Amount'
                        rules={[{ required: true, message: 'Please enter an amount' }]} />
                      <Button
                        title='APPLY'
                        variant='ghost'
                        size='medium'
                        className='promo-button'
                        onClick={() => pointsForm.submit()} />
                    </PromoContainer>
                  </Form>
                  <Tag variant='t3' className='points-disclaimer' align='center' color={theme.colors.green.greenVogue}>
                    Faithful Points are only deducted when order is confirmed.
                  </Tag>
                </If>
              </DropDownContainer>
            </If>
          </LayoutCol>
        </LayoutRow>
        <LayoutRow>
          <LayoutCol span={{ mobile: 10, tablet: 10, desktop: 12 }}>
            <BottomSpacer />
          </LayoutCol>
        </LayoutRow>
      </LayoutGrid>
      <If condition={!!cartData?.cart?.items?.length}>
        <DeviceContainer mobile>
          <Fixed isApp={appData.app.isNativeApp} isIos={appData.app.mobileOSType === MobileOSTypeEnum.APPLE}>
            <Choose>
              <When condition={!!cartData}>
                <MobileCartCheckout cart={cartData.cart} customer={customerData?.currentCustomer} />
              </When>
            </Choose>
          </Fixed>
        </DeviceContainer>
      </If>
    </Container>
  )
}
