
import React from 'react'
import { Svg } from './Svg'

export interface GridProps {
  color?: string
  hoverColor?: string
  className?: string
}

export function Grid({ color, hoverColor, className }: GridProps): JSX.Element {

  return (
    <Svg strokeColor={color} strokeHoverColor={hoverColor} fillColor={color} fillHoverColor={hoverColor} className={className} width="100%" height="100%" viewBox="0 0 20 20" version="1.1">
      <g transform="matrix(0.898701,0,0,0.898701,-0.78441,-0.78441)">
        <path className='fill' d="M11,4.225C11,2.997 10.003,2 8.775,2L4.225,2C2.997,2 2,2.997 2,4.225L2,8.775C2,10.003 2.997,11 4.225,11L8.775,11C10.003,11 11,10.003 11,8.775L11,4.225Z" />
      </g>
      <g transform="matrix(0.898701,0,0,0.898701,-0.78441,-0.78441)">
        <path className='fill' d="M22,4.225C22,2.997 21.003,2 19.775,2L15.225,2C13.997,2 13,2.997 13,4.225L13,8.775C13,10.003 13.997,11 15.225,11L19.775,11C21.003,11 22,10.003 22,8.775L22,4.225Z" />
      </g>
      <g transform="matrix(0.898701,0,0,0.898701,-0.78441,-0.78441)">
        <path className='fill' d="M22,15.225C22,13.997 21.003,13 19.775,13L15.225,13C13.997,13 13,13.997 13,15.225L13,19.775C13,21.003 13.997,22 15.225,22L19.775,22C21.003,22 22,21.003 22,19.775L22,15.225Z" />
      </g>
      <g transform="matrix(0.898701,0,0,0.898701,-0.78441,-0.78441)">
        <path className='fill' d="M11,15.225C11,13.997 10.003,13 8.775,13L4.225,13C2.997,13 2,13.997 2,15.225L2,19.775C2,21.003 2.997,22 4.225,22L8.775,22C10.003,22 11,21.003 11,19.775L11,15.225Z" />
      </g>
    </Svg>
  )

}
    