// eslint-disable-next-line
import React from 'react'
import { Helmet } from 'react-helmet'
import { useLocation } from 'react-router'

import { useConfig } from '@contexts/ConfigProvider'

export interface SearchEngineOptimizationParams {
  name: string
  title: string
  meta: { name?: string, content: string, property?: string }[]
}

interface SearchEngineOptimizationProps {
  seo?: SearchEngineOptimizationParams
}

export function SearchEngineOptimization({ seo }: SearchEngineOptimizationProps): JSX.Element {
  const location = useLocation()
  const config = useConfig()
  let meta: { name: string, content: string, property: string }
  return (
    <Choose>

      <When condition={!!seo}>
        <Helmet>
          <title>{seo.title}</title>
          <If condition={seo.meta !== undefined}>
            <For each='meta' of={seo.meta}>
              <Choose>
                <When condition={meta.name !== undefined}>
                  <meta key={meta.name} name={meta.name} content={meta.content} />
                </When>
                <Otherwise>
                  <meta key={meta.property} property={meta.property} content={meta.content} />
                </Otherwise>
              </Choose>
            </For>
          </If>
          <link rel='canonical' href={`${config.getBaseUrl(true)}${location.pathname}`} />
        </Helmet>
      </When>
      <Otherwise>
        <Helmet>
          <title>Faithful to Nature</title>
          <link rel='canonical' href={`${config.getBaseUrl(true)}${location.pathname}`} />
        </Helmet>
      </Otherwise>
    </Choose>
  )
}
