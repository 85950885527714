import { PageBuilderPlugin } from '@api/local/PageBuilderPlugin'
import { WishlistPlugin } from '@api/local/WishlistPlugin'
import { Config } from '@lib/Config'
import { GTM } from '@lib/GTM'
import { Smile } from '@lib/Smile'
import { UserEvents } from '@lib/UserEvents'
import { MobileOSTypeEnum } from '@uctypes/api/globalTypes'

import { AppPlugin, AuthPlugin, CartPlugin, CheckoutPlugin, CustomerPlugin, MenuCategoryPlugin, ModalPlugin, NavigationPlugin, PaymentTokenPlugin, ProductPlugin } from './api/local/index'

const wait = (time: number): Promise<void> => {
  return new Promise<void>((resolve, reject) => {
    setTimeout(() => resolve(), time)
  })
}

export async function configure(config: Config, events: UserEvents): Promise<void> {

  const appPlugin = AppPlugin.shared()
  config.registerPlugin(appPlugin)

  const navigationPlugin = NavigationPlugin.shared()
  config.registerPlugin(navigationPlugin)

  const menuCategoryPlugin = MenuCategoryPlugin.shared()
  config.registerPlugin(menuCategoryPlugin)

  const modalPlugin = ModalPlugin.shared()
  config.registerPlugin(modalPlugin)

  const productPlugin = new ProductPlugin()
  config.registerPlugin(productPlugin)

  const paymentTokenPlugin = new PaymentTokenPlugin()
  config.registerPlugin(paymentTokenPlugin)

  const checkoutPlugin = CheckoutPlugin.shared()
  config.registerPlugin(checkoutPlugin)

  const cartPlugin = CartPlugin.shared()
  config.registerPlugin(cartPlugin)

  const wishlistPlugin = WishlistPlugin.shared()
  config.registerPlugin(wishlistPlugin)

  const authPlugin = AuthPlugin.shared()
  config.registerPlugin(authPlugin)

  const customerPlugin = new CustomerPlugin()
  config.registerPlugin(customerPlugin)

  const pageBuilder = PageBuilderPlugin.shared()
  config.registerPlugin(pageBuilder)

  await config.configureApp()

  if (config.isBrowser() && window.env.ENVIRONMENT === 'production') {
    const gtm = new GTM('GTM-T7HDTBL', 'ga4_')
    const smile = new Smile()
    smile.init(config)

    events.registerPlugin(gtm)
    events.registerPlugin(smile)
  }

  let hasReceivedInfo = false

  if (config.isBrowser()) {
    window.receiveIOSMessage = (data: any) => {
      if (data.type === 'AUTH_INFO') {
        authPlugin.token = data.token
        authPlugin.setCartIdFromNativeApp(data.cartId)
        hasReceivedInfo = true
      }
    }

    window.receiveAndroidMessage = (data: any) => {
      if (data.type === 'AUTH_INFO') {
        authPlugin.token = data.token
        authPlugin.setCartIdFromNativeApp(data.cartId)
        hasReceivedInfo = true
      }
    }

    if (appPlugin.getMobileOSType() === MobileOSTypeEnum.APPLE) {
      let hasReceivedInfoInLoop = false
      window.webkit?.messageHandlers?.JSBridge?.postMessage({ ftn: 'true', type: 'STARTUP' })
      while (!hasReceivedInfoInLoop) {
        await wait(100)
        if (hasReceivedInfo) {
          hasReceivedInfoInLoop = true
        }
      }
    }
  }

}
