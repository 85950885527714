import React from 'react'

import styled from 'styled-components'

import { FavouriteButton, ProductLabel, ProductLabels, Skeleton, SkeletonNode } from '@atoms/index'
import { ResponsivePXValue } from '@components/Theme'
import { ProductDetailsFragment, ProductDetailsImageFragment } from '@hooks/api'
import { ProductDesktopImages, ProductMobileImages } from '@molecules/store'
import { LabelItem } from '@uctypes/api/globalTypes'
import { DeviceContainer } from '@utility/DeviceContainer'

const ImageContainer = styled.div`
  position: relative;

  ${ResponsivePXValue('margin-right', { desktop: '14px' })}
`

const FavouriteButtonContainer = styled.div`
  position: absolute;
  top: 0; 
  right: 0;
  z-index: 1;
`

interface ProductImageProps {
  gallery: ProductDetailsImageFragment[]
  labels: LabelItem[]
  product: ProductDetailsFragment
}

export function ProductImage({ gallery, labels, product }: ProductImageProps): JSX.Element {

  if (!gallery || !gallery.length) {
    return (
      <ImageContainer>
        {/** Desktop Tablet Image */}
        <DeviceContainer desktop tablet>
          <Skeleton direction='column'>
            <SkeletonNode color='gallery' shape='rounded'
              width={{ mobile: '0', tablet: '200px', desktop: '380px' }}
              height={{ mobile: '0', tablet: '200px', desktop: '380px' }}
            />
            {/** Desktop Tablet Small image preview */}
            <Skeleton
              loop={3} justify='center' align='center' gap='10px'
              height={{ mobile: '0', tablet: '50px', desktop: '50px' }}
              margin={{ mobile: '0', tablet: '16px 0 0 0', desktop: '20px 0 0 0' }}>
              <SkeletonNode color='gallery' shape='rounded'
                width={{ mobile: '0', tablet: '60px', desktop: '50px' }}
                height={{ mobile: '0', tablet: '60px', desktop: '50px' }} />
            </Skeleton>
          </Skeleton>
        </DeviceContainer>

        {/** Mobile Image */}
        <DeviceContainer mobile>
          <Skeleton direction='column' padding={{ mobile: '0', tablet: '0', desktop: '0' }} margin={{ mobile: '0 0 12px 0', tablet: '0', desktop: '0' }}>
            <SkeletonNode color='gallery' shape='rounded' height={{ mobile: '230px', tablet: '0', desktop: '0' }}/>
          </Skeleton>
        </DeviceContainer>
      </ImageContainer>
    )
  }

  let labelIndex: number
  let label: LabelItem

  return (
    <>
      <DeviceContainer desktop tablet>
        <ImageContainer>
          <ProductDesktopImages gallery={gallery || []} />
        </ImageContainer>
        <If condition={!!labels && labels?.length > 0}>
          <ProductLabels>
            <For each='label' of={labels || []} index='labelIndex'>
              {/** TODO: If ever we need to set the max labels for display then it will need to be implemented here. Default is 3 */}
              <If condition={labelIndex < 3}>
                <ProductLabel key={labelIndex} color={label.labelForegroundColor} backgroundColor={label.labelColor}>{label.label}</ProductLabel>
              </If>
            </For>
          </ProductLabels>
        </If>
      </DeviceContainer>
      <DeviceContainer mobile>
        <ImageContainer>
          <ProductMobileImages gallery={gallery || []} />
          <FavouriteButtonContainer>
            <FavouriteButton className='favourite' product={product} />
          </FavouriteButtonContainer>
        </ImageContainer>
        <If condition={!!labels && labels?.length > 0}>
          <ProductLabels>
            <For each='label' of={labels || []} index='labelIndex'>
              {/** TODO: If ever we need to set the max labels for display then it will need to be implemented here. Default is 3 */}
              <If condition={labelIndex < 3}>
                <ProductLabel key={labelIndex} color={label.labelForegroundColor} backgroundColor={label.labelColor}>{label.label}</ProductLabel>
              </If>
            </For>
          </ProductLabels>
        </If>
      </DeviceContainer>
    </>

  )

}
