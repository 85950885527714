import React, { useEffect, useState } from 'react'

import update from 'react-addons-update'

import { useEvents } from '@contexts/GTMProvider'
import { CartItemFragment, useCartQuery } from '@hooks/api'
import { useCartId } from '@hooks/UseCartId'

interface GTMCartState {
  cartId: string
  items: CartItemFragment[]
}

const DEFAULT_STATE: GTMCartState = {
  cartId: '',
  items: [],
}

export function GTMCart(): JSX.Element {

  const { cartId } = useCartId()
  const events = useEvents()
  const { data: cartData } = useCartQuery({ variables: { cartId }, skip: !cartId })
  const [state, setState] = useState<GTMCartState>({ ...DEFAULT_STATE })

  useEffect(() => {
    if (cartData?.cart?.items) {
      if (state.cartId !== cartId) {
        const items = [...cartData?.cart?.items || []]
        setState((prevState) => update(prevState, {
          cartId: {
            $set: cartId,
          },
          items: {
            $set: items,
          },
        }))
      } else {
        const added = cartData.cart.items.filter((cartItem) => {
          return !state.items.find((stateItem) => stateItem.id === cartItem.id)
        })
        for (let a = 0; a < added.length; a++) {
          events.hasAddedProductToCart(added[a])
        }
        const removed = state.items.filter((stateItem) => {
          return !cartData.cart.items.find((cartItem) => cartItem.id === stateItem.id)
        })
        for (let r = 0; r < removed.length; r++) {
          events.hasRemovedProductFromCart(removed[r])
        }

        cartData.cart.items.forEach((cartItem) => {
          state.items.forEach((stateItem) => {
            if (cartItem.id === stateItem.id) {
              if (cartItem.quantity > stateItem.quantity) {
                events.hasAddedProductToCart(cartItem, stateItem)
              } else if (cartItem.quantity < stateItem.quantity) {
                events.hasRemovedProductFromCart(cartItem, stateItem)
              }
            }
          })
        })
        const items = [...cartData?.cart?.items || []]
        setState((prevState) => update(prevState, {
          items: {
            $set: items,
          },
        }))
      }
    }
  }, [cartData?.cart?.items])

  return null

}
