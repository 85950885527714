import React from 'react'

import styled, { useTheme } from 'styled-components'

import { Button } from '@atoms/buttons'
import { SmallLoader } from '@atoms/notifications'
import { Paragraph, Tag } from '@atoms/typography'
import { ResponsivePXValue } from '@components/Theme'
import { CustomerAddressFragment } from '@hooks/api/index'
import { DeviceContainer } from '@utility/DeviceContainer'

const Container = styled.div<{ active: boolean }>`
  display: flex;
  align-items: center;
  background-color: ${(props): string => props.active ? props.theme.colors.white.pampas : props.theme.colors.white.pureWhite};

  .desktop-row{
    width: 100%;
  }

  ${ResponsivePXValue('padding', { mobile: '16px', tablet: '24px', desktop: '24px' })}
  ${ResponsivePXValue('border-radius', { mobile: '0', tablet: '10px', desktop: '10px' })}
`

const CircleContainer = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  ${ResponsivePXValue('width', { mobile: '30px', tablet: '60px', desktop: '60px' })}
`

const Circle = styled.div<{ active: boolean }>`
  border-style: solid;
  cursor: pointer;
  ${ResponsivePXValue('width', { mobile: '16px', tablet: '20px', desktop: '20px' })}
  ${ResponsivePXValue('height', { mobile: '16px', tablet: '20px', desktop: '20px' })}
  ${ResponsivePXValue('border-radius', { mobile: '10px', tablet: '13px', desktop: '13px' })}
  ${(props): string => {
    if (props.active) {
      return `
        ${ResponsivePXValue('border-width', { mobile: '4px', tablet: '5px', desktop: '5px' })}
        border-color: ${props.theme.colors.green.greenVogue};
      `
    }
    return `
    ${ResponsivePXValue('border-width', { mobile: '1px', tablet: '2px', desktop: '2px' })}
    border-color: ${props.theme.colors.grey.silver};
  `
  }}
`

const TextContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  .title {
    font-weight: 600;
  }
`

const RightContainer = styled.div`
  display: flex;
  ${ResponsivePXValue('align-items', { mobile: 'flex-end', tablet: 'center', desktop: 'center' })}
  ${ResponsivePXValue('justify-content', { mobile: 'space-between', tablet: 'center', desktop: 'center' })}
  ${ResponsivePXValue('flex-direction', { mobile: 'column', tablet: 'row', desktop: 'row' })}
  ${ResponsivePXValue('align-self', { mobile: 'stretch', tablet: 'auto', desktop: 'auto' })}
  ${ResponsivePXValue('width', { mobile: '40%', tablet: 'auto', desktop: 'auto' })}
`

const AddressType = styled.div<{ active: boolean }>`
  flex-shrink: 0;
  flex-grow: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  ${ResponsivePXValue('padding', { mobile: '4px 10px', tablet: '0 12px', desktop: '0 12px' })}
  ${ResponsivePXValue('height', { mobile: '24px', tablet: '36px', desktop: '36px' })}
  ${ResponsivePXValue('border-radius', { mobile: '16px', tablet: '16px', desktop: '16px' })}
  background-color: ${(props): string => props.active ? props.theme.colors.green.magicMint : props.theme.colors.grey.athens};
`

const DefaultTag = styled.div`
  flex-shrink: 0;
  flex-grow: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  ${ResponsivePXValue('padding', { mobile: '4px 10px', tablet: '0 12px', desktop: '0 12px' })}
  ${ResponsivePXValue('margin-right', { mobile: '4px', tablet: '12px', desktop: '12px' })}
  ${ResponsivePXValue('height', { mobile: '24px', tablet: '36px', desktop: '36px' })}
  ${ResponsivePXValue('border-radius', { mobile: '16px', tablet: '16px', desktop: '16px' })}
  background-color: ${(props): string => props.theme.colors.green.magicMint};
`

const ControlsContainer = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: ${(props): string => props.theme.colors.white.pureWhite};

  ${ResponsivePXValue('width', { mobile: 'auto', tablet: '200px', desktop: '200px' })}
  ${ResponsivePXValue('gap', { mobile: '8px', tablet: '8px', desktop: '8px' })}


  .buttons {
   font-weight: 700;
  }
`

const LoadingContainer = styled.div`
  ${ResponsivePXValue('width', { mobile: '50px', tablet: '50px', desktop: '50px' })}
  ${ResponsivePXValue('height', { mobile: '50px', tablet: '50px', desktop: '50px' })}
`

const MobileAddressRow = styled.div`
  display: flex;
  flex-direction: column;

  ${ResponsivePXValue('gap', { mobile: '8px', tablet: '8px', desktop: '8px' })}
`

const MobileTags = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  ${ResponsivePXValue('gap', { mobile: '8px', tablet: '8px', desktop: '8px' })}

  color: ${(props): string => props.theme.colors.white.pureWhite};
  .buttons {
   font-weight: 700;
  }
`

const DesktopRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

export interface AddressRowProps {
  address: CustomerAddressFragment
  selected: boolean
  loading?: boolean
  onSelect: () => void
  onEdit: () => void
  onRemove: () => void
  canRemove?: boolean
}

export function AddressRow({ selected, address, loading, onSelect, onEdit, onRemove, canRemove }: AddressRowProps): JSX.Element {

  const theme = useTheme()

  return (
    <Container active={selected}>
      <If condition={!!onSelect}>
        <CircleContainer onClick={onSelect} >
          <Circle active={selected} />
        </CircleContainer>
      </If>

      <DeviceContainer mobile>
        <MobileAddressRow>
          <TextContainer onClick={onSelect}>
            <Paragraph className='title'>
              {address.street as unknown as string}
            </Paragraph>
            <Paragraph>
              {`${address.suburb}, ${address.city}, ${address.postcode}`}
            </Paragraph>
            <Paragraph color={theme.colors.grey.gunSmoke}>
              {`${address?.firstname} ${address?.lastname}, ${address?.telephone}`}
            </Paragraph>
          </TextContainer>

          <MobileTags>
            <If condition={address.defaultShipping}>
              <DefaultTag>
                <Tag variant='t4'>Shipping</Tag>
              </DefaultTag>
            </If>

            <If condition={address.defaultBilling}>
              <DefaultTag>
                <Tag variant='t4'>Billing</Tag>
              </DefaultTag>
            </If>

            <AddressType active={selected} onClick={onSelect} >
              <Tag variant='t4'>{address.addressGroup === 'BUSINESS' ? 'Business' : 'Residential'}</Tag>
            </AddressType>

          </MobileTags>

          <MobileTags>
            <Choose>
              <When condition={loading}>
                <LoadingContainer>
                  <SmallLoader color={theme.colors.green.greenVogue} />
                </LoadingContainer>
              </When>
              <Otherwise>
                <Button variant='text' title='Edit' onClick={onEdit} className='buttons' />
                <If condition={canRemove}>
                 | <Button variant='text' title='Remove' onClick={onRemove} className='buttons' />
                </If>
              </Otherwise>
            </Choose>
          </MobileTags>

        </MobileAddressRow>
      </DeviceContainer>

      <DeviceContainer tablet desktop className='desktop-row'>

        <DesktopRow>
          <TextContainer onClick={onSelect}>
            <Paragraph className='title'>
              {address.street as unknown as string}
            </Paragraph>
            <Paragraph>
              {`${address.suburb}, ${address.city}, ${address.postcode}`}
            </Paragraph>
            <Paragraph color={theme.colors.grey.gunSmoke}>
              {`${address?.firstname} ${address?.lastname}, ${address?.telephone}`}
            </Paragraph>
          </TextContainer>

          <If condition={address.defaultShipping}>
            <DefaultTag>
              <Tag variant='t4'>Shipping</Tag>
            </DefaultTag>
          </If>

          <If condition={address.defaultBilling}>
            <DefaultTag>
              <Tag variant='t4'>Billing</Tag>
            </DefaultTag>
          </If>
          <AddressType active={selected} onClick={onSelect} >
            <Tag variant='t4'>{address.addressGroup === 'BUSINESS' ? 'Business' : 'Residential'}</Tag>
          </AddressType>

          <RightContainer>
            <ControlsContainer>
              <Choose>
                <When condition={loading}>
                  <LoadingContainer>
                    <SmallLoader color={theme.colors.green.greenVogue} />
                  </LoadingContainer>
                </When>
                <Otherwise>
                  <Choose>
                    <When condition={canRemove}>
                      <Button variant='text' title='Edit' onClick={onEdit} className='buttons' /> |
                      <Button variant='text' title='Remove' onClick={onRemove} className='buttons' />
                    </When>
                    <Otherwise>
                      <Button variant='text' title='Edit' onClick={onEdit} className='buttons' />

                    </Otherwise>
                  </Choose>
                </Otherwise>
              </Choose>
            </ControlsContainer>
          </RightContainer>
        </DesktopRow>

      </DeviceContainer>

    </Container>
  )

}
