
import React from 'react'
import { Svg } from './Svg'

export interface ThumbsDownProps {
  color?: string
  hoverColor?: string
  className?: string
}

export function ThumbsDown({ color, hoverColor, className }: ThumbsDownProps): JSX.Element {

  return (
    <Svg strokeColor={color} strokeHoverColor={hoverColor} fillColor={color} fillHoverColor={hoverColor} className={className} width="100%" height="100%" viewBox="0 0 20 20">
      <g transform="matrix(1.21408,0,0,1.15365,-949.632,-2347.89)">
        <path d="M795.53,2037.37C795.868,2037.67 796.089,2038.08 796.152,2038.53L796.991,2044.2C797.031,2044.47 797.013,2044.75 796.939,2045.01C796.866,2045.27 796.738,2045.52 796.564,2045.72C796.393,2045.93 796.178,2046.1 795.935,2046.21C795.698,2046.32 795.438,2046.38 795.176,2046.37L792.344,2046.37L792.344,2048.27C792.348,2048.93 792.092,2049.57 791.632,2050.05C791.185,2050.52 790.563,2050.79 789.914,2050.79L789.514,2050.79L787.084,2045.11L785.656,2045.11C785.169,2045.11 784.703,2044.91 784.367,2044.56C784.022,2044.2 783.831,2043.72 783.833,2043.22L783.833,2038.81C783.83,2038.31 784.022,2037.83 784.367,2037.47C784.703,2037.12 785.169,2036.92 785.656,2036.92L794.344,2036.92C794.781,2036.92 795.203,2037.08 795.53,2037.37ZM788.091,2044.35L790.283,2049.47C790.469,2049.4 790.637,2049.3 790.773,2049.16C791.003,2048.92 791.131,2048.6 791.129,2048.27L791.129,2045.11L795.182,2045.11C795.27,2045.11 795.357,2045.1 795.437,2045.06C795.518,2045.02 795.59,2044.97 795.648,2044.9C795.765,2044.76 795.817,2044.57 795.79,2044.39L794.951,2038.72C794.93,2038.57 794.856,2038.43 794.744,2038.33C794.634,2038.23 794.487,2038.17 794.337,2038.18L788.091,2038.18L788.091,2044.35ZM786.876,2043.85L786.876,2038.18L785.661,2038.18C785.499,2038.18 785.344,2038.24 785.232,2038.36C785.118,2038.48 785.054,2038.64 785.054,2038.81L785.054,2043.22C785.054,2043.38 785.118,2043.55 785.232,2043.66C785.344,2043.78 785.499,2043.85 785.661,2043.85L786.876,2043.85Z" className='fill' />
      </g>
    </Svg>
  )

}
    