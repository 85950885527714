
import React from 'react'

import loadable from '@loadable/component'

import { PageLoader } from '@atoms/notifications'

import(/* webpackPrefetch: true */ '@pages/EarthChild')

const EarthChild = loadable(() => import(/* webpackChunkName: "brand" */'@pages/EarthChild'), {
  resolveComponent: (components) => components.EarthChild,
})

export function EarthChildAsync(): JSX.Element {
  
  return <EarthChild fallback={<PageLoader />} />

}
