import React from 'react'

import styled from 'styled-components'

import { UltraContainer } from '@atoms/misc'
import { ResponsivePXValue } from '@components/Theme'
import { PageDeviceIntValueFragment, PageSectionGroupFragment, PageSectionFragment, useCustomerQuery } from '@hooks/api/index'
import { PageUserStatus, CustomerTypeEnum } from '@uctypes/api/globalTypes'

import { PageSection } from './PageSection'

const shouldShow = (device: boolean, userStatus: PageUserStatus, customerType?: CustomerTypeEnum): boolean => {
  if (device) {
    return userStatus === PageUserStatus.BOTH ||
    (userStatus === PageUserStatus.LOGGED_IN && customerType === CustomerTypeEnum.REGISTERED) ||
    (userStatus === PageUserStatus.LOGGED_OUT && customerType === CustomerTypeEnum.GUEST)
  }
  return false
}

const OuterContainer = styled.div <{ backgroundColor: string, showOnMobile: boolean, showOnTablet: boolean, showOnDesktop: boolean, userStatus: PageUserStatus, customerType?: CustomerTypeEnum }>`
  width: 100%;
  max-width: 100vw;
  background-color: ${(props): string => props.backgroundColor};
  content-visibility: auto;

  ${(props): string => ResponsivePXValue('display', {
    mobile: !shouldShow(props.showOnMobile, props.userStatus, props.customerType) ? 'none' : undefined,
    tablet: !shouldShow(props.showOnTablet, props.userStatus, props.customerType) ? 'none' : undefined,
    desktop: !shouldShow(props.showOnDesktop, props.userStatus, props.customerType) ? 'none' : undefined,
  })}
`

const InnerContainer = styled(UltraContainer) <{ topPadding?: PageDeviceIntValueFragment, rightPadding?: PageDeviceIntValueFragment, bottomPadding?: PageDeviceIntValueFragment, leftPadding?: PageDeviceIntValueFragment, gap?: PageDeviceIntValueFragment }>`
  display: flex;
  flex-direction:column;
  align-items: center;
  justify-content: center;
  ${(props): string => ResponsivePXValue('padding', {
    mobile: `${props.topPadding?.mobile || 0}px ${props.rightPadding?.mobile || 0}px ${props.bottomPadding?.mobile || 0}px ${props.leftPadding?.mobile || 0}px`,
    tablet: `${props.topPadding?.tablet || 0}px ${props.rightPadding?.tablet || 0}px ${props.bottomPadding?.tablet || 0}px ${props.leftPadding?.tablet || 0}px`,
    desktop: `${props.topPadding?.desktop || 0}px ${props.rightPadding?.desktop || 0}px ${props.bottomPadding?.desktop || 0}px ${props.leftPadding?.desktop || 0}px`,
  })}

  ${(props): string => ResponsivePXValue('gap', {
    mobile: `${props.gap?.mobile || 0}px`,
    tablet: `${props.gap?.tablet || 0}px`,
    desktop: `${props.gap?.desktop || 0}px`,
  })}
`

export interface PageSectionGroupProps {
  pageSectionGroup: PageSectionGroupFragment
}

export function PageSectionGroup({ pageSectionGroup }: PageSectionGroupProps): JSX.Element {

  const { data: customerData } = useCustomerQuery()

  let section!: PageSectionFragment

  return (
    <OuterContainer
      showOnMobile={pageSectionGroup.shouldShow.mobile}
      showOnTablet={pageSectionGroup.shouldShow.tablet}
      showOnDesktop={pageSectionGroup.shouldShow.desktop}
      backgroundColor={pageSectionGroup.backgroundColor}
      customerType={customerData?.currentCustomer?.customerType}
      userStatus={pageSectionGroup.userStatus}>
      <InnerContainer
        topPadding={pageSectionGroup.topPadding}
        rightPadding={pageSectionGroup.rightPadding}
        bottomPadding={pageSectionGroup.bottomPadding}
        leftPadding={pageSectionGroup.leftPadding}
        gap={pageSectionGroup.gap}>
        <For each='section' of={pageSectionGroup.sections}>
          <PageSection section={section} customerType={customerData?.currentCustomer?.customerType} key={section.id} />
        </For>
      </InnerContainer>
    </OuterContainer>
  )

}
