import React from 'react'

import styled, { css } from 'styled-components'

import { ResponsivePXValue } from '@components/Theme'

export type ParagraphVariant = 'p1' | 'p2' | 'p3'
export type ParagraphAlignment = 'left' | 'center' | 'right' | 'justify'
export type ParagraphTransforms = 'none' | 'capitalize' | 'uppercase' | 'lowercase'
export type ParagraphDecorations = 'none' | 'underline' | 'overline' | 'line-through' | 'italic'

const P = styled.div<{ align: string, decoration: string, transform: string, color?: string, bold: boolean, variant: string, display: string }>`
  font-family: open-sans;
  color: ${(props): string => props.color ? props.color : props.theme.colors.green.bottleGreen};
  text-align: ${(props): string => props.align};
  text-decoration: ${(props): string => props.decoration};
  display: ${(props): string => props.display};
  text-transform: ${(props): string => props.transform};
  font-weight: ${(props): string => props.bold ? '700' : '400'};

  ${props => props.variant === 'p1' && css`
    font-family: open-sans;
    ${ResponsivePXValue('font-size', { mobile: '12px', tablet: '14px', desktop: '14px' })}
    ${ResponsivePXValue('line-height', { mobile: '18px', tablet: '20px', desktop: '22px' })}
  `}

  ${props => props.variant === 'p2' && css`
    font-family: open-sans;
    ${ResponsivePXValue('font-size', { mobile: '11px', tablet: '12px', desktop: '12px' })}
    ${ResponsivePXValue('line-height', { mobile: '17px', tablet: '18px', desktop: '18px' })}
  `}
  
  ${props => props.variant === 'p3' && css`
    font-family: open-sans;
    ${ResponsivePXValue('font-size', { mobile: '10px', tablet: '10px', desktop: '10px' })}
    ${ResponsivePXValue('line-height', { mobile: '16px', tablet: '16px', desktop: '16px' })}
  `}
`
export interface ParagraphProps {
  children: React.ReactNode
  variant?: ParagraphVariant
  align?: ParagraphAlignment
  transform?: ParagraphTransforms
  decoration?: ParagraphDecorations
  display?: string
  color?: string
  skeleton?: boolean
  className?: string
  bold?: boolean
  onClick?: () => void
}

export function Paragraph({ children, variant = 'p1', align = 'left', transform = 'none', decoration = 'none', color, className, onClick, bold = false, display = 'block' }: ParagraphProps): JSX.Element {

  return <P
    onClick={onClick}
    align={align}
    display={display}
    variant={variant}
    decoration={decoration}
    transform={transform}
    color={color}
    className={className}
    bold={bold}>
    {children}
  </P>

}
