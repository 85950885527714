import React, { RefObject, useEffect, useRef, useState } from 'react'

import { ApolloQueryResult } from '@apollo/client'

import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import update from 'react-addons-update'
import { useNavigate } from 'react-router'
import { animated, useSpring } from 'react-spring'
import styled, { useTheme } from 'styled-components'

import { NAVIGATION_DEFAULT_STATE, NavigationPlugin } from '@api/local/NavigationPlugin'
import { Icon, LocalIconEnums, Button, SmallLoader } from '@atoms/index'
import { ResponsivePXValue } from '@components/Theme'
import { useConfig } from '@contexts/ConfigProvider'
import { useGetNavigationQuery, SearchQuery, SearchQueryDocument, SearchResultFragment } from '@hooks/api/index'
import { SearchResults } from '@molecules/search/SearchResults'

const DEBOUNCE_TIME = 300
const SEARCH_RESULT_COUNT = 5

const SidebarContainer = styled(animated.div) <{ $notificationHeight: number }>`
  width: 100%;
  position: fixed;
  overflow: scroll;
  scroll-padding-bottom: 0;
  top: 0;
  z-index: 40;
  height: ${(props): string => `CALC(100vh - ${props.$notificationHeight - 15}px)`};
  top: ${(props): string => `${props.$notificationHeight}px`};
`

const Container = styled(animated.div)`
  position: relative;
  height: 100%;
  width: 100%;
  background-color: ${(props): string => props.theme.colors.white.pureWhite};
  overflow-x: hidden;
`

const SearchBarContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${(props): string => props.theme.colors.white.pureWhite};
  ${ResponsivePXValue('padding', '12px 12px 12px 16px')}
  ${(props): string => ResponsivePXValue('border-bottom', `1px solid ${props.theme.colors.grey.athens}`)};
`

const SearchInputContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${(props): string => props.theme.colors.grey.pampas};
  ${ResponsivePXValue('padding-left', '8px')}
  ${ResponsivePXValue('margin-right', '8px')}
  ${ResponsivePXValue('height', '32px')}
`

const SearchTextContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-grow: 1;
  width: 100%;
  height: 100%;
`

const ClearSearch = styled.div`
  cursor: pointer;
  position: absolute;
  ${ResponsivePXValue('top', '7px')}
  ${ResponsivePXValue('right', '7px')}
  ${ResponsivePXValue('width', '18px')}
  ${ResponsivePXValue('height', '18px')}
  ${ResponsivePXValue('border-radius', '9px')}
  ${ResponsivePXValue('padding', '3px')}
  ${ResponsivePXValue('border', '1px solid black')}
  background-color: ${(props): string => props.theme.colors.white.pureWhite};
  display: flex;
  align-items: center;
  justify-content: center;
`

const SearchIconContainer = styled.div`

  flex-shrink: 0;
  display: flex;
  cursor: pointer;

  ${ResponsivePXValue('width', '20px')}
  ${ResponsivePXValue('height', '20px')}

`

const CloseContainer = styled.div`
  flex-shrink: 0;
  display: flex;
  cursor: pointer;
`

const SearchInput = styled.input`
  width: 100%;
  height: 100%;
  border: none; 
  border-width: 0; 
  box-shadow: none;
  outline: 0;
  &:focus {
    outline: none !important;
  }

  ::placeholder {
    color: ${(props): string => props.theme.colors.grey.stormDust};
  }
  color: ${(props): string => props.theme.colors.grey.stormDust};
  background-color: ${(props): string => props.theme.colors.grey.pampas};

  font-family: open-sans;
  font-weight: 700;
  
  ${ResponsivePXValue('font-size', '12px')}
  ${ResponsivePXValue('height', 'CALC(100% - 10px)')}
  ${ResponsivePXValue('padding', '10px')}
  
`

export interface MobileSearchProps {
  notificationHeight: number
  onSelect: (path: string) => void
}

interface MobileSearchState {
  query: string
  hasResult: boolean
  searchLoading: boolean
  items: SearchResultFragment[]
}

const DEFAULT_STATE: MobileSearchState = {
  query: '',
  hasResult: false,
  searchLoading: false,
  items: [],
}

export function MobileSearch({ onSelect, notificationHeight }: MobileSearchProps): JSX.Element {

  const { data: navigationData = { navigation: { ...NAVIGATION_DEFAULT_STATE } } } = useGetNavigationQuery()
  const navigate = useNavigate()
  const config = useConfig()
  const [state, setState] = useState<MobileSearchState>({ ...DEFAULT_STATE })
  const theme = useTheme()
  const searchInput: RefObject<HTMLInputElement> = useRef()
  let timer: NodeJS.Timeout

  const search = async (): Promise<void> => {
    const client = await config.getClient()
    const result: ApolloQueryResult<SearchQuery> = await client.query({
      query: SearchQueryDocument,
      variables: { phrase: state.query, pageSize: SEARCH_RESULT_COUNT, currentPage: 1 },
    })

    const hasResult = result.data?.search?.items.length > 0

    setState((prevState) => update(prevState, {
      hasResult: { $set: hasResult },
      items: { $set: result.data?.search?.items },
      searchLoading: { $set: false },
    }))
  }

  const _handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setState((prevState) => update(prevState, {
      query: { $set: e.target.value },
    }))
  }

  const _handleClearSearch = () => {
    setState((prevState) => update(prevState, {
      query: { $set: '' },
      hasResult: { $set: false },
      items: { $set: [] },
      products: { $set: [] },
      categories: { $set: [] },
    }))
  }

  const _handleClick = (path: string): void => {
    onSelect(path)
    _handleClear()
  }

  const _handleClear = (): void => {
    NavigationPlugin.shared().closeSearch()
    _handleClearSearch()
  }

  const _handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, query: string): void => {
    if (e.key === 'Enter') {
      navigate(`/search?term=${query}`)
      _handleClear()
    }
  }

  useEffect(() => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      if (state.query.length >= 3) {
        setState((prevState) => update(prevState, {
          searchLoading: { $set: true },
        }))
        search()
      } else {
        setState((prevState) => update(prevState, {
          hasResult: { $set: false },
          products: { $set: [] },
          categories: { $set: [] },
        }))
      }
    }, DEBOUNCE_TIME)
    return () => clearTimeout(timer)
  }, [state.query])

  useEffect(() => {
    NavigationPlugin.shared().preventScroll(navigationData.navigation.navigationOpen)
    if (navigationData.navigation.searchOpen) {
      document && disableBodyScroll(document as unknown as Document, {
        allowTouchMove: (el) => {
          while (el && el !== document.body) {
            if (el.classList.contains('scroll-lock-ignore')) {
              return true
            }

            el = el.parentElement
          }
        },
      })
    } else {
      document && enableBodyScroll(document as unknown as Document)
    }
    return () => {
      document && enableBodyScroll(document as unknown as Document)
    }
  }, [navigationData.navigation.searchOpen])

  const sidebarContainerProps = useSpring({
    dspl: navigationData.navigation.searchOpen ? 1 : 0,
  })

  const containerProps = useSpring({
    right: navigationData.navigation.searchOpen ? '0%' : '-100%',
  })

  return (
    <SidebarContainer
      className='scroll-lock-ignore'
      $notificationHeight={notificationHeight}
      style={{
        ...sidebarContainerProps,
        display: sidebarContainerProps.dspl.to((displ) =>
          displ === 0 ? 'none' : 'initial',
        ),
      }}>
      <Container style={containerProps}>
        <SearchBarContainer>
          <SearchInputContainer>
            <SearchIconContainer>
              <Choose>
                <When condition={state.searchLoading}>
                  <SmallLoader color={theme.colors.grey.stormDust} />
                </When>
                <Otherwise>
                  <Icon icon={LocalIconEnums.SEARCH_OUTLINE} color={theme.colors.grey.stormDust} />
                </Otherwise>
              </Choose>
            </SearchIconContainer>
            <SearchTextContainer>
              <SearchInput
                ref={searchInput}
                value={state.query}
                onFocus={_handleSearchChange}
                onChange={_handleSearchChange}
                placeholder="Search"
                onKeyDown={(e) => _handleKeyDown(e, state.query)}/>
              <If condition={!!state.query}>
                <ClearSearch onClick={_handleClearSearch}>
                  <Icon icon={LocalIconEnums.CLOSE} color={theme.colors.green.bottleGreen} />
                </ClearSearch>
              </If>
            </SearchTextContainer>
          </SearchInputContainer>
          <CloseContainer>
            <Button icon={LocalIconEnums.CLOSE} variant='nav' onClick={_handleClear} />
          </CloseContainer>
        </SearchBarContainer>
        <SearchResults searchTerm={state.query} results={state.items} onSelect={_handleClick} />
      </Container>
    </SidebarContainer>
  )

}
