import React, { useState } from 'react'

import { sentenceCase } from 'change-case'
import update from 'react-addons-update'
import { ShareLink } from 'social-media-sharing'
import styled, { useTheme } from 'styled-components'

import { Icon, LocalIconEnums, Tag } from '@atoms/index'
import { ResponsivePXValue } from '@components/Theme'
import { useEvents } from '@contexts/GTMProvider'
import { ShareModal } from '@molecules/index'
import { useSimpleToasts } from '@simple/toasts'
import { DeviceContainer } from '@utility/DeviceContainer'

enum ShareTypesEnum {
  FACEBOOK = 'facebook',
  TWITTER = 'twitter',
  // EMAIL = 'email', Note from Ryan: We don't have an email share option
  COPY = 'copy',
}

const ProductPageShareContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  ${ResponsivePXValue('gap', '8px')}
  ${ResponsivePXValue('height', { mobile: '48px', tablet: '48px', desktop: '62px' })}
  ${ResponsivePXValue('padding', { mobile: '0 10px 0 0', tablet: '0 10px 0 0', desktop: '0 10px 0 0' })}
`

const ProductPageShareIconContainer = styled.div`
  ${ResponsivePXValue('width', { mobile: '14px', tablet: '18px', desktop: '22px' })}
  ${ResponsivePXValue('margin-top', '5px')}
  ${ResponsivePXValue('margin-left', { mobile: '10px', tablet: '5px', desktop: '5px' })}
  ${ResponsivePXValue('cursor', { tablet: 'pointer', desktop: 'pointer' })}

  .shareIcon path {
    stroke-width: 1px;
  }

  &:hover {
    .shareIcon path {
      fill: ${(props): string => props.theme.colors.blue.curiousBlue};
    }
    .share-text {
      font-weight: 700;
      color: ${(props): string => props.theme.colors.blue.curiousBlue};
    }
  }
`

interface ProductShareState {
  isOpen: boolean
}

const DEFAULT_STATE = {
  isOpen: false,
}

export function ProductShare({ sku }: { sku: string }): JSX.Element {
  const [state, setState] = useState<ProductShareState>({ ...DEFAULT_STATE })
  const { addToast } = useSimpleToasts()
  const theme = useTheme()
  const events = useEvents()

  const _handleShareModalOpen = (): void => {
    setState((prevState) => update(prevState, {
      isOpen: { $set: true },
    }))
  }

  const _handleShareModalClose = (): void => {
    setState((prevState) => update(prevState, {
      isOpen: { $set: false },
    }))
  }

  const _handleShare = (platform: ShareTypesEnum): void => {
    const socialMediaLinks = new ShareLink(platform)
    const pageUrl = window?.location?.href
    const description = 'I\'m a conscious shopper, see my favourite natural products on Faithful to Nature.'
    events.hasShared(sentenceCase(platform), 'Product', sku)
    switch (platform) {
      case ShareTypesEnum.FACEBOOK:
        socialMediaLinks.get({
          u: pageUrl,
          quote: description,
        })
        socialMediaLinks.open()
        break
      case ShareTypesEnum.TWITTER:
        socialMediaLinks.get({
          url: pageUrl,
          text: description,
        })
        socialMediaLinks.open()
        break
      default:
        navigator.clipboard.writeText(pageUrl)
        addToast({
          message: 'Link copied!',
          appearance: 'success',
        })
    }
  }

  return (
    <>
      <DeviceContainer mobile>
        <ProductPageShareContainer onClick={_handleShareModalOpen}>
          <Tag variant='t4' className='share-text' color={theme.colors.green.bottleGreen}>Share</Tag>
          <ProductPageShareIconContainer>
            <Icon icon={LocalIconEnums.SHARE} color={theme.colors.green.bottleGreen} className='share-icon' />
          </ProductPageShareIconContainer>
        </ProductPageShareContainer>
      </DeviceContainer>
      <DeviceContainer tablet desktop>
        <ProductPageShareContainer>
          <Tag variant='t4' className='share-text' color={theme.colors.green.bottleGreen}>Share</Tag>
          <ProductPageShareIconContainer onClick={() => _handleShare(ShareTypesEnum.COPY)}>
            <Icon
              icon={LocalIconEnums.SHARE}
              color={theme.colors.green.bottleGreen}
              className='shareIcon' />
          </ProductPageShareIconContainer>
          <ProductPageShareIconContainer onClick={() => _handleShare(ShareTypesEnum.TWITTER)}>
            <Icon
              icon={LocalIconEnums.TWITTER}
              color={theme.colors.green.bottleGreen}
              className='shareIcon' />
          </ProductPageShareIconContainer>
          <ProductPageShareIconContainer onClick={() => _handleShare(ShareTypesEnum.FACEBOOK)}>
            <Icon
              icon={LocalIconEnums.FACEBOOK}
              color={theme.colors.green.bottleGreen}
              className='shareIcon' />
          </ProductPageShareIconContainer>
        </ProductPageShareContainer>
      </DeviceContainer>

      <If condition={state.isOpen}>
        <ShareModal
          open={state.isOpen}
          onClose={_handleShareModalClose} />
      </If>
    </>
  )

}
