
import React from 'react'

import loadable from '@loadable/component'

import(/* webpackPrefetch: true */ '@pages/Brands')

const Brands = loadable(() => import(/* webpackChunkName: "brands" */'@pages/Brands'), {
  resolveComponent: (components) => components.Brands,
})

export function BrandsAsync(): JSX.Element {

  return <Brands fallback={<div>Loading...</div>} />

}
