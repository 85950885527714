import React, { useEffect, useState } from 'react'

import update from 'react-addons-update'
import { useNavigate } from 'react-router'
import styled, { useTheme } from 'styled-components'

import { Button } from '@atoms/buttons'
import { Icon, LocalIconEnums, ResponsiveImage } from '@atoms/images'
import { Tag, Paragraph, Title } from '@atoms/typography'
import { LiteBoxShadow, ResponsivePXValue } from '@components/Theme'
import { CartFragment, DiscountFragment, CheckoutFragment, ShortageItemFragment, CartItemFragment } from '@hooks/api/index'
import { SiteHelper } from '@lib/SiteHelper'
import { Table, Row, Cell } from '@molecules/tables'
import { CarrierTypeEnum, ShippingActionEnum } from '@uctypes/api/globalTypes'

const Container = styled.div`
  width: 100%;
  .summary-title {
    ${ResponsivePXValue('margin', '0 0 10px')}
  }
  .seperator-text {
    flex-shrink: 0;
    margin: 0;
  }
  .seperator {
    ${ResponsivePXValue('margin', '16px 0')}
  }
`

const SectionHeading = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${ResponsivePXValue('padding', '10px 0')}
  ${(props): string => ResponsivePXValue('border-bottom', `1px solid ${props.theme.colors.grey.gallery}`)}
`

const SecureContainer = styled.div`

  display: flex;
  align-items: center;
  justify-content: center;
  ${ResponsivePXValue('gap', '5px')}
  ${ResponsivePXValue('margin-top', '12px')}

  .icon {
    ${ResponsivePXValue('width', '15px')}
    ${ResponsivePXValue('height', '15px')}
  }

  .text {
    margin: 0;
  }
`

const Section = styled.div`
  ${ResponsivePXValue('margin-bottom', '16px')}
`

const DelayedContainer = styled.div`
  
`

const DelayedProducts = styled.div`
  display: flex;
  flex-wrap: wrap;
  ${ResponsivePXValue('padding', { mobile: '24px 8px', tablet: '32px', desktop: '32px' })}
  ${ResponsivePXValue('gap', { mobile: '8px', tablet: '24px', desktop: '24px' })}
  ${ResponsivePXValue('margin', '16px 0 24px')}
  background-color: ${(props): string => props.theme.colors.white.floralWhite};
`

const DelayedProduct = styled.div`
  position: relative;
  background-color: ${(props): string => props.theme.colors.white.pureWhite};
  ${ResponsivePXValue('padding', { mobile: '8px', tablet: '8px', desktop: '8px' })}
  ${ResponsivePXValue('width', { mobile: '132px', tablet: '182px', desktop: '182px' })}
  /* ${(props): string => ResponsivePXValue('border', `1px solid ${props.theme.colors.green.bottleGreen}`)} */
  ${LiteBoxShadow}
`

const DelayedProductImageContainer = styled.div`
  ${ResponsivePXValue('width', { mobile: '116px', tablet: '166px', desktop: '166px' })}
  ${ResponsivePXValue('height', { mobile: '116px', tablet: '166px', desktop: '166px' })}
`

const DelayedProductOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const DelayedProductQuantity = styled.div`
  ${ResponsivePXValue('height', '24px')}
  ${ResponsivePXValue('min-width', '24px')}
  ${ResponsivePXValue('border-radius', '12px')}
  ${ResponsivePXValue('padding', '0 8px')}
  color: ${(props): string => props.theme.colors.white.pureWhite};
  ${ResponsivePXValue('font-size', '12px')}
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  ${ResponsivePXValue('top', '5px')}
  ${ResponsivePXValue('right', '5px')}
  background-color: ${(props): string => SiteHelper.getOpaqueColor(props.theme.colors.red.cinnabar, 0.8)};
`

const DelayedCount = styled.div`
  background-color: ${(props): string => SiteHelper.getOpaqueColor(props.theme.colors.green.watercourse, 0.8)};
  color: ${(props): string => props.theme.colors.white.pureWhite};
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  ${ResponsivePXValue('font-size', { mobile: '10px', tablet: '12px', desktop: '12px' })}
  ${ResponsivePXValue('padding', '4px 12px')}
  ${ResponsivePXValue('min-height', '30px')}
  ${ResponsivePXValue('border-radius', '15px')}
  ${ResponsivePXValue('margin', '0 8px')}
`

const WarningContainer = styled.div<{type?: string}>`
  background-color: ${(props): string => (props.type === 'info') ? SiteHelper.getOpaqueColor(props.theme.colors.orange.casablanca, 0.5) : SiteHelper.getOpaqueColor(props.theme.colors.red.cinnabar, 0.5)};

  color: ${(props): string => props.theme.colors.white.floralWhite};
  ${(props): string => (props.type === 'info') ? ResponsivePXValue('border', `3px solid ${props.theme.colors.orange.casablanca}`) :ResponsivePXValue('border', `3px solid ${props.theme.colors.red.cinnabar}`)}
  ${ResponsivePXValue('width', '100%')}
  ${ResponsivePXValue('padding', '16px')}
  ${ResponsivePXValue('font-size', '16px')}
  font-weight: bold;
  text-align: center;
`

export interface FinalSummaryProps {
  cart: CartFragment
  checkout: CheckoutFragment
  loading?: boolean
  onNext: () => void
  onCancel: () => void
}

interface FinalSummaryState {
  delayedCartItems: {[k: string]: CartItemFragment}
}

const DEFAULT_STATE: FinalSummaryState = {
  delayedCartItems: {},
}

export function FinalSummary({ cart, checkout, loading = false, onNext, onCancel }: FinalSummaryProps): JSX.Element {

  const theme = useTheme()
  const navigate = useNavigate()
  const [state, setState] = useState<FinalSummaryState>({ ...DEFAULT_STATE })

  const _handleNext = async (): Promise<void> => {
    onNext()
  }

  const _handleEditShipping = () => {
    onCancel()
    navigate('/checkout/delivery')
  }

  const _handleEditPayment = () => {
    onCancel()
    navigate('/checkout/payment')
  }

  let discount: DiscountFragment
  const deliveryAddress = cart?.shippingAddresses?.[0]
  const paymentMethod = cart.availablePaymentMethods.find((method) => method.id === checkout.paymentInfo?.paymentMethod)?.title || ''
  const deliveryMethod = deliveryAddress?.availableShippingMethods?.find((method) => method.rateUid === checkout.deliveryInfo.rateUid)?.carrierTitle || ''
  const isCollection = deliveryAddress?.availableShippingMethods?.find((method) => method.rateUid === checkout.deliveryInfo.rateUid)?.carrierType === CarrierTypeEnum.COLLECT
  const deliveryTitle = isCollection ? 'Collection Address' : 'Delivery Address'
  const $deliveryAddress = isCollection
    ? checkout.deliveryInfo.requiresPargoLocation
      ? (<><div>{checkout.deliveryInfo.pargoLocation.storeName}</div><div>{checkout.deliveryInfo.pargoLocation.addressSms}</div>{checkout.deliveryInfo.pargoLocation.businessHours.split(',').map((hours) => <div key={hours}>{hours}</div>)}</>)
      : (<>{deliveryAddress?.availableShippingMethods?.find((method) => method.rateUid === checkout.deliveryInfo.rateUid)?.methodTitle}<br />Open:<br />Mon - Fri: 8am - 8pm<br />Sat - Sun: 8am - 10pm</>) || ''
    : (<>{deliveryAddress.street.join(', ')}<br />{deliveryAddress.suburb}<br />{deliveryAddress.city}<br />{deliveryAddress.postcode}</>)
  const displayedShippingActions = [ShippingActionEnum.REMOVE, ShippingActionEnum.SPLIT]
  const points = cart?.amastyRewardsHighlights?.captionText || '0'
  const shippingAddress = cart?.shippingAddresses?.[0]

  let removedItems: { previousQuantity: number, newQuantity: number, item: CartItemFragment }[] = []
  const removedItemsString = sessionStorage.getItem('REMOVED_CART_ITEMS')
  if (removedItemsString) {
    removedItems = JSON.parse(removedItemsString)
  }

  useEffect(() => {
    if (shippingAddress?.shippingActions?.shortageItems && shippingAddress?.shippingActions?.shortageItems.length) {
      const delayedCartItems: {[k: string]: CartItemFragment} = {}
      shippingAddress?.shippingActions?.shortageItems.forEach((shortageItem) => {
        delayedCartItems[shortageItem.cartParentItemUid || shortageItem.cartItemUid] = cart.items.find((ci) => ci.uid === (shortageItem.cartParentItemUid || shortageItem.cartItemUid))
      })
      setState((prevState) => update(prevState, {
        delayedCartItems: {
          $set: delayedCartItems,
        },
      }))
    }
  }, [shippingAddress?.shippingActions?.shortageItems, removedItems])

  const hasProducts = cart?.items?.length > 0

  let removedItem: { previousQuantity: number, newQuantity: number, item: CartItemFragment }
  let delayedItem: ShortageItemFragment

  return (
    <Container>
      <Section>
        <SectionHeading>
          <Title variant='t4'>Payment</Title>
          <Button title='Edit' size='small' onClick={_handleEditPayment} />
        </SectionHeading>
        <Table>
          <Row align='start'>
            <Cell><Tag variant='t2' className='tag'>Payment Method</Tag></Cell>
            <Cell align='end'><Tag variant='t2' className='tag' align='right'>{paymentMethod}</Tag></Cell>
          </Row>
          <Row align='start'>
            <Cell><Tag variant='t2' className='tag'>Faithful Points Earned</Tag></Cell>
            <Cell align='end'><Tag variant='t2' className='tag' align='right'>{points}</Tag></Cell>
          </Row>
        </Table>
      </Section>
      <Section>
        <SectionHeading>
          <Title variant='t4'>Delivery</Title>
          <Button title='Edit' size='small' onClick={_handleEditShipping} />
        </SectionHeading>
        <Table>
          <Row align='start'>
            <Cell><Tag variant='t2' className='tag'>Delivery Method</Tag></Cell>
            <Cell align='end'><Tag variant='t2' className='tag' align='right'>{deliveryMethod}</Tag></Cell>
          </Row>
          <Row align='start'>
            <Cell><Tag variant='t2' className='tag'>{deliveryTitle}</Tag></Cell>
            <Cell align='end'><Tag variant='t2' className='tag' align='right'>{$deliveryAddress}</Tag></Cell>
          </Row>
        </Table>
        <If condition={displayedShippingActions.includes(checkout.deliveryInfo.deliveryAction as ShippingActionEnum)}>
          <DelayedContainer>
            <Choose>
              <When condition={checkout.deliveryInfo.deliveryAction === ShippingActionEnum.REMOVE}>
                <WarningContainer>
                  The following items have be removed from your order
                </WarningContainer>
              </When>
              <When condition={checkout.deliveryInfo.deliveryAction === ShippingActionEnum.SPLIT}>
                <WarningContainer type='info'>
                  The following items will be shipped seperately
                </WarningContainer>
              </When>
              <When condition={checkout.deliveryInfo.deliveryAction === ShippingActionEnum.REROUTE}>
                <WarningContainer>
                  Your order will be re-routed
                </WarningContainer>
              </When>
            </Choose>
            <DelayedProducts>
              <Choose>
                <When condition={!!removedItems.length}>
                  <For each='removedItem' of={removedItems || []}>
                    <DelayedProduct key={removedItem.item.uid}>
                      <DelayedProductImageContainer>
                        <ResponsiveImage image={removedItem.item.product.coverImage} />
                      </DelayedProductImageContainer>
                      <Paragraph>{removedItem.item.product.name}</Paragraph>
                      <DelayedProductOverlay>
                        <DelayedCount>
                          <Choose>
                            <When condition={!!removedItem.newQuantity}>
                          Only {removedItem.newQuantity} available in {shippingAddress.shippingActions.primarySource.name}, {removedItem.previousQuantity - removedItem.newQuantity}{checkout.deliveryInfo.deliveryAction === ShippingActionEnum.REMOVE ? ' have been removed' : ' will be shipped seperately'}
                            </When>
                            <Otherwise>
                          Not available in {shippingAddress.shippingActions.primarySource.name}{checkout.deliveryInfo.deliveryAction === ShippingActionEnum.REMOVE ? ', all have been removed' : ', all will be shipped seperately'}
                            </Otherwise>
                          </Choose>
                        </DelayedCount>
                      </DelayedProductOverlay>
                      <DelayedProductQuantity>
                        {removedItem.item.quantity} in cart
                      </DelayedProductQuantity>
                    </DelayedProduct>
                  </For>
                </When>
                <Otherwise>
                  <For each='delayedItem' of={shippingAddress?.shippingActions?.shortageItems || []}>
                    <DelayedProduct key={delayedItem.cartItemUid}>
                      <DelayedProductImageContainer>
                        <ResponsiveImage image={state.delayedCartItems[delayedItem.cartParentItemUid || delayedItem.cartItemUid]?.product?.coverImage} />
                      </DelayedProductImageContainer>
                      <Paragraph>{state.delayedCartItems[delayedItem.cartParentItemUid || delayedItem.cartItemUid]?.product?.name}</Paragraph>
                      <DelayedProductOverlay>
                        <DelayedCount>
                          <Choose>
                            <When condition={!!(state.delayedCartItems[delayedItem.cartParentItemUid || delayedItem.cartItemUid]?.quantity - delayedItem.qty)}>
                          Only {state.delayedCartItems[delayedItem.cartParentItemUid || delayedItem.cartItemUid]?.quantity - delayedItem.qty} available in {shippingAddress.shippingActions.primarySource.name}, {delayedItem.qty}{checkout.deliveryInfo.deliveryAction === ShippingActionEnum.REMOVE ? ' have been removed' : ' will be shipped seperately'}
                            </When>
                            <Otherwise>
                          Not available in {shippingAddress.shippingActions.primarySource.name}{checkout.deliveryInfo.deliveryAction === ShippingActionEnum.REMOVE ? ', all have been removed' : ', all will be shipped seperately'}
                            </Otherwise>
                          </Choose>
                        </DelayedCount>
                      </DelayedProductOverlay>
                      <DelayedProductQuantity>
                        {state.delayedCartItems[delayedItem.cartParentItemUid || delayedItem.cartItemUid]?.quantity} in cart
                      </DelayedProductQuantity>
                    </DelayedProduct>
                  </For>
                </Otherwise>
              </Choose>
            </DelayedProducts>
          </DelayedContainer>
        </If>
      </Section>
      <Section>
        <Table>
          <For each='discount' of={cart.prices?.discounts || []}>
            <Row key={discount.label}>
              <Cell><Tag variant='t2' className='tag' color={theme.colors.green.greenVogue}>{discount.label}</Tag></Cell>
              <Cell align='end'><Tag variant='t2' className='tag' color={theme.colors.green.greenVogue}>-{SiteHelper.formatCurrency(discount.amount)}</Tag></Cell>
            </Row>
          </For>
          <Row className='total-row'>
            <Cell><Paragraph variant='p2' className='tag'>To pay</Paragraph></Cell>
            <Cell align='end'><Title variant='t2' className='tag'>{SiteHelper.formatCurrency(cart.prices.grandTotal)}</Title></Cell>
          </Row>
        </Table>
      </Section>
      <Button
        fullWidth
        className='button'
        disabled={!checkout.canAdvance || !hasProducts}
        title={hasProducts ? 'CONTINUE' : 'NO PRODUCTS'}
        loading={loading}
        onClick={_handleNext} />
      <SecureContainer>
        <Icon icon={LocalIconEnums.LOCK_CLOSED_OUTLINE} className='icon' color={theme.colors.black.pureBlack} />
        <Paragraph variant='p2' className='text'>Secure Checkout</Paragraph>
      </SecureContainer>
    </Container>
  )

}
