import React, { MutableRefObject, useEffect, useRef, useState } from 'react'

import deepmerge from 'deepmerge'
import qs from 'qs'
import update from 'react-addons-update'
import { useNavigate, useLocation } from 'react-router'
import styled from 'styled-components'

import { NavigationPlugin } from '@api/local/NavigationPlugin'
import { LayoutCol, LayoutGrid, LayoutRow } from '@atoms/layout/LayoutGrid'
import { ResponsivePXValue } from '@components/Theme'
import { BaseCategoryFragment, PageSectionGroupFragment, AggregationFragment, useGetAllAggregationsQuery, BreadcrumbFragment } from '@hooks/api/index'
import { useLoadingData } from '@hooks/UseLoadingData'
import { BreadCrumb, BreadCrumbs } from '@molecules/navigation/BreadCrumbs'
import { PageSectionGroup } from '@organisms/index'
import { CatalogueHeader } from '@organisms/navigation/CatalogueHeader'
import { BaseCategoryHeader, CategoryRows } from '@organisms/shop'
import { ProductFilters, ProductFilterInputs } from '@organisms/shop/ProductFilters'
import { DisplayTypeEnum, OrderValue, ProductGrid } from '@organisms/shop/ProductGrid'
import { OrderDirectionEnum } from '@uctypes/api/globalTypes'
import { Mutable } from '@uctypes/global'
import { SearchEngineOptimization, SearchEngineOptimizationParams } from '@utility/SearchEngineOptimization'

const PRODUCTS_PER_PAGE = 16

const Container = styled.div`
  .content-row {
    ${ResponsivePXValue('gap', { mobile: '0' })}
  }
`

const ProductGridContainer = styled.div`
  ${ResponsivePXValue('margin', '0 0 34px 0')}
`

const ProductFilterContainer = styled.div`
  ${ResponsivePXValue('margin', { tablet: '16px 16px 16px 0', desktop: '16px 16px 16px 0' })}
`

interface BaseCategoryState {
  fetchingMore: boolean
  mobileFiltersOpen: boolean
  displayType: DisplayTypeEnum
}

const DEFAULT_STATE: BaseCategoryState = {
  fetchingMore: false,
  mobileFiltersOpen: false,
  displayType: DisplayTypeEnum.GRID,
}

export interface BaseCategoryProps {
  baseCategory?: BaseCategoryFragment
  loading: boolean
}

export function BaseCategory({ baseCategory, loading: categoryLoading }: BaseCategoryProps): JSX.Element {

  const navigate = useNavigate()
  const location = useLocation()

  const [state, setState] = useState<BaseCategoryState>({ ...DEFAULT_STATE })
  const query = qs.parse(location.search.replace('?', ''))
  const queryFilters = query?.filters as { [k: string]: any } || {}
  const currentPage = parseInt(query?.page as string || '1')
  const filters = { ...queryFilters }
  const order = query?.order as unknown as OrderValue || { field: 'position', direction: OrderDirectionEnum.ASC }
  const where = { categoryUid: { eq: baseCategory?.uid } }
  const combinedFilters = deepmerge(filters || {}, where || {})
  const isFiltered = Object.keys(filters).length > 0
  const scrollRef: MutableRefObject<number> = useRef(0)
  const pathRef: MutableRefObject<string> = useRef('')
  const { data: aggregationData, loading: aggreationLoading } = useGetAllAggregationsQuery({
    skip: !baseCategory?.uid,
    variables: {
      filters: combinedFilters,
    },
  })

  const hasFilters = aggregationData?.allProducts?.aggregations?.length > 0 || !aggregationData?.allProducts?.aggregations

  const aggregations = useLoadingData<Readonly<AggregationFragment[]>>({
    data: aggregationData?.allProducts?.aggregations,
    loading: aggreationLoading,
    defaultData: [],
  })

  const productCount = useLoadingData<number>({
    data: aggregationData?.allProducts?.totalCount,
    loading: aggreationLoading,
    defaultData: 0,
  })

  const updateSessionScroll = () => {
    if (pathRef.current && scrollRef.current) {
      sessionStorage.setItem(pathRef.current, scrollRef.current+'')
    }
  }

  const _handleScroll = () => {
    const position = window.pageYOffset
    scrollRef.current = position
    updateSessionScroll()
  }

  const _handleDisplayTypeChange = (displayType: DisplayTypeEnum): void => {
    setState((prevState) => update(prevState, {
      displayType: { $set: displayType },
    }))
    _handleScroll()
  }

  const _handleOrderChange = (newOrder: OrderValue): void => {
    const newQueryString = qs.stringify({ filters, order: newOrder })
    navigate(`${location.pathname}?${newQueryString}`)
    _handleScroll()
  }

  const _handleFetchMore = (): void => {
    const newQueryString = qs.stringify({ filters, order, page: currentPage + 1 })
    navigate(`${location.pathname}?${newQueryString}`)
    _handleScroll()
  }

  const _handleFiltersChange = (filters: ProductFilterInputs): void => {
    const newFilters = { ...filters } as Mutable<ProductFilterInputs>
    delete newFilters.name
    let subCategoryId: string | null = null
    if (newFilters?.categoryUid?.in?.[0]) {
      subCategoryId = newFilters.categoryUid.in[0]
      delete newFilters.categoryUid
    }
    const newQueryString = qs.stringify({ filters: newFilters })
    if (subCategoryId) {
      const canonicalUrl = baseCategory?.children?.find((c) => c.uid === subCategoryId)?.canonicalUrl
      navigate(`/${canonicalUrl}?${newQueryString}`)
    } else {
      navigate(`${location.pathname}?${newQueryString}`)
    }
    _handleScroll()
  }

  const _handleToggleFilter = () => {
    NavigationPlugin.shared().closeSearch()
    setState((prevState) => update(prevState, {
      mobileFiltersOpen: { $set: !state.mobileFiltersOpen },
    }))
  }

  const _handleAllProductsLoaded = () => {
    const key = location.pathname+location.search
    const value = sessionStorage.getItem(key)
    if (value) {
      window.scroll(0, parseInt(value))
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', _handleScroll, { passive: true })
    return () => {
      window.removeEventListener('scroll', _handleScroll)
    }
  }, [])

  useEffect(() => {
    pathRef.current = location.pathname+location.search
    updateSessionScroll()
  }, [location.pathname, location.search])

  let section!: PageSectionGroupFragment
  const overwriteBreadcrumbs: BreadcrumbFragment[] = baseCategory?.breadcrumbs
    ? [...baseCategory.breadcrumbs, {
      categoryId: baseCategory.id,
      categoryLevel: 0,
      categoryName: baseCategory.name,
      categoryUid: baseCategory.uid,
      categoryUrlKey: baseCategory.urlKey,
      categoryUrlPath: baseCategory.canonicalUrl,
      __typename: 'Breadcrumb',
    }]
    : [{
      categoryId: baseCategory.id,
      categoryLevel: 0,
      categoryName: baseCategory.name,
      categoryUid: baseCategory.uid,
      categoryUrlKey: baseCategory.urlKey,
      categoryUrlPath: baseCategory.canonicalUrl,
      __typename: 'Breadcrumb',
    }]
  const seo: SearchEngineOptimizationParams = {
    name: baseCategory?.name,
    title: baseCategory?.name,
    meta: [], // <---- TODO
  }
  // const loading = categoryLoading || aggreationLoading || productLoading || state.fetchingMore

  return (
    <Container>
      <SearchEngineOptimization seo={seo} />
      <LayoutGrid>
        <LayoutRow>
          <LayoutCol span={{ mobile: 10, tablet: 10, desktop: 12 }}>
            <BreadCrumbs>
              <BreadCrumb title='Home' href='/' />
              <BreadCrumb title={baseCategory?.name} href={`/${baseCategory?.canonicalUrl}`} />
            </BreadCrumbs>
            <Choose>
              <When condition={!!baseCategory?.pageContent}>
                <For each='section' of={baseCategory.pageContent.content.sectionGroups}>
                  <PageSectionGroup pageSectionGroup={section} key={section.id} />
                </For>
              </When>
              <Otherwise>
                <BaseCategoryHeader category={baseCategory} />
              </Otherwise>
            </Choose>
          </LayoutCol>
        </LayoutRow>
        <LayoutRow className='content-row'>
          <If condition={hasFilters}>
            <LayoutCol span={{ mobile: 10, tablet: 3, desktop: 3 }}>
              <ProductFilterContainer>
                <ProductFilters
                  productCount={productCount}
                  open={state.mobileFiltersOpen}
                  aggregations={aggregations}
                  loading={aggreationLoading}
                  filters={filters}
                  onFilterChange={_handleFiltersChange}
                  onToggleFilters={_handleToggleFilter} />
              </ProductFilterContainer>
            </LayoutCol>
          </If>
          <LayoutCol span={{ mobile: 10, tablet: hasFilters ? 7 : 10, desktop: hasFilters ? 9 : 12 }}>
            <ProductGridContainer>
              <Choose>
                <When condition={isFiltered}>
                  <CatalogueHeader
                    aggregations={aggregations}
                    filters={filters}
                    count={aggregationData?.allProducts?.totalCount}
                    loading={aggreationLoading}
                    displayType={state.displayType}
                    order={order}
                    onDisplayTypeChange={_handleDisplayTypeChange}
                    onOrderChange={_handleOrderChange}
                    onToggleFilters={_handleToggleFilter}
                    onFilterChange={_handleFiltersChange} />
                  <ProductGrid
                    userFilters={filters}
                    filters={combinedFilters}
                    order={order}
                    productsPerPage={PRODUCTS_PER_PAGE}
                    currentPage={currentPage}
                    overwriteBreadCrumbs={overwriteBreadcrumbs}
                    displayType={state.displayType}
                    category={baseCategory}
                    onFetchMore={_handleFetchMore}
                    onFilterChange={_handleFiltersChange}
                    onLoaded={_handleAllProductsLoaded} />
                </When>
                <Otherwise>
                  <CategoryRows
                    overwriteBreadCrumbs={overwriteBreadcrumbs}
                    category={baseCategory}
                    loading={categoryLoading}
                    onLoaded={_handleAllProductsLoaded} />
                </Otherwise>
              </Choose>
            </ProductGridContainer>
          </LayoutCol>
        </LayoutRow>
      </LayoutGrid>
    </Container>
  )

}
