import React, { useEffect } from 'react'

import { useNavigate } from 'react-router'

import { useEvents } from '@contexts/GTMProvider'
import { BaseCategoryFragment, BreadcrumbFragment, ProductListFragment, useGetBaseCategoryChildrenQuery } from '@hooks/api'

import { ProductSlider } from './ProductSlider'
import { ProductSliderSkeleton } from './ProductSliderSkeleton'

export interface BaseCategoryChildProps {
  child: BaseCategoryFragment['children'][0]
  overwriteBreadCrumbs?: BreadcrumbFragment[]
  basePath?: string
  onLoaded?: () => void
}

export function BaseCategoryChild({ child, overwriteBreadCrumbs, basePath, onLoaded }: BaseCategoryChildProps): JSX.Element {

  const { data: childData, loading } = useGetBaseCategoryChildrenQuery({
    variables: {
      id: child.uid,
    },
  })
  const events = useEvents()
  const navigate = useNavigate()

  const _handleViewAll = (slug: string): void => {
    const parentCategory = window.location.pathname.split('categories')?.[1] || ''
    navigate(`${parentCategory}/${slug}`)
  }

  const subCategory = childData?.categories?.items?.[0]

  useEffect(() => {
    if (subCategory) {
      onLoaded?.()
      events.hasViewedCatalogue(subCategory, subCategory.products.items as ProductListFragment[], {
        productsPerPage: 10,
        totalProducts: subCategory.products.totalCount,
        currentPage: 1,
        sortOrder: 'default',
        sortDirection: 'asc',
        displayMode: 'grid',
      })
    }
  }, [subCategory])

  return (
    <Choose>
      <When condition={!subCategory?.products}>
        <ProductSliderSkeleton productCount={10} />
      </When>
      <When condition={!!subCategory?.products?.items?.length}>
        <ProductSlider
          overwriteBreadCrumbs={overwriteBreadCrumbs}
          basePath={basePath}
          loading={loading}
          title={subCategory.name}
          total={subCategory.products.totalCount}
          onViewAll={() => _handleViewAll(subCategory.canonicalUrl)}
          products={subCategory.products.items as ProductListFragment[]} />
      </When>
    </Choose>

  )

}
