
import React from 'react'
import { Svg } from './Svg'

export interface Home_1Props {
  color?: string
  hoverColor?: string
  className?: string
}

export function Home_1({ color, hoverColor, className }: Home_1Props): JSX.Element {

  return (
    <Svg strokeColor={color} strokeHoverColor={hoverColor} fillColor={color} fillHoverColor={hoverColor} className={className} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 85.04 85.04">
      <g>
        <path className='fill' strokeWidth="2" strokeLinecap="square" d="M57.22,41.47c-0.82,0-1.49,0.64-1.49,1.44V60.9H29.58V42.96c0-0.79-0.67-1.44-1.49-1.44s-1.49,0.64-1.49,1.44v18.29 c0,1.39,1.18,2.52,2.64,2.52h26.83c1.46,0,2.64-1.13,2.64-2.52V42.91C58.71,42.12,58.05,41.47,57.22,41.47z" />
        <path className='fill' strokeWidth="2" strokeLinecap="square" d="M62.08,40.4L43.47,21.9c-0.53-0.53-1.36-0.53-1.9,0L22.96,40.4c-0.55,0.55-0.59,1.45-0.1,2.05c0.25,0.3,0.6,0.48,0.98,0.5 c0.38,0.03,0.74-0.12,1.02-0.39L42.52,25l17.66,17.56c0.26,0.25,0.59,0.4,0.95,0.4c0.4,0,0.78-0.18,1.05-0.51 C62.67,41.85,62.63,40.95,62.08,40.4z" />
      </g>
    </Svg>
  )

}
    