import React from 'react'

import { useNavigate } from 'react-router'
import styled from 'styled-components'

import { ResponsiveImage } from '@atoms/images'
import { Title } from '@atoms/typography'
import { ResponsivePXValue } from '@components/Theme'
import { PageBlockFragment } from '@hooks/api/index'
import { PageHorizontalAlignment } from '@uctypes/api/globalTypes'

import { HTML } from './HTML'

const Container = styled.a<{ href: string }>`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  ${({ href }) => !href && 'pointer-events: none;'}
`

const ImageContainer = styled.div`
  width: 100%;
  flex: 1;
`

const ContentContainer = styled.div<{ alignment: string, backgroundColor: string }>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  display: flex;
  flex-direction: column;
  align-items: ${({ alignment }): string => alignment === PageHorizontalAlignment.LEFT
    ? 'flex-start'
    : alignment === PageHorizontalAlignment.RIGHT
      ? 'flex-end'
      : 'center'};
  ${ResponsivePXValue('gap', { mobile: '12px', tablet: '16px', desktop: '16px' })}
  ${ResponsivePXValue('padding', { mobile: '16px', tablet: '24px', desktop: '24px' })}
  flex: 1 1 auto;


`

const HeadingContainer = styled.div`
  white-space: normal;
`

const DescriptionContainer = styled.div`
  white-space: normal;
`

export interface PageImageTitleBlockProps {
  block: PageBlockFragment
}

export function PageImageTitleBlock({ block }: PageImageTitleBlockProps): JSX.Element {

  const { title, image, horizontalAlignment, description, backgroundColor, foregroundColor, href } = block

  const navigate = useNavigate()

  const _handleClick = (e: React.MouseEvent<HTMLAnchorElement>): void => {
    e.preventDefault()
    if (href) {
      navigate(href)
    }
  }

  const isInternalLink = (url: string) => {
    try {
      return new URL(url).hostname === (typeof window !== 'undefined' && window.location.hostname)
    } catch (error) {
      return false
    }
  }

  const alignment = horizontalAlignment === PageHorizontalAlignment.LEFT
    ? 'left'
    : horizontalAlignment === PageHorizontalAlignment.RIGHT
      ? 'right'
      : 'center'

  return (
    <Container href={href} onClick={() => isInternalLink(href) && _handleClick}>
      <ImageContainer>
        <ResponsiveImage
          image={image}
          objectFit='contain' />
      </ImageContainer>
      <ContentContainer
        alignment={horizontalAlignment}
        backgroundColor={backgroundColor}>
        <HeadingContainer>
          <Title
            variant='t3'
            align={alignment}
            color={foregroundColor}>
            {title}
          </Title>
        </HeadingContainer>
        <If condition={!!description}>
          <DescriptionContainer>
            <HTML align={alignment}>
              {description}
            </HTML>
          </DescriptionContainer>
        </If>
      </ContentContainer>
    </Container>
  )
}
