import React from 'react'

import styled from 'styled-components'

import { ResponsiveImage } from '@atoms/images'
import { Link, Paragraph } from '@atoms/typography'
import { ResponsivePXValue } from '@components/Theme'
import { PageSiteNoticeSectionFragment } from '@hooks/api/index'

const Container = styled.div<{ backgroundColor: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  ${ResponsivePXValue('gap', '12px')}
  width: 100%;
  ${ResponsivePXValue('padding', '10px 0')}
  background-color: ${({ backgroundColor }) => backgroundColor};
`

const IconContainer = styled.div`
  ${ResponsivePXValue('height', '32px')}
  ${ResponsivePXValue('width', '32px')}
`

export interface PageSiteNoticeSectionProps {
  section: PageSiteNoticeSectionFragment
}

export function PageSiteNoticeSection({ section }: PageSiteNoticeSectionProps): JSX.Element {

  const { link, backgroundColor, foregroundColor, image } = section

  return (
    <Container backgroundColor={backgroundColor}>
      <IconContainer>
        <ResponsiveImage image={image} objectFit="cover"/>
      </IconContainer>
      <Paragraph variant='p2'>
        <Link href={link.url || '#'} color={foregroundColor} hoverColor={foregroundColor}>{link.title}</Link>
      </Paragraph>
    </Container>
  )
}
