import React from 'react'

import styled from 'styled-components'

import { ResponsivePXValue, theme } from '@components/Theme'

const getColor = (color: string): string => {
  switch (color) {
    case 'gallery':
      return theme.colors.grey.gallery
    case 'pampas':
      return theme.colors.white.pampas
    case 'pureWhite':
      return theme.colors.white.pureWhite
  }
}

interface SkeletonSizeInterface {
  mobile: string
  tablet: string
  desktop: string
}

interface SkeletonContainerInterface {
  width?: string | SkeletonSizeInterface
  height?: string | SkeletonSizeInterface
  color?: 'gallery' | 'pampas' | 'pureWhite'
  direction?: 'row' | 'column'
  justify?: 'space-evenly' | 'space-between' | 'center' | 'flex-start' | 'flex-end'
  align?: 'center' | 'flex-start' | 'flex-end'
  gap?: string | SkeletonSizeInterface
  padding?: string | SkeletonSizeInterface
  margin?: string | SkeletonSizeInterface
  loop?: number
}

const SkeletonContainer = styled.div<SkeletonContainerInterface>`
  display: flex;

  align-items: ${(props): string => (props.justify) ? props.align : 'center'};
  justify-content: ${(props): string => (props.justify) ? props.justify : 'space-evenly'};
  ${(props): string => ResponsivePXValue('width', props.width)}
  ${(props): string => ResponsivePXValue('height', props.height)}
  ${(props): string => (props.padding) ? ResponsivePXValue('padding', props.padding) : ''}
  ${(props): string => (props.margin) ? ResponsivePXValue('margin', props.margin) : ''}
  ${(props): string => (props.gap) ? ResponsivePXValue('gap', props.gap) : ''}

  flex-direction: ${(props): string => props.direction};
  background-color: ${(props): string => getColor(props.color)};
`

interface SkeletonProps extends SkeletonContainerInterface{
  className?: string
  children?: React.ReactNode | React.ReactNode[]
}

export function Skeleton({
  width = '100%',
  height = '100%',
  color = 'pureWhite',
  direction = 'row',
  justify,
  align,
  gap,
  padding,
  margin,
  loop,
  className = '',
  children,
}: SkeletonProps): JSX.Element {

  return (
    <SkeletonContainer className={className} width={width} height={height} color={color} direction={direction} gap={gap} padding={padding} align={align} justify={justify} margin={margin}>
      <Choose>
        <When condition={(!!loop && loop > 0)}>
          <For each="item" index="index" of={[...Array(loop).keys()]}>
            {children}
          </For>
        </When>
        <Otherwise>
          {children}
        </Otherwise>
      </Choose>
    </SkeletonContainer>
  )
}

interface SkeletonNodeProps extends SkeletonProps {
  shape?: 'circle' | 'square' | 'rounded'
  children?: React.ReactNode | React.ReactNode[]
  variWidth?: SkeletonSizeInterface
}

const SkeletonNodeContainer = styled.div<SkeletonNodeProps>`
  display: flex;

  ${(props): string => {
    switch (props.shape) {
      case 'circle':
        return ResponsivePXValue('border-radius', '50%')
      case 'square':
        return ResponsivePXValue('border-radius', '0')
      case 'rounded':
        return ResponsivePXValue('border-radius', { mobile: '6px', tablet: '6px', desktop: '6px' })
    }
  }}
  
  align-items: ${(props): string => (props.align) ? props.align : 'center'};
  justify-content: ${(props): string => (props.justify) ? props.justify : 'space-evenly'};
  flex-direction: ${(props): string => props.direction};
  ${(props): string => ResponsivePXValue('width', props.width)}
  ${(props): string => ResponsivePXValue('height', props.height)}
  ${(props): string => (props.padding) ? ResponsivePXValue('padding', props.padding) : ''}
  ${(props): string => (props.gap) ? ResponsivePXValue('gap', props.gap) : ''}

  background-color: ${(props): string => getColor(props.color)};
`

export function SkeletonNode({ width = '100%', height = '100%', color = 'pureWhite', shape = 'square', padding, justify, align, direction, gap, variWidth, children }: SkeletonNodeProps): JSX.Element {

  const getVariableDimensions = (vari: {[k:string]: string}): SkeletonSizeInterface => {

    if (typeof vari === 'string') {
      return vari
    }

    const returnValue = {} as {[k:string]: string}

    for (const device in vari) {
      const split = vari[device].split('-')
      if (split.length === 1) {
        returnValue[device] = vari[device]
        continue
      }
      const unit = split[0].replace(/\d+/g, '')
      const deviceMin = parseInt(split[0].match(/\d+/)[0])
      const deviceMax = parseInt(split[1].match(/\d+/)[0])
      returnValue[device] = `${Math.floor(Math.random() * (deviceMax - deviceMin + 1) + deviceMin) + unit}`
    }

    return returnValue as unknown as SkeletonSizeInterface

  }

  if (variWidth) {
    width = getVariableDimensions(variWidth as unknown as {[k:string]: string})
  }

  return (
    <SkeletonNodeContainer width={width} height={height} color={color} shape={shape} padding={padding} justify={justify} align={align} gap={gap} direction={direction}>
      {children}
    </SkeletonNodeContainer>
  )
}
