import { ApolloClient, FieldFunctionOptions, gql, makeVar, NormalizedCacheObject, Reference, StoreObject, TypePolicies } from '@apollo/client'

import { DocumentNode } from 'graphql'

import { Config, ConfigPlugin } from '@lib/Config'

interface WishlistSku { wishlistItemId: number, wishlistId: number, type: string }

interface Wishlist { [k: string]: WishlistSku }

const isBrowser = (): boolean => {
  return (typeof window !== 'undefined')
}

export interface WishlistAddon {
  open: boolean
}

const CART_DEFAULT_STATE = {
  open: false,
}

const _data = makeVar<WishlistAddon>({ ...CART_DEFAULT_STATE })

export class WishlistPlugin implements ConfigPlugin {

  static instance: WishlistPlugin

  static shared(): WishlistPlugin {
    if (!this.instance) {
      this.instance = new WishlistPlugin()
    }
    return this.instance
  }

  client!: ApolloClient<NormalizedCacheObject>

  open(): void {
    _data({ open: true })
  }

  close(): void {
    _data({ open: false })
  }

  diffWishlistQuantities(current: Wishlist, previous: Wishlist) {
    const cache = this.client.cache
    const allItems: Wishlist = { ...previous, ...current }
    Object.keys(allItems).forEach((sku) => {
      const product = {
        sku,
        __typename: allItems[sku].type,
      }
      cache.evict({ id: cache.identify(product as unknown as StoreObject), fieldName: 'isInWishlist' })
      cache.evict({ id: cache.identify(product as unknown as StoreObject), fieldName: 'wishlistItemId' })
      cache.evict({ id: cache.identify(product as unknown as StoreObject), fieldName: 'wishlistd' })
    })
  }

  async configure(config: Config): Promise<void> {
    const client = await config.getClient()
    this.client = client
  }

  typePolicies = (): TypePolicies => ({
    Wishlist: {
      fields: {
        open: {
          read: (current: boolean, options: FieldFunctionOptions): boolean => {
            if (isBrowser()) {
              const previousWishlistQuantities = JSON.parse(sessionStorage.getItem('WISHLIST') || '{}')
              const currentWishlistQuantities: Wishlist = {}
              const wishlistId = options.readField('id') as string
              const itemsV2 = options.readField('itemsV2') as Reference
              const items = options.readField('items', itemsV2) as readonly Reference[]
              if (items) {
                for (let i = 0; i < items.length; i++) {
                  const itemRef = items[i]
                  const wishlistItemId = options.readField('id', itemRef) as string
                  const productRef = options.readField('product', itemRef) as Reference
                  const sku = options.readField('sku', productRef) as string
                  const type = options.readField('__typename', productRef) as string
                  currentWishlistQuantities[sku] = { wishlistItemId: parseInt(wishlistItemId), wishlistId: parseInt(wishlistId), type }
                }
              }
              sessionStorage.setItem('WISHLIST', JSON.stringify(currentWishlistQuantities))
              sessionStorage.setItem('PREVIOUS_WISHLIST', JSON.stringify(previousWishlistQuantities))
              setTimeout(() => {
                if (JSON.stringify(currentWishlistQuantities) !== JSON.stringify(previousWishlistQuantities)) {
                  this.diffWishlistQuantities(currentWishlistQuantities, previousWishlistQuantities)
                }
              }, 100)
              return _data().open
            }
            return false
          },
        },
      },
    },
  })

  extensions = (): DocumentNode => gql`
    extend type Wishlist {
      open: Boolean!
    }
  `

}
