
import React from 'react'
import { Svg } from './Svg'

export interface LargeCrossProps {
  color?: string
  hoverColor?: string
  className?: string
}

export function LargeCross({ color, hoverColor, className }: LargeCrossProps): JSX.Element {

  return (
    <Svg strokeColor={color} strokeHoverColor={hoverColor} fillColor={color} fillHoverColor={hoverColor} className={className} width="100%" height="100%" viewBox="0 0 20 20" version="1.1">
      <g transform="matrix(1,0,0,1,1,1)">
        <path className='stroke' d="M17,17L1,1M17,1L1,17" />
      </g>
    </Svg>
  )

}
    