import React from 'react'

import styled from 'styled-components'

import { ResponsivePXValue } from '@components/Theme'

export type TagVariant = 't1' | 't2' | 't3' | 't4' | 't5'
export type DisplayVariant = 'block' | 'inline-block'
export type TagAlignment = 'left' | 'center' | 'right' | 'justify'
export type TagTransforms = 'none' | 'capitalize' | 'uppercase' | 'lowercase'
export type TagDecorations = 'none' | 'underline' | 'overline' | 'line-through'

const T1 = styled.div<{ align: string, decoration: string, transform: string, color?: string, display: string }>`

  color: ${(props): string => props.color ? props.color : props.theme.colors.green.greenVogue};
  text-align: ${(props): string => props.align};
  display: ${(props): string => props.display};
  text-decoration: ${(props): string => props.decoration};
  text-transform: ${(props): string => props.transform};
  line-height: 1.2;
  font-family: open-sans;
  font-weight: 400;
  cursor: pointer;

  ${ResponsivePXValue('font-size', { mobile: '10px', tablet: '12px', desktop: '12px' })}
  ${ResponsivePXValue('margin', { mobile: '0 0 0 5px', tablet: '0 0 0 5px', desktop: '0 0 0 10px' })}

  p {
    ${ResponsivePXValue('margin', '12px 0 10px')}
  }

`

const T2 = styled.div<{ align: string, decoration: string, transform: string, color?: string, display: string }>`

  color: ${(props): string => props.color ? props.color : props.theme.colors.green.greenVogue};
  text-align: ${(props): string => props.align};
  display: ${(props): string => props.display};
  text-decoration: ${(props): string => props.decoration};
  text-transform: ${(props): string => props.transform};
  line-height: 1.2;
  font-family: open-sans;
  font-weight: 400;
  cursor: pointer;

  ${ResponsivePXValue('font-size', { mobile: '10px', tablet: '12px', desktop: '12px' })}
  ${ResponsivePXValue('margin', { mobile: '12px 5px 10px', tablet: '12px 0 10px', desktop: '12px 0 10px' })}
  p {
    ${ResponsivePXValue('margin', '12px 0 10px')}
  }

`

const T3 = styled.div<{ align: string, decoration: string, transform: string, color?: string, display: string }>`

  color: ${(props): string => props.color ? props.color : props.theme.colors.green.greenVogue};
  text-align: ${(props): string => props.align};
  display: ${(props): string => props.display};
  text-decoration: ${(props): string => props.decoration};
  text-transform: ${(props): string => props.transform};
  line-height: 1.2;
  font-family: open-sans;
  font-weight: 400;
  cursor: pointer;

  ${ResponsivePXValue('font-size', '10px')}
  ${ResponsivePXValue('margin', '10px 0 8px')}
  p {
    ${ResponsivePXValue('margin', '10px 0 8px')}
  }

`

const T4 = styled.div<{ align: string, decoration: string, transform: string, color?: string, display: string }>`

  color: ${(props): string => props.color ? props.color : props.theme.colors.green.bottleGreen};
  text-align: ${(props): string => props.align};
  display: ${(props): string => props.display};
  text-decoration: ${(props): string => props.decoration};
  text-transform: ${(props): string => props.transform};
  line-height: 1.2;
  font-family: open-sans;
  font-weight: 700;
  cursor: pointer;

  ${ResponsivePXValue('font-size', { mobile: '12px', tablet: '14px', desktop: '14px' })}

  p {
    ${ResponsivePXValue('margin', '10px 0 8px')}
  }

`

const T5 = styled.div<{ align: string, decoration: string, transform: string, color?: string, display: string }>`

  color: ${(props): string => props.color ? props.color : props.theme.colors.green.bottleGreen};
  text-align: ${(props): string => props.align};
  display: ${(props): string => props.display};
  text-decoration: ${(props): string => props.decoration};
  text-transform: ${(props): string => props.transform};
  line-height: 1.2;
  font-family: open-sans;
  font-weight: 600;
  cursor: pointer;

  ${ResponsivePXValue('font-size', { mobile: '10px', tablet: '12px', desktop: '12px' })}

  p {
    ${ResponsivePXValue('margin', '10px 0 8px')}
  }

`

export interface TagProps {
  children: React.ReactNode
  align?: TagAlignment
  transform?: TagTransforms
  decoration?: TagDecorations
  color?: string
  display?: DisplayVariant
  skeleton?: boolean
  className?: string
  variant?: TagVariant
  onClick?: () => void
}

export function Tag({ children, onClick, variant = 't1', align = 'left', transform = 'none', decoration = 'none', color, className, display = 'block' }: TagProps): JSX.Element {

  switch (variant) {

    case 't1':
      return <T1
        onClick={onClick}
        align={align}
        decoration={decoration}
        transform={transform}
        color={color}
        display={display}
        className={className}>
        {children}
      </T1>
    case 't2':
      return <T2
        onClick={onClick}
        align={align}
        decoration={decoration}
        transform={transform}
        color={color}
        display={display}
        className={className}>
        {children}
      </T2>
    case 't3':
      return <T3
        onClick={onClick}
        align={align}
        decoration={decoration}
        transform={transform}
        color={color}
        display={display}
        className={className}>
        {children}
      </T3>
    case 't4':
      return <T4
        onClick={onClick}
        align={align}
        decoration={decoration}
        transform={transform}
        color={color}
        display={display}
        className={className}>
        {children}
      </T4>
    case 't5':
      return <T5
        onClick={onClick}
        align={align}
        decoration={decoration}
        transform={transform}
        color={color}
        display={display}
        className={className}>
        {children}
      </T5>
  }

}
