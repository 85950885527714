import React from 'react'

import { useNavigate } from 'react-router'
import styled, { useTheme } from 'styled-components'

import { NavigationPlugin } from '@api/local/NavigationPlugin'
import { Link, Paragraph } from '@components/atoms'
import { ResponsivePXValue } from '@components/Theme'
import { SearchResultFragment, SearchTermFragment, ProductSearchFragment, CategorySuggestionFragment } from '@hooks/api/index'
import { CategorySearchItem, ProductSearchItem } from '@molecules/index'
import { TermSearchItem } from '@molecules/navigation/TermSearchItem'
import { ProductTypeEnum } from '@uctypes/api/globalTypes'

const SearchContainer = styled.div`
  background-color: ${(props): string => props.theme.colors.white.pureWhite};
  ${ResponsivePXValue('margin-bottom', { mobile: '75px' })}
  .heading {
    color: ${(props): string => props.theme.colors.green.greenVogue};
    background-color: ${(props): string => props.theme.colors.white.fantasy};
    ${ResponsivePXValue('padding', '12px 16px')}
  }

`

const ViewAllContainer = styled.div`
  ${ResponsivePXValue('margin-bottom', { mobile: '16px', tablet: '16px', desktop: '16px' })}
`

const PRODUCT_TYPES = Object.keys(ProductTypeEnum)

const ResultContainer = styled.div`
    ${ResponsivePXValue('padding', '6px 16px')}
  
`

export interface SearchResultsProps {
  results: SearchResultFragment[]
  searchTerm: string
  onSelect?: (path: string) => void
}

export function SearchResults({ results, searchTerm, onSelect }: SearchResultsProps): JSX.Element {

  const theme = useTheme()
  const navigate = useNavigate()

  const _handleSeeAll = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, href: string): void => {
    e.preventDefault()
    NavigationPlugin.shared().closeSearch()
    navigate(href)
    onSelect(href)
  }

  const terms = results?.filter?.((result) => result.__typename === 'SearchTerm') || []
  const products = results?.filter?.((result) => PRODUCT_TYPES.includes(result.__typename)) || []
  const categories = results?.filter?.((result) => result.__typename === 'CategorySuggestion') || []

  let term: SearchTermFragment
  let product: ProductSearchFragment
  let category: CategorySuggestionFragment

  return (
    <SearchContainer>

      <If condition={!!categories.length}>
        <Paragraph variant='p1' className='heading' bold color={theme.colors.green.greenVogue}>Categories</Paragraph>
        <ResultContainer>
          <For each='category' of={categories}>
            <CategorySearchItem item={category} key={category.uid} onSelect={onSelect} />
          </For>
        </ResultContainer>
      </If>

      <If condition={!!terms.length}>
        <Paragraph variant='p1' className='heading' bold color={theme.colors.green.greenVogue}>Popular Searches</Paragraph>
        <ResultContainer>
          <For each='term' of={terms}>
            <TermSearchItem item={term} key={term.queryText} onClick={onSelect} />
          </For>
        </ResultContainer>
      </If>

      <If condition={!!products.length}>
        <Paragraph variant='p1' className='heading' bold color={theme.colors.green.greenVogue}>Products</Paragraph>
        <ResultContainer>
          <For each='product' of={products}>
            <ProductSearchItem item={product} key={product.uid} onSelect={onSelect} />
          </For>
        </ResultContainer>
      </If>
      <If condition={!!products.length || !!terms.length}>
        <ViewAllContainer>
          <Paragraph variant='p1' align='center'>
            <Link href={`/search?term=${searchTerm}`} onClick={_handleSeeAll}>See all results</Link>
          </Paragraph>
        </ViewAllContainer>
      </If>
    </SearchContainer>
  )

}
