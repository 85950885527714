import React from 'react'

import { Button } from '@atoms/buttons'
import { LocalIconEnums } from '@atoms/index'
import { useConfig } from '@contexts/ConfigProvider'
import FacebookLogin, { FailResponse, SuccessResponse } from '@greatsumini/react-facebook-login'

interface FacebookLoginButtonProps {
  onSuccess: (res: SuccessResponse) => void
  onFail: (error: FailResponse) => void
  forSignUp?: boolean
}

export function FacebookLoginButton({ onSuccess, forSignUp = false }: FacebookLoginButtonProps): JSX.Element {
  const config = useConfig()
  const facebookKey = config.getFacebookKey()

  return (
    <FacebookLogin
      appId={facebookKey}
      onSuccess={onSuccess}
      render={renderProps => (
        <Button
          variant='ghost'
          size='medium'
          className='signup-button form-button'
          title={forSignUp ? 'SIGN UP WITH' : 'LOGIN WITH'}
          icon={LocalIconEnums.FACEBOOK}
          onClick={renderProps.onClick} />
      )}
    />
  )
}
