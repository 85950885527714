import { APP_DEFAULT_STATE } from "@api/local/AppPlugin"
import { PageBlockFragment, useGetAppQuery } from "@hooks/api"
import React from "react"
import { HTML } from "./HTML"
import { Link } from "@atoms/typography/Link"
import styled from "styled-components"
import { ResponsivePXValue } from "@components/Theme"
import { MobileOSTypeEnum } from "@uctypes/api/globalTypes"

const Container = styled.div<{ bgColor: string }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    ${ResponsivePXValue('gap', '12px')}
    width: 100%;
    background-color: ${(props): string => props.bgColor};
    ${ResponsivePXValue('padding', '20px 12px 20px 12px')}
`

interface PageMobileAppMessageBlockProps {
    block: PageBlockFragment
}

export function PageMobileAppMessageBlock({ block }: PageMobileAppMessageBlockProps): JSX.Element {
    
  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()

  const isApp = appData.app.isNativeApp
    return (
        <If condition={isApp}>
            <Container bgColor={block.backgroundColor}>
                <HTML color={block.foregroundColor} align='center'>
                    {block.description}
                </HTML>
                <Link href={block.link.url} bold decoration='underline' color={block.foregroundColor}>
                    {block.link.title}
                </Link>
            </Container>
        </If>
    )
}