
import React from 'react'
import { Svg } from './Svg'

export interface FacebookSolidProps {
  color?: string
  hoverColor?: string
  className?: string
}

export function FacebookSolid({ color, hoverColor, className }: FacebookSolidProps): JSX.Element {

  return (
    <Svg strokeColor={color} strokeHoverColor={hoverColor} fillColor={color} fillHoverColor={hoverColor} className={className} width="100%" height="100%" viewBox="0 0 20 20" version="1.1">
      <g transform="matrix(0.749975,0,0,0.749975,1.00031,1.00031)">
        <path className='stroke' d="M0,12.067C0,18.033 4.333,22.994 10,24L10,15.333L7,15.333L7,12L10,12L10,9.333C10,6.333 11.933,4.667 14.667,4.667C15.533,4.667 16.467,4.8 17.333,4.933L17.333,8L15.8,8C14.333,8 14,8.733 14,9.667L14,12L17.2,12L16.667,15.333L14,15.333L14,24C19.667,22.994 24,18.034 24,12.067C24,5.43 18.6,0 12,0C5.4,0 0,5.43 0,12.067Z" />
      </g>
    </Svg>
  )

}
    