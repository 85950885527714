
import React from 'react'
import { Svg } from './Svg'

export interface ThumbsUpProps {
  color?: string
  hoverColor?: string
  className?: string
}

export function ThumbsUp({ color, hoverColor, className }: ThumbsUpProps): JSX.Element {

  return (
    <Svg strokeColor={color} strokeHoverColor={hoverColor} fillColor={color} fillHoverColor={hoverColor} className={className} width="100%" height="100%" viewBox="0 0 20 20">
      <g transform="matrix(1.21488,0,0,1.15365,-922.95,-2624.4)">
        <path d="M771.864,2290.47L763.175,2290.47C762.688,2290.47 762.222,2290.27 761.887,2289.91C761.542,2289.55 761.35,2289.08 761.353,2288.58L761.353,2284.16C761.35,2283.67 761.542,2283.19 761.887,2282.83C762.222,2282.47 762.688,2282.27 763.175,2282.27L764.6,2282.27L767.03,2276.6L767.425,2276.6C768.074,2276.6 768.696,2276.87 769.143,2277.34C769.604,2277.82 769.859,2278.46 769.855,2279.12L769.855,2281.01L772.687,2281.01C772.949,2281.01 773.208,2281.07 773.446,2281.18C773.689,2281.29 773.904,2281.46 774.076,2281.66C774.249,2281.87 774.377,2282.11 774.451,2282.38C774.524,2282.64 774.542,2282.92 774.503,2283.19L773.664,2288.86C773.6,2289.31 773.38,2289.72 773.042,2290.02C772.717,2290.31 772.298,2290.47 771.864,2290.47ZM765.606,2289.21L771.851,2289.21C772.001,2289.22 772.148,2289.16 772.258,2289.06C772.371,2288.96 772.444,2288.82 772.465,2288.67L773.3,2283C773.313,2282.91 773.307,2282.82 773.283,2282.73C773.258,2282.64 773.216,2282.56 773.158,2282.49C773.1,2282.42 773.028,2282.37 772.947,2282.33C772.867,2282.29 772.78,2282.28 772.692,2282.28L768.639,2282.28L768.639,2279.12C768.641,2278.79 768.513,2278.47 768.283,2278.23C768.147,2278.09 767.979,2277.99 767.793,2277.92L765.602,2283.04L765.606,2289.21ZM763.175,2283.53C763.013,2283.53 762.858,2283.6 762.746,2283.72C762.632,2283.84 762.568,2284 762.568,2284.16L762.568,2288.58C762.568,2288.74 762.632,2288.9 762.746,2289.02C762.858,2289.14 763.013,2289.21 763.175,2289.21L764.39,2289.21L764.39,2283.53L763.175,2283.53Z" className='fill' />
      </g>
    </Svg>
  )

}
    