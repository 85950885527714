import React, { useState } from 'react'

import styled from 'styled-components'

import { ResponsivePXValue } from '@components/Theme'

import { SelectOption } from './SelectInput'

const Container = styled.div`
  position: relative;
`

const Dropdown = styled.div`
  position: absolute;
  z-index: 11;
  width: 100%;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  ${ResponsivePXValue('margin-top', '1px')}
  ${ResponsivePXValue('left', '0')}
  ${ResponsivePXValue('padding', '8px 0')}

  background-color: ${(props): string => props.theme.colors.white.pureWhite};
`

const DropdownItem = styled.div<{ selected: boolean }>`

  font-family: open-sans;
  border-width: 0;
  ${ResponsivePXValue('padding', { mobile: '8px 16px', tablet: '8px 16px', desktop: '8px 16px' })}
  ${ResponsivePXValue('font-size', { mobile: '11px', tablet: '11px', desktop: '11px' })}
  ${ResponsivePXValue('line-height', { mobile: '17px', tablet: '17px', desktop: '17px' })}

  font-weight: ${(props): string => props.selected ? '600' : '400'};

  color: ${(props): string => props.selected ? props.theme.colors.green.watercourse : props.theme.colors.green.bottleGreen};

  &:hover {
    background-color: ${(props): string => props.theme.colors.green.watercourse};
    color: ${(props): string => props.theme.colors.white.pureWhite};
  }
`
const Wrapper = styled.div``

export interface CustomSelectInputProps {
  children: React.ReactNode
  options: SelectOption[]
  getValue?: (value: string) => void
}

let option: SelectOption
let index: number

export function CustomSelectInput({ children, options, getValue }: CustomSelectInputProps): JSX.Element {

  const [open, setOpen] = useState<boolean>(false)
  const [value, setValue] = useState<string>('')

  function _click(newValue: string) {

    getValue(newValue.toString())
    setOpen(!open)
    setValue(newValue)
  }

  return (
    <Container onMouseLeave={() => setOpen(false)}>
      <Wrapper onClick={() => setOpen(!open)} >
        {children}
      </Wrapper>
      <If condition={!!open}>
        <Dropdown>
          <For each='option' index='index' of={options}>
            <DropdownItem selected={value === option.value.toString()} key={index} onClick={() => _click(option.value.toString())}>{option.title}</DropdownItem>
          </For>
        </Dropdown>
      </If>
    </Container>
  )

}
