
import React from 'react'
import { Svg } from './Svg'

export interface GreyOzowProps {
  color?: string
  hoverColor?: string
  className?: string
}

export function GreyOzow({ color, hoverColor, className }: GreyOzowProps): JSX.Element {

  return (
    <Svg strokeColor={color} strokeHoverColor={hoverColor} fillColor={color} fillHoverColor={hoverColor} className={className} width="100%" height="100%" viewBox="0 0 43 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M41.6 2.99195C40.9 2.99195 40.7 3.09195 40.5 3.79195C40.2 4.59195 40 5.39195 39.7 6.09195C39.7 6.19195 39.6 6.39195 39.5 6.59195C39.2 5.59195 38.9 4.69195 38.6 3.79195C38.3 2.99195 38.3 2.99195 37.5 2.99195C37.2 2.99195 37.1 3.09195 37 3.39195C36.7 4.39195 36.3 5.49195 36 6.49195H35.9C35.9 6.39195 35.8 6.19195 35.8 6.09195C35.5 5.29195 35.3 4.49195 35 3.69195C34.8 3.19195 34.6 2.99195 34.1 2.99195C33.9 2.99195 33.8 2.99195 33.6 2.99195C33.3 2.99195 33.3 3.09195 33.4 3.39195C33.9 4.89195 34.4 6.39195 34.9 7.89195C35.3 8.99195 35.3 8.99195 36.4 8.99195C36.6 8.99195 36.7 8.89195 36.8 8.69195C37.1 7.79195 37.4 6.89195 37.7 5.99195C37.7 5.89195 37.8 5.79195 37.9 5.59195C38.2 6.49195 38.5 7.29195 38.7 8.09195C39 9.09195 39 9.09195 40.1 9.09195C40.4 9.09195 40.5 8.99195 40.6 8.79195C41.1 7.19195 41.7 5.49195 42.2 3.89195C42.3 2.99195 42.3 2.99195 41.6 2.99195Z" fill="#EAEAEA"/>
      <path d="M30 2.89195C28.3 2.89195 26.8 4.29195 26.8 5.99195C26.8 7.69195 28.2 9.09195 29.9 9.09195C31.6 9.09195 33 7.69195 33 5.99195C33.1 4.29195 31.7 2.89195 30 2.89195ZM29.9 7.79195C28.9 7.79195 28.1 6.99195 28.1 5.99195C28.1 4.99195 28.9 4.29195 29.9 4.19195C30.9 4.19195 31.7 4.99195 31.7 5.99195C31.7 6.99195 30.9 7.79195 29.9 7.79195Z" fill="#EAEAEA"/>
      <path d="M17.6 2.89195C15.9 2.89195 14.5 4.29195 14.5 5.99195C14.5 7.69195 15.9 9.09195 17.6 9.09195C19.3 9.09195 20.7 7.69195 20.7 5.99195C20.7 4.29195 19.3 2.89195 17.6 2.89195ZM17.5 7.79195C16.5 7.79195 15.7 6.99195 15.8 5.99195C15.8 4.99195 16.6 4.29195 17.6 4.29195C18.6 4.29195 19.4 5.09195 19.4 6.09195C19.3 6.99195 18.5 7.79195 17.5 7.79195Z" fill="#EAEAEA"/>
      <path d="M25.7 7.69195C25 7.69195 24.3 7.69195 23.6 7.69195C23.5 7.69195 23.4 7.69195 23.3 7.69195C24.3 6.59195 25.2 5.59195 26.1 4.69195C26.5 4.29195 26.6 3.89195 26.5 3.39195C26.5 3.29195 26.3 3.19195 26.3 3.19195C24.7 3.19195 23.1 3.19195 21.5 3.19195C21.4 3.19195 21.3 3.29195 21.3 3.29195C21.1 3.89195 21.4 4.49195 22.1 4.49195C22.8 4.49195 23.6 4.49195 24.4 4.49195C24.3 4.59195 24.3 4.69195 24.2 4.79195C23.3 5.79195 22.4 6.89195 21.5 7.89195C21.2 8.29195 21.3 8.69195 21.3 9.09195C21.3 9.09195 21.4 9.19195 21.5 9.19195C23.1 9.19195 24.7 9.19195 26.3 9.19195C26.4 9.19195 26.5 9.09195 26.5 9.09195C26.7 8.19195 26.3 7.69195 25.7 7.69195Z" fill="#EAEAEA"/>
      <path d="M10.8 9.09195C10.5 8.89195 10.3 8.99195 9.90001 9.29195L9.80001 9.39195C8.80001 10.392 7.60001 10.992 6.20001 10.992C4.90001 10.992 3.80001 10.5919 2.80001 9.79195C2.60001 9.59195 2.30001 9.39195 2.20001 9.19195C2.10001 8.99195 2.10001 8.89195 2.30001 8.69195C2.40001 8.59195 2.50001 8.49195 2.60001 8.39195C2.70001 8.19195 2.80001 8.19195 3.00001 8.39195C3.20001 8.69195 3.40001 8.99195 3.80001 9.09195C4.80001 9.89195 5.90001 10.092 7.10001 9.89195C9.60001 9.39195 11 6.39195 9.80001 4.29195C9.60001 3.89195 9.30001 3.49195 9.00001 3.19195C8.80001 2.99195 8.50001 2.89195 8.30001 3.19195C8.10001 3.39195 8.10001 3.69195 8.30001 3.89195C8.60001 4.19195 8.80001 4.59195 9.00001 4.89195C9.20001 5.49195 9.30001 6.19195 9.10001 6.79195C8.80001 7.99195 7.40001 9.19195 5.90001 8.89195C5.40001 8.79195 4.90001 8.59195 4.50001 8.39195C4.10001 7.99195 3.80001 7.59195 3.50001 7.09195C3.30001 6.69195 2.90001 6.69195 2.60001 6.99195C2.10001 7.49195 1.60001 7.99195 1.10001 8.49195C0.900008 8.69195 0.800008 8.99195 1.00001 9.19195C1.30001 9.69195 1.60001 10.092 2.10001 10.492C3.90001 12.092 6.00001 12.4919 8.30001 11.7919C9.30001 11.4919 10.1 10.892 10.8 10.0919C11 9.59195 11 9.29195 10.8 9.09195Z" fill="#EAEAEA"/>
      <path d="M3.5 4.49195C4.4 3.49195 5.4 2.89195 6.8 3.19195C7.1 3.29195 7.3 3.09195 7.4 2.79195C7.5 2.49195 7.3 2.29195 7 2.19195C6.7 2.09195 6.5 2.09195 6.2 2.09195C5.1 2.09195 4.1 2.49195 3.3 3.19195C2.3 4.19195 1.3 5.19195 0.3 6.19195C0.1 6.39195 0 6.69195 0 6.99195C0 7.19195 0 7.39195 0.2 7.49195C0.4 7.59195 0.5 7.39195 0.6 7.29195C1.6 6.29195 2.6 5.39195 3.5 4.49195Z" fill="#EAEAEA"/>
      <path d="M5.90001 4.09195C5.00001 4.19195 4.40001 4.79195 4.20001 5.59195C4.00001 6.39195 4.30001 7.19195 5.10001 7.59195C5.90001 8.09195 6.80001 7.99195 7.50001 7.39195C8.10001 6.79195 8.20002 5.89195 7.80001 5.09195C7.40001 4.39195 6.70001 3.99195 5.90001 4.09195ZM5.50001 7.19195C5.60001 7.09195 5.70001 7.09195 5.70001 6.99195C5.60001 7.09195 5.50001 7.09195 5.50001 7.19195ZM7.00001 5.69195C7.10001 6.49195 6.70001 6.89195 5.90001 6.79195C5.50001 6.59195 5.20001 6.29195 5.30001 5.79195C5.40001 5.39195 5.60001 5.19195 6.00001 5.09195C6.40001 5.09195 6.70001 5.29195 7.00001 5.69195Z" fill="#EAEAEA"/>
      <path d="M11.4 3.09195C11.4 2.99195 11.3 2.89195 11.3 2.89195C9.79997 0.59195 7.39997 -0.308053 4.99997 0.0919466C3.79997 0.291947 2.79997 0.891945 1.89997 1.69195C1.59997 1.99195 1.59997 2.29195 1.79997 2.49195C2.09997 2.79195 2.39997 2.69195 2.59997 2.49195C2.69997 2.39195 2.79997 2.29194 2.79997 2.29194C3.99997 1.29194 5.49997 0.891945 6.99997 1.19195C8.49997 1.49195 9.59997 2.39195 10.4 3.69195C10.7 4.29194 10.9 4.79195 11 5.49195C11 5.79195 11.2 5.99195 11.6 5.99195C12 5.99195 12.1 5.69195 12 5.39195C12 4.39195 11.8 3.69195 11.4 3.09195Z" fill="#EAEAEA"/>
    </Svg>

    

  )

}
