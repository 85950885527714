import React from 'react'

import styled from 'styled-components'

import { Icon, LocalIconEnums } from '@atoms/images'
import { Paragraph } from '@atoms/typography'
import { ResponsivePXValue, theme } from '@components/Theme'

const PageNotificationWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${ResponsivePXValue('gap', '10px')}

  ${ResponsivePXValue('height', { mobile: '48px', tablet: '48px', desktop: '48px' })}
  ${ResponsivePXValue('padding', { mobile: '8px 16px' })}


  background-color: ${(props): string => props.theme.colors.green.watercourse};
  .icon {
    ${ResponsivePXValue('width', '30px')}
    ${ResponsivePXValue('height', '25px')}
  }
  .text{
    padding: 0;
    margin: 0;
    font-weight: 100;
  }
`

export interface PageNotificationProps {
  text: string
  icon?: LocalIconEnums
}

export function PageNotification({ text, icon }: PageNotificationProps): JSX.Element {
  return (
    <PageNotificationWrapper>
      <If condition={!!icon}>
        <Icon className='icon' color={theme.colors.white.pureWhite} icon={icon} />
      </If>

      <Paragraph variant='p2' color={theme.colors.white.pureWhite} className='text' >{text}</Paragraph>
    </PageNotificationWrapper>
  )
}
