import React from 'react'

import styled from 'styled-components'

import { ResponsiveImage } from '@atoms/images'
import { Heading } from '@atoms/typography'
import { ResponsivePXValue } from '@components/Theme'
import { PageBlockFragment } from '@hooks/api/index'
import { PageHorizontalAlignment } from '@uctypes/api/globalTypes'

import { HTML } from './HTML'

const Container = styled.div<{ backgroundColor: string }>`
  width: 100%;
  ${ResponsivePXValue('min-height', '220px')}
  ${ResponsivePXValue('height', { desktop: '100%' })}
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  ${ResponsivePXValue('flex-direction', { mobile: 'column', tablet: 'row', desktop: 'row' })};
  ${ResponsivePXValue('align-items', { mobile: '', tablet: 'flex-start', desktop: 'flex-start' })}
  background-color: ${({ backgroundColor }): string => backgroundColor};
`

const ImageContainer = styled.div`
  flex-shrink: 0;
  ${ResponsivePXValue('height', { desktop: '100%' })}
  ${ResponsivePXValue('width', { mobile: '100%', tablet: '45%', desktop: '45%' })}
  ${ResponsivePXValue('max-width', { desktop: '350px' })}
`

const ContentContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  ${ResponsivePXValue('justify-content', { tablet: 'center', desktop: 'center' })}
  ${ResponsivePXValue('align-items', 'flex-start')}
  ${ResponsivePXValue('gap', { mobile: '16px', tablet: '12px', desktop: '12px' })}
  ${ResponsivePXValue('padding', { mobile: '24px 16px', tablet: '24px', desktop: '24px' })}
`

const TitleContainer = styled.div`
  .blockTitle{
    margin-top: 0;
  }
`

const DescriptionContainer = styled.div`
`

export interface PageBannerBlockProps {
  block: PageBlockFragment
}

export function PageBannerBlock({ block }: PageBannerBlockProps): JSX.Element {

  const { backgroundColor, foregroundColor, image, title, description, horizontalAlignment } = block

  const alignment = horizontalAlignment === PageHorizontalAlignment.LEFT
    ? 'left'
    : horizontalAlignment === PageHorizontalAlignment.RIGHT
      ? 'right'
      : 'center'

  return (
    <Container backgroundColor={backgroundColor}>
      <ImageContainer>
        <ResponsiveImage image={image} objectFit='cover' />
      </ImageContainer>
      <ContentContainer>
        <TitleContainer>
          <Heading variant='h3' color={foregroundColor} align={alignment} className='blockTitle'>
            {title}
          </Heading>
        </TitleContainer>
        <DescriptionContainer>
          <HTML color={foregroundColor} align={alignment} className='description'>
            {description}
          </HTML>
        </DescriptionContainer>
      </ContentContainer>

    </Container>
  )

}
