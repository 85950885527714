
import React from 'react'
import { Svg } from './Svg'

export interface PaymentVisaColorProps {
  color?: string
  hoverColor?: string
  className?: string
}

export function PaymentVisaColor({ color, hoverColor, className }: PaymentVisaColorProps): JSX.Element {

  return (
    <Svg strokeColor={color} strokeHoverColor={hoverColor} fillColor={color} fillHoverColor={hoverColor} className={className} width="100%" height="100%" viewBox="0 0 25 8" fill="none" xmlns="http://www.w3.org/2000/svg" keepColor="true">
      <path d="M10.857 8.00008H8.76172L10.0951 0.190552H11.9998L10.857 8.00008Z" fill={color ? color : '#00579F'} className={color ? '' : 'fill'} />
      <path d="M18.0942 0.38095C17.7133 0.190474 17.1418 0 16.3799 0C14.4752 0 12.9514 1.14286 12.9514 2.47619C12.9514 3.61905 13.9037 4.19048 14.6656 4.57143C15.4275 4.95238 15.618 5.14286 15.618 5.52381C15.618 6.09524 15.0466 6.28571 14.4752 6.28571C13.7133 6.28571 13.3323 6.09524 12.5704 5.90476L12.3799 5.71429L12.1895 7.42857C12.5704 7.61905 13.5228 7.80952 14.4752 7.80952C16.5704 7.80952 17.9037 6.85714 17.9037 5.14286C17.9037 4.19047 17.3323 3.61904 16.1895 3.04762C15.4275 2.66666 15.0466 2.47619 15.0466 2.09524C15.0466 1.71428 15.4275 1.33333 16.1895 1.33333C16.7609 1.33333 17.3323 1.52381 17.7133 1.71429H17.9037L18.0942 0.38095Z" fill={color ? color : '#00579F'} className={color ? '' : 'fill'} />
      <path d="M20.7615 5.14293C20.952 4.76198 21.5234 3.04769 21.5234 3.04769C21.5234 3.04769 21.7139 2.66674 21.7139 2.28579L21.9044 3.04769C21.9044 3.04769 22.2853 4.95246 22.2853 5.33341C22.0949 5.14293 21.1425 5.14293 20.7615 5.14293ZM23.2377 0.190552H21.7139C21.1425 0.190552 20.952 0.38103 20.5711 0.761983L17.5234 7.8096H19.6187C19.6187 7.8096 19.9996 6.85722 19.9996 6.66675C20.1901 6.66675 22.2853 6.66675 22.6663 6.66675C22.6663 6.85722 22.8568 7.8096 22.8568 7.8096H24.7615L23.2377 0.190552Z" fill={color ? color : '#00579F'} className={color ? '' : 'fill'} />
      <path d="M7.04809 0.190552L5.14332 5.52389L4.95285 4.38103C4.57189 3.23817 3.42904 1.71436 2.0957 1.14293L3.80999 8.00008H5.90523L9.14332 0.190552H7.04809V0.190552Z" fill={color ? color : '#00579F'} className={color ? '' : 'fill'} />
      <path d="M3.2381 0.190552H0V0.381029C2.47619 0.952457 4.19048 2.66674 4.95238 4.38103L4.19048 0.761983C4 0.38103 3.80953 0.190552 3.2381 0.190552Z" fill={color ? color : '#FAA61A'} className={color ? '' : 'fill'} />
    </Svg>
  )

}
    