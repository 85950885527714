import React from 'react'

import styled from 'styled-components'

import { Loader, Heading } from '@atoms/index'
import { ResponsivePXValue } from '@components/Theme'

const LoadingContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${ResponsivePXValue('padding', '50px')}
  ${ResponsivePXValue('width', { mobile: '100vw', tablet: '100vw', desktop: '600px' })}

  .modal-loading-title {
    ${ResponsivePXValue('margin', '10px')}
  }
`

export interface ModalLoaderProps {
  message?: string
}

export function ModalLoader({ message }: ModalLoaderProps): JSX.Element {

  return (
    <LoadingContainer>
      <Loader />
      <If condition={!!message}>
        <Heading variant='h4' className='modal-loading-title'>{message}</Heading>
      </If>
    </LoadingContainer>
  )

}
