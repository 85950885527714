import React, { useEffect } from 'react'

import { AppPlugin } from '@api/local/AppPlugin'
import { useGetStoreConfigQuery } from '@hooks/api'

export function StoreConfig(): JSX.Element {

  const { data: storeConfigData } = useGetStoreConfigQuery()
  useEffect(() => {
    if (storeConfigData?.storeConfig) {
      AppPlugin.shared().setBaseCategoryId(storeConfigData.storeConfig.rootCategoryUid)
      // Not sure what the correc value on store confid is for this
      AppPlugin.shared().setStoreId(1)
    }
  }, [storeConfigData?.storeConfig])

  return null

}
