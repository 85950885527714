
import React from 'react'
import { Svg } from './Svg'

export interface TrashOutlineProps {
  color?: string
  hoverColor?: string
  className?: string
}

export function TrashOutline({ color, hoverColor, className }: TrashOutlineProps): JSX.Element {

  return (
    <Svg strokeColor={color} strokeHoverColor={hoverColor} fillColor={color} fillHoverColor={hoverColor} className={className} width="100%" height="100%" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7 7L8.25 27C8.30937 28.1556 9.15 29 10.25 29H21.75C22.8544 29 23.6794 28.1556 23.75 27L25 7" className='stroke' strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path fillRule="evenodd" clipRule="evenodd" d="M4 7C4 6.44772 4.44772 6 5 6H27C27.5523 6 28 6.44772 28 7C28 7.55228 27.5523 8 27 8H5C4.44772 8 4 7.55228 4 7Z" className='fill' />
      <path d="M12 7.00001V4.50001C11.9994 4.30286 12.0378 4.10755 12.113 3.9253C12.1882 3.74305 12.2987 3.57746 12.4381 3.43806C12.5775 3.29866 12.743 3.18819 12.9253 3.11301C13.1075 3.03783 13.3029 2.99943 13.5 3.00001H18.5C18.6972 2.99943 18.8925 3.03783 19.0747 3.11301C19.257 3.18819 19.4226 3.29866 19.562 3.43806C19.7014 3.57746 19.8118 3.74305 19.887 3.9253C19.9622 4.10755 20.0006 4.30286 20 4.50001V7.00001" className='stroke' strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path fillRule="evenodd" clipRule="evenodd" d="M16 10C16.5523 10 17 10.4477 17 11V25C17 25.5523 16.5523 26 16 26C15.4477 26 15 25.5523 15 25V11C15 10.4477 15.4477 10 16 10Z" className='fill' />
      <path fillRule="evenodd" clipRule="evenodd" d="M11.4643 10.0006C12.0163 9.98094 12.4797 10.4124 12.4994 10.9643L12.9994 24.9643C13.0191 25.5163 12.5876 25.9797 12.0357 25.9994C11.4838 26.0191 11.0204 25.5876 11.0006 25.0357L10.5006 11.0357C10.4809 10.4838 10.9124 10.0204 11.4643 10.0006Z" className='fill' />
      <path fillRule="evenodd" clipRule="evenodd" d="M20.5357 10.0006C21.0876 10.0204 21.5191 10.4838 21.4994 11.0357L20.9994 25.0357C20.9797 25.5876 20.5163 26.0191 19.9643 25.9994C19.4124 25.9797 18.9809 25.5163 19.0006 24.9643L19.5006 10.9643C19.5204 10.4124 19.9838 9.98094 20.5357 10.0006Z" className='fill' />
    </Svg>
  )

}
    