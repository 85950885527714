import React from 'react'

import { Button } from '@atoms/buttons'
import { LocalIconEnums } from '@atoms/index'
import { TokenResponse, useGoogleLogin } from '@react-oauth/google'

interface GoogleLoginButtonProps {
  onError: (errorResponse: Pick<TokenResponse, 'error' | 'error_description' | 'error_uri'>) => void
  onSuccess: (userInfo: Omit<TokenResponse, 'error' | 'error_description' | 'error_uri'>) => void
  forSignUp?: boolean
}

export function GoogleLoginButton({ onSuccess, onError, forSignUp = false }: GoogleLoginButtonProps): JSX.Element {

  const googleLogin = useGoogleLogin({
    onError,
    onSuccess,
  })

  return (
    <Button
      variant='ghost'
      size='medium'
      className='signup-button form-button'
      title={forSignUp ? 'SIGN UP WITH' : 'LOGIN WITH'}
      icon={LocalIconEnums.GOOGLE}
      onClick={() => googleLogin()} />
  )
}
