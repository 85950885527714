import React from 'react'

import loadable from '@loadable/component'

// import(/* webpackPrefetch: true */ '@components/builder/pages/PageBuilderPageRenderer')

const PageBuilderPageRenderer = loadable(() => import(/* webpackChunkName: "page-builder-page-renderer" */'@components/builder/pages/PageBuilderPageRenderer'), {
  resolveComponent: (components) => components.PageBuilderPageRenderer,
})

export function PageBuilderPageRendererAsync(): JSX.Element {

  return <PageBuilderPageRenderer fallback={<div>Loading...</div>} />

}
