import React, { useState, useEffect } from 'react'

import { sentenceCase } from 'change-case'
import update from 'react-addons-update'
import { useLocation, useNavigate } from 'react-router'

import { AuthPlugin } from '@api/local/AuthPlugin'
import { ModalPlugin, GlobalModalTypeEnum, MODALS_DEFAULT_STATE } from '@api/local/ModalPlugin'
import { ModalFormContainer } from '@atoms/index'
import { useEvents } from '@contexts/GTMProvider'
import { useCustomerQuery, useGenerateCustomerTokenForSsoMutation, useGetFrequentlyBoughtProductsQuery, useGetModalsQuery, useLogInMutation, usePersonalDiscountsQuery, useSignUpMutation, userequestPasswordResetEmailMutation, useresetPasswordMutation, usesubscribeEmailToNewsletterMutation } from '@hooks/api/index'
import { ForgotPasswordForm, Modal, SignUpForm, LogInForm, SubscribeForm, Credentials, SignUpData, ForgotPasswordData, ResetEmailData } from '@molecules/index'
import { SsoProviderEnum } from '@uctypes/api/globalTypes'

const SUCCESS_DISPLAY_TIME = 1000

export enum LogInModalDisplayType {
  LOG_IN = 'LOG_IN',
  SIGN_UP = 'SIGN_UP',
  FORGOT_PASSWORD = 'FORGOT_PASSWORD',
  SUBSCRIBE = 'SUBSCRIBE'
}

export enum LoginModalDisplayStatus {
  INPUT = 'INPUT',
  LOADING = 'LOADING',
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
}

export enum SocialLoginType {
  LOG_IN = 'LOG_IN',
  SIGN_UP = 'SIGN_UP',
}

interface LoginAction {
  action: () => Promise<any>
  followup?: (data: any) => Promise<any>
  complete?: (data: any) => void
}

export interface SocialLoginData {
  token: string
  provider: SsoProviderEnum
  loginType: SocialLoginType
  accessType?: string
}

interface LogInModalState {
  errorMessage: string
  displayStatus: LoginModalDisplayStatus
  token: string
  provider?: SsoProviderEnum
}

const DEFAULT_STATE: LogInModalState = {
  errorMessage: '',
  displayStatus: LoginModalDisplayStatus.INPUT,
  token: '',
  provider: null,
}

export function LogInModal(): JSX.Element {

  const { refetch: refetchCustomer } = useCustomerQuery()
  const { refetch: refetchDiscounts } = usePersonalDiscountsQuery()
  const { refetch: refetchMyShop } = useGetFrequentlyBoughtProductsQuery()

  const [state, setState] = useState<LogInModalState>({ ...DEFAULT_STATE })
  const { data: modalsData = { modals: { ...MODALS_DEFAULT_STATE } } } = useGetModalsQuery()
  const events = useEvents()
  const navigate = useNavigate()
  const location = useLocation()
  const [logIn] = useLogInMutation()
  const [signUp] = useSignUpMutation()
  const [resetPassword] = useresetPasswordMutation()
  const [requestPasswordResetEmail] = userequestPasswordResetEmailMutation()
  const [generateCustomerToken] = useGenerateCustomerTokenForSsoMutation()
  const [subscribeEmailToNewsletter] = usesubscribeEmailToNewsletterMutation()

  const open = modalsData.modals.logIn || modalsData.modals.signUp || modalsData.modals.subscribe || modalsData.modals.forgotPassword

  const setUserToken = async (token: string): Promise<void> => {
    AuthPlugin.shared().setToken(token)
    await refetchCustomer()
    refetchDiscounts()
    refetchMyShop()
    await AuthPlugin.shared().mergeCarts()
  }

  const reset = (): void => {
    setState((prevState) => update(prevState, {
      displayStatus: {
        $set: LoginModalDisplayStatus.INPUT,
      },
      errorMessage: {
        $set: '',
      },
    }))
  }

  const perfomAction = async ({ action, followup, complete }: LoginAction): Promise<void> => {
    setState((prevState) => update(prevState, {
      displayStatus: { $set: LoginModalDisplayStatus.LOADING },
    }))
    try {
      const actionResponse = await action()
      setState((prevState) => update(prevState, {
        displayStatus: { $set: LoginModalDisplayStatus.SUCCESS },
      }))
      const startTime = Date.now()
      const followupResponse = await followup?.(actionResponse)
      const endTime = Date.now()
      if (endTime - startTime < SUCCESS_DISPLAY_TIME) {
        setTimeout(() => {
          complete?.(followupResponse)
        }, SUCCESS_DISPLAY_TIME - (endTime - startTime))
      } else {
        complete?.(followupResponse)
      }
    } catch (e) {
      if (e.message === 'Variable "$accessToken" of required type "String!" was not provided.') {
        e.message = 'Login failed, please try again'
      }
      setState((prevState) => update(prevState, {
        displayStatus: { $set: LoginModalDisplayStatus.ERROR },
        errorMessage: { $set: e.message },
      }))
    }

  }

  const _handleModalClose = (): void => {
    reset()
    ModalPlugin.shared().closeAllModals()
  }

  const _handleSetDisplayType = (display: LogInModalDisplayType): void => {
    if (display === LogInModalDisplayType.LOG_IN) {
      ModalPlugin.shared().toggleGlobalModal(true, GlobalModalTypeEnum.LOG_IN)
    } else if (display === LogInModalDisplayType.SIGN_UP) {
      ModalPlugin.shared().toggleGlobalModal(true, GlobalModalTypeEnum.SIGN_UP)
    } else if (display === LogInModalDisplayType.SUBSCRIBE) {
      ModalPlugin.shared().toggleGlobalModal(true, GlobalModalTypeEnum.SUBSCRIBE)
    } else if (display === LogInModalDisplayType.FORGOT_PASSWORD) {
      ModalPlugin.shared().toggleGlobalModal(true, GlobalModalTypeEnum.FORGOT_PASSWORD)
    }
  }

  const _handleLoginWithCredentials = async ({ email, password }: Credentials): Promise<void> => {
    perfomAction({
      action: async (): Promise<string> => {
        const result = await logIn({
          variables: {
            email,
            password,
          },
        })
        return result.data.logIn.token
      },
      followup: async (token: string): Promise<void> => {
        events.hasLoggedIn('Email')
        await setUserToken(token)
      },
      complete: (): void => {
        ModalPlugin.shared().closeAllModals()
      },
    })
  }

  const _handleSignUp = async (input: SignUpData): Promise<void> => {
    perfomAction({
      action: async (): Promise<string> => {
        if (input.isSubscribed) {
          input.isSubscribed = true
        } else {
          input.isSubscribed = false
        }
        await signUp({
          variables: { input },
        })
        const result = await logIn({
          variables: {
            email: input.email,
            password: input.password,
          },
        })
        return result.data.logIn.token
      },
      followup: async (token: string): Promise<void> => {
        events.hasSignedUp('Email')
        await setUserToken(token)
      },
      complete: (): void => {
        ModalPlugin.shared().closeAllModals()
      },
    })
  }

  const _handleSocialLogin = async ({ token, provider, accessType, loginType }: SocialLoginData): Promise<void> => {
    perfomAction({
      action: async (): Promise<string> => {
        setState((prevState) => update(prevState, {
          provider: {
            $set: provider,
          },
        }))
        const result = await generateCustomerToken({
          variables: {
            provider,
            accessToken: token,
            accessType: (accessType) || 'Bearer',
          },
        })
        return result.data.generateCustomerTokenForSso.token
      },
      followup: async (token: string): Promise<void> => {
        if (loginType === SocialLoginType.LOG_IN) {
          events.hasLoggedIn(sentenceCase(provider))
        } else {
          events.hasSignedUp(sentenceCase(provider))
        }
        await setUserToken(token)
      },
      complete: (): void => {
        if (loginType === SocialLoginType.LOG_IN) {
          ModalPlugin.shared().closeAllModals()
        } else {
          reset()
          ModalPlugin.shared().toggleGlobalModal(true, GlobalModalTypeEnum.SUBSCRIBE)
        }
      },
    })
  }

  const _handleSendPasswordResetEmail = async ({ email }: ResetEmailData) => {
    perfomAction({
      action: async (): Promise<void> => {
        await requestPasswordResetEmail({
          variables: {
            email,
          },
        })
      },
      complete: (): void => {
        ModalPlugin.shared().closeAllModals()
      },
    })
  }

  const _handlePasswordReset = async ({ email, password, token }: ForgotPasswordData) => {
    perfomAction({
      action: async (): Promise<void> => {
        await resetPassword({
          variables: {
            email,
            resetPasswordToken: token,
            newPassword: password,
          },
        })
      },
      complete: (): void => {
        navigate('/')
        ModalPlugin.shared().closeAllModals()
      },
    })
  }

  const _handleSubscribe = async (email: string) => {
    perfomAction({
      action: async (): Promise<void> => {
        await subscribeEmailToNewsletter({
          variables: {
            email,
          },
        })
      },
      complete: (): void => {
        ModalPlugin.shared().closeAllModals()
      },
    })
  }

  const _handleSocialLoginError = (errorMessage: string): void => {
    setState((prevState) => update(prevState, {
      errorMessage: {
        $set: errorMessage,
      },
      displayStatus: {
        $set: LoginModalDisplayStatus.ERROR,
      },
    }))
  }

  useEffect(() => {
    if (open) {
      reset()
    }
    if (window.location.search) {
      const queryParams = new URLSearchParams(window.location.search)
      const token = queryParams.get('token')
      if (token) {
        setState((prevState) => update(prevState, {
          displayType: { $set: LogInModalDisplayType.FORGOT_PASSWORD },
          token: { $set: token },
        }))
      }
    }
  }, [open, location.search])

  return (
    <Modal open={open} onClose={_handleModalClose} allowCloseOnBackgroundClick={false}>
      <ModalFormContainer>
        <Choose>
          <When condition={modalsData.modals.logIn}>
            <LogInForm
              onLogInWithCredentials={_handleLoginWithCredentials}
              onSocialLogin={_handleSocialLogin}
              onSetDisplayType={_handleSetDisplayType}
              onSocialLoginError={_handleSocialLoginError}
              displayStatus={state.displayStatus}
              errorMessage={state.errorMessage} />
          </When>
          <When condition={modalsData.modals.signUp}>
            <SignUpForm
              onSignUp={_handleSignUp}
              onSocialLogin={_handleSocialLogin}
              onSetDisplayType={_handleSetDisplayType}
              onSocialLoginError={_handleSocialLoginError}
              displayStatus={state.displayStatus}
              errorMessage={state.errorMessage} />
          </When>
          <When condition={modalsData.modals.forgotPassword}>
            <ForgotPasswordForm
              onResetPassword={_handlePasswordReset}
              onRequestResetPassword={_handleSendPasswordResetEmail}
              onSetDisplayType={_handleSetDisplayType}
              displayStatus={state.displayStatus}
              errorMessage={state.errorMessage}
              token={state.token} />
          </When>
          <When condition={modalsData.modals.subscribe}>
            <SubscribeForm
              onSubscribe={_handleSubscribe}
              onSetDisplayType={_handleSetDisplayType}
              displayStatus={state.displayStatus}
              errorMessage={state.errorMessage}
              provider={state.provider} />
          </When>
        </Choose>
      </ModalFormContainer>
    </Modal>
  )

}
