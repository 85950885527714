
import React from 'react'
import { Svg } from './Svg'

export interface GiftProps {
  color?: string
  hoverColor?: string
  className?: string
}

export function Gift({ color, hoverColor, className }: GiftProps): JSX.Element {

  return (
    <Svg strokeColor={color} strokeHoverColor={hoverColor} fillColor={color} fillHoverColor={hoverColor} className={className} width="100%" height="100%" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13 5H11.619C11.9421 4.51894 12.0666 3.9315 11.9663 3.36073C11.866 2.78996 11.5488 2.28014 11.081 1.93806C10.6132 1.59598 10.0312 1.44818 9.45686 1.52562C8.88254 1.60306 8.36047 1.89974 8 2.3535C7.63953 1.89974 7.11746 1.60306 6.54314 1.52562C5.96882 1.44818 5.38681 1.59598 4.91903 1.93806C4.45125 2.28014 4.13398 2.78996 4.03369 3.36073C3.93339 3.9315 4.05786 4.51894 4.381 5H3C2.73486 5.00027 2.48066 5.10571 2.29319 5.29319C2.10571 5.48067 2.00026 5.73487 2 6V8C2.00026 8.26514 2.10571 8.51934 2.29319 8.70682C2.48066 8.8943 2.73486 8.99974 3 9V14C3.00026 14.2651 3.10571 14.5193 3.29319 14.7068C3.48066 14.8943 3.73486 14.9997 4 15H12C12.2651 14.9997 12.5193 14.8943 12.7068 14.7068C12.8943 14.5193 12.9997 14.2651 13 14V9C13.2651 8.99974 13.5193 8.8943 13.7068 8.70682C13.8943 8.51934 13.9997 8.26514 14 8V6C13.9997 5.73487 13.8943 5.48067 13.7068 5.29319C13.5193 5.10571 13.2651 5.00027 13 5ZM8.5 3.75C8.5 3.50278 8.57331 3.2611 8.71066 3.05554C8.84801 2.84998 9.04324 2.68976 9.27165 2.59515C9.50005 2.50054 9.75139 2.47579 9.99386 2.52402C10.2363 2.57225 10.4591 2.6913 10.6339 2.86612C10.8087 3.04093 10.9278 3.26366 10.976 3.50614C11.0242 3.74862 10.9995 3.99995 10.9048 4.22836C10.8102 4.45676 10.65 4.65199 10.4445 4.78934C10.2389 4.92669 9.99723 5 9.75 5H8.5V3.75ZM6.25 2.5C6.5814 2.5004 6.89911 2.63222 7.13345 2.86656C7.36778 3.10089 7.4996 3.4186 7.5 3.75V5H6.25C5.91848 5 5.60054 4.86831 5.36612 4.63389C5.1317 4.39946 5 4.08152 5 3.75C5 3.41848 5.1317 3.10054 5.36612 2.86612C5.60054 2.6317 5.91848 2.5 6.25 2.5ZM3 6H7.5V8H3V6ZM4 9H7.5V14H4V9ZM12.0005 14H8.5V9H12L12.0005 14ZM8.5 8V6H13L13.0005 8H8.5Z" className='fill' />
    </Svg>
  )

}
    