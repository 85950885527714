import React, { Ref } from 'react'

import styled, { useTheme } from 'styled-components'

import { APP_DEFAULT_STATE } from '@api/local/AppPlugin'
import { Icon, LocalIconEnums } from '@atoms/index'
import { Heading, Paragraph } from '@atoms/typography'
import { ResponsivePXValue } from '@components/Theme'
import { useGetAppQuery } from '@hooks/api/index'
import { DeviceTypeEnum } from '@uctypes/api/globalTypes'
import { DeviceContainer } from '@utility/DeviceContainer'

const Item = styled.a`
  text-decoration: none;
  ${ResponsivePXValue('width', { mobile: 'auto', tablet: '33%', desktop: '33%' })}

  .item {
    ${ResponsivePXValue('padding', { mobile: '8px 16px', tablet: '0px', desktop: '0px' })}
    ${ResponsivePXValue('cursor', { mobile: 'none', tablet: 'none', desktop: 'pointer' })}
    font-weight: 600;
  }
`

export interface ItemLinkProps {
  content: string
  url: string
}

export function ItemLink({ content, url }: ItemLinkProps): JSX.Element {

  return (
    <Item href={`/${url}`}>
      <Paragraph
        variant="p1"
        className='item'>
        {content}
      </Paragraph>
    </Item>
  )
}

const SectionContainer = styled.div`
  display: flex;
  ${ResponsivePXValue('flex-direction', { mobile: 'column', tablet: 'row', desktop: 'row' })}
  ${ResponsivePXValue('gap', { mobile: '2px', tablet: '0px', desktop: '0px' })}
  ${ResponsivePXValue('padding', { mobile: '', tablet: '16px 0px', desktop: '16px 0px' })}
  ${(props): string => ResponsivePXValue('background-color', { mobile: props.theme.colors.misc.transparent, tablet: props.theme.colors.white.pureWhite, desktop: props.theme.colors.white.pureWhite })}

  .letter {
    font-weight: 600;
  }
`
const LetterContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${ResponsivePXValue('justify-content', { mobile: 'space-between', tablet: 'center', desktop: 'center' })}
  ${ResponsivePXValue('padding', { mobile: '8px 16px', tablet: '26px 40px', desktop: '26px 40px' })}
  box-sizing: border-box;
  ${ResponsivePXValue('width', { mobile: 'auto', tablet: '110px', desktop: '110px' })}
  background-color: ${({ theme }) => theme.colors.white.pureWhite};
`

const IconContainer = styled.div`
  ${ResponsivePXValue('height', { mobile: '24px' })}
  ${ResponsivePXValue('width', { mobile: '24px' })}
`

const Items = styled.div<{ isOpen: boolean }>`
  display: flex;
  flex: 1;
  ${ResponsivePXValue('align-items', { mobile: 'flex-start', tablet: 'center', desktop: 'center' })}
  flex-direction: column;
  ${ResponsivePXValue('flex-direction', { mobile: 'column', tablet: 'row', desktop: 'row' })}
  ${ResponsivePXValue('flex-wrap', { mobile: 'nowrap', tablet: 'wrap', desktop: 'wrap' })} 
  ${ResponsivePXValue('margin-left', { mobile: '0px', tablet: '56px', desktop: '56px' })}
  background-color: ${({ theme }) => theme.colors.white.pureWhite};  
  max-height: ${({ isOpen }): string => isOpen ? '4000px' : '0px'};
  transition: max-height 0.3s ease-in-out;
  overflow: hidden;
   
  & > div {
    display: flex;
    ${ResponsivePXValue('padding', { mobile: '4px 0px', tablet: '0px', desktop: '0px' })}
    ${ResponsivePXValue('flex-direction', { mobile: 'column', tablet: 'row', desktop: 'row' })}
    ${ResponsivePXValue('flex-wrap', { mobile: 'nowrap', tablet: 'wrap', desktop: 'wrap' })} 
    ${ResponsivePXValue('flex-grow', { mobile: '0', tablet: '1', desktop: '1' })} 
  }
  .item{
    ${ResponsivePXValue('flex-direction', { mobile: 'column', tablet: 'row', desktop: 'row' })}
  }
`

export interface ListingSectionProps {
  title: React.ReactNode
  children: React.ReactNode
  id: string
  isOpen?: boolean
  onChange?: () => void
}

export const ListingSection = React.forwardRef(({ title, children, id, isOpen, onChange }: ListingSectionProps, ref: Ref<HTMLDivElement>): JSX.Element => {

  const theme = useTheme()
  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()

  return (
    <SectionContainer ref={ref}>
      <DeviceContainer mobile>
        <LetterContainer onClick={onChange}>
          <Heading
            variant='h1'
            color={theme.colors.green.greenVogue}
            transform='capitalize'
            className='letter'>
            {title}
          </Heading>
          <IconContainer>
            <Choose>
              <When condition={isOpen}>
                <Icon icon={LocalIconEnums.CHEVRON_UP} color={theme.colors.green.greenVogue} />
              </When>
              <Otherwise>
                <Icon icon={LocalIconEnums.CHEVRON_DOWN} color={theme.colors.green.greenVogue} />
              </Otherwise>
            </Choose>
          </IconContainer>
        </LetterContainer>
      </DeviceContainer>
      <DeviceContainer tablet desktop>
        <LetterContainer>
          <Heading
            variant='h1'
            color={theme.colors.green.greenVogue}
            transform='capitalize'
            className='letter'>
            {title}
          </Heading>
        </LetterContainer>
      </DeviceContainer>
      <Choose>
        <When condition={appData.app.deviceType === DeviceTypeEnum.MOBILE}>
          <Items isOpen={isOpen}>
            <div>
              {children}
            </div>
          </Items>
        </When>
        <Otherwise>
          <Items isOpen={true}>
            <div>
              {children}
            </div>
          </Items>
        </Otherwise>
      </Choose>
    </SectionContainer>
  )
})

ListingSection.displayName = 'ListingSection'

const Container = styled.div`
  ${ResponsivePXValue('display', { mobile: 'flex', tablet: 'flex', desktop: 'flex' })}
  ${ResponsivePXValue('flex-direction', { mobile: 'column', tablet: 'colum', desktop: 'column' })}
  ${ResponsivePXValue('width', { mobile: '320px', tablet: '1232px', desktop: '1232px' })}
  ${ResponsivePXValue('margin-bottom', { mobile: '25px', tablet: '100px', desktop: '100px' })}
  ${ResponsivePXValue('gap', { mobile: '0.5px', tablet: '4px', desktop: '4px' })}
`

export interface ListingProps {
  children: JSX.Element[] | JSX.Element
  onTabsChange: (active: string[]) => void
  tabs: string[]
}

export function Listing({ children, onTabsChange, tabs }: ListingProps): JSX.Element {

  const _handleTabChanged = (id: string): void => {

    const newTabs = [...tabs]

    if (tabs.includes(id)) {
      newTabs.splice(newTabs.indexOf(id), 1)
      onTabsChange(newTabs)
    } else {
      onTabsChange([id])
    }
  }

  const $tabs = React.Children.map(children, ($child) => {
    if ($child?.props?.id) {
      let isOpen = false
      if (tabs.includes($child.props.id)) {
        isOpen = true
      }
      return React.cloneElement($child, { ...$child.props, isOpen, onChange: () => _handleTabChanged($child.props.id) })
    }

    return $child
  })

  return (
    <Container>
      {$tabs}
    </Container>
  )

}
