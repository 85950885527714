import React from 'react'

import styled, { useTheme } from 'styled-components'

import { Button } from '@atoms/buttons'
import { Icon, LocalIconEnums } from '@atoms/images'
import { SmallLoader } from '@atoms/notifications'
import { Paragraph } from '@atoms/typography'
import { ResponsivePXValue } from '@components/Theme'
import { PeachPaymentTokenFragment } from '@hooks/api/index'
import { PeachPaymentsCreditCardTypeEnum } from '@uctypes/api/globalTypes'

const Container = styled.div<{ active: boolean }>`
  display: flex;
  align-items: center;
  background-color: ${(props): string => props.active ? props.theme.colors.white.pampas : props.theme.colors.white.pureWhite};
  ${ResponsivePXValue('padding', { mobile: '24px', tablet: '24px', desktop: '24px' })}
  ${ResponsivePXValue('border-radius', { mobile: '0', tablet: '10px', desktop: '10px' })}
`

const CircleContainer = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  ${ResponsivePXValue('width', { mobile: '30px', tablet: '60px', desktop: '60px' })}
`

const Circle = styled.div<{ active: boolean }>`
  border-style: solid;
  cursor: pointer;
  ${ResponsivePXValue('width', { mobile: '16px', tablet: '20px', desktop: '20px' })}
  ${ResponsivePXValue('height', { mobile: '16px', tablet: '20px', desktop: '20px' })}
  ${ResponsivePXValue('border-radius', { mobile: '10px', tablet: '13px', desktop: '13px' })}
  ${(props): string => {
    if (props.active) {
      return `
        ${ResponsivePXValue('border-width', { mobile: '4px', tablet: '5px', desktop: '5px' })}
        border-color: ${props.theme.colors.green.greenVogue};
      `
    }
    return `
    ${ResponsivePXValue('border-width', { mobile: '1px', tablet: '2px', desktop: '2px' })}
    border-color: ${props.theme.colors.grey.silver};
  `
  }}
`

const LogoContainer = styled.div`
  flex-shrink: 0;
  ${ResponsivePXValue('width', { mobile: '32px', tablet: '64px', desktop: '64px' })}
  ${ResponsivePXValue('height', { mobile: '32px', tablet: '40px', desktop: '40px' })}
  ${ResponsivePXValue('margin-right', { mobile: '6px' })}
`

const TextContainer = styled.div`
  flex-grow: 1;
  display: flex;
  ${ResponsivePXValue('align-items', { mobile: 'flex-start', tablet: 'center', desktop: 'center' })}
  justify-content: flex-start;
  ${ResponsivePXValue('flex-direction', { mobile: 'column', tablet: 'row', desktop: 'row' })}
  ${ResponsivePXValue('gap', { mobile: '2px', tablet: '10px', desktop: '10px' })}
  ${ResponsivePXValue('margin-left', { mobile: '6px', tablet: '36px', desktop: '36px' })}
`

const ControlsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  ${ResponsivePXValue('width', { tablet: '200px', desktop: '200px' })}
  ${ResponsivePXValue('gap', { mobile: '8px', tablet: '8px', desktop: '8px' })}
  .input {
    ${ResponsivePXValue('width', { mobile: '70px' })}
  }
`

const LoadingContainer = styled.div`
  ${ResponsivePXValue('width', { mobile: '50px', tablet: '50px', desktop: '50px' })}
  ${ResponsivePXValue('height', { mobile: '50px', tablet: '50px', desktop: '50px' })}
`

export interface CreditCardRowProps {
  creditCard: PeachPaymentTokenFragment
  selected: boolean
  loading?: boolean
  onSelect: () => void
  onRemove: () => void
}

export function CreditCardRow({ selected, creditCard, loading, onSelect, onRemove }: CreditCardRowProps): JSX.Element {

  const theme = useTheme()

  let logo!: LocalIconEnums

  switch (creditCard.cardBrand) {
    case PeachPaymentsCreditCardTypeEnum.AMERICAN_EXPRESS:
      logo = LocalIconEnums.PAYMENT_AMERICAN_EXPRESS_COLOR
      break
    case PeachPaymentsCreditCardTypeEnum.MASTERCARD:
      logo = LocalIconEnums.PAYMENT_MASTER_CARD_COLOR
      break
    default:
      logo = LocalIconEnums.PAYMENT_VISA_COLOR
      break
  }

  return (
    <Container active={selected}>
      <If condition={!!onSelect}>
        <CircleContainer onClick={onSelect} >
          <Circle active={selected} />
        </CircleContainer>
      </If>
      <LogoContainer>
        <Icon icon={logo} />
      </LogoContainer>
      <TextContainer onClick={onSelect}>
        <Paragraph>
          {`*** ${creditCard.last4Digits}`}
        </Paragraph>
        <Paragraph color={theme.colors.grey.gunSmoke}>
          {`Expires: ${creditCard.expiryYear}/${creditCard.expiryMonth}`}
        </Paragraph>
      </TextContainer>
      <ControlsContainer>
        <Choose>
          <When condition={loading}>
            <LoadingContainer>
              <SmallLoader color={theme.colors.green.greenVogue} />
            </LoadingContainer>
          </When>
          <Otherwise>
            <If condition={!selected}>
              <Button variant='text' title='Remove' onClick={onRemove} />
            </If>
          </Otherwise>
        </Choose>
      </ControlsContainer>
    </Container>
  )

}
