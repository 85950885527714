
import React from 'react'
import { Svg } from './Svg'

export interface FavouritesProps {
  color?: string
  hoverColor?: string
  className?: string
}

export function Favourites({ color, hoverColor, className }: FavouritesProps): JSX.Element {

  return (
    <Svg strokeColor={color} strokeHoverColor={hoverColor} fillColor={color} fillHoverColor={hoverColor} className={className} width="100%" height="100%" viewBox="0 0 20 19" version="1.1">
      <g transform="matrix(0.587481,0,0,0.587481,-688.971,-42.3852)">
        <path className='fill' d="M1200.13,74.691C1198.11,73.72 1195.77,73.612 1193.67,74.391C1192.11,74.957 1190.75,75.969 1189.77,77.3C1188.78,75.969 1187.42,74.957 1185.87,74.391C1183.76,73.612 1181.43,73.72 1179.4,74.691C1177.26,75.678 1175.63,77.514 1174.91,79.753C1174.31,81.63 1174.03,84.685 1176.18,88.586C1178.44,92.669 1181.84,95.739 1189.11,101.507C1189.49,101.807 1190.03,101.807 1190.41,101.507C1197.68,95.738 1201.08,92.668 1203.34,88.585C1205.49,84.685 1205.22,81.627 1204.61,79.752C1203.89,77.516 1202.26,75.681 1200.13,74.691ZM1199.23,76.591C1200.85,77.331 1202.08,78.713 1202.63,80.4C1203.27,82.842 1202.87,85.444 1201.52,87.575C1199.49,91.243 1196.25,94.192 1189.78,99.347C1183.31,94.192 1180.06,91.247 1178.04,87.575C1176.68,85.444 1176.28,82.842 1176.92,80.4C1177.47,78.713 1178.7,77.331 1180.32,76.591C1181.84,75.864 1183.59,75.782 1185.16,76.366C1186.8,76.967 1188.12,78.206 1188.83,79.797C1188.99,80.172 1189.37,80.414 1189.78,80.414C1190.19,80.414 1190.57,80.172 1190.74,79.797C1191.44,78.206 1192.77,76.967 1194.4,76.366C1195.97,75.785 1197.72,75.865 1199.23,76.588L1199.23,76.591Z" />
      </g>
    </Svg>
  )

}
    