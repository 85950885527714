import React, { useState, useEffect } from 'react'

import { ApolloClient, ApolloQueryResult, NormalizedCacheObject, useApolloClient } from '@apollo/client'

import update from 'react-addons-update'
import { useNavigate } from 'react-router'
import styled from 'styled-components'

import { AuthPlugin } from '@api/local/AuthPlugin'
import { CheckoutPlugin } from '@api/local/CheckoutPlugin'
import { Button } from '@atoms/buttons'
import { Card } from '@atoms/layout'
import { Loader } from '@atoms/notifications'
import { Heading, Paragraph, Title } from '@atoms/typography'
import { ResponsivePXValue } from '@components/Theme'
import { useConfig } from '@contexts/ConfigProvider'
import { useEvents } from '@contexts/GTMProvider'
import { CartFragment, useCustomerQuery, CheckoutFragment, GetOzowRedirectInfoDocument, GetOzowRedirectInfoQuery, usePlaceOrderMutation, CartQueryDocument } from '@hooks/api/index'
import { PaymentMethodEnum } from '@uctypes/api/globalTypes'

const Container = styled.div`
  width: 100%;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  ${ResponsivePXValue('margin-top', { mobile: '56px', tablet: '56px', desktop: '56px' })}
  .card {
    width: 100%;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${ResponsivePXValue('gap', '20px')}
  ${ResponsivePXValue('padding', '20px')}
`

enum DisplayTypeEnum {
  LOADING = 'LOADING',
  MESSAGE = 'MESSAGE',
  ERROR = 'ERROR',
}

export interface PlaceOrderProps {
  checkout: CheckoutFragment
  cart: CartFragment
}

interface PlaceOrderState {
  orderNumber: string
  message: string
  error: string
}

const DEFAULT_STATE: PlaceOrderState = {
  orderNumber: '',
  message: 'Placing order',
  error: '',
}

export function PlaceOrder({ checkout, cart }: PlaceOrderProps): JSX.Element {

  const { refetch } = useCustomerQuery()
  const [placeOrder] = usePlaceOrderMutation()
  const [state, setState] = useState<PlaceOrderState>({ ...DEFAULT_STATE })
  const config = useConfig()
  const client = useApolloClient() as ApolloClient<NormalizedCacheObject>
  const navigate = useNavigate()
  const events = useEvents()

  const order = async (): Promise<void> => {

    try {
      const response = await placeOrder({
        variables: {
          input: {
            cartId: cart.id,
          },
        },
      })

      try {
        localStorage?.setItem('orderId', response.data.placeOrder.order.id)
      } catch (e) {
        console.log('UNABLE TO STORE ORDER ID')
      }

      events.hasMadePurchase(cart, response.data.placeOrder.order.id)

      const cartId = await AuthPlugin.shared().clearCartId()
      const newCartData = await client.query({ query: CartQueryDocument, variables: { cartId } })
      try {
        CheckoutPlugin.shared().addProblemItemsBackToCart(newCartData.data.cart, client)
      } catch (e) {
        console.log(e)
      }

      if (checkout.paymentInfo.paymentMethod === PaymentMethodEnum.ozow) {
        setState((prevState) => update(prevState, {
          message: {
            $set: 'Redirecting to Ozow',
          },
        }))

        const client = await config.getClient()
        const ozowData: ApolloQueryResult<GetOzowRedirectInfoQuery> = await client.query({
          query: GetOzowRedirectInfoDocument,
          variables: {
            input: {
              orderNumber: response.data.placeOrder.order.id,
            },
          },
        })
        const url = ozowData?.data?.getOzowRedirectInfoV2?.url
        window.location.href = url
      } else {
        if (checkout.paymentInfo.paymentMethod === PaymentMethodEnum.peachpayments_s2s && checkout.paymentInfo.isNewCard) {
          await refetch()
        }
        navigate(`/checkout/place/${response.data.placeOrder.order.id}`)
      }
    } catch (e) {
      setState((prevState) => update(prevState, {
        error: {
          $set: e.message,
        },
      }))
    }
  }

  const _handleReset = (): void => {
    CheckoutPlugin.shared().resetPaymentInfo()
    navigate('/checkout/payment')
    setState((prevState) => update(prevState, {
      $set: DEFAULT_STATE,
    }))
  }

  useEffect(() => {
    order()
  }, [])

  return (
    <Container>
      <Card className='card'>
        <Choose>
          <When condition={!!state.error}>
            <ErrorContainer>
              <Heading variant='h1'>Sorry!</Heading>
              <Paragraph>{state.error}</Paragraph>
              <Button title='TRY AGAIN' onClick={_handleReset} />
            </ErrorContainer>
          </When>
          <Otherwise>
            <Title variant='t3'>
              {state.message}
            </Title>
            <Loader />
          </Otherwise>
        </Choose>
      </Card>
    </Container>
  )

}
