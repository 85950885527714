import React, { useEffect } from 'react'

import styled from 'styled-components'

import { Button, Paragraph } from '@atoms/index'
import { ResponsivePXValue } from '@components/Theme'
import { useCustomerQuery } from '@hooks/api/index'
import { useForm, Form, TextInput, RatingInput, TextAreaInput } from '@molecules/index'
import { CustomerTypeEnum } from '@uctypes/api/globalTypes'

const Container = styled.div`
  width: 100%;
  .rating {
    ${ResponsivePXValue('margin', '10px 0 18px 0')}
  }
  .name {
    ${ResponsivePXValue('margin', '16px 0')}
    ${ResponsivePXValue('padding-right', { mobile: '0', tablet: '80px', desktop: '160px' })}
  }
  .review-button {
    align-self: flex-start;
    ${ResponsivePXValue('margin', '24px 0 0 0')}
  }
`

export interface ReviewFormData {
  nickname: string
  title: string
  description: string
  rating: number
}

export interface ReviewFormProps {
  loading?: boolean
  onSuccess: (data: ReviewFormData) => void
}

export function ReviewForm({ onSuccess, loading = false }: ReviewFormProps): JSX.Element {

  const { data: customerData } = useCustomerQuery()
  const form = useForm()

  const _handleSuccess = (data: ReviewFormData) => {
    onSuccess(data)
  }

  useEffect(() => {
    const nickname = form.getFieldValue('nickname')
    if (nickname) {
      form.submit()
    } else {
      if (customerData?.currentCustomer?.customerType === CustomerTypeEnum.REGISTERED && customerData?.currentCustomer?.email) {
        form.setFieldsValue({
          nickname: customerData.currentCustomer?.firstname,
        })
      }
    }

  }, [customerData?.currentCustomer])

  return (
    <Container>
      <Form form={form} onFinish={_handleSuccess} loading={loading}>
        <Paragraph variant='p1'>Select a star rating</Paragraph>
        <RatingInput wrapperClassName='rating' showLabel={false} label='Select a star rating' name='rating' rules={[{ required: true, message: 'Select a star rating' }]} />

        <TextAreaInput showLabel={true} label='My review' name='description' placeholder='My review' rules={[{ required: true, message: 'Please enter a description' }]} />
        <TextInput wrapperClassName='name' showLabel={true} label='Your name' name='nickname' placeholder='Your name' rules={[{ required: true, message: 'Please enter your full name' }]} />

        <Button size='large' className='review-button' title='Submit' onClick={() => form.submit()} />
      </Form>
    </Container>
  )

}
