
import React from 'react'
import { Svg } from './Svg'

export interface GoogleProps {
  color?: string
  hoverColor?: string
  className?: string
}

export function Google({ color, hoverColor, className }: GoogleProps): JSX.Element {

  return (
    <Svg strokeColor={color} strokeHoverColor={hoverColor} fillColor={color} fillHoverColor={hoverColor} className={className} width="100%" height="100%" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.2863 5.92125L14.2156 5.62156H7.70187V8.37844H11.5938C11.1897 10.2972 9.31469 11.3072 7.78312 11.3072C6.66875 11.3072 5.49406 10.8384 4.71656 10.085C4.30635 9.68113 3.97983 9.20032 3.75568 8.6701C3.53154 8.13987 3.41418 7.57064 3.41031 6.995C3.41031 5.83375 3.93219 4.67219 4.69156 3.90812C5.45094 3.14406 6.59781 2.71656 7.73812 2.71656C9.04406 2.71656 9.98 3.41 10.33 3.72625L12.2891 1.7775C11.7144 1.2725 10.1356 0 7.675 0C5.77656 0 3.95625 0.727188 2.62562 2.05344C1.3125 3.35938 0.632812 5.24781 0.632812 7C0.632812 8.75219 1.27594 10.5463 2.54844 11.8625C3.90812 13.2663 5.83375 14 7.81656 14C9.62063 14 11.3306 13.2931 12.5494 12.0106C13.7475 10.7481 14.3672 9.00125 14.3672 7.17C14.3672 6.39906 14.2897 5.94125 14.2863 5.92125Z" className='fill' />
    </Svg>
  )

}
    