
import React from 'react'
import { Svg } from './Svg'

export interface HomeProps {
  color?: string
  hoverColor?: string
  className?: string
}

export function Home({ color, hoverColor, className }: HomeProps): JSX.Element {

  return (
    <Svg strokeColor={color} strokeHoverColor={hoverColor} fillColor={color} fillHoverColor={hoverColor} className={className} width="100%" height="100%" viewBox="0 0 20 20" version="1.1">
      <g transform="matrix(1,0,0,1,-2,-2)">
        <g transform="matrix(0.90013,0,0,0.90013,1.19712,1.19903)">
          <path className='fill' d="M19.35,11.456C18.95,11.456 18.6,11.74 18.6,12.118L18.6,20.629L5.55,20.629L5.55,12.166C5.55,11.787 5.2,11.504 4.8,11.504C4.4,11.504 4.05,11.787 4.05,12.166L4.05,20.818C4.05,21.48 4.65,22 5.35,22L18.75,22C19.5,22 20.05,21.48 20.05,20.818L20.05,12.118C20.1,11.74 19.75,11.456 19.35,11.456Z" />
        </g>
        <g transform="matrix(0.90013,0,0,0.90013,1.19712,1.19903)">
          <path className='fill' d="M21.8,10.936L12.45,2.189C12.2,1.953 11.75,1.953 11.5,2.189L2.2,10.936C1.95,11.173 1.9,11.645 2.15,11.929C2.25,12.071 2.45,12.165 2.65,12.165C2.85,12.165 3,12.118 3.15,11.976L12,3.655L20.85,11.976C21,12.118 21.15,12.165 21.3,12.165C21.5,12.165 21.7,12.071 21.8,11.929C22.05,11.645 22.05,11.173 21.8,10.936Z" />
        </g>
      </g>
    </Svg>
  )

}
    