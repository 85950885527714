import React from 'react'

import styled from 'styled-components'

import { ResponsivePXValue } from '@components/Theme'

const Container = styled.div<{ $backgroundColor?: string }>`
  width: 100%;
  ${ResponsivePXValue('padding', { mobile: '16px', tablet: '24px', desktop: '24px' })}
  background-color: ${(props): string => props.$backgroundColor ? props.$backgroundColor : props.theme.colors.white.pureWhite};
`

export interface CardProps {
  children: React.ReactNode
  className?: string
  topContainer?: boolean
  padding?: string
  backgroundColor?: string
}

export function Card({ children, className, backgroundColor }: CardProps): JSX.Element {

  return (
    <Container className={className} $backgroundColor={backgroundColor}>
      {children}
    </Container>
  )

}
