import React, { } from 'react'

import { LayoutRow, LayoutCol } from '@atoms/layout'
import { CartFragment, CheckoutFragment } from '@hooks/api/index'
import { PlaceStepEnum } from '@uctypes/api/globalTypes'

import { Details } from './Details'
import { PlaceOrder } from './PlaceOrder'

export interface ConfirmProps {
  checkout: CheckoutFragment
  cart: CartFragment
  loading?: boolean
}

export function Confirm({ checkout, cart }: ConfirmProps): JSX.Element {
  return (
    <LayoutRow align='center'>
      <LayoutCol span={{ mobile: 10, tablet: 10, desktop: 8 }}>
        <Choose>
          <When condition={checkout.confirmInfo.step === PlaceStepEnum.PLACE_ORDER}>
            <PlaceOrder checkout={checkout} cart={cart} />
          </When>
          <When condition={checkout.confirmInfo.step === PlaceStepEnum.CONFIRM}>
            <Details checkout={checkout} cart={cart} />
          </When>
        </Choose>
      </LayoutCol>
    </LayoutRow>
  )

}
