import React from 'react'

import { PageBlockFragment } from '@hooks/api/index'
import { PageBlockType } from '@uctypes/api/globalTypes'

import { PageAwardBlock } from './PageAwardBlock'
import { PageBannerBlock } from './PageBannerBlock'
import { PageBrandBlock } from './PageBrandBlock'
import { PageCardBlock } from './PageCardBlock'
import { PageDetailsBlock } from './PageDetailsBlock'
import { PageIconBlock } from './PageIconBlock'
import { PageIconTextBlock } from './PageIconTextBlock'
import { PageImageBlock } from './PageImageBlock'
import { PageImageTitleBlock } from './PageImageTitleBlock'
import { PageRatingBlock } from './PageRatingBlock'
import { PageTextBlock } from './PageTextBlock'
import { PageTitleBlock } from './PageTitleBlock'
import { PageMobileAppMessageBlock } from './PageMobileAppMessageBlock'
import { PageBlogCardBlock } from './PageBlogCardBlock'

export interface PageBlockProps {
  block: PageBlockFragment
  type: PageBlockType
}

export function PageBlock({ block, type }: PageBlockProps): JSX.Element {

  return (
    <>
      <Choose>
        <When condition={type === PageBlockType.ICON}>
          <PageIconBlock key={block.id} block={block} />
        </When>
        <When condition={type === PageBlockType.IMAGE}>
          <PageImageBlock key={block.id} block={block} />
        </When>
        <When condition={type === PageBlockType.BRAND}>
          <PageBrandBlock key={block.id} block={block} />
        </When>
        <When condition={type === PageBlockType.RATING}>
          <PageRatingBlock key={block.id} block={block} />
        </When>
        <When condition={type === PageBlockType.TEXT}>
          <PageTextBlock key={block.id} block={block} />
        </When>
        <When condition={type === PageBlockType.CARD}>
          <PageCardBlock key={block.id} block={block} />
        </When>
        <When condition={type === PageBlockType.AWARD}>
          <PageAwardBlock key={block.id} block={block} />
        </When>
        <When condition={type === PageBlockType.TITLE}>
          <PageTitleBlock key={block.id} block={block} />
        </When>
        <When condition={type === PageBlockType.IMAGETITLE}>
          <PageImageTitleBlock key={block.id} block={block} />
        </When>
        <When condition={type === PageBlockType.BANNER}>
          <PageBannerBlock key={block.id} block={block} />
        </When>
        <When condition={type === PageBlockType.ICONTEXT}>
          <PageIconTextBlock key={block.id} block={block} />
        </When>
        <When condition={type === PageBlockType.DETAILS}>
          <PageDetailsBlock key={block.id} block={block} />
        </When>
        <When condition={type === PageBlockType.MOBILEAPPMESSAGE}>
          <PageMobileAppMessageBlock key={block.id} block={block} />
        </When>
        <When condition={type === PageBlockType.BLOGCARD}>
          <PageBlogCardBlock key={block.id} block={block} />
        </When>
        <Otherwise>
          UNKNOWN BLOCK TYPE
        </Otherwise>
      </Choose>
    </>
  )

}
