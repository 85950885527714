import React from 'react'

import styled from 'styled-components'

import { Heading } from '@atoms/typography'
import { ResponsivePXValue } from '@components/Theme'

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  ${ResponsivePXValue('gap', '16px')}
  ${ResponsivePXValue('margin', { mobile: '16px', tablet: '32px', desktop: '32px' })}
  ${ResponsivePXValue('padding', { mobile: '0 16px' })}
`

export function SectionFallbackComponent(): JSX.Element {

  return (
    <Container>
      <Heading variant='h1' align='center'>An error has occured rendering the section</Heading>
    </Container>
  )
}
