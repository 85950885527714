import React from 'react'

import styled, { useTheme } from 'styled-components'

import { Icon, LocalIconEnums } from '@atoms/images'
import { ResponsivePXValue } from '@components/Theme'

const Container = styled.div<{ textColor: string, backgroundColor: string }>`

  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: milliard;
  font-weight: 600;

  color: ${(props): string => props.textColor};
  background-color: ${(props): string => props.backgroundColor};

  ${ResponsivePXValue('border-radius', '4px')}
  ${ResponsivePXValue('font-size', '14px')}
  ${ResponsivePXValue('padding', '6px 8px')}

`

const TextContiner = styled.div`
  ${ResponsivePXValue('margin-right', '8px')}
`

const IconContainer = styled.div`
  ${ResponsivePXValue('width', '24px')}
  ${ResponsivePXValue('height', '24px')}
`

export interface ChipProps {
  title: string
  onRemove: () => void
  textColor?: string
  backgroundColor?: string
}

export function Chip({ title, onRemove, textColor, backgroundColor }: ChipProps): JSX.Element {

  const theme = useTheme()
  if (!textColor) {
    textColor = theme.colors.white.floralWhite
  }
  if (!backgroundColor) {
    backgroundColor = theme.colors.green.goldenrod
  }

  return (
    <Container textColor={textColor} backgroundColor={backgroundColor}>
      <TextContiner>
        {title}
      </TextContiner>
      <IconContainer onClick={onRemove}>
        <Icon color={textColor} icon={LocalIconEnums.SMALL_CROSS} />
      </IconContainer>
    </Container>
  )

}
