
import React from 'react'

import loadable from '@loadable/component'

import(/* webpackPrefetch: true */ '@pages/GiftVoucher')

const GiftVoucher = loadable(() => import(/* webpackChunkName: "me" */'@pages/GiftVoucher'), {
  resolveComponent: (components) => components.GiftVoucher,
})

export function GiftVoucherAsync(): JSX.Element {

  return <GiftVoucher fallback={<div>Loading...</div>} />

}
