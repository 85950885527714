
import React from 'react'
import { Svg } from './Svg'

export interface PhoneProps {
  color?: string
  hoverColor?: string
  className?: string
}

export function Phone({ color, hoverColor, className }: PhoneProps): JSX.Element {

  return (
    <Svg strokeColor={color} strokeHoverColor={hoverColor} fillColor={color} fillHoverColor={hoverColor} className={className} xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
      <g>
        <path className='fill' strokeWidth="2" d="M1004.505 456.014a.624.624 0 0 1 .619.529 8.565 8.565 0 0 0 .472 1.884.618.618 0 0 1 .027.348.628.628 0 0 1-.165.306l-.794.789a.618.618 0 0 0-.1.747 10.615 10.615 0 0 0 3.988 3.962.629.629 0 0 0 .751-.1l.792-.787a.619.619 0 0 1 .309-.166.637.637 0 0 1 .353.028 8.716 8.716 0 0 0 1.886.467.631.631 0 0 1 .391.212.619.619 0 0 1 .148.418v1.885a.6.6 0 0 1-.052.25.62.62 0 0 1-.149.209.626.626 0 0 1-.424.164h-.045a11.863 11.863 0 0 1-5.13-1.814 11.822 11.822 0 0 1-5.385-8.651.6.6 0 0 1 .03-.253.611.611 0 0 1 .131-.221.629.629 0 0 1 .208-.15.648.648 0 0 1 .252-.053h1.889m0-.945h-1.889a1.57 1.57 0 0 0-1.156.508 1.547 1.547 0 0 0-.33.556 1.565 1.565 0 0 0-.077.642v.017a12.768 12.768 0 0 0 5.82 9.349 12.815 12.815 0 0 0 5.533 1.955H1012.555a1.564 1.564 0 0 0 1.06-.41 1.568 1.568 0 0 0 .377-.524 1.592 1.592 0 0 0 .134-.641v-1.864a1.568 1.568 0 0 0-1.355-1.577 7.775 7.775 0 0 1-1.688-.417 1.573 1.573 0 0 0-1.654.352l-.621.617a9.644 9.644 0 0 1-3.3-3.273l.614-.611a1.564 1.564 0 0 0 .357-1.657 7.6 7.6 0 0 1-.42-1.675 1.568 1.568 0 0 0-1.556-1.35z" transform="translate(-1001.047 -455.069)" />
      </g>
    </Svg>
  )

}
    