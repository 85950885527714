import React from 'react'

import styled from 'styled-components'

import { ResponsiveImage } from '@atoms/images'
import { Heading } from '@atoms/typography'
import { ResponsivePXValue } from '@components/Theme'
import { PageBlockFragment } from '@hooks/api/index'
import { PageHorizontalAlignment, PageSize } from '@uctypes/api/globalTypes'

import { HTML } from './HTML'
import { DeviceContainer } from '@utility/DeviceContainer'

const LinkedContainer = styled.a<{ backgroundColor: string, foregroundColor: string, alignment?: PageHorizontalAlignment }>`

  display: flex;
  flex-direction: column;
  ${ResponsivePXValue('gap', { mobile: '12px', tablet: '16px', desktop: '16px' })}
  align-items: ${(props): string => props.alignment === PageHorizontalAlignment.LEFT
    ? 'flex-start'
    : props.alignment === PageHorizontalAlignment.RIGHT
      ? 'flex-end'
      : 'center'};
  width: 100%;
  justify-content: space-between;
  background-color: ${(props): string => props.backgroundColor};
  color: ${(props): string => props.foregroundColor};
  text-decoration: none;
  ${ResponsivePXValue('height', '100%')}
  ${ResponsivePXValue('padding', { mobile: '24px 40px 24px 40px', tablet: '24px', desktop: '24px' })}

`

const Container = styled.div<{ backgroundColor: string, foregroundColor: string, alignment?: PageHorizontalAlignment }>`

  display: flex;
  flex-direction: column;
  ${ResponsivePXValue('gap', { mobile: '12px', tablet: '16px', desktop: '16px' })}
  align-items: ${(props): string => props.alignment === PageHorizontalAlignment.LEFT
    ? 'flex-start'
    : props.alignment === PageHorizontalAlignment.RIGHT
      ? 'flex-end'
      : 'center'};
  width: 100%;
  justify-content: space-between;
  background-color: ${(props): string => props.backgroundColor};
  color: ${(props): string => props.foregroundColor};
  text-decoration: none;
  ${ResponsivePXValue('height', '100%')}
  ${ResponsivePXValue('padding', { mobile: '24px 40px 24px 40px', tablet: '24px', desktop: '24px' })}

`

const ImageContainer = styled.div<{ size: PageSize }>`
  ${(props): string => {
    if (props.size === PageSize.LARGE) {
      return `
        ${ResponsivePXValue('height', { mobile: '64px', tablet: '128px', desktop: '128px' })}
        ${ResponsivePXValue('width', { mobile: '64px', tablet: '128px', desktop: '128px' })}
      `
    } else if (props.size === PageSize.MEDIUM) {
      return `
        ${ResponsivePXValue('height', { mobile: '32px', tablet: '64px', desktop: '64px' })}
        ${ResponsivePXValue('width', { mobile: '32px', tablet: '64px', desktop: '64px' })}
      `
    }
    return `
      ${ResponsivePXValue('height', { mobile: '16px', tablet: '32px', desktop: '32px' })}
      ${ResponsivePXValue('width', { mobile: '64px', tablet: '128px', desktop: '128px' })}
    `
  }}
  
  flex-shrink: 0;
`

const TitleContainer = styled.div`
  white-space: normal;

  .block-title {
    ${ResponsivePXValue('margin', '0px')}
  }
  
`

const ContentContainer = styled.div`
  flex-grow: 1;
  width: 100%;

  .block-content {
    white-space: normal;
  }

`

export interface PageIconBlockProps {
  block: PageBlockFragment
}

export function PageIconBlock({ block }: PageIconBlockProps): JSX.Element {

  const { horizontalAlignment, backgroundColor, foregroundColor, href, image, description, imageSize, title } = block

  const alignment = horizontalAlignment === PageHorizontalAlignment.LEFT
    ? 'left'
    : horizontalAlignment === PageHorizontalAlignment.RIGHT
      ? 'right'
      : 'center'

  return (
    <Choose>
      <When condition={!!href}>
        <LinkedContainer
          backgroundColor={backgroundColor}
          foregroundColor={foregroundColor}
          alignment={horizontalAlignment || PageHorizontalAlignment.CENTER}
          href={href || ''}>
          <DeviceContainer desktop tablet>
            <If condition={!!image}>
              <ImageContainer size={imageSize}>
                <ResponsiveImage image={image} objectFit='contain' />
              </ImageContainer>
            </If>
          </DeviceContainer>
          <TitleContainer>
            <Heading
              variant={!!image && imageSize === 'MEDIUM'
                ? 'h3'
                : !!image && imageSize === 'LARGE'
                  ? 'h5'
                  : 'h1'}
              align={alignment}
              color={foregroundColor}
              className='block-title'>
              {title}
            </Heading>
          </TitleContainer>
          <If condition={!!description}>
            <ContentContainer>
              <HTML color={foregroundColor} align={alignment} className='block-content'>
                {description}
              </HTML>
            </ContentContainer>
          </If>
        </LinkedContainer>
      </When>
      <Otherwise>
        <Container
          backgroundColor={backgroundColor}
          foregroundColor={foregroundColor}
          alignment={horizontalAlignment || PageHorizontalAlignment.CENTER}>
          <If condition={!!image}>
            <ImageContainer size={imageSize}>
              <ResponsiveImage image={image} objectFit='contain' />
            </ImageContainer>
          </If>
          <TitleContainer>
            <Heading
              variant={!!image && imageSize === 'MEDIUM'
                ? 'h3'
                : !!image && imageSize === 'LARGE'
                  ? 'h5'
                  : 'h1'}
              align={alignment}
              color={foregroundColor}
              className='block-title'>
              {title}
            </Heading>
          </TitleContainer>
          <If condition={!!description}>
            <ContentContainer>
              <HTML color={foregroundColor} align={alignment} className='block-content'>
                {description}
              </HTML>
            </ContentContainer>
          </If>
        </Container>
      </Otherwise>
    </Choose>
  )

}
