import React from 'react'

import styled, { useTheme } from 'styled-components'

import { Label, Skeleton, SkeletonNode, Tag } from '@atoms/index'
import { ResponsivePXValue } from '@components/Theme'
import { ProductDetailsFragment_SimpleProduct_ as SimpleDetails } from '@hooks/api/index'

type ProductValueAttributes = SimpleDetails['valueAttributes'][0]

const Strong = styled.span`
  font-weight: 700;
  ${ResponsivePXValue('position', { mobile: 'relative' })}
  ${ResponsivePXValue('right', { mobile: '5px' })}
  ${ResponsivePXValue('font-size', { mobile: '11px', tablet: '12px', desktop: '12px' })}
  ${ResponsivePXValue('line-height', { mobile: '18px' })}
`

const TagsInnerContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  ${ResponsivePXValue('margin', { mobile: '8px 0 12px 0', tablet: '20px 0 16px 0', desktop: '20px 0 16px 0' })}
  ${ResponsivePXValue('gap', '8px')}
`

const TagsContainer = styled.div`
  border-style: solid;
  ${ResponsivePXValue('padding', { mobile: '0', tablet: '10px 0', desktop: '10px 0' })}
  ${ResponsivePXValue('border-bottom-width', '1px')}
  border-color: ${(props): string => props.theme.colors.grey.athens};

  .values-label {
    width: 100%;
    margin: 0;
    ${ResponsivePXValue('margin-bottom', { mobile: '0', tablet: '5px', desktop: '5px' })}
  }
`

interface ProductValueAttributesProps {
  valueAttributes: ProductValueAttributes[]
}

interface ValueTagColorInterface {
  [key: string]: {
    color: string
    background: string
  }
}

export function ProductValueAttributes({ valueAttributes }: ProductValueAttributesProps): JSX.Element {

  if (!valueAttributes) {
    return (
      <TagsContainer>
        <Skeleton direction='column' justify='flex-start' align='flex-start' gap='28px' height='auto'>
          <SkeletonNode shape='rounded' color='gallery' align='flex-start' justify='flex-start'
            height={{ mobile: '12px', tablet: '24px', desktop: '24px' }}
            variWidth={{ mobile: '30%-45%', tablet: '25%', desktop: '20%-50%' }}
          />
        </Skeleton>
      </TagsContainer>
    )
  }

  const theme = useTheme()

  let tagIndex: number
  let valueAttribute: ProductValueAttributes
  let productValue: string

  const valueTagColors = {
    SOCIAL_VALUES: {
      background: theme.colors.yellow.lemonChiffon,
      color: theme.colors.orange.piper,
    },
    VALUES_SOCIAL: {
      background: theme.colors.yellow.lemonChiffon,
      color: theme.colors.orange.piper,
    },
    DIET_VALUES: {
      background: theme.colors.blue.tropicalBlue,
      color: theme.colors.blue.curiousBlue,
    },
    VALUES_DIET: {
      background: theme.colors.blue.tropicalBlue,
      color: theme.colors.blue.curiousBlue,
    },
    ENVIRONMENTAL_VALUES: {
      background: theme.colors.green.tusk,
      color: theme.colors.green.viridian,
    },
    VALUES_ENVIRONMENTAL: {
      background: theme.colors.green.tusk,
      color: theme.colors.green.viridian,
    },
  } as ValueTagColorInterface

  return (
    <TagsContainer>
      <Tag variant='t2' className='text-item' color={theme.colors.green.bottleGreen}><Strong>Values:</Strong> </Tag>
      <TagsInnerContainer>
        <For each='valueAttribute' of={valueAttributes || []} index='valueIndex'>
          <For each='productValue' of={valueAttribute.value || []} index='tagIndex'>
            <If condition={!!productValue}>
              <Label
                color={valueTagColors[valueAttribute.code].color}
                backgroundColor={valueTagColors[valueAttribute.code].background}
                key={tagIndex}
                radius={true}>
                {productValue.trim()}
              </Label>
            </If>
          </For>
        </For>
      </TagsInnerContainer>
    </TagsContainer>
  )

}
