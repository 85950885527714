import React from 'react'

import { useNavigate } from 'react-router'
import styled from 'styled-components'

import { Heading, Link, Title } from '@atoms/typography'
import { ResponsivePXValue } from '@components/Theme'
import { PageBlockFragment } from '@hooks/api/index'
import { PageHorizontalAlignment } from '@uctypes/api/globalTypes'

import { HTML } from './HTML'

const Container = styled.div<{ backgroundColor: string, foregroundColor: string, alignment?: PageHorizontalAlignment, containsDesc: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: ${(props): string => props.alignment === PageHorizontalAlignment.LEFT
    ? 'flex-start'
    : props.alignment === PageHorizontalAlignment.RIGHT
      ? 'flex-end'
      : 'center'};
  width: 100%;
  justify-content: center;
  height: 100%;
  background-color: ${(props): string => props.backgroundColor};
  color: ${(props): string => props.foregroundColor};
  text-decoration: none;
  ${ResponsivePXValue('padding', { mobile: '24px', tablet: '32px', desktop: '32px' })}

`

const LinkContainer = styled.a<{ backgroundColor: string, foregroundColor: string, alignment?: PageHorizontalAlignment, containsDesc: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: ${(props): string => props.alignment === PageHorizontalAlignment.LEFT
    ? 'flex-start'
    : props.alignment === PageHorizontalAlignment.RIGHT
      ? 'flex-end'
      : 'center'};
  width: 100%;
  justify-content: center;
  height: 100%;
  background-color: ${(props): string => props.backgroundColor};
  color: ${(props): string => props.foregroundColor};
  text-decoration: none;
  ${ResponsivePXValue('padding', { mobile: '24px', tablet: '32px', desktop: '32px' })}

`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  margin-top: 16px;
  justify-content: space-between;


  .block-title {
    white-space: normal;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    align-items: flex-end;
  }

  .block-content {
    white-space: normal;
  }

  .block-link {
    font-weight: 700;
  }

  .block-link-container {
    width: 336px;
  }

`

export interface PageTextBlockProps {
  block: PageBlockFragment
}

export function PageTextBlock({ block }: PageTextBlockProps): JSX.Element {

  const { href, horizontalAlignment, description, foregroundColor, title, link, backgroundColor } = block

  const navigate = useNavigate()
  const _handleClick = (e: React.MouseEvent<HTMLAnchorElement>):void => {
    e.preventDefault()
    navigate(href)
  }

  const alignment = horizontalAlignment === PageHorizontalAlignment.LEFT
    ? 'left'
    : horizontalAlignment === PageHorizontalAlignment.RIGHT
      ? 'right'
      : 'center'

  const $innerElement = (
    <>
      <Heading
        variant={description ? 'h5' : 'h1'}
        align={alignment}
        color={foregroundColor}
        className='block-title'>
        {title}
      </Heading>
      <If condition={!!description || !!link?.title}>
        <ContentContainer>
          <If condition={!!description}>
            <HTML color={foregroundColor} align={alignment} className='block-content'>
              {description}
            </HTML>
          </If>
          <If condition={!!link}>
            <Title variant='t8'>
              <Link
                href={link.url || '#'}
                className='block-link'
                decoration='underline'>
                {link.title}
              </Link>
            </Title>
          </If>
        </ContentContainer>
      </If>
    </>
  )

  return (
    <Choose>
      <When condition={!!href}>
        <LinkContainer
          href={href}
          backgroundColor={backgroundColor}
          foregroundColor={foregroundColor}
          alignment={horizontalAlignment || PageHorizontalAlignment.CENTER}
          containsDesc={description}
          onClick={_handleClick}>
          {$innerElement}
        </LinkContainer>
      </When>
      <Otherwise>
        <Container
          backgroundColor={backgroundColor}
          foregroundColor={foregroundColor}
          alignment={horizontalAlignment || PageHorizontalAlignment.CENTER}
          containsDesc={description}>
          {$innerElement}
        </Container>
      </Otherwise>
    </Choose>

  )

}
