import React from 'react'

import { ModalFormContainer } from '@atoms/layout/ModalFormContainer'
import { CartFragment, CheckoutFragment } from '@hooks/api'
import { Modal } from '@molecules/content/Modal'

import { PargoLocation, PargoSelect } from './PargoSelect'

export interface PargoModalProps {
  open: boolean
  onSelect: (location: PargoLocation) => void
  onCancel: () => void
  cart: CartFragment
  checkout: CheckoutFragment
}

export function PargoModal({ open, onSelect, onCancel, cart, checkout }: PargoModalProps): JSX.Element {

  return (
    <Modal open={open} title='Select Location' onClose={onCancel}>
      <ModalFormContainer>
        <PargoSelect cart={cart} checkout={checkout} onSelect={onSelect} />
      </ModalFormContainer>
    </Modal>
  )

}
