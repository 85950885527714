import { Heading, Paragraph, ResponsiveImage } from '@atoms/index';
import { ResponsivePXValue } from '@components/Theme';
import { PageBlockFragment } from '@hooks/api';
import { BlockTag, PageHorizontalAlignment } from '@uctypes/api/globalTypes';
import { Content } from 'antd/es/layout/layout';
import React from 'react';
import styled from 'styled-components';
import { HTML } from './HTML';
import { useNavigate } from 'react-router';

const LinkWrapper = styled.a`
    text-decoration: none;
    color: inherit;
    display: block;
    width: 100%;
    height: 100%;
    cursor: pointer;
`

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    ${ResponsivePXValue('gap', '19px')}
`

const ImageContainer = styled.div`
    width: 100%;
    flex-shrink: 0;
`

const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    ${ResponsivePXValue('gap', '8px')}
    
    .title {
        margin: 0;
    }
`

const TagContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    ${ResponsivePXValue('gap', '8px')}
`

const BlogTag = styled.div<{ bgColor: string }>`
    background-color: ${props => props.bgColor};
    ${ResponsivePXValue('padding', '8px')}
    ${ResponsivePXValue('border-radius', '5px')}
`


interface PageBlockProps {
    block: PageBlockFragment
}

export function PageBlogCardBlock({ block }: PageBlockProps): JSX.Element {

    const { title, image, description, horizontalAlignment, foregroundColor, href, tags } = block
    const navigate = useNavigate()

    const alignment = horizontalAlignment === PageHorizontalAlignment.LEFT
    ? 'left'
    : horizontalAlignment === PageHorizontalAlignment.RIGHT
      ? 'right'
      : 'center'

    const _handleLinkClick = () => {
        navigate(`/${href}`)
    }  

    let tag: BlockTag
    return (
            <Container onClick={_handleLinkClick}>
                <LinkWrapper href={href} onClick={_handleLinkClick}>
                    <ImageContainer>
                        <ResponsiveImage image={image} objectFit='cover'/>
                    </ImageContainer>
                </LinkWrapper>
                <ContentContainer>
                    <If condition={!!tags}>
                        <TagContainer>
                            <For each='tag' of={tags}>
                                <BlogTag bgColor={tag.backgroundColor}>
                                    <Paragraph variant='p1' color={tag.foregroundColor} bold>
                                        {tag.title}
                                    </Paragraph>
                                </BlogTag>
                            </For>
                        </TagContainer>
                    </If>
                    <Heading variant='h3' align={alignment} color={foregroundColor} className='title'>{title}</Heading>
                    <HTML children={description} align={alignment} color={foregroundColor}/> 
                </ContentContainer>
            </Container>
    )
}
  