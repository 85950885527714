
import React from 'react'
import { Svg } from './Svg'

export interface ErrorProps {
  color?: string
  hoverColor?: string
  className?: string
}

export function Error({ color, hoverColor, className }: ErrorProps): JSX.Element {

  return (
    <Svg strokeColor={color} strokeHoverColor={hoverColor} fillColor={color} fillHoverColor={hoverColor} className={className} width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" className='stroke' strokeWidth="2" strokeLinecap="square" />
      <path d="M12 8V12" className='stroke' strokeWidth="2" strokeLinecap="square" />
      <path d="M12 16H12.01" className='stroke' strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </Svg>
  )

}
    