import React, { useEffect, useState } from 'react'

import update from 'react-addons-update'
import styled from 'styled-components'

import { AppPlugin } from '@api/local/AppPlugin'
import { Button } from '@atoms/buttons'
import { Skeleton, SkeletonNode } from '@atoms/layout'
import { Divider } from '@atoms/layout/Divider'
import { Paragraph, Title } from '@atoms/typography'
import { ResponsivePXValue } from '@components/Theme'
import { CustomerAddressFragment, useCustomerQuery, useDeleteCustomerAddressMutation, useUpdateCustomerAddressMutation } from '@hooks/api/index'
import { AddressForm } from '@molecules/forms/AddressForm'
import { useSimpleToasts } from '@simple/toasts'
import { CustomerAddress } from '@uctypes/api/globalTypes'

import { AddressRow } from './AddressRow'

const Container = styled.div`
  width: 100%;
  background-color: ${(props): string => props.theme.colors.white.pureWhite};
`

const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${ResponsivePXValue('padding', { mobile: '24px', tablet: '24px', desktop: '24px' })}
`

const List = styled.div`
    ${ResponsivePXValue('padding', { mobile: '0 0 16px 0', tablet: '16px', desktop: '0 16px 16px 16px' })}

  .noData{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${(props): string => props.theme.colors.white.fantasy};
    ${ResponsivePXValue('width', { mobile: 'CALC(100% -32px)', tablet: 'CALC(100% -32px)', desktop: 'CALC(100% -32px)' })}
    ${ResponsivePXValue('padding', { mobile: '24px', tablet: '24px', desktop: '24px' })}
  }
`
const FormContainer = styled.div`
  ${ResponsivePXValue('padding', { mobile: '0 24px', tablet: '0 24px', desktop: '0 24px' })}
  ${ResponsivePXValue('margin-bottom', { mobile: '25px' })}
`

enum DisplayTypeEnum {
  LIST = 'LIST',
  EDIT = 'EDIT',
}

export enum AddressType {
  DELIVERY = 'DELIVERY',
  BILLING = 'BILLING',
}

export interface AddressesProps {
  selected?: number
  onSelect?: (id: number) => void
  forceAdd?: boolean
  addressType?: AddressType
}

interface AddressesState {
  displayType: DisplayTypeEnum
  editingAddress: CustomerAddressFragment | null
  loadingRows: number[]
}

const DEFAULT_STATE: AddressesState = {
  displayType: DisplayTypeEnum.LIST,
  editingAddress: null,
  loadingRows: [],
}

export function Addresses({ selected, onSelect, forceAdd = false, addressType = AddressType.DELIVERY }: AddressesProps): JSX.Element {

  const { data: customerData, loading: customerLoading, refetch } = useCustomerQuery()
  const [removeCustomerAddress] = useDeleteCustomerAddressMutation()
  const [updateCustomerAddress, { loading: updateAddressLoading }] = useUpdateCustomerAddressMutation()
  const [state, setState] = useState<AddressesState>({ ...DEFAULT_STATE })
  const { addToast } = useSimpleToasts()

  const _handleAdd = (): void => {
    setState((prevState) => update(prevState, {
      displayType: {
        $set: DisplayTypeEnum.EDIT,
      },
      editingAddress: {
        $set: null,
      },
    }))
  }

  const _handleEdit = (id: number): void => {
    const address = customerData.currentCustomer.addresses.find((address) => address.id === id)
    setState((prevState) => update(prevState, {
      displayType: {
        $set: DisplayTypeEnum.EDIT,
      },
      editingAddress: {
        $set: address,
      },
    }))
  }

  const updateAddress = async (input: CustomerAddress[], defaultBilling: boolean, defaultShipping: boolean) => {

    const otherAddress = input.find((address) => {
      if (defaultBilling && defaultShipping) {
        return !address.defaultBilling && !address.defaultShipping
      } else if (defaultBilling) {
        return !address.defaultBilling
      } else if (defaultShipping) {
        return !address.defaultShipping
      } else {
        return []
      }
    })
    await updateCustomerAddress({
      variables: {
        id: otherAddress?.id,
        input: {
          defaultShipping: true,
          defaultBilling: true,
        },
      },
    })

  }

  const removeAddress = async (id: number) => {
    await removeCustomerAddress({
      variables: {
        id,
      },
    })

    addToast({
      message: 'Address successfully removed',
      appearance: 'success',
    })

    refetch()
    onSelect(null)
  }

  const _handlRemove = async (id: number): Promise<void> => {
    setState((prevState) => update(prevState, {
      loadingRows: {
        $apply: (loadingRows) => [...loadingRows, id],
      },
    }))

    try {
      const { addresses } = customerData?.currentCustomer || {}

      if (addresses.length > 1) {
        const address = addresses.find((address) => address.id === id)
        const { defaultShipping, defaultBilling } = address || {}

        if (defaultShipping && defaultBilling) {
          await updateAddress(addresses, true, true)
          await removeAddress(id)

        } else if (defaultShipping) {

          await updateAddress(addresses, false, true)
          await removeAddress(id)
        } else if (defaultBilling) {

          await updateAddress(addresses, true, false)
          await removeAddress(id)
        } else {
          await removeAddress(id)
        }
      }
    } catch (e) {
      addToast({
        message: e.message,
        appearance: 'error',
      })
    } finally {
      setState((prevState) => update(prevState, {
        loadingRows: {
          $splice: [[prevState.loadingRows.indexOf(id), 1]],
        },
      }))
    }
  }

  const _handleSelect = (id: number): void => {
    onSelect(id)
  }

  const _handleCancelEdit = (): void => {
    setState((prevState) => update(prevState, {
      displayType: {
        $set: DisplayTypeEnum.LIST,
      },
      editingAddress: {
        $set: null,
      },
    }))
  }

  const _handleEditSuccess = (): void => {
    setState((prevState) => update(prevState, {
      displayType: {
        $set: DisplayTypeEnum.LIST,
      },
      editingAddress: {
        $set: null,
      },
    }))
    refetch()
  }

  useEffect(() => {
    const defaultShipping = customerData?.currentCustomer?.addresses?.find((address) => address.defaultShipping)
    if (!customerLoading && !customerData?.currentCustomer?.addresses?.length && forceAdd) {
      _handleAdd()
    } else if (!customerLoading && customerData?.currentCustomer?.addresses?.length === 1) {
      _handleSelect(customerData?.currentCustomer?.addresses[0].id)
    } else if (!selected && defaultShipping) {
      _handleSelect(defaultShipping.id)
    }
  }, [customerLoading, customerData?.currentCustomer])

  const addresses = customerData?.currentCustomer?.addresses || []

  const canRemove = addresses?.length > 1
  let address!: CustomerAddressFragment
  let addressIndex!: number

  const shouldSelect = onSelect !== undefined

  return (
    <Container>
      <Choose>
        <When condition={state.displayType === DisplayTypeEnum.LIST}>
          <>
            <Header>
              <Title variant='t3'>
                { addressType === AddressType.DELIVERY ? 'Delivery Address' : 'Billing Address' }
              </Title>
              <Button
                title='NEW ADDRESS'
                variant='ghost'
                loading={customerLoading}
                size='medium'
                onClick={_handleAdd} />
            </Header>
            <List>
              <Choose>
                <When condition={customerLoading}>
                  <Skeleton loop={2} direction='column' gap='10px'>
                    <SkeletonNode
                      color='pampas'
                      height={{ mobile: '150px', tablet: '120px', desktop: '120px' }}/>
                  </Skeleton>
                </When>
                <When condition={addresses.length === 0}>
                  <Paragraph className='noData'>
                    No saved addresses. Add one now to speed up checkout.
                  </Paragraph>
                </When>
                <Otherwise>
                  <For each='address' index='addressIndex' of={addresses}>
                    <AddressRow
                      address={address}
                      selected={(address.id === selected)}
                      key={address.id}
                      loading={state.loadingRows.includes(address.id)}
                      onEdit={() => _handleEdit(address.id)}
                      onRemove={() => _handlRemove(address.id)}
                      onSelect={shouldSelect ? () => _handleSelect(address.id) : undefined}
                      canRemove={canRemove}/>
                    <If condition={addressIndex < addresses.length - 1}>
                      <Divider key={`divider-${address.id}`} />
                    </If>
                  </For>
                </Otherwise>
              </Choose>
            </List>
          </>
        </When>
        <Otherwise>
          <>
            <Header>
              <Title variant='t3'>
                {state.editingAddress ? 'Edit Address' : 'Add Address'}
              </Title>
            </Header>
            <FormContainer>
              <AddressForm
                address={state.editingAddress}
                onCancel={_handleCancelEdit}
                onSuccess={_handleEditSuccess}
                handleSelect={_handleSelect}/>
            </FormContainer>
          </>
        </Otherwise>
      </Choose>
    </Container>
  )

}
