
import React from 'react'

import loadable from '@loadable/component'

import(/* webpackPrefetch: true */ '@pages/Ingredients')

const Ingredients = loadable(() => import(/* webpackChunkName: "ingredients" */'@pages/Ingredients'), {
  resolveComponent: (components) => components.Ingredients,
})

export function IngredientsAsync(): JSX.Element {

  return <Ingredients fallback={<div>Loading...</div>} />

}
