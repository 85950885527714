
import React from 'react'

import loadable from '@loadable/component'

import(/* webpackPrefetch: true */ '@pages/Me')

const Me = loadable(() => import(/* webpackChunkName: "me" */'@pages/Me'), {
  resolveComponent: (components) => components.Me,
})

export function MeAsync(): JSX.Element {

  return <Me fallback={<div>Loading...</div>} />

}
