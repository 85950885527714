
import React from 'react'
import { Svg } from './Svg'

export interface GreyAmexProps {
  color?: string
  hoverColor?: string
  className?: string
}

export function GreyAmex({ color, hoverColor, className }: GreyAmexProps): JSX.Element {

  return (
    <Svg strokeColor={color} strokeHoverColor={hoverColor} fillColor={color} fillHoverColor={hoverColor} className={className} width="100%" height="100%" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 0.600037H13.9V8.10004L13.2 9.20001L13.9 10.2V14.6H0V7.5L0.400024 7L0 6.5V0.600037Z" fill="#EAEAEA"/>
      <path d="M2.70001 10.3V8.10004H5L5.20001 8.40002L5.5 8.10004H13.9V10.1C13.9 10.1 13.7 10.2 13.4 10.3H8.80002L8.5 10V10.3H7.60001V9.70001C7.60001 9.70001 7.50001 9.80005 7.20001 9.80005H6.90002V10.3H5.5L5.30002 10L5 10.3H2.70001Z" fill="white"/>
      <path d="M0 6.40002L0.5 5.20001H1.40002L1.70001 5.90002V5.20001H2.80002L3 5.70001L3.20001 5.20001H8.20001V5.40002C8.20001 5.40002 8.50002 5.20001 8.90002 5.20001H10.5L10.8 5.90002V5.20001H11.7L12 5.60004V5.20001H12.9V7.40002H12L11.8 7V7.40002H10.4L10.3 7.10004H9.90002L9.80002 7.40002H8.90002C8.50002 7.40002 8.30002 7.20001 8.30002 7.20001V7.40002H6.90002L6.60001 7.10004V7.40002H1.40002L1.30002 7.10004H0.900024L0.800018 7.40002H0.200012V6.40002H0Z" fill="white"/>
      <path d="M0.700012 5.5L0 7.10004H0.5L0.600006 6.80005H1.40002L1.5 7.10004H2L1.30002 5.5H0.700012ZM1 5.90002L1.20001 6.5H0.700012L1 5.90002Z" fill="#EAEAEA"/>
      <path d="M2 7.10004V5.5H2.70001L3.10001 6.60004L3.5 5.5H4.10001V7.10004H3.70001V5.90002L3.30002 7.10004H2.90002L2.5 5.90002V7.10004H2Z" fill="#EAEAEA"/>
      <path d="M4.30005 7.10004V5.5H5.60004V5.90002H4.70004V6.20001H5.60004V6.5H4.70004V6.80005H5.60004V7.20001L4.30005 7.10004Z" fill="#EAEAEA"/>
      <path d="M5.90002 5.5V7.10004H6.30002V6.5H6.5L7 7.10004H7.5L7 6.5C7.2 6.5 7.40002 6.3 7.40002 6C7.40002 5.7 7.10002 5.5 6.80002 5.5H5.90002ZM6.30002 5.90002H6.80002C6.90002 5.90002 7 6.00004 7 6.10004C7 6.20004 6.90002 6.30005 6.80002 6.30005H6.30002V5.90002Z" fill="#EAEAEA"/>
      <path d="M7.99997 7.10004H7.59998V5.5H7.99997V7.10004Z" fill="#EAEAEA"/>
      <path d="M9 7.10004C8.5 7.10004 8.20001 6.80005 8.20001 6.30005C8.20001 5.80005 8.5 5.5 9 5.5H9.5V5.90002H9C8.8 5.90002 8.60001 6.10005 8.60001 6.30005C8.60001 6.60005 8.8 6.70001 9 6.70001H9.10001L9 7.10004Z" fill="#EAEAEA"/>
      <path d="M9.79999 5.5L9.09998 7.10004H9.59998L9.69998 6.80005H10.5L10.6 7.10004H11.1L10.4 5.5H9.79999ZM10.1 5.90002L10.3 6.5H9.79999L10.1 5.90002Z" fill="#EAEAEA"/>
      <path d="M11.1 7.10004V5.5H11.6L12.3 6.5V5.5H12.7V7.10004H12.2L11.5 6V7.10004H11.1Z" fill="#EAEAEA"/>
      <path d="M3 10V8.40002H4.30002V8.80005H3.40002V9.10004H4.30002V9.40002H3.40002V9.70001H4.30002V10.1H3V10Z" fill="#EAEAEA"/>
      <path d="M9.5 10V8.40002H10.8V8.80005H9.90002V9.10004H10.8V9.40002H9.90002V9.70001H10.8V10.1H9.5V10Z" fill="#EAEAEA"/>
      <path d="M4.40005 10L5.00003 9.20001L4.30005 8.40002H4.80005L5.20004 8.90002L5.60004 8.40002H6.10004L5.40005 9.20001L6.10004 10H5.60004L5.20004 9.5L4.80005 10H4.40005Z" fill="#EAEAEA"/>
      <path d="M6.20001 8.40002V10H6.60001V9.5H7C7.4 9.5 7.60001 9.30002 7.60001 8.90002C7.60001 8.60002 7.4 8.30005 7 8.30005H6.20001V8.40002ZM6.60001 8.70001H7.10001C7.20001 8.70001 7.30002 8.80002 7.30002 8.90002C7.30002 9.00002 7.20001 9.10004 7.10001 9.10004H6.60001V8.70001Z" fill="#EAEAEA"/>
      <path d="M7.90002 8.40002V10H8.30002V9.40002H8.5L9 10H9.5L9 9.40002C9.2 9.40002 9.40002 9.20002 9.40002 8.90002C9.40002 8.60002 9.10002 8.40002 8.80002 8.40002H7.90002ZM8.30002 8.70001H8.80002C8.90002 8.70001 9 8.80002 9 8.90002C9 9.00002 8.90002 9.10004 8.80002 9.10004H8.30002V8.70001Z" fill="#EAEAEA"/>
      <path d="M11.1 10V9.60004H11.9C12 9.60004 12.1 9.5 12.1 9.5C12.1 9.4 12 9.40002 11.9 9.40002H11.5C11.2 9.40002 11 9.20002 11 8.90002C11 8.60002 11.2 8.40002 11.6 8.40002H12.4L12.2 8.80005H11.5C11.4 8.80005 11.3 8.90002 11.3 8.90002C11.3 9.00002 11.4 9 11.5 9H11.9C12.3 9 12.4 9.2 12.4 9.5C12.4 9.8 12.2 10 11.9 10H11.1Z" fill="#EAEAEA"/>
      <path d="M12.6 10V9.60004H13.4C13.5 9.60004 13.6 9.5 13.6 9.5C13.6 9.4 13.5 9.40002 13.4 9.40002H13C12.7 9.40002 12.5 9.20002 12.5 8.90002C12.5 8.60002 12.7 8.40002 13.1 8.40002H13.9L13.7 8.80005H13C12.9 8.80005 12.8 8.90002 12.8 8.90002C12.8 9.00002 12.9 9 13 9H13.4C13.8 9 13.9 9.2 13.9 9.5C13.9 9.8 13.7 10 13.4 10H12.6Z" fill="#EAEAEA"/>
  </Svg>
  )

}
