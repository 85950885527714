import React, { useEffect, useState } from 'react'

import { decode } from 'html-entities'
import update from 'react-addons-update'
import styled, { LayoutSizeClass, useTheme } from 'styled-components'

import { ModalPlugin, GlobalModalTypeEnum } from '@api/local/ModalPlugin'
import { Button, PercentageBar, Rating, Heading, Paragraph, Tag, Card, LayoutCol, LayoutGrid, LayoutRow, Skeleton, SkeletonNode } from '@atoms/index'
import { ResponsivePXValue } from '@components/Theme'
import { useCustomerQuery, ProductReviewFragment, useGetProductReviewsQuery, ProductDetailsFragment } from '@hooks/api/index'
import { useLoadingData } from '@hooks/UseLoadingData'
import { Form, RadioInput, SelectInput, useForm } from '@molecules/inputs'
import { RatingModal } from '@molecules/modals'
import { useSimpleToasts } from '@simple/toasts'
import { CustomerTypeEnum, ReviewSortInput, ReviewSortOrderEnum, SortEnum } from '@uctypes/api/globalTypes'
import { DeviceContainer } from '@utility/DeviceContainer'

import { ProductReview } from './ProductReview'

const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${ResponsivePXValue('margin-bottom', '16px')}
  
  .review-filter-radio {
    ${ResponsivePXValue('gap', '8px')}
  }
`

const OuterContainer = styled.div`
  ${ResponsivePXValue('margin-bottom', { mobile: '12px' })}

  .review-title {
    ${ResponsivePXValue('margin-left', { mobile: '15px' })}
    ${ResponsivePXValue('margin', { mobile: '0 0 10px 0px', tablet: '0 0 20px 0px', desktop: '0 0 20px 0px' })}
  }
  .no-review{
    ${ResponsivePXValue('margin', { mobile: '16px 0 10px 0', tablet: '16px 0 10px 0', desktop: '16px 0 16px 0' })}
  }
  .title {
    ${ResponsivePXValue('margin', '0 0 12px 0')}
  }
  .copy {
    ${ResponsivePXValue('margin', '0 0 12px 0')}
  }
  .button {
    ${ResponsivePXValue('margin', '0 auto')}
  }
`

const Summary = styled.div`
  position: relative;
  ${ResponsivePXValue('padding', { mobile: '16px', tablet: '24px', desktop: '24px' })}

  .rate-button {
    flex-shrink: 0;
    ${ResponsivePXValue('margin-top', '12px')}
    ${ResponsivePXValue('margin-top', { mobile: '0', tablet: '12px', desktop: '12px' })}
    ${ResponsivePXValue('width', { tablet: '100%', desktop: '100%' })}
  }
`

const LoadingContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.3);
`

const RatingWrapper = styled.div`
  display: flex;
  align-items:flex-end;
  justify-content: flex-start;
  ${ResponsivePXValue('margin-bottom', '12px')}
`

const SummaryHeader = styled.div`
  display: flex;
  justify-content:  space-between;
  ${ResponsivePXValue('margin-bottom', '5px')}

  ${ResponsivePXValue('flex-grow', { mobile: '1' })}
  ${ResponsivePXValue('gap', '5px')}

  .rating-summary {
    margin: 0;
  }
`

const Reviews = styled.div`
  ${ResponsivePXValue('padding', { mobile: '0', tablet: '22px 0', desktop: '22px 0' })}
  ${ResponsivePXValue('margin', { mobile: '-16px 16px', tablet: '0', desktop: '0' })}

  .more-button {
    font-weight: 700;
    width: 100%;
    cursor: pointer;
  }
`

const Strong = styled.span`
  font-weight: 600;
`

const FilterBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${ResponsivePXValue('padding', '0')};
  ${ResponsivePXValue('margin-bottom', '12px')};

  .result-size {
    margin: 0;
  }
  .result-select {
    margin: 0;
  }
  .showAll {
    font-weight: 700;
    cursor: pointer;
    ${ResponsivePXValue('margin', '0')}
  }
`

const List = styled.div`

`

const RatingItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${ResponsivePXValue('gap', '6px')}
  ${ResponsivePXValue('margin-left', '6px')}
`

export interface ProductReviewsProps {
  product: ProductDetailsFragment
}

export interface FormValues {
  stars: number
}

enum ViewEnum {
  Skeleton = 'SKELETON',
  NoReviews = 'NO_REVIEWS',
  Reviews = 'REVIEWS',
}

interface ProductReviewsState {
  limit: number
  skip: number
  sort: ReviewSortInput
  showModal: boolean
  starFilter: { [k: string]: string | string[] }
  canShowMore: boolean
  shownReviewCount?: number
  view: ViewEnum
}

const DEFAULT_STATE: ProductReviewsState = {
  limit: 5,
  skip: 0,
  sort: {
    order: ReviewSortOrderEnum.RECENT,
    direction: SortEnum.DESC,
  },
  showModal: false,
  starFilter: null,
  canShowMore: true,
  shownReviewCount: 0,
  view: ViewEnum.Skeleton,
}

const timeout: any = null

export function ProductReviews({ product }: ProductReviewsProps): JSX.Element {

  const ratingForm = useForm()
  const orderForm = useForm()
  const [state, setState] = useState<ProductReviewsState>({ ...DEFAULT_STATE })
  const { data: customerData } = useCustomerQuery()
  const { data: reviewData, loading: reviewsLoading, refetch, fetchMore } = useGetProductReviewsQuery({
    skip: !product,
    variables: {
      sku: product?.sku,
      skip: state.skip,
      limit: state.limit,
      order: state.sort,
      filters: state.starFilter
        ? {
          rating: state.starFilter,
        }
        : null,
    },
  })

  const { addToast } = useSimpleToasts()
  const theme = useTheme()

  const reviews = useLoadingData<ProductReviewFragment[]>({ data: reviewData?.productReviews?.items, loading: reviewsLoading, defaultData: [] })

  const performFetcMore = async (): Promise<void> => {
    fetchMore({
      variables: {
        skip: state.skip,
      },
    })
  }

  const _handleFetchMore = (): void => {
    setState((prevState) => update(prevState, {
      skip: { $set: prevState.skip + prevState.limit },
    }))
  }

  const _handleRefetch = (): void => {
    refetch({
      skip: 0,
      limit: state.skip + state.limit,
    })
  }

  const _handleShowAddReview = (): void => {
    if (customerData?.currentCustomer?.customerType === CustomerTypeEnum.REGISTERED) {
      setState((prevState) => update(prevState, {
        showModal: { $set: true },
      }))
    } else {
      ModalPlugin.shared().toggleGlobalModal(true, GlobalModalTypeEnum.LOG_IN)
    }
  }

  const _handleHideAddReview = (): void => {
    setState((prevState) => update(prevState, {
      showModal: { $set: false },
    }))
  }

  const _handleReviewError = (e: Error): void => {
    addToast({
      message: e.message,
      appearance: 'error',
    })
  }

  const _handleReviewSuccess = (): void => {
    addToast({
      message: 'Successfully created review',
      appearance: 'success',
    })
    setState((prevState) => update(prevState, {
      showModal: { $set: false },
    }))
    refetch({
      skip: 0,
      limit: state.skip + state.limit,
    })
  }

  const _handleResetFilters = (): void => {
    ratingForm.setFieldsValue({ stars: null })
    setState((prevState) => update(prevState, {
      filter: { $set: null },
    }))
  }

  const _handleChange = (_: unknown, { stars }: FormValues): void => {
    setState((prevState) => update(prevState, {
      starFilter: { $set: { eq: stars } },
      skip: { $set: 0 },
    }))
  }

  const _hndleSortChange = (values: { order: ReviewSortOrderEnum }): void => {

    setState((prevState) => update(prevState, {
      skip: {
        $set: 0,
      },
      sort: {
        order: {
          $set: values.order,
        },
      },
    }))
  }

  const size: LayoutSizeClass = {
    mobile: 0,
    tablet: 0,
    desktop: 0,
  }

  const totalReviews =
  state.starFilter
    ? product?.ratingSummary?.ratingBreakdown.find((item) => item.rating === state.starFilter.eq)?.total || 0
    : product?.ratingSummary?.ratingBreakdown.reduce((total, ra) => total + ra.total, 0)

  useEffect(() => {
    if (product?.sku) {
      performFetcMore()
    }
  }, [state.skip])

  useEffect(() => {
    setState((prevState) => update(prevState, {
      canShowMore: { $set: reviews.length < totalReviews },
    }))
  }, [reviews.length, state.starFilter])

  useEffect(() => {
    if (product && product.reviewCount === 0) {
      setState((prevState) => update(prevState, {
        view: {
          $set: ViewEnum.NoReviews,
        },
      }))
    } else if (product && !reviewsLoading) {
      if (state.starFilter?.eq && !product.ratingSummary.ratingBreakdown.find((bre) => bre.rating === state.starFilter?.eq)?.total) {
        const nextStar = product.ratingSummary.ratingBreakdown.find((bre) => bre.total !== 0)
        if (nextStar) {
          setState((prevState) => update(prevState, {
            starFilter: {
              $set: {
                eq: nextStar.rating,
              },
            },
          }))
        }
      }
      setState((prevState) => update(prevState, {
        view: {
          $set: ViewEnum.Reviews,
        },
      }))
    }
  }, [reviewsLoading, reviews.length, product])

  useEffect(() => {
    if (state.starFilter && state.starFilter.eq !== ratingForm.getFieldValue('starts')) {
      ratingForm.setFieldValue('stars', state.starFilter.eq)
    }
  }, [state.starFilter])

  const ratingStars = ['5', '4', '3', '2', '1']
  let review: ProductReviewFragment

  return (
    <OuterContainer>
      <Choose>
        <When condition={state.view === ViewEnum.Skeleton}>
          <Card backgroundColor={theme.colors.white.pureWhite} className='no-review' >
            <Skeleton direction='column' justify='center' align='center' gap='18px' height='auto'>
              <SkeletonNode shape='rounded' color='gallery' align='flex-start' justify='flex-start'
                height={{ mobile: '12px', tablet: '24px', desktop: '24px' }}
                width={{ mobile: '90%', tablet: '70%', desktop: '65%' }}
              />
              <SkeletonNode shape='rounded' color='gallery' align='flex-start' justify='flex-start'
                height={{ mobile: '12px', tablet: '24px', desktop: '24px' }}
                width={{ mobile: '30%', tablet: '25%', desktop: '70%' }}
              />
              <SkeletonNode shape='rounded' color='gallery' align='flex-start' justify='flex-start'
                height={{ mobile: '12px', tablet: '24px', desktop: '52px' }}
                width={{ mobile: '30%', tablet: '25%', desktop: '20%' }}
              />
            </Skeleton>
          </Card>
        </When>
        <Otherwise>
          <RatingModal
            open={state.showModal}
            sku={product?.sku}
            onError={_handleReviewError}
            onSuccess={_handleReviewSuccess}
            onClose={_handleHideAddReview} />
          <Choose>
            <When condition={state.view === ViewEnum.NoReviews}>
              <Card backgroundColor={theme.colors.white.pureWhite} className='no-review' >
                <Heading variant='h4' className='title' align='center'>Be the first to leave a review. </Heading>
                <Paragraph
                  variant='p2' align='center' className='copy'>
                  Have you purchased <Strong>{decode(product?.name) ?? 'Product'}</Strong> before?
                </Paragraph>
                <Button
                  variant='ghost'
                  onClick={_handleShowAddReview}
                  className='button'
                  title='SHARE YOUR THOUGHTS' />
              </Card>
            </When>
            <Otherwise>
              <LayoutGrid margin={size}>
                <LayoutRow>
                  <LayoutCol span={{ mobile: 10, tablet: 10, desktop: 3.5 }}>
                    <Summary>
                      <Heading variant='h8' className='review-title'>Customer Reviews</Heading>
                      <SummaryHeader>
                        <RatingWrapper>
                          <Rating rating={product?.ratingSummary.averageRating} size='large' />
                          <Tag variant='t1' color={theme.colors.grey.silver}>{product?.ratingSummary.averageRating} ({product?.reviewCount ?? 0})</Tag>
                        </RatingWrapper>
                        <DeviceContainer mobile>
                          <Button variant='ghost' size='medium' onClick={_handleShowAddReview} className='rate-button' title='Write A Review' />
                        </DeviceContainer>
                      </SummaryHeader>
                      <DeviceContainer desktop tablet>
                        <FilterContainer>
                          <Form form={ratingForm} className='registerForm' onValuesChange={_handleChange}>
                            <RadioInput
                              name='stars'
                              showLabel={false}
                              className='review-filter-radio'
                              options={ratingStars.map((rating) => ({
                                title: (
                                  <RatingItem>
                                    <Paragraph>{rating} stars</Paragraph>
                                    <PercentageBar className="review-bar" percentage={product?.ratingSummary?.ratingBreakdown.find((item) => { return item.rating === rating })?.total || 0} />
                                  </RatingItem>
                                ),
                                value: rating,
                                disabled: !product?.ratingSummary?.ratingBreakdown.find((item) => item.rating === rating)?.total,
                              }))}
                              variant="vertical"
                            />
                          </Form>
                        </FilterContainer>
                        <Paragraph variant='p2' >
                          Have you purchased <Strong>{decode(product?.name) ?? 'Product'}</Strong> before?
                        </Paragraph>
                        <Button
                          variant='ghost'
                          onClick={_handleShowAddReview}
                          className='rate-button'
                          fullWidth
                          title='SHARE YOUR THOUGHTS' />
                      </DeviceContainer>
                      <If condition={reviewsLoading}>
                        <LoadingContainer />
                      </If>
                    </Summary>
                  </LayoutCol>
                  <LayoutCol span={{ mobile: 10, tablet: 10, desktop: 4.2 }}>
                    <Reviews>
                      <FilterBar>
                        <Tag variant='t1' align='left' className='result-size'>{totalReviews} Results</Tag>
                        <Form form={orderForm} onValuesChange={_hndleSortChange} initialValues={{ order: ReviewSortOrderEnum.TOP }}>
                          <SelectInput name='order' wrapperClassName='result-select' options={[{ title: 'Helpful', value: ReviewSortOrderEnum.TOP }, { title: 'Recent', value: ReviewSortOrderEnum.RECENT }]} />
                        </Form>
                      </FilterBar>
                      <List>
                        <For each='review' index='index' of={reviews ?? []}>
                          <ProductReview
                            key={review.reviewId}
                            productId={product.id}
                            review={review}
                            didUpdate={_handleRefetch} />
                        </For>
                      </List>
                      <If condition={state.canShowMore}>
                        <Tag
                          variant='t1'
                          className='more-button'
                          display='block'
                          align='center'
                          color={theme.colors.grey.stormDust}
                          onClick={_handleFetchMore}>- show more -</Tag>
                      </If>
                    </Reviews>
                  </LayoutCol>
                </LayoutRow>
              </LayoutGrid>
            </Otherwise>
          </Choose>
        </Otherwise>
      </Choose>
    </OuterContainer>
  )

}
