
import React from 'react'
import { Svg } from './Svg'

export interface LogoProps {
  color?: string
  hoverColor?: string
  className?: string
}

export function Logo({ color, hoverColor, className }: LogoProps): JSX.Element {

  return (
    <Svg strokeColor={color} strokeHoverColor={hoverColor} fillColor={color} fillHoverColor={hoverColor} className={className} width="100%" height="100%" viewBox="0 0 108 130" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M56.1001 55.5C54.6001 56.6 52.6001 56.6 51.1001 55.5C50.5001 55.1 37.0001 45.3 36.1001 29.9C35.6001 19.9 40.4001 10.2 50.6001 1.1C52.2001 -0.400002 54.7001 -0.400002 56.4001 1.1C66.6001 10.2 71.5001 19.9 71.0001 29.9C70.1001 45.3 56.6001 55.1 56.1001 55.5ZM53.6001 10.3C47.4001 16.7 44.5001 23.1 44.8001 29.4C45.2001 37.1 50.4001 43.2 53.6001 46.3C56.8001 43.2 62.0001 37.1 62.4001 29.4C62.7001 23.1 59.7001 16.7 53.6001 10.3Z" className='fill' />
      <path d="M104 94.1C90.9001 90.2 80.0001 91.1 71.8001 96.8C69.7001 98.2 68.0001 99.9 66.5001 101.6V88.3C74.7001 87.5 81.9001 85.9 86.7001 83.5C104.6 74.4 103.1 52.1 103 51.2C102.8 49.2 101.3 47.5 99.3001 47.3C85.7001 45.4 75.2001 47.8 67.9001 54.7C59.0001 63.1 57.9001 75.4 57.9001 80.4C55.1001 80.5 52.2001 80.5 49.3001 80.4C49.3001 75.4 48.2001 63.1 39.3001 54.7C32.0001 47.9 21.4001 45.4 7.90006 47.3C5.90006 47.6 4.40006 49.2 4.20006 51.2C4.00006 52.1 2.50006 74.4 20.5001 83.5C25.2001 85.9 32.5001 87.5 40.7001 88.3V101.6C39.2001 99.9 37.5001 98.2 35.4001 96.8C27.2001 91.1 16.3001 90.2 3.20006 94.1C1.10006 94.6 -0.199944 96.7 0.200056 98.8C2.60006 112.4 8.40006 121.7 17.2001 126.5C22.1001 129.1 27.2001 130 31.8001 130C39.9001 130 46.4001 127.3 46.8001 127.2C48.4001 126.5 49.4001 125 49.4001 123.3V88.8C52.3001 88.9 55.2001 88.9 58.0001 88.8V123.3C58.0001 125 59.0001 126.5 60.6001 127.2C61.0001 127.4 67.5001 130 75.6001 130C80.2001 130 85.3001 129.2 90.2001 126.5C99.1 121.7 104.8 112.4 107.2 98.8C107.4 96.7 106.1 94.7 104 94.1ZM21.2001 118.9C15.6001 115.9 11.7001 110 9.50006 101.4C18.2001 99.5 25.2001 100.3 30.4001 103.9C36.8001 108.3 39.3001 115.9 40.2001 120.3C35.9001 121.4 28.0001 122.6 21.2001 118.9ZM24.4001 75.8C15.2001 71.1 13.2001 60.8 12.8001 55.4C21.8001 54.7 28.7001 56.6 33.3001 60.9C39.5001 66.7 40.5001 75.6 40.6001 79.7C33.9001 79 28.1001 77.7 24.4001 75.8ZM73.8001 60.9C77.8001 57.1 83.6001 55.2 90.8001 55.2C91.9001 55.2 93.1001 55.2 94.3001 55.3C93.9001 60.7 91.9001 71 82.7001 75.7C79.0001 77.6 73.1001 78.9 66.4001 79.6C66.6001 75.6 67.6001 66.7 73.8001 60.9ZM86.0001 118.9C79.2001 122.6 71.3001 121.3 67.0001 120.2C67.9001 115.9 70.4001 108.3 76.8001 103.9C82.0001 100.3 89.1001 99.5 97.8001 101.4C95.5001 110 91.5001 115.9 86.0001 118.9Z" className='fill' />
    </Svg>
  )

}
    