// TODO: find all instances of merge and reset

import { useGetAuthQuery } from './api/index'

export function useCartId(): { cartId: string | null, loading: boolean } {

  const { data: authData } = useGetAuthQuery()
  return { cartId: authData?.auth?.cartId || null, loading: false }

}
