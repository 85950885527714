import React from 'react'

import loadable from '@loadable/component'

import(/* webpackPrefetch: true */ '@pages/IngredientsLibrary')

const IngredientsLibrary = loadable(() => import(/* webpackChunkName: "ingredientsLibrary" */'@pages/IngredientsLibrary'), {
  resolveComponent: (components) => components.IngredientsLibrary,
})

export function IngredientsLibraryAsync(): JSX.Element {

  return <IngredientsLibrary fallback={<div>Loading...</div>} />

}
