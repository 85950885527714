import { useEffect } from 'react'

import { useTheme } from 'styled-components'

import { AppPlugin } from '@api/local/AppPlugin'
import { DeviceTypeEnum } from '@uctypes/api/globalTypes'

export interface ResizeContainerProps {
  children: JSX.Element
}

export function ResizeContainer({ children }: ResizeContainerProps): JSX.Element {

  const theme = useTheme()

  const _handleResize = (): void => {
    let deviceType = DeviceTypeEnum.MOBILE
    if (theme.isUltra()) {
      deviceType = DeviceTypeEnum.ULTRA
    } else if (theme.isDesktop()) {
      deviceType = DeviceTypeEnum.DESKTOP
    } else if (theme.isTablet()) {
      deviceType = DeviceTypeEnum.TABLET
    }

    AppPlugin.shared().setDeviceType(deviceType)
  }

  useEffect(() => {
    _handleResize()
    window.addEventListener('resize', _handleResize)
    return () => window.removeEventListener('resize', _handleResize)
  }, [])

  return children

}
