
import React from 'react'
import { Svg } from './Svg'

export interface AccountProps {
  color?: string
  hoverColor?: string
  className?: string
}

export function Account({ color, hoverColor, className }: AccountProps): JSX.Element {

  return (
    <Svg strokeColor={color} strokeHoverColor={hoverColor} fillColor={color} fillHoverColor={hoverColor} className={className} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 85.04 85.04">
      <path  className='fill-stroke' strokeWidth="2" d="M61.1,57.94c-1.5-3.63-4.02-6.72-7.28-8.93c-1.73-1.18-3.6-2.07-5.57-2.65c3.43-2.02,5.58-5.72,5.58-9.75
    	c0-6.24-5.07-11.31-11.31-11.31s-11.31,5.07-11.31,11.31c0,4.03,2.15,7.73,5.58,9.75c-1.97,0.58-3.84,1.47-5.57,2.65
    	c-3.26,2.22-5.77,5.31-7.28,8.94c-0.19,0.47-0.14,1,0.14,1.42c0.28,0.42,0.75,0.68,1.26,0.68h34.34c0.51,0,0.98-0.25,1.26-0.68
    	C61.24,58.94,61.29,58.41,61.1,57.94L61.1,57.94z M57.24,57H27.79c3.05-5.22,8.61-8.43,14.73-8.43S54.2,51.78,57.24,57z
    	 M34.25,36.61c0-4.56,3.71-8.27,8.27-8.27s8.27,3.71,8.27,8.27s-3.71,8.27-8.27,8.27S34.25,41.17,34.25,36.61z" />
    </Svg>
  )

}
    