export interface FooterLinkItem {
  title: string
  link: string
  target?: string
}
export interface FooterLinkGroup {
  title: string
  subs: FooterLinkItem[]
}
export const links: FooterLinkGroup[] = [
  {
    title: 'Faithful to Nature',
    subs: [
      {
        title: 'About Us',
        link: '/about-us',
      },
      {
        title: 'Our Brands',
        link: '/brands',
      },
      {
        title: 'Retail Stores',
        link: '/retail-stores',
      }, {
        title: 'Gift Cards',
        link: '/gift-vouchers',
      },
      {
        title: 'BCorp Certified',
        link: 'https://www.bcorporation.net/en-us/find-a-b-corp/company/faithful-to-nature/',
        target: '_blank',
      },
    ],
  }, {
    title: 'Our Products & Values',
    subs : [
      {
        title: 'Our Products',
        link: '/brands',
      },  
      {
        title: 'Product Values',
        link: '/values-listing',
      }, 
      {
        title: 'Product Listing Policy',
        link: '/ingredient-policy-information',
      },
      {
        title: 'The Bad Bunch',
        link: '/bad-bunch',
      }, 
      {
        title: 'Ingredients Library',
        link: '/ingredient',
      },
      {
        title: 'The FtN Range',
        link: '/brands/faithful-to-nature',
      },
    ],  
  }, {
    title: 'Sustainibility & Initiatives',
    subs: [
      {
        title: 'Carbon Neutral Shipping',
        link: '/carbon-neutral',
      },
      {
        title: 'Partner With Us',
        link: 'https://faithfultonature.ac-page.com/partnerships',
        target: '_blank',
      }, 
      {
        title: 'Our Blog',
        link: 'https://www.faithful-to-nature.co.za/blog/',
      }, 
      {
        title: 'Affiliates',
        link: 'https://www.faithful-to-nature.co.za/affiliate-program'
      },

    ],
  }, {
    title: 'Customer Support',
    subs: [
      {
        title: 'FAQs',
        link: '/faq',
      }, {
        title: 'Refunds & Returns',
        link: '/faq?section=Returns',
      }, {
        title: 'Delivery Options',
        link: '/faq?section=Delivery',
      }, {
        title: 'Payment Options',
        link: '/faq?section=Payments',
      }, {
        title: 'FtN Loyalty Program',
        link: '/me/points?section=Do Faithful Points expire?',
      },
    ],
  },
]
