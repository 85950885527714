
import React from 'react'
import { Svg } from './Svg'

export interface EyeOffProps {
  color?: string
  hoverColor?: string
  className?: string
}

export function EyeOff({ color, hoverColor, className }: EyeOffProps): JSX.Element {

  return (
    <Svg strokeColor={color} strokeHoverColor={hoverColor} fillColor={color} fillHoverColor={hoverColor} className={className} width="100%" height="100%" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.5 14C13.4343 14.0001 13.3693 13.9872 13.3086 13.962C13.2479 13.9369 13.1929 13.9 13.1465 13.8534L2.14654 2.85342C2.05673 2.75889 2.0074 2.63301 2.00907 2.50263C2.01074 2.37225 2.06328 2.24768 2.15548 2.15548C2.24768 2.06328 2.37225 2.01074 2.50263 2.00907C2.63301 2.0074 2.75889 2.05673 2.85342 2.14654L13.8534 13.1465C13.9233 13.2165 13.9709 13.3055 13.9902 13.4025C14.0094 13.4995 13.9995 13.6 13.9617 13.6913C13.9239 13.7826 13.8598 13.8607 13.7776 13.9156C13.6955 13.9706 13.5988 13.9999 13.5 14Z" className='fill' />
      <path d="M7.75009 9.87032L6.13166 8.25189C6.12238 8.24269 6.11046 8.23663 6.09757 8.23456C6.08467 8.23248 6.07145 8.2345 6.05976 8.24033C6.04807 8.24616 6.0385 8.2555 6.0324 8.26704C6.02629 8.27859 6.02396 8.29176 6.02572 8.3047C6.09106 8.72458 6.28821 9.11282 6.58869 9.41329C6.88916 9.71377 7.2774 9.91092 7.69728 9.97626C7.71022 9.97802 7.72339 9.97569 7.73493 9.96958C7.74648 9.96348 7.75582 9.95391 7.76165 9.94222C7.76748 9.93053 7.7695 9.91731 7.76742 9.90441C7.76535 9.89152 7.75929 9.8796 7.75009 9.87032Z" className='fill' />
      <path d="M8.24997 6.12968L9.87091 7.74999C9.88016 7.75932 9.89211 7.76549 9.90507 7.76764C9.91803 7.76978 9.93133 7.76779 9.9431 7.76194C9.95486 7.7561 9.96448 7.74669 9.9706 7.73507C9.97671 7.72344 9.979 7.71018 9.97716 7.69718C9.91198 7.27672 9.71469 6.88792 9.41383 6.58707C9.11297 6.28621 8.72417 6.08891 8.30372 6.02374C8.2907 6.02173 8.27738 6.02389 8.26566 6.0299C8.25394 6.03592 8.24441 6.04548 8.23845 6.05723C8.23249 6.06898 8.2304 6.08231 8.23246 6.09532C8.23453 6.10833 8.24066 6.12036 8.24997 6.12968Z" className='fill' />
      <path d="M15.3438 8.5425C15.4473 8.38012 15.502 8.19146 15.5014 7.99892C15.5009 7.80638 15.4451 7.61804 15.3407 7.45625C14.5138 6.1775 13.441 5.09125 12.2385 4.31469C10.9063 3.45469 9.43758 3 7.99008 3C7.22699 3.00105 6.46908 3.12547 5.74571 3.36844C5.72546 3.37517 5.70727 3.38698 5.69288 3.40273C5.67849 3.41848 5.66837 3.43765 5.66348 3.45842C5.65859 3.47919 5.6591 3.50086 5.66496 3.52138C5.67082 3.5419 5.68184 3.56057 5.69696 3.57563L7.17321 5.05188C7.18854 5.06724 7.20761 5.07834 7.22855 5.0841C7.24948 5.08985 7.27155 5.09005 7.29258 5.08469C7.79297 4.96274 8.31633 4.97168 8.81227 5.11063C9.30821 5.24959 9.76002 5.51388 10.1242 5.87807C10.4884 6.24225 10.7527 6.69406 10.8916 7.19C11.0306 7.68594 11.0395 8.2093 10.9176 8.70969C10.9123 8.73068 10.9125 8.75268 10.9183 8.77355C10.924 8.79443 10.9351 8.81345 10.9504 8.82875L13.0738 10.9538C13.0959 10.9759 13.1255 10.9889 13.1567 10.9903C13.188 10.9917 13.2186 10.9813 13.2426 10.9613C14.06 10.2646 14.7682 9.44924 15.3438 8.5425Z" className='fill' />
      <path d="M8 11C7.54586 11.0001 7.09764 10.897 6.68913 10.6986C6.28063 10.5001 5.9225 10.2116 5.64175 9.8546C5.361 9.49764 5.16496 9.08159 5.06841 8.63783C4.97186 8.19407 4.97732 7.73418 5.08437 7.29284C5.08968 7.27185 5.08945 7.24984 5.0837 7.22897C5.07795 7.2081 5.06687 7.18908 5.05156 7.17377L2.96281 5.08409C2.94066 5.06191 2.91099 5.04887 2.87968 5.04754C2.84837 5.04621 2.8177 5.05669 2.79375 5.0769C2.03156 5.72721 1.325 6.51846 0.679061 7.44315C0.565994 7.60543 0.503723 7.79767 0.500162 7.99542C0.496601 8.19318 0.55191 8.38753 0.659061 8.55377C1.48437 9.84534 2.54625 10.9331 3.73031 11.6991C5.06437 12.5625 6.4975 13 7.99 13C8.76079 12.998 9.52658 12.8762 10.26 12.6391C10.2804 12.6326 10.2988 12.6209 10.3134 12.6052C10.3281 12.5895 10.3384 12.5703 10.3434 12.5495C10.3485 12.5286 10.3481 12.5068 10.3423 12.4862C10.3365 12.4655 10.3255 12.4467 10.3103 12.4316L8.82687 10.9485C8.81157 10.9332 8.79255 10.9221 8.77168 10.9163C8.75081 10.9106 8.7288 10.9103 8.70781 10.9156C8.47606 10.9718 8.23845 11.0001 8 11Z" className='fill' />
    </Svg>
  )

}
    