import React from 'react'

import styled, { CSS, css } from 'styled-components'

const CellContainer = styled.div<{ align: 'center' | 'start' | 'end' }>`
  width: 100%;
  display: flex;
  ${(props): CSS => {
    if (props.align === 'center') {
      return css`justify-content: center;`
    } else if (props.align === 'end') {
      return css`justify-content: flex-end;`
    } else if (props.align === 'start') {
      return css`justify-content: flex-start;`
    }
  }}
`

export interface CellProps {
  children: React.ReactNode
  className?: string
  align?: 'center' | 'start' | 'end'
}

export function Cell({ children, className, align = 'start' }: CellProps): JSX.Element {
  return (
    <CellContainer className={className} align={align}>
      {children}
    </CellContainer>
  )
}

const RowContainer = styled.div<{ align: 'center' | 'start' | 'end' }>`
  width: 100%;
  display: flex;
  justify-content: center;
  ${(props): CSS => {
    if (props.align === 'center') {
      return css`align-items: center;`
    } else if (props.align === 'end') {
      return css`align-items: flex-end;`
    } else if (props.align === 'start') {
      return css`align-items: flex-start;`
    }
  }}
`

export interface RowProps {
  children: JSX.Element[] | JSX.Element
  className?: string
  align?: 'center' | 'start' | 'end'
}

export function Row({ children, className, align = 'center' }: RowProps): JSX.Element {
  return (
    <RowContainer className={className} align={align}>
      {children}
    </RowContainer>
  )
}

const TableContainer = styled.div`
  width: 100%;
`

export interface TableProps {
  children: JSX.Element[] | JSX.Element
  className?: string
}

export function Table({ children, className }: TableProps): JSX.Element {

  return (
    <TableContainer className={className}>
      {children}
    </TableContainer>
  )

}
