import React from 'react'

import loadable from '@loadable/component'

// import(/* webpackPrefetch: true */ '@components/builder/pages/Widgets')

const Widgets = loadable(() => import(/* webpackChunkName: "widgets" */'@components/builder/pages/Widgets'), {
  resolveComponent: (components) => components.Widgets,
})

export function WidgetsAsync(): JSX.Element {

  return <Widgets fallback={<div>Loading...</div>} />

}
