import React from 'react'

import { useNavigate } from 'react-router'
import styled from 'styled-components'

export type LinkTransforms = 'none' | 'capitalize' | 'uppercase' | 'lowercase'
export type LinkDecorations = 'none' | 'underline' | 'overline' | 'line-through'

const Anchor = styled.a<{ decoration: string, transform: string, color?: string, hoverColor?: string, active: boolean, bold: boolean, display: string }>`

  cursor: pointer;
  white-space: nowrap;

  color: ${(props): string => props.color ? props.color : props.active ? props.theme.colors.blue.curiousBlue : props.theme.colors.blue.curiousBlue};
  text-decoration: ${(props): string => props.decoration};
  text-transform: ${(props): string => props.transform};
  font-weight: ${(props): string => props.bold ? '700' : '400'};
  display: ${(props): string => props.display};

  &:hover {
    font-weight: ${(props): string => props.bold ? '700' : '400'};
    color: ${(props): string => props.hoverColor ? props.hoverColor : props.theme.colors.black.pureBlack};
  }
`

export interface LinkPropsInterface {
  children: React.ReactNode
  href: string
  display?: string
  blank?: boolean
  transform?: LinkTransforms
  decoration?: LinkDecorations
  onClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, href: string) => void
  className?: string
  color?: string
  hoverColor?: string
  active?: boolean
  bold?: boolean
}

export function Link(props: LinkPropsInterface): JSX.Element {

  const {
    children,
    href,
    blank = false,
    transform = 'none',
    decoration = 'none',
    display = 'inline-block',
    onClick,
    className,
    color,
    hoverColor,
    active = false,
    bold = false,
  } = props
  const navigate = useNavigate()

  const _handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (onClick) {
      e.preventDefault()
      return onClick(e, href)
    }
    if (href.indexOf('http') === -1) {
      e.preventDefault()
      navigate(href)
    }
  }

  return (
    <Anchor
      active={active}
      className={className}
      decoration={decoration}
      transform={transform}
      href={href}
      target={blank ? '_blank' : '_self'}
      color={color}
      display={display}
      hoverColor={hoverColor}
      bold={bold}
      onClick={_handleClick}>
      {children}
    </Anchor>
  )

}
