import React from 'react'

import styled from 'styled-components'

import { Heading } from '@atoms/typography'
import { ResponsivePXValue } from '@components/Theme'
import { PagePlainTextSectionFragment } from '@hooks/api/index'
import { HTML } from '@molecules/content'
import { PageHorizontalAlignment } from '@uctypes/api/globalTypes'

const Container = styled.div<{ backgroundColor: string }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${({ backgroundColor }) => backgroundColor};
  ${ResponsivePXValue('padding', { mobile: '24px 16px', tablet: '24px', desktop: '24px' })}
  ${ResponsivePXValue('gap', { mobile: '16px', tablet: '12px', desktop: '12px' })}
`

const HeadingContainer = styled.div``

const DescriptionContainer = styled.div``

export interface PagePlainTextSectionProps {
  section: PagePlainTextSectionFragment
}

export function PagePlainTextSection({ section }: PagePlainTextSectionProps): JSX.Element {

  const { title, description, backgroundColor, foregroundColor, horizontalAlignment } = section

  const alignment = horizontalAlignment === PageHorizontalAlignment.LEFT
    ? 'left'
    : horizontalAlignment === PageHorizontalAlignment.RIGHT
      ? 'right'
      : 'center'

  return (
    <Container backgroundColor={backgroundColor}>
      <HeadingContainer>
        <Heading
          variant='h1'
          align={alignment}
          color={foregroundColor}
          className='title'>
          {title}
        </Heading>
      </HeadingContainer>
      <If condition={!!description}>
        <DescriptionContainer>
          <HTML align={alignment}>{ description }</HTML>
        </DescriptionContainer>
      </If>
    </Container>
  )

}
