
import React from 'react'

import loadable from '@loadable/component'

import { PageLoader } from '@atoms/notifications'

import(/* webpackPrefetch: true */ '@pages/BadBunch')

const BadBunch = loadable(() => import(/* webpackChunkName: "BadBunch" */'@pages/BadBunch'), {
  resolveComponent: (components) => components.BadBunch,
})

export function BadBunchAsync(): JSX.Element {
  return <BadBunch fallback={<PageLoader />} />

}
