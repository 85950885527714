import React from 'react'

import { Svg } from './Svg'

export interface StarOutlineProps {
  color?: string
  hoverColor?: string
  className?: string
}

export function StarOutline({ color, hoverColor, className }: StarOutlineProps): JSX.Element {

  return (
    <Svg strokeColor={color} strokeHoverColor={hoverColor} fillColor={color} fillHoverColor={hoverColor} className={className} width="100%" height="100%" viewBox="0 0 20 20" version="1.1">
      <g transform="matrix(0.908938,0,0,0.908938,-0.937944,-0.417111)">
        <path className='fill' d="M21.284,8.276L15.333,7.411L12.673,2.018C12.6,1.87 12.48,1.751 12.333,1.678C11.963,1.495 11.512,1.648 11.327,2.018L8.667,7.411L2.716,8.276C2.552,8.299 2.402,8.377 2.287,8.494C2.149,8.636 2.072,8.828 2.075,9.028C2.077,9.227 2.159,9.416 2.302,9.555L6.607,13.753L5.59,19.68C5.566,19.818 5.581,19.96 5.634,20.09C5.687,20.22 5.774,20.332 5.888,20.414C6.001,20.496 6.135,20.545 6.274,20.555C6.414,20.565 6.554,20.536 6.677,20.47L12,17.672L17.323,20.47C17.468,20.548 17.637,20.573 17.798,20.545C18.206,20.475 18.481,20.088 18.41,19.68L17.393,13.753L21.698,9.555C21.816,9.441 21.893,9.291 21.916,9.127C21.98,8.716 21.694,8.337 21.284,8.276ZM15.581,13.163L16.427,18.091L12,15.766L7.573,18.094L8.419,13.165L4.837,9.673L9.787,8.953L12,4.47L14.213,8.953L19.163,9.673L15.581,13.163Z" />
      </g>
    </Svg>
  )

}
