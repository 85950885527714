
import React from 'react'
import { Svg } from './Svg'

export interface HomeLogoProps {
  color?: string
  hoverColor?: string
  className?: string
}

export function HomeLogo({ color, hoverColor, className }: HomeLogoProps): JSX.Element {

  return (
    <Svg strokeColor={color} strokeHoverColor={hoverColor} fillColor={color} fillHoverColor={hoverColor} className={className} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 85.04 85.04">
      <g>
        <g>
          <g>
            <path className='fill' strokeWidth="2" strokeLinecap="square" d="M43.25,39.89c-0.43,0.31-1.02,0.31-1.45,0l0,0c-0.17-0.12-4.07-2.96-4.31-7.39c-0.16-2.89,1.26-5.7,4.21-8.34v0 c0.47-0.42,1.19-0.42,1.67,0l0,0c2.95,2.64,4.37,5.45,4.21,8.34C47.32,36.93,43.41,39.77,43.25,39.89L43.25,39.89z M42.52,26.82 c-1.79,1.85-2.64,3.7-2.55,5.53c0.12,2.23,1.62,4,2.55,4.89c0.93-0.89,2.43-2.66,2.55-4.89C45.16,30.52,44.31,28.67,42.52,26.82z " />
          </g>
          <g>
            <path className='fill' strokeWidth="2" strokeLinecap="square" d="M57.11,51.07L57.11,51.07c-3.8-1.13-6.93-0.88-9.31,0.77c-0.59,0.41-1.1,0.88-1.53,1.38v-3.85 c2.36-0.23,4.47-0.7,5.83-1.39c5.19-2.63,4.75-9.07,4.73-9.34c-0.05-0.58-0.49-1.06-1.07-1.14c-3.92-0.56-6.98,0.16-9.09,2.14 c-2.58,2.42-2.89,5.98-2.9,7.42c-0.82,0.03-1.65,0.03-2.47,0c-0.01-1.44-0.32-5-2.9-7.42c-2.11-1.98-5.17-2.7-9.09-2.14 c-0.58,0.08-1.02,0.56-1.07,1.14c-0.02,0.27-0.46,6.71,4.73,9.34c1.36,0.69,3.47,1.15,5.83,1.39v3.85 c-0.43-0.5-0.94-0.97-1.53-1.38c-2.38-1.65-5.51-1.91-9.31-0.77l0,0c-0.6,0.15-0.99,0.75-0.88,1.36c0.71,3.93,2.36,6.62,4.92,8 c1.41,0.76,2.89,1.01,4.22,1.01c2.35,0,4.22-0.77,4.34-0.82l0,0c0.45-0.19,0.74-0.63,0.74-1.12v-9.96c0.83,0.03,1.67,0.03,2.5,0 v9.96c0,0.49,0.29,0.93,0.74,1.12l0,0c0.12,0.05,1.99,0.82,4.34,0.82c1.33,0,2.81-0.25,4.22-1.01c2.56-1.38,4.22-4.07,4.92-8 C58.1,51.81,57.71,51.22,57.11,51.07z M33.16,58.23c-1.62-0.87-2.76-2.58-3.4-5.07c2.51-0.56,4.54-0.32,6.05,0.72 c1.84,1.26,2.56,3.47,2.83,4.73C37.41,58.94,35.13,59.3,33.16,58.23z M34.08,45.76c-2.66-1.35-3.24-4.33-3.35-5.9 c2.59-0.2,4.59,0.34,5.94,1.6c1.79,1.67,2.08,4.26,2.12,5.44C36.85,46.68,35.15,46.3,34.08,45.76z M48.38,41.45 c1.17-1.09,2.82-1.64,4.93-1.64c0.33,0,0.66,0.01,1.01,0.04c-0.11,1.57-0.69,4.55-3.35,5.9c-1.08,0.55-2.77,0.92-4.71,1.13 C46.29,45.71,46.59,43.12,48.38,41.45z M51.89,58.23c-1.96,1.07-4.26,0.71-5.5,0.38c0.27-1.25,0.98-3.44,2.82-4.72 c1.51-1.04,3.54-1.29,6.06-0.73C54.64,55.65,53.5,57.35,51.89,58.23z" />
          </g>
        </g>
      </g>
    </Svg>
  )

}
    