// @Move
import React, { RefObject, useEffect, useRef } from 'react'

import { camelCase, sentenceCase } from 'change-case'
import styled from 'styled-components'

import { APP_DEFAULT_STATE } from '@api/local/AppPlugin'
import { NAVIGATION_DEFAULT_STATE } from '@api/local/NavigationPlugin'
import { Icon, LocalIconEnums } from '@atoms/images'
import { Seperator } from '@atoms/layout'
import { Paragraph } from '@atoms/typography'
import { ResponsivePXValue, theme } from '@components/Theme'
import { AggregationFragment, useGetAppQuery, useGetNavigationQuery } from '@hooks/api/index'
import { Form, SelectInput, useForm } from '@molecules/inputs'
import { CustomSelectInput } from '@molecules/inputs/CustomSelectInput'
import { OrderValue, ProductFilterInputs } from '@organisms/index'
import { AggregationTypeEnum, DeviceTypeEnum, MobileMenuDisplayEnum, OrderDirectionEnum } from '@uctypes/api/globalTypes'
import { DeviceContainer } from '@utility/DeviceContainer'

// Desktop

const DesktopControls = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  ${ResponsivePXValue('gap', '12px')}
  ${ResponsivePXValue('Height', '56px')}
  ${ResponsivePXValue('margin-bottom', '12px')}

  .form {
    ${ResponsivePXValue('width', '134px')}
  }

  .sort-by {
    width: fit-content;
    white-space: nowrap;
  }
  .results-total {
    width: fit-content;
    white-space: nowrap;
  }

  .order {
    cursor:pointer;
  }

  .seperator {
    position: revert;
  }

`

// Mobile

const FilterBar = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  
  background-color: ${(props): string => props.theme.colors.white.pureWhite};
  ${ResponsivePXValue('height', '49px')}
  ${ResponsivePXValue('gap', '8px')}
  ${ResponsivePXValue('padding', '8px')}
`
const ViewBy = styled.div`
  ${ResponsivePXValue('height', '32px')}
  ${ResponsivePXValue('width', '42px')}
  ${ResponsivePXValue('padding', '9px')}
  background-color: ${(props): string => props.theme.colors.white.fantasy};
`
const SortBy = styled.div`
  display: flex;
  justify-content: center;
  ${ResponsivePXValue('gap', '5px')}
  ${ResponsivePXValue('width', '125px')}
  ${ResponsivePXValue('height', '32px')}
  ${ResponsivePXValue('padding', '9px')}

  background-color: ${(props): string => props.theme.colors.white.fantasy};

  .icon {
    ${ResponsivePXValue('height', '18px')}
    ${ResponsivePXValue('width', '10.25px')}
  }
`

const FilterBy = styled.div`
  display: flex;
  justify-content: center;
  ${ResponsivePXValue('gap', '5px')}
  ${ResponsivePXValue('width', '125px')}
  ${ResponsivePXValue('height', '32px')}
  ${ResponsivePXValue('padding', '9px')}

  background-color: ${(props): string => props.theme.colors.white.fantasy};

  .icon {
    ${ResponsivePXValue('height', '10px')}
    ${ResponsivePXValue('width', '15px')}
  }
`

const MobileControls = styled.div<{mobileMenuDisplay: boolean}>`
  position: sticky;

  ${(props) => {
    if (props.mobileMenuDisplay) {
      return ResponsivePXValue('top', '56px')
    } else {
      return ResponsivePXValue('top', '0px')
    }
  }}
  transition: top 0.2s;
  z-index: 6;
  background-color: ${(props): string => props.theme.colors.white.pampas};
  ${ResponsivePXValue('box-shadow', '0px 2px 6px rgba(0, 0, 0, 0.1)')}
  .select {
    ${ResponsivePXValue('margin-top', '1px')}
  }
`

const Iconwrapper = styled.div``

enum DisplayTypeEnum {
  GRID = 'GRID',
  LIST = 'LIST',
}

export interface CatalogueHeaderProps {
  aggregations: Readonly<AggregationFragment[]>
  filters: ProductFilterInputs
  loading: boolean
  count: number
  displayType: DisplayTypeEnum
  order: OrderValue
  term?: string
  availableSortBy?: Readonly<string[]>
  productsTop?: number
  onDisplayTypeChange?: (displayType: DisplayTypeEnum) => void
  onFilterChange?: (filters: ProductFilterInputs) => void
  onOrderChange: (order: OrderValue) => void
  onToggleFilters?: () => void
}

export function CatalogueHeader(props: CatalogueHeaderProps): JSX.Element {

  const {
    aggregations,
    filters,
    loading,
    displayType,
    order,
    count,
    term,
    availableSortBy = ['name', 'performance', 'price', 'position', 'relevance'],
    productsTop = 0,
    onFilterChange,
    onDisplayTypeChange,
    onOrderChange,
    onToggleFilters,
  } = props

  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()
  const { data: navigationData = { navigation: { ...NAVIGATION_DEFAULT_STATE } } } = useGetNavigationQuery()

  const form = useForm()
  const mobileForm = useForm()
  const mobileControlsRef: RefObject<HTMLDivElement> = useRef()
  const orderMap: {[k: string]: { title: string, options: OrderDirectionEnum[] }} = {
    name: { title: 'Name', options: [OrderDirectionEnum.ASC, OrderDirectionEnum.DESC] },
    performance: { title: 'Performance', options: [OrderDirectionEnum.DESC] },
    price: { title: 'Price', options: [OrderDirectionEnum.ASC, OrderDirectionEnum.DESC] },
    position: { title: 'Recommended', options: [OrderDirectionEnum.ASC] },
    relevance: { title: 'Relevance', options: [OrderDirectionEnum.ASC] },
  }

  const _handleSortChange = (value: OrderValue): void => {
    onOrderChange(value)
    if (mobileControlsRef.current && appData.app.deviceType === DeviceTypeEnum.MOBILE) {
      const stickyTop = parseInt(window.getComputedStyle(mobileControlsRef.current).top)
      const currentTop = mobileControlsRef.current.getBoundingClientRect().top
      const height = mobileControlsRef.current.getBoundingClientRect().height
      if (currentTop === stickyTop) {
        window.scrollTo(0, productsTop - height)
      }
    }
  }

  const _handleDesktopSortFieldCahnge = ({ order }: { order: string }): void => {
    const [field, direction] = order.split('-')
    _handleSortChange({ field, direction: direction as OrderDirectionEnum })
  }

  function _handleMobileSortFieldCahnge(value: string) {
    _handleDesktopSortFieldCahnge({ order: value })
  }

  const _handleDisplayTypeChange = (): void => {
    onDisplayTypeChange(displayType === DisplayTypeEnum.GRID ? DisplayTypeEnum.LIST : DisplayTypeEnum.GRID)
  }

  const _handleFilter = (option: { category: string }): void => {
    const group = aggregations?.find((agg) => agg.type === AggregationTypeEnum.CATEGORY)
    const isMulti = group?.type === AggregationTypeEnum.MULTI_SELECT
    const attributeCode = group?.attributeCode
    const newFilters = { ...filters }
    if (isMulti) {
      if (!newFilters[camelCase(attributeCode)]) {
        newFilters[camelCase(attributeCode)] = {
          in: [option.category],
        }
      } else if (newFilters[camelCase(attributeCode)].in.includes(option.category)) {
        newFilters[camelCase(attributeCode)].in.splice(newFilters[camelCase(attributeCode)].in.indexOf(option.category), 1)
        if (!newFilters[camelCase(attributeCode)].in.length) {
          delete newFilters[camelCase(attributeCode)]
        }
      } else {
        newFilters[camelCase(attributeCode)].in.push(option.category)
      }
    } else {
      if (newFilters?.[camelCase(attributeCode)]?.in?.[0] === option.category) {
        delete newFilters[camelCase(attributeCode)]
      } else {
        newFilters[camelCase(attributeCode)] = {
          in: [option.category],
        }
      }
    }
    onFilterChange(newFilters)
  }

  useEffect(() => {
    if (form.getFieldValue('order') !== `${order.field}-${order.direction}`) {
      form.setFieldValue('order', `${order.field}-${order.direction}`)
    }
  }, [order])

  const orderOptions = availableSortBy
    .map((option) => {
      option = camelCase(option)
      if (orderMap[option]) {
        if (orderMap[option].options.length > 1) {
          return orderMap[option].options.map((mapOption) => {
            let mapOptions = {
              [OrderDirectionEnum.ASC]: 'Low to High',
              [OrderDirectionEnum.DESC]: 'High to Low',
            }
            if (orderMap[option].title === 'Name') {
              mapOptions = {
                [OrderDirectionEnum.ASC]: 'A to Z',
                [OrderDirectionEnum.DESC]: 'Z to A',
              }
            }
            return {
              title: `${orderMap[option].title} ${mapOptions[mapOption]}`,
              value: `${option}-${mapOption}`,
            }
          })
        } else if (orderMap[option].options.length === 0) {
          return []
        } else {
          return [{
            title: orderMap[option].title,
            value: `${option}-${orderMap[option].options[0]}`,
          }]
        }
      }
      return [{
        title: sentenceCase(option)+' High to Low',
        value: `${option}-DESC`,
      }, {
        title: sentenceCase(option) + ' Low to High',
        value: `${option}-ASC`,
      }]
    })
    .flat()

  const categoryOptions = aggregations?.find((agg) => agg.type === AggregationTypeEnum.CATEGORY)?.options?.map((option) => ({
    title: option.label,
    value: option.value,
  }))

  return (
    <>
      <DeviceContainer desktop tablet className='device-container'>
        <DesktopControls>
          <Paragraph variant='p1' className='results-total' color={theme.colors.grey.silver} >{count} Results{term ? ` for "${term}"` : ''}</Paragraph>
          <Seperator align='horizontal' className='seperator' color={theme.colors.grey.gallery}></Seperator>
          <Paragraph variant='p3' className='sort-by' bold={true} color={theme.colors.grey.stormDust} >Sort by:</Paragraph>
          <Form form={form} onValuesChange={_handleDesktopSortFieldCahnge} initialValues={{ order: 'performance-DESC' }} className='form' >
            <SelectInput
              border={false}
              name='order'
              wrapperClassName='form'
              className='order'
              showLabel={false}
              loading={loading}
              options={orderOptions} />
          </Form>
        </DesktopControls>
      </DeviceContainer>
      <DeviceContainer mobile>
        <MobileControls ref={mobileControlsRef} mobileMenuDisplay={appData.app.isNativeApp && navigationData.navigation.mobileMenuDisplayState === MobileMenuDisplayEnum.SHOW}>
          <FilterBar>
            <ViewBy onClick={_handleDisplayTypeChange}>
              <Icon icon={displayType === DisplayTypeEnum.LIST ? LocalIconEnums.GRID : LocalIconEnums.LIST} color={theme.colors.green.greenVogue} />
            </ViewBy>
            <CustomSelectInput options={orderOptions} getValue={_handleMobileSortFieldCahnge} >
              <SortBy >
                <Iconwrapper className="icon">
                  <Icon icon={LocalIconEnums.SORT} color={theme.colors.green.greenVogue} />
                </Iconwrapper>
                <Paragraph bold={true} color={theme.colors.green.greenVogue} variant='p2' >SORT</Paragraph>
              </SortBy>
            </CustomSelectInput>
            <FilterBy onClick={onToggleFilters}>
              <Iconwrapper className="icon">
                <Icon icon={LocalIconEnums.FILTER} color={theme.colors.green.greenVogue} />
              </Iconwrapper>
              <Paragraph bold={true} color={theme.colors.green.greenVogue} variant='p2' >FILTER</Paragraph>
            </FilterBy>
          </FilterBar>
        </MobileControls>
      </DeviceContainer>
    </>
  )

}
