
import React from 'react'
import { Svg } from './Svg'

export interface ShareProps {
  color?: string
  hoverColor?: string
  className?: string
}

export function Share({ color, hoverColor, className }: ShareProps): JSX.Element {

  return (
    <Svg strokeColor={color} strokeHoverColor={hoverColor} fillColor={color} fillHoverColor={hoverColor} className={className} width="100%" height="100%" viewBox="0 0 19 20" version="1.1">
      <g transform="matrix(0.983704,0,0,0.984737,-2.51316,-1.81685)">
        <path className='fill' d="M17.625,15.563C16.957,15.563 16.341,15.797 15.858,16.188L11.002,12.675C11.083,12.229 11.083,11.771 11.002,11.325L15.858,7.812C16.341,8.203 16.957,8.438 17.625,8.438C19.177,8.438 20.438,7.177 20.438,5.625C20.438,4.073 19.177,2.813 17.625,2.813C16.073,2.813 14.813,4.073 14.813,5.625C14.813,5.897 14.85,6.157 14.923,6.405L10.31,9.745C9.626,8.838 8.538,8.25 7.313,8.25C5.241,8.25 3.563,9.928 3.563,12C3.563,14.072 5.241,15.75 7.313,15.75C8.538,15.75 9.626,15.162 10.31,14.255L14.923,17.595C14.85,17.843 14.813,18.106 14.813,18.375C14.813,19.927 16.073,21.188 17.625,21.188C19.177,21.188 20.438,19.927 20.438,18.375C20.438,16.823 19.177,15.563 17.625,15.563ZM17.625,4.406C18.298,4.406 18.844,4.952 18.844,5.625C18.844,6.298 18.298,6.844 17.625,6.844C16.952,6.844 16.406,6.298 16.406,5.625C16.406,4.952 16.952,4.406 17.625,4.406ZM7.313,14.063C6.176,14.063 5.25,13.137 5.25,12C5.25,10.863 6.176,9.938 7.313,9.938C8.449,9.938 9.375,10.863 9.375,12C9.375,13.137 8.449,14.063 7.313,14.063ZM17.625,19.594C16.952,19.594 16.406,19.048 16.406,18.375C16.406,17.702 16.952,17.156 17.625,17.156C18.298,17.156 18.844,17.702 18.844,18.375C18.844,19.048 18.298,19.594 17.625,19.594Z" />
      </g>
    </Svg>
  )

}
    