import React from 'react'

import { useNavigate } from 'react-router'
import styled, { LayoutSizeClass } from 'styled-components'

import { Button } from '@atoms/buttons'
import { LocalIconEnums, ResponsiveImage } from '@atoms/images'
import { layoutColWidthForGrid } from '@atoms/layout'
import { ResponsivePXValue } from '@components/Theme'
import { ProductListFragment } from '@hooks/api/index'

const Container = styled.div<{ size: LayoutSizeClass }>`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: ${(props): string => props.theme.colors.white.pureWhite};
  
  ${ResponsivePXValue('padding', { mobile: '12px', tablet: '12px', desktop: '12px' })}
  ${ResponsivePXValue('height', { mobile: '200px', tablet: '220px', desktop: '220px' })}
  ${ResponsivePXValue('width', { mobile: '157px', tablet: '217px', desktop: '190px' })}

  .button {
    width: 100%;
  }

  .product-card-favourite {
    position: absolute;
    ${ResponsivePXValue('top', '12px')}
    ${ResponsivePXValue('right', '12px')}
  }
`

const CardFooter = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  ${ResponsivePXValue('padding', { mobile: '12px', tablet: '9px', desktop: '12px' })}

`

const ImageContainer = styled.div<{ size: LayoutSizeClass }>`
  align-self: center;
  cursor: pointer;
  
  ${(props): string => ResponsivePXValue('width', {
    mobile: `${props.size.mobile - 24}px`,
    tablet: `${props.size.tablet - 36}px`,
    desktop: `${props.size.desktop - 48}px`,
  })}

  ${ResponsivePXValue('height', { mobile: '146px', tablet: '146px', desktop: '146px' })}
  ${ResponsivePXValue('margin-bottom', { mobile: '0', tablet: '8px', desktop: '8px' })}
  
`

export interface GiftVoucherCardProps {
  product: ProductListFragment
  disabled?: boolean
}

export function GiftVoucherCard({ product }: GiftVoucherCardProps): JSX.Element {

  const navigate = useNavigate()

  const _handleAddToCart = (): void => {
    navigate(`/gift-voucher/${product.urlKey}`)
  }

  const size = layoutColWidthForGrid({ mobile: 5.2, tablet: 3.6, desktop: 2.30 })

  return (
    <Container size={size}>

      <ImageContainer size={size} onClick={_handleAddToCart}>
        <ResponsiveImage image={product.coverImage} objectFit='contain' />
      </ImageContainer>

      <CardFooter>
        <Button title='Select' size='medium' fullWidth={true} icon={LocalIconEnums.CART} onClick={() => _handleAddToCart()} />
      </CardFooter>

    </Container>
  )

}
