import React, { useState, useEffect } from 'react'

import styled from 'styled-components'

import { ResponsiveImage } from '@atoms/images'
import { Heading } from '@atoms/typography'
import { ResponsivePXValue } from '@components/Theme'
import { PageBlockFragment } from '@hooks/api/index'

import { HTML } from './HTML'

const Container = styled.div<{ backgroundColor?: string }>`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: stretch;

  background-color: ${({ backgroundColor, theme }) => backgroundColor || theme.colors.white.pureWhite}; 
`
const TitleContainer = styled.div<{ backgroundColor: string }>`
  width: 100%;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;

  background-color: ${({ backgroundColor }) => backgroundColor}; 
  
  ${ResponsivePXValue('height', { mobile: '44px', tablet: '56px', desktop: '56px' })}
  ${ResponsivePXValue('padding', { mobile: '12px', tablet: '24px', desktop: '24px' })}
  ${ResponsivePXValue('gap', '16px')}
`
const ImageContainer = styled.div`
  flex-shrink: 0;

  ${ResponsivePXValue('display', { mobile: 'none', tablet: 'flex', desktop: 'flex' })}
  ${ResponsivePXValue('width', '26px')}
  ${ResponsivePXValue('height', '24px')}
  ${ResponsivePXValue('margin', '16px')}
`
const ImageTitleContainer = styled.div`
  flex-grow: 0;
  ${ResponsivePXValue('padding', '0 32px')}
  .image-header {
    margin: 0;
  }
`

const DescriptionContainer = styled.div`
  width: 100%;
  flex-grow: 1;
  background-color: ${(props): string => props.theme.colors.white.fantasy};
  
  ${ResponsivePXValue('padding', '16px')}
  ${ResponsivePXValue('gap', '10px')}
`

function PageTitleImageBlock({ block }: PageTitleBlockProps): JSX.Element {
  const { image, foregroundColor, backgroundColor, title } = block

  return (
    <Container backgroundColor={backgroundColor}>
      <ImageContainer>
        <ResponsiveImage image={image} />
      </ImageContainer>
      <ImageTitleContainer>
        <Heading
          variant='h3'
          align='center'
          className='image-header'
          color={foregroundColor}>
          {title}
        </Heading>
      </ImageTitleContainer>
    </Container>
  )
}

function PageTitleTextBlock({ block }: PageTitleBlockProps): JSX.Element {
  const { description, foregroundColor, backgroundColor, title } = block

  return (
    <Container>
      <TitleContainer
        backgroundColor={backgroundColor}>
        <Heading
          variant='h5'
          align='center'
          color={foregroundColor}>
          {title}
        </Heading>
      </TitleContainer>
      <If condition={!!description}>
        <DescriptionContainer
          color={backgroundColor}>
          <HTML align='center'>
            {description}
          </HTML>
        </DescriptionContainer>
      </If>
    </Container>
  )
}

export interface PageTitleBlockProps {
  block: PageBlockFragment
}

export function PageTitleBlock({ block }: PageTitleBlockProps): JSX.Element {

  const { image, title } = block

  return (
    <Choose>
      <When condition={!!image && !!title}>
        <PageTitleImageBlock block={block} />
      </When>
      <Otherwise>
        <PageTitleTextBlock block={block} />
      </Otherwise>
    </Choose>
  )
}
