
import React from 'react'
import { Svg } from './Svg'

export interface CartFilledProps {
  color?: string
  hoverColor?: string
  className?: string
}

export function CartFilled({ color, hoverColor, className }: CartFilledProps): JSX.Element {

  return (
    <Svg strokeColor={color} strokeHoverColor={hoverColor} fillColor={color} fillHoverColor={hoverColor} className={className} width="100%" height="100%" viewBox="0 0 20 19" version="1.1">
      <g transform="matrix(0.85626,0,0,0.85626,0.573522,0.965587)">
        <path className='fill' d="M20.63,13.451L6.673,13.451L7.374,12.023L19.018,12.002C19.411,12.002 19.749,11.721 19.819,11.332L21.432,2.306C21.474,2.07 21.411,1.826 21.256,1.641C21.18,1.55 21.084,1.476 20.977,1.426C20.869,1.375 20.752,1.348 20.633,1.348L5.82,1.298L5.693,0.703C5.614,0.323 5.272,0.047 4.883,0.047L1.261,0.047C1.042,0.047 0.832,0.134 0.676,0.289C0.521,0.444 0.434,0.655 0.434,0.874C0.434,1.094 0.521,1.304 0.676,1.459C0.832,1.614 1.042,1.702 1.261,1.702L4.212,1.702L4.765,4.331L6.127,10.924L4.374,13.786C4.283,13.909 4.228,14.055 4.216,14.207C4.203,14.36 4.234,14.512 4.304,14.648C4.444,14.927 4.728,15.103 5.042,15.103L6.514,15.103C6.2,15.52 6.031,16.028 6.031,16.549C6.031,17.876 7.109,18.954 8.436,18.954C9.762,18.954 10.84,17.876 10.84,16.549C10.84,16.027 10.667,15.518 10.358,15.103L14.133,15.103C13.82,15.52 13.65,16.028 13.65,16.549C13.65,17.876 14.729,18.954 16.055,18.954C17.382,18.954 18.46,17.876 18.46,16.549C18.46,16.027 18.286,15.518 17.977,15.103L20.633,15.103C21.087,15.103 21.46,14.733 21.46,14.276C21.459,14.057 21.371,13.847 21.215,13.692C21.06,13.538 20.849,13.451 20.63,13.451ZM8.436,17.29C8.028,17.29 7.695,16.957 7.695,16.549C7.695,16.141 8.028,15.809 8.436,15.809C8.843,15.809 9.176,16.141 9.176,16.549C9.176,16.746 9.098,16.934 8.959,17.073C8.82,17.212 8.632,17.29 8.436,17.29ZM16.055,17.29C15.647,17.29 15.315,16.957 15.315,16.549C15.315,16.141 15.647,15.809 16.055,15.809C16.463,15.809 16.796,16.141 16.796,16.549C16.796,16.746 16.718,16.934 16.579,17.073C16.44,17.212 16.252,17.29 16.055,17.29Z" />
      </g>
    </Svg>
  )

}
    