
import React from 'react'
import { Svg } from './Svg'

export interface LockClosedOutlineProps {
  color?: string
  hoverColor?: string
  className?: string
}

export function LockClosedOutline({ color, hoverColor, className }: LockClosedOutlineProps): JSX.Element {

  return (
    <Svg strokeColor={color} strokeHoverColor={hoverColor} fillColor={color} fillHoverColor={hoverColor} className={className} width="100%" height="100%" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M5.87868 1.40993C6.44129 0.84732 7.20435 0.53125 8 0.53125C8.79565 0.53125 9.55871 0.84732 10.1213 1.40993C10.6839 1.97254 11 2.7356 11 3.53125V6.5C11 6.77614 10.7761 7 10.5 7C10.2239 7 10 6.77614 10 6.5V3.53125C10 3.00082 9.78929 2.49211 9.41421 2.11704C9.03914 1.74196 8.53043 1.53125 8 1.53125C7.46957 1.53125 6.96086 1.74196 6.58579 2.11704C6.21071 2.49211 6 3.00082 6 3.53125V6.5C6 6.77614 5.77614 7 5.5 7C5.22386 7 5 6.77614 5 6.5V3.53125C5 2.7356 5.31607 1.97254 5.87868 1.40993Z" className='fill' />
      <path fillRule="evenodd" clipRule="evenodd" d="M4.5 7C3.94772 7 3.5 7.44772 3.5 8V13.5C3.5 14.0523 3.94772 14.5 4.5 14.5H11.5C12.0523 14.5 12.5 14.0523 12.5 13.5V8C12.5 7.44772 12.0523 7 11.5 7H4.5ZM2.5 8C2.5 6.89543 3.39543 6 4.5 6H11.5C12.6046 6 13.5 6.89543 13.5 8V13.5C13.5 14.6046 12.6046 15.5 11.5 15.5H4.5C3.39543 15.5 2.5 14.6046 2.5 13.5V8Z" className='fill' />
    </Svg>
  )

}
    