
import React from 'react'
import { Svg } from './Svg'

export interface GoogleFullProps {
  color?: string
  hoverColor?: string
  className?: string
}

export function GoogleFull({ color, hoverColor, className }: GoogleFullProps): JSX.Element {

  return (
    <Svg strokeColor={color} strokeHoverColor={hoverColor} fillColor={color} fillHoverColor={hoverColor} className={className} width="100%" height="100%" viewBox="0 0 20 8" version="1.1">
      <g transform="matrix(1,0,0,1,-426.284,-6.42693)">
        <g transform="matrix(0.499896,0,0,0.50059,9.09577,7.19994)">
          <path className='fill' d="M851.62,6.154C851.62,7.82 850.316,9.047 848.717,9.047C847.118,9.047 845.815,7.82 845.815,6.154C845.815,4.477 847.118,3.261 848.717,3.261C850.316,3.261 851.62,4.477 851.62,6.154ZM850.349,6.154C850.349,5.113 849.594,4.401 848.717,4.401C847.841,4.401 847.086,5.113 847.086,6.154C847.086,7.184 847.841,7.907 848.717,7.907C849.594,7.907 850.349,7.183 850.349,6.154Z" />
        </g>
        <g transform="matrix(0.499896,0,0,0.50059,9.09577,7.19994)">
          <path className='fill' d="M857.88,6.154C857.88,7.82 856.577,9.047 854.978,9.047C853.379,9.047 852.076,7.82 852.076,6.154C852.076,4.478 853.379,3.261 854.978,3.261C856.577,3.261 857.88,4.477 857.88,6.154ZM856.61,6.154C856.61,5.113 855.855,4.401 854.978,4.401C854.102,4.401 853.346,5.113 853.346,6.154C853.346,7.184 854.102,7.907 854.978,7.907C855.855,7.907 856.61,7.183 856.61,6.154Z" />
        </g>
        <g transform="matrix(0.499896,0,0,0.50059,9.09577,7.19994)">
          <path className='fill' d="M863.88,3.436L863.88,8.63C863.88,10.766 862.62,11.639 861.131,11.639C859.729,11.639 858.885,10.701 858.567,9.934L859.673,9.473C859.87,9.944 860.352,10.5 861.13,10.5C862.083,10.5 862.674,9.912 862.674,8.804L862.674,8.388L862.63,8.388C862.345,8.739 861.797,9.046 861.106,9.046C859.66,9.046 858.334,7.786 858.334,6.164C858.334,4.531 859.66,3.261 861.106,3.261C861.796,3.261 862.344,3.567 862.63,3.908L862.674,3.908L862.674,3.437L863.88,3.437L863.88,3.436ZM862.764,6.164C862.764,5.146 862.084,4.401 861.22,4.401C860.343,4.401 859.609,5.146 859.609,6.164C859.609,7.173 860.343,7.907 861.22,7.907C862.084,7.907 862.764,7.173 862.764,6.164Z" />
        </g>
        <g transform="matrix(0.499896,0,0,0.50059,9.09577,7.19994)">
          <rect x="864.63" y="0.391" width="100%" height="100%" />
        </g>
        <g transform="matrix(0.499896,0,0,0.50059,9.09577,7.19994)">
          <path className='fill' d="M870.698,7.106L871.684,7.763C871.366,8.234 870.599,9.046 869.274,9.046C867.63,9.046 866.403,7.775 866.403,6.153C866.403,4.432 867.641,3.26 869.132,3.26C870.633,3.26 871.367,4.454 871.607,5.1L871.739,5.429L867.872,7.03C868.168,7.611 868.628,7.907 869.274,7.907C869.921,7.907 870.37,7.589 870.698,7.106ZM867.663,6.065L870.248,4.992C870.106,4.63 869.678,4.379 869.175,4.379C868.529,4.379 867.63,4.949 867.663,6.065Z" />
        </g>
        <g transform="matrix(0.499896,0,0,0.50059,9.08198,7.36995)">
          <path className='fill' d="M841.125,5.401L841.125,4.174L845.261,4.174C845.301,4.388 845.322,4.641 845.322,4.915C845.322,5.836 845.07,6.974 844.259,7.786C843.47,8.607 842.462,9.046 841.126,9.046C838.65,9.046 836.569,7.029 836.569,4.553C836.569,2.078 838.65,0.061 841.126,0.061C842.496,0.061 843.471,0.599 844.204,1.299L843.338,2.165C842.813,1.672 842.1,1.289 841.125,1.289C839.317,1.289 837.903,2.746 837.903,4.553C837.903,6.361 839.317,7.818 841.125,7.818C842.297,7.818 842.965,7.347 843.393,6.92C843.74,6.573 843.968,6.077 844.058,5.4L841.125,5.401Z" />
        </g>
      </g>
    </Svg>
  )

}
    