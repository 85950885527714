import React, { useState, useEffect } from 'react'

import ReactDOM from 'react-dom'
import styled, { useTheme } from 'styled-components'
import DrawerComponent from 'styled-react-modal'

import { APP_DEFAULT_STATE } from '@api/local/AppPlugin'
import { usePortal } from '@client/hooks/UsePortal'
import { ResponsivePXValue, StrongBoxShadow } from '@components/Theme'
import { useGetAppQuery } from '@hooks/api/index'
import { DeviceTypeEnum } from '@uctypes/api/globalTypes'

const TRANSITION_DURATION = 500

const StyledDrawer = DrawerComponent.styled`
  display: flex;
  align-items: center;
  justify-content: center;
`

const DrawerContainer = styled.div<{ opacity: number }>`
  position: absolute;
  right: 0;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
  opacity: ${(props): number => props.opacity};
  transform: translateX(${(props): string => props.opacity ? '0' : '100%'});
  transition: all ${TRANSITION_DURATION}ms ease;

  ${ResponsivePXValue('min-width', { mobile: '100vw' })}

  ${StrongBoxShadow}

`

export interface DrawerProps {
  open: boolean
  onClose?: (e?: React.MouseEvent<HTMLAnchorElement | HTMLDivElement> | Event) => void
  children: string | JSX.Element | JSX.Element[]
  allowClose?: boolean
}

interface DrawerState {
  isOpen: boolean
  opacity: number
  isClosing: boolean
  isOpening: boolean
  closeHovering: boolean
}

const DEFAULT_STATE: DrawerState = {
  isOpen: false,
  opacity: 0,
  isClosing: false,
  isOpening: false,
  closeHovering: false,
}

export function Drawer({ open, onClose, children, allowClose = true }: DrawerProps): JSX.Element {

  const [state, setState] = useState<DrawerState>({ ...DEFAULT_STATE })
  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()
  const theme = useTheme()

  const _handleClose = (): void => {
    if (open && allowClose && onClose) {
      onClose()
    }
  }

  const modalAfterOpen = (): void => {
    if (state.isOpening) {
      setTimeout(() => {
        setState((prevState) => ({ ...prevState, opacity: 1, isOpening: false }))
      })
    }
  }

  const modalBeforeClose = (): Promise<void> => {
    if (state.isClosing) {
      return new Promise<void>((resolve): void => {
        setState((prevState) => ({ ...prevState, opacity: 0, isClosing: false }))
        setTimeout(resolve, TRANSITION_DURATION)
      })
    } else {
      return new Promise<void>((resolve): void => {
        setTimeout(resolve, TRANSITION_DURATION)
      })
    }
  }

  useEffect(() => {
    if (open && !state.isOpen) {
      setState((prevState) => ({ ...prevState, isOpen: true, isOpening: true }))
    } else if (!open && state.isOpen) {
      setState((prevState) => ({ ...prevState, isOpen: false, isClosing: true }))
    }
  }, [open])

  const style = appData.app.deviceType === DeviceTypeEnum.DESKTOP || appData.app.deviceType === DeviceTypeEnum.ULTRA || appData.app.deviceType === DeviceTypeEnum.TABLET
    ? { opacity: state.opacity, transition: '0.5s all ease-in-out' }
    : { background: theme.colors.misc.transparent }

  const modal = (
    <StyledDrawer
      isOpen={state.isOpen}
      backgroundProps={{ style }}
      // beforeOpen={modalBeforeOpen}
      afterOpen={modalAfterOpen}
      beforeClose={modalBeforeClose}
      // afterClose={modalAfterClose}
      onBackgroundClick={_handleClose}
      allowScroll={false}
      onEscapeKeydown={_handleClose}>
      <DrawerContainer opacity={state.opacity}>
        {children}
      </DrawerContainer>
    </StyledDrawer>
  )

  const target = usePortal('root-modal')
  if (target) {
    return ReactDOM.createPortal(modal, target)
  }
  return modal

}
