
import React from 'react'

import loadable from '@loadable/component'

import(/* webpackPrefetch: true */ '@pages/CarbonNeutral')

const CarbonNeutral = loadable(() => import(/* webpackChunkName: "CarbonNeutral" */'@pages/CarbonNeutral'), {
  resolveComponent: (components) => components.CarbonNeutral,
})

export function CarbonNeutralAsync(): JSX.Element {

  return <CarbonNeutral fallback={<div>Loading...</div>} />

}
