
import React from 'react'
import { Svg } from './Svg'

export interface GreyVisaProps {
  color?: string
  hoverColor?: string
  className?: string
}

export function GreyVisa({ color, hoverColor, className }: GreyVisaProps): JSX.Element {

  return (
    <Svg strokeColor={color} strokeHoverColor={hoverColor} fillColor={color} fillHoverColor={hoverColor} className={className} width="100%" height="100%" viewBox="0 0 25 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.857 8.00003H8.76172L10.0951 0.19051H11.9998L10.857 8.00003Z" fill="#EAEAEA"/>
      <path d="M18.0949 0.38095C17.7139 0.190474 17.1425 0 16.3806 0C14.4758 0 12.952 1.14286 12.952 2.47619C12.952 3.61905 13.9044 4.19048 14.6663 4.57143C15.4282 4.95238 15.6187 5.14286 15.6187 5.52381C15.6187 6.09524 15.0473 6.28571 14.4758 6.28571C13.7139 6.28571 13.333 6.09524 12.5711 5.90476L12.3806 5.71429L12.1901 7.42857C12.5711 7.61905 13.5235 7.80952 14.4758 7.80952C16.5711 7.80952 17.9044 6.85714 17.9044 5.14286C17.9044 4.19047 17.333 3.61904 16.1901 3.04762C15.4282 2.66666 15.0473 2.47619 15.0473 2.09524C15.0473 1.71428 15.4282 1.33333 16.1901 1.33333C16.7616 1.33333 17.333 1.52381 17.7139 1.71429H17.9044L18.0949 0.38095Z" fill="#EAEAEA"/>
      <path d="M20.7622 5.14289C20.9527 4.76194 21.5241 3.04765 21.5241 3.04765C21.5241 3.04765 21.7146 2.6667 21.7146 2.28575L21.9051 3.04765C21.9051 3.04765 22.286 4.95242 22.286 5.33337C22.0955 5.14289 21.1432 5.14289 20.7622 5.14289ZM23.2384 0.19051H21.7146C21.1432 0.19051 20.9527 0.380988 20.5717 0.761941L17.5241 7.80956H19.6193C19.6193 7.80956 20.0003 6.85718 20.0003 6.6667C20.1908 6.6667 22.286 6.6667 22.667 6.6667C22.667 6.85718 22.8574 7.80956 22.8574 7.80956H24.7622L23.2384 0.19051Z" fill="#EAEAEA"/>
      <path d="M7.04741 0.19051L5.14265 5.52385L4.95217 4.38099C4.57122 3.23813 3.42837 1.71432 2.09503 1.14289L3.80932 8.00003H5.90456L9.14265 0.19051H7.04741V0.19051Z" fill="#EAEAEA"/>
      <path d="M3.2381 0.19051H0V0.380987C2.47619 0.952415 4.19048 2.6667 4.95238 4.38099L4.19048 0.761941C4 0.380988 3.80953 0.19051 3.2381 0.19051Z" fill="#EAEAEA"/>
    </Svg>
    

  )

}
