import React, { useState, useEffect } from 'react'

import useEmblaCarousel from 'embla-carousel-react'
import update from 'react-addons-update'
import styled from 'styled-components'

import { ResponsiveImage } from '@atoms/images'
import { ResponsivePXValue } from '@components/Theme'
import { ProductDetailsImageFragment } from '@hooks/api/index'
import { ProductImageGalleryModal } from '@molecules/modals/ProductImageGalleryModal'

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  ${ResponsivePXValue('width', '376px')}
`

const ImageContainer = styled.div`
  width: 100%;
  ${ResponsivePXValue('height', '376px')}
`

const SliderContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  ${ResponsivePXValue('margin-top', '20px')}
  ${ResponsivePXValue('height', '60px')}
`

const Embla = styled.div`
  position: relative;
  overflow: hidden;
`

const EmblaContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  ${ResponsivePXValue('gap', '8px')}
`

const EmblaSlide = styled.div<{ selected: boolean }>`
  position: relative;
  user-select: none;
  ${ResponsivePXValue('flex', '0 0 42px')}
  ${ResponsivePXValue('height', '42px')}

`

const LeftControl = styled.div<{ visible: boolean }>`
  user-select: none;
  opacity: ${(props): number => props.visible ? 1 : 0};
  ${ResponsivePXValue('width', '30px')}
  ${ResponsivePXValue('height', '42px')}
  ${ResponsivePXValue('top', '35px')}
  ${ResponsivePXValue('left', '0')}
`

const RightControl = styled.div<{ visible: boolean }>`
  user-select: none;
  opacity: ${(props): number => props.visible ? 1 : 0};
  ${ResponsivePXValue('width', '30px')}
  ${ResponsivePXValue('height', '60px')}
  ${ResponsivePXValue('top', '35px')}
  ${ResponsivePXValue('right', '0')}
`

export interface ProductDesktopImagesProps {
  gallery: ProductDetailsImageFragment[]
}

interface ProductDesktopImagesState {
  selectedIndex: number
  scrollOffset: number
  modalOpen: boolean
}

const DEFAULT_STATE: ProductDesktopImagesState = {
  selectedIndex: 0,
  scrollOffset: 0,
  modalOpen: false,
}

export function ProductDesktopImages({ gallery }: ProductDesktopImagesProps): JSX.Element {

  const [state, setState] = useState<ProductDesktopImagesState>({ ...DEFAULT_STATE })
  const [emblaRef, emblaApi] = useEmblaCarousel({
    draggable: false,
    align: 'start',
  })

  const images: ProductDetailsImageFragment[] = gallery
  const selectedImage = images?.[state.selectedIndex]
  // const showLeftControl = state.scrollOffset > 0
  // const showRightControl = state.scrollOffset + 4 < images.length

  const _handleSelectThumbNail = (index: number): void => {
    setState((prevState) => update(prevState, {
      selectedIndex: { $set: index },
    }))
  }

  const _handleModalClose = (): void => {
    setState((prevState) => update(prevState, {
      modalOpen: { $set: false },
    }))
  }

  const _handleModalOpen = (): void => {
    setState((prevState) => update(prevState, {
      modalOpen: { $set: true },
    }))
  }

  useEffect(() => {
    if (emblaApi) {
      emblaApi.scrollTo(state.scrollOffset)
    }
  }, [state.scrollOffset])

  let image: ProductDetailsImageFragment
  let imageIndex: number

  return (
    <>
      <ProductImageGalleryModal
        index={state.selectedIndex}
        onIndexChange={_handleSelectThumbNail}
        images={images}
        open={state.modalOpen}
        onClose={_handleModalClose} />
      <Container>
        <ImageContainer onClick={_handleModalOpen}>
          <Choose>
            <When condition={!!selectedImage}>
              <ResponsiveImage image={selectedImage} objectFit='contain' />
            </When>
            <Otherwise>
              No Preview
            </Otherwise>
          </Choose>
        </ImageContainer>
        <SliderContainer>
          <Embla>
            <EmblaContainer ref={emblaRef}>
              <For each='image' index='imageIndex' of={images}>
                <EmblaSlide
                  selected={imageIndex === 0 /* state.selectedIndex */}
                  key={`${image.id}-${imageIndex}`}
                  onClick={() => _handleSelectThumbNail(imageIndex)}>
                  <ResponsiveImage image={image} objectFit='contain' />
                </EmblaSlide>
              </For>
            </EmblaContainer>
          </Embla>
        </SliderContainer>
      </Container>
    </>
  )

}
