import React, { useEffect, useState } from 'react'

import { useNavigate } from 'react-router'
import styled, { useTheme } from 'styled-components'

import { APP_DEFAULT_STATE } from '@api/local/AppPlugin'
import { NAVIGATION_DEFAULT_STATE, NavigationPlugin } from '@api/local/NavigationPlugin'
import { Button, Icon, LocalIconEnums } from '@atoms/index'
import { ResponsivePXValue } from '@components/Theme'
import { useConfig } from '@contexts/ConfigProvider'
import { useGetAppQuery, useGetNavigationQuery } from '@hooks/api/index'
import { MobileNavigationMenu, MobileSearch } from '@molecules/index'
import { MobileMenuDisplayEnum } from '@uctypes/api/globalTypes'

const Container = styled.div<{isApp: boolean}>`
  position: relative;
  width: 100%;
  z-index: 30;
  display: block;
  ${(props) => () => {
    if (props.isApp) {
      return ResponsivePXValue('height', { mobile: '56px', tablet: '56px' })
    }
  }}
`

const NavigationBarContainer = styled.div<{isApp: boolean, show: boolean}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: ${(props): string => (props.isApp) ? props.theme.colors.green.watercourse : props.theme.colors.white.pureWhite};

  ${(props) => () => {
    if (props.isApp) {
      if (props.show) {
        return `
          position: fixed;
          top: 0;
          transition: top 0.2s;
        `
      } else {
        return `
          position: fixed;
          ${ResponsivePXValue('top', '-56px')}  
          transition: top 0.2s;
        `
      }
    } else {
      return `
        position: relative;
      `
    }
  }}

  ${ResponsivePXValue('height', { mobile: '56px', tablet: '56px' })}
  ${ResponsivePXValue('padding', '0 10px')}

  ${(props) => () => {
    if (!props.isApp) {
      return ResponsivePXValue('border-bottom', `2px solid ${props.theme.colors.grey.athens}`)
    }
  }}
  
  .menuIcons {
    ${ResponsivePXValue('width', '50px')}
    ${ResponsivePXValue('height', '50px')}
  }
  .menuLogo {
    ${ResponsivePXValue('width', '50px')}
    ${ResponsivePXValue('height', '50px')}
  }
`

const LogoContainer = styled.div`
  ${ResponsivePXValue('width', '35px')}
  ${ResponsivePXValue('height', '35px')}
`

const Spacer = styled.div`
  flex-grow: 1;
  ${ResponsivePXValue('height', { mobile: '56px', tablet: '56px' })}
`

export function MobileNavigationBar({ notificationHeight }: { notificationHeight: number }): JSX.Element {
  const config = useConfig()
  const { data: navigationData = { navigation: { ...NAVIGATION_DEFAULT_STATE } } } = useGetNavigationQuery()
  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()
  const navigate = useNavigate()
  const theme = useTheme()

  const [scrolling, setScrolling] = useState(false)
  const [scrollTop, setScrollTop] = useState(0)
  const isNativeApp = appData.app.isNativeApp

  useEffect(() => {
    if (typeof window !== 'undefined' && isNativeApp) {
      const onScroll = (e: any) => {
        setScrollTop(e.target.documentElement.scrollTop)
        setScrolling(e.target.documentElement.scrollTop > scrollTop && e.target.documentElement.scrollTop > 0)
      }
      window.addEventListener('scroll', onScroll)
      return () => window.removeEventListener('scroll', onScroll)
    }
  }, [scrollTop])

  useEffect(() => {
    NavigationPlugin.shared().toggleMobileMenuDisplay()
  }, [scrolling])

  const _handleToggleMenu = () => {
    NavigationPlugin.shared().toggleNavigation()
  }

  const _handleItemClicked = async (path: string): Promise<void> => {
    const client = await config.getClient()
    NavigationPlugin.shared().closeNavigation()
    if (navigationData.navigation.isTest) {
      NavigationPlugin.shared().setPath(client, path)
    } else {
      navigate(path)
    }
  }

  const _handleToggleSearch = (): void => {
    NavigationPlugin.shared().toggleSearch()
  }

  return (
    <Container isApp={isNativeApp}>
      <NavigationBarContainer isApp={isNativeApp} show={(isNativeApp && navigationData.navigation.mobileMenuDisplayState === MobileMenuDisplayEnum.SHOW)} className='gonative-mobile-top-menu'>
        <Button
          icon={LocalIconEnums.HAMBURGER_MENU}
          iconColor={(isNativeApp) ? theme.colors.white.pureWhite : ''}
          className='menuIcons'
          variant='nav'
          shape='square'
          onClick={_handleToggleMenu} />
        <Spacer />
        <LogoContainer onClick={() => _handleItemClicked('/')}>
          <Icon icon={LocalIconEnums.MOBILE_LOGO} color={(isNativeApp) ? theme.colors.white.pureWhite : theme.colors.green.watercourse} />
        </LogoContainer>
        <Spacer />
        <Button
          icon={LocalIconEnums.SEARCH_OUTLINE}
          iconColor={(isNativeApp) ? theme.colors.white.pureWhite : ''}
          variant='nav'
          shape='square'
          className='menuIcons'
          onClick={_handleToggleSearch} />
      </NavigationBarContainer>
      <MobileNavigationMenu onSelect={_handleItemClicked} />
      <MobileSearch onSelect={_handleItemClicked} notificationHeight={notificationHeight} />
    </Container>
  )

}
