import React from 'react'

import { useNavigate } from 'react-router'
import styled from 'styled-components'

import { Link, Loader, Paragraph, ResponsiveImage } from '@atoms/index'
import { ResponsivePXValue } from '@components/Theme'
import { ProductListImageFragment, useGetOrderProductQuery } from '@hooks/api'

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  background-color: ${({ theme }): string => theme.colors.white.pureWhite};
  ${ResponsivePXValue('padding', { mobile: '', tablet: '12px 0px', desktop: '12px 0px' })}

`

const ImageContainer = styled.div`
  ${ResponsivePXValue('width', { mobile: '88px', tablet: '94px', desktop: '94px' })}
  ${ResponsivePXValue('height', { mobile: '88px', tablet: '94px', desktop: '94px' })}
  ${ResponsivePXValue('margin', '0px 12px')}
  background-color: ${({ theme }): string => theme.colors.white.pureWhite};
  flex-shrink: 0;

`

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${ResponsivePXValue('gap', '12px')}
  overflow-wrap: break-word;
  flex-grow: 1;
  flex-wrap: wrap;
  width: 100%;
  ${ResponsivePXValue('padding', { mobile: '0px 6px' })}
`

const NotAvailable = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  flex-direction: column;
  ${ResponsivePXValue('padding', '8px')}
  ${ResponsivePXValue('gap', '8px')}
`

export interface OrderItemPanelProps {
  name: string
  quantity: number
  slug: string
  sku: string
  coverImage: ProductListImageFragment
}

export function OrderItemPanel({ name, quantity, slug, sku, coverImage }: OrderItemPanelProps): JSX.Element {
  const navigate = useNavigate()

  const _handleLink = (href: string): void => {
    navigate(`/${href}`)
  }

  return (
    <Container>
      <ImageContainer>
        <ResponsiveImage image={coverImage} objectFit='contain' />
      </ImageContainer>
      <InfoContainer>
        <Paragraph variant='p1' bold={true} onClick={() => _handleLink(slug)}>
          {name}
        </Paragraph>
        <Paragraph variant='p1'>
          Qty: {quantity}
        </Paragraph>
      </InfoContainer>
    </Container>
  )

}
