import React, { useEffect, useState } from 'react'

import { sentenceCase } from 'change-case'
import deepmerge from 'deepmerge'
import qs from 'qs'
import update from 'react-addons-update'
import { useNavigate, useLocation, useParams } from 'react-router'
import styled from 'styled-components'

import { AppPlugin } from '@api/local/AppPlugin'
import { NavigationPlugin } from '@api/local/NavigationPlugin'
import { LayoutCol, LayoutGrid, LayoutRow } from '@atoms/layout/LayoutGrid'
import { ResponsivePXValue } from '@components/Theme'
import { BreadcrumbFragment, useGetCategoryQuery, PageSectionGroupFragment, AggregationFragment, ProductListFragment, useGetAllAggregationsQuery, useGetAllProductsQuery } from '@hooks/api/index'
import { useLoadingData } from '@hooks/UseLoadingData'
import { BreadCrumb, BreadCrumbs } from '@molecules/navigation/BreadCrumbs'
import { PageSectionGroup } from '@organisms/content/PageSectionGroup'
import { CatalogueHeader } from '@organisms/navigation/CatalogueHeader'
import { CategoryHeader } from '@organisms/shop'
import { ProductFilters, ProductFilterInputs } from '@organisms/shop/ProductFilters'
import { DisplayTypeEnum, ProductGrid, OrderValue } from '@organisms/shop/ProductGrid'
import { OrderDirectionEnum } from '@uctypes/api/globalTypes'
import { Mutable } from '@uctypes/global'

const Container = styled.div`
  .content-row {
    ${ResponsivePXValue('gap', { mobile: '0' })}
  }
`

const ProductGridContainer = styled.div`
  ${ResponsivePXValue('margin', '0 0 34px 0')}
`

const ProductFilterContainer = styled.div`
  ${ResponsivePXValue('margin', { tablet: '0 16px 16px 0', desktop: '0 16px 16px 0' })}
`

const SectionContainer = styled.div`
  ${ResponsivePXValue('margin-bottom', { mobile: '16px', tablet: '32px', desktop: '32px' })}
`

interface CategoryState {
  fetchingMore: boolean
  mobileFiltersOpen: boolean
  filters: ProductFilterInputs
  skip: number
  limit: number
  order: OrderValue
  displayType: DisplayTypeEnum
}

const DEFAULT_STATE: CategoryState = {
  fetchingMore: false,
  mobileFiltersOpen: false,
  filters: {},
  skip: 0,
  limit: 16,
  order: { field: 'name', direction: OrderDirectionEnum.ASC },
  displayType: DisplayTypeEnum.GRID,
}

export function Ingredients(): JSX.Element {

  const { slug } = useParams<{ slug: string }>()
  const { data: categoryData, loading: categoryLoading } = useGetCategoryQuery({ variables: { slug } })
  const navigate = useNavigate()
  const location = useLocation()

  const category = categoryData?.categories?.items?.[0]

  const [state, setState] = useState<CategoryState>({ ...DEFAULT_STATE })
  const query = qs.parse(location.search.replace('?', ''))
  const queryFilters = query?.filters as { [k: string]: any } || {}
  const filters = { ...queryFilters }
  const where = { categoryUid: { eq: category?.uid } }
  const combinedFilters = deepmerge(filters || {}, where || {})
  const { data: aggregationData, loading: aggreationLoading } = useGetAllAggregationsQuery({
    skip: !category?.uid,
    variables: {
      filters: combinedFilters,
    },
  })

  const { data: productData, loading: productLoading, fetchMore } = useGetAllProductsQuery({
    skip: !category?.uid,
    variables: {
      filters: combinedFilters,
      skip: state.skip,
      limit: state.limit,
      order: state.order ? { [state.order.field]: state.order.direction } : null,
    },
  })

  const aggregations = useLoadingData<Readonly<AggregationFragment[]>>({
    data: aggregationData?.allProducts?.aggregations,
    loading: aggreationLoading,
    defaultData: [],
  })

  const products = useLoadingData<Readonly<ProductListFragment[]>>({
    data: productData?.allProducts?.items,
    loading: productLoading,
    defaultData: [],
  })
  const count = useLoadingData<number>({
    data: productData?.allProducts?.totalCount,
    loading: productLoading,
    defaultData: 0,
  })

  const performFetcMore = async (): Promise<void> => {
    setState((prevState) => update(prevState, {
      fetchingMore: { $set: true },
    }))
    await fetchMore({
      variables: {
        skip: state.skip,
      },
    })
    setState((prevState) => update(prevState, {
      fetchingMore: { $set: false },
    }))
  }

  const _handleDisplayTypeChange = (displayType: DisplayTypeEnum): void => {
    setState((prevState) => update(prevState, {
      displayType: { $set: displayType },
    }))
  }

  const _handleOrderChange = (order: OrderValue): void => {
    setState((prevState) => update(prevState, {
      order: { $set: order },
    }))
  }

  const _handlePaginationChange = (skip: number, limit: number): void => {
    setState((prevState) => update(prevState, {
      skip: { $set: skip },
      limit: { $set: limit },
    }))
  }

  const _handleFiltersChange = (filters: ProductFilterInputs): void => {
    const newFilters = { ...filters } as Mutable<ProductFilterInputs>
    delete newFilters.name
    let subCategoryId: string | null = null
    if (newFilters?.categoryUid?.in?.[0]) {
      subCategoryId = newFilters.categoryUid.in[0]
      delete newFilters.categoryUid
    }
    setState((prevState) => update(prevState, {
      skip: { $set: 0 },
    }))
    const newQueryString = qs.stringify({ filters: newFilters })
    if (subCategoryId) {
      const canonicalUrl = category?.children?.find((c) => c.uid === subCategoryId)?.canonicalUrl
      navigate(`/${canonicalUrl}?${newQueryString}`)
    } else {
      navigate(`${location.pathname}?${newQueryString}`)
    }

  }

  const _handleToggleFilter = () => {
    NavigationPlugin.shared().closeNavigation()
    setState((prevState) => update(prevState, {
      mobileFiltersOpen: { $set: !state.mobileFiltersOpen },
    }))
  }

  useEffect(() => {
    performFetcMore()
  }, [state.skip])

  let breadCrumb!: BreadcrumbFragment
  let section!: PageSectionGroupFragment
  const hasContent = !!category?.pageContent
  const loading = categoryLoading || aggreationLoading || productLoading || state.fetchingMore

  return (
    <Container>
      <LayoutGrid>
        <LayoutRow>
          <LayoutCol span={{ mobile: 10, tablet: 10, desktop: 12 }}>
            <BreadCrumbs>
              <BreadCrumb title='Home' href='/' />
              <For each='breadCrumb' of={category?.breadcrumbs || []}>
                <BreadCrumb title={breadCrumb.categoryName} key={breadCrumb.categoryUrlKey} href={breadCrumb.categoryUrlKey} />
              </For>
              <BreadCrumb title={category ? category.name : sentenceCase(slug)} href={`/${slug}`} />
            </BreadCrumbs>
          </LayoutCol>
        </LayoutRow>
        <If condition={hasContent}>
          <LayoutRow>
            <LayoutCol span={{ mobile: 10, tablet: 10, desktop: 12 }}>
              <SectionContainer>
                <For each='section' of={category.pageContent.content.sectionGroups}>
                  <PageSectionGroup pageSectionGroup={section} key={section.id} />
                </For>
              </SectionContainer>
            </LayoutCol>
          </LayoutRow>
        </If>
        <LayoutRow className='content-row'>
          <LayoutCol span={{ mobile: 10, tablet: 3, desktop: 3 }}>
            <ProductFilterContainer>
              <ProductFilters
                productCount={count}
                open={state.mobileFiltersOpen}
                aggregations={aggregations}
                loading={loading}
                filters={filters}
                onFilterChange={_handleFiltersChange}
                onToggleFilters={_handleToggleFilter} />
            </ProductFilterContainer>
          </LayoutCol>
          <LayoutCol span={{ mobile: 10, tablet: 7, desktop: 9 }}>
            <ProductGridContainer>
              <If condition={!hasContent}>
                <CategoryHeader category={category} />
              </If>
              <CatalogueHeader
                aggregations={aggregations}
                filters={filters}
                count={count}
                loading={loading}
                displayType={state.displayType}
                order={state.order}
                onDisplayTypeChange={_handleDisplayTypeChange}
                onOrderChange={_handleOrderChange}
                onToggleFilters={_handleToggleFilter}
                onFilterChange={_handleFiltersChange} />
              <ProductGrid
                products={products}
                count={count}
                loading={loading}
                skip={state.skip}
                limit={state.limit}
                displayType={state.displayType}
                onPaginationChange={_handlePaginationChange}
                onFilterChange={_handleFiltersChange} />
            </ProductGridContainer>
          </LayoutCol>
        </LayoutRow>
      </LayoutGrid>
    </Container>
  )

}
