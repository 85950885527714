import React, { ChangeEvent, HTMLInputAutoCompleteAttribute, useEffect, useRef, useState } from 'react'

import valid from 'card-validator'
import parsePhoneNumberFromString from 'libphonenumber-js'
import { Field, FormInstance } from 'rc-field-form'
import { Meta, Rule, RuleObject, RuleType, StoreValue } from 'rc-field-form/es/interface.d'
import update from 'react-addons-update'
import styled, { css, CSS, useTheme } from 'styled-components'

import { Icon, LocalIconEnums, SmallLoader } from '@atoms/index'
import { LiteBoxShadow, ResponsivePXValue, theme } from '@components/Theme'
import { SiteHelper } from '@lib/SiteHelper'
import { InputWrapper, FormContext, FormContextProperties } from '@molecules/inputs/index'

const Container = styled.div`
  position: relative;

  .icon-btn {
    ${ResponsivePXValue('height', { mobile: '13px', tablet: '36px', desktop: '36px' })}
    ${ResponsivePXValue('width', { mobile: '13px', tablet: '36px', desktop: '36px' })}
    position: absolute;
    top: 2px;
    right: 0;
    padding-right: 10px;
  }
`

const TextInputElement = styled.input<{ error: boolean, disabled: boolean, inputBgColor: string }>`
  font-family: open-sans;
  border-style: solid;
  color: ${(props): string => props.error ? props.theme.colors.red.cinnabar : props.theme.colors.green.bottleGreen};
  border-color: ${(props): string => props.error ? props.theme.colors.red.cinnabar : props.theme.colors.grey.silver};
  background-color: ${(props): string => props.inputBgColor ? props.inputBgColor : props.theme.colors.white.pureWhite};
  width: 100%;
  box-shadow: none;

  ${ResponsivePXValue('font-weight', { mobile: '100', tablet: '400', desktop: '400' })};
  ${ResponsivePXValue('font-size', { mobile: '12px', tablet: '12px', desktop: '12px' })};
  ${ResponsivePXValue('height', { mobile: '33px', tablet: '36px', desktop: '36px' })};
  ${ResponsivePXValue('padding', { mobile: '0 5px', tablet: '0 5px', desktop: '8px 12px' })};
  ${ResponsivePXValue('border-width', '1px')}

  &:focus {
    outline: none;
    box-shadow: none;
    border-color: ${(props): string => props.error ? props.theme.colors.red.cinnabar : props.theme.colors.grey.silver};
    ${LiteBoxShadow}
  }

  ::placeholder {
    color: ${(props): string => props.theme.colors.grey.silver};
    opacity: 1;
    ${ResponsivePXValue('font-size', { mobile: '10px' })}
  }

  ${(props): CSS => {
    if (props.disabled) {
      return css`
        border-color: ${(props): string => props.theme.colors.grey.silver};
        color: ${(props): string => props.theme.colors.grey.silver};
        background-color: ${(props): string => props.theme.colors.grey.athens};
        ::placeholder {
          color: ${(props): string => props.theme.colors.grey.silver};
          opacity: 1;
        }
      `
    }
  }}
`

const LoadingContainer = styled.div`
  position: absolute;
  ${ResponsivePXValue('right', '12px')}
  ${ResponsivePXValue('top', '12px')}
  ${ResponsivePXValue('width', '20px')}
  ${ResponsivePXValue('height', '20px')}
`

const InputIconContainer = styled.div`
  position: absolute;
  ${ResponsivePXValue('right', '8px')}
  ${ResponsivePXValue('top', '6px')}
  ${ResponsivePXValue('width', '20px')}
  ${ResponsivePXValue('height', '20px')}

  ${theme.desktop} {
    ${ResponsivePXValue('top', '8px')}
  }
`

interface Values {
  value?: string | number
  onChange?: (value: string | number) => void
}

export interface TextFormInputProps {
  rules?: Rule[]
  label?: string
  showLabel?: boolean
  name: string
  loading?: boolean
  inputBgColor?: string
  disabled?: boolean
  placeholder?: string
  variant?: 'text' | 'email' | 'phone' | 'password' | 'integer' | 'float' | 'creditCard' | 'expiryDate'
  className?: string
  wrapperClassName?: string
  autoComplete?: HTMLInputAutoCompleteAttribute
  errorMessage?: string
  readOnly?: boolean
  onFocus?: () => void
  onBlur?: () => void
}

interface TextInputState {
  type: string
  view: boolean
}

const DEFAULT_STATE: TextInputState = {
  type: 'text',
  view: true,
}

export function TextInput(props: TextFormInputProps): JSX.Element {

  let { placeholder = '', label, rules, showLabel, name, variant = 'text', loading, inputBgColor, disabled, className, wrapperClassName, autoComplete = true, errorMessage = '', readOnly = false, onFocus, onBlur } = props
  const theme = useTheme()
  const [state, setState] = useState<TextInputState>({ ...DEFAULT_STATE })
  const inputRef: React.RefObject<HTMLInputElement> = useRef()
  const formContext = React.useContext(FormContext)

  const changeView = () => {
    setState((prevState) => update(prevState, {
      type: { $set: (!prevState.view) ? 'text' : 'password' },
      view: { $set: (!prevState.view) },
    }))
  }

  useEffect(() => {
    if (variant === 'phone') {
      setState((prevState) => update(prevState, {
        type: { $set: 'tel' },
        view: { $set: false },
      }))
    }
    if (variant === 'email') {
      setState((prevState) => update(prevState, {
        type: { $set: 'email' },
        view: { $set: false },
      }))
    }
    if (variant === 'password') {
      setState((prevState) => update(prevState, {
        type: { $set: 'password' },
        view: { $set: false },
      }))
    }
  }, [variant])

  return (
    <Field name={name} rules={rules}>
      {(control: Values, meta: Meta, form: FormInstance) => {
        if (!rules) {
          rules = []
        }
        const hasRule = rules.find((rule: { type: RuleType }) => rule.type === 'method')
        if (!hasRule) {
          rules.push({
            type: 'method',
            validateTrigger: 'onSubmit',
            validator: (rule: RuleObject, value: StoreValue, callback: (error?: string) => void): void => {
              let error: string | undefined
              if (variant === 'email') {
                const isValid = SiteHelper.validateEmail(value)
                if (!isValid) {
                  error = 'Please enter a valid email address'
                }
              } else if (variant === 'phone') {
                if (rule.required) {
                  const isValid = SiteHelper.validatePhone(value)

                  if (!isValid) {
                    error = 'Please enter a valid phone number'
                  }
                }
              } else if (variant === 'creditCard') {
                const isValid = valid.number(value.replace(' ', ''))
                if (!isValid.isValid) {
                  error = 'Please enter a valid credit card number'
                }
              }
              callback(error)
            },
          })
        }
        const required = !!(
          rules &&
          rules.some(rule => {
            if (rule && typeof rule === 'object' && rule.required) {
              return true
            }
            if (typeof rule === 'function') {
              const ruleEntity = rule(form)
              return ruleEntity && ruleEntity.required
            }
            return false
          })

        )
        const error = meta.errors?.[0]
        const { value, onChange } = control

        const format = (val: string): string | number | undefined => {
          if (val === undefined) {
            return
          }
          if (variant === 'integer') {
            return parseInt(val)
          } else if (variant === 'float') {
            return parseFloat(val)
          } else if (variant === 'email') {
            return val
          } else if (variant === 'phone') {
            let newNumber = null
            if (val.indexOf(' ') !== -1) {
              const values = val.split(' ')
              const prefix = SiteHelper.getCountryCodeForTelPrefix(values.shift())
              const number = values.join(' ')
              try {
                const phoneNumber = parsePhoneNumberFromString(number, prefix)
                newNumber = phoneNumber.formatInternational()
              } catch (err) { }
            } else {
              newNumber = val
              try {
                const phoneNumber = parsePhoneNumberFromString(newNumber, 'ZA')
                newNumber = phoneNumber.formatInternational()
              } catch (err) { }
            }
            return newNumber
          } else if (variant === 'creditCard') {
            if (val.length > 0) {
              const cardNum = val.replace(/\s/g, '')
              let checkedNum = parseInt(cardNum) + ''
              if (checkedNum === 'NaN') {
                return ''
              }
              if (checkedNum.length > 16) {
                checkedNum = checkedNum.substr(0, 16)
              }
              let returnString = ''
              for (let s = 0; s < checkedNum.length; s++) {
                if (s % 4 === 0 && s !== 0) {
                  returnString += ' '
                }
                returnString += checkedNum.charAt(s)
              }
              return returnString
            }
          } else if (variant === 'expiryDate') {
            if (val.length > 0) {
              const parts: string[] = val.split('/').map((part) => part.trim()).slice(0, 2)
              if (parts.length === 2 && parts[1]) {
                if (isNaN(parseInt(parts[0]))) {
                  return ''
                }
                if (parseInt(parts[0]) < 10) {
                  parts[0] = '0' + parseInt(parts[0])
                }
                if (parseInt(parts[0]) > 12) {
                  parts[0] = '12'
                }
                if (isNaN(parseInt(parts[1]))) {
                  return parts[0] + ' / '
                }
                const currentYear = new Date().getFullYear()
                if (parts[1].length >= 4) {
                  parts[1] = parts[1].substring(0, 4)
                  if (parseInt(parts[1]) < currentYear) {
                    return parts[0] + ' / '
                  }
                }
                return parts[0] + ' / ' + parts[1]

              } else if (parts.length === 1) {
                if (parts[0].length >= 2 && val.slice(-2) !== ' /') {
                  if (parseInt(parts[0].substring(0, 2)) > 12) {
                    parts[0] = '12'
                  }
                  parts[0] = parts[0].substring(0, 2) + ' / '
                }
                if (isNaN(parseInt(parts[0].trim()))) {
                  return ''
                }
                return parts[0]
              }
            }
          } else {
            return val
          }
        }

        const _handleChange = (e: ChangeEvent<HTMLInputElement>) => {

          const newValue = e.target.value
          const parsed = format(newValue)
          onChange(parsed)
        }

        return (
          <InputWrapper
            className={wrapperClassName}
            required={required}
            label={label}
            showLabel={showLabel}
            error={error || errorMessage}>
            <FormContext.Consumer>
              {({ loading: formLoading, disabled: formDisabled, registerRef }: FormContextProperties) => {
                registerRef?.(name, inputRef)
                return (
                  <Container>
                    <TextInputElement
                      ref={inputRef}
                      className={className}
                      inputBgColor={inputBgColor}
                      autoComplete={autoComplete}
                      disabled={loading || disabled || formLoading || formDisabled}
                      name={name}
                      error={!!error || !!errorMessage}
                      type={state.type}
                      value={value ? value + '' : ''}
                      placeholder={placeholder}
                      onChange={_handleChange}
                      readOnly={readOnly}
                      onFocus={onFocus}
                      onBlur={onBlur} 
                      onKeyDown={(e) => {
                        
                        if (formContext) {
                          const { registerRef } = formContext
                          registerRef?.(name, inputRef)
                        }
                        if (e.key === "Tab") {
                          const form = e.currentTarget.closest('form')
                          const inputs = Array.from(form.querySelectorAll('input'))
                          const index = inputs.indexOf(e.currentTarget)
                          if (index === inputs.length - 1) {
                            e.preventDefault()
                            inputs[0].focus()
                          }
                        }
                      }}/>
                    <If condition={loading || formLoading}>
                      <LoadingContainer>
                        <SmallLoader
                          color={theme.colors.green.deYork} />
                      </LoadingContainer>
                    </If>
                    <If condition={!loading && !formLoading && variant === 'email' && !!error}>
                      <InputIconContainer>
                        <Icon icon={LocalIconEnums.ERROR_WARNING} color={theme.colors.red.cinnabar} />
                      </InputIconContainer>
                    </If>
                    <If condition={!loading && !formLoading && variant === 'password'}>
                      <InputIconContainer onClick={() => changeView()}>
                        <Icon icon={(state.view) ? LocalIconEnums.EYE_ON : LocalIconEnums.EYE_OFF} color={theme.colors.grey.silver} />
                      </InputIconContainer>
                    </If>
                  </Container>
                )
              }}
            </FormContext.Consumer>
          </InputWrapper>
        )
      }}
    </Field>
  )

}
