import React, { useEffect, useRef, useState } from 'react'

import update from 'react-addons-update'
import { useSearchParams } from 'react-router-dom'
import styled from 'styled-components'

import { Heading } from '@atoms/typography'
import { ResponsivePXValue } from '@components/Theme'
import { PageQuestionFragment, PageQuestionSectionFragment } from '@hooks/api/index'
import { HTML } from '@molecules/content'

import { IndexAccordion, IndexSection } from './IndexAccordion'

const Container = styled.div`
  width: 100%;
  background-color: ${(props): string => props.theme.colors.white.pureWhite};
`

const TitleContainer = styled.div`
  ${ResponsivePXValue('padding', { mobile: '12px 0px', tablet: '9px 0px', desktop: '9px 0px' })}
  ${ResponsivePXValue('margin', { mobile: '0px 24px', tablet: '0px 16px', desktop: '0px 16px' })}
`

const AccordionContainer = styled.div`
  ${ResponsivePXValue('padding', { mobile: '12px 0px', tablet: '9px 0px', desktop: '9px 0px' })}
  ${ResponsivePXValue('margin', { mobile: '0px 24px', tablet: '0px 16px', desktop: '0px 16px' })}
`
export interface PageQuestionSectionProps {
  section: PageQuestionSectionFragment
}

interface PageQuestionSectionState {
  tabs: string[]
  activeQuestion: string
}

const DEFAULT_STATE: PageQuestionSectionState = {
  tabs: [],
  activeQuestion: '',
}

export function PageQuestionSection({ section }: PageQuestionSectionProps): JSX.Element {

  const [state, setState] = useState<PageQuestionSectionState>({ ...DEFAULT_STATE })

  const _handleTabsChange = (tabs: string[]): void => {
    setState((prevState) => update(prevState, {
      tabs: { $set: tabs },
    }))
  }

  let question!: PageQuestionFragment

  return (
    <Container>
      <If condition={!!section?.title}>
        <TitleContainer>
          <Heading variant='h3'>{section.title}</Heading>
        </TitleContainer>
      </If>
      <AccordionContainer>
        <IndexAccordion tabs={state.tabs} onTabsChange={_handleTabsChange}>
          <For each='question' of={section?.question?.questions || []}>
            <IndexSection title={question.question} key={question.id} id={question.id}>
              <HTML>
                {question.answer}
              </HTML>
            </IndexSection>
          </For>
        </IndexAccordion>
      </AccordionContainer>
    </Container>
  )

}
