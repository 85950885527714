import React, { useEffect, useRef, useState } from 'react'

import { set } from 'date-fns'
import update from 'react-addons-update'
import { useSearchParams, useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { Heading } from '@atoms/typography'
import { ResponsivePXValue, theme } from '@components/Theme'
import { PageAccordionSectionFragment } from '@hooks/api/index'

import { Accordion } from './index'

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${ResponsivePXValue('margin-top', { mobile: '12px', tablet: '40px', desktop: '40px' })}
`

const HeadingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  ${ResponsivePXValue('padding', '12px 0px')}
  ${ResponsivePXValue('margin-bottom', '40px')}
  ${ResponsivePXValue('margin-bottom', { mobile: '16px', tablet: '40px', desktop: '40px' })}
`

const PageJumper = styled.div`
  ${ResponsivePXValue('display', { mobile: 'none', tablet: 'flex', desktop: 'flex' })}
  ${ResponsivePXValue('gap', '8px')};
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  ${ResponsivePXValue('width', { tablet: '920px', desktop: '920px' })}
  ${ResponsivePXValue('margin-bottom', '40px')}
`

const PageJumperButton = styled.div`
  background-color: ${({ theme }) => theme.colors.brown.fantasy};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${ResponsivePXValue('padding', '12px 24px')};
  width: fit-content;
  border-radius: ${ResponsivePXValue('border-radius', '4px')};
  cursor: pointer;

  &:hover {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3)), ${({ theme }) => theme.colors.brown.fantasy}
  }
`

const AccordionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${ResponsivePXValue('gap', { mobile: '0px', tablet: '24px', desktop: '24px' })}
  ${ResponsivePXValue('margin-bottom', { mobile: '16px', tablet: '80px', desktop: '80px' })}

  a {
    text-decoration: none;
    color: inherit;
    display: block;
  }
`

interface PageAccordionSectionState {
  activeSection: string
}

const DEFAULT_STATE = {
  activeSection: '',
}

export interface PageAccordionSectionProps {
  section: PageAccordionSectionFragment
}

export function PageAccordionSection({ section }: PageAccordionSectionProps): JSX.Element {

  const accordionRefs: React.MutableRefObject<HTMLDivElement[]> = useRef([])
  const [searchParams] = useSearchParams()
  const [state, setState] = useState<PageAccordionSectionState>({ ...DEFAULT_STATE })

  const _handleScroll = (id: number): void => accordionRefs.current[id].scrollIntoView({ behavior: 'smooth' })

  let itemIndex!: number
  let accordionIndex!: number

  useEffect(() => {
    const section = searchParams.get('section')

    setState((prevState) => update(prevState, { activeSection: { $set: section } }))

    const element = document.getElementById(section)

    !!element && element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' })

  }, [state.activeSection, searchParams])

  return (

    <Container>
      <If condition={!!section.title}>
        <HeadingWrapper>
          <Heading
            variant='h1'
            align='center'>
            {section.title}
          </Heading>
        </HeadingWrapper>
      </If>
      <If condition={!!section.quickLinks.length}>
        <PageJumper>
          <For each='button' index='itemIndex' of={section.quickLinks} >
            <PageJumperButton
              key={itemIndex}
              title={section.quickLinks[itemIndex].title}
              onClick={() => _handleScroll(itemIndex)}
            >
              <Heading
                variant='h8'
                align='center'
                transform='capitalize'
                color={theme.colors.green.bottleGreen}
              >
                {section.quickLinks[itemIndex].title}
              </Heading>
            </PageJumperButton>
          </For>
        </PageJumper>
      </If>
      <AccordionContainer>
        <For each='accordion' index='accordionIndex' of={section.questions}>
          <div
            ref={(element) => accordionRefs.current.push(element)}
            key={section.questions[accordionIndex].id}
            id={section.questions[accordionIndex].title}
          >
            <Accordion
              title={section.questions[accordionIndex].title}
              content={section.questions[accordionIndex]}/>
          </div>
        </For>
      </AccordionContainer>
    </Container>
  )
}
