import React from 'react'

import ReactPlayer from 'react-player'
import styled from 'styled-components'

import { APP_DEFAULT_STATE } from '@api/local/AppPlugin'
import { Heading } from '@atoms/typography'
import { ResponsivePXValue } from '@components/Theme'
import { useGetAppQuery, PageMediaSectionFragment } from '@hooks/api/index'
import { HTML } from '@molecules/content'
import { DeviceTypeEnum } from '@uctypes/api/globalTypes'

const Container = styled.div`
  width: 100%;
  ${ResponsivePXValue('padding', { mobile: '24px 0px 46px', tablet: '77px 0px 72px', desktop: '77px 0px 72px' })}

`
const TitleContainer = styled.div`
  width: 100%;
  ${ResponsivePXValue('margin-bottom', '16px')}  
`
const SubTitleContainer = styled.div`
  width: 100%;
  ${ResponsivePXValue('margin-bottom', '16px')}

`
const DescriptionContainer = styled.div`
  width: 100%;
  ${ResponsivePXValue('margin-bottom', { mobile: '40px', tablet: '94px', desktop: '94px' })}
  ${ResponsivePXValue('padding', { mobile: '0 16px', tablet: '0px 140px', desktop: '0px 140px' })}

`
const MediaContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`
export interface PageMediaSectionProps {
  section: PageMediaSectionFragment
}

export function PageMediaSection({ section }: PageMediaSectionProps): JSX.Element {

  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()
  const { MOBILE } = DeviceTypeEnum

  return (
    <Container>
      <TitleContainer>
        <Heading variant='h1' align='center'>{section.heading}</Heading>
      </TitleContainer>
      <SubTitleContainer>
        <Heading variant={MOBILE === appData.app.deviceType ? 'h5' : 'h2'} align='center'>{section.subTitle}</Heading>
      </SubTitleContainer>
      <DescriptionContainer>
        <HTML align='center'>{section.description}</HTML>
      </DescriptionContainer>
      <MediaContainer>
        <ReactPlayer
          width={MOBILE === appData.app.deviceType ? '288px' : '960px'}
          height={MOBILE === appData.app.deviceType ? '180px' : '542px'}
          url={section.video}>
        </ReactPlayer>
      </MediaContainer>
    </Container>
  )
}
