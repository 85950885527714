import React from 'react'

import styled from 'styled-components'

import { ResponsivePXValue } from '@components/Theme'
import { Heading } from '@atoms/typography'

const ListItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${ResponsivePXValue('height', '73px')}


  ${ResponsivePXValue('gap', '8px')}
`

const NumberContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  z-index: 1;
  background: ${(props) => props.theme.colors.green.magicMint};
  color: ${(props) => props.theme.colors.green.bottleGreen};
  height: 100%;
   ${ResponsivePXValue('width', { mobile: '90px', tablet: '80px', desktop: '80px' })}
`

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${ResponsivePXValue('gap', '20px')}
`

const ChildrenContainer = styled.div`
  width: 100%;
  z-index: 0;
  background: ${(props) => props.theme.colors.white.pureWhite};
  display: flex;
  align-items: center;

  height: 100%;
  ${ResponsivePXValue('margin-left', '-38px')}
  ${ResponsivePXValue('padding-left', '40px')}
  ${ResponsivePXValue('padding-right', '8px')}
`

interface NumberedListProps {
  items: JSX.Element[]
}

export function NumberedList({ items }: NumberedListProps): JSX.Element {

  let item
  let index
  return (
    <ListContainer>
      <For index='index' each='item' of={items}>
        <ListItemContainer key={index}>
          <NumberContainer>
            <Heading variant='h1'>
              {parseInt(index)+1}
            </Heading>
            </NumberContainer>
          <ChildrenContainer>{item}</ChildrenContainer>
        </ListItemContainer>
      </For>
    </ListContainer>
  )
}
