
import React from 'react'
import { Svg } from './Svg'

export interface PinterestProps {
  color?: string
  hoverColor?: string
  className?: string
}

export function Pinterest({ color, hoverColor, className }: PinterestProps): JSX.Element {

  return (
    <Svg strokeColor={color} strokeHoverColor={hoverColor} fillColor={color} fillHoverColor={hoverColor} className={className} width="100%" height="100%" viewBox="0 0 20 20" version="1.1">
      <g transform="matrix(1.00619,0,0,1.00619,-1308.39,-3106.95)">
        <path className='fill' d="M1319.22,3097.77C1319.22,3102.68 1315.18,3106.72 1310.28,3106.72C1309.38,3106.72 1308.49,3106.59 1307.63,3106.32C1308.1,3105.59 1308.47,3104.8 1308.74,3103.97C1308.85,3103.56 1309.3,3101.85 1309.3,3101.85C1309.76,3102.51 1310.53,3102.9 1311.35,3102.88C1314.05,3102.88 1315.99,3100.39 1315.99,3097.31C1315.94,3094.46 1313.59,3092.14 1310.74,3092.14C1310.65,3092.14 1310.56,3092.14 1310.47,3092.14C1306.61,3092.14 1304.56,3094.73 1304.56,3097.56C1304.52,3098.95 1305.21,3100.26 1306.37,3101.02C1306.54,3101.1 1306.63,3101.07 1306.67,3100.91L1306.92,3099.89C1306.95,3099.8 1306.92,3099.7 1306.86,3099.64C1306.43,3099.04 1306.2,3098.33 1306.2,3097.59C1306.2,3097.59 1306.2,3097.59 1306.2,3097.58C1306.2,3095.46 1307.95,3093.71 1310.07,3093.71C1310.13,3093.71 1310.19,3093.71 1310.24,3093.71C1310.31,3093.71 1310.38,3093.71 1310.45,3093.71C1312.39,3093.71 1313.98,3095.3 1313.98,3097.24C1313.98,3097.28 1313.98,3097.31 1313.98,3097.35C1313.98,3099.77 1312.76,3101.45 1311.16,3101.45C1311.14,3101.45 1311.12,3101.45 1311.1,3101.45C1310.39,3101.45 1309.8,3100.87 1309.8,3100.15C1309.8,3100.05 1309.82,3099.94 1309.84,3099.84C1310.17,3098.87 1310.42,3097.87 1310.58,3096.86C1310.59,3096.81 1310.59,3096.77 1310.59,3096.72C1310.59,3096.1 1310.08,3095.6 1309.46,3095.6C1309.46,3095.6 1309.45,3095.6 1309.45,3095.6C1308.55,3095.6 1307.83,3096.52 1307.83,3097.77C1307.82,3098.22 1307.91,3098.68 1308.1,3099.1C1308.28,3099.51 1307.21,3102.84 1307.05,3103.54C1306.91,3104.39 1306.9,3105.26 1307.02,3106.11C1303.59,3104.77 1301.33,3101.45 1301.33,3097.78C1301.33,3092.87 1305.37,3088.83 1310.28,3088.83C1315.18,3088.83 1319.22,3092.87 1319.22,3097.78L1319.22,3097.77Z" />
      </g>
    </Svg>
  )

}
    