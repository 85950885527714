import React from 'react'

import loadable from '@loadable/component'

// import(/* webpackPrefetch: true */ '@components/builder/pages/PageBuilderPages')

const PageBuilderPages = loadable(() => import(/* webpackChunkName: "page-builder-pages" */'@components/builder/pages/PageBuilderPages'), {
  resolveComponent: (components) => components.PageBuilderPages,
})

export function PageBuilderPagesAsync(): JSX.Element {

  return <PageBuilderPages fallback={<div>Loading...</div>} />

}
