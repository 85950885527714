import React, { } from 'react'

import styled from 'styled-components'

import { ResponsiveImage } from '@atoms/images'
import { Paragraph, Title } from '@atoms/typography'
import { ResponsivePXValue } from '@components/Theme'
import { PageExcerptSectionFragment } from '@hooks/api/index'
import { FlexDeviceContainer } from '@utility/DeviceContainer'

const templateForSection = (section: PageExcerptSectionFragment): { mobile: string, tablet: string, desktop: string } | string => {
  if (section.image && section.attribution) {
    return {
      mobile: '"I E" "A A"',
      tablet: '"I E" "A A"',
      desktop: '"I E" "I A"',
    }
  } else if (section.image) {
    return '"I E"'
  } else if (section.attribution) {
    return '"E" "A"'
  } else {
    return '"E"'
  }
}

const rowsForSection = (section: PageExcerptSectionFragment): { mobile: string, tablet: string, desktop: string } | string => {
  if (section.attribution) {
    return 'auto auto'
  } else {
    return 'auto'
  }
}

const columnsForSection = (section: PageExcerptSectionFragment): { mobile: string, tablet: string, desktop: string } | string => {
  if (section.image) {
    return { mobile: '90px auto', tablet: '90px auto', desktop: '120px auto' }
  } else {
    return 'auto'
  }
}

const Container = styled.div<{ section: PageExcerptSectionFragment }>`
  display: grid;
  width: 100%;
  background-color: ${(props): string => props.section.backgroundColor};
  ${ResponsivePXValue('padding', '16px')}
  ${ResponsivePXValue('column-gap', '24px')}
  ${ResponsivePXValue('row-gap', '16px')}
  ${(props): string => ResponsivePXValue('grid-template-areas', templateForSection(props.section))}
  ${(props): string => ResponsivePXValue('grid-template-columns', columnsForSection(props.section))}
  ${(props): string => ResponsivePXValue('grid-template-rows', rowsForSection(props.section))}

  .title {
    grid-area: E;
    align-self: center;
  }
  .paragraph {
    grid-area: A;
  }
  .flex-mobile {
    align-items: center;
    justify-content: center;
  }
  .flex-desktop {
    align-items: center;
  }
`

const ImageContainer = styled.div`

  grid-area: I;
  ${ResponsivePXValue('width', { mobile: '90px', tablet: '90px', desktop: '120px' })}
  align-self: center;
  border-radius: 50%;
  overflow: hidden;
`

export interface PageExcerptSectionProps {
  section: PageExcerptSectionFragment
}

export function PageExcerptSection({ section }: PageExcerptSectionProps): JSX.Element {

  return (
    <Container section={section}>
      <ImageContainer>
        <ResponsiveImage objectFit='cover' image={section.image} />
      </ImageContainer>
      <FlexDeviceContainer mobile tablet className='flex-mobile'>
        <Title variant='t6' className='title' color={section.foregroundColor}>
          &ldquo;{section.excerpt}&rdquo;
        </Title>
      </FlexDeviceContainer>
      <FlexDeviceContainer desktop className='flex-desktop'>
        <Title variant='t3' className='title' color={section.foregroundColor}>
          &ldquo;{section.excerpt}&rdquo;
        </Title>
      </FlexDeviceContainer>
      <Paragraph className='paragraph' color={section.foregroundColor}>
        {section.attribution}
      </Paragraph>
    </Container>

  )

}
