
import React from 'react'
import { Svg } from './Svg'

export interface SearchProps {
  color?: string
  hoverColor?: string
  className?: string
}

export function Search({ color, hoverColor, className }: SearchProps): JSX.Element {

  return (
    <Svg strokeColor={color} strokeHoverColor={hoverColor} fillColor={color} fillHoverColor={hoverColor} className={className} width="100%" height="100%" viewBox="0 0 20 20" version="1.1">
      <g transform="matrix(0.959102,0,0,0.959102,0.40898,0.40898)">
        <path className='fill' d="M19.319,18.027L13.232,11.941C14.177,10.72 14.688,9.227 14.688,7.656C14.688,5.777 13.954,4.014 12.627,2.685C11.301,1.356 9.534,0.625 7.656,0.625C5.779,0.625 4.012,1.359 2.685,2.685C1.356,4.012 0.625,5.777 0.625,7.656C0.625,9.534 1.359,11.301 2.685,12.627C4.012,13.956 5.777,14.688 7.656,14.688C9.227,14.688 10.717,14.177 11.938,13.234L18.025,19.319C18.043,19.337 18.064,19.351 18.087,19.36C18.111,19.37 18.136,19.375 18.161,19.375C18.186,19.375 18.211,19.37 18.235,19.36C18.258,19.351 18.279,19.337 18.297,19.319L19.319,18.299C19.337,18.281 19.351,18.26 19.36,18.237C19.37,18.214 19.375,18.189 19.375,18.163C19.375,18.138 19.37,18.113 19.36,18.09C19.351,18.066 19.337,18.045 19.319,18.027ZM11.369,11.369C10.375,12.36 9.058,12.906 7.656,12.906C6.255,12.906 4.938,12.36 3.944,11.369C2.952,10.375 2.406,9.058 2.406,7.656C2.406,6.255 2.952,4.935 3.944,3.944C4.938,2.952 6.255,2.406 7.656,2.406C9.058,2.406 10.377,2.95 11.369,3.944C12.36,4.938 12.906,6.255 12.906,7.656C12.906,9.058 12.36,10.377 11.369,11.369Z" />
      </g>
    </Svg>
  )

}
    