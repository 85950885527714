
import React from 'react'
import { Svg } from './Svg'

export interface ChevronDownProps {
  color?: string
  hoverColor?: string
  className?: string
}

export function ChevronDown({ color, hoverColor, className }: ChevronDownProps): JSX.Element {

  return (
    <Svg strokeColor={color} strokeHoverColor={hoverColor} fillColor={color} fillHoverColor={hoverColor} className={className} width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6 9L12 15L18 9" className='stroke' strokeWidth="2" strokeLinecap="square" />
    </Svg>
  )

}
    