import React, { useRef, useState } from 'react'

import update from 'react-addons-update'
import styled from 'styled-components'

import { ResponsiveImage } from '@atoms/images'
import { Skeleton, SkeletonNode } from '@atoms/layout'
import { ResponsivePXValue } from '@components/Theme'
import { PageBannerFragment } from '@hooks/api/index'
import useIsomorphicLayoutEffect from '@hooks/UseIsomorphicLayoutEffect'
import { DeviceContainer } from '@utility/DeviceContainer'

const Container = styled.div``

const ImageContainer = styled.div<{ height: number }>`
  position: relative;
  width: 100%;
  ${(props): string => {
    if (props.height) {
      return `height: ${props.height}px;`
    }
    return ResponsivePXValue('height', { mobile: '320px', tablet: '568px', desktop: '568px' })
  }}
  display: flex;
  flex-direction: column;
`

interface PageStandardBannerState {
  mobileHeight: number
  desktopHeight: number
}

export interface PageStandardBannerProps {
  banner: PageBannerFragment
}

const DEFAULT_STATE: PageStandardBannerState = {
  mobileHeight: 0,
  desktopHeight: 0,
}

export function PageImageOnlyBanner({ banner }: PageStandardBannerProps): JSX.Element {

  const [state, setState] = useState<PageStandardBannerState>({ ...DEFAULT_STATE })
  const containerRef: React.RefObject<HTMLDivElement> = useRef()

  if (!banner) {
    return (
      <Skeleton color='pureWhite' justify='flex-start' padding={{ mobile: '10px', tablet: '24px', desktop: '24px' }} gap='24px'>
        <SkeletonNode
          color='gallery'
          shape='rounded'
          height={{ mobile: '320px', tablet: '488px', desktop: '400px' }}
          width={{ mobile: '100%', tablet: '488px', desktop: '400px' }}
        />
        <Skeleton loop={8} direction='column' justify='space-between' align='flex-start'>
          <SkeletonNode
            color='gallery'
            shape='rounded'
            height={{ mobile: '24px', tablet: '24px', desktop: '24px' }}
            variWidth={{ mobile: '50%-90%', tablet: '50%-90%', desktop: '50%-90%' }}/>
        </Skeleton>
      </Skeleton>
    )
  }

  useIsomorphicLayoutEffect(() => {
    if (containerRef.current) {
      const desktopHeight = (banner.image.imageInfo.height / banner.image.imageInfo.width) * containerRef.current.getBoundingClientRect().width
      const mobileHeight = banner.mobileImage
        ? (banner.mobileImage.imageInfo.height / banner.mobileImage.imageInfo.width) * containerRef.current.getBoundingClientRect().width
        :desktopHeight
      setState((prevState) => update(prevState, {
        desktopHeight: {
          $set: desktopHeight,
        },
        mobileHeight: {
          $set: mobileHeight,
        },
      }))
    }
  }, [banner, containerRef.current])

  return (
    <Container ref={containerRef}>
      <DeviceContainer desktop tablet>
        <ImageContainer height={state.desktopHeight}>
          <ResponsiveImage image={banner.image} objectFit='cover' />
        </ImageContainer>
      </DeviceContainer>
      <DeviceContainer mobile>
        <ImageContainer height={state.mobileHeight}>
          <ResponsiveImage image={banner.mobileImage ? banner.mobileImage : banner.image} objectFit='cover' />
        </ImageContainer>
      </DeviceContainer>
    </Container>
  )

}
