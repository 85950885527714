
import React from 'react'

import { Svg } from './Svg'

export interface UserProps {
  color?: string
  hoverColor?: string
  className?: string
}

export function User({ color, hoverColor, className }: UserProps): JSX.Element {

  return (
    <Svg strokeColor={color} strokeHoverColor={hoverColor} fillColor={color} fillHoverColor={hoverColor} className={className} width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.06252 3.875C7.97064 5.11414 7.03127 6.125 6.00002 6.125C4.96877 6.125 4.02775 5.11438 3.93752 3.875C3.84377 2.58594 4.75783 1.625 6.00002 1.625C7.24221 1.625 8.15627 2.60938 8.06252 3.875Z" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M6.00006 7.625C3.96099 7.625 1.89146 8.75 1.50849 10.8734C1.46232 11.1294 1.60717 11.375 1.87506 11.375H10.1251C10.3932 11.375 10.538 11.1294 10.4919 10.8734C10.1087 8.75 8.03912 7.625 6.00006 7.625Z" stroke={color} strokeMiterlimit="10"/>
    </Svg>

  )

}



