import React from 'react'

import styled from 'styled-components'

import { ResponsivePXValue } from '@components/Theme'

import { Loader } from './Loader'

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  ${ResponsivePXValue('height', 'CALC(100vh - 140px)')}
`

export function PageLoader(): JSX.Element {

  return (
    <Container>
      <Loader />
    </Container>
  )

}
