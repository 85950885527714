import React from 'react'

import styled, { useTheme } from 'styled-components'

import { Icon, LocalIconEnums } from '@atoms/index'
import { ResponsivePXValue } from '@components/Theme'

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  cursor: pointer;

  background-color: ${(props): string => props.theme.colors.green.bottleGreen};
  ${ResponsivePXValue('height', '40px')}
`

const TitleContainer = styled.div`
  position: relative;
  font-weight: 600;
  font-family: open-sans;

  color: ${(props): string => props.theme.colors.white.pureWhite};

  ${ResponsivePXValue('left', '5px')}
  ${ResponsivePXValue('font-size', '16px')}
`

const IconContainer = styled.div`
  position: relative;
  ${ResponsivePXValue('top', '-1px')}
  ${ResponsivePXValue('width', '40px')}
  ${ResponsivePXValue('height', '40px')}
`

// TODO Part of checkout so leaving here
export function CartButton(): JSX.Element {

  const theme = useTheme()

  return (
    <Container>
      <TitleContainer>ADD TO</TitleContainer>
      <IconContainer>
        <Icon icon={LocalIconEnums.CART} color={theme.colors.white.pureWhite} />
      </IconContainer>
    </Container>
  )

}
