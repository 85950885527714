import React, { useRef, useState } from 'react'

import styled, { useTheme } from 'styled-components'

import { MENU_CATEGORIES_DEFAULT_STATE } from '@api/local/MenuCategoryPlugin'
import { Heading, Seperator } from '@atoms/index'
import { DesktopCategoryMenuItem } from '@components/atoms/navigation/DesktopCategoryMenuItem'
import { ResponsivePXValue } from '@components/Theme'
import { useGetMenuCategoriesQuery, MenuCategoryBaseFragment } from '@hooks/api/index'
import { SiteHelper } from '@lib/SiteHelper'

type MenuCategoryFragmentChildren = MenuCategoryBaseFragment['children'][0]
type MenuCategoryFragmentChildrenChildren = MenuCategoryBaseFragment['children'][0]['children'][0]
type MenuCategoryFragmentChildrenChildrenChildren = MenuCategoryBaseFragment['children'][0]['children'][0]['children'][0]

const DEBOUCE_TIME = 300

const Container = styled.div`
  position: absolute;
  display: flex;
  width: 100%;
  ${ResponsivePXValue('height', '430px')};
  margin: 0 auto;
  background-color: ${(props): string => props.theme.colors.white.pureWhite};
  ${ResponsivePXValue('top', '55px')}
`

const CatWrapper = styled.div`
  flex-shrink: 0;
  overflow-y: scroll;
  overflow-x: visible;

  width: 23%;

  ${ResponsivePXValue('padding', '0 32px')}

  .menu-heading {
    display: block;
    width: 100%;
    font-family: 'fortescue';
    font-weight: 700;
    margin: 0;
    ${ResponsivePXValue('padding', '12px 8px')}
    ${ResponsivePXValue('font-size', '20px')}
    color: ${(props): string => props.theme.colors.green.bottleGreen};
  }
`

const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  .category-link {
    ${ResponsivePXValue('margin', '8px')}
  }
`

export interface DesktopCategoryMenuProps {
  mainNav: MenuCategoryBaseFragment
  onSelect?: (path: string) => void
}

export function DesktopCategoryMenu({ mainNav, onSelect }: DesktopCategoryMenuProps): JSX.Element {

  const { data: menuCategoriesData = { menuCategories: { ...MENU_CATEGORIES_DEFAULT_STATE } } } = useGetMenuCategoriesQuery()
  // const { data: childData, loading } = useGetCategoryMenuChildrenQuery({ variables: { parentCategoryId: mainNav.uid } })
  const [firstCatHovering, setFirstCatHovering] = useState<MenuCategoryFragmentChildren | null>(null)
  const [secondCatHovering, setSecondCatHovering] = useState<MenuCategoryFragmentChildrenChildren | null>(null)
  const [thirdCatHovering, setThirdCatHovering] = useState<MenuCategoryFragmentChildrenChildrenChildren | null>(null)
  const timer = useRef<NodeJS.Timeout | null>()

  const theme = useTheme()

  const clearDebounce = (): void => {
    clearTimeout(timer?.current)
    timer.current = null
  }

  const _handleMouseEnterFirstCat = (subItem: MenuCategoryFragmentChildren) => {
    if (!timer?.current) {
      setFirstCatHovering(subItem)
    }
    clearDebounce()
    timer.current = setTimeout(() => {
      setFirstCatHovering(subItem)
    }, DEBOUCE_TIME)
  }

  const _handleMouseEnterSecondCat = (subItem: MenuCategoryFragmentChildrenChildren) => {
    if (!timer?.current) {
      setSecondCatHovering(subItem)
    }
    clearDebounce()
    timer.current = setTimeout(() => {
      setSecondCatHovering(subItem)
    }, DEBOUCE_TIME)
  }

  const _handleMouseEnterThirdCat = (subItem: MenuCategoryFragmentChildrenChildrenChildren) => {
    if (!timer?.current) {
      setThirdCatHovering(subItem)
    }
    clearDebounce()
    timer.current = setTimeout(() => {
      setThirdCatHovering(subItem)
    }, DEBOUCE_TIME)
  }

  const firstCatItemsGroup: MenuCategoryFragmentChildren[] = mainNav.children

  let subItem: MenuCategoryFragmentChildren
  let secondCatItemsParent: MenuCategoryFragmentChildren
  let secondCatItemsGroup: MenuCategoryFragmentChildrenChildren[]
  let secondCatItemsIndex: number
  let secondCatItem: MenuCategoryFragmentChildrenChildren

  let thirdCatItemsParent: MenuCategoryFragmentChildrenChildrenChildren
  let thirdCatItemsGroup: MenuCategoryFragmentChildrenChildrenChildren[]
  let thirdCatItemsIndex: number
  let thirdCatItem: MenuCategoryFragmentChildrenChildrenChildren

  if (firstCatHovering) {
    secondCatItemsParent = firstCatItemsGroup.find((child) => child.uid === firstCatHovering.uid)
  }
  if (!secondCatItemsParent && menuCategoriesData.menuCategories.currentMiddle) {
    secondCatItemsParent = firstCatItemsGroup.find((child) => child.uid === menuCategoriesData.menuCategories.currentMiddle)
  }
  const secondCatFiltered = secondCatItemsParent?.children?.filter((child) => child.includeInMenu) || []
  const secondCatItems: Readonly<MenuCategoryFragmentChildrenChildren[][]> = SiteHelper.chunkArray(secondCatFiltered, 10)

  if (secondCatHovering) {
    thirdCatItemsParent = secondCatItemsParent?.children.find((child) => child.uid === secondCatHovering.uid)
  }
  if (!thirdCatItemsParent && menuCategoriesData.menuCategories.currentMiddle) {
    thirdCatItemsParent = secondCatItemsParent?.children?.find((child) => child.uid === menuCategoriesData.menuCategories.currentMiddle)
  }
  const thirdCatFiltered = thirdCatItemsParent?.children?.filter((child) => child.includeInMenu) || []
  const thirdCatItems: Readonly<MenuCategoryFragmentChildrenChildren[][]> = SiteHelper.chunkArray(thirdCatFiltered, 10)

  return (
    <Container>
      <CatWrapper>
        <For each='subItem' of={firstCatItemsGroup}>
          <DesktopCategoryMenuItem
            onMouseEnter={() => _handleMouseEnterFirstCat(subItem)}
            active={subItem.uid === menuCategoriesData?.menuCategories?.currentTop}
            hovering={subItem.uid === firstCatHovering?.uid}
            href={`/${subItem.canonicalUrl}`}
            key={subItem.uid}
            title={subItem.name}
            onClick={() => onSelect?.(`/${subItem.canonicalUrl}`)} />
        </For>
      </CatWrapper>
      <If condition={!!secondCatItems.length}>
        <Seperator align='vertical' color={theme.colors.grey.pampas} />
        <CatWrapper>
          <Heading className='menu-heading' variant='h3' color={theme.colors.green.watercourse} >{firstCatHovering?.name}</Heading>
          <For each='secondCatItemsGroup' index='secondCatItemsIndex' of={secondCatItems}>
            <MenuContainer key={secondCatItemsIndex}>
              <For each='secondCatItem' of={secondCatItemsGroup}>
                <DesktopCategoryMenuItem
                  onMouseEnter={() => _handleMouseEnterSecondCat(secondCatItem)}
                  active={secondCatItem.uid === menuCategoriesData?.menuCategories?.currentMiddle}
                  hovering={secondCatItem.uid === secondCatHovering?.uid}
                  href={`/${secondCatItem.canonicalUrl}`}
                  key={secondCatItem.uid}
                  title={secondCatItem.name}
                  onClick={() => onSelect?.(`/${secondCatItem.canonicalUrl}`)} />
              </For>
            </MenuContainer>
          </For>
        </CatWrapper>
      </If>
      <If condition={!!thirdCatItems.length}>
        <Seperator align='vertical' color={theme.colors.grey.pampas} />
        <CatWrapper>
          <Heading className='menu-heading' variant='h4' color={theme.colors.green.watercourse} >{secondCatHovering?.name}</Heading>
          <For each='thirdCatItemsGroup' index='thirdCatItemsIndex' of={thirdCatItems}>
            <MenuContainer key={thirdCatItemsIndex}>
              <For each='thirdCatItem' of={thirdCatItemsGroup}>
                <DesktopCategoryMenuItem
                  onMouseEnter={() => _handleMouseEnterThirdCat(thirdCatItem)}
                  active={thirdCatItem.uid === menuCategoriesData?.menuCategories?.currentBottom}
                  hovering={thirdCatItem.uid === thirdCatHovering?.uid}
                  href={`/${thirdCatItem.canonicalUrl}`}
                  key={thirdCatItem.uid}
                  title={thirdCatItem.name}
                  onClick={() => onSelect?.(`/${thirdCatItem.canonicalUrl}`)} />
              </For>
            </MenuContainer>
          </For>
        </CatWrapper>
      </If>

      <Seperator align='vertical' color={theme.colors.grey.pampas} />
    </Container>
  )

}
