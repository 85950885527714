import { RefObject, useEffect } from 'react'

import { useNavigate } from 'react-router-dom'

export function useLinkClickHandlers(ref: RefObject<HTMLDivElement>) {
  const navigate = useNavigate()

  function _handleLinkClick(event: MouseEvent) {
    const link = event.currentTarget as HTMLAnchorElement
    const href = link.getAttribute('href')
    const target = link.getAttribute('target')
    const url = new URL(href || '', window.location.origin)

    const isInternalLink = url.origin === window.location.origin
    const isOpenedInSameWindow = !target || target === '_self'
    const isLeftButtonClick = event.button === 0

    // E.g. Ctrl-clicking a link opens it in a new tab
    // so let the browser handle such clicks
    const isModifierKeyPressed =
      event.altKey || event.ctrlKey || event.metaKey || event.shiftKey

    if (
      isInternalLink &&
      isOpenedInSameWindow &&
      isLeftButtonClick &&
      !isModifierKeyPressed
    ) {
      event.preventDefault()
      navigate(url.pathname + url.search + url.hash)
    }
  }

  useEffect(() => {
    if (!ref.current) return

    const links = ref.current.querySelectorAll('a')
    links.forEach((link) => link.addEventListener('click', _handleLinkClick))

    return () => {
      links.forEach((link) =>
        link.removeEventListener('click', _handleLinkClick),
      )
    }
  }, [navigate, ref])

}
