import React from 'react'

import loadable from '@loadable/component'

import(/* webpackPrefetch: true */ '@pages/MyFtnShop')

const MyFtnShop = loadable(() => import(/* webpackChunkName: "myFtnShop" */'@pages/MyFtnShop'), {
  resolveComponent: (components) => components.MyFtnShop,
})

export function MyFtnShopAsync(): JSX.Element {

  return <MyFtnShop fallback={<div>Loading...</div>} />

}
