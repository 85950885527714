import React from 'react'

import { loadableReady } from '@loadable/component'

import { createRoot, hydrateRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'

import { Config } from '@lib/Config'
import { UserEvents } from '@lib/UserEvents'
// import * as Sentry from '@sentry/react'
import { ScrollToTop } from '@utility/ScrollToTop'

import { App } from './App'
import { configure } from './configure'

(async (): Promise<void> => {
  const config = new Config()
  const regex = new RegExp(`^${config.getApiBaseUrl()}`)

  // if (process.env.ENVIRONMENT === 'production') {
  //   Sentry.init({
  //     dsn: 'https://58d9d814533f2065c3929cbcc375dbf1@o4506874738049024.ingest.us.sentry.io/4506896380723200',
  //     integrations: [
  //       Sentry.browserTracingIntegration(),
  //       Sentry.replayIntegration({
  //         maskAllText: false,
  //         blockAllMedia: false,
  //       }),
  //     ],
  //     // Performance Monitoring
  //     tracesSampleRate: 1.0, //  Capture 100% of the transactions
  //     // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  //     tracePropagationTargets: ['localhost', regex],
  //     // Session Replay
  //     replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  //     replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  //   })
  // }

  const events = new UserEvents()
  await configure(config, events)
  const client = await config.getClient()

  if (config.ssr()) {
    loadableReady(() => {
      hydrateRoot(document.getElementById('react-app'), (
        <BrowserRouter>
          <ScrollToTop>
            <App client={client} config={config} events={events} />
          </ScrollToTop>
        </BrowserRouter>
      ))
    })
  } else {
    loadableReady(() => {
      const root = createRoot(document.getElementById('react-app'))
      root.render((
        <BrowserRouter>
          <ScrollToTop>
            <App client={client} config={config} events={events} />
          </ScrollToTop>
        </BrowserRouter>
      ))
    })
  }
})()
