
import React from 'react'
import { Svg } from './Svg'

export interface OzowProps {
  color?: string
  hoverColor?: string
  className?: string
}

export function Ozow({ color, hoverColor, className }: OzowProps): JSX.Element {

  return (
    <Svg strokeColor={color} strokeHoverColor={hoverColor} fillColor={color} fillHoverColor={hoverColor} className={className} width="100%" height="100%" viewBox="0 0 20 8" version="1.1">
      <g id="Group_678" transform="matrix(1,0,0,1,-322.332,-3316.36)">
        <g id="Path_713" transform="matrix(0.263042,0,0,0.263042,244.917,2446.67)">
          <path className='fill' d="M365.5,3315.14C364.391,3315.14 364.087,3315.36 363.741,3316.42C363.33,3317.69 362.926,3318.95 362.515,3320.22C362.445,3320.43 362.355,3320.64 362.225,3320.98C361.682,3319.35 361.192,3317.88 360.699,3316.41C360.272,3315.13 360.27,3315.16 358.924,3315.12C358.384,3315.11 358.197,3315.31 358.046,3315.79C357.522,3317.46 356.946,3319.12 356.392,3320.78L356.251,3320.77C356.177,3320.56 356.099,3320.36 356.031,3320.15C355.608,3318.84 355.194,3317.53 354.762,3316.23C354.625,3315.53 353.966,3315.05 353.262,3315.14C352.991,3315.14 352.718,3315.16 352.449,3315.13C351.949,3315.08 351.896,3315.25 352.049,3315.7C352.887,3318.15 353.693,3320.61 354.512,3323.07C355.097,3324.82 355.097,3324.81 356.923,3324.77C357.215,3324.81 357.488,3324.61 357.538,3324.32C358.016,3322.84 358.523,3321.37 359.023,3319.9C359.087,3319.71 359.167,3319.53 359.305,3319.18C359.796,3320.64 360.221,3321.9 360.64,3323.15C361.182,3324.78 361.18,3324.77 362.916,3324.78C362.952,3324.78 362.988,3324.78 363.024,3324.78C363.345,3324.78 363.622,3324.55 363.672,3324.23C364.531,3321.58 365.43,3318.93 366.315,3316.28C366.7,3315.13 366.7,3315.13 365.5,3315.14Z" />
        </g>
        <g id="Path_714" transform="matrix(0.263042,0,0,0.263042,244.917,2446.67)">
          <path className='fill' d="M346.711,3314.89C346.689,3314.89 346.667,3314.89 346.645,3314.89C343.871,3314.89 341.588,3317.17 341.588,3319.95C341.588,3322.72 343.871,3325.01 346.645,3325.01C349.406,3325.01 351.683,3322.74 351.702,3319.98C351.706,3317.22 349.467,3314.94 346.711,3314.89ZM346.636,3322.81C346.632,3322.81 346.629,3322.81 346.625,3322.81C345.052,3322.81 343.757,3321.51 343.757,3319.94C343.757,3318.37 345.045,3317.08 346.614,3317.07C346.618,3317.07 346.621,3317.07 346.625,3317.07C348.198,3317.07 349.493,3318.37 349.493,3319.94C349.493,3321.51 348.205,3322.8 346.636,3322.81Z" />
        </g>
        <g id="Path_715" transform="matrix(0.263042,0,0,0.263042,244.917,2446.67)">
          <path className='fill' d="M326.623,3314.89C326.619,3314.89 326.614,3314.89 326.61,3314.89C323.836,3314.89 321.553,3317.17 321.553,3319.95C321.553,3322.72 323.836,3325 326.61,3325C329.384,3325 331.667,3322.72 331.667,3319.95C331.667,3319.94 331.667,3319.93 331.667,3319.92C331.662,3317.16 329.385,3314.89 326.623,3314.89ZM326.568,3322.81C325.01,3322.79 323.74,3321.5 323.74,3319.94C323.74,3318.37 325.035,3317.07 326.609,3317.07C328.183,3317.07 329.478,3318.37 329.478,3319.94C329.478,3319.95 329.478,3319.96 329.478,3319.97C329.468,3321.53 328.183,3322.81 326.624,3322.81C326.605,3322.81 326.587,3322.81 326.568,3322.81Z" />
        </g>
        <g id="Path_716" transform="matrix(0.263042,0,0,0.263042,244.917,2446.67)">
          <path className='fill' d="M339.721,3322.65C338.57,3322.64 337.421,3322.65 336.268,3322.65C336.08,3322.63 335.893,3322.61 335.709,3322.57C337.288,3320.87 338.753,3319.21 340.309,3317.64C340.881,3317.1 341.139,3316.3 340.994,3315.52C340.939,3315.34 340.797,3315.2 340.617,3315.15C338.017,3315.13 335.426,3315.14 332.83,3315.15C332.688,3315.15 332.556,3315.22 332.474,3315.34C332.39,3315.51 332.347,3315.7 332.347,3315.9C332.347,3316.62 332.939,3317.21 333.658,3317.21C333.701,3317.21 333.744,3317.21 333.787,3317.2L337.581,3317.2C337.411,3317.43 337.339,3317.54 337.253,3317.63C335.779,3319.31 334.33,3321.01 332.817,3322.65C332.257,3323.26 332.524,3323.92 332.482,3324.56C332.482,3324.62 332.711,3324.74 332.835,3324.75C335.43,3324.76 338.026,3324.76 340.622,3324.75C340.765,3324.74 340.898,3324.67 340.984,3324.56C341.082,3324.37 341.133,3324.16 341.133,3323.95C341.133,3323.24 340.544,3322.65 339.828,3322.65C339.792,3322.65 339.757,3322.65 339.721,3322.65Z" />
        </g>
        <g id="Path_717" transform="matrix(0.263042,0,0,0.263042,244.917,2446.67)">
          <path className='fill' d="M315.594,3324.91C315.176,3324.56 314.715,3324.68 314.21,3325.24L314.076,3325.4C312.568,3327.07 310.426,3328.03 308.176,3328.05C306.177,3328.06 304.238,3327.36 302.705,3326.08C302.413,3325.69 301.959,3325.48 301.698,3325.04C301.488,3324.68 301.514,3324.5 301.803,3324.26C301.976,3324.12 302.135,3323.96 302.277,3323.79C302.498,3323.52 302.654,3323.49 302.877,3323.81C303.194,3324.3 303.611,3324.71 304.1,3325.03C305.604,3326.27 307.61,3326.72 309.5,3326.25C313.535,3325.39 315.814,3320.62 313.861,3317.17C313.553,3316.49 313.122,3315.86 312.588,3315.33C312.438,3315.14 312.21,3315.03 311.97,3315.03C311.749,3315.03 311.538,3315.13 311.388,3315.29C311.202,3315.44 311.094,3315.66 311.094,3315.9C311.094,3316.13 311.192,3316.34 311.361,3316.49C311.809,3316.99 312.195,3317.54 312.511,3318.13C312.848,3319.14 312.896,3320.22 312.649,3321.26C311.987,3323.51 309.768,3324.97 307.437,3324.68C306.641,3324.52 305.88,3324.23 305.187,3323.81C304.526,3323.23 303.992,3322.53 303.617,3321.73C303.499,3321.4 303.183,3321.18 302.831,3321.18C302.577,3321.18 302.337,3321.29 302.179,3321.49C301.368,3322.27 300.579,3323.07 299.791,3323.87C299.446,3324.16 299.383,3324.67 299.649,3325.03C300.096,3325.81 300.677,3326.5 301.365,3327.08C304.018,3329.66 307.936,3330.47 311.397,3329.17C312.987,3328.63 314.399,3327.67 315.477,3326.38C315.99,3325.78 316.028,3325.27 315.594,3324.91Z" />
        </g>
        <g id="Path_718" transform="matrix(0.263042,0,0,0.263042,244.917,2446.67)">
          <path className='fill' d="M303.748,3317.41C305.221,3315.83 306.869,3314.86 309.121,3315.28C309.181,3315.29 309.242,3315.3 309.303,3315.3C309.694,3315.3 310.022,3314.99 310.051,3314.61C310.065,3314.55 310.072,3314.48 310.072,3314.42C310.072,3314.01 309.765,3313.67 309.36,3313.62C308.932,3313.53 308.496,3313.49 308.06,3313.49C306.334,3313.47 304.669,3314.14 303.439,3315.35C301.807,3316.93 300.216,3318.56 298.606,3320.17C298.228,3320.51 298.055,3321.02 298.152,3321.52C298.21,3321.81 298.152,3322.2 298.545,3322.31C298.898,3322.42 299.07,3322.12 299.27,3321.92C300.767,3320.43 302.3,3318.96 303.748,3317.41Z" />
        </g>
        <g id="Path_719" transform="matrix(0.263042,0,0,0.263042,244.917,2446.67)">
          <path className='fill' d="M307.762,3316.84C306.424,3316.97 305.31,3317.94 305.017,3319.26C304.7,3320.53 305.263,3321.88 306.397,3322.56C307.608,3323.34 309.211,3323.18 310.244,3322.17C311.241,3321.2 311.456,3319.67 310.764,3318.46C310.196,3317.36 308.999,3316.71 307.762,3316.84ZM306.962,3321.88C307.093,3321.78 307.23,3321.7 307.362,3321.6C307.228,3321.7 307.091,3321.78 306.96,3321.88L306.962,3321.88ZM309.432,3319.51C309.605,3320.75 308.985,3321.4 307.67,3321.36C306.996,3321.13 306.571,3320.46 306.662,3319.76C306.72,3319.15 307.177,3318.66 307.774,3318.56C308.442,3318.39 309,3318.74 309.43,3319.51L309.432,3319.51Z" />
        </g>
        <g id="Path_720" transform="matrix(0.263042,0,0,0.263042,244.917,2446.67)">
          <path className='fill' d="M316.582,3315.14C316.534,3315.02 316.477,3314.9 316.412,3314.78C314.267,3311.31 310.165,3309.52 306.16,3310.32C304.278,3310.64 302.545,3311.55 301.209,3312.92C300.761,3313.36 300.701,3313.87 301.068,3314.22C301.23,3314.39 301.457,3314.49 301.694,3314.49C301.964,3314.49 302.219,3314.36 302.38,3314.15C302.511,3314.02 302.645,3313.9 302.78,3313.79C304.663,3312.14 307.217,3311.48 309.661,3312.01C311.991,3312.47 314.01,3313.93 315.191,3315.99C315.638,3316.89 315.951,3317.86 316.123,3318.85C316.124,3319.29 316.485,3319.65 316.923,3319.65C316.98,3319.65 317.036,3319.64 317.092,3319.63C317.678,3319.55 317.858,3319.13 317.783,3318.6C317.648,3317.37 317.237,3316.19 316.582,3315.14Z" />
        </g>
      </g>
    </Svg>
  )

}
    