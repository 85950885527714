import React, { useState } from 'react'

import update from 'react-addons-update'
import styled from 'styled-components'

import { Icon, LocalIconEnums } from '@atoms/images'
import { Title } from '@atoms/typography'
import { ResponsivePXValue } from '@components/Theme'

const Container = styled.div``

const Header = styled.div<{ $backgroundColor: string }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${ResponsivePXValue('padding', '0 20px')}
  ${ResponsivePXValue('height', '44px')}
  background-color: ${(props): string => props.$backgroundColor};
  cursor: pointer;
  .drop-down-title {
    margin: 0;
  }
`

const IconContainer = styled.div`
  cursor: pointer;
  ${ResponsivePXValue('width', '20px')}
  ${ResponsivePXValue('width', '20px')}
`

const ContentContainer = styled.div`
  ${ResponsivePXValue('padding', '20px')}
  background-color: ${(props): string => props.theme.colors.white.pureWhite};
`

export interface DropDownContainerProps {
  title: string
  titleColor: string
  headerColor: string
  children: React.ReactNode
  className?: string
  contentClassName?: string
  onClick?: () => void
}

interface DropDownContainerState {
  open: boolean
}

const DEFAULT_STATE: DropDownContainerState = {
  open: false,
}

export function DropDownContainer({ title, titleColor, headerColor, children, className, contentClassName, onClick }: DropDownContainerProps): JSX.Element {

  const [state, setState] = useState<DropDownContainerState>({ ...DEFAULT_STATE })

  const _handleClick = () => {
    if (onClick) {
      onClick()
    }
    setState((prevState) => update(prevState, { open: { $set: !prevState.open } }))

  }

  return (
    <Container className={className}>
      <Header
        $backgroundColor={headerColor}
        onClick={_handleClick}>
        <Title variant='t8' color={titleColor} className='drop-down-title'>
          {title}
        </Title>
        <IconContainer>
          <Choose>
            <When condition={state.open}>
              <Icon icon={LocalIconEnums.CHEVRON_UP} color={titleColor} />
            </When>
            <Otherwise>
              <Icon icon={LocalIconEnums.CHEVRON_DOWN} color={titleColor} />
            </Otherwise>
          </Choose>
        </IconContainer>
      </Header>
      <If condition={state.open}>
        <ContentContainer className={contentClassName}>
          {children}
        </ContentContainer>
      </If>
    </Container>
  )

}
