interface ProductAttributeInterface {
  code: string
  label: string
  value: string
}

let instance: AttributeHelper | null = null

export default class AttributeHelper {

  static attributeKeys = [
    'SIZE',
    'COLOUR',
    'FRAGRANCE',
    'QUANTITY',
    'FLAVOUR',
    'AGE',
    'SKIN_TYPE',
    'HAIR_TYPE',
    'SKIN_CONCERN',
    'CONCENTRATION',
  ]

  static shared(): AttributeHelper {

    if (!instance) {
      instance = new AttributeHelper()
    }
    return instance

  }

  getProductAttributes(productAttributes: readonly ProductAttributeInterface[]): ProductAttributeInterface[] {

    const returnAttributes = [] as ProductAttributeInterface[]

    for (let i = 0; i < AttributeHelper.attributeKeys.length; i++) {
      const attribute = productAttributes.find((attribute) => attribute.code === AttributeHelper.attributeKeys[i] && attribute.value !== '')
      if (attribute) {
        returnAttributes.push(attribute)
      }
    }

    return returnAttributes
  }

}
