import React from 'react'

import styled, { useTheme } from 'styled-components'

import { ResponsivePXValue } from '@components/Theme'

const StepElement = styled.div<{ backgroundColor: string, foregroundColor: string, borderColor: string }>`
  display: flex;
  align-items: center;
  justify-content: center;

  ${ResponsivePXValue('padding', { mobile: '0 24px', tablet: '0 24px', desktop: '0 24px' })}
  ${ResponsivePXValue('height', { mobile: '32px', tablet: '40px', desktop: '40px' })}
  ${(props): string => ResponsivePXValue('border', `1px solid ${props.borderColor}`)}

  background-color: ${(props): string => props.backgroundColor};
  color: ${(props): string => props.foregroundColor};

  ${ResponsivePXValue('font-size', { mobile: '10px', tablet: '14px', desktop: '14px' })}
  font-weight: 600;
  font-family: open-sans;
  
`

export interface StepProps {
  children: JSX.Element | JSX.Element[] | string
  active?: boolean
  step: string
  backgroundColor?: string
  foregroundColor?: string
  borderColor?: string
  onClick?: () => void
}

export function Step({ children, active = false, backgroundColor, foregroundColor, borderColor, onClick }: StepProps): JSX.Element {

  return (
    <StepElement
      onClick={onClick}
      className='step'
      backgroundColor={backgroundColor}
      foregroundColor={foregroundColor}
      borderColor={borderColor}>
      {children}
    </StepElement>
  )

}

const Container = styled.div`
  display: flex;
  flex-direction: row-reverse;
  .step:first-child {
    ${ResponsivePXValue('border-radius', { mobile: '0 16px 16px 0', tablet: '0 20px 20px 0', desktop: '0 20px 20px 0' })}
  }
  
`

export interface StepsProps {
  children: JSX.Element | JSX.Element[]
  currentStep: string
  onChange: (step: string) => void
}

export function Steps({ children = [], currentStep, onChange }: StepsProps): JSX.Element {

  const theme = useTheme()

  const colors = {
    activeColors: {
      backgroundColor: theme.colors.green.magicMint,
      foregroundColor: theme.colors.green.greenVogue,
      borderColor: theme.colors.green.magicMint,
    },
    inactiveColors: {
      backgroundColor: theme.colors.white.pureWhite,
      foregroundColor: theme.colors.grey.silver,
      borderColor: theme.colors.grey.gallery,
    },
  }

  const $actualChildren = Array.isArray(children) ? children.flat() : [children]
  const stepIndex = $actualChildren.findIndex(($child) => $child.props.step === currentStep)

  const $children = React.Children.map($actualChildren, ($child, childIndex) => {
    let newProps = {
      ...$child.props,
      onClick: () => onChange($child.props.step),
    }
    if (childIndex === stepIndex) {
      newProps.active = true
    }
    if (childIndex <= stepIndex) {
      newProps = {
        ...newProps,
        ...colors.activeColors,
      }
    } else {
      newProps = {
        ...newProps,
        ...colors.inactiveColors,
      }
    }
    return React.cloneElement($child, newProps)
  })

  return (
    <Container>
      {$children.reverse()}
    </Container>
  )

}
