import React, { } from 'react'

import styled from 'styled-components'

import { Button, ButtonColors } from '@atoms/buttons'
import { Heading, Link, Paragraph } from '@atoms/typography'
import { ResponsivePXValue } from '@components/Theme'
import { PageChipFragment, PageChipSectionFragment } from '@hooks/api/index'
import { HTML } from '@molecules/content'
import { PageChipStyle } from '@uctypes/api/globalTypes'
import { DeviceContainer } from '@utility/DeviceContainer'

const Container = styled.div<{ backgroundColor: string, foregroundColor: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: ${(props): string => props.backgroundColor};
  ${ResponsivePXValue('padding', { mobile: '24px 16px', tablet: '24px 16px', desktop: '40px' })}
  ${ResponsivePXValue('gap', '16px')}

  .block-title {
    ${ResponsivePXValue('margin', { mobile: '0 10px', tablet: '0 10px', desktop: '0' })}
  }

  .block-paragraph {
    ${ResponsivePXValue('margin', { mobile: '0 10px', tablet: '0 10px', desktop: '0' })}
  }
  
  .block-link-paragraph {
    ${ResponsivePXValue('margin-top', '12px')}
  }
  
  .link {
    color: ${(props): string => props.foregroundColor};
    text-decoration: underline;
  }

`

const ChipContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  ${ResponsivePXValue('gap', '8px')}
`

const getButtonColors = (chip: PageChipFragment, style: PageChipStyle): ButtonColors => {

  if (style === PageChipStyle.BORDERED) {
    return {
      color: chip.foregroundColor,
      backgroundColor: chip.backgroundColor,
      borderColor: chip.foregroundColor,
      hoverColor: chip.backgroundColor,
      hoverBackgroundColor: chip.foregroundColor,
      hoverBorderColor: chip.backgroundColor,
      disabledColor: chip.foregroundColor,
      disabledBackgrondColor: chip.foregroundColor,
      disabledBorderColor: chip.foregroundColor,
    }
  }
  return {
    color: chip.foregroundColor,
    backgroundColor: chip.backgroundColor,
    borderColor: chip.backgroundColor,
    hoverColor: chip.backgroundColor,
    hoverBackgroundColor: chip.foregroundColor,
    hoverBorderColor: chip.foregroundColor,
    disabledColor: chip.foregroundColor,
    disabledBackgrondColor: chip.foregroundColor,
    disabledBorderColor: chip.foregroundColor,
  }
}

export interface PageChipSectionProps {
  section: PageChipSectionFragment
}

export function PageChipSection({ section }: PageChipSectionProps): JSX.Element {

  let chip!: PageChipFragment

  return (
    <Container backgroundColor={section.backgroundColor} foregroundColor={section.foregroundColor}>
      <If condition={!!section.title}>
        <DeviceContainer mobile tablet>
          <Heading variant='h3' align='center' className='block-title' color={section.foregroundColor}>
            {section.title}
          </Heading>  
        </DeviceContainer>
        <DeviceContainer desktop>
          <Heading variant='h1' align='center' className='block-title' color={section.foregroundColor}>
            {section.title}
          </Heading>
        </DeviceContainer>
      </If>
      <If condition={!!section.description}>
        <HTML align='center' className='block-paragraph' color={section.foregroundColor}>
          {section.description}
        </HTML>
      </If>
      <ChipContainer>
        <For each='chip' of={section.chips}>
          <Button title={chip.title} colors={getButtonColors(chip, section.chipStyle)} href={chip.url} key={chip.id} />
        </For>
      </ChipContainer>
      <If condition={!!section.link}>
        <Paragraph align='center' className='block-link-paragraph'>
          <Link className='link' href={section.link.url} color={section.foregroundColor} hoverColor={section.foregroundColor}>
            {section.link.title}
          </Link>
        </Paragraph>
      </If>
    </Container>
  )

}
