
import React from 'react'
import { Svg } from './Svg'

export interface DesktopLogoProps {
  color?: string
  hoverColor?: string
  className?: string
}

export function DesktopLogo({ color, hoverColor, className }: DesktopLogoProps): JSX.Element {

  return (
    <Svg strokeColor={color} strokeHoverColor={hoverColor} fillColor={color} fillHoverColor={hoverColor} className={className} width="100%" height="100%" viewBox="0 0 20 4" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" space="preserve" serif="http://www.serif.com/" style={{fillRule:"evenodd",clipRule:"evenodd",strokeLinejoin:"round",strokeMiterlimit:"2"}}>
      <g id="Group_646" transform="matrix(1,0,0,1,-180.564,-86.0375)">
        <g id="Path_670" transform="matrix(0.0947987,0,0,0.0947987,172.502,79.6028)">
          <path d="M107.959,93.759C107.918,92.809 108.289,91.886 108.977,91.229C109.728,90.561 110.672,90.147 111.672,90.047L114.977,89.564C115.604,89.48 115.917,89.175 115.918,88.649C115.938,88.053 115.696,87.476 115.256,87.073C114.696,86.627 113.987,86.408 113.273,86.463C112.566,86.425 111.871,86.667 111.341,87.136C110.854,87.612 110.555,88.249 110.502,88.928L108.213,88.395C108.313,87.257 108.896,86.215 109.813,85.534C110.789,84.788 111.991,84.397 113.219,84.428C114.615,84.313 116.001,84.767 117.058,85.687C117.896,86.543 118.346,87.706 118.304,88.903L118.304,95.031C118.3,95.703 118.351,96.375 118.457,97.039L116.118,97.039C116.03,96.493 115.988,95.94 115.991,95.387C115.131,96.713 113.629,97.488 112.05,97.42C110.963,97.463 109.903,97.075 109.101,96.34C108.367,95.684 107.949,94.743 107.956,93.759M112.38,95.412C113.314,95.459 114.232,95.15 114.948,94.547C115.652,93.815 116.005,92.813 115.915,91.802L115.915,91.242L112.177,91.802C111.694,91.858 111.24,92.062 110.877,92.386C110.546,92.713 110.37,93.167 110.394,93.632C110.393,94.105 110.592,94.558 110.941,94.878C111.328,95.243 111.846,95.435 112.377,95.412" className='fill' />
        </g>
        <g id="Path_671" transform="matrix(0.0947987,0,0,0.0947987,172.502,79.6028)">
          <path d="M121.472,81.39C121.146,81.074 120.965,80.636 120.972,80.182C120.966,79.725 121.147,79.284 121.472,78.962C121.786,78.631 122.224,78.447 122.68,78.454C123.137,78.447 123.578,78.626 123.901,78.949C124.235,79.271 124.42,79.718 124.409,80.182C124.416,80.638 124.232,81.076 123.901,81.39C123.579,81.715 123.138,81.896 122.68,81.89C122.226,81.898 121.788,81.716 121.472,81.39M123.872,97.039L121.51,97.039L121.51,84.81L123.875,84.81L123.872,97.039Z" className='fill' />
        </g>
        <g id="Path_672" transform="matrix(0.0947987,0,0,0.0947987,172.502,79.6028)">
          <path d="M130.638,80.945L130.638,84.81L133.282,84.81L133.282,86.971L130.638,86.971L130.638,93.327C130.623,93.41 130.616,93.495 130.616,93.58C130.616,94.376 131.271,95.031 132.067,95.031C132.158,95.031 132.249,95.022 132.338,95.005C132.654,95.01 132.97,94.976 133.278,94.905L133.278,96.939C132.753,97.109 132.202,97.186 131.651,97.167C130.731,97.216 129.83,96.885 129.16,96.252C128.517,95.557 128.186,94.629 128.244,93.684L128.244,86.971L125.905,86.971L125.905,84.81L126.566,84.81C127.09,84.85 127.606,84.659 127.977,84.288C128.301,83.911 128.47,83.425 128.447,82.928L128.447,80.945L130.638,80.945Z" className='fill' />
        </g>
        <g id="Path_673" transform="matrix(0.0947987,0,0,0.0947987,172.502,79.6028)">
          <path d="M138.341,89.844L138.341,97.039L135.951,97.039L135.951,78.632L138.341,78.632L138.341,86.232C139.182,85.033 140.591,84.357 142.053,84.452C143.283,84.377 144.48,84.875 145.295,85.799C146.079,86.786 146.481,88.024 146.427,89.283L146.427,97.037L144.036,97.037L144.036,89.691C144.036,87.64 143.087,86.614 141.189,86.615C140.4,86.574 139.636,86.908 139.13,87.515C138.616,88.181 138.338,89 138.341,89.841" className='fill' />
        </g>
        <g id="Path_674" transform="matrix(0.0947987,0,0,0.0947987,172.502,79.6028)">
          <path d="M155.884,86.971L152.935,86.971L152.935,97.039L150.469,97.039L150.469,86.971L148.206,86.971L148.206,84.81L150.469,84.81L150.469,82.75C150.405,81.611 150.82,80.495 151.613,79.674C152.379,78.927 153.417,78.522 154.486,78.555C154.975,78.504 155.468,78.592 155.909,78.81L155.909,80.945C155.574,80.831 155.221,80.779 154.868,80.793C154.373,80.782 153.89,80.949 153.507,81.263C153.083,81.687 152.872,82.281 152.935,82.878L152.935,84.81L155.884,84.81L155.884,86.971Z" className='fill' />
        </g>
        <g id="Path_675" transform="matrix(0.0947987,0,0,0.0947987,172.502,79.6028)">
          <path d="M166,95.564C165.676,96.177 165.157,96.664 164.526,96.95C163.858,97.265 163.128,97.426 162.39,97.421C161.13,97.475 159.909,96.967 159.059,96.035C158.211,95.063 157.765,93.802 157.813,92.513L157.813,84.813L160.203,84.813L160.203,92.161C160.17,92.969 160.408,93.765 160.877,94.423C161.388,95.045 162.174,95.378 162.977,95.313C163.788,95.369 164.583,95.056 165.138,94.461C165.655,93.819 165.917,93.009 165.875,92.186L165.875,84.81L168.265,84.81L168.265,94.776C168.263,95.532 168.305,96.288 168.392,97.039L166.1,97.039C166.024,96.551 165.991,96.058 166,95.564" className='fill' />
        </g>
        <g transform="matrix(1,0,0,1,180.564,86.0375)">
          <g id="Rectangle_363">
            <path d="M8.227,1.02L8.227,2.764L8.446,2.77L8.45,1.016L8.227,1.02Z" className='fill' />
          </g>
        </g>
        <g id="Path_676" transform="matrix(0.0947987,0,0,0.0947987,172.502,79.6028)">
          <path d="M186.826,80.945L186.826,84.81L189.47,84.81L189.47,86.971L186.826,86.971L186.826,93.327C186.812,93.409 186.805,93.492 186.805,93.575C186.805,94.371 187.46,95.026 188.256,95.026C188.348,95.026 188.44,95.017 188.53,95C188.846,95.005 189.162,94.971 189.47,94.9L189.47,96.934C188.945,97.104 188.394,97.181 187.843,97.162C186.922,97.211 186.021,96.88 185.351,96.247C184.709,95.552 184.378,94.624 184.436,93.679L184.436,86.971L182.1,86.971L182.1,84.81L182.761,84.81C183.285,84.85 183.801,84.659 184.172,84.288C184.496,83.911 184.664,83.424 184.642,82.928L184.642,80.945L186.826,80.945Z" className='fill' />
        </g>
        <g id="Path_677" transform="matrix(0.0947987,0,0,0.0947987,172.502,79.6028)">
          <path d="M192.89,86.272C195.36,83.829 199.395,83.829 201.865,86.272C204.205,88.914 204.211,92.929 201.877,95.577C199.396,98.019 195.358,98.019 192.877,95.577C190.543,92.929 190.549,88.913 192.89,86.272M194.669,94.128C195.376,94.867 196.355,95.285 197.377,95.285C198.399,95.285 199.378,94.867 200.085,94.128C201.555,92.253 201.555,89.596 200.085,87.721C199.378,86.982 198.399,86.564 197.377,86.564C196.355,86.564 195.376,86.982 194.669,87.721C193.199,89.596 193.199,92.253 194.669,94.128" className='fill' />
        </g>
        <g id="Path_678" transform="matrix(0.0947987,0,0,0.0947987,172.502,79.6028)">
          <path d="M227.1,97.039L224.507,97.039L214.769,82.339L214.769,97.039L212.278,97.039L212.278,79.013L215.608,79.013L224.608,92.844L224.608,79.013L227.1,79.013L227.1,97.039Z" className='fill' />
        </g>
        <g id="Path_679" transform="matrix(0.0947987,0,0,0.0947987,172.502,79.6028)">
          <path d="M230.149,93.759C230.108,92.809 230.479,91.886 231.166,91.229C231.917,90.561 232.861,90.147 233.861,90.047L237.166,89.564C237.793,89.48 238.106,89.175 238.107,88.649C238.127,88.053 237.885,87.477 237.446,87.073C236.886,86.627 236.177,86.408 235.463,86.463C234.756,86.425 234.061,86.667 233.53,87.136C233.043,87.612 232.745,88.249 232.691,88.928L230.403,88.395C230.503,87.257 231.086,86.215 232.003,85.534C232.979,84.788 234.182,84.397 235.41,84.428C236.806,84.313 238.192,84.767 239.249,85.687C240.087,86.542 240.54,87.704 240.5,88.9L240.5,95.028C240.496,95.7 240.547,96.372 240.653,97.036L238.313,97.036C238.225,96.49 238.183,95.937 238.186,95.384C237.327,96.711 235.824,97.486 234.245,97.417C233.158,97.46 232.098,97.072 231.296,96.337C230.562,95.681 230.145,94.74 230.152,93.756M234.576,95.409C235.51,95.455 236.428,95.146 237.144,94.544C237.847,93.812 238.2,92.81 238.11,91.799L238.11,91.239L234.372,91.799C233.889,91.855 233.435,92.059 233.072,92.383C232.741,92.71 232.565,93.164 232.589,93.629C232.588,94.102 232.786,94.555 233.135,94.875C233.522,95.24 234.041,95.433 234.572,95.409" className='fill' />
        </g>
        <g id="Path_680" transform="matrix(0.0947987,0,0,0.0947987,172.502,79.6028)">
          <path d="M246.9,80.945L246.9,84.81L249.544,84.81L249.544,86.971L246.9,86.971L246.9,93.327C246.885,93.41 246.878,93.495 246.878,93.58C246.878,94.376 247.533,95.031 248.329,95.031C248.42,95.031 248.511,95.022 248.6,95.005C248.916,95.01 249.232,94.976 249.54,94.905L249.54,96.939C249.015,97.109 248.464,97.186 247.913,97.167C246.992,97.216 246.091,96.885 245.421,96.252C244.779,95.557 244.448,94.629 244.506,93.684L244.506,86.971L242.167,86.971L242.167,84.81L242.828,84.81C243.352,84.85 243.868,84.659 244.239,84.288C244.563,83.911 244.731,83.424 244.709,82.928L244.709,80.945L246.9,80.945Z" className='fill' />
        </g>
        <g id="Path_681" transform="matrix(0.0947987,0,0,0.0947987,172.502,79.6028)">
          <path d="M260.277,95.564C259.952,96.177 259.432,96.665 258.8,96.95C258.132,97.265 257.402,97.426 256.664,97.421C255.404,97.475 254.183,96.967 253.333,96.035C252.485,95.063 252.039,93.802 252.087,92.513L252.087,84.813L254.477,84.813L254.477,92.161C254.444,92.969 254.682,93.765 255.151,94.423C255.662,95.045 256.448,95.378 257.251,95.313C258.062,95.369 258.857,95.056 259.412,94.461C259.929,93.819 260.191,93.009 260.149,92.186L260.149,84.81L262.539,84.81L262.539,94.776C262.537,95.532 262.579,96.288 262.666,97.039L260.377,97.039C260.302,96.551 260.268,96.058 260.277,95.564" className='fill' />
        </g>
        <g id="Path_682" transform="matrix(0.0947987,0,0,0.0947987,172.502,79.6028)">
          <path d="M272.888,84.682L272.888,87.225C272.543,87.175 272.194,87.15 271.845,87.149C269.608,87.149 268.49,88.403 268.489,90.912L268.489,97.039L266.1,97.039L266.1,84.81L268.439,84.81L268.439,86.946C269.045,85.478 270.513,84.54 272.1,84.607C272.365,84.606 272.629,84.632 272.889,84.682" className='fill' />
        </g>
        <g id="Path_683" transform="matrix(0.0947987,0,0,0.0947987,172.502,79.6028)">
          <path d="M283.515,92.844L285.575,93.556C285.231,94.674 284.535,95.65 283.591,96.34C282.573,97.076 281.339,97.456 280.083,97.42C278.45,97.452 276.873,96.814 275.722,95.654C274.49,94.382 273.841,92.654 273.93,90.886C273.872,89.176 274.494,87.511 275.659,86.259C276.768,85.09 278.311,84.426 279.923,84.426C281.491,84.426 282.997,85.055 284.1,86.17C285.183,87.475 285.737,89.142 285.65,90.836C285.653,91.091 285.637,91.346 285.6,91.598L276.4,91.598C276.382,92.597 276.772,93.562 277.48,94.268C278.17,94.957 279.112,95.334 280.087,95.31C281.669,95.422 283.12,94.379 283.519,92.844M276.476,89.666L283.163,89.666C283.163,89.647 283.164,89.627 283.164,89.608C283.164,87.917 281.773,86.527 280.083,86.527C279.991,86.527 279.899,86.531 279.807,86.539C278.933,86.509 278.084,86.846 277.468,87.467C276.874,88.05 276.52,88.836 276.476,89.667" className='fill' />
        </g>
        <g id="Path_684" transform="matrix(0.0947987,0,0,0.0947987,172.502,79.6028)">
          <path d="M97.967,97.039L95.477,97.039L95.477,79.013L106.435,79.013L106.435,81.326L97.967,81.326L97.967,87.126L105.645,87.126L105.645,89.465L97.967,89.465L97.967,97.039Z" className='fill' />
        </g>
      </g>
    </Svg>
  )

}
    