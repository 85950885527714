import React, { useState, useRef } from 'react'

import update from 'react-addons-update'
import styled from 'styled-components'

import { Icon, LocalIconEnums } from '@atoms/images'
import { Heading, Paragraph } from '@atoms/typography'
import { ResponsivePXValue, theme } from '@components/Theme'
import { PageAccordionSectionFragment } from '@hooks/api/index'
import { HTML } from '@molecules/content'

type PageAccordionSectionFragmentQuestions = PageAccordionSectionFragment['questions'][0]

const SectionContainer = styled.div`
  ${ResponsivePXValue('width', { mobile: '288px', tablet: '920px', desktop: '920px' })}
  width: 100%;
  height: fit-content;
`
const SectionTitleContainer = styled.div<{ open: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  ${ResponsivePXValue('gap', '8px')}
  ${ResponsivePXValue('padding', { mobile: '12px 0px', tablet: '9px 0px', desktop: '9px 0px' })}
  ${ResponsivePXValue('margin', { mobile: '0px 24px', tablet: '0px 16px', desktop: '0px 16px' })}
  cursor: pointer;
  border-bottom: 1px solid ${(props) => props.theme.colors.grey.athens};
  
  .title {
    ${ResponsivePXValue('max-width', { mobile: '220px' })}
    font-weight: 600
  }
`
const IconContainer = styled.div`
  ${ResponsivePXValue('cursor', { mobile: 'default', tablet: 'default', desktop: 'pointer' })}
  ${ResponsivePXValue('max-width', { mobile: '12px', tablet: '32px', desktop: '32px' })}
  ${ResponsivePXValue('max-height', { mobile: '12px', tablet: '32px', desktop: '32px' })}
`
const SectionContent = styled.div<{ open: boolean }>`
  ${ResponsivePXValue('margin-bottom', { mobile: '8px', tablet: '0px', desktop: '0px' })}
  max-height: ${({ open }): string => open ? '4000px' : '0px'};
  transition: max-height 0.3s ease-in-out;
  overflow: hidden;
  div {
    ${ResponsivePXValue('padding', { mobile: '12px 16px', tablet: '16px', desktop: '16px' })}
  }
`
interface AccordionSectionState {
  open: boolean
}
const DEFAULT_STATE: AccordionSectionState = {
  open: false,
}
export interface AccordionSectionProps {
  title: string
  content: string
}
export function AccordionSection({ title, content }: AccordionSectionProps): JSX.Element {

  const [state, setState] = useState<AccordionSectionState>({ ...DEFAULT_STATE })

  const ref: React.MutableRefObject<HTMLDivElement> = useRef(null)

  const _handleToggleOpen = (): void => {
    state.open === false &&
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' })

    setState((prevState) => update(prevState, { open: { $set: !prevState.open } }))
  }

  return (
    <SectionContainer>
      <SectionTitleContainer onClick={_handleToggleOpen} ref={ref} open={state.open}>
        <Paragraph
          variant='p1'
          color={theme.colors.green.bottleGreen}
          className='title'
        >
          {title}
        </Paragraph>
        <IconContainer>
          <Choose>
            <When condition={!!state.open}>
              <Icon icon={LocalIconEnums.CHEVRON_UP} color={theme.colors.green.greenVogue} />
            </When>
            <Otherwise>
              <Icon icon={LocalIconEnums.CHEVRON_DOWN} color={theme.colors.green.greenVogue} />
            </Otherwise>
          </Choose>
        </IconContainer>
      </SectionTitleContainer>
      <SectionContent open={state.open}>
        <HTML color={theme.colors.green.bottleGreen}>
          {content}
        </HTML>
      </SectionContent>
    </SectionContainer>
  )
}

const AccordionContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${ResponsivePXValue('margin-bottom', '20px')}
  background-color: ${({ theme }) => theme.colors.white.pureWhite};
  ${ResponsivePXValue('margin', { mobile: '0px 16px', tablet: '', desktop: '' })}
`
const AccordionHeading = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  ${ResponsivePXValue('gap', '8px')}
  ${ResponsivePXValue('padding', { mobile: '24px 16px 12px', tablet: '12px 16px', desktop: '12px 16px' })}
  ${ResponsivePXValue('width', { mobile: '288px', tablet: '920px', desktop: '920px' })}
`
export interface AccordionProps {
  title: string
  content: PageAccordionSectionFragmentQuestions
}

export function Accordion({ title, content }: AccordionProps): JSX.Element {

  let questionIndex!: number

  return (
    <>
      <AccordionContainer>
        <If condition={!!title}>
          <AccordionHeading>
            <Heading
              color={theme.colors.green.bottleGreen}
              variant='h3'
              align='left'
              transform='capitalize'>
              {title}
            </Heading>
          </AccordionHeading>
        </If>
        <For each="question" index='questionIndex' of={content.questions}>
          <AccordionSection
            key={questionIndex}
            content={content.questions[questionIndex].answer}
            title={content.questions[questionIndex].question}
          />
        </For>
      </AccordionContainer>
    </>
  )
}
