import React, { useEffect } from 'react'

import styled, { useTheme } from 'styled-components'

import { Button, ButtonColors } from '@atoms/buttons'
import { Icon, LocalIconEnums } from '@atoms/images'
import { ResponsivePXValue } from '@components/Theme'
import { ProductDetailsFragment } from '@hooks/api/index'
import { Modal } from '@molecules/content'
import { Form, TextInput, useForm } from '@molecules/inputs'
import { useSimpleToasts } from '@simple/toasts'

const Container = styled.div``

const IconsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  ${ResponsivePXValue('margin', '40px 20px')}
`

const IconContainer = styled.div`
  ${ResponsivePXValue('width', '80px')}
  ${ResponsivePXValue('height', '80px')}
  ${ResponsivePXValue('border-radius', '40px')}
  ${ResponsivePXValue('padding', '10px')}
  ${(props): string => ResponsivePXValue('border', `2px solid ${props.theme.colors.green.bottleGreen}`)}
`

const FormContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  ${ResponsivePXValue('gap', '5px')}
  ${ResponsivePXValue('padding', '0 20px 20px')}
  .input {
    flex-grow: 1;
  }
  .button {
    flex-shrink: 0;
  }
`

export interface ProductShareModalProps {
  product: ProductDetailsFragment
  open: boolean
  onClose: () => void
}

export function ProductShareModal({ product, open, onClose }: ProductShareModalProps): JSX.Element {

  const { addToast } = useSimpleToasts()
  const theme = useTheme()
  const form = useForm()

  const _handleCopy = (): void => {
    navigator?.clipboard?.writeText(window.location.href)
    addToast({
      message: 'Link copied to clibpoard',
      appearance: 'info',
    })
  }

  const copyButtonColors: ButtonColors = {
    color: theme.colors.white.pureWhite,
    backgroundColor: theme.colors.green.bottleGreen,
    borderColor: theme.colors.green.bottleGreen,
    hoverColor: theme.colors.green.bottleGreen,
    hoverBackgroundColor: theme.colors.yellow.cornSilk,
    hoverBorderColor: theme.colors.green.bottleGreen,
  }

  useEffect(() => {
    if (product && form) {
      form.setFieldsValue({ link: `${window.location.href}` })
    }
  }, [product, form])

  return (
    <Modal open={open} title='Share Product' onClose={onClose}>
      <Container>
        <IconsContainer>
          <IconContainer>
            <Icon icon={LocalIconEnums.FACEBOOK} color={theme.colors.green.bottleGreen} />
          </IconContainer>
          <IconContainer>
            <Icon icon={LocalIconEnums.TWITTER} color={theme.colors.green.bottleGreen} />
          </IconContainer>
        </IconsContainer>
        <Form form={form}>
          <FormContainer>
            <TextInput name='link' showLabel={false} wrapperClassName='input' />
            <Button title='COPY' className='button' colors={copyButtonColors} onClick={_handleCopy} />
          </FormContainer>
        </Form>
      </Container>
    </Modal>
  )

}
