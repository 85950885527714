
import React from 'react'
import { Svg } from './Svg'

export interface AeProps {
  color?: string
  hoverColor?: string
  className?: string
}

export function Ae({ color, hoverColor, className }: AeProps): JSX.Element {

  return (
    <Svg strokeColor={color} strokeHoverColor={hoverColor} fillColor={color} fillHoverColor={hoverColor} className={className} width="100%" height="100%" viewBox="0 0 20 9" version="1.1">
      <g id="layer1" transform="matrix(1,0,0,1,-605.836,-3315.71)">
        <g id="Group_679" transform="matrix(0.377487,0,0,0.377487,383.368,2066.72)">
          <path className='fill' id="path3046" d="M594.378,3312.24L591.97,3317.85L593.538,3317.85L593.982,3316.72L596.565,3316.72L597.007,3317.85L598.607,3317.85L596.201,3312.24L594.378,3312.24ZM595.269,3313.55L596.057,3315.51L594.479,3315.51L595.269,3313.55Z" />
          <path className='fill' id="path3048" d="M598.776,3317.84L598.776,3312.24L601.004,3312.25L602.304,3315.86L603.569,3312.24L605.779,3312.24L605.779,3317.84L604.379,3317.84L604.379,3313.72L602.895,3317.84L601.668,3317.84L600.18,3313.72L600.18,3317.84L598.776,3317.84Z" />
          <path className='fill' id="path3056" d="M622.573,3317.84L622.264,3317.84C620.772,3317.84 619.864,3316.67 619.864,3315.07C619.855,3314.98 619.85,3314.89 619.85,3314.8C619.85,3313.39 621.008,3312.23 622.416,3312.23C622.493,3312.23 622.57,3312.24 622.646,3312.24L624.193,3312.24L624.193,3313.57L622.593,3313.57C621.866,3313.59 621.277,3314.19 621.277,3314.92C621.277,3314.97 621.28,3315.03 621.287,3315.08C621.279,3315.14 621.275,3315.2 621.275,3315.26C621.275,3316.01 621.894,3316.63 622.645,3316.63C622.696,3316.63 622.747,3316.63 622.797,3316.62L623.165,3316.62L622.573,3317.84Z" />
          <path className='fill' id="path3058" d="M625.619,3312.24L623.211,3317.85L624.779,3317.85L625.223,3316.72L627.806,3316.72L628.248,3317.85L629.848,3317.85L627.442,3312.24L625.619,3312.24ZM626.51,3313.55L627.297,3315.51L625.72,3315.51L626.51,3313.55Z" />
          <path className='fill' id="path3060" d="M630.014,3317.84L630.014,3312.24L631.794,3312.24L634.066,3315.76L634.066,3312.24L635.466,3312.24L635.466,3317.84L633.744,3317.84L631.414,3314.23L631.414,3317.84L630.014,3317.84Z" />
          <path className='fill' id="path3062" d="M602.165,3327.64L602.165,3322.04L606.733,3322.04L606.733,3323.3L603.579,3323.3L603.579,3324.25L606.659,3324.25L606.659,3325.43L603.579,3325.43L603.579,3326.43L606.733,3326.43L606.733,3327.65L602.165,3327.64Z" />
          <path className='fill' id="path3064" d="M624.548,3327.64L624.548,3322.04L629.115,3322.04L629.115,3323.3L625.962,3323.3L625.962,3324.25L629.027,3324.25L629.027,3325.43L625.962,3325.43L625.962,3326.43L629.115,3326.43L629.115,3327.65L624.548,3327.64Z" />
          <path className='fill' id="path3066" d="M606.91,3327.64L609.134,3324.88L606.857,3322.04L608.621,3322.04L609.977,3323.8L611.337,3322.04L613.037,3322.04L610.79,3324.84L613.018,3327.64L611.25,3327.64L609.933,3325.92L608.648,3327.64L606.91,3327.64Z" />
          <path className='fill' id="path3068" d="M613.179,3322.04L613.179,3327.64L614.616,3327.64L614.616,3325.88L616.09,3325.88C616.175,3325.89 616.26,3325.89 616.345,3325.89C617.407,3325.89 618.281,3325.02 618.281,3323.96C618.281,3323.95 618.281,3323.94 618.281,3323.93C618.281,3323.91 618.282,3323.9 618.282,3323.88C618.282,3322.87 617.45,3322.04 616.439,3322.04C616.383,3322.04 616.327,3322.04 616.271,3322.05L613.179,3322.04ZM614.612,3323.31L616.168,3323.31C616.188,3323.31 616.207,3323.31 616.227,3323.31C616.574,3323.31 616.859,3323.59 616.859,3323.94L616.859,3323.96C616.859,3324.31 616.567,3324.6 616.213,3324.6C616.195,3324.6 616.177,3324.6 616.159,3324.6L614.612,3324.6L614.612,3323.31Z" />
          <path className='fill' id="path3072" d="M618.889,3322.04L618.889,3327.64L620.289,3327.64L620.289,3325.65L620.878,3325.65L622.557,3327.64L624.268,3327.64L622.426,3325.58C623.303,3325.49 623.975,3324.74 623.961,3323.86C623.961,3323.86 623.961,3323.85 623.961,3323.85C623.961,3322.86 623.144,3322.04 622.15,3322.04C622.101,3322.04 622.053,3322.04 622.004,3322.04L618.889,3322.04ZM620.289,3323.3L621.889,3323.3C622.23,3323.28 622.524,3323.55 622.552,3323.89C622.529,3324.22 622.245,3324.48 621.91,3324.47L620.289,3324.47L620.289,3323.3Z" />
          <path className='fill' id="path3074" d="M629.764,3327.64L629.764,3326.43L632.564,3326.43C632.979,3326.43 633.158,3326.2 633.158,3325.96C633.158,3325.71 632.979,3325.48 632.564,3325.48L631.3,3325.48C631.249,3325.49 631.198,3325.49 631.147,3325.49C630.289,3325.49 629.582,3324.78 629.582,3323.93C629.582,3323.89 629.584,3323.85 629.587,3323.81C629.587,3322.91 630.148,3322.04 631.787,3322.04L634.513,3322.04L633.924,3323.3L631.566,3323.3C631.116,3323.3 630.977,3323.54 630.977,3323.76C630.977,3323.76 630.977,3323.77 630.977,3323.77C630.977,3324.03 631.197,3324.25 631.465,3324.25C631.474,3324.25 631.484,3324.25 631.493,3324.25L632.819,3324.25C632.894,3324.24 632.969,3324.24 633.045,3324.24C633.887,3324.24 634.581,3324.93 634.581,3325.77C634.581,3325.8 634.58,3325.83 634.578,3325.86C634.58,3325.9 634.582,3325.93 634.582,3325.97C634.582,3326.89 633.824,3327.65 632.903,3327.65C632.852,3327.65 632.802,3327.65 632.752,3327.64L629.764,3327.64Z" />
          <path className='fill' id="path3076" d="M634.9,3327.64L634.9,3326.43L637.7,3326.43C638.115,3326.43 638.294,3326.2 638.294,3325.96C638.294,3325.71 638.115,3325.48 637.7,3325.48L636.434,3325.48C636.383,3325.49 636.332,3325.49 636.281,3325.49C635.423,3325.49 634.716,3324.78 634.716,3323.93C634.716,3323.89 634.718,3323.85 634.721,3323.81C634.721,3322.91 635.282,3322.04 636.921,3322.04L639.647,3322.04L639.058,3323.3L636.7,3323.3C636.25,3323.3 636.111,3323.54 636.111,3323.76C636.111,3323.76 636.111,3323.77 636.111,3323.77C636.111,3324.03 636.331,3324.25 636.599,3324.25C636.608,3324.25 636.618,3324.25 636.627,3324.25L637.953,3324.25C638.028,3324.24 638.103,3324.24 638.179,3324.24C639.021,3324.24 639.715,3324.93 639.715,3325.77C639.715,3325.8 639.714,3325.83 639.712,3325.86C639.714,3325.9 639.716,3325.93 639.716,3325.97C639.716,3326.89 638.958,3327.65 638.037,3327.65C637.986,3327.65 637.936,3327.65 637.886,3327.64L634.9,3327.64Z" />
          <path className='fill' id="path3050" d="M606.733,3317.84L606.733,3312.24L611.3,3312.24L611.3,3313.5L608.146,3313.5L608.146,3314.45L611.226,3314.45L611.226,3315.63L608.146,3315.63L608.146,3316.63L611.3,3316.63L611.3,3317.84L606.733,3317.84Z" />
          <path className='fill' id="path3052" d="M612.111,3312.24L612.111,3317.85L613.511,3317.85L613.511,3315.86L614.1,3315.86L615.779,3317.85L617.489,3317.85L615.647,3315.78C616.523,3315.7 617.195,3314.94 617.183,3314.06C617.183,3314.06 617.183,3314.06 617.183,3314.05C617.183,3313.06 616.366,3312.24 615.372,3312.24C615.323,3312.24 615.275,3312.24 615.226,3312.25L612.111,3312.24ZM613.511,3313.5L615.111,3313.5C615.452,3313.49 615.746,3313.75 615.774,3314.09C615.751,3314.42 615.467,3314.68 615.132,3314.68L613.511,3314.68L613.511,3313.5Z" />
        </g>
      </g>
    </Svg>
  )

}
    