import React from 'react'

import styled, { useTheme } from 'styled-components'

import { Button, ButtonColors, Heading, Link, Paragraph } from '@atoms/index'
import { ResponsivePXValue } from '@components/Theme'

import { CheckBoxInput, TextInput } from '../inputs'
import { Form, useForm } from '../inputs/Form'

const Container = styled.div`

  width: 100%;

  .form-button {
    width: 100%;
    ${ResponsivePXValue('margin', '12px 0 12px 0')}
  }

  .title {
    ${ResponsivePXValue('margin', '0 0 14px 0')}
  }
  .join {
    ${ResponsivePXValue('margin-bottom', '14px')}
  }

  .input {
    ${ResponsivePXValue('margin-bottom', '9px')}
  }

  .footer {
    ${ResponsivePXValue('padding', '14px 24px 0 24px')}
  }
`

export interface ThankYouFormProps {
  onLogIn: () => void
}

export function ThankYouForm({ onLogIn }:ThankYouFormProps): JSX.Element {

  const form = useForm()
  const theme = useTheme()

  const _handleSuccess = () => {
    onLogIn()
  }

  const btnColors: ButtonColors = {
    color: theme.colors.white.pureWhite,
    backgroundColor: theme.colors.black.pureBlack,
    borderColor: theme.colors.black.pureBlack,
    // Hover
    hoverColor: theme.colors.white.pureWhite,
    hoverBackgroundColor: theme.colors.black.pureBlack,
    hoverBorderColor: theme.colors.black.pureBlack,
    // Disable
    disabledColor: theme.colors.white.pureWhite,
    disabledBackgrondColor: theme.colors.grey.athens,
  }

  return (
    <Container>

      <Heading className="title" variant='h3' >Thanks for joining Faithful to Nature</Heading>
      <Paragraph className="join" variant='p2' align='left'>{'Enter your email address and we\'ll send you a link to reset your password.'}</Paragraph>

      <Form form={form} onFinish={_handleSuccess}>
        <TextInput variant='email' showLabel={true} label="You' ve signed up with Google:" name='email' placeholder='Email address' rules={[{ required: true, message: 'Please enter an email address' }]} className="input" />

        <CheckBoxInput
          name='subscribe'
          options={[{ value: true, title: 'Subscribe to newsletter for unbeatable discounts, great new products and conscious living tips' }]}
          showLabel={true}
        />

        <Button variant='primary' colors={btnColors} className='form-button' title='LOGIN' onClick={() => form.submit()} />
      </Form>

      <Paragraph display='inline' className='footer' align='center' variant='p2'>Go back to </Paragraph><Link decoration='underline' href='#' onClick={onLogIn} >login</Link>

    </Container>
  )

}
