import React, { RefObject } from 'react'

import styled from 'styled-components'

import { ResponsivePXValue } from '@components/Theme'

const Container = styled.div`
  flex-grow: 1;
  position: relative;
  ${ResponsivePXValue('margin', { mobile: '8px 0 0', tablet: '15px 0 0', desktop: '0' })};
`

const TopContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Label = styled.div`
  font-family: open-sans;
  ${ResponsivePXValue('font-weight', { mobile: '400', tablet: '600', desktop: '600' })};
  ${ResponsivePXValue('font-size', { mobile: '11px', tablet: '10px', desktop: '10px' })};
  ${ResponsivePXValue('line-height', { mobile: '18px', tablet: '18px', desktop: '18px' })};
  color: ${(props): string => props.theme.colors.grey.stormDust};
`

const Error = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  font-family: open-sans;
  font-weight: 500;
  color: ${(props): string => props.theme.colors.red.cinnabar};
  ${ResponsivePXValue('font-size', '10px')}
  ${ResponsivePXValue('margin', '-4px 0 18px')}
  ${ResponsivePXValue('height', '16px')}
`

export interface InputWrapperProps {
  label?: string
  error?: string
  showLabel?: boolean
  required?: boolean
  children: JSX.Element
  className?: string
}

export const InputWrapper = React.forwardRef(({ label, error = '', showLabel = true, required = false, children, className }: InputWrapperProps, ref: RefObject<HTMLDivElement>): JSX.Element => {

  return (
    <Container className={className} ref={ref}>

      <If condition={showLabel}>
        <TopContainer>
          <If condition={!!label}>
            <Label>
              {label} {(required) ? '*' : ''}
            </Label>
          </If>
        </TopContainer>
      </If>

      {children}

      <If condition={!!error}>
        <Error>
          {error}
        </Error>
      </If>
    </Container>
  )

})

InputWrapper.displayName = 'InputWrapper'
