import React, { useEffect } from 'react'

import styled from 'styled-components'

import { Button } from '@atoms/buttons'
import { DEFAULT_GAP } from '@atoms/layout'
import { Paragraph } from '@atoms/typography/Paragraph'
import { Title } from '@atoms/typography/Title'
import { ResponsivePXValue } from '@components/Theme'
import { useEvents } from '@contexts/GTMProvider'
import { useGetAllProductsQuery, ProductListFragment, BreadcrumbFragment, CategoryFragment, BaseCategoryFragment } from '@hooks/api'
import { useLoadingData } from '@hooks/UseLoadingData'
import { ProductCard, ProductRow } from '@molecules/store'
import { OrderDirectionEnum } from '@uctypes/api/globalTypes'

import { ProductFilterInputs } from './ProductFilters'
import { OrderValue, DisplayTypeEnum } from './ProductGrid'

const Container = styled.div`

  flex-grow: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${ResponsivePXValue('margin-bottom', '16px')}

  .load-more {
    ${ResponsivePXValue('margin', '24px 32px 10px 32px')}
    ${ResponsivePXValue('width', '192px')}
  }

  .device-container {
    width: 100%;
  }
`

const GridContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  
  ${ResponsivePXValue('padding', { mobile: '0 2px' })}

  ${ResponsivePXValue('gap', {
    mobile: '2px',
    tablet: `${DEFAULT_GAP.tablet}px`,
    desktop: `${DEFAULT_GAP.desktop}px`,
  })}

  .seperator {
    ${ResponsivePXValue('margin', '24px 0')}
  }

`

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${ResponsivePXValue('gap', {
    mobile: `${DEFAULT_GAP.mobile}px`,
    tablet: `${DEFAULT_GAP.tablet}px`,
    desktop: `${DEFAULT_GAP.desktop}px`,
  })}

  .seperator {
    ${ResponsivePXValue('margin', '24px 0')}
  }

`

const NoResultsContainr = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  ${ResponsivePXValue('gap', '16px')}
  ${ResponsivePXValue('padding', '24px')}
  background-color: ${(props): string => props.theme.colors.white.pureWhite};
`

export interface ProductPageProps {
  filters: ProductFilterInputs
  userFilters: ProductFilterInputs
  order?: OrderValue
  productsPerPage: number
  page: number
  displayType: DisplayTypeEnum
  basePath?: string
  overideLoadingBehaviour?: boolean
  overwriteBreadCrumbs?: BreadcrumbFragment[]
  isLast: boolean
  category?: CategoryFragment | BaseCategoryFragment
  term?: string
  isSpellChecked?: boolean
  onFetchMore: () => void
  onFilterChange?: (filters: ProductFilterInputs) => void
  onLoaded?: () => void
}

export function ProductPage({ filters, userFilters, productsPerPage, page, order, displayType, basePath, overideLoadingBehaviour, overwriteBreadCrumbs, isLast, category, term, isSpellChecked = false, onFetchMore, onFilterChange, onLoaded }: ProductPageProps): JSX.Element {

  const events = useEvents()
  const { data: productData, loading: productLoading } = useGetAllProductsQuery({
    variables: {
      filters,
      search: term,
      skip: (page - 1) * productsPerPage,
      limit: productsPerPage,
      order: order ? { [order.field]: order.direction } : null,
    },
  })

  const products = useLoadingData<Readonly<ProductListFragment[]>>({
    data: productData?.allProducts?.items,
    loading: productLoading,
    defaultData: [],
  })

  const count = useLoadingData<number>({
    data: productData?.allProducts?.totalCount,
    loading: productLoading,
    defaultData: 0,
  })

  const _handleClearFilters = (): void => {
    onFilterChange?.({})
  }

  useEffect(() => {
    if (productData?.allProducts?.items) {
      onLoaded?.()
    }
    if (productData?.allProducts?.items.length) {
      const products = productData.allProducts.items
      if (category) {
        if (Object.keys(userFilters).length) {
          events.hasFilteredCatalogue(category, products, {
            productsPerPage,
            totalProducts: productData.allProducts.totalCount,
            currentPage: page,
            sortOrder: order?.field,
            sortDirection: order?.direction === OrderDirectionEnum.ASC ? 'asc' : 'desc',
            displayMode: 'grid',
          }, userFilters)
        } else {
          events.hasViewedCatalogue(category, products, {
            productsPerPage,
            totalProducts: productData.allProducts.totalCount,
            currentPage: page,
            sortOrder: order?.field,
            sortDirection: order?.direction === OrderDirectionEnum.ASC ? 'asc' : 'desc',
            displayMode: 'grid',
          })
        }
      } else {
        if (Object.keys(userFilters).length) {
          events.hasFilteredSearch(term, productData?.allProducts?.pageInfo?.isSpellchecked, {
            productsPerPage: products.length,
            totalProducts: products.length,
            currentPage: 1,
            sortOrder: 'relevance',
            sortDirection: 'desc',
            displayMode: 'grid',
          }, userFilters)
        } else {
          events.hasPerformedSearch(term, productData?.allProducts?.pageInfo?.isSpellchecked, {
            productsPerPage: products.length,
            totalProducts: products.length,
            currentPage: 1,
            sortOrder: 'relevance',
            sortDirection: 'desc',
            displayMode: 'grid',
          })
        }
      }

    }
  }, [productData?.allProducts?.items])

  const shouldShowSkeleton = (productLoading && !products.length)
  const shouldShowLoadingOnCards = productLoading && (!shouldShowSkeleton || overideLoadingBehaviour)
  let product: ProductListFragment
  let productIndex

  if (productData?.allProducts?.totalCount && (page - 1) * productsPerPage > productData?.allProducts?.totalCount) {
    return null
  }

  return (
    <Container>
      <Choose>
        <When condition={!productLoading && !products.length}>
          <NoResultsContainr>
            <Title variant='t4'>No results</Title>
            <Paragraph align='center'>
              There are no products for the filter combintation currently selected.
            </Paragraph>
            <If condition={!!onFilterChange}>
              <Button onClick={_handleClearFilters} title='CLEAR ALL FILTERS' />
            </If>
          </NoResultsContainr>
        </When>
        <When condition={displayType === DisplayTypeEnum.GRID}>
          <GridContainer>
            <For each='product' of={products || []}>
              <ProductCard basePath={basePath} key={product.id} product={product} disabled={shouldShowLoadingOnCards} overwriteBreadCrumbs={overwriteBreadCrumbs} />
            </For>
            <If condition={shouldShowSkeleton}>
              <For each='product' of={[...Array(productsPerPage).keys()]} index='productIndex'>
                <ProductCard key={productIndex} />
              </For>
            </If>
          </GridContainer>
        </When>
        <Otherwise>
          <ListContainer>
            <For each='product' of={products || []}>
              <ProductRow basePath={basePath} key={product.id} product={product} disabled={shouldShowLoadingOnCards} overwriteBreadCrumbs={overwriteBreadCrumbs} />
            </For>
            <If condition={shouldShowSkeleton}>
              <For each='product' of={[...Array(productsPerPage).keys()]} index='productIndex'>
                <ProductRow key={productIndex} />
              </For>
            </If>
          </ListContainer>
        </Otherwise>
      </Choose>
      <If condition={isLast && (count || 0) > productsPerPage * page}>
        <Button
          title='SHOW MORE'
          className='load-more'
          variant='primary'
          onClick={onFetchMore}
          loading={productLoading} />
      </If>
    </Container>
  )

}
