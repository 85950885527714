
import React from 'react'
import { Svg } from './Svg'

export interface CartOutlineProps {
  color?: string
  hoverColor?: string
  className?: string
}

export function CartOutline({ color, hoverColor, className }: CartOutlineProps): JSX.Element {

  return (
    <Svg strokeColor={color} strokeHoverColor={hoverColor} fillColor={color} fillHoverColor={hoverColor} className={className} width="100%" height="100%" viewBox="0 0 20 19" version="1.1">
      <g transform="matrix(0.856928,0,0,0.856928,0.566284,0.959244)">
        <path className='fill' d="M20.631,13.451L6.674,13.451L7.374,12.023L19.018,12.002C19.412,12.002 19.749,11.721 19.82,11.332L21.432,2.306C21.474,2.07 21.411,1.826 21.256,1.641C21.18,1.55 21.085,1.476 20.977,1.426C20.869,1.375 20.752,1.348 20.633,1.348L5.821,1.298L5.694,0.703C5.614,0.323 5.272,0.047 4.883,0.047L1.262,0.047C1.042,0.047 0.832,0.134 0.677,0.289C0.522,0.444 0.435,0.655 0.435,0.874C0.435,1.094 0.522,1.304 0.677,1.459C0.832,1.614 1.042,1.702 1.262,1.702L4.213,1.702L4.766,4.331L6.128,10.924L4.374,13.786C4.283,13.909 4.229,14.055 4.216,14.207C4.204,14.36 4.234,14.512 4.304,14.648C4.445,14.927 4.728,15.103 5.042,15.103L6.514,15.103C6.2,15.52 6.031,16.028 6.031,16.549C6.031,17.876 7.11,18.954 8.436,18.954C9.763,18.954 10.841,17.876 10.841,16.549C10.841,16.027 10.667,15.518 10.358,15.103L14.134,15.103C13.82,15.52 13.65,16.028 13.651,16.549C13.651,17.876 14.729,18.954 16.056,18.954C17.382,18.954 18.46,17.876 18.46,16.549C18.46,16.027 18.287,15.518 17.978,15.103L20.633,15.103C21.088,15.103 21.46,14.733 21.46,14.276C21.459,14.057 21.371,13.847 21.216,13.692C21.06,13.538 20.85,13.451 20.631,13.451ZM6.165,2.93L19.66,2.974L18.339,10.376L7.738,10.395L6.165,2.93ZM8.436,17.29C8.028,17.29 7.696,16.957 7.696,16.549C7.696,16.141 8.028,15.809 8.436,15.809C8.844,15.809 9.177,16.141 9.177,16.549C9.177,16.746 9.099,16.934 8.96,17.073C8.821,17.212 8.633,17.29 8.436,17.29ZM16.056,17.29C15.648,17.29 15.315,16.957 15.315,16.549C15.315,16.141 15.648,15.809 16.056,15.809C16.464,15.809 16.796,16.141 16.796,16.549C16.796,16.746 16.718,16.934 16.579,17.073C16.441,17.212 16.252,17.29 16.056,17.29Z" />
      </g>
    </Svg>
  )

}
    