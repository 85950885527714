import React, { useEffect } from 'react'

import styled from 'styled-components'

import { APP_DEFAULT_STATE, AppPlugin } from '@api/local/AppPlugin'
import { Button, ResponsiveImage, UltraContainer } from '@atoms/index'
import { Heading, Link } from '@atoms/typography'
import { ResponsivePXValue } from '@components/Theme'
import { useGetAppQuery, useStaticImageQuery } from '@hooks/api'
import { DeviceContainer } from '@utility/DeviceContainer'

const Container = styled.div`
  width: 100%;
  background-color: ${({ theme }): string => theme.colors.white.pureWhite};
  ${ResponsivePXValue('padding', { mobile: '16px', tablet: '48px', desktop: '48px' })};
  ${ResponsivePXValue('margin', { mobile: '0px', tablet: '32px', desktop: '32px' })}
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${ResponsivePXValue('gap', '16px')}
`

const Wrapper = styled.div`
  display: flex;
  align-items: center;

  ${ResponsivePXValue('flex-direction', { mobile: 'column-reverse', tablet: 'column-reverse', desktop: 'row' })}
`

const ContentContainer = styled.div`

  display: flex;
  flex-direction: column;
  ${ResponsivePXValue('gap', { mobile: '14px', tablet: '28px', desktop: '28px' })}

  .copy {
    max-width: ${ResponsivePXValue('max-width', '670px')};
    margin: 0;
  }

  .oops {
    margin: 0;
  }

  .errorCode {
    margin: 0;
  }

  .redirectLink {
    color: ${({ theme }): string => theme.colors.green.silverTree};
    &:hover {
      color: ${({ theme }): string => theme.colors.orange.casablanca};
    }
  }

  .inlineLink {
    text-decoration: underline;
  }
`

const ImageContainer = styled.div`
  ${ResponsivePXValue('width', { mobile: '250px', tablet: '460px', desktop: '460px' })}
  ${ResponsivePXValue('margin-bottom', { mobile: '20px' })}
  flex-grow: 0;
`

const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${ResponsivePXValue('gap', '8px')}
`

const array404 = [
  {
    image: '404-plasticbottles',
    copy: <Heading variant='h3' className='copy'>
      This page does not exist. But plastic bottles still do? Let&rsquo;s make the change by swapping to <Link href='/home-and-lifestyle/waste-free-living/water-bottles-flasks' className='redirectLink inlineLink'>reusable bottles</Link>.
    </Heading>,

  },
  {
    image: '404-coral',
    copy: <Heading variant='h3' className='copy'>
      This page doesn&rsquo;t exist. Neither will coral reefs due to chemicals commonly found in sunscreens.
      Swap for a <Link href='/body-beauty/bath-and-body/sun-protection' className='redirectLink inlineLink'>natural sunscreen</Link>.
    </Heading>,

  },
  {
    image: '404-plasticbags',
    copy: <Heading variant='h3' className='copy'>
     This page does not exist. But single-use shopping bags still do. Be a part of the change and swap them for <Link href='/home-and-lifestyle/waste-free-living/waste-free-shopping' className='redirectLink inlineLink'>reusable bags</Link>!
    </Heading>,

  },
  {
    image: '404-animaltesting',
    copy: <Heading variant='h3' className='copy'>
      This page does not exist. Do you know what else shouldn’t exist? Testing on animals. Not one of our <Link href='/body-beauty/makeup' className='redirectLink inlineLink'>makeup</Link> and <Link href='/body-beauty' className='redirectLink inlineLink'>beauty</Link> products is tested on animals.
    </Heading>,

  },
]

const random404 = array404[Math.floor(Math.random() * array404.length)]

export function NotFound(): JSX.Element {

  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()

  const { data, loading } = useStaticImageQuery({
    variables: {
      staticId: random404.image,
      filters: {
        identifiers: {
          equals: random404.image,
        },
      },
      mobileWidth: 700,
      mobileHeight: 729,
      tabletWidth: 700,
      desktopWidth: 700,
      desktopHeight: 729,
    },
  })

  const mobileProps = {
    align: 'center',
  }

  return (
    <UltraContainer>
      <Container>
        <Wrapper>
          <ContentContainer>
            <DeviceContainer mobile>
              <ContentContainer>
                <Heading
                  variant='h1'
                  align='center'
                  className='oops'>
                  Oops!
                </Heading>
                { React.cloneElement(random404.copy, {
                  ...random404.copy.props,
                  ...mobileProps,
                }) }
                <Heading variant='h3' className='errorCode' align='center'>Error Code: 404</Heading>
              </ContentContainer>

            </DeviceContainer>
            <DeviceContainer tablet desktop>
              <ContentContainer>
                <Heading
                  variant='h1'
                  align='left'
                  className='oops'>
                    Oops!
                </Heading>
                { random404.copy }
                <Heading variant='h3' className='errorCode' align='left'>Error Code: 404</Heading>
              </ContentContainer>

            </DeviceContainer>
            <DeviceContainer desktop tablet>
              <LinkContainer>
                <Link href='/'>
                  <Heading variant='h4' className='redirectLink'>
                    Home
                  </Heading>
                </Link>
                <Link href='/health'>
                  <Heading variant='h4' className='redirectLink'>
                    Health
                  </Heading>
                </Link>
                <Link href='/body-beauty'>
                  <Heading variant='h4' className='redirectLink'>
                    Body & Beauty
                  </Heading>
                </Link>
                <Link href='/brands/faithful-to-nature'>
                  <Heading variant='h4' className='redirectLink'>
                    Brand
                  </Heading>
                </Link>
              </LinkContainer>
            </DeviceContainer>
            <DeviceContainer mobile>
              <LinkContainer>
                <Button variant='secondary' title='Home' fullWidth={true} href='/' />
                <Button variant='secondary' title='Health' fullWidth={true} href='/health'/>
                <Button variant='secondary' title='Body & Beauty' fullWidth={true} href='/body-beauty'/>
                <Button variant='secondary' title='Brand' fullWidth={true} href='/brand'/>
              </LinkContainer>

            </DeviceContainer>
          </ContentContainer>
          <ImageContainer>
            <ResponsiveImage image={data?.oneImage } lazy={true} objectFit='contain'/>
          </ImageContainer>

        </Wrapper>
      </Container>
    </UltraContainer>
  )

}
