import React, { useEffect, useState } from 'react'

import update from 'react-addons-update'

import { Heading, HeadingAlignment, HeadingVariant, Paragraph, ParagraphVariant } from '@atoms/index'

export interface LineEllipsisProps {
  variant?: ParagraphVariant | HeadingVariant
  text: string
  ellipsis: string
  maxLine: number
  trimRight?: boolean
  basedOn: string
  align?: HeadingAlignment
  className?: string
  onClick?: () => void
}

interface PageLoaderState {
  textText: string
  varientType: string
}

const DEFAULT_STATE: PageLoaderState = {
  textText: '',
  varientType: '',
}

// TODO Story needed
export function LineEllipsis({
  variant,
  text, 
  ellipsis,
  maxLine,
  trimRight,
  align='left',
  basedOn,
  className,
  onClick,
}: LineEllipsisProps): JSX.Element {

  const [state, setState] = useState<PageLoaderState>({ ...DEFAULT_STATE })

  useEffect(() => {

    let textSplit = []
    let newString = ''
    let letter = ''
    let cnt = 0

    if (variant) {
      letter = variant.charAt(0)
    }

    if (basedOn === 'words') {
      textSplit = text.split(' ')
    } else {
      textSplit = text.split('')
    }

    if (textSplit.length > 0) {
      for (let i = 0; i < textSplit.length; i++) {
        newString = newString + (basedOn === 'words' ? ' ' : '') + textSplit[i]
        cnt++
        if (maxLine <= i) {
          break
        }
      }

      newString = newString + (cnt > maxLine ? ellipsis : '')
      setState((prevState) => update(prevState, {
        textText: { $set: newString },
        varientType: { $set: letter },
      }))
    }

  }, [text])

  return (

    <Choose>
      <When condition={state.varientType === 'p'}>
        <Paragraph variant={variant as ParagraphVariant}  align={align} className={className} onClick={onClick}>{state.textText}</Paragraph>
      </When>

      <When condition={state.varientType === 'h'}>
        <Heading variant={variant as HeadingVariant} align={align} className={className} onClick={onClick}>{state.textText}</Heading>
      </When>

      <Otherwise>
        <div className={className} onClick={onClick} >{state.textText}</div>
      </Otherwise>
    </Choose>

  )

}
