
import React from 'react'
import { Svg } from './Svg'

export interface VisaProps {
  color?: string
  hoverColor?: string
  className?: string
}

export function Visa({ color, hoverColor, className }: VisaProps): JSX.Element {

  return (
    <Svg strokeColor={color} strokeHoverColor={hoverColor} fillColor={color} fillHoverColor={hoverColor} className={className} width="100%" height="100%" viewBox="0 0 20 8" version="1.1">
      <g id="g4158" transform="matrix(1,0,0,1,-64.219,-3316.06)">
        <g id="polygon9" transform="matrix(0.396185,0,0,0.396185,44.8145,2004.64)">
          <path className='fill' d="M71.19,3327.1L67.509,3327.1L69.809,3312.87L73.49,3312.87L71.19,3327.1Z" />
        </g>
        <g id="path11" transform="matrix(0.396185,0,0,0.396185,44.8145,2004.64)">
          <path className='fill' d="M84.533,3313.22C83.48,3312.81 82.361,3312.6 81.233,3312.61C77.598,3312.61 75.038,3314.55 75.023,3317.32C74.992,3319.36 76.855,3320.5 78.248,3321.18C79.673,3321.88 80.157,3322.33 80.157,3322.95C80.142,3323.91 79.006,3324.35 77.946,3324.35C76.751,3324.38 75.565,3324.12 74.493,3323.59L74.008,3323.36L73.493,3326.56C74.802,3327.07 76.194,3327.33 77.598,3327.32C81.46,3327.32 83.974,3325.41 84.004,3322.46C84.019,3320.84 83.035,3319.6 80.915,3318.58C79.627,3317.93 78.839,3317.49 78.839,3316.82C78.854,3316.22 79.506,3315.6 80.959,3315.6C81.902,3315.57 82.84,3315.76 83.701,3316.14L84.034,3316.29L84.534,3313.22L84.533,3313.22Z" />
        </g>
        <g id="path13" transform="matrix(0.396185,0,0,0.396185,44.8145,2004.64)">
          <path className='fill' d="M89.425,3322.06C89.725,3321.24 90.895,3318.08 90.895,3318.08C90.88,3318.11 91.195,3317.24 91.379,3316.71L91.636,3317.94C91.636,3317.94 92.336,3321.35 92.485,3322.06L89.425,3322.06ZM93.969,3312.87L91.122,3312.87C90.287,3312.77 89.486,3313.26 89.198,3314.05L83.731,3327.1L87.593,3327.1L88.365,3324.97L93.091,3324.97C93.197,3325.47 93.53,3327.1 93.53,3327.1L96.938,3327.1L93.969,3312.87L93.969,3312.87Z" />
        </g>
        <g id="path15" transform="matrix(0.396185,0,0,0.396185,44.8145,2004.64)">
          <path className='fill' d="M64.435,3312.87L60.835,3322.57L60.441,3320.61C59.544,3318.05 57.729,3315.93 55.352,3314.64L58.652,3327.09L62.544,3327.09L68.33,3312.87L64.435,3312.87Z" />
        </g>
        <g id="path17" transform="matrix(0.396185,0,0,0.396185,44.8145,2004.64)">
          <path className='fill' d="M57.483,3312.87L51.561,3312.87L51.5,3313.16C56.12,3314.34 59.179,3317.18 60.436,3320.61L59.149,3314.06C58.988,3313.3 58.261,3312.77 57.483,3312.86L57.483,3312.87Z" />
        </g>
      </g>
    </Svg>
  )

}
    