import React, { } from 'react'

import { useNavigate } from 'react-router'
import styled from 'styled-components'

import { APP_DEFAULT_STATE } from '@api/local/AppPlugin'
import { Icon, LocalIconEnums } from '@atoms/images'
import { ResponsivePXValue, theme } from '@components/Theme'
import { useGetAppQuery } from '@hooks/api/index'
import { NavigationCartButton, NavigationProfile } from '@molecules/navigation'
import { NavigationFavouriteButton } from '@molecules/navigation/NavigationFavouriteButton'
import { DesktopSearch } from '@molecules/search'

const Header = styled.div`
  border-style: solid;
  ${ResponsivePXValue('border-bottom-width', '1px')}
  border-color: ${(props): string => props.theme.colors.grey.pampas};
`

const Container = styled.div`
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  ${ResponsivePXValue('width', '1440px')}
  ${ResponsivePXValue('height', '72px')}
  ${ResponsivePXValue('padding', '0 56px')}



  .favourite {
    ${ResponsivePXValue('margin-right', '30px')}
  }
  .cart {
    ${ResponsivePXValue('margin-right', '30px')}
  }
  .search {
    ${ResponsivePXValue('margin-right', '60px')}
  }
`

const HomeLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  ${ResponsivePXValue('margin-right', '66px')}

`
const MobileLogoContainer = styled.div`
  ${ResponsivePXValue('width', '40px')}
  ${ResponsivePXValue('height', '40px')}  
`

const DesktopLogoContainer = styled.div`
  ${ResponsivePXValue('width', '190px')}
`
const CovidAction = styled.a`
  font-family: open-sans;
  border-style: solid;
  text-decoration: none;
  color: ${(props): string => props.theme.colors.grey.silver};
  font-weight: 400;
  ${ResponsivePXValue('font-size', '10px')}
  ${ResponsivePXValue('line-height', '18px')}
  ${ResponsivePXValue('margin-right', '16px')}
  :hover {
    text-decoration: underline;
  }

`

const SearchHolder = styled.div`

  flex-grow: 1;
  ${ResponsivePXValue('height', '40px')}

`

export function DesktopNavigationBar(): JSX.Element {

  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()
  const navigate = useNavigate()

  const _handleHomeClicked = (e: React.MouseEvent<HTMLAnchorElement>): void => {
    e.preventDefault()
    navigate('/')
  }

  return (
    <Header>
      <Container>

        <HomeLink href='/' onClick={_handleHomeClicked}>
          <MobileLogoContainer>
            <Icon icon={LocalIconEnums.MOBILE_LOGO} color={theme.colors.green.watercourse} />
          </MobileLogoContainer>
          <DesktopLogoContainer>
            <Icon className='' icon={LocalIconEnums.DESKTOP_LOGO} color={theme.colors.green.watercourse} />
          </DesktopLogoContainer>
        </HomeLink>
        <If condition={appData.app.showHeaderContent}>

          <Choose>
            <When condition={appData.app.showSearch}>
              <DesktopSearch className='search' />
            </When>
            <Otherwise>
              <SearchHolder />
            </Otherwise>
          </Choose>

          <NavigationFavouriteButton className='favourite' />

          <NavigationCartButton className='cart' />

          <NavigationProfile className='profile' />
        </If>
      </Container>
    </Header>
  )

}
