
import React from 'react'
import { Svg } from './Svg'

export interface RadionDefaultProps {
  color?: string
  hoverColor?: string
  className?: string
}

export function RadionDefault({ color, hoverColor, className }: RadionDefaultProps): JSX.Element {

  return (
    <Svg strokeColor={color} strokeHoverColor={hoverColor} fillColor={color} fillHoverColor={hoverColor} className={className} width="100%" height="100%" viewBox="0 0 20 20" version="1.1">
      <g transform="matrix(0.997468,0,0,0.997468,2.02026,2.02026)">
        <path className='stroke' d="M8,15C4.134,15 1,11.866 1,8L-1,8C-1,12.971 3.029,17 8,17L8,15ZM15,8C15,11.866 11.866,15 8,15L8,17C12.971,17 17,12.971 17,8L15,8ZM8,1C11.866,1 15,4.134 15,8L17,8C17,3.029 12.971,-1 8,-1L8,1ZM8,-1C3.029,-1 -1,3.029 -1,8L1,8C1,4.134 4.134,1 8,1L8,-1Z" />
      </g>
    </Svg>
  )

}
    