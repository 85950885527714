import React, { useEffect, Fragment, useRef } from 'react'

import { useLocation } from 'react-router'

interface ScrollTopProps {
  children: JSX.Element | JSX.Element
}

export function ScrollToTop({ children }: ScrollTopProps): JSX.Element {

  const path = useRef<string>()
  const location = useLocation()

  useEffect(() => {
    if (path.current !== location.pathname.split('?')[0]) {
      window.scrollTo(0, 0)
      path.current = location.pathname.split('?')[0]
    }
  }, [location.pathname])

  return <Fragment>{children}</Fragment>
}
