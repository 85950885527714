
import React from 'react'

import loadable from '@loadable/component'

import { PageLoader } from '@atoms/notifications'

import(/* webpackPrefetch: true */ '@pages/OceanAlly')

const OceanAlly = loadable(() => import(/* webpackChunkName: "brand" */'@pages/OceanAlly'), {
  resolveComponent: (components) => components.OceanAlly,
})

export function OceanAllyAsync(): JSX.Element {
  
  return <OceanAlly fallback={<PageLoader />} />

}
