
import React from 'react'
import { Svg } from './Svg'

export interface ListProps {
  color?: string
  hoverColor?: string
  className?: string
}

export function List({ color, hoverColor, className }: ListProps): JSX.Element {

  return (
    <Svg strokeColor={color} strokeHoverColor={hoverColor} fillColor={color} fillHoverColor={hoverColor} className={className} width="100%" height="100%" viewBox="0 0 20 20" version="1.1">
      <g transform="matrix(0.898428,0,0,0.898428,-0.781131,-0.781131)">
        <path className='fill' d="M22,4.226C22,2.997 21.003,2 19.774,2L4.226,2C2.997,2 2,2.997 2,4.226L2,8.774C2,10.003 2.997,11 4.226,11L19.774,11C21.003,11 22,10.003 22,8.774L22,4.226Z" />
      </g>
      <g transform="matrix(0.898428,0,0,0.898428,-0.781131,-0.781131)">
        <path className='fill' d="M22,15.226C22,13.997 21.003,13 19.774,13L4.226,13C2.997,13 2,13.997 2,15.226L2,19.774C2,21.003 2.997,22 4.226,22L19.774,22C21.003,22 22,21.003 22,19.774L22,15.226Z" />
      </g>
    </Svg>
  )

}
    