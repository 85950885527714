import React from 'react'

import styled, { css, useTheme } from 'styled-components'

import { ResponsivePXValue } from '@components/Theme'

const Container = styled.div`
  display: flex;
  ${ResponsivePXValue('gap', '4px')}
`

const Common = css`
  ${ResponsivePXValue('border-radius', '5px')}
  ${ResponsivePXValue('width', '8px')}
  ${ResponsivePXValue('height', '8px')}
`

const Dot = styled.div<{ color: string }>`
  cursor: pointer;
  background-color: ${(props): string => props.color};
  ${Common}
`

export interface DotPaginationProps {
  currentIndex: number
  total: number
  className?: string
  selectedColor?: string
  defaultColor?: string
  onChange?: (index: number) => void
}

export function DotPagination({ currentIndex, total, className, selectedColor, defaultColor, onChange }: DotPaginationProps): JSX.Element {

  const theme = useTheme()

  if (!selectedColor) {
    selectedColor = theme.colors.grey.stormDust
  }

  if (!defaultColor) {
    defaultColor = theme.colors.grey.gallery
  }

  const $dots: JSX.Element[] = []
  for (let d = 0; d < total; d++) {
    if (currentIndex === d) {
      $dots.push(<Dot key={d} color={selectedColor} onClick={() => onChange?.(d)} />)
    } else {
      $dots.push(<Dot key={d} color={defaultColor} onClick={() => onChange?.(d)} />)
    }
  }

  return (
    <Container className={className}>
      {$dots}
    </Container>
  )

}
