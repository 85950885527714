import React from 'react'

import styled from 'styled-components'

import { ResponsiveImage } from '@atoms/images'
import { ResponsivePXValue } from '@components/Theme'
import { PageBlockFragment } from '@hooks/api/index'

import { HTML } from './HTML'

const Container = styled.div`
  width: 100%;
  height: 100%;
`
const ContentContainer = styled.div <{ backgroundColor: string, foregroundColor: string }>`
  ${ResponsivePXValue('height', { mobile: '100px', tablet: '110px', desktop: '110px' })}
  ${ResponsivePXValue('padding', '15px')}

  display: flex;
  flex-shrink: 0;
  flex-direction: row;

  background-color: ${(props): string => props.backgroundColor};
  color: ${(props): string => props.foregroundColor};
`

const ImageContainer = styled.div`
  ${ResponsivePXValue('width', { mobile: '90px', tablet: '105px', desktop: '105px' })}
  ${ResponsivePXValue('height', { mobile: '50px', tablet: '60px', desktop: '60px' })}
  ${ResponsivePXValue('padding-right', { mobile: '5px', tablet: '10px', desktop: '10px ' })}

`
const DescriptionContainer = styled.div`  
  ${ResponsivePXValue('padding', '5px 0 0 10px')}
`
export interface PageIconTextBlockProps {
  block: PageBlockFragment
}

export function PageIconTextBlock({ block }: PageIconTextBlockProps): JSX.Element {

  const { image, description, backgroundColor, foregroundColor } = block

  return (
    <Container >
      <ContentContainer backgroundColor={backgroundColor} foregroundColor={foregroundColor}>
        <If condition={!!image}>
          <ImageContainer>
            <ResponsiveImage image={image} objectFit='fill' />
          </ImageContainer>
        </If>
        <If condition={!!description}>
          <DescriptionContainer>
            <HTML color={foregroundColor} align='left'>
              {description}
            </HTML>
          </DescriptionContainer>
        </If>
      </ContentContainer>
    </Container>
  )
}
