import React, { useMemo } from 'react'

import styled, { LayoutSizeClass, useTheme } from 'styled-components'

import { ResponsivePXValue, theme } from '@components/Theme'

// (1440 - (104 * 2) - (16 * 11)) / 12 = 88

interface OptionalLayoutSizeClass {
  mobile?: string
  tablet?: string
  desktop?: string
}

export const DEFAULT_COLS: LayoutSizeClass = {
  mobile: 10,
  tablet: 10,
  desktop: 12,
}

export const DEFAULT_MARGIN: LayoutSizeClass = {
  mobile: 0,
  tablet: 15,
  desktop: 104,
}

export const DEFAULT_GAP: LayoutSizeClass = {
  mobile: 10,
  tablet: 15,
  desktop: 16,
}

const DEFAULT_COL_WIDTH: LayoutSizeClass = {
  mobile: 10,
  tablet: 15,
  desktop: 16,
}

const Container = styled.div<{ $margin: LayoutSizeClass }>`
  ${(props): string => ResponsivePXValue('width', {
    mobile: `${props.theme.calculationSize.mobile}px`,
    tablet: `${props.theme.calculationSize.tablet}px`,
    desktop: `${props.theme.calculationSize.desktop}px`,
  })}
  ${(props): string => ResponsivePXValue('padding', {
    mobile: `0 ${props.$margin.mobile}px`,
    tablet: `0 ${props.$margin.tablet}px`,
    desktop: `0 ${props.$margin.desktop}px`,
  })}
  
`

const ColContainer = styled.div<{ $widths: LayoutSizeClass, $displays: OptionalLayoutSizeClass }>`
  ${(props): string => ResponsivePXValue('width', {
    mobile: `${props.$widths.mobile}px`,
    tablet: `${props.$widths.tablet}px`,
    desktop: `${props.$widths.desktop}px`,
  })}
  ${(props): string => ResponsivePXValue('display', props.$displays)}
`

const RowContainer = styled.div<{ $gap: LayoutSizeClass, $align: 'flex-start' | 'center' | 'flex-end' }>`
  display: flex;
  flex-wrap: wrap;
  justify-content: ${(props): string => props.$align};
  width: 100%;
  ${(props): string => ResponsivePXValue('gap', {
    mobile: `${props.$gap.mobile}px`,
    tablet: `${props.$gap.tablet}px`,
    desktop: `${props.$gap.desktop}px`,
  })}
`

export interface GridSizeArgs {
  cols?: LayoutSizeClass
  margin?: LayoutSizeClass
  gap?: LayoutSizeClass
}

export const defaultGrid = { cols: DEFAULT_COLS, margin: DEFAULT_MARGIN, gap: DEFAULT_GAP }

const calculateColWidth = (calculationSize: number, margin: number, gap: number, cols: number): number => {
  return (calculationSize - (margin * 2) - (gap * (cols - 1))) / cols
}

const calculateElementWidth = (colWidth: number, span: number, gap: number): number => {
  return (span * colWidth) + (span ? ((span - 1) * gap) : 0)
}

const calculateColWidths = (calculationSize: LayoutSizeClass, margin: LayoutSizeClass, gap: LayoutSizeClass, cols: LayoutSizeClass): LayoutSizeClass => {
  return {
    mobile: calculateColWidth(theme.calculationSize.mobile, margin.mobile, gap.mobile, cols.mobile),
    tablet: calculateColWidth(theme.calculationSize.tablet, margin.tablet, gap.tablet, cols.tablet),
    desktop: calculateColWidth(theme.calculationSize.desktop, margin.desktop, gap.desktop, cols.desktop),
  }
}

const calculateElementWidths = (colWidth: LayoutSizeClass, span: LayoutSizeClass, gap: LayoutSizeClass): LayoutSizeClass => {
  return {
    mobile: calculateElementWidth(colWidth.mobile, span.mobile, gap.mobile),
    tablet: calculateElementWidth(colWidth.tablet, span.tablet, gap.tablet),
    desktop: calculateElementWidth(colWidth.desktop, span.desktop, gap.desktop),
  }
}

export function layoutColWidthForGrid(span: LayoutSizeClass, grid: GridSizeArgs = defaultGrid): LayoutSizeClass {
  const { cols, margin, gap } = grid

  const colWidth: LayoutSizeClass = calculateColWidths(theme.calculationSize, margin, gap, cols)
  return calculateElementWidths(colWidth, span, gap)
}

const GridContext = React.createContext<{
  colWidth?: LayoutSizeClass
  gap?: LayoutSizeClass
}>(null)

const useGrid = (): {
  colWidth?: LayoutSizeClass
  gap?: LayoutSizeClass
} => React.useContext(GridContext)

export interface LayoutColProps {
  span: LayoutSizeClass
  children: React.ReactNode
  className?: string
}

export function LayoutCol({ span, children, className }: LayoutColProps): JSX.Element {

  const grid = useGrid()
  const colWidth = grid.colWidth || DEFAULT_COL_WIDTH
  const gap = grid.gap || DEFAULT_GAP
  const widths = useMemo(() => calculateElementWidths(colWidth, span, gap), [colWidth, span, gap])

  const display: OptionalLayoutSizeClass = {}
  if (widths.mobile === 0) {
    display.mobile = 'none'
  }
  if (widths.tablet === 0) {
    display.tablet = 'none'
  }
  if (widths.desktop === 0) {
    display.desktop = 'none'
  }

  return (
    <ColContainer $widths={widths} className={className} $displays={display}>
      {children}
    </ColContainer>
  )
}

export interface LayoutRowProps {
  children: JSX.Element[] | JSX.Element
  className?: string
  align?: 'start' | 'center' | 'end'
}

export function LayoutRow({ children, className, align = 'start' }: LayoutRowProps): JSX.Element {

  const grid = useGrid()

  return (
    <RowContainer $gap={grid.gap || DEFAULT_GAP} className={className} $align={align === 'center' ? align : `flex-${align}`}>
      {children}
    </RowContainer>
  )
}

export interface LayoutGridProps {
  cols?: LayoutSizeClass
  margin?: LayoutSizeClass
  gap?: LayoutSizeClass
  children: JSX.Element[] | JSX.Element
  className?: string
}

export function LayoutGrid({ cols = DEFAULT_COLS, margin = DEFAULT_MARGIN, gap = DEFAULT_GAP, children, className }: LayoutGridProps): JSX.Element {

  const theme = useTheme()
  const colWidth = useMemo(() => calculateColWidths(theme.calculationSize, margin, gap, cols), [margin, gap, cols])

  return (
    <Container $margin={margin} className={className}>
      <GridContext.Provider value={{ gap, colWidth }}>
        {children}
      </GridContext.Provider>
    </Container>
  )

}
