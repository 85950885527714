import { Heading, Paragraph } from "@atoms/typography"
import { ResponsivePXValue } from "@components/Theme"
import { ContentChangeDocument, PageTimetableFragment } from "@hooks/api"
import { HTML } from "@molecules/content"
import React from "react"
import styled, { useTheme } from "styled-components"

const Container = styled.div`
    width: 100%;
    ${ResponsivePXValue('padding', { mobile: '16px', tablet: '20px', desktop: '20px' })}
    background-color: ${({ theme }) => theme.colors.white.pureWhite};
`

const TitleContainer = styled.div`
    width: 100%;
    ${ResponsivePXValue('margin-bottom', { mobile: '4px', tablet: '16px', desktop: '16px' })}
    display: flex;

    
    .scheduledToClose {
        ${ResponsivePXValue('margin-left', '12px')}    
    }
`

const ContentContainer = styled.div`

`

export interface PageTimetableProps {
    timetable: PageTimetableFragment
}
export function PageTimetable({ timetable }: PageTimetableProps): JSX.Element {

    const { colors } = useTheme()

    return (
        <Container>
            <If condition={!!timetable.title}>
                <TitleContainer>
                    <Heading variant='h5' color={colors.green.greenVogue} >{timetable.title}</Heading>
                    <If condition={!!timetable.isClosing}>
                        <Paragraph color={colors.red.cinnabar} variant='p2' className='scheduledToClose'>*Scheduled to close</Paragraph>
                    </If>
                </TitleContainer>
            </If>
            <ContentContainer>
                <HTML children={timetable.content} align='left'  />
            </ContentContainer>
        </Container>
    )
}
    