import React, { useEffect, useRef, useState } from 'react'

import { FormInstance, Field } from 'rc-field-form'
import update from 'react-addons-update'
import ReactDOM from 'react-dom'
import styled, { css, CSS, useTheme } from 'styled-components'

import { Icon, LocalIconEnums, SmallLoader } from '@atoms/index'
import { LiteBoxShadow, ResponsivePXValue } from '@components/Theme'
import { useGetApiEnumQuery } from '@hooks/api/index'

import { FormValue, Meta, Rule, FormContext, FormContextProperties } from './index'

const Container = styled.div`
  position: relative;
  flex-shrink: 0;
`

const SelectDisplayElement = styled.div<{ disabled: boolean, focused: boolean, isActive: boolean }>`
  display: flex;
  align-items: center;
  font-family: open-sans;
  font-weight: 400;
  border-style: solid;
  ${(props): CSS => {
    if (props.disabled) {
      return css`
        color: ${props.theme.colors.white.romance};
        border-color: ${props.theme.colors.white.romance};
        background-color: ${props.theme.colors.white.floralWhite};
      `
    } else if (props.focused) {
      return css`
        color: ${props.theme.colors.green.goldenrod};
        border-color: ${props.theme.colors.green.goldenrod};
        background-color: ${props.theme.colors.green.goldenrod};
      `
    }
    return css`
      color: ${props.theme.colors.green.goldenrod};
      border-color: ${props.theme.colors.green.goldenrod};
      background-color: ${props.theme.colors.white.floralWhite};
    `
  }}
  
  width: fit-content;

  ${ResponsivePXValue('font-size', '14px')}
  ${ResponsivePXValue('padding', '0 12px')}
  ${ResponsivePXValue('height', '40px')}
  ${ResponsivePXValue('border-radius', '2px')}
  ${ResponsivePXValue('border-width', '1px')}

  ${(props): CSS => {
    if (props.disabled) {
      return css`
        border-color: ${props.theme.colors.white.romance};
        color: ${props.theme.colors.white.romance};
        background-color: ${props.theme.colors.white.floralWhite};
      `
    }
  }}
`

const TitleContainer = styled.div`
  ${ResponsivePXValue('margin-right', '16px')}
`

const LoadingContainer = styled.div`
  ${ResponsivePXValue('width', '20px')}
  ${ResponsivePXValue('height', '20px')}
`

const SelectContainer = styled.div<{ open: boolean, optionsToShow: number }>`
  flex-shrink: 0;
  position: absolute;
  left: 0;
  display: ${(props): string => props.open ? 'inline-block' : 'none'};
  z-index: 10;
  width: fit-content;
  min-width: 100%;
  background-color: ${(props): string => props.theme.colors.white.floralWhite};
  overflow-x: hidden;
  overflow-y: scroll;
  ${LiteBoxShadow}

  ${(props): CSS => {
    return css`
      ${ResponsivePXValue('max-height', `${props.optionsToShow * 40}px`)}
    `
  }}
  ${(props): CSS => {
    return css`
      ${ResponsivePXValue('border', `1px solid ${props.theme.colors.green.goldenrod}`)}
    `
  }}

  ${ResponsivePXValue('top', '50px')}
  ${ResponsivePXValue('border-radius', '2px')}
  ${ResponsivePXValue('border-width', '1px')}
  ${ResponsivePXValue('padding', '20px')}
`

const SelectOptionElement = styled.div<{ selected: boolean }>`

  display: flex;
  align-items: center;
  justify-content: space-between;

  ${ResponsivePXValue('height', '40px')}

  color: ${(props): string => props.theme.colors.green.goldenrod};
  background-color: ${(props): string => props.selected ? props.theme.colors.green.goldenrod : props.theme.colors.white.floralWhite};
  &:hover {
    background-color: ${(props): string => props.theme.colors.green.goldenrod};
    .check-box {
      border-color: ${(props): string => props.theme.colors.green.goldenrod};
      ${ResponsivePXValue('border-width', '3px')}
      ${ResponsivePXValue('margin', '0 0 0 14px')}
    }
  }
`

const OptionText = styled.div`
  user-select: none;
  white-space: nowrap;
  font-weight: 400;
  font-family: open-sans;
  text-align: left;
  flex-grow: 1;

  ${ResponsivePXValue('margin', '0 8px')}
  ${ResponsivePXValue('font-size', '14px')}
`

const OptionIcon = styled.div`
  pointer-events: none;
  ${ResponsivePXValue('width', '25px')}
  ${ResponsivePXValue('height', '25px')}
  ${ResponsivePXValue('margin-left', '8px')}
`

const ScrollForMore = styled.div<{ top: number, width: number }>`
  flex-shrink: 0;
  position: absolute;
  left: 0;
  z-index: 10;
  top: ${(props): number => props.top}px;
  width: ${(props): number => props.width}px;
  ${ResponsivePXValue('margin-top', '56px')}
  ${ResponsivePXValue('height', '32px')}

  .info {
    margin: 0;
  }
`

const CountContainer = styled.div`
  user-select: none;
  /* white-space: nowrap; */
  font-weight: 400;
  font-family: open-sans;

  ${ResponsivePXValue('margin', '0 16px')}
  ${ResponsivePXValue('font-size', '14px')}
`

interface Values {
  value?: FormValue | FormValue[]
  onChange?: (value: FormValue | FormValue[]) => void
}

export interface InlineSelectOption {
  title: string
  value: FormValue
}

interface InlineSelectInputInnerProps extends InlineSelectFormInputProps {
  control: Values
  meta: Meta
  form: FormInstance
}

function InlineSelectInputInner(props: InlineSelectInputInnerProps): JSX.Element {
  const { placeholder = 'Select', options, loading, disabled, optionsToShow = 6, className, apiEnum, control, allowClear = true } = props

  const [state, setState] = useState<InlineSelectInputState>({ ...DEFAULT_STATE })
  const stateRef = React.useRef(state)
  const { data, loading: apiLoading } = useGetApiEnumQuery({ variables: { enum: apiEnum }, skip: !apiEnum })
  const theme = useTheme()

  const displayRef: React.RefObject<HTMLDivElement> = useRef()
  const optionsRef: React.RefObject<HTMLDivElement> = useRef()

  const { value, onChange } = control

  let actualOptions = options ? [...options] : []
  if (data?.enum?.values) {
    actualOptions = data.enum.values.map((enumVal) => ({ title: enumVal.title, value: enumVal.value }))
  }

  const _handleChange = (newValue: string | number) => {
    if (newValue === value) {
      newValue = null
    }
    onChange(newValue)
    setState((prevState) => update(prevState, { open: { $set: false } }))
  }

  const _handleKeyDown = (e: KeyboardEvent): void => {
    if (!state.open) {
      return
    }
    if (e.key === 'Enter') {
      e.preventDefault()
      // onSelectNext(this.props.dataKey)
      setState((prevState) => update(prevState, { open: { $set: false } }))
    } else if (e.key === 'ArrowDown') {
      if (actualOptions.length) {
        if (value) {
          let index = actualOptions.findIndex((option) => {
            return option.value === value
          })
          if (index < actualOptions.length - 1) {
            index++
          } else {
            index = 0
          }
          onChange(actualOptions[index].value)
        } else {
          onChange(actualOptions[0].value)
        }
      }
    } else if (e.key === 'ArrowUp') {
      if (actualOptions.length) {
        if (value) {
          let index = actualOptions.findIndex((option) => {
            return option.value === value
          })
          if (index > 0) {
            index--
          } else {
            index = actualOptions.length - 1
          }
          onChange(actualOptions[index].value)
        } else {
          onChange(actualOptions[actualOptions.length - 1].value)
        }
      }
    }
  }

  const _handleFocus = () => {
    setState((prevState) => update(prevState, { open: { $set: !prevState.open } }))
  }

  const _handleClear = (e: React.MouseEvent<HTMLDivElement>) => {
    const isActive = !!value
    if (isActive && !state.open && allowClear) {
      e.stopPropagation()
      onChange(null)
    }
  }

  const _handlePageClick = (e: MouseEvent): void => {
    const shouldClose = !optionsRef?.current?.contains?.(e.target as Element) && !displayRef?.current?.contains?.(e.target as Element)
    if (stateRef?.current?.open && shouldClose) {
      setState((prevState) => update(prevState, { open: { $set: false } }))
    }
  }

  const _handleScroll = (): void => {
    const currentScroll = optionsRef.current.scrollTop
    setState((prevState) => update(prevState, {
      selectScroll: { $set: currentScroll },
    }))
  }

  useEffect(() => {
    stateRef.current = state
  }, [JSON.stringify(state)])

  useEffect(() => {
    window.addEventListener('keydown', _handleKeyDown)
    return () => window.removeEventListener('keydown', _handleKeyDown)
  }, [])

  useEffect(() => {
    document.addEventListener('mousedown', _handlePageClick)
    return () => document.addEventListener('mousedown', _handlePageClick)
  }, [])

  useEffect(() => {
    if (value && state.open) {
      const index = actualOptions.findIndex((option) => option.value === value)
      const currentScroll = optionsRef.current.scrollTop
      const containerHeight = optionsRef.current.clientHeight
      const itemHeight = optionsRef.current.clientHeight / optionsToShow
      const selectedItemTop = itemHeight * index
      if (selectedItemTop >= currentScroll + containerHeight) {
        // eslint-disable-next-line react/no-find-dom-node
        const scroller = ReactDOM.findDOMNode(optionsRef.current) as Element
        scroller.scrollTo(0, selectedItemTop + itemHeight - containerHeight)
      } else if (selectedItemTop < currentScroll) {
        // eslint-disable-next-line react/no-find-dom-node
        const scroller = ReactDOM.findDOMNode(optionsRef.current) as Element
        scroller.scrollTo(0, selectedItemTop)
      }
    }
  }, [value])

  useEffect(() => {
    if (actualOptions.length > optionsToShow) {
      const shouldShow = optionsRef.current.scrollHeight - optionsRef.current.scrollTop - 5 > optionsRef.current.clientHeight
      if (shouldShow) {
        setState((prevState) => update(prevState, {
          showMore: { $set: true },
        }))
      } else {
        setState((prevState) => update(prevState, {
          showMore: { $set: false },
        }))
      }
    } else {
      setState((prevState) => update(prevState, {
        showMore: { $set: false },
      }))
    }
  }, [options?.length, state.selectScroll, state.open])

  useEffect(() => {
    const { clientWidth = 0, clientHeight = 0 } = optionsRef?.current ?? {}
    setState((prevState) => update(prevState, {
      selectWidth: { $set: clientWidth },
      selectHeight: { $set: clientHeight },
    }))
  }, [state.open])

  let option: Option
  const isActive = !!value

  const iconColor = theme.colors.green.goldenrod
  const title = actualOptions.find((option) => option.value === value)?.title ?? placeholder

  return (
    <FormContext.Consumer>
      {({ loading: formLoading, disabled: formDisabled }: FormContextProperties) => {
        return (
          <Container className={className}>
            <SelectDisplayElement
              ref={displayRef}
              disabled={disabled || formDisabled || loading || apiLoading}
              focused={state.open}
              isActive={isActive}
              onClick={_handleFocus}>
              <TitleContainer>
                {title}
              </TitleContainer>
              <LoadingContainer onClick={_handleClear}>
                <Choose>
                  <When condition={loading || apiLoading || formLoading}>
                    <SmallLoader
                      color={theme.colors.green.goldenrod} />
                  </When>
                  <When condition={state.open}>
                    <Icon color={iconColor} icon={LocalIconEnums.CHEVRON_UP} />
                  </When>
                  <When condition={isActive && allowClear}>
                    <Icon color={iconColor} icon={LocalIconEnums.CLOSE} />
                  </When>
                  <Otherwise>
                    <Icon color={iconColor} icon={LocalIconEnums.CHEVRON_DOWN} />
                  </Otherwise>
                </Choose>
              </LoadingContainer>

            </SelectDisplayElement>

            <SelectContainer
              onScroll={_handleScroll}
              ref={optionsRef}
              open={state.open}
              optionsToShow={optionsToShow}>
              <Choose>
                <When condition={!!actualOptions.length}>
                  <For each='option' of={actualOptions} index='optionIndex'>
                    <SelectOptionElement
                      onMouseDown={() => _handleChange(option.value)}
                      selected={option.value === value}
                      key={option.value.toString()}>
                      <If condition={!!option.icon}>
                        {option.icon}
                      </If>
                      <If condition={option.value === value}>
                        <OptionIcon>
                          <Icon
                            icon={LocalIconEnums.PLUS}
                            color={theme.colors.green.goldenrod} />
                        </OptionIcon>
                      </If>
                      <OptionText>
                        {option.title}
                      </OptionText>
                      <If condition={!!option.count}>
                        <CountContainer>
                          ({option.count})
                        </CountContainer>
                      </If>
                    </SelectOptionElement>
                  </For>
                </When>
                <Otherwise>
                  <SelectOptionElement selected={false}>
                    <OptionText>
                      No Options
                    </OptionText>
                    <OptionIcon>
                      <Icon
                        icon={LocalIconEnums.CLOSE}
                        color={theme.colors.white.romance} />
                    </OptionIcon>
                  </SelectOptionElement>
                </Otherwise>
              </Choose>
            </SelectContainer>
            <If condition={state.showMore && state.open}>
              <ScrollForMore top={state.selectHeight} width={state.selectWidth}>
                <Icon color={theme.colors.green.goldenrod} icon={LocalIconEnums.CHEVRON_DOWN} />
              </ScrollForMore>
            </If>
          </Container>
        )
      }}
    </FormContext.Consumer>
  )
}

export interface Option {
  title: string
  value: string | number
  icon?: React.ReactNode
  count?: number
}

interface InlineSelectInputState {
  open: boolean
  showMore: boolean
  selectWidth: number
  selectHeight: number
  selectScroll: number
}

export interface InlineSelectFormInputProps {
  rules?: Rule[]
  name: string
  loading?: boolean
  disabled?: boolean
  options?: Option[]
  apiEnum?: string
  optionsToShow?: number
  className?: string
  placeholder?: string
  allowClear?: boolean
}

const DEFAULT_STATE = {
  open: false,
  showMore: true,
  selectWidth: 0,
  selectHeight: 0,
  selectScroll: 0,
}

export function InlineSelectInput(props: InlineSelectFormInputProps): JSX.Element {

  const { name, rules } = props

  return (
    <Field name={name} rules={rules}>
      {(control: Values, meta: Meta, form: FormInstance) => <InlineSelectInputInner {...props} control={control} meta={meta} form={form} />}
    </Field>
  )

}
