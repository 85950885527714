import { gql } from '@apollo/client'

export const localQueries = gql`
  fragment AppFragment on App {
    id
    deviceType
    showHeaderContent
    showBottomNav
    showFooter
    showSearch
  }
  
  query GetApp {
    app @client {
      ...AppFragment
    }
  }
  
  fragment NavigationFragment on Navigation {
    id
    navigationOpen
    searchOpen
    path
    isTest
    preventScroll
  } 
  
  query GetNavigation {
    navigation @client {
      ...NavigationFragment
    }
  }
  
  fragment MenuCategoriesFragment on MenuCategories {
    id
    open
    currentTop
    currentMiddle
    currentBottom
  }
  
  query GetMenuCategories {
    menuCategories @client {
      ...MenuCategoriesFragment
    }
  }
  
  fragment ModalsFragment on Modals {
    id
    logIn
    signUp
    search
    subscribe
  }
  
  query GetModals {
    modals @client {
      ...ModalsFragment
    }
  }
  
  fragment CreditCardInfoFragment on CreditCardInfo {
    id
    expiryMonth
    expiryYear
    name
    nickname
    number
    saveCard
    brand
    cvv
  }
  
  fragment DeliveryInfoFragment on DeliveryInfo {
    id
    step
    hasSetInfo
    carrierCode
    deliveryMethod
    deliveryAddressId
  }
  
  fragment PaymentInfoFragment on PaymentInfo {
    id
    step
    hasSetInfo
    paymentMethod
    creditCardId
    isNewCard
    newCardInfo {
      ...CreditCardInfoFragment
    }
    threeDSecureId
    canSkipThreeDSecure
  }
  
  fragment ConfirmInfoFragment on ConfirmInfo {
    id
    step
  }
  
  fragment CheckoutFragment on Checkout {
    id
    step
    canAdvance
    showSteps
    deliveryInfo {
      ...DeliveryInfoFragment
    }
    paymentInfo {
      ...PaymentInfoFragment
    }
    confirmInfo {
      ...ConfirmInfoFragment
    }
  }
  
  query GetCheckout {
    checkout @client {
      ...CheckoutFragment
    }
  }
  
  fragment AuthFragment on Auth {
    id
    token
    cartId
  }
  
  query GetAuth {
    auth @client {
      ...AuthFragment
    }
  }
  
`
