
import React from 'react'
import { Svg } from './Svg'

export interface TwitterProps {
  color?: string
  hoverColor?: string
  className?: string
}

export function Twitter({ color, hoverColor, className }: TwitterProps): JSX.Element {

  return (
    <Svg strokeColor={color} strokeHoverColor={hoverColor} fillColor={color} fillHoverColor={hoverColor} className={className} width="100%" height="100%" viewBox="0 0 20 17" version="1.1">
      <g transform="matrix(0.97251,0,0,0.97251,-1150.44,-3004.32)">
        <path className='fill' d="M1200.58,3094.01C1200.59,3094.18 1200.59,3094.34 1200.59,3094.5C1200.59,3100.41 1195.72,3105.28 1189.81,3105.28C1187.75,3105.28 1185.74,3104.68 1184,3103.58C1184.31,3103.61 1184.61,3103.62 1184.92,3103.62C1186.62,3103.63 1188.28,3103.06 1189.62,3102.01C1188,3101.98 1186.58,3100.92 1186.08,3099.38C1186.32,3099.42 1186.56,3099.44 1186.79,3099.44C1187.13,3099.44 1187.47,3099.39 1187.79,3099.31C1186.03,3098.95 1184.76,3097.39 1184.76,3095.59L1184.76,3095.55C1185.28,3095.84 1185.87,3096 1186.47,3096.03C1184.81,3094.92 1184.29,3092.69 1185.3,3090.97C1187.22,3093.33 1190.06,3094.77 1193.1,3094.93C1193.04,3094.64 1193.01,3094.35 1193.01,3094.06C1193.01,3091.98 1194.72,3090.27 1196.8,3090.27C1197.84,3090.27 1198.85,3090.71 1199.56,3091.47C1200.41,3091.31 1201.22,3091 1201.96,3090.55C1201.68,3091.43 1201.09,3092.17 1200.3,3092.64C1201.05,3092.56 1201.78,3092.36 1202.48,3092.06C1201.96,3092.81 1201.32,3093.47 1200.58,3094.01L1200.58,3094.01Z" />
      </g>
    </Svg>
  )

}
    