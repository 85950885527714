
import React from 'react'
import { Svg } from './Svg'

export interface PeachPaymentsProps {
  color?: string
  hoverColor?: string
  className?: string
}

export function PeachPayments({ color, hoverColor, className }: PeachPaymentsProps): JSX.Element {

  return (
    <Svg strokeColor={color} strokeHoverColor={hoverColor} fillColor={color} fillHoverColor={hoverColor} className={className} width="100%" height="100%" viewBox="0 0 20 6" version="1.1">
      <g transform="matrix(0.133872,0,0,0.133872,-54.0627,-441.814)">
        <g id="Page-1">
          <g id="SS_PeachPayments_Logo_Horizontal_Icon">
            <g id="Group">
              <path className='fill' id="Fill-1" d="M438.761,3317.08C438.207,3317.03 437.662,3317.25 437.295,3317.67C436.942,3318.23 436.78,3318.89 436.834,3319.55L436.834,3319.8C436.776,3320.53 436.936,3321.26 437.292,3321.9C437.649,3322.35 438.215,3322.6 438.792,3322.55C439.336,3322.57 439.852,3322.29 440.14,3321.83C440.491,3321.21 440.656,3320.5 440.613,3319.79C440.659,3319.08 440.495,3318.38 440.143,3317.77C439.836,3317.31 439.31,3317.05 438.761,3317.08ZM439.169,3323.92C438.261,3323.96 437.387,3323.56 436.834,3322.83L436.734,3322.83C436.801,3323.5 436.834,3323.91 436.834,3324.05L436.834,3327.3L435.15,3327.3L435.15,3315.85L436.511,3315.85C436.549,3316 436.628,3316.35 436.747,3316.91L436.834,3316.91C437.348,3316.11 438.253,3315.66 439.198,3315.71C440.097,3315.67 440.961,3316.07 441.509,3316.78C442.616,3318.64 442.61,3320.97 441.494,3322.83C440.944,3323.55 440.075,3323.96 439.169,3323.92L439.169,3323.92Z" />
              <path className='fill' id="Fill-2" d="M447.122,3316.98C446.641,3316.96 446.173,3317.14 445.836,3317.49C445.482,3317.91 445.279,3318.43 445.259,3318.97L448.859,3318.97C448.888,3318.44 448.72,3317.91 448.386,3317.49C448.059,3317.14 447.597,3316.96 447.122,3316.98ZM447.43,3323.92C446.359,3323.98 445.309,3323.59 444.54,3322.84C443.81,3322.03 443.433,3320.96 443.496,3319.87C443.433,3318.77 443.777,3317.68 444.464,3316.82C445.136,3316.06 446.114,3315.66 447.122,3315.71C448.046,3315.66 448.948,3316 449.601,3316.66C450.239,3317.38 450.566,3318.32 450.511,3319.28L450.511,3320.19L445.231,3320.19C445.21,3320.84 445.433,3321.47 445.854,3321.96C446.293,3322.4 446.895,3322.62 447.509,3322.58C447.949,3322.59 448.387,3322.54 448.817,3322.45C449.264,3322.35 449.699,3322.2 450.117,3322.01L450.117,3323.38C449.721,3323.57 449.302,3323.71 448.87,3323.8C448.395,3323.88 447.913,3323.92 447.43,3323.92Z" />
              <path className='fill' id="Fill-3" d="M454.551,3322.63C455.089,3322.65 455.617,3322.47 456.023,3322.12C456.407,3321.74 456.61,3321.22 456.579,3320.68L456.579,3319.99L455.562,3320.03C454.96,3320.01 454.364,3320.15 453.831,3320.43C453.469,3320.67 453.262,3321.09 453.29,3321.52C453.271,3321.82 453.386,3322.12 453.605,3322.34C453.873,3322.55 454.211,3322.65 454.551,3322.63ZM457.059,3323.77L456.722,3322.67L456.664,3322.67C456.363,3323.09 455.968,3323.43 455.511,3323.66C455.038,3323.85 454.53,3323.94 454.021,3323.92C453.354,3323.96 452.698,3323.73 452.198,3323.29C451.738,3322.81 451.5,3322.16 451.541,3321.51C451.501,3320.77 451.847,3320.07 452.452,3319.66C453.295,3319.18 454.255,3318.94 455.224,3318.98L456.593,3318.93L456.593,3318.51C456.625,3318.1 456.498,3317.69 456.238,3317.38C455.94,3317.1 455.541,3316.96 455.138,3317C454.742,3317 454.348,3317.06 453.971,3317.18C453.604,3317.3 453.245,3317.44 452.896,3317.6L452.351,3316.4C452.798,3316.17 453.273,3316 453.763,3315.89C454.237,3315.78 454.723,3315.72 455.21,3315.71C456.025,3315.65 456.834,3315.89 457.492,3316.37C458.046,3316.91 458.329,3317.67 458.262,3318.44L458.262,3323.78L457.059,3323.77Z" />
              <path className='fill' id="Fill-4" d="M463.485,3323.92C462.464,3323.99 461.463,3323.6 460.752,3322.87C459.493,3321.03 459.51,3318.6 460.795,3316.78C461.536,3316.03 462.569,3315.64 463.622,3315.71C464.399,3315.69 465.169,3315.85 465.878,3316.17L465.37,3317.53C464.812,3317.28 464.215,3317.14 463.607,3317.11C462.236,3317.11 461.55,3318.02 461.551,3319.84C461.501,3320.54 461.681,3321.25 462.063,3321.84C462.423,3322.3 462.984,3322.55 463.563,3322.51C464.308,3322.51 465.041,3322.32 465.69,3321.95L465.69,3323.42C465.391,3323.6 465.066,3323.72 464.727,3323.8C464.318,3323.88 463.901,3323.92 463.484,3323.91" />
              <path className='fill' id="Fill-5" d="M474.2,3323.77L472.509,3323.77L472.509,3318.9C472.546,3318.41 472.415,3317.93 472.139,3317.53C471.84,3317.21 471.407,3317.04 470.968,3317.08C470.378,3317.03 469.798,3317.27 469.41,3317.71C469.021,3318.35 468.846,3319.1 468.91,3319.84L468.91,3323.77L467.226,3323.77L467.226,3312.62L468.91,3312.62L468.91,3315.45C468.908,3315.94 468.879,3316.43 468.824,3316.91L468.932,3316.91C469.16,3316.53 469.492,3316.22 469.888,3316.02C470.332,3315.8 470.822,3315.69 471.317,3315.7C471.434,3315.69 471.552,3315.68 471.67,3315.68C473.071,3315.68 474.225,3316.83 474.225,3318.24C474.225,3318.36 474.216,3318.48 474.198,3318.61L474.198,3323.77" />
              <path className='fill' id="Fill-6" d="M483.323,3317.08C482.769,3317.03 482.225,3317.25 481.858,3317.67C481.504,3318.23 481.342,3318.89 481.396,3319.55L481.396,3319.8C481.338,3320.53 481.498,3321.26 481.854,3321.9C482.211,3322.35 482.777,3322.6 483.354,3322.55C483.898,3322.57 484.413,3322.29 484.701,3321.83C485.052,3321.21 485.217,3320.5 485.174,3319.79C485.22,3319.08 485.057,3318.38 484.705,3317.77C484.397,3317.31 483.872,3317.05 483.323,3317.08ZM483.731,3323.92C482.823,3323.96 481.949,3323.56 481.396,3322.83L481.296,3322.83C481.363,3323.5 481.396,3323.91 481.396,3324.05L481.396,3327.3L479.712,3327.3L479.712,3315.85L481.073,3315.85C481.111,3316 481.191,3316.35 481.31,3316.91L481.396,3316.91C481.91,3316.11 482.815,3315.66 483.76,3315.71C484.659,3315.67 485.523,3316.07 486.071,3316.78C487.179,3318.64 487.173,3320.97 486.056,3322.83C485.506,3323.55 484.637,3323.96 483.731,3323.92L483.731,3323.92Z" />
              <path className='fill' id="Fill-7" d="M490.982,3322.63C491.521,3322.65 492.049,3322.47 492.455,3322.12C492.839,3321.74 493.042,3321.21 493.01,3320.68L493.01,3319.99L491.993,3320.03C491.391,3320.01 490.795,3320.15 490.262,3320.43C489.901,3320.67 489.694,3321.09 489.721,3321.52C489.702,3321.82 489.817,3322.12 490.037,3322.34C490.304,3322.55 490.642,3322.65 490.982,3322.63ZM493.49,3323.77L493.153,3322.67L493.1,3322.67C492.799,3323.09 492.403,3323.43 491.946,3323.66C491.473,3323.85 490.965,3323.94 490.456,3323.92C489.789,3323.96 489.133,3323.73 488.633,3323.29C488.174,3322.81 487.935,3322.16 487.977,3321.51C487.936,3320.77 488.282,3320.07 488.887,3319.66C489.731,3319.18 490.691,3318.94 491.66,3318.98L493.028,3318.93L493.028,3318.51C493.06,3318.1 492.933,3317.69 492.674,3317.38C492.376,3317.1 491.977,3316.96 491.574,3317C491.178,3317 490.784,3317.06 490.406,3317.18C490.039,3317.3 489.68,3317.44 489.331,3317.6L488.787,3316.4C489.234,3316.17 489.708,3316 490.198,3315.89C490.672,3315.78 491.158,3315.72 491.645,3315.71C492.46,3315.65 493.27,3315.89 493.928,3316.37C494.481,3316.91 494.764,3317.67 494.697,3318.44L494.697,3323.78L493.49,3323.77Z" />
              <path className='fill' id="Fill-8" d="M495.516,3315.85L497.351,3315.85L498.963,3320.34C499.191,3320.92 499.354,3321.53 499.45,3322.15L499.508,3322.15C499.565,3321.82 499.644,3321.5 499.744,3321.19C499.858,3320.82 500.465,3319.03 501.564,3315.85L503.384,3315.85L499.994,3324.83C499.378,3326.47 498.351,3327.3 496.914,3327.3C496.548,3327.3 496.182,3327.26 495.825,3327.18L495.825,3325.84C496.11,3325.9 496.401,3325.93 496.692,3325.93C497.52,3325.92 498.235,3325.33 498.404,3324.52L498.697,3323.77L495.516,3315.85" />
              <path className='fill' id="Fill-9" d="M510.943,3323.77L509.252,3323.77L509.252,3318.89C509.287,3318.41 509.166,3317.93 508.908,3317.53C508.643,3317.22 508.242,3317.05 507.833,3317.08C507.28,3317.04 506.741,3317.28 506.403,3317.72C506.047,3318.36 505.889,3319.09 505.949,3319.83L505.949,3323.77L504.264,3323.77L504.264,3315.85L505.583,3315.85L505.82,3316.89L505.905,3316.89C506.129,3316.51 506.463,3316.2 506.862,3316.01C507.291,3315.81 507.764,3315.7 508.241,3315.71C509.226,3315.58 510.191,3316.08 510.663,3316.95L510.778,3316.95C511.014,3316.56 511.356,3316.24 511.766,3316.03C512.22,3315.81 512.721,3315.7 513.228,3315.71C513.987,3315.64 514.741,3315.9 515.295,3316.42C515.774,3317.05 516.005,3317.82 515.943,3318.61L515.943,3323.77L514.259,3323.77L514.259,3318.89C514.294,3318.41 514.173,3317.93 513.912,3317.53C513.646,3317.22 513.244,3317.05 512.834,3317.08C512.284,3317.03 511.745,3317.26 511.397,3317.69C511.053,3318.26 510.894,3318.92 510.943,3319.58L510.943,3323.78" />
              <path className='fill' id="Fill-10" d="M521.123,3316.98C520.642,3316.96 520.174,3317.14 519.837,3317.49C519.484,3317.91 519.28,3318.43 519.26,3318.97L522.86,3318.97C522.89,3318.44 522.722,3317.91 522.387,3317.49C522.06,3317.14 521.598,3316.96 521.123,3316.98ZM521.432,3323.92C520.36,3323.98 519.31,3323.59 518.541,3322.84C517.811,3322.03 517.435,3320.96 517.498,3319.87C517.434,3318.77 517.779,3317.68 518.465,3316.82C519.137,3316.06 520.115,3315.66 521.123,3315.71C522.047,3315.66 522.949,3316 523.602,3316.66C524.24,3317.38 524.567,3318.32 524.512,3319.28L524.512,3320.19L519.232,3320.19C519.211,3320.84 519.434,3321.47 519.855,3321.96C520.294,3322.4 520.896,3322.62 521.51,3322.58C521.95,3322.59 522.388,3322.54 522.818,3322.45C523.265,3322.35 523.701,3322.2 524.118,3322.01L524.118,3323.38C523.722,3323.57 523.303,3323.71 522.872,3323.8C522.397,3323.88 521.915,3323.92 521.432,3323.92Z" />
              <path className='fill' id="Fill-11" d="M533.074,3323.77L531.383,3323.77L531.383,3318.9C531.42,3318.41 531.289,3317.93 531.014,3317.53C530.715,3317.21 530.282,3317.04 529.843,3317.08C529.252,3317.03 528.671,3317.26 528.281,3317.71C527.895,3318.35 527.721,3319.09 527.786,3319.83L527.786,3323.77L526.1,3323.77L526.1,3315.85L527.418,3315.85L527.654,3316.89L527.74,3316.89C527.987,3316.5 528.34,3316.2 528.757,3316.01C529.216,3315.8 529.716,3315.7 530.22,3315.7C532.121,3315.7 533.072,3316.67 533.071,3318.61L533.071,3323.77" />
              <path className='fill' id="Fill-12" d="M538.045,3322.56C538.463,3322.55 538.878,3322.49 539.277,3322.36L539.277,3323.63C539.045,3323.72 538.804,3323.79 538.557,3323.83C538.256,3323.89 537.95,3323.91 537.644,3323.91C536.048,3323.91 535.25,3323.07 535.251,3321.39L535.251,3317.12L534.169,3317.12L534.169,3316.38L535.329,3315.76L535.903,3314.08L536.942,3314.08L536.942,3315.85L539.2,3315.85L539.2,3317.12L536.943,3317.12L536.943,3321.36C536.914,3321.69 537.023,3322.02 537.243,3322.26C537.46,3322.46 537.748,3322.57 538.043,3322.55" />
              <g id="Group-16">
                <path className='fill' id="Fill-13" d="M545.861,3321.52C545.897,3322.21 545.579,3322.88 545.016,3323.3C544.294,3323.75 543.446,3323.97 542.594,3323.92C541.721,3323.95 540.851,3323.79 540.051,3323.44L540.051,3321.98C540.861,3322.38 541.748,3322.6 542.651,3322.63C543.688,3322.63 544.206,3322.31 544.206,3321.69C544.212,3321.51 544.151,3321.33 544.035,3321.19C543.871,3321.02 543.68,3320.88 543.468,3320.77C543.112,3320.59 542.744,3320.43 542.368,3320.28C541.683,3320.07 541.051,3319.7 540.516,3319.22C540.184,3318.85 540.011,3318.35 540.033,3317.85C540.01,3317.22 540.322,3316.62 540.853,3316.27C541.523,3315.86 542.301,3315.66 543.085,3315.71C543.996,3315.7 544.898,3315.89 545.728,3316.27L545.184,3317.54C544.505,3317.23 543.773,3317.05 543.027,3317.01C542.139,3317.01 541.695,3317.26 541.695,3317.77C541.698,3318.03 541.828,3318.26 542.042,3318.4C542.52,3318.69 543.028,3318.93 543.558,3319.11C544.058,3319.29 544.536,3319.52 544.983,3319.81C545.258,3320 545.484,3320.24 545.642,3320.54C545.793,3320.84 545.866,3321.18 545.857,3321.52" />
                <path className='fill' id="Fill-15" d="M424.8,3314.96C424.34,3314.6 423.819,3314.32 423.263,3314.14C421.372,3313.59 420.32,3313.98 419.078,3315.54C417.917,3316.91 417.431,3318.72 417.756,3320.48C417.897,3321.49 418.299,3321.52 418.288,3320.48C418.258,3317.59 419.196,3316.11 420.065,3315.54C421.115,3314.8 422.503,3314.73 423.622,3315.36C425.29,3316.39 426.107,3319.43 425.262,3321.98C424.368,3324.68 422.723,3326.83 419.623,3327.16C416.7,3327.47 414.079,3323.96 413.413,3321.21C412.503,3317.45 414.239,3315.25 416.292,3315.11C416.958,3315.07 417.283,3316.82 418.239,3316.19C418.863,3315.73 418.674,3314.54 419.111,3313.88C419.511,3313.28 420.965,3312.96 421.019,3312.95C421.512,3312.85 421.887,3312.56 421.887,3312.08C421.887,3312.08 421.887,3312.08 421.887,3312.08C421.887,3311.61 421.496,3311.22 421.02,3311.22L421,3311.22C420.907,3311.22 420.815,3311.23 420.727,3311.26C419.317,3311.64 418.225,3312.76 417.894,3314.18C417.53,3313.8 417.049,3313.55 416.528,3313.48C411.49,3312.99 410.715,3319.27 411.883,3322.28C412.974,3325.1 414.39,3326.99 417.111,3328.18C421.936,3330.28 425.411,3325.51 426.44,3322.13C427.246,3319.48 426.393,3316.17 424.799,3314.95" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </Svg>
  )

}
    