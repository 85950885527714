import React from 'react'

import styled from 'styled-components'

import { Button } from '@atoms/buttons'
import { ResponsiveImage } from '@atoms/images'
import { Heading } from '@atoms/typography'
import { ResponsivePXValue } from '@components/Theme'
import { PageBannerFragment } from '@hooks/api/index'
import { SiteHelper } from '@lib/SiteHelper'
import { PageBannerLayoutEnum, PageLink } from '@uctypes/api/globalTypes'
import { DeviceContainer } from '@utility/DeviceContainer'

import { HTML } from './HTML'

const Container = styled.div<{ layout: PageBannerLayoutEnum, backgroundColor: string }>`
  width: 100%;
  display: flex;
  align-items: center;
  ${(props): string => ResponsivePXValue('flex-direction', {
    mobile: props.layout === PageBannerLayoutEnum.IMAGE_LEFT ? 'row' : 'row-reverse',
    tablet: props.layout === PageBannerLayoutEnum.IMAGE_LEFT ? 'row' : 'row-reverse',
    desktop: props.layout === PageBannerLayoutEnum.IMAGE_LEFT ? 'row' : 'row-reverse',
  })}
  background-color: ${(props): string => props.backgroundColor};
  ${ResponsivePXValue('padding', { mobile: '16px' })}
`

const ImageContainer = styled.div<{ layout: PageBannerLayoutEnum }>`
  flex-shrink: 0;
  ${ResponsivePXValue('width', { mobile: '100px', tablet: '100%', desktop: '400px' })}
  ${ResponsivePXValue('height', { mobile: '100px', tablet: '100%', desktop: '400px' })}
`

const ContentContainer = styled.div<{ foregroundColor: string }>`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  ${ResponsivePXValue('align-items', 'flex-start')}
  ${ResponsivePXValue('justify-content', { desktop: 'center' })}
  ${ResponsivePXValue('width', { mobile: '100%', tablet: '100%', desktop: '50%' })}
  ${ResponsivePXValue('height', { desktop: '100%' })}
  ${ResponsivePXValue('padding', { mobile: '10px', tablet: '10px', desktop: '24px 32px' })}
  ${ResponsivePXValue('gap', { mobile: '8px', tablet: '8px', desktop: '16px' })}
  color: ${(props): string => props.foregroundColor};

  .align {
    ${ResponsivePXValue('text-align', { mobile: 'center', tablet: 'center', desktop: 'left' })}
  }

  .description {
    ${ResponsivePXValue('display', { mobile: 'none', tablet: 'none' })}
  }

  .clamped {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;  
    overflow: hidden;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  ${ResponsivePXValue('align-items', { mobile: 'center', tablet: 'center', desktop: 'flex-start' })}
  ${ResponsivePXValue('justify-content', { desktop: 'center' })}
  ${ResponsivePXValue('gap', '8px')}
`

export interface PageSplitSmallImageBannerProps {
  banner: PageBannerFragment
}

export function PageSplitSmallImageBanner({ banner }: PageSplitSmallImageBannerProps): JSX.Element {

  const { links, layout, backgroundColor, image, foregroundColor, title, description } = banner

  const buttonColors = {
    color: foregroundColor,
    backgroundColor,
    borderColor: foregroundColor,
    hoverColor: foregroundColor,
    hoverBackgroundColor: backgroundColor,
    hoverBorderColor: foregroundColor,
    disabledColor: SiteHelper.getOpaqueColor(foregroundColor, 0.45),
    disabledBackgrondColor: backgroundColor,
    disabledBorderColor: SiteHelper.getOpaqueColor(foregroundColor, 0.45),
  }

  let link: PageLink
  let index: number

  return (
    <Container layout={layout} backgroundColor={backgroundColor}>
      <ImageContainer layout={layout}>
        <ResponsiveImage image={image} objectFit='cover' />
      </ImageContainer>
      <ContentContainer foregroundColor={foregroundColor}>
        <DeviceContainer desktop>
          <Heading variant='h2' className='align' color={foregroundColor}>{title}</Heading>
        </DeviceContainer>
        <DeviceContainer mobile tablet>
          <Heading variant='h5' className='clamped' color={foregroundColor}>{title}</Heading>
        </DeviceContainer>
        <DeviceContainer desktop>
          <HTML color={foregroundColor}>
            {description}
          </HTML>
        </DeviceContainer>
        <ButtonContainer>
          <For each='link' index='index' of={links || []}>
            <Button title={link.title} href={link.url} key={index} colors={buttonColors} target="_blank" />
          </For>
        </ButtonContainer>
      </ContentContainer>
    </Container>
  )

}
