import React, { MouseEventHandler, useRef } from 'react'

import { useNavigate } from 'react-router'
import styled from 'styled-components'

import { ResponsivePXValue } from '@components/Theme'
import { useLinkClickHandlers } from '@hooks/UseLinkClickHandlers'

const Container = styled.div<{ color?: string, align: 'left' | 'right' | 'center' }>`
  text-align: ${(props): string => props.align ? props.align : 'left'};
  width: 100%;

  > * {
    color: ${(props): string => props.color ? props.color : props.theme.colors.green.bottleGreen};
  }

  p {
    font-family: open-sans;
    ${ResponsivePXValue('font-size', { mobile: '12px', tablet: '14px', desktop: '14px' })}
    ${ResponsivePXValue('line-height', { mobile: '18px', tablet: '20px', desktop: '22px' })}
  }

  iframe {
    width: 100%;
  }
`

export interface HTMLProps {
  children: string
  color?: string
  className?: string
  align?: 'left' | 'right' | 'center'
}

export function HTML({ children, color, className, align }: HTMLProps): JSX.Element {

  const ref = useRef<HTMLDivElement>(null)
  useLinkClickHandlers(ref)

  return (
    <Container
      className={className}
      align={align}
      color={color}
      dangerouslySetInnerHTML={{ __html: children }}
      ref={ref} />
  )

}
