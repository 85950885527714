import React, { useState, useEffect, useRef } from 'react'

import valid from 'card-validator'
import update from 'react-addons-update'
import styled, { useTheme } from 'styled-components'
import { v4 } from 'uuid'

import { Button, Icon, LocalIconEnums } from '@atoms/index'
import { ResponsivePXValue } from '@components/Theme'
import { CreditCardInfoFragment } from '@hooks/api'
import { TextInput, CheckBoxInput, Form, useForm } from '@molecules/index'
import { PeachPaymentsCreditCardTypeEnum } from '@uctypes/api/globalTypes'
import { DeviceContainer } from '@utility/DeviceContainer'
import { Flex } from 'antd'

const Container = styled.div`
  width: 100%;
  .input {
    ${ResponsivePXValue('margin-bottom', '9px')}
  }
  .button {
    ${ResponsivePXValue('margin', '20px 0')}
  }
`

const FlexContainer = styled.div`
 display: flex;
 ${ResponsivePXValue('gap', { mobile: '12px', tablet: '16px', desktop: '16px' })}
 .flex-item {
   ${ResponsivePXValue('width', { mobile: '100%', tablet: 'CALC(50% - 8px)', desktop: 'CALC(50% - 8px)' })}
 }
 .card-number {
   flex-grow: 1;
 }
 .card-icons {
   flex-shrink: 0;
 }

 ${ResponsivePXValue('flex-wrap', { mobile: 'wrap' })}
`

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${ResponsivePXValue('padding', '24px 0')}

  .credit-card-button {
    flex-shrink: 0;
  }
  
  .continue {
    ${ResponsivePXValue('width', '200px')}
  }

  .mobile-button {
    flex-grow: 1;
  }
`

const ButtonSpacer = styled.div`
  flex-grow: 1;
`

const IconsContainer = styled.div`
  ${ResponsivePXValue('display', { mobile: 'none', tablet: 'none', desktop: 'flex' })}
  align-items: center;
  justify-content: center;

  ${ResponsivePXValue('gap', '8px')}

  .icon {
    ${ResponsivePXValue('width', { mobile: '46px', tablet: '46px', desktop: '46px' })}
    ${ResponsivePXValue('height', { mobile: '46px', tablet: '46px', desktop: '46px' })}
  }

`

export interface AddCreditCardFormData {
  brand: PeachPaymentsCreditCardTypeEnum
  nickname: string
  ccname: string
  cardnumber: string
  expiryMonth: string
  expiryYear: string
  cvv: string
  saveCard: boolean
}

type FormKeys = 'ccname' | 'cardnumber' | 'expiry' | 'cvv' | 'saveCard' | 'cardnickname'

type AddCreditCardFormDataInternal = Record<FormKeys, string>

interface FormItem {
  touched: boolean
  valid: boolean
  error: string
}

type AddCreditCardFormItems = Record<FormKeys, FormItem>

export interface AddCreditCardFormProps {
  loading?: boolean
  onSuccess: (creditCard: CreditCardInfoFragment | null) => void
  onCancel?: () => void
  onContinue?: () => void
}

interface AddCreditCardFormState {
  loading: boolean
  cardType: PeachPaymentsCreditCardTypeEnum | ''
  cvvLabel: string
  items: AddCreditCardFormItems
  valid: boolean
}

const DEFAULT_STATE: AddCreditCardFormState = {
  loading: false,
  cardType: '',
  cvvLabel: 'CVV',
  // @ts-ignore
  items: {
    ccname: {
      touched: false,
      valid: false,
      error: '',
    },
    cardnumber: {
      touched: false,
      valid: false,
      error: '',
    },
    expiry: {
      touched: false,
      valid: false,
      error: '',
    },
    cvv: {
      touched: false,
      valid: false,
      error: '',
    },
    cardnickname: {
      touched: false,
      valid: false,
      error: '',
    },
  },
  valid: false,
}

const SUPPORTED_CARDS = [PeachPaymentsCreditCardTypeEnum.VISA, PeachPaymentsCreditCardTypeEnum.MASTERCARD, PeachPaymentsCreditCardTypeEnum.AMERICAN_EXPRESS]

const getCardType = (type: string): PeachPaymentsCreditCardTypeEnum => {
  switch (type) {
    case 'visa':
      return PeachPaymentsCreditCardTypeEnum.VISA
    case 'mastercard':
      return PeachPaymentsCreditCardTypeEnum.MASTERCARD
    case 'american-express':
      return PeachPaymentsCreditCardTypeEnum.AMERICAN_EXPRESS
    default:
      break
  }
}

export function AddCreditCardForm({ onSuccess, onCancel, onContinue, loading }: AddCreditCardFormProps): JSX.Element {

  const [state, setState] = useState<AddCreditCardFormState>({ ...DEFAULT_STATE })
  const form = useForm()
  const theme = useTheme()
  const buttonRef: React.RefObject<HTMLButtonElement> = useRef()

  const validateForm = async (): Promise<void> => {

    const data: AddCreditCardFormDataInternal = form.getFieldsValue()
    const allKeys = Object.keys(state.items) as (FormKeys)[]
    const items = { ...state.items }
    let cardType = state.cardType
    let cvvLabel = state.cvvLabel

    for (let i = 0; i < allKeys.length; i++) {
      const value = data[allKeys[i]] || ''
      switch (allKeys[i]) {
        case 'ccname':
          if (value.length === 0) {
            items.ccname.error = items.ccname.touched ? 'Please enter the name on this card' : ''
            items.ccname.valid = false
          } else {
            items.ccname.error = ''
            items.ccname.valid = true
          }
          break
        case 'cardnumber':
          if (value.length === 0) {
            items.cardnumber.error = items.cardnumber.touched ? 'Please enter the number on this card' : ''
            items.cardnumber.valid = false
          } else {
            const isValid = valid.number(value.replace(' ', ''))
            if (!isValid.isValid) {
              items.cardnumber.error = items.cardnumber.touched ? 'Please enter a valid credit card number' : ''
              items.cardnumber.valid = false
            } else {
              const cardNum = value?.replace(/\s/g, '') || ''
              const cardInfo = valid?.number(cardNum)
              cardType = getCardType(cardInfo?.card?.type)
              const numberError = ''
              if (cardInfo?.isValid && !SUPPORTED_CARDS.includes(cardType)) {
                items.cardnumber.error = `Sorry we do not support ${cardInfo?.card?.niceType}`
                items.cardnumber.valid = false
              } else {
                items.cardnumber.error = ''
                items.cardnumber.valid = true
              }
              cvvLabel = cardType === PeachPaymentsCreditCardTypeEnum.AMERICAN_EXPRESS ? 'CID' : 'CVV'
              setState((prevState) => ({
                ...prevState,
                cardType,
                cvvLabel,
              }))
            }
          }
          break
        case 'expiry':
          if (value.length === 0) {
            items.expiry.error = items.expiry.touched ? 'Please enter the cards expiry date' : ''
            items.expiry.valid = false
          } else {
            const isValid = value.match(/^[0-9]{2}\s.\s[0-9]{4}$/)
            if (!isValid) {
              items.expiry.error = items.expiry.touched ? 'Please enter a valid expiry date' : ''
              items.expiry.valid = false
            } else {
              items.expiry.error = ''
              items.expiry.valid = true
            }
          }
          break
        case 'cvv':
          if (value.length === 0) {
            items.cvv.error = items.cvv.touched ? `Please enter the cards ${state.cvvLabel}` : ''
            items.cvv.valid = false
          } else {
            if (value.length < 3) {
              items.cvv.error = items.cvv.touched ? `Please a valid ${state.cvvLabel}` : ''
              items.cvv.valid = false
            } else {
              items.cvv.error = ''
              items.cvv.valid = true
            }
          }
          break
        case 'cardnickname':
          if (value.length > 20) {
            items.cardnickname.error = 'Nickname too long';
            items.cardnickname.valid = false;
          } else {
            items.cardnickname.error = '';
            items.cardnickname.valid = true;
          }
            
        default:
          break
      }
    }
    setState((prevState) => update(prevState, {
      valid: {
        $set: !Object.keys(items).find((key: FormKeys) => !items[key].valid),
      },
      cardType: {
        $set: cardType,
      },
      cvvLabel: {
        $set: cvvLabel,
      },
      items: {
        $set: items,
      },
    }))
  }

  const _handleBlur = (item: FormKeys): void => {
    setState((prevState) => update(prevState, {
      items: {
        [item]: {
          touched: {
            $set: true,
          },
        },
      },
    }))
  }

  useEffect(() => {
    validateForm()
  }, [...Object.keys(state.items).map((item: FormKeys) => state.items[item].touched)])

  useEffect(() => {
    if (state.valid) {
      const data: AddCreditCardFormDataInternal = form.getFieldsValue()
      const expiry = data.expiry
      const expiryParts = expiry.split(' / ')
      delete data.expiry
      const cardInfo: CreditCardInfoFragment = {
        id: v4(),
        name: data.ccname,
        cvv: data.cvv,
        nickname: data.cardnickname ? data.cardnickname : 'My Card',
        number: data.cardnumber.replace(/\s/g, ''),
        expiryMonth: expiryParts[0],
        expiryYear: expiryParts[1],
        brand: state.cardType as PeachPaymentsCreditCardTypeEnum,
        saveCard: ((data.saveCard || []) as unknown as string[]).includes('TRUE'),
        __typename: 'CreditCardInfo',
      }
      onSuccess(cardInfo)
    } else {
      onSuccess(null)
    }
  }, [state.valid, form.getFieldValue('saveCard')?.length])

  useEffect(() => {
    const monthOptions: { title: string, value: string }[] = []
    const yearOptions: { title: string, value: string }[] = []
    for (let m = 1; m < 13; m++) {
      monthOptions.push({ title: `${m}`.padStart(2, '0'), value: `${m}  `.padStart(2, '0') })
    }
    const year = 2022
    for (let y = year; y < year + 10; y++) {
      yearOptions.push({ title: `${y}`, value: `${y}` })
    }
    setState((prevState) => ({ ...prevState, monthOptions, yearOptions }))
  }, [])

  return (
    <Container>
      <Form form={form} onValuesChange={validateForm} loading={loading}>
        <DeviceContainer desktop tablet>
          <FlexContainer >
            <TextInput
              autoComplete='cc-number'
              name='cardnumber'
              label='Card Number'
              variant='creditCard'
              wrapperClassName='input'
              errorMessage={state.items.cardnumber.error}
              onBlur={() => _handleBlur('cardnumber')}
              placeholder='____ ____ ____ ____' />
            <IconsContainer>
              <Icon
                className='icon'
                icon={LocalIconEnums.PAYMENT_VISA_COLOR}/>
              <Icon
                className='icon'
                icon={LocalIconEnums.PAYMENT_AMERICAN_EXPRESS_COLOR}/>
              <Icon
                className='icon'
                icon={LocalIconEnums.PAYMENT_MASTER_CARD_COLOR}/>
            </IconsContainer>
          </FlexContainer>
          <TextInput
            autoComplete='cc-name'
            name='ccname'
            label='Name on Card'
            wrapperClassName='card-number input'
            errorMessage={state.items.ccname.error}
            onBlur={() => _handleBlur('ccname')}
            placeholder='Name on Card' />
          <FlexContainer>
            <TextInput
              autoComplete='cc-exp'
              name='expiry'
              label='Expiry'
              variant='expiryDate'
              wrapperClassName='flex-item input'
              placeholder='MM / YYYY'
              errorMessage={state.items.expiry.error}
              onBlur={() => _handleBlur('expiry')} />
            <TextInput
              autoComplete='cc-csc'
              name='cvv'
              label={state.cvvLabel}
              wrapperClassName='flex-item input'
              errorMessage={state.items.cvv.error}
              onBlur={() => _handleBlur('cvv')}
              placeholder={state.cvvLabel} />
            <TextInput 
              autoComplete='off'
              name='cardnickname' 
              label='Nickname (optional)'
              wrapperClassName='flex-item input'
              errorMessage={state.items.cardnickname.error}
              onBlur={() => _handleBlur('cardnickname')}
              placeholder='e.g., My Travel Card'/>  
          </FlexContainer>
          <CheckBoxInput
            name='saveCard'
            options={[{ title: 'Save Card', value: 'TRUE' }]} />
        </DeviceContainer>
        <DeviceContainer mobile>
          <FlexContainer>
            <TextInput
                autoComplete='cc-number'
                name='cardnumber'
                label='Card Number'
                variant='creditCard'
                wrapperClassName='input'
                errorMessage={state.items.cardnumber.error}
                onBlur={() => _handleBlur('cardnumber')}
                placeholder='____ ____ ____ ____' />
              <IconsContainer>
                <Icon
                  className='icon'
                  icon={LocalIconEnums.PAYMENT_VISA_COLOR}/>
                <Icon
                  className='icon'
                  icon={LocalIconEnums.PAYMENT_AMERICAN_EXPRESS_COLOR}/>
                <Icon
                  className='icon'
                  icon={LocalIconEnums.PAYMENT_MASTER_CARD_COLOR}/>
              </IconsContainer>
            <TextInput
              autoComplete='cc-name'
              name='ccname'
              label='Name on Card'
              wrapperClassName='card-number input'
              errorMessage={state.items.ccname.error}
              onBlur={() => _handleBlur('ccname')}
              placeholder='Name on Card' />
          <TextInput
              autoComplete='cc-exp'
              name='expiry'
              label='Expiry'
              variant='expiryDate'
              wrapperClassName='flex-item input'
              placeholder='MM / YYYY'
              errorMessage={state.items.expiry.error}
              onBlur={() => _handleBlur('expiry')} />
            <TextInput
              autoComplete='cc-csc'
              name='cvv'
              label={state.cvvLabel}
              wrapperClassName='flex-item input'
              errorMessage={state.items.cvv.error}
              onBlur={() => _handleBlur('cvv')}
              placeholder={state.cvvLabel} />
            <TextInput 
              autoComplete='off'
              name='cardnickname' 
              label='Nickname (optional)'
              wrapperClassName='flex-item input'
              errorMessage={state.items.cardnickname.error}
              onBlur={() => _handleBlur('cardnickname')}
              placeholder='e.g., My Travel Card'/>  

          </FlexContainer>
          <CheckBoxInput
          name='saveCard'
          options={[{ title: 'Save Card', value: 'TRUE' }]} />
        </DeviceContainer>
        <DeviceContainer desktop tablet>
          <ButtonContainer>
            <If condition={state.valid && !!onContinue}>
              <Button
                title='CONTINUE'
                size='medium'
                loading={loading}
                onClick={onContinue}
                className='credit-card-button continue' />
            </If>
            <ButtonSpacer />
            <If condition={!!onCancel}>
              <Button title='Cancel' variant='ghost' size='medium' onClick={onCancel} className='credit-card-button' />
            </If>
          </ButtonContainer>
        </DeviceContainer>
        <DeviceContainer mobile>
          <ButtonContainer>
            <If condition={!!onCancel}>
              <Button title='Cancel' variant='ghost' size='medium' onClick={onCancel} className='mobile-button' />
            </If>
          </ButtonContainer>
        </DeviceContainer>
      </Form>
    </Container>
  )

}
