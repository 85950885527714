import React from 'react'

import { Field, FormInstance } from 'rc-field-form'
import { Meta, Rule } from 'rc-field-form/es/interface.d'
import styled, { css, CSS, useTheme } from 'styled-components'

import { SmallLoader } from '@atoms/index'
import { ResponsivePXValue } from '@components/Theme'
import { useGetApiEnumQuery } from '@hooks/api/index'
import { InputWrapper, FormContext, FormContextProperties } from '@molecules/inputs/index'

export const Container = styled.div<{ direction: 'vertical' | 'horizontal' }>`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: ${(props): string => props.direction === 'vertical' ? 'column' : 'row'};
  ${ResponsivePXValue('gap', '10px')}
`

export const ItemContainer = styled.div<{ loading: boolean, isVertical: boolean }>`

  cursor: ${(props): string => props.loading ? 'inherit' : 'pointer'};
  display: flex;
  align-self: flex-start;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: ${(props): string => props.isVertical ? '100%' : 'auto'};
  flex-shrink: 1; 
`

const ButtonOuterContainer = styled.div<{ selected: boolean, disabled: boolean }>`

  border-style: solid;
  border-color: ${(props): string => props.theme.colors.grey.silver};
  ${ResponsivePXValue('width', '16px')}
  ${ResponsivePXValue('height', '16px')}
  ${ResponsivePXValue('padding', '2px')}
  ${ResponsivePXValue('margin', { mobile: '6px 1px', tablet: '4px 2px', desktop: '3px 0' })}
  ${ResponsivePXValue('border-radius', '14px')}
  ${ResponsivePXValue('border-width', '1px')}

  ${(props): CSS => {
    if (props.selected) {
      return css`
        border-color: ${(props): string => props.theme.colors.green.greenVogue};
        ${ResponsivePXValue('border-width', { mobile: '4px', tablet: '4px', desktop: '4px' })}
      `
    }
    if (props.disabled) {
      return css`
        border-color: ${(props): string => props.theme.colors.white.romance};
      `
    }
  }}

`
const ItemLabel = styled.div<{ color: string, isVertical: boolean }>`

  position: relative;
  font-family: open-sans;
  color: ${(props): string => props.color};
  width: ${(props): string => props.isVertical ? '100%' : 'auto'};
  ${ResponsivePXValue('font-weight', { mobile: '400', tablet: '400', desktop: '400' })};
  ${ResponsivePXValue('margin-left', '5px')}
  ${ResponsivePXValue('top', '0px')}
  ${ResponsivePXValue('font-size', { mobile: '14px', tablet: '14px', desktop: '14px' })}

`

const LoadingContainer = styled.div`
  position: absolute;
  ${ResponsivePXValue('right', '12px')}
  ${ResponsivePXValue('top', '12px')}
  ${ResponsivePXValue('width', '20px')}
  ${ResponsivePXValue('height', '20px')}
`

interface Values {
  value?: string | number
  onChange?: (value: string | number) => void
}

interface RadioInputInnerProps extends RadioFormInputProps {
  control: Values
  meta: Meta
  form: FormInstance
}

function RadioInner(props: RadioInputInnerProps): JSX.Element {

  const { label, showLabel = true, variant = 'vertical', options, loading, apiEnum, disabled, rules, control, meta, form, className, wrapperClassName } = props
  const { data, loading: apiLoading } = useGetApiEnumQuery({ variables: { enum: apiEnum }, skip: !apiEnum })

  const theme = useTheme()

  let availableOptions: RadioOption[] = options ?? []
  if (data?.enum?.values) {
    availableOptions = data.enum.values.map((enumVal) => ({ title: enumVal.title, value: enumVal.value }))
  }

  const required = !!(
    rules &&
    rules.some(rule => {
      if (rule && typeof rule === 'object' && rule.required) {
        return true
      }
      if (typeof rule === 'function') {
        const ruleEntity = rule(form)
        return ruleEntity && ruleEntity.required
      }
      return false
    })
  )
  const error = meta.errors?.[0]
  const { value, onChange } = control

  const _handleChange = (value: string | number, locallyDisabled: boolean) => {
    if (disabled || loading || apiLoading || locallyDisabled) {
      return
    }
    const newValue = value
    onChange(newValue)
  }

  let option: RadioOption
  const labelColor = disabled || loading || apiLoading ? theme.colors.white.romance : theme.colors.green.bottleGreen

  return (
    <InputWrapper
      required={required}
      label={label}
      showLabel={showLabel}
      error={error}
      className={wrapperClassName}>
      <FormContext.Consumer>
        {({ loading: formLoading, disabled: formDisabled }: FormContextProperties) => (
          <Container direction={variant} className={className}>
            <For each='option' of={availableOptions}>
              <ItemContainer
                isVertical={variant === 'vertical'}
                loading={disabled || loading || apiLoading || formLoading || formDisabled}
                key={option.value.toString()}
                data-selected={option.value === value}
                data-disabled={disabled || option.disabled}
                onClick={() => _handleChange(option.value, option.disabled)}>
                <ButtonOuterContainer
                  disabled={disabled || loading || apiLoading || option.disabled}
                  selected={option.value === value}>
                </ButtonOuterContainer>
                <ItemLabel color={labelColor} isVertical={variant === 'vertical'}>{option.title}</ItemLabel>
              </ItemContainer>
            </For>
            <If condition={loading || apiLoading || formLoading}>
              <LoadingContainer>
                <SmallLoader
                  color={theme.colors.grey.gallery} />
              </LoadingContainer>
            </If>
          </Container>
        )}
      </FormContext.Consumer>
    </InputWrapper>
  )
}

export interface RadioOption {
  title: React.ReactNode
  value: string | number
  disabled?: boolean
}

export interface RadioFormInputProps {
  rules?: Rule[]
  label?: string
  showLabel?: boolean
  name: string
  loading?: boolean
  disabled?: boolean
  variant?: 'vertical' | 'horizontal'
  apiEnum?: string
  options?: RadioOption[]
  wrapperClassName?: string
  className?: string
}

export function RadioInput(props: RadioFormInputProps): JSX.Element {

  const { name, rules } = props

  return (
    <Field name={name} rules={rules}>
      {(control: Values, meta: Meta, form: FormInstance) => <RadioInner {...props} control={control} meta={meta} form={form} />}
    </Field>
  )

}
