import React from 'react'

import styled from 'styled-components'

import { ResponsivePXValue, theme } from '@components/Theme'

export const ProductLabels = styled.div`
  flex-direction: column;
  position: absolute;
  justify-content: space-between;
  display: flex;
  top: 0;
  left: 0;
  ${ResponsivePXValue('gap', '4px')}
`

export const ProductLabel = styled.div<{backgroundColor: string, color: string}>`
  font-family: open-sans;
  color: ${props => (props.color) ? props.color : theme.colors.white.fantasy};
  ${ResponsivePXValue('padding', '4px 8px')}
  ${ResponsivePXValue('font-size', '10px')}
  background-color: ${props => (props.backgroundColor) ? props.backgroundColor : theme.colors.white.fantasy};
  font-weight: 600;
  ${ResponsivePXValue('line-height', { mobile: '17px', tablet: '18px', desktop: '18px' })}
`
