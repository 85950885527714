import React from 'react'

import loadable from '@loadable/component'

// import(/* webpackPrefetch: true */ '@components/builder/pages/PageBuilderPage')

const PageBuilderPage = loadable(() => import(/* webpackChunkName: "page-builder-page" */'@components/builder/pages/PageBuilderPage'), {
  resolveComponent: (components) => components.PageBuilderPage,
})

export function PageBuilderPageAsync(): JSX.Element {

  return <PageBuilderPage fallback={<div>Loading...</div>} />

}
