import React from 'react'

import styled, { CSS, css, useTheme } from 'styled-components'

import { ResponsivePXValue } from '@components/Theme'

const Container = styled.div`
  position: relative; 
`

const BadgeContainer = styled.div<{ offsetY: number, offsetX: number, color: string, backgroundColor: string, size: 'small' | 'large' }>`

  background-color: ${(props): string => props.backgroundColor};
  color: ${(props): string => props.color};
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: open-sans;
  font-weight: 700;
  pointer-events: none;
  transform: translateY(${(props): number => props.offsetY}%) translateX(${(props): number => props.offsetX}%);

  ${(props): CSS => {
    if (props.size === 'large') {
      return css`
        ${ResponsivePXValue('border-radius', '12px')}
        ${ResponsivePXValue('padding', '6px')}
        ${ResponsivePXValue('font-size', '12px')}
        ${ResponsivePXValue('min-width', '24px')}
        ${ResponsivePXValue('height', '24px')}
        ${ResponsivePXValue('top', '-6px')}
        ${ResponsivePXValue('right', '-6px')}
      `
    } else if (props.size === 'small') {
      return css`
        ${ResponsivePXValue('border-radius', '9px')}
        ${ResponsivePXValue('padding', '4px')}
        ${ResponsivePXValue('font-size', '10px')}
        ${ResponsivePXValue('min-width', '18px')}
        ${ResponsivePXValue('height', '18px')}
        ${ResponsivePXValue('top', '-5px')}
        ${ResponsivePXValue('right', '-5px')}
      `
    } else {
      return css`
        ${ResponsivePXValue('border-radius', '9px')}
        ${ResponsivePXValue('padding', '4px')}
        ${ResponsivePXValue('font-size', '6px')}
        ${ResponsivePXValue('min-width', '12px')}
        ${ResponsivePXValue('height', '12px')}
        ${ResponsivePXValue('top', '-5px')}
        ${ResponsivePXValue('right', '-5px')}
      `
    }
  }}

`

const TextContainer = styled.span`

  position: relative;

`

export interface BadgeProps {
  children: React.ReactNode
  count: number
  color?: string
  backgroundColor?: string
  offsetY?: number
  offsetX?: number
  size?: 'small' | 'large'
}

export function Badge({ children, count, offsetX = 40, offsetY = -40, color, backgroundColor, size = 'large' }: BadgeProps): JSX.Element {

  const theme = useTheme()

  return (
    <Container>
      {children}
      <BadgeContainer
        size={size}
        color={color || theme.colors.white.floralWhite}
        backgroundColor={backgroundColor || theme.colors.orange.casablanca}
        offsetX={offsetX}
        offsetY={offsetY}>
        <TextContainer>
          {count ?? 0}
        </TextContainer>
      </BadgeContainer>
    </Container>
  )

}
