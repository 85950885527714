
import React from 'react'

import loadable from '@loadable/component'

import { PageLoader } from '@atoms/notifications'

import(/* webpackPrefetch: true */ '@pages/Cart')

const Cart = loadable(() => import(/* webpackChunkName: "cart" */'@pages/Cart'), {
  resolveComponent: (components) => components.Cart,
})

export function CartAsync(): JSX.Element {

  return <Cart fallback={<PageLoader />} />

}
