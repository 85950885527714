import { getIcon, LocalIconEnums } from '@atoms/index'

export interface IconProps {
  icon: LocalIconEnums
  color?: string
  hoverColor?: string
  skeleton?: boolean
  className?: string
}

export function Icon({ icon, color, hoverColor, className }: IconProps): JSX.Element {

  const $icon = getIcon({ icon, color, hoverColor, className })

  return $icon

}
