import React, { useState, useEffect } from 'react'

import useEmblaCarousel from 'embla-carousel-react'
import update from 'react-addons-update'
import styled from 'styled-components'

import { APP_DEFAULT_STATE } from '@api/local/AppPlugin'
import { ResponsiveImage, Icon, LocalIconEnums } from '@atoms/images'
import { ResponsivePXValue, theme } from '@components/Theme'
import { useGetAppQuery, ProductDetailsImageFragment } from '@hooks/api/index'
import { Modal } from '@molecules/index'
import { DeviceTypeEnum } from '@uctypes/api/globalTypes'

const Container = styled.div`
  position: relative;
  display: flex;
  ${ResponsivePXValue('flex-direction', { mobile: 'column', tablet: 'column', desktop: 'row-reverse' })}
  ${ResponsivePXValue('width', { mobile: '100%', tablet: '100%', desktop: '1000px' })}
`

const ImageContainer = styled.div`
  width: 100%;
  ${ResponsivePXValue('height', { mobile: 'CALC(100vh - 130px)', tablet: 'CALC(100vh - 130px)', desktop: 'CALC(80vh - 100px)' })}
  ${ResponsivePXValue('width', { mobile: '100%', tablet: '100%', desktop: '1000px' })}
  ${ResponsivePXValue('padding', { mobile: '10px', tablet: '10px', desktop: '20px' })}
`

const SliderContainer = styled.div`
  display: flex;
  ${ResponsivePXValue('flex-direction', { mobile: 'row', tablet: 'row', desktop: 'column' })}
  align-items: center;
  justify-content: center;
  ${ResponsivePXValue('width', { mobile: '100%', tablet: '100%', desktop: '80px' })}
  ${ResponsivePXValue('height', { mobile: '50px', tablet: '50px', desktop: 'CALC(80vh - 100px)' })}
  ${ResponsivePXValue('margin', { mobile: '10px 0', tablet: '10px 0', desktop: '0 20px' })}
`

const CarouselContainer = styled.div`
  ${ResponsivePXValue('height', { mobile: '50px', tablet: '50px', desktop: 'CALC(80vh - 200px)' })}
  ${ResponsivePXValue('width', { mobile: '240px', tablet: '280px' })}
`

const Embla = styled.div`
  position: relative;
  height: 100%;
  overflow: hidden;
`

const EmblaContainer = styled.div`
  position: relative;
  display: flex;
  ${ResponsivePXValue('flex-direction', { mobile: 'row', tablet: 'row', desktop: 'column' })}
  ${ResponsivePXValue('gap', { mobile: '20px', tablet: '10px', desktop: '6px' })}
`

const EmblaSlide = styled.div<{ selected: boolean }>`
  position: relative;
  user-select: none;
  ${ResponsivePXValue('flex', { mobile: '0 0 45px', tablet: '0 0 50px', desktop: '0 0 70px' })}
  ${ResponsivePXValue('height', { mobile: '50px', tablet: '50px', desktop: '70px' })}
  ${(props): string => props.selected
    ? ResponsivePXValue('border', `3px solid ${props.theme.colors.blue.curiousBlue}`)
    : ResponsivePXValue('border', `1px solid ${props.theme.colors.grey.desertStorm}`)}
  ${(props): string => props.selected
    ? ResponsivePXValue('padding', { mobile: '0', tablet: '0', desktop: '8px' })
    : ResponsivePXValue('padding', { mobile: '2px', tablet: '2px', desktop: '10px' })}
`

const TopControl = styled.div<{ visible: boolean }>`
  user-select: none;
  flex-shrink: 0;
  opacity: ${(props): number => props.visible ? 1 : 0};
  ${ResponsivePXValue('width', '50px')}
  ${ResponsivePXValue('height', '50px')}
  ${ResponsivePXValue('padding', { mobile: '5px', tablet: '10px', desktop: '10px' })}
`

const BottomControl = styled.div<{ visible: boolean }>`
  user-select: none;
  flex-shrink: 0;
  opacity: ${(props): number => props.visible ? 1 : 0};
  ${ResponsivePXValue('width', '50px')}
  ${ResponsivePXValue('height', '50px')}
  ${ResponsivePXValue('padding', { mobile: '5px', tablet: '0', desktop: '10px' })}
`

export interface ProductImageGalleryModalProps {
  index: number
  onIndexChange: (index: number) => void
  images: ProductDetailsImageFragment[]
  open: boolean
  onClose: () => void
}

interface ProductImageGalleryModalState {
  scrollOffset: number
}

const DEFAULT_STATE: ProductImageGalleryModalState = {
  scrollOffset: 0,
}

export function ProductImageGalleryModal({ images, open, index, onIndexChange, onClose }: ProductImageGalleryModalProps): JSX.Element {

  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()
  const [state, setState] = useState<ProductImageGalleryModalState>({ ...DEFAULT_STATE })
  const [emblaRef, emblaApi] = useEmblaCarousel({
    draggable: false,
    axis: appData.app.deviceType === DeviceTypeEnum.DESKTOP ? 'y' : 'x',
    align: 'start',
  })

  const maxImages = appData.app.deviceType === DeviceTypeEnum.DESKTOP ? 10 : appData.app.deviceType === DeviceTypeEnum.TABLET ? 5 : 4
  const selectedImage = images?.[index]
  const showTopControl = state.scrollOffset > 0
  const showBottomControl = state.scrollOffset + maxImages < images.length

  const _handleSelectThumbNail = (index: number): void => {
    onIndexChange(index)
  }

  const _handleGoUp = (): void => {
    if (!showTopControl) {
      return
    }
    setState((prevState) => update(prevState, {
      scrollOffset: { $set: prevState.scrollOffset - 1 },
    }))
  }

  const _handleGoDown = (): void => {
    if (!showBottomControl) {
      return
    }
    setState((prevState) => update(prevState, {
      scrollOffset: { $set: prevState.scrollOffset + 1 },
    }))
  }

  useEffect(() => {
    if (emblaApi) {
      emblaApi.scrollTo(state.scrollOffset)
    }
  }, [state.scrollOffset])

  let image: ProductDetailsImageFragment
  let imageIndex: number

  return (
    <Modal open={open} title='Product Images' onClose={onClose}>
      <Container>
        <ImageContainer>
          <Choose>
            <When condition={!!selectedImage}>
              <ResponsiveImage image={selectedImage} objectFit='contain' />
            </When>
            <Otherwise>
              No Preview
            </Otherwise>
          </Choose>
        </ImageContainer>
        <SliderContainer>
          <TopControl onClick={_handleGoUp} visible={showTopControl}>
            <Icon
              icon={appData.app.deviceType === DeviceTypeEnum.DESKTOP || appData.app.deviceType === DeviceTypeEnum.ULTRA
                ? LocalIconEnums.CHEVRON_UP
                : LocalIconEnums.CHEVRON_LEFT}
              color={theme.colors.blue.curiousBlue} />
          </TopControl>
          <CarouselContainer>
            <Embla ref={emblaRef}>
              <EmblaContainer>
                <For each='image' index='imageIndex' of={images}>
                  <EmblaSlide
                    selected={imageIndex === index}
                    key={`${image.id}-${imageIndex}`}
                    onClick={() => _handleSelectThumbNail(imageIndex)}>
                    <ResponsiveImage image={image} objectFit='contain' />
                  </EmblaSlide>
                </For>
              </EmblaContainer>
            </Embla>
          </CarouselContainer>
          <BottomControl onClick={_handleGoDown} visible={showBottomControl}>
            <Icon
              icon={appData.app.deviceType === DeviceTypeEnum.DESKTOP || appData.app.deviceType === DeviceTypeEnum.ULTRA
                ? LocalIconEnums.CHEVRON_DOWN
                : LocalIconEnums.CHEVRON_RIGHT}
              color={theme.colors.blue.curiousBlue} />
          </BottomControl>
        </SliderContainer>
      </Container>
    </Modal>
  )

}
