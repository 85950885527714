
import React from 'react'

import loadable from '@loadable/component'

import { PageLoader } from '@atoms/notifications'

import(/* webpackPrefetch: true */ '@pages/NotFound')

const NotFound = loadable(() => import(/* webpackChunkName: "not-found" */'@pages/NotFound'), {
  resolveComponent: (components) => components.NotFound,
})

export function NotFoundAsync(): JSX.Element {

  return <NotFound fallback={<PageLoader />} />

}
