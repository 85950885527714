import React, { useState } from 'react'

import { useNavigate } from 'react-router'
import styled, { css } from 'styled-components'

import { Icon, LocalIconEnums, SmallLoader } from '@atoms/index'
import { ResponsivePXValue, theme } from '@components/Theme'

export interface IconButtonColors {
  color?: string
  backgroundColor?: string
  borderColor?: string
  hoverColor?: string
  hoverBackgroundColor?: string
  hoverBorderColor?: string
  disabledColor?: string
  disabledBackgrondColor?: string
  disabledBorderColor?: string
}

interface ElementProps extends IconButtonColors {
  isDisabled: boolean
  isLoading: boolean
  shape: 'round' | 'square'
}

const CommonStyle = css`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-style: solid;

  ${ResponsivePXValue('width', '20px')}
  ${ResponsivePXValue('height', '20px')}
  ${ResponsivePXValue('border-width', '1px')}
  ${ResponsivePXValue('padding', { mobile: '3px', tablet: '5px', desktop: '5px' })}
`

const DivIconButton = styled.div<ElementProps>`

  color: ${(props): string => props.isDisabled ? props.disabledColor : props.color};
  background-color: ${(props): string => props.isDisabled ? props.disabledBackgrondColor : props.backgroundColor};
  border-color: ${(props): string => props.isDisabled ? props.disabledBorderColor : props.borderColor};

  &:hover {
    color: ${(props): string => props.isDisabled ? props.disabledColor : props.hoverColor};
    background-color: ${(props): string => props.isDisabled ? props.disabledBorderColor : props.hoverBackgroundColor};
    border-color: ${(props): string => props.isDisabled ? props.disabledBorderColor : props.hoverBorderColor};
  }
  cursor: ${(props): string => props.isDisabled ? 'not-allowed' : 'pointer'};
  border-radius: ${(props): string => props.shape === 'round' ? '50%' : '0'};

  ${CommonStyle}

`

const AnchorIconButton = styled.a<ElementProps>`

  color: ${(props): string => props.isDisabled ? props.disabledColor : props.color};
  background-color: ${(props): string => props.isDisabled ? props.disabledBackgrondColor : props.backgroundColor};
  border-color: ${(props): string => props.isDisabled ? props.disabledBorderColor : props.borderColor};

  &:hover {
    color: ${(props): string => props.isDisabled ? props.disabledColor : props.hoverColor};
    background-color: ${(props): string => props.isDisabled ? props.disabledBorderColor : props.hoverBackgroundColor};
    border-color: ${(props): string => props.isDisabled ? props.disabledBorderColor : props.hoverBorderColor};
  }
  cursor: ${(props): string => props.isDisabled ? 'not-allowed' : 'pointer'};
  border-radius: ${(props): string => props.shape === 'round' ? '50%' : '0'};

  ${CommonStyle}


`

const IconButtonLoaderContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;

`

const IconButtonLoader = styled.div`

  ${ResponsivePXValue('width', '28px')}
  ${ResponsivePXValue('height', '28px')}

`

export const iconButtonPreset: IconButtonColors = {
  color: theme.colors.black.codGrey,
  backgroundColor: theme.colors.white.pureWhite,
  borderColor: theme.colors.white.pureWhite,
  hoverColor: theme.colors.green.watercourse,
  hoverBackgroundColor: theme.colors.white.floralWhite,
  hoverBorderColor: theme.colors.white.floralWhite,
  disabledColor: theme.colors.black.codGrey,
  disabledBackgrondColor: theme.colors.grey.gallery,
  disabledBorderColor: theme.colors.grey.gallery,
}

export interface IconButtonProps {
  icon: LocalIconEnums
  shape?: 'round' | 'square'
  colors?: IconButtonColors
  href?: string
  blank?: boolean
  disabled?: boolean
  loading?: boolean
  className?: string
  onClick?: () => void
}

// TODO might be able to make a variant in button but looks good as is for a component

export function IconButton({ icon, shape = 'round', colors = {}, href, blank, disabled, loading, className, onClick }: IconButtonProps): JSX.Element {

  const [hovering, setHovering] = useState<boolean>(false)
  const navigate = useNavigate()

  const _handleDivClicked = (): void => {
    if (onClick && !disabled) {
      onClick()
    }
  }

  const _handleAnchorClicked = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void => {
    if (onClick) {
      e.preventDefault()
      onClick()
    } else if (href.indexOf('http') === -1) {
      e.preventDefault()
      navigate(href)
    }
  }

  const actualColors = { ...iconButtonPreset, ...colors }
  const iconColor = disabled ? actualColors.disabledColor : hovering ? actualColors.hoverColor : actualColors.color

  return (
    <Choose>
      <When condition={!!href}>
        <AnchorIconButton
          {...actualColors}
          shape={shape}
          href={href}
          className={className}
          isDisabled={disabled}
          isLoading={loading}
          target={blank ? '_blank' : '_self'}
          onMouseEnter={(): void => setHovering(true)}
          onMouseLeave={(): void => setHovering(false)}
          onClick={_handleAnchorClicked}>
          <Choose>
            <When condition={loading}>
              <IconButtonLoaderContainer>
                <IconButtonLoader>
                  <SmallLoader color={iconColor} />
                </IconButtonLoader>
              </IconButtonLoaderContainer>
            </When>
            <Otherwise>
              <Icon className='icon' icon={icon} color={iconColor} />
            </Otherwise>
          </Choose>
        </AnchorIconButton>
      </When>
      <Otherwise>
        <DivIconButton
          {...actualColors}
          shape={shape}
          className={className}
          isDisabled={disabled}
          isLoading={loading}
          onMouseEnter={(): void => setHovering(true)}
          onMouseLeave={(): void => setHovering(false)}
          onClick={_handleDivClicked}>
          <Choose>
            <When condition={loading}>
              <IconButtonLoaderContainer>
                <IconButtonLoader>
                  <SmallLoader color={iconColor} />
                </IconButtonLoader>
              </IconButtonLoaderContainer>
            </When>
            <Otherwise>
              <Icon className='icon' icon={icon} color={iconColor} />
            </Otherwise>
          </Choose>
        </DivIconButton>
      </Otherwise>
    </Choose>
  )

}
