import React, { } from 'react'

import { useNavigate } from 'react-router'
import styled from 'styled-components'

import { Heading, Title } from '@atoms/typography'
import { ResponsivePXValue } from '@components/Theme'
import { PageProductSliderSectionFragment, ProductListFragment } from '@hooks/api/index'
import { ProductSlider } from '@organisms/index'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .block-title {
    ${ResponsivePXValue('margin', { mobile: '0 10px', tablet: '0 10px', desktop: '0' })}
  }

  .block-paragraph {
    ${ResponsivePXValue('margin', { mobile: '0 10px', tablet: '0 10px', desktop: '0' })}
  }

`

export interface PageProductSliderSectionProps {
  section: PageProductSliderSectionFragment
}

export function PageProductSliderSection({ section }: PageProductSliderSectionProps): JSX.Element {

  const navigate = useNavigate()

  const _handleViewAll = () => {
    navigate(section.category.canonicalUrl)
  }

  const category = section.category
  const products = category?.products?.items || section.products || []
  const totalCount = category ? category.products.totalCount : products.length

  return (
    <Container>
      <If condition={!!section.title}>
        <Title variant='t1' className='block-title'>
          {section.title}
        </Title>
      </If>
      <ProductSlider
        showCount={false}
        title={section.subTitle}
        total={totalCount}
        onViewAll={category ? _handleViewAll : undefined}
        products={products as ProductListFragment[]} />
    </Container>

  )

}
