import React from 'react'

import styled, { useTheme } from 'styled-components'

import { Icon, LocalIconEnums } from '@atoms/images'
import { Paragraph } from '@atoms/typography'
import { ResponsivePXValue } from '@components/Theme'
import { SiteHelper } from '@lib/SiteHelper'

const Container = styled.div`
  background-color: ${(props): string => SiteHelper.getOpaqueColor(props.theme.colors.green.deYork, 0.15)};
`

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props): string => props.theme.colors.green.greenVogue};
  ${ResponsivePXValue('height', '28px')}
  .upsell-title {
    margin: 0;
  }
`

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const Card = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  ${ResponsivePXValue('gap', '4px')}
  ${ResponsivePXValue('padding', '15px')}

  line-height: 1.2;
  font-family: open-sans;
  font-weight: 400;

  ${ResponsivePXValue('font-size', '14px')}

  color: ${(props): string => props.theme.colors.green.watercourse};

  .text {
    margin: 0;
  }
`

const OrContainer = styled.div`
  font-family: open-sand;
  font-style: italic;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props): string => props.theme.colors.green.watercourse};
  ${ResponsivePXValue('font-size', '12px')}
  ${ResponsivePXValue('padding', '0 10px')}
`

const Strong = styled.span`
  font-weight: 600;
`

const IconContainer = styled.div`
  flex-shrink: 0;
  float: left;
  ${ResponsivePXValue('width', '30px')}
  ${ResponsivePXValue('height', '30px')}
`

export function CartUpSell(): JSX.Element {

  const theme = useTheme()

  return (
    <Container>
      <Header>
        <Paragraph variant='p3' className='upsell-title' color={theme.colors.white.pureWhite}>Purchase R150 or more for</Paragraph>
      </Header>
      <Content>
        <Card>
          <IconContainer>
            <Icon icon={LocalIconEnums.CARBON_NEUTRAL} color={theme.colors.green.watercourse} />
          </IconContainer>
          <Strong>FREE</Strong> <span>carbon</span> <span>neutral</span> <span>deliveries</span>
        </Card>
        <OrContainer>
          OR
        </OrContainer>
        <Card>
          <IconContainer>
            <Icon icon={LocalIconEnums.SHOP} color={theme.colors.green.watercourse} />
          </IconContainer>
          <Strong>FREE</Strong> <span>pargo</span> <span>or</span> <span>in-store</span> <span>collection</span>
        </Card>
      </Content>
    </Container>
  )

}
