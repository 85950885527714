
import React from 'react'
import { Svg } from './Svg'

export interface GridViewProps {
  color?: string
  hoverColor?: string
  className?: string
}

export function GridView({ color, hoverColor, className }: GridViewProps): JSX.Element {

  return (
    <Svg strokeColor={color} strokeHoverColor={hoverColor} fillColor={color} fillHoverColor={hoverColor} className={className} width="100%" height="100%" viewBox="0 0 20 20" version="1.1">
      <rect x="1" y="1" width="8" height="8" className='fill' />
      <rect x="11" y="1" width="8" height="8" className='fill' />
      <rect x="1" y="11" width="8" height="8" className='fill' />
      <rect x="11" y="11" width="8" height="8" className='fill' />
    </Svg>
  )

}
    