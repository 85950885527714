import React, { useState, useEffect } from 'react'

import useEmblaCarousel from 'embla-carousel-react'
import update from 'react-addons-update'
import { useNavigate } from 'react-router'
import styled, { useTheme } from 'styled-components'

import { Button } from '@atoms/buttons'
import { LocalIconEnums } from '@atoms/images'
import { Link, Paragraph } from '@atoms/typography'
import { ResponsivePXValue } from '@components/Theme'
import { PageLinkFragment, PageCarouselSectionFragment } from '@hooks/api/index'
import { SiteHelper } from '@lib/SiteHelper'

const Container = styled.div`
  width: 100%;
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  ${ResponsivePXValue('gap', '16px')}
`

const StaticBlock = styled.div<{ backgroundColor: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex:1;
  ${ResponsivePXValue('padding', '16px')}
  background-color: ${({ backgroundColor }) => backgroundColor};
`

const DynamicBlock = styled.div<{ backgroundColor: string }>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex:1;
  ${ResponsivePXValue('padding', '16px')}
  background-color: ${({ backgroundColor }) => backgroundColor};

  .slidePg {
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
  }

  .prevBtn {
    position: absolute;
    left: 0;
    ${ResponsivePXValue('left', { mobile: '30px', tablet: '30px', desktop: '30px' })}
  }

  .nextBtn {
    position: absolute;
    right: 0;
    ${ResponsivePXValue('right', { mobile: '30px', tablet: '30px', desktop: '30px' })}
  }
`

const Embla = styled.div`
  overflow: hidden;
`

const EmblaContainer = styled.div`
  display: flex;

`

const EmblaSlide = styled.div`
  ${ResponsivePXValue('flex', '0 0 100%')}
`

export interface PageCarouselSectionProps {
  section: PageCarouselSectionFragment
}

interface PageCarouselSectionState {
  currentIdx: number
}

const DEFAULT_STATE: PageCarouselSectionState = {
  currentIdx: 0,
}

export function PageCarouselSection({ section }: PageCarouselSectionProps): JSX.Element {

  const theme = useTheme()

  const navigate = useNavigate()

  const {
    staticBlock: {
      backgroundColor: staticBgColor,
      foregroundColor: staticFgColor,
      plainText: staticText,
      link: staticLink,
    }, dynamicBlock: {
      backgroundColor: dynamicBgColor,
      foregroundColor: dynamicFgColor,
      plainText: dynamicText,
      links: dynamicLinks,
    },
  } = section

  const [state, setState] = useState<PageCarouselSectionState>({ ...DEFAULT_STATE })
  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true })

  const _handleNext = () => emblaApi.scrollNext()

  const _handlePrev = () => emblaApi.scrollPrev()

  const _handleLinkClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, href: string): void => navigate(href)

  useEffect(() => {
    if (emblaApi) {
      emblaApi.on('select', () => {
        const currentIdx = emblaApi.selectedScrollSnap()
        setState((prevState) => update(prevState, { currentIdx: { $set: currentIdx } }))
      })
    }
  }, [emblaApi])

  const dynamicContent = dynamicText && dynamicLinks
    ? [...dynamicText, ...dynamicLinks]
    : dynamicText || dynamicLinks

  const buttonColors = {
    color: theme.colors.green.bottleGreen,
    backgroundColor: theme.colors.misc.transparent,
    borderColor: theme.colors.misc.transparent,
    hoverColor: theme.colors.green.bottleGreen,
    hoverBackgroundColor: theme.colors.misc.transparent,
    hoverBorderColor: theme.colors.misc.transparent,
    disabledColor: SiteHelper.getOpaqueColor(theme.colors.green.bottleGreen, 0.45),
    disabledBackgrondColor: theme.colors.misc.transparent,
    disabledBorderColor: theme.colors.misc.transparent,
  }

  let dynamicSlide: string | PageLinkFragment
  let slideIdx: number

  return (
    <Container>
      <ContentContainer>
        <StaticBlock backgroundColor={staticBgColor}>
          <Choose>
            <When condition={!!staticText}>
              <Paragraph variant='p1' color={staticFgColor}>
                {staticText}
              </Paragraph>
            </When>
            <Otherwise>
              <Paragraph variant='p1' color={staticFgColor}>
                <Link
                  href={`/${staticLink.url}`}
                  color={staticFgColor}
                  hoverColor={staticFgColor}>
                  {staticLink.title}
                </Link>
              </Paragraph>
            </Otherwise>
          </Choose>
        </StaticBlock>
        <DynamicBlock backgroundColor={dynamicBgColor}>
          <Embla ref={emblaRef}>
            <EmblaContainer>
              <For each='dynamicSlide' index='slideIdx' of={dynamicContent}>
                <EmblaSlide key={slideIdx}>
                  <Choose>
                    <When condition={typeof dynamicSlide === 'string'}>
                      <Paragraph
                        variant='p1'
                        color={dynamicFgColor}
                        className='slidePg'
                        align='center'>
                        {dynamicSlide as string}
                      </Paragraph>
                    </When>
                    <Otherwise>
                      <Paragraph
                        variant='p1'
                        color={dynamicFgColor}
                        align='center'
                        className='slidePg'>
                        <Link
                          href={`/${(dynamicSlide as PageLinkFragment).url}`}
                          color={dynamicFgColor}
                          hoverColor={dynamicFgColor}
                          onClick={_handleLinkClick}>
                          {(dynamicSlide as PageLinkFragment).title}
                        </Link>
                      </Paragraph>
                    </Otherwise>
                  </Choose>
                </EmblaSlide>
              </For>
            </EmblaContainer>
          </Embla>
          <If condition={dynamicContent.length > 1}>
            <Button
              colors={buttonColors}
              className='prevBtn'
              icon={LocalIconEnums.CHEVRON_LEFT}
              onClick={_handlePrev} />
            <Button
              colors={buttonColors}
              className='nextBtn'
              icon={LocalIconEnums.CHEVRON_RIGHT}
              onClick={_handleNext} />
          </If>
        </DynamicBlock>
      </ContentContainer>
    </Container>
  )

}
