
import React from 'react'
import { Svg } from './Svg'

export interface ChevronFillUpProps {
  color?: string
  hoverColor?: string
  className?: string
}

export function ChevronFillUp({ color, hoverColor, className }: ChevronFillUpProps): JSX.Element {

  return (
    <Svg strokeColor={color} strokeHoverColor={hoverColor} fillColor={color} fillHoverColor={hoverColor} className={className} width="100%" height="100%" viewBox="0 0 20 14" version="1.1">
      <g transform="matrix(1.50292,0,0,1.50292,0.982489,0.988326)">
        <path className='fill' d="M-0,8L12,8L6,-0L-0,8Z" />
      </g>
    </Svg>
  )

}
    