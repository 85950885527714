import React, { useEffect, useState } from 'react'

import { useNavigate } from 'react-router'
import styled from 'styled-components'

import { BaseCategoryFragment, BaseCategoryChildFragment, ProductListFragment, BreadcrumbFragment } from '@hooks/api/index'

import { BaseCategoryChild } from './BaseCategoryChild'
import { ProductSlider } from './ProductSlider'

const Products = styled.div`
  width: 100%;
`

export interface CategoryRowsProps {
  category: BaseCategoryFragment
  loading: boolean
  overwriteBreadCrumbs?: BreadcrumbFragment[]
  onLoaded?: () => void
}

export function CategoryRows({ category, loading, overwriteBreadCrumbs, onLoaded }: CategoryRowsProps): JSX.Element {

  const [loaded, setLoaded] = useState<number>(0)

  const _handleLoaded = () => {
    setLoaded((prevLoaded) => prevLoaded + 1)
  }

  useEffect(() => {
    if (loaded === category.children.length) {
      onLoaded?.()
    }
  }, [loaded])

  let subCategory: BaseCategoryChildFragment

  return (
    <Products>
      <For each='subCategory' of={category?.children || []}>
        <BaseCategoryChild
          key={subCategory.uid}
          overwriteBreadCrumbs={overwriteBreadCrumbs}
          basePath={category?.canonicalUrl}
          child={subCategory}
          onLoaded={_handleLoaded} />
      </For>
    </Products>
  )

}
