import React, { ReactNode, createContext, useContext, useState } from "react"

interface AccordionContextType {
    openBlockId: string | null
    toggleBlock: (blockId: string) => void
    isMobile: boolean
    hasAccordion: boolean
}

interface AccordionProviderState {
    openBlockId: string | null
}

const DEFAULT_STATE: AccordionProviderState = {
    openBlockId: null,
}

const AccordionContext =  createContext<AccordionContextType | undefined>(undefined)

export function AccordionProvider({ children, isMobile }: { children: ReactNode, isMobile: boolean }) {
    const [state, setState] = useState<AccordionProviderState>({ ...DEFAULT_STATE })
    const toggleBlock = (blockId: string) => {
        setState((prevState) => ({
            openBlockId: prevState.openBlockId === blockId ? null : blockId,
        }))
    }

    const contextValue = {
        openBlockId: state.openBlockId,
        toggleBlock,
        isMobile,
        hasAccordion: true,
    }

    return (
        <AccordionContext.Provider value={contextValue}>
            {children}
        </AccordionContext.Provider>
    )
}

export const useAccordion = (): AccordionContextType => {
    const context = useContext(AccordionContext)
    if (context === undefined) {
        throw new Error('useAccordion must be used within a AccordionProvider')
    }
    return context
}