import React from 'react'

import { PageBannerFragment } from '@hooks/api/index'
import { PageBannerTypeEnum } from '@uctypes/api/globalTypes'

import { PageFullImageBanner } from './PageFullImageBanner'
import { PageImageOnlyBanner } from './PageImageOnlyBanner'
import { PageSplitLargeImageBanner } from './PageSplitLargeImageBanner'
import { PageSplitSmallImageBanner } from './PageSplitSmallImageBanner'

export interface PageBannerProps {
  pageBanner: PageBannerFragment
}

export function PageBanner({ pageBanner }: PageBannerProps): JSX.Element {

  return (
    <>
      <Choose>
        <When condition={pageBanner.bannerType === PageBannerTypeEnum.FULL_IMAGE}>
          <PageFullImageBanner banner={pageBanner} />
        </When>
        <When condition={pageBanner.bannerType === PageBannerTypeEnum.SPLIT_LARGE_IMAGE}>
          <PageSplitLargeImageBanner banner={pageBanner} />
        </When>
        <When condition={pageBanner.bannerType === PageBannerTypeEnum.SPLIT_SMALL_IMAGE}>
          <PageSplitSmallImageBanner banner={pageBanner} />
        </When>
        <When condition={pageBanner.bannerType === PageBannerTypeEnum.IMAGE_ONLY}>
          <PageImageOnlyBanner banner={pageBanner} />
        </When>
      </Choose>
    </>
  )

}
