import React from 'react'

import loadable from '@loadable/component'

import(/* webpackPrefetch: true */ '@pages/ProductWrapper')

const ProductWrapper = loadable(() => import(/* webpackChunkName: "product-wrapper" */'@pages/ProductWrapper'), {
  resolveComponent: (components) => components.ProductWrapper,
})

export function ProductWrapperAsync(): JSX.Element {

  return <ProductWrapper fallback={<div>Loading...</div>} />

}
