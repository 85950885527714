import React from 'react'

import { Field, FormInstance } from 'rc-field-form'
import { Meta, Rule } from 'rc-field-form/es/interface.d'
import styled, { css, CSS, useTheme } from 'styled-components'

import { Icon, LocalIconEnums, SmallLoader } from '@atoms/index'
import { ResponsivePXValue } from '@components/Theme'
import { useGetApiEnumQuery } from '@hooks/api/index'
import { InputWrapper, FormContext, FormContextProperties } from '@molecules/inputs/index'

const Container = styled.div<{ direction: 'vertical' | 'horizontal' }>`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: ${(props): string => props.direction === 'vertical' ? 'column' : 'row'};

  ${ResponsivePXValue('gap', '8px')}
  ${ResponsivePXValue('padding', { mobile: '8px 0', tablet: '8px 0', desktop: '8px 0' })}
`

const ItemContainer = styled.div<{ loadingActive: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: ${(props): string => props.loadingActive ? 'inherit' : 'pointer'};
  position: relative;
`

const IconWrapper = styled.div`
  ${ResponsivePXValue('width', '14px')}
  ${ResponsivePXValue('height', '14px')}
`

const ButtonOuterContainer = styled.div<{ selected: boolean, disabled: boolean }>`
  border-style: solid;
  border-color: ${(props): string => props.theme.colors.grey.silver};

  ${ResponsivePXValue('width', '16px')}
  ${ResponsivePXValue('height', '16px')}
  ${ResponsivePXValue('margin', '0')}
  ${ResponsivePXValue('border-radius', '2px')}
  ${ResponsivePXValue('border-width', '1px')}

  ${(props): CSS => {
    if (props.disabled && props.selected) {
      return css`
        border-color: ${(props): string => props.theme.colors.green.greenVogue};
        background-color: ${(props): string => props.theme.colors.green.greenVogue};
        opacity: 45%;
      `
    }
    if (props.selected) {
      return css`
        border-color: ${(props): string => props.theme.colors.green.greenVogue};
        background-color: ${(props): string => props.theme.colors.green.greenVogue};
      `
    }
    if (props.disabled) {
      return css`
        border-color: ${(props): string => props.theme.colors.white.romance};
      `
    }
  }}
`

const ItemLabel = styled.div<{ color: string }>`
  position: relative;
  font-family: open-sans;
  font-weight: 400;
  max-width: 90%;
  color: ${(props): string => props.color};

  ${ResponsivePXValue('margin-left', '8px')}
  ${ResponsivePXValue('font-size', { mobile: '10px', tablet: '12px', desktop: '12px' })}
  ${ResponsivePXValue('line-height', { mobile: '20px', tablet: '20px', desktop: '20px' })}
`

const ItemElement = styled.div`
  position: relative;
  width: 100%;

  ${ResponsivePXValue('margin-left', '8px')}
  ${ResponsivePXValue('top', '2px')}
`

const LoadingContainer = styled.div`
  position: absolute;
  ${ResponsivePXValue('right', '0px')}
  ${ResponsivePXValue('top', '0px')}
  ${ResponsivePXValue('width', '16px')}
  ${ResponsivePXValue('height', '16px')}
`

interface Values {
  value?: (string | number | boolean)[]
  onChange?: (value: (string | number | boolean)[]) => void
}

interface CheckBoxInputInnerProps extends CheckBoxFormInputProps {
  control: Values
  meta: Meta
  form: FormInstance
}

function CheckBoxInner(props: CheckBoxInputInnerProps): JSX.Element {
  const { label, showLabel = true, variant = 'vertical', options, loading, apiEnum, disabled, rules, meta, control, form, className, wrapperClassName } = props
  const { data, loading: apiLoading } = useGetApiEnumQuery({ variables: { enum: apiEnum }, skip: !apiEnum })

  const theme = useTheme()

  let availableOptions: CheckBoxOption[] = options ?? []
  if (data?.enum?.values) {
    availableOptions = data.enum.values.map((enumVal) => ({ title: enumVal.title, value: enumVal.value }))
  }

  const required = !!(
    rules &&
    rules.some(rule => {
      if (rule && typeof rule === 'object' && rule.required) {
        return true
      }
      if (typeof rule === 'function') {
        const ruleEntity = rule(form)
        return ruleEntity && ruleEntity.required
      }
      return false
    })
  )
  const error = meta.errors?.[0]
  const { value, onChange } = control

  const _handleChange = (checkValue: string | number | boolean, locallyDisabled: boolean) => {

    if (disabled || loading || apiLoading || locallyDisabled) {
      return
    }
    const newValue: (string | number | boolean)[] = value as (string | number | boolean)[] || []

    if (Array.isArray(newValue) && newValue.includes(checkValue)) {
      newValue.splice(newValue.indexOf(checkValue), 1)
    } else if (Array.isArray(newValue)) {
      newValue.push(checkValue)
    }
    onChange(newValue)
  }

  let option: CheckBoxOption
  const color = disabled || loading || apiLoading ? theme.colors.white.pureWhite : theme.colors.white.pureWhite
  const labelColor = disabled || loading || apiLoading ? theme.colors.green.bottleGreen : theme.colors.green.bottleGreen

  return (
    <InputWrapper
      required={required}
      label={label}
      showLabel={showLabel}
      error={error}
      className={wrapperClassName}>
      <FormContext.Consumer>
        {({ loading: formLoading, disabled: formDisabled }: FormContextProperties) => (
          <Container direction={variant} className={className}>
            <For each='option' of={availableOptions}>
              {/** Transient props from styled-components now causing issues loading is one of them, changed to loadingActive */}
              <ItemContainer
                loadingActive={disabled || loading || apiLoading || formLoading || formDisabled}
                key={option.value.toString()}
                onClick={() => _handleChange(option.value, option.disabled)}>
                <ButtonOuterContainer
                  disabled={disabled || loading || apiLoading || option.disabled}
                  selected={Array.isArray(value) ? (value as (string | number | boolean)[] || []).includes(option.value) : value === option.value}>
                  <If condition={Array.isArray(value) ? (value as (string | number | boolean)[] || []).includes(option.value) : value === option.value}>
                    <IconWrapper>
                      <Icon color={color} icon={LocalIconEnums.CHECK} />
                    </IconWrapper>
                  </If>
                </ButtonOuterContainer>
                <Choose>
                  <When condition={typeof option.title === 'string' && option.title !== ''}>
                    <If condition={(option.title !== '')}>
                      <ItemLabel color={labelColor}>{option.title}</ItemLabel>
                    </If>
                  </When>
                  <Otherwise>
                    <ItemElement>
                      {option.title}
                    </ItemElement>
                  </Otherwise>
                </Choose>
              </ItemContainer>
            </For>
            <If condition={loading || apiLoading || formLoading}>
              <LoadingContainer>
                <SmallLoader
                  color={theme.colors.green.goldenrod} />
              </LoadingContainer>
            </If>
          </Container>
        )}
      </FormContext.Consumer>
    </InputWrapper>
  )
}

export interface CheckBoxOption {
  title: React.ReactNode
  value: string | number | boolean
  disabled?: boolean
}

export interface CheckBoxFormInputProps {
  rules?: Rule[]
  label?: string
  showLabel?: boolean
  name: string
  loading?: boolean
  disabled?: boolean
  variant?: 'vertical' | 'horizontal'
  apiEnum?: string
  options?: CheckBoxOption[]
  wrapperClassName?: string
  className?: string
}

export function CheckBoxInput(props: CheckBoxFormInputProps): JSX.Element {

  const { name, rules } = props

  return (
    <Field name={name} rules={rules}>
      {(control: Values, meta: Meta, form: FormInstance) => <CheckBoxInner {...props} control={control} meta={meta} form={form} />}
    </Field>
  )

}
