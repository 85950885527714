import React from 'react'

import { ApolloProvider, ApolloClient, NormalizedCacheObject } from '@apollo/client'

import { ThemeProvider } from 'styled-components'
import { ModalProvider } from 'styled-react-modal'

import { theme } from '@components/Theme'
import { ConfigProvider } from '@contexts/ConfigProvider'
import { EventProvider } from '@contexts/GTMProvider'
import { Config } from '@lib/Config'
import { SiteHelper } from '@lib/SiteHelper'
import { UserEvents } from '@lib/UserEvents'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { Routes } from '@routes/Routes'
import { SimpleToastsProvider } from '@simple/toasts'
import { ResizeContainer } from '@utility/index'

import { GlobalStyle } from './GlobalStyle'
import { StoreConfig } from './StoreConfig'

interface AppProps {
  client: ApolloClient<NormalizedCacheObject>
  config: Config
  events: UserEvents
}

export function App({ client, config, events }: AppProps): JSX.Element {

  return (
    <ConfigProvider config={config}>
      <ApolloProvider client={client}>
        <ThemeProvider theme={theme}>
          <EventProvider events={events}>
            <GoogleOAuthProvider clientId={config.getGoogleAuthKey()}>
              <>
                <GlobalStyle />
                <StoreConfig />
                <SimpleToastsProvider config={SiteHelper.getSimpleToastConfig()}>
                  <ModalProvider>
                    <ResizeContainer>
                      <Routes />
                    </ResizeContainer>
                  </ModalProvider>
                </SimpleToastsProvider>
              </>
            </GoogleOAuthProvider>
          </EventProvider>
        </ThemeProvider>
      </ApolloProvider>
    </ConfigProvider>
  )

}
