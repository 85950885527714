
import React from 'react'
import { Svg } from './Svg'

export interface ChevronFillDownProps {
  color?: string
  hoverColor?: string
  className?: string
}

export function ChevronFillDown({ color, hoverColor, className }: ChevronFillDownProps): JSX.Element {

  return (
    <Svg strokeColor={color} strokeHoverColor={hoverColor} fillColor={color} fillHoverColor={hoverColor} className={className} width="100%" height="100%" viewBox="0 0 20 14" version="1.1">
      <g transform="matrix(1.49866,0,0,1.49866,1.00802,1.00535)">
        <path className='fill' d="M2.283,0L0,0L6,8L12,0L2.283,0Z" />
      </g>
    </Svg>
  )

}
    