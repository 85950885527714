
import React from 'react'
import { Svg } from './Svg'

export interface AccountNewProps {
  color?: string
  hoverColor?: string
  className?: string
}

export function AccountNew({ color, hoverColor, className }: AccountNewProps): JSX.Element {

  return (
    <Svg strokeColor={color} strokeHoverColor={hoverColor} fillColor={color} fillHoverColor={hoverColor} className={className} width="100%" height="100%" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" space="preserve" serif="http://www.serif.com/" style={{fillRule:"evenodd",clipRule:"evenodd"}}>
      <g transform="matrix(0.481468,0,0,0.517986,-10.4717,-12.105)">
        <path d="M61.1,57.94C59.6,54.31 57.08,51.22 53.82,49.01C52.09,47.83 50.22,46.94 48.25,46.36C51.68,44.34 53.83,40.64 53.83,36.61C53.83,30.37 48.76,25.3 42.52,25.3C36.28,25.3 31.21,30.37 31.21,36.61C31.21,40.64 33.36,44.34 36.79,46.36C34.82,46.94 32.95,47.83 31.22,49.01C27.96,51.23 25.45,54.32 23.94,57.95C23.75,58.42 23.8,58.95 24.08,59.37C24.36,59.79 24.83,60.05 25.34,60.05L59.68,60.05C60.19,60.05 60.66,59.8 60.94,59.37C61.24,58.94 61.29,58.41 61.1,57.94ZM57.24,57L27.79,57C30.84,51.78 36.4,48.57 42.52,48.57C48.64,48.57 54.2,51.78 57.24,57ZM34.25,36.61C34.25,32.05 37.96,28.34 42.52,28.34C47.08,28.34 50.79,32.05 50.79,36.61C50.79,41.17 47.08,44.88 42.52,44.88C37.96,44.88 34.25,41.17 34.25,36.61Z" className='fill' />
      </g>
    </Svg>
  )

}
    