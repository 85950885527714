import React from 'react'

import styled from 'styled-components'

export type SeperatorVariant = 'vertical' | 'horizontal'

const SpacerElement = styled.div<{ $color?: string, $align?: string }>`
  position: relative;
  width: ${(props): string => props.$align === 'vertical' ? '1px' : '90%'};
  height: ${(props): string => props.$align === 'horizontal' ? '1px' : '90%'};
  background-color: ${(props): string => props.color ? props.color : props.theme.colors.grey.athens};
  ${(props): string => {
    if (props.$align === 'vertical') {
      return `
        top: 5%;
      `
    }

    return `
      left: 5%;
    `
  }}
`

export interface SeperatorProps {
  color?: string
  align?: SeperatorVariant
  className?: string
}

export function Seperator({ color, align = 'horizontal', className }: SeperatorProps): JSX.Element {

  return <SpacerElement color={color} $align={align} className={className} />

}
