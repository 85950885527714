
import React from 'react'
import { Svg } from './Svg'

export interface EmailProps {
  color?: string
  hoverColor?: string
  className?: string
}

export function Email({ color, hoverColor, className }: EmailProps): JSX.Element {

  return (
    <Svg strokeColor={color} strokeHoverColor={hoverColor} fillColor={color} fillHoverColor={hoverColor} className={className} width="100%" height="100%" viewBox="0 0 20 16">
      <g id="Group_145" transform="matrix(1,0,0,1,-1140.34,-454.955)">
        <g id="Path_242" transform="matrix(1.2656,0,0,1.2656,-305.529,-122.868)">
          <path d="M1155.43,457.361L1145.23,457.361C1144.13,457.358 1143.22,458.264 1143.21,459.368L1143.21,465.842C1143.22,466.946 1144.13,467.852 1145.23,467.849L1155.43,467.849C1156.54,467.852 1157.45,466.946 1157.45,465.842L1157.45,459.368C1157.45,458.264 1156.54,457.358 1155.43,457.361ZM1156.54,465.842C1156.54,465.879 1156.54,465.917 1156.54,465.954C1156.48,466.513 1156,466.943 1155.43,466.943L1145.24,466.943C1145.23,466.943 1145.23,466.943 1145.23,466.943C1144.62,466.943 1144.13,466.446 1144.13,465.843L1144.13,459.369C1144.13,458.766 1144.62,458.269 1145.23,458.269C1145.23,458.269 1145.23,458.269 1145.24,458.269L1155.43,458.269C1156,458.269 1156.48,458.698 1156.54,459.257C1156.54,459.295 1156.54,459.332 1156.54,459.37L1156.54,465.842Z" className='fill' />
        </g>
        <g id="Group_144" transform="matrix(1.2656,0,0,1.2656,-305.529,-122.868)">
          <g id="Group_141">
            <path id="Path_243" d="M1144.62,466.2C1144.71,466.358 1144.86,466.475 1145.04,466.526L1148.9,463.27L1148.5,462.93L1144.62,466.2Z" className='fill' />
            <path id="Path_244" d="M1145.07,466.68L1145,466.66C1144.79,466.599 1144.61,466.459 1144.5,466.27L1144.44,466.17L1148.49,462.749L1149.11,463.271L1145.07,466.68ZM1144.81,466.223C1144.86,466.285 1144.93,466.335 1145.01,466.368L1148.68,463.268L1148.49,463.111L1144.81,466.223Z" className='fill' />
          </g>
          <g id="Group_142">
            <path id="Path_245" d="M1151.77,463.285L1155.63,466.526C1155.81,466.475 1155.96,466.358 1156.05,466.2L1152.18,462.948L1151.77,463.285Z" className='fill' />
            <path id="Path_246" d="M1155.6,466.68L1151.56,463.28L1152.18,462.763L1156.23,466.163L1156.17,466.263C1156.06,466.452 1155.88,466.591 1155.67,466.652L1155.6,466.68ZM1151.99,463.286L1155.66,466.368C1155.74,466.335 1155.81,466.285 1155.86,466.223L1152.18,463.13L1151.99,463.286Z" className='fill' />
          </g>
          <g id="Group_143">
            <path id="Path_247" d="M1155.81,458.761L1150.34,463.295L1144.92,458.73C1144.76,458.81 1144.63,458.947 1144.57,459.114L1150.17,463.831C1150.21,463.871 1150.27,463.893 1150.34,463.893C1150.4,463.893 1150.46,463.872 1150.5,463.833L1156.12,459.174C1156.07,459.001 1155.96,458.854 1155.81,458.761Z" className='fill' />
            <path id="Path_248" d="M1150.34,464.032C1150.24,464.031 1150.15,463.996 1150.08,463.932L1144.4,459.151L1144.44,459.059C1144.52,458.859 1144.66,458.695 1144.86,458.6L1144.94,458.56L1150.34,463.108L1155.8,458.585L1155.88,458.637C1156.06,458.748 1156.2,458.924 1156.26,459.13L1156.28,459.218L1150.59,463.935C1150.52,463.997 1150.43,464.031 1150.34,464.032ZM1144.74,459.077L1150.26,463.724C1150.28,463.746 1150.31,463.758 1150.34,463.758C1150.37,463.758 1150.39,463.746 1150.41,463.724L1155.96,459.124C1155.92,459.051 1155.87,458.985 1155.81,458.932L1150.34,463.472L1144.9,458.896C1144.84,458.943 1144.78,459.005 1144.74,459.077Z" className='fill' />
          </g>
        </g>
      </g>
    </Svg>
  )

}
    