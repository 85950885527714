import React from 'react'

import styled, { useTheme } from 'styled-components'

import { Button, ButtonColors } from '@atoms/buttons'
import { LocalIconEnums, ResponsiveImage } from '@atoms/images'
import { Paragraph, Tag } from '@atoms/typography'
import { LineEllipsis } from '@atoms/typography/LineEllipsis'
import { ResponsivePXValue } from '@components/Theme'
import { useRemoveItemFromCartMutation, CartItemFragment, useCustomerQuery, useAddProductToWishlistMutation } from '@hooks/api/index'
import { useCartId } from '@hooks/UseCartId'
import { SiteHelper } from '@lib/SiteHelper'
import { CustomerTypeEnum } from '@uctypes/api/globalTypes'

const Container = styled.div`
  display: flex;
  ${ResponsivePXValue('padding', '20px 0')}
  ${(props): string => ResponsivePXValue('border-bottom', `1px solid ${props.theme.colors.grey.gallery}`)};
`

const ImageContainer = styled.div`
  flex-shrink: 0;
  ${ResponsivePXValue('width', '50px')}
  ${ResponsivePXValue('height', '50px')}
  ${ResponsivePXValue('margin-right', '5px')}
`

const InfoContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  .name {
    margin: 0;
    ${ResponsivePXValue('height', '24px')}
    overflow: hidden;
  }
`

const PriceContainer = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  .price {
    margin: 0;
  }
`

const CartControls = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  ${ResponsivePXValue('gap', '10px')}
  .cart-item-buttons {
    padding: 0;
  }
`

export interface ProductCartCardProps {
  cartItem: CartItemFragment
}

export function ProductCartCard({ cartItem }: ProductCartCardProps): JSX.Element {

  const { cartId } = useCartId()
  const { data: customerData, loading: customerLoading, error: customerError } = useCustomerQuery()
  const [removeItemFromCart, { loading: removeLoading }] = useRemoveItemFromCartMutation()
  const [addProductToWishlist, { loading: addWishlistLoading }] = useAddProductToWishlistMutation()
  const theme = useTheme()

  const _handleRemove = async (): Promise<void> => {
    await removeItemFromCart({
      variables: {
        cartId,
        cartItemUid: cartItem.uid,
      },
    })
  }

  const _handleMoveToWishlist = async (): Promise<void> => {
    await addProductToWishlist({
      variables: {
        wishlistId: customerData.currentCustomer.wishlists[0].id,
        sku: cartItem.product.sku,
        quantity: 1,
      },
    })
    await removeItemFromCart({
      variables: {
        cartId,
        cartItemUid: cartItem.uid,
      },
    })
  }

  const buttonColors: ButtonColors = {
    color: theme.colors.grey.gunSmoke,
    backgroundColor: theme.colors.misc.transparent,
    borderColor: theme.colors.misc.transparent,
    hoverColor: theme.colors.red.cinnabar,
    hoverBackgroundColor: theme.colors.misc.transparent,
    hoverBorderColor: theme.colors.misc.transparent,
    disabledColor: theme.colors.grey.gallery,
    disabledBackgrondColor: theme.colors.misc.transparent,
    disabledBorderColor: theme.colors.misc.transparent,
  }

  const price = SiteHelper.formatCurrency(cartItem.prices.rowTotalIncludingTax)

  return (
    <Container>
      <ImageContainer>
        <ResponsiveImage image={cartItem.product.coverImage} objectFit='contain' />
      </ImageContainer>
      <InfoContainer>
        <LineEllipsis
          variant='p3'
          className='name'
          text={cartItem.product.name}
          maxLine={1}
          ellipsis='...'
          trimRight
          basedOn='letters' />
        <CartControls>
          <Button
            onClick={_handleRemove}
            colors={buttonColors}
            title='Remove'
            className='cart-item-buttons'
            loading={removeLoading}
            disabled={addWishlistLoading}
            rightIcon={LocalIconEnums.CLOSE} />
          <If condition={customerData?.currentCustomer?.customerType === CustomerTypeEnum.REGISTERED}>
            <Button
              onClick={_handleMoveToWishlist}
              colors={buttonColors}
              title='Move To'
              className='cart-item-buttons'
              disabled={removeLoading}
              loading={addWishlistLoading}
              rightIcon={LocalIconEnums.HEART_OUTLINE} />
          </If>
        </CartControls>
      </InfoContainer>
      <PriceContainer>
        <Paragraph variant='p3' className='price'>{price}</Paragraph>
        <Tag variant='t3' className='price'>Qty: {cartItem.quantity}</Tag>
      </PriceContainer>
    </Container>
  )

}
