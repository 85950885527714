import { DocumentNode, FieldPolicy, gql, makeVar } from '@apollo/client'

import { v4 } from 'uuid'

import { ModalsFragment } from '@hooks/api/index'
import { ConfigPlugin } from '@lib/Config'

export enum GlobalModalTypeEnum {
  LOG_IN = 'LOG_IN',
  SIGN_UP = 'SIGN_UP',
  SEARCH = 'SEARCH',
  SUBSCRIBE = 'SUBSCRIBE',
  VALIDATE_MOBILE = 'VALIDATE_MOBILE',
  FORGOT_PASSWORD = 'FORGOT_PASSWORD',
}

export const MODALS_DEFAULT_STATE: ModalsFragment = {
  id: v4(),
  logIn: false,
  signUp: false,
  search: false,
  subscribe: false,
  validateMobile: false,
  forgotPassword: false,
  __typename: 'Modals',
}

const _data = makeVar<ModalsFragment>({ ...MODALS_DEFAULT_STATE })

export class ModalPlugin implements ConfigPlugin {

  static instance: ModalPlugin

  static shared(): ModalPlugin {
    if (!this.instance) {
      this.instance = new ModalPlugin()
    }
    return this.instance
  }

  closeAllModals(): void {
    _data({ ...MODALS_DEFAULT_STATE })
  }

  toggleGlobalModal(show: boolean, type: GlobalModalTypeEnum): void {
    const modals = { ..._data() }
    switch (type) {
      case GlobalModalTypeEnum.LOG_IN:
        modals.logIn = show
        modals.signup = false
        modals.subscribe = false
        modals.forgotPassword = false
        break
      case GlobalModalTypeEnum.SIGN_UP:
        modals.signUp = show
        modals.logIn = false
        modals.subscribe = false
        modals.forgotPassword = false
        break
      case GlobalModalTypeEnum.SEARCH:
        modals.search = show
        break
      case GlobalModalTypeEnum.SUBSCRIBE:
        modals.subscribe = show
        modals.logIn = false
        modals.signUp = false
        modals.forgotPassword = false
        break
      case GlobalModalTypeEnum.FORGOT_PASSWORD:
        modals.forgotPassword = show
        modals.logIn = false
        modals.signUp = false
        modals.subscribe = false
        break
      case GlobalModalTypeEnum.VALIDATE_MOBILE:
        modals.validateMobile = show
        break
      default:
        break
    }
    _data(modals)
  }

  fieldPolicies = (): { [k: string]: FieldPolicy } => ({
    modals: {
      read(): ModalsFragment {
        return _data() as ModalsFragment
      },
    },
  })

  types = (): DocumentNode => gql`
    type Modals {
      id: ID!
      logIn: Boolean!
      signUp: Boolean!
      search: Boolean!
      subscribe: Boolean!
      validateMobile: Boolean!
      forgotPassword: Boolean!
    }
  `

  extensions = (): DocumentNode => gql`
    extend type Query {
      modals: Modals!
    }
  `

  queries = (): DocumentNode => gql`
    fragment ModalsFragment on Modals {
      id
      logIn
      signUp
      search
      subscribe
      validateMobile
      forgotPassword
    }
    query GetModals {
      modals @client {
        ... ModalsFragment
      }
    }
  `

}
