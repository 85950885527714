import React, { useState } from 'react'

import { ShareLink } from 'social-media-sharing'
import styled from 'styled-components'

import { Button, ButtonColors } from '@atoms/buttons'
import { Icon, LocalIconEnums } from '@atoms/images'
import { Heading } from '@atoms/typography'
import { LineEllipsis } from '@atoms/typography/LineEllipsis'
import { ResponsivePXValue, theme } from '@components/Theme'
import { useConfig } from '@contexts/ConfigProvider'
import { Config } from '@lib/Config'
import { SiteHelper } from '@lib/SiteHelper'
import { Modal } from '@molecules/content'

enum ShareTypesEnum {
  FACEBOOK = 'facebook',
  TWITTER = 'twitter',
  EMAIL = 'email',
  COPY = 'copy',
}

const SocialIcons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${ResponsivePXValue('gap', { mobile: '24px' })}
  ${ResponsivePXValue('margin', { mobile: '16px 0' })}
`

const SocialIcon = styled.div`
  border-style: solid;
  border-color: ${(props): string => theme.colors.green.bottleGreen};
  ${ResponsivePXValue('border-width', '1.5px')}
  ${ResponsivePXValue('border-radius', { mobile: '32px' })}
  ${ResponsivePXValue('width', { mobile: '32px' })}
  ${ResponsivePXValue('height', { mobile: '32px' })}
  ${ResponsivePXValue('padding', { mobile: '4px' })}
`

const ShareModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 50;
  ${ResponsivePXValue('padding', { mobile: '24px 16px 16px 16px' })}
  ${ResponsivePXValue('width', { mobile: '100%' })}
  ${ResponsivePXValue('height', { mobile: '100%' })}

  background-color: ${(props): string => SiteHelper.getOpaqueColor(props.theme.colors.green.bottleGreen, 0.77)};
`

const ShareModalInner = styled.div`
  z-index: 51;
  position: absolute;
  bottom: 0;
  left: 0;
  ${ResponsivePXValue('width', { mobile: '100%' })}
  ${ResponsivePXValue('padding', { mobile: '24px 16px 16px 16px' })}
  background-color: ${(props): string => props.theme.colors.white.pureWhite};
`

const FakeInput = styled.div`
  font-family: open-sans;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  color: ${(props): string => props.theme.colors.grey.stormDust};

  ${ResponsivePXValue('width', { mobile: '228px' })}
  ${ResponsivePXValue('padding', { mobile: '5px 12px' })}
  ${ResponsivePXValue('height', { mobile: '40px' })}
  ${ResponsivePXValue('font-size', { mobile: '11px', tablet: '24px', desktop: '24px' })}

  background-color: ${(props): string => props.theme.colors.grey.gallery};
`

const CopySection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${ResponsivePXValue('gap', { mobile: '12px' })}
  .button {
    ${ResponsivePXValue('width', { mobile: '58px' })}
  }
`

const Close = styled.div`
  position: absolute;
  ${ResponsivePXValue('right', { mobile: '12px' })}
  ${ResponsivePXValue('top', { mobile: '12px' })}
  ${ResponsivePXValue('width', { mobile: '20px' })}
  ${ResponsivePXValue('height', { mobile: '20px' })}

`

export interface ShareModalProps {
  open: boolean
  onClose?: () => void
  url?: string
}

export function ShareModal({ open, onClose, url }: ShareModalProps): JSX.Element {

  const config = useConfig()
  let href = '/'
  if (typeof window !=='undefined') {
    href = window?.location?.href
  }
  const pageUrl = url ? config.getBaseUrl() + url : href
  const [complete, setComplete] = useState(false)

  const _handleShare = (platform: ShareTypesEnum): void => {
    const socialMediaLinks = new ShareLink(platform)
    const pageUrl = url ? config.getBaseUrl() + url : href
    const description = 'I\'m a conscious shopper, see my favourite natural products on Faithful to Nature.'

    switch (platform) {
      case ShareTypesEnum.FACEBOOK:
        socialMediaLinks.get({
          u: pageUrl,
          quote: description,
        })
        socialMediaLinks.open()
        break
      case ShareTypesEnum.TWITTER:
        socialMediaLinks.get({
          url: pageUrl,
          text: description,
        })
        socialMediaLinks.open()
        break
      case ShareTypesEnum.EMAIL:
        window.open(
          'mailto:user@example.com?subject=' + description + '&body=' + pageUrl,
          '_blank',
        )
        break
      default:
        navigator.clipboard.writeText(pageUrl)
        setComplete(true)
    }
  }

  const btnColors: ButtonColors = {
    color: theme.colors.white.pureWhite,
    backgroundColor: complete ? theme.colors.green.deYork : theme.colors.black.pureBlack,
    borderColor: complete ? theme.colors.green.deYork : theme.colors.black.pureBlack,
    // Hover
    hoverColor: theme.colors.white.pureWhite,
    hoverBackgroundColor: complete ? theme.colors.green.deYork : theme.colors.black.pureBlack,
    hoverBorderColor: complete ? theme.colors.green.deYork : theme.colors.black.pureBlack,
    // Disable
    disabledColor: theme.colors.white.pureWhite,
    disabledBackgrondColor: theme.colors.grey.athens,
  }

  return (
    <ShareModalWrapper>
      <ShareModalInner>

        <Close onClick={() => onClose()}><Icon color={theme.colors.grey.silver} icon={LocalIconEnums.CLOSE} /></Close>

        <Heading variant='h3' align='center' color={theme.colors.black.pureBlack}>Share this Product</Heading>
        <SocialIcons>
          <SocialIcon onClick={() => _handleShare(ShareTypesEnum.FACEBOOK)}><Icon color={theme.colors.black.pureBlack} icon={LocalIconEnums.FACEBOOK} /></SocialIcon>
          <SocialIcon onClick={() => _handleShare(ShareTypesEnum.TWITTER)} ><Icon color={theme.colors.black.pureBlack} icon={LocalIconEnums.TWITTER} /></SocialIcon>
        </SocialIcons>

        <CopySection>
          <FakeInput><LineEllipsis
            text={pageUrl}
            maxLine={0}
            ellipsis='...'
            trimRight
            basedOn='words' /></FakeInput>
          <Button onClick={() => _handleShare(ShareTypesEnum.COPY)} className='button' variant='primary' title={complete ? 'Copied' : 'Copy'} fill={false} colors={btnColors} />
        </CopySection>
      </ShareModalInner>
    </ShareModalWrapper>
  )

}
