import React from 'react'

import loadable from '@loadable/component'

// import(/* webpackPrefetch: true */ '@components/builder/pages/CacheManagement')

const CacheManagement = loadable(() => import(/* webpackChunkName: "cache-management" */'@components/builder/pages/CacheManagement'), {
  resolveComponent: (components) => components.CacheManagement,
})

export function CacheManagementAsync(): JSX.Element {

  return <CacheManagement fallback={<div>Loading...</div>} />

}
