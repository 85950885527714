import React, { useState, useEffect, useRef } from 'react'

import update from 'react-addons-update'
import styled from 'styled-components'

import { GlobalModalTypeEnum, MODALS_DEFAULT_STATE, ModalPlugin } from '@api/local/ModalPlugin'
import { Button, Heading, Link, Tag } from '@atoms/index'
import { ResponsivePXValue } from '@components/Theme'
import { useCustomerQuery, useGetModalsQuery, useGetStoreConfigQuery, useSendMobileVerificationMutation, useVerifyMobileMutation } from '@hooks/api'
import { Modal } from '@molecules/content'
import { Form, TextInput, useForm } from '@molecules/inputs'

const Container = styled.div`
  ${ResponsivePXValue('width', { mobile: '100%', tablet: '500px', desktop: '500px' })}
  ${ResponsivePXValue('padding', { mobile: '16px', tablet: '24px', desktop: '24px' })}
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .title {
    ${ResponsivePXValue('margin', { mobile: '12px', tablet: '12px', desktop: '12px' })}
    text-align: center;
  }
  .tag {
    ${ResponsivePXValue('margin', { mobile: '8px', tablet: '8px', desktop: '8px' })}
    ${ResponsivePXValue('max-width', { mobile: '100%', tablet: '400px', desktop: '400px' })}
    text-align: center;
  }
  .button {
    width: 100%;
    ${ResponsivePXValue('margin', { mobile: '12px 0 0', tablet: '24px 0 0', desktop: '24px 0 0' })}
  }
  .text {
    width: 100%;
    input {
      text-align: center;
    }
  }
  .form {
    width: 100%;
  }
`

enum DisplayTypeEnum {
  LOADING = 'LOADING',
  SEND = 'SEND',
  GET_NUMBER = 'GET_NUMBER',
  VERIFY = 'VERIFY',
  COMPLETE = 'COMPLETE',
  TOO_MANY_OTP_ATTEMPTS = 'TOO_MANY_OTP_ATTEMPTS',
  TOO_MANY_WRONG_INPUTS = 'TOO_MANY_WRONG_INPUTS',
  OTP_EXPIRED = 'OTP_EXPIRED',
  INCORRECT = 'INCORRECT',
  ERROR = 'ERROR',
}

interface MobileVerificationState {
  displayType: DisplayTypeEnum
  sentDate: Date | null
  expiryDate: Date | null
  sendAttemptsRemaining: number | null
  verifyAttemptsRemaining: number | null
  mobile: string

}

const DEFAULT_STATE: MobileVerificationState = {
  displayType: DisplayTypeEnum.LOADING,
  sentDate: null,
  expiryDate: null,
  sendAttemptsRemaining: null,
  verifyAttemptsRemaining: null,
  mobile: '',
}

export function MobileVerification(): JSX.Element {

  const [state, setState] = useState<MobileVerificationState>({ ...DEFAULT_STATE })
  const { data: modalsData = { modals: { ...MODALS_DEFAULT_STATE } } } = useGetModalsQuery()
  const { data: customerData, loading: customerDataLoading, refetch } = useCustomerQuery()
  const { data: storeData } = useGetStoreConfigQuery()
  const [send, { loading: sendLoading }] = useSendMobileVerificationMutation()
  const [verify, { loading: verifyLoading }] = useVerifyMobileMutation()
  const form = useForm()

  const processError = (e: Error): void => {
    const match = (e as Error).message.match(/\(E: ([0-9]{4})\)/)
    let hasProcessedError = false
    if (match) {
      if (match[1] === '1019') {
        hasProcessedError = true
        setState((prevState) => update(prevState, {
          displayType: {
            $set: DisplayTypeEnum.TOO_MANY_OTP_ATTEMPTS,
          },
        }))
      } else if (match[1] === '1024') {
        hasProcessedError = true
        setState((prevState) => update(prevState, {
          displayType: {
            $set: DisplayTypeEnum.OTP_EXPIRED,
          },
        }))
      } else if (match[1] === '1022') {
        hasProcessedError = true
        setState((prevState) => update(prevState, {
          displayType: {
            $set: DisplayTypeEnum.TOO_MANY_WRONG_INPUTS,
          },
        }))
      }
    }
    if (!hasProcessedError) {
      setState((prevState) => update(prevState, {
        displayType: {
          $set: DisplayTypeEnum.ERROR,
        },
      }))
    }
  }

  const sendOtp = async (): Promise<void> => {
    try {
      const response = await send({
        variables: {
          input: {
            mobile: state.mobile,
          },
        },
      })
      if (response.data?.sendMobileVerification) {
        setState((prevState) => update(prevState, {
          displayType: {
            $set: DisplayTypeEnum.VERIFY,
          },
          sentDate: {
            $set: new Date(response.data.sendMobileVerification.sentDate),
          },
          expiryDate: {
            $set: new Date(response.data.sendMobileVerification.expiryDate),
          },
          sendAttemptsRemaining: {
            $set: response.data.sendMobileVerification.sendAttemptsRemaining,
          },
        }))
      }
    } catch (e) {
      processError(e)
    }
  }

  const verifyOtp = async (otp: string): Promise<void> => {
    try {
      const response = await verify({
        variables: {
          input: {
            mobile: state.mobile,
            otp,
          },
        },
      })
      if (response.data?.verifyMobile.verified) {
        await refetch()
        setState((prevState) => update(prevState, {
          displayType: {
            $set: DisplayTypeEnum.COMPLETE,
          },
        }))
      } else {
        setState((prevState) => update(prevState, {
          verifyAttemptsRemaining: {
            $set: response.data?.verifyMobile.verifyAttemptsRemaining,
          },
          displayType: {
            $set: response.data?.verifyMobile.verifyAttemptsRemaining ? DisplayTypeEnum.INCORRECT : DisplayTypeEnum.TOO_MANY_WRONG_INPUTS,
          },
        }))
      }
    } catch (e) {
      processError(e)
    }
  }

  const _handleUpdateMobile = async (values: { mobile: string }): Promise<void> => {
    setState((prevState) => update(prevState, {
      mobile: {
        $set: values.mobile,
      },
    }))
  }

  const _handleResend = async (): Promise<void> => {
    sendOtp()
  }

  const _handleSend = (): void => {
    setState((prevState) => update(prevState, {
      mobile: {
        $set: customerData.currentCustomer.mobile,
      },
    }))
  }

  const _handleSendOtp = ({ otp }: {otp: string}): void => {
    verifyOtp(otp)
  }

  const _handleClose = (): void => {
    // TODO: close
    ModalPlugin.shared().toggleGlobalModal(false, GlobalModalTypeEnum.VALIDATE_MOBILE)
    setState({ ...DEFAULT_STATE })
  }

  const _handleReEnter = (): void => {
    setState((prevState) => update(prevState, {
      otp: {
        $set: '',
      },
      displayType: {
        $set: DisplayTypeEnum.VERIFY,
      },
    }))
  }

  useEffect(() => {
    if (customerData?.currentCustomer?.mobileVerified === false && storeData?.storeConfig?.otpConfirmationRequired) {
      ModalPlugin.shared().toggleGlobalModal(true, GlobalModalTypeEnum.VALIDATE_MOBILE)
      if (customerData?.currentCustomer?.mobile) {
        setState((prevState) => update(prevState, {
          displayType: {
            $set: DisplayTypeEnum.SEND,
          },
        }))
      } else {
        setState((prevState) => update(prevState, {
          displayType: {
            $set: DisplayTypeEnum.GET_NUMBER,
          },
        }))
      }
    }
  }, [customerData?.currentCustomer?.mobile, customerData?.currentCustomer?.mobileVerified, storeData?.storeConfig?.otpConfirmationRequired])

  useEffect(() => {
    if (state.mobile) {
      sendOtp()
    }
  }, [state.mobile])

  useEffect(() => {
    if (modalsData.modals.validateMobile) {
      if (customerData?.currentCustomer?.mobileVerified === false && storeData?.storeConfig?.otpConfirmationRequired) {
        ModalPlugin.shared().toggleGlobalModal(true, GlobalModalTypeEnum.VALIDATE_MOBILE)
        if (customerData?.currentCustomer?.mobile) {
          setState((prevState) => update(prevState, {
            displayType: {
              $set: DisplayTypeEnum.SEND,
            },
          }))
        } else {
          setState((prevState) => update(prevState, {
            displayType: {
              $set: DisplayTypeEnum.GET_NUMBER,
            },
          }))
        }
      }
    }
  }, [modalsData.modals.validateMobile])

  const numberString = customerData?.currentCustomer?.mobile
    ? customerData?.currentCustomer?.mobile.substring(customerData?.currentCustomer?.mobile?.length - 4)
    : state.mobile
      ? state.mobile.substring(state.mobile.length - 4)
      : ''

  return (
    <Modal open={modalsData.modals.validateMobile} onClose={_handleClose}>
      <Container>
        <Choose>
          <When condition={state.displayType === DisplayTypeEnum.SEND}>
            <Heading className='title'>Please verify your mobile number</Heading>
            <Tag className='tag'>An OTP will be sent to the number ending in {numberString}</Tag>
            <Button
              className='button'
              title='SEND OTP'
              loading={sendLoading}
              onClick={_handleSend} />
            <Tag variant='t2'>{state.sendAttemptsRemaining !== null ? `${state.sendAttemptsRemaining} attempts remining` : ''}</Tag>
          </When>
          <When condition={state.displayType === DisplayTypeEnum.GET_NUMBER}>
            <Heading className='title'>Please verify your mobile number</Heading>
            <Tag className='tag'>Enter your mobile number below to update your account.</Tag>
            <Form form={form} onFinish={_handleUpdateMobile} loading={sendLoading} className='form'>
              <TextInput name='mobile' variant='phone' wrapperClassName='text' placeholder='Mobile Number' />
            </Form>
            <Button
              className='button'
              title='SEND OTP'
              loading={sendLoading}
              onClick={() => form.submit()} />
            <Tag variant='t2'>{state.sendAttemptsRemaining !== null ? `${state.sendAttemptsRemaining} attempts remining` : ''}</Tag>
          </When>
          <When condition={state.displayType === DisplayTypeEnum.VERIFY}>
            <Heading className='title'>Please verify your mobile number</Heading>
            <Tag className='tag'>An OTP has been sent to the number ending in {numberString}</Tag>
            <Form form={form} onFinish={_handleSendOtp} loading={sendLoading || verifyLoading} className='form'>
              <TextInput name='otp' wrapperClassName='text' placeholder='OTP' />
            </Form>
            <Button
              className='button'
              title='VERIFY OTP'
              loading={sendLoading}
              onClick={() => form.submit()} />
            <Tag variant='t2'>{state.verifyAttemptsRemaining !== null ? `${state.verifyAttemptsRemaining} attempts remining` : ''}</Tag>
            <Button
              variant='text'
              title='RE-SEND OTP'
              loading={sendLoading}
              onClick={_handleResend} />
            <Tag variant='t2'>{state.sendAttemptsRemaining !== null ? `${state.sendAttemptsRemaining} attempts remining` : ''}</Tag>
          </When>
          <When condition={state.displayType === DisplayTypeEnum.COMPLETE}>
            <Heading className='title'>Mobile number verified</Heading>
            <Tag className='tag'>Thank you for verifying your mobile number, happy shopping!</Tag>
            <Button
              className='button'
              title='CLOSE'
              loading={customerDataLoading}
              onClick={_handleClose} />
          </When>
          <When condition={state.displayType === DisplayTypeEnum.INCORRECT}>
            <Heading className='title'>Oh no!</Heading>
            <Tag className='tag'>The code you have entered is incorrect.</Tag>
            <Button
              className='button'
              title='RE-ENTER CODE'
              onClick={_handleReEnter} />
            <Tag variant='t2'>{state.verifyAttemptsRemaining !== null ? `${state.verifyAttemptsRemaining} attempts remining` : ''}</Tag>
            <Button
              variant='text'
              title='RE-SEND OTP'
              loading={sendLoading}
              onClick={_handleResend} />
            <Tag variant='t2'>{state.sendAttemptsRemaining !== null ? `${state.sendAttemptsRemaining} attempts remining` : ''}</Tag>
          </When>
          <When condition={state.displayType === DisplayTypeEnum.OTP_EXPIRED}>
            <Heading className='title'>Oh no!</Heading>
            <Tag className='tag'>The code you have entered has expired.</Tag>
            <Button
              variant='text'
              title='SEND A NEW OTP'
              loading={sendLoading}
              onClick={_handleResend} />
            <Tag variant='t2'>{state.sendAttemptsRemaining !== null ? `${state.sendAttemptsRemaining} attempts remining` : ''}</Tag>
          </When>
          <When condition={state.displayType === DisplayTypeEnum.TOO_MANY_OTP_ATTEMPTS}>
            <Heading className='title'>Oh no!</Heading>
            <Tag className='tag'>You have exceeded the amount of allowed attempts, please give our support team a call on <Link href="tel:0217853268">021 785 3268</Link> or pop us an email: <Link href={`mailto:${storeData?.storeConfig?.transEmailIdentSupportEmail}`}>{storeData?.storeConfig?.transEmailIdentSupportEmail}</Link></Tag>
            <Button
              className='button'
              title='OK'
              onClick={_handleClose} />
          </When>
          <When condition={state.displayType === DisplayTypeEnum.TOO_MANY_WRONG_INPUTS}>
            <Heading className='title'>Oh no!</Heading>
            <Tag className='tag'>You have exceeded the amount of allowed attempts, please give our support team a call on <Link href="tel:0217853268">021 785 3268</Link> or pop us an email: <Link href={`mailto:${storeData?.storeConfig?.transEmailIdentSupportEmail}`}>{storeData?.storeConfig?.transEmailIdentSupportEmail}</Link></Tag>
            <Button
              className='button'
              title='OK'
              onClick={_handleClose} />
          </When>
          <When condition={state.displayType === DisplayTypeEnum.ERROR}>
            <Heading className='title'>Oh no!</Heading>
            <Tag className='tag'>An unknown error has occured, please give our support team a call on <Link href="tel:0217853268">021 785 3268</Link> or pop us an email: <Link href={`mailto:${storeData?.storeConfig?.transEmailIdentSupportEmail}`}>{storeData?.storeConfig?.transEmailIdentSupportEmail}</Link></Tag>
            <Button
              className='button'
              title='OK'
              onClick={_handleClose} />
          </When>
        </Choose>
      </Container>
    </Modal>
  )

}
