import React from 'react'

import loadable from '@loadable/component'

import { PageLoader } from '@atoms/notifications'

import(/* webpackPrefetch: true */ '@pages/Category')

const Category = loadable(() => import(/* webpackChunkName: "category" */'@pages/Category'), {
  resolveComponent: (components) => components.Category,
})

export function CategoryAsync(): JSX.Element {

  return <Category fallback={<PageLoader />} />

}
