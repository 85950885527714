import React from 'react'

import styled from 'styled-components'

import { Button } from '@atoms/buttons'
import { Link, Paragraph } from '@atoms/typography'
import { ResponsivePXValue, theme } from '@components/Theme'
import { PageLinkSectionFragment } from '@hooks/api/index'
import { SiteHelper } from '@lib/SiteHelper'

const Container = styled.div<{ backgroundColor: string, foregroundColor: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  ${ResponsivePXValue('padding', '24px 0')}

  .link {
    color: ${(props): string => props.foregroundColor};
    text-decoration: underline;
  }
`
const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  ${ResponsivePXValue('align-items', { mobile: 'center', tablet: 'center', desktop: 'flex-start' })}
  `
export interface PageLinkSectionProps {
  section: PageLinkSectionFragment
}

export function PageLinkSection({ section }: PageLinkSectionProps): JSX.Element {

  const { buttonStyle, foregroundColor, backgroundColor } = section

  const buttonColors = {
    color: buttonStyle === 'SOLID' ? foregroundColor : buttonStyle === 'INVERTED' ? foregroundColor : theme.colors.black.codGrey,
    foregroundColor: buttonStyle === 'SOLID' ? foregroundColor: buttonStyle === 'INVERTED' ? foregroundColor : theme.colors.black.codGrey,
    backgroundColor: buttonStyle === 'SOLID' ? backgroundColor : buttonStyle === 'INVERTED' ? backgroundColor : theme.colors.misc.transparent,
    hoverColor: buttonStyle === 'SOLID' ? backgroundColor : buttonStyle === 'INVERTED' ? foregroundColor : theme.colors.white.pureWhite,
    borderColor: buttonStyle === 'SOLID' ? backgroundColor : buttonStyle === 'INVERTED' ? foregroundColor : theme.colors.black.codGrey,
    hoverBackgroundColor: buttonStyle === 'SOLID' ? backgroundColor : buttonStyle === 'INVERTED' ? foregroundColor : SiteHelper.getOpaqueColor(theme.colors.black.codGrey, 0.88),
    disabledColor: buttonStyle === 'SOLID' ? backgroundColor : buttonStyle === 'INVERTED' ? foregroundColor : theme.colors.white.pureWhite,
    disabledBackgrondColor: buttonStyle === 'SOLID' ? SiteHelper.getOpaqueColor(foregroundColor, 0.45) : buttonStyle === 'INVERTED' ? SiteHelper.getOpaqueColor(backgroundColor, 0.45) : SiteHelper.getOpaqueColor(theme.colors.white.pureWhite, 0.45),
    disabledBorderColor: buttonStyle === 'SOLID' ? SiteHelper.getOpaqueColor(foregroundColor, 0.45) : buttonStyle === 'INVERTED' ? SiteHelper.getOpaqueColor(backgroundColor, 0.45) : SiteHelper.getOpaqueColor(theme.colors.black.pureBlack, 0.45),
  }

  return (
    <Container backgroundColor={section.backgroundColor} foregroundColor={section.foregroundColor}>
      <Choose>
        <When condition={!!buttonStyle}>
          <ButtonContainer>
            <Button title={section.title} href={section.url} colors={buttonColors} />
          </ButtonContainer>
        </When>
        <Otherwise>
          <Paragraph>
            <Link href={section.url} className='link'>{section.title}</Link>
          </Paragraph>
        </Otherwise>
      </Choose>
    </Container>
  )
}
