import styled from 'styled-components'

import { ResponsivePXValue } from '@components/Theme'

export const ModalFormContainer = styled.div`
  ${ResponsivePXValue('padding', { mobile: '16px', tablet: '24px', desktop: '24px' })}
  ${ResponsivePXValue('width', { mobile: '100vw', tablet: '100%', desktop: '500px' })}

  .messageClass{
    ${ResponsivePXValue('margin', '20px 0')}
  }
`
