import React, { useState, useEffect, RefObject, useRef } from 'react'

import { ApolloQueryResult } from '@apollo/client'

import update from 'react-addons-update'
import { useLocation, useNavigate } from 'react-router'
import styled, { useTheme } from 'styled-components'

import { Button, Icon, LocalIconEnums, SmallLoader } from '@atoms/index'
import { ResponsivePXValue } from '@components/Theme'
import { useConfig } from '@contexts/ConfigProvider'
import { SearchQuery, SearchQueryDocument, SearchResultFragment } from '@hooks/api/index'
import { useClickOutside } from '@hooks/UseClickOutside'
import { SearchResults } from '@molecules/index'

const DEBOUNCE_TIME = 300
const SEARCH_RESULT_COUNT = 5

const Container = styled.div`

  flex-grow: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${(props): string => props.theme.colors.grey.pampas};
  ${ResponsivePXValue('padding', '0 0 0 20px')}
  ${ResponsivePXValue('border-radius', '2px')}
  ${ResponsivePXValue('height', '40px')}

`

const SearchIconContainer = styled.div`

  flex-shrink: 0;
  display: flex;
  cursor: pointer;

  ${ResponsivePXValue('width', '20px')}
  ${ResponsivePXValue('height', '20px')}

`

const CloseContainer = styled.div`
  flex-shrink: 0;
  display: flex;
  cursor: pointer;

  ${ResponsivePXValue('width', '40px')}
  ${ResponsivePXValue('height', '40px')}
`

const SearchInput = styled.input`
  flex-grow: 1;
  width: 100%;
  height: 100%;
  border: none; 
  border-width: 0; 
  box-shadow: none;
  outline: 0;
  &:focus {
    outline: none !important;
  }

  ::placeholder {
    color: ${(props): string => props.theme.colors.grey.stormDust};
  }
  color: ${(props): string => props.theme.colors.grey.stormDust};
  background-color: ${(props): string => props.theme.colors.grey.pampas};

  font-family: open-sans;
  font-weight: 700;
  
  ${ResponsivePXValue('font-size', '12px')}
  ${ResponsivePXValue('height', 'CALC(100% - 10px)')}
  ${ResponsivePXValue('padding', '10px')}
  
`

const ResultsContainer = styled.div`
  position: absolute;
  overflow-x: hidden;
  overflow-y: scroll;
  z-index: 6;
  background-color: ${(props): string => props.theme.colors.white.pureWhite};
  ${ResponsivePXValue('top', '45px')}
  ${ResponsivePXValue('left', '0')}
  ${ResponsivePXValue('max-height', '60vh')}
  ${ResponsivePXValue('border-radius', '2px')}
  width: 100%;
`

const SearchContainer = styled.div`
  position: relative;
  width: 100%;
`

export interface DesktopSearchProps {
  className?: string
}

interface DesktopSearchState {
  query: string
  shouldShow: boolean
  hasResult: boolean
  searchLoading: boolean
  items: SearchResultFragment[]
}

const DEFAULT_STATE: DesktopSearchState = {
  query: '',
  shouldShow: false,
  hasResult: false,
  searchLoading: false,
  items: [],
}

export function DesktopSearch({ className }: DesktopSearchProps): JSX.Element {

  const config = useConfig()
  const [state, setState] = useState<DesktopSearchState>({ ...DEFAULT_STATE })
  const theme = useTheme()
  const location = useLocation()
  const navigate = useNavigate()
  const searchInput: RefObject<HTMLInputElement> = useRef()
  let timer: NodeJS.Timeout

  const { isOutside, shouldMonitor, ref } = useClickOutside<HTMLDivElement>(true)

  const search = async (): Promise<void> => {
    const client = await config.getClient()
    const result: ApolloQueryResult<SearchQuery> = await client.query({
      query: SearchQueryDocument,
      variables: { phrase: state.query, pageSize: SEARCH_RESULT_COUNT, currentPage: 1 },
    })

    const hasResult = result.data?.search?.items.length > 0

    setState((prevState) => update(prevState, {
      hasResult: { $set: hasResult },
      items: { $set: result.data?.search?.items || [] },
      searchLoading: { $set: false },
    }))
  }

  const _handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, query: string): void => {
    if (e.key === 'Enter') {
      navigate(`/search?term=${query}`)
      _handleClear()
    }
  }

  const _handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setState((prevState) => update(prevState, {
      shouldShow: { $set: e.target.value.length >= 3 },
      query: { $set: e.target.value },
    }))

  }

  const _handleClick = (path: string): void => {
    setState((prevState) => update(prevState, {
      shouldShow: { $set: false },
      query: { $set: '' },
    }))
    navigate(path)
  }

  const _handleClear = (): void => {
    setState((prevState) => update(prevState, {
      hasResult: { $set: false },
      shouldShow: { $set: false },
      products: { $set: [] },
      categories: { $set: [] },
      query: { $set: '' },
    }))

    setTimeout(() => searchInput?.current?.focus())
  }

  useEffect(() => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      if (state.query.length >= 3) {
        setState((prevState) => update(prevState, {
          searchLoading: { $set: true },
        }))
        search()
      } else {
        setState((prevState) => update(prevState, {
          hasResult: { $set: false },
          products: { $set: [] },
          categories: { $set: [] },
        }))
      }
    }, DEBOUNCE_TIME)
    return () => clearTimeout(timer)
  }, [state.query])

  useEffect(() => {
    shouldMonitor(state.shouldShow)
  }, [state.shouldShow])

  useEffect(() => {
    if (state.shouldShow && isOutside) {
      setState((prevState) => update(prevState, {
        shouldShow: { $set: false },
      }))
    }
  }, [isOutside])

  useEffect(() => {
    setState((prevState) => update(prevState, {
      shouldShow: { $set: false },
      query: { $set: '' },
    }))
  }, [location.pathname])

  return (
    <SearchContainer className={className} ref={ref}>
      <Container>
        <SearchIconContainer>
          <Choose>
            <When condition={state.searchLoading}>
              <SmallLoader color={theme.colors.grey.stormDust} />
            </When>
            <Otherwise>
              <Icon icon={LocalIconEnums.SEARCH_OUTLINE} color={theme.colors.grey.stormDust} />
            </Otherwise>
          </Choose>
        </SearchIconContainer>
        <SearchInput
          ref={searchInput}
          value={state.query}
          onFocus={_handleSearchChange}
          onChange={_handleSearchChange}
          onKeyDown={(e) => _handleKeyDown(e, state.query)}
          placeholder="Search" />
        <CloseContainer>
          <If condition={state.hasResult || state.shouldShow}>
            <Button icon={LocalIconEnums.CLOSE} variant='nav' onClick={_handleClear} />
          </If>
        </CloseContainer>
      </Container>
      <If condition={state.shouldShow}>
        <ResultsContainer>
          <SearchResults searchTerm={state.query} results={state.items} onSelect={_handleClick} />
        </ResultsContainer>
      </If>
    </SearchContainer>
  )

}
