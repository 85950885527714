import { DocumentNode, FieldPolicy, gql, makeVar } from '@apollo/client'

import update from 'react-addons-update'
import { v4 } from 'uuid'

import { MenuCategoriesFragment } from '@hooks/api/index'
import { ConfigPlugin } from '@lib/Config'

export const MENU_CATEGORIES_DEFAULT_STATE: MenuCategoriesFragment = {
  id: v4(),
  open: false,
  currentTop: null,
  currentMiddle: null,
  currentBottom: null,
  __typename: 'MenuCategories',
}

const _data = makeVar<MenuCategoriesFragment>({ ...MENU_CATEGORIES_DEFAULT_STATE })

export class MenuCategoryPlugin implements ConfigPlugin {

  static instance: MenuCategoryPlugin

  static shared(): MenuCategoryPlugin {
    if (!this.instance) {
      this.instance = new MenuCategoryPlugin()
    }
    return this.instance
  }

  fieldPolicies = (): { [k: string]: FieldPolicy } => ({
    menuCategories: {
      read(): MenuCategoriesFragment {
        return _data() as MenuCategoriesFragment
      },
    },
  })

  open(): void {
    _data(update(_data(), {
      open: {
        $set: true,
      },
    }))
  }

  close(): void {
    _data(update(_data(), {
      open: {
        $set: false,
      },
    }))
  }

  setTopMiddleAndBottom(top: string | null, middle: string | null, bottom: string | null): void {
    _data(update(_data(), {
      currentTop: { $set: top },
      currentMiddle: { $set: middle },
      currentBottom: { $set: bottom },
    }))
  }

  types = (): DocumentNode => gql`
    type MenuCategories {
      id: ID!
      open: Boolean!
      currentTop: String
      currentMiddle: String
      currentBottom: String
    }
  `

  extensions = (): DocumentNode => gql`
    extend type Query {
      menuCategories: MenuCategories!
    }
  `

  queries = (): DocumentNode => gql`
    fragment MenuCategoriesFragment on MenuCategories {
      id
      open
      currentTop
      currentMiddle
      currentBottom
    }
    query GetMenuCategories {
      menuCategories @client {
        ... MenuCategoriesFragment
      }
    }
  `

}
