import React from 'react'

import { useNavigate } from 'react-router'
import styled, { useTheme } from 'styled-components'

import { Button } from '@atoms/buttons'
import { Loader } from '@atoms/notifications'
import { Heading, Paragraph } from '@atoms/typography'
import { ResponsivePXValue } from '@components/Theme'
import { CustomerOrderFragment, OrderFragment, useCustomerOrdersQuery } from '@hooks/api/index'
import { CustomerOrderSortableField, SortEnum } from '@uctypes/api/globalTypes'

import { Order } from './Order'

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  ${ResponsivePXValue('gap', { mobile: '8px', tablet: '19px', desktop: '19px' })}
`

const LoadingContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  ${ResponsivePXValue('height', { mobile: '100vh', tablet: '100vh', desktop: '100%' })}
`

const OrdersWrapper = styled.div`

`

const NoOrders = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }): string => theme.colors.white.pureWhite};
  ${ResponsivePXValue('padding', { mobile: '16px 0px 24px', tablet: '24px', desktop: '24px' })}
  ${ResponsivePXValue('gap', { mobile: '12px', tablet: '16px', desktop: '16px' })}
`

const HeadingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${ResponsivePXValue('padding', { mobile: '0px 16px' })}
  ${ResponsivePXValue('margin-top', { mobile: '9px', tablet: '0px', desktop: '0px' })}
  ${ResponsivePXValue('margin-bottom', { mobile: '9px', tablet: '19px', desktop: '19px' })}

  .orderHeading {
    margin: 0;
  }
`

const OrdersSection = styled.div`
  display: flex;
  flex-direction: column;
  ${ResponsivePXValue('gap', { mobile: '2px', tablet: '4px', desktop: '4px' })}
`

export function UserOrders(): JSX.Element {

  const { colors } = useTheme()
  const navigate = useNavigate()

  const { data, loading } = useCustomerOrdersQuery({
    // TODO: !!!!!!!!!!!!!!!!!!! this must be removed when the issue is sorted on Magento !!!!!!!!!!!!!!!!!!!
    errorPolicy: 'ignore',
    variables: {
      sort: {
        sortDirection: SortEnum.DESC,
        sortField: CustomerOrderSortableField.CREATED_AT,
      },
    },
  })

  const allOrders = data?.allOrders?.orders?.items || []

  const completeStages = ['Collected in store', 'Collected', 'Delivered', 'Cancelled', 'Complete', 'Closed']

  const activeOrders = allOrders?.filter(order => !completeStages.includes(order.status))
  const pastOrders = allOrders?.filter(order => completeStages.includes(order.status))

  const _handleContinueShopping = (e: React.MouseEvent<HTMLAnchorElement>): void => {
    e.preventDefault()
    navigate('/')
  }

  let order: CustomerOrderFragment

  return (

    <Container>
      <If condition={!!loading}>
        <LoadingContainer>
          <Loader />
        </LoadingContainer>
      </If>
      <If condition={!loading}>
        <If condition={activeOrders?.length > 0}>
          <OrdersWrapper>
            <HeadingContainer>
              <Heading variant='h3' className='orderHeading'>Active Orders</Heading>
              <Paragraph variant='p2' color={colors.green.bottleGreen}>{activeOrders.length} in progress</Paragraph>
            </HeadingContainer>
            <OrdersSection>
              <For each='order' of={activeOrders}>
                <Order key={order.id} order={order} isActive={true} />
              </For>
            </OrdersSection>
          </OrdersWrapper>
        </If>
        <If condition={pastOrders?.length > 0}>
          <OrdersWrapper>
            <HeadingContainer>
              <Heading variant='h3' className='orderHeading'>Past Orders</Heading>
            </HeadingContainer>
            <OrdersSection>
              <For each='order' of={pastOrders}>
                <Order key={order.id} order={order} isActive={false} />
              </For>
            </OrdersSection>
          </OrdersWrapper>
        </If>
        <If condition={allOrders?.length === 0}>
          <HeadingContainer>
            <Heading variant='h3' className='orderHeading'>Orders</Heading>
          </HeadingContainer>
          <NoOrders>
            <Paragraph variant='p1' align='center'>No orders, let&apos;s get shopping!</Paragraph>
            <Button variant='primary' title='Continue Shopping' href='/' onClick={_handleContinueShopping} />
          </NoOrders>
        </If>
      </If>
    </Container>
  )
}
