
import React from 'react'

import loadable from '@loadable/component'

import { PageLoader } from '@atoms/notifications'

import(/* webpackPrefetch: true */ '@pages/AboutUs')

const AboutUs = loadable(() => import(/* webpackChunkName: "AboutUs" */'@pages/AboutUs'), {
  resolveComponent: (components) => components.AboutUs,
})

export function AboutUsAsync(): JSX.Element {
  return <AboutUs fallback={<PageLoader />} />

}
