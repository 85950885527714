import React, { ReactNode } from 'react'

import styled, { css } from 'styled-components'

import { ResponsivePXValue } from '@components/Theme'

export type TitleVariant = 't1' | 't2' | 't3' | 't4' | 't5' | 't6' | 't7' | 't8' | 't9' | 't10'
export type TitleAlignment = 'left' | 'center' | 'right' | 'justify'
export type TitleTransforms = 'none' | 'capitalize' | 'uppercase' | 'lowercase'
export type TitleDecorations = 'none' | 'underline' | 'overline' | 'line-through'

const T = styled.div<{ align: string, decoration: string, transform: string, color?: string, variant: string }>`
  color: ${(props): string => props.color ? props.color : props.theme.colors.green.bottleGreen};
  text-align: ${(props): string => props.align};
  text-decoration: ${(props): string => props.decoration};
  text-transform: ${(props): string => props.transform};

  ${props => props.variant === 't1' && css`
    font-family: fortescue;
    font-weight: 700;
    ${ResponsivePXValue('font-size', { mobile: '30px', tablet: '32px', desktop: '38px' })}
    ${ResponsivePXValue('line-height', { mobile: '30px', tablet: '38px', desktop: '38px' })}
  `}

  ${props => props.variant === 't2' && css`
    font-family: open-sans;
    font-weight: 700;
    ${ResponsivePXValue('font-size', { mobile: '16px', tablet: '28px', desktop: '28px' })}
    ${ResponsivePXValue('line-height', { mobile: '22px', tablet: '36px', desktop: '36px' })}
  `}

  ${props => props.variant === 't3' && css`
    font-family: fortescue;
    font-weight: 700;
    ${ResponsivePXValue('font-size', { mobile: '16px', tablet: '24px', desktop: '24px' })}
    ${ResponsivePXValue('line-height', { mobile: '22px', tablet: '30px', desktop: '30px' })}
  `}

  ${props => props.variant === 't4' && css`
    font-family: fortescue;
    font-weight: 700;
    ${ResponsivePXValue('font-size', { mobile: '14px', tablet: '20px', desktop: '20px' })}
    ${ResponsivePXValue('line-height', { mobile: '20px', tablet: '26px', desktop: '26px' })}
  `}

  ${props => props.variant === 't5' && css`
    font-family: open-sans;
    font-weight: 700;
    ${ResponsivePXValue('font-size', { mobile: '16px', tablet: '18px', desktop: '18px' })}
    ${ResponsivePXValue('line-height', { mobile: '18px', tablet: '24px', desktop: '24px' })}
  `}

  ${props => props.variant === 't6' && css`
    font-family: fortescue;
    font-weight: 700;
    ${ResponsivePXValue('font-size', { mobile: '12px', tablet: '18px', desktop: '18px' })}
    ${ResponsivePXValue('line-height', { mobile: '20px', tablet: '22px', desktop: '22px' })}
  `}

  ${props => props.variant === 't7' && css`
    font-family: fortescue;
    font-weight: 700;
    ${ResponsivePXValue('font-size', { mobile: '11px', tablet: '16px', desktop: '16px' })}
    ${ResponsivePXValue('line-height', { mobile: '16px', tablet: '22px', desktop: '22px' })}
  `}

  ${props => props.variant === 't8' && css`
    font-family: open-sans;
    font-weight: 600;
    ${ResponsivePXValue('font-size', { mobile: '11px', tablet: '14px', desktop: '14px' })}
    ${ResponsivePXValue('line-height', { mobile: '14px', tablet: '24px', desktop: '22px' })}
  `}

  ${props => props.variant === 't9' && css`
    font-family: open-sans;
    font-weight: 700;
    ${ResponsivePXValue('font-size', { mobile: '18px', tablet: '32px', desktop: '32px' })}
    ${ResponsivePXValue('line-height', { mobile: '24px', tablet: '38px', desktop: '38px' })}
  `}

  ${props => props.variant === 't10' && css`
    font-family: open-sans;
    font-weight: 600;
    ${ResponsivePXValue('font-size', { mobile: '12px', tablet: '14px', desktop: '14px' })}
    ${ResponsivePXValue('line-height', { mobile: '14px', tablet: '16px', desktop: '16px' })}
  `}
`
export interface TitleProps {
  children: string | ReactNode | JSX.Element
  variant?: TitleVariant
  align?: TitleAlignment
  transform?: TitleTransforms
  decoration?: TitleDecorations
  color?: string
  skeleton?: boolean
  className?: string
}

export function Title({ children, variant = 't1', align = 'left', transform = 'none', decoration = 'none', color, className }: TitleProps): JSX.Element {

  return <T align={align} variant={variant} decoration={decoration} transform={transform} color={color} className={className}>{children}</T>

}
