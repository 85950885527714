
import { Paragraph } from "@atoms/typography"
import { ResponsivePXValue } from "@components/Theme"
import React, { RefObject, useCallback, useEffect, useRef, useState } from "react"
import styled from "styled-components"
import { FormContext, FormContextProperties } from '@molecules/inputs/index'
import { Field } from "rc-field-form"
import update from "react-addons-update"

const Track = styled.div`
    position: absolute;
    ${ResponsivePXValue('border-radius', '5px')}
    ${ResponsivePXValue('height', '5px')}
    background-color: ${({ theme }) => theme.colors.grey.gallery};
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
`

const ActiveTrack = styled.div`
    position: absolute;
    ${ResponsivePXValue('border-radius', '5px')}
    ${ResponsivePXValue('height', '5px')}
    background-color: ${({ theme }) => theme.colors.green.greenVogue};
    top: 50%;
    transform: translateY(-50%);
`

const Thumb = styled.div`
    position: absolute;
    ${ResponsivePXValue('height', '18px')}
    ${ResponsivePXValue('width', '18px')}
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.green.greenVogue};
    top: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    z-index: 2;
`


interface RangeInputInnerProps {
    value: [number, number]
    onChange: (value: [number, number]) => void
    min: number
    max: number
    step: number
    loading: boolean
    disabled: boolean

}



interface Values {
    value?: { min: number, max: number }
    onChange?: (value: [min: number, max: number]) => void
    }
    
interface RangeInputInnerState {
    isDragging: boolean
    isInitialized: boolean
}

const DEFAULT_STATE: RangeInputInnerState = {
    isDragging: false,
    isInitialized: false
}
function RangeInputInner({ value, onChange, min, max, step, loading, disabled }: RangeInputInnerProps) {
    
    const [state, setState] = useState<RangeInputInnerState>({ ...DEFAULT_STATE })
    const trackRef = useRef<HTMLDivElement>(null)
    const minThumbRef = useRef<HTMLDivElement>(null)
    const maxThumbRef = useRef<HTMLDivElement>(null)
    const lastUpdateRef = useRef<number>(Date.now())
    const [minValue, maxValue] = value

  
  
    const updateThumbPositions = useCallback(() => {
        if (trackRef.current && minThumbRef.current && maxThumbRef.current) {
            const trackWidth = trackRef.current.clientWidth
            if (trackWidth === 0) {
                return  
            }
            
            const minThumbLeft = ((clamp(minValue, min, max) - min) / (max - min)) * trackWidth
            const maxThumbLeft = ((clamp(maxValue, min, max) - min) / (max - min)) * trackWidth

            minThumbRef.current.style.left = `${minThumbLeft}px`
            maxThumbRef.current.style.left = `${maxThumbLeft}px`
        }
    }, [min, max, maxValue, minValue])

    useEffect(() => {
        const initializeComponent = () => {
            if (trackRef.current && trackRef.current.clientWidth > 0) {
                updateThumbPositions()
                setState(prevState => update(prevState, { isInitialized: { $set: true } }))

            } else {
                requestAnimationFrame(initializeComponent)
            }
        }

        !state.isInitialized && trackRef.current && setTimeout(initializeComponent, 0)
    }, [updateThumbPositions, state.isInitialized])
  
    useEffect(() => {
        state.isInitialized && updateThumbPositions()
    }, [updateThumbPositions, minValue, maxValue, state.isInitialized])

    const clamp = (value: number, lower: number, upper: number) => 
        Math.max(lower, Math.min(value, upper))
    
    const handleMove = useCallback((clientX: number, thumbRef: RefObject<HTMLDivElement>, updateValue: (newValue: number) => void) => {
        if(trackRef.current && thumbRef.current) {
            const now = Date.now()
            if (now - lastUpdateRef.current < 16) return
            lastUpdateRef.current = now

            const trackRect = trackRef.current.getBoundingClientRect()
            const newPosition = clamp(clientX - trackRect.left, 0, trackRect.width)
            const percentage = newPosition / trackRect.width
            const newValue = Math.round(percentage * (max - min) /  step) * step + min
            updateValue(clamp(newValue, min, max))
            updateThumbPositions()
        }
    }, [min, max, step, updateThumbPositions])
    
    const makeMoveHandler = (thumbRef: RefObject<HTMLDivElement>, updateValue: (newValue: number) => void) => {

        let requestAnimationFrameId: number

        return (e: MouseEvent | TouchEvent) => {
            const clientX = 'touches' in e ? e.touches[0].clientX : e.clientX
            cancelAnimationFrame(requestAnimationFrameId)
            requestAnimationFrameId = requestAnimationFrame(() => handleMove(clientX, thumbRef, updateValue))
        }
    }

    const handleStart = (thumbRef: React.RefObject<HTMLDivElement>, updateValue: (newValue: number) => void) => (e: React.MouseEvent | React.TouchEvent) => {
        if (loading || disabled) return
        e.preventDefault()
        setState(prevState => update(prevState, { isDragging: { $set: true } }))

        const moveHandler = makeMoveHandler(thumbRef, updateValue)

        const handleEnd = () => {
            setState(prevState => update(prevState, { isDragging: { $set: false } }))
            document.removeEventListener('mousemove', moveHandler)
            document.removeEventListener('mouseup', handleEnd)
            document.removeEventListener('touchmove', moveHandler)
            document.removeEventListener('touchend', handleEnd)

        }
    
        document.addEventListener('mousemove', moveHandler)
        document.addEventListener('mouseup', handleEnd)
        document.addEventListener('touchmove', moveHandler)
        document.addEventListener('touchend', handleEnd)
    }

    const updateMinValue = (newMin: number) => {
        const clampedValue = clamp(+newMin, +min, +maxValue - +step)
        onChange([clampedValue, maxValue])
    }
    
    const updateMaxValue = (newMax: number) => {
        const clampedValue = clamp(+newMax, +minValue + +step, +max)
        onChange([minValue, clampedValue])
    }
    
  
    return (
        <>
            <Track ref={trackRef} />
            <ActiveTrack
                style={{
                left: `${((minValue - min) / (max - min)) * 100}%`,
                right: `${100 - ((maxValue - min) / (max - min)) * 100}%`,}}/>
            <Thumb
                ref={minThumbRef}
                onMouseDown={handleStart(minThumbRef, updateMinValue)}
                onTouchStart={handleStart(minThumbRef, updateMinValue)}
                style={{ cursor: loading || disabled ? 'not-allowed' : 'pointer' }}/>
            <Thumb
                ref={maxThumbRef}
                onMouseDown={handleStart(maxThumbRef, updateMaxValue)}
                onTouchStart={handleStart(maxThumbRef, updateMaxValue)}
                style={{ cursor: loading || disabled ? 'not-allowed' : 'pointer' }}/>
        </>
    )
  }

const SliderContainer = styled.div`
    position: relative;
    width: 100%;
    ${ResponsivePXValue('height', '65px')}
    ${ResponsivePXValue('margin-top', { mobile: '16px' })}
`

const RangeLabels = styled.div`
    display: flex;
    justify-content: space-between;
    ${ResponsivePXValue('margin-bottom', '14px')}
`
  
export interface RangeInputProps {
    name: string
    min: number 
    max: number
    step: number
    loading?: boolean
}

export function RangeInput({ name, min, max, step, loading = false }: RangeInputProps) {

    return (
        <Field name={name}>
        {(control: { value?: [number, number]; onChange?: (value: [number, number]) => void }) => {
            const { value = [min, max], onChange = () => {} } = control
            return (
                <SliderContainer>
                    <RangeLabels>
                        <Paragraph>R{value[0]}</Paragraph>
                        <Paragraph>R{value[1]}</Paragraph>
                    </RangeLabels>
                    <FormContext.Consumer>
                    {({ loading: formLoading, disabled: formDisabled }: FormContextProperties) => (
                        <RangeInputInner
                            value={value}
                            onChange={onChange}
                            min={min}
                            max={max}
                            step={step}
                            loading={loading || formLoading}
                            disabled={formDisabled}/>    
                    )}
                    </FormContext.Consumer>
                </SliderContainer>
            ) 
        }}
        </Field>
    ) 
}



