import React, { } from 'react'

import styled, { useTheme } from 'styled-components'

import { Rating } from '@atoms/index'
import { Paragraph, Title } from '@atoms/typography'
import { ResponsivePXValue } from '@components/Theme'
import { PageBlockFragment } from '@hooks/api/index'

const Container = styled.div`
  max-width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  background-color: ${(props): string => props.theme.colors.brown.fantasy};
  ${ResponsivePXValue('gap', '16px')}
  ${ResponsivePXValue('padding', '24px 32px')}
`

export interface PageRatingBlockProps {
  block: PageBlockFragment
}

export function PageRatingBlock({ block }: PageRatingBlockProps): JSX.Element {

  const { rating, title, foregroundColor, description, author } = block

  const theme = useTheme()

  return (
    <Container>
      <Rating rating={rating * 20} color={theme.colors.green.bottleGreen} />
      <Title variant='t3' align='center' className='block-title' color={foregroundColor}>
        {title}
      </Title>
      <Paragraph align='center'>
        {description}
      </Paragraph>
      <Paragraph>
        {author}
      </Paragraph>
    </Container>
  )

}
