import React, { RefObject, useEffect, useRef } from 'react'

import { useParams } from 'react-router'
import styled from 'styled-components'

import { AppPlugin } from '@api/local/AppPlugin'
import { Loader } from '@atoms/notifications'
import { Heading, Paragraph } from '@atoms/typography'
import { useGetOnePageQuery } from '@hooks/api/index'
import useIsomorphicLayoutEffect from '@hooks/UseIsomorphicLayoutEffect'
import { Page } from '@organisms/content'

const Container = styled.div``

export function PageRenderer(): JSX.Element {

  const { id } = useParams<{ id: string }>()

  const { data, error, loading } = useGetOnePageQuery({ variables: { id } })
  const containerRef: RefObject<HTMLDivElement> = useRef()

  useIsomorphicLayoutEffect(() => {
    if (data) {
      window.status = 'READY'
    }
  }, [data])

  useIsomorphicLayoutEffect(() => {
    const width = containerRef.current?.clientWidth
    const height = containerRef.current?.clientHeight
    window.top?.postMessage({ width, height }, '*')
  }, [containerRef.current?.clientHeight])

  return (
    <Container ref={containerRef}>
      <Choose>
        <When condition={!!error}>
          <Heading>Error</Heading>
          <Paragraph>{error.message} ({error.extraInfo})</Paragraph>
        </When>
        <When condition={loading}>
          <Loader />
        </When>
        <When condition={!!data}>
          <Page page={data.page} />
        </When>
      </Choose>
    </Container>
  )

}
