import React from 'react'

import styled, { useTheme, CSS, css } from 'styled-components'

import { Paragraph } from '@atoms/index'
import { ResponsivePXValue } from '@components/Theme'

const Container = styled.a<{ backgroundColor: string, active: boolean }>`

  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  text-decoration: none;
  background-color: ${(props): string => props.backgroundColor};
  width: 100%;
  ${ResponsivePXValue('height', '28px')}
  ${ResponsivePXValue('margin-bottom', '4px')}
  ${ResponsivePXValue('padding', '0 8px')};

  ${(props): CSS => {
    if (props.active) {
      return css`font-weight: 700;`
    }

  }}

  .category-item-text {
    margin: 0;
    padding: 0;
  }
`

export interface DesktopCategoryMenuItemProps {
  title: string
  className?: string
  active: boolean
  hovering: boolean
  href: string
  onClick: () => void
  onMouseEnter?: () => void
  onMouseLeave?: () => void
}

export function DesktopCategoryMenuItem({ title, active, hovering, href, onClick, onMouseEnter, onMouseLeave }: DesktopCategoryMenuItemProps): JSX.Element {

  const theme = useTheme()

  const _handleMouseEnter = () => {
    onMouseEnter?.()
  }

  const _handleMouseLeave = () => {
    onMouseLeave?.()
  }

  const _handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    onClick?.()
  }

  const backgroundColor = hovering ? theme.colors.green.watercourse : active ? theme.colors.green.swansDown : ''
  const foregroundColor = hovering ? theme.colors.white.pureWhite : active ? theme.colors.black.pureBlack : theme.colors.green.bottleGreen

  return (
    <Container
      href={href}
      active={active}
      backgroundColor={backgroundColor}
      onMouseEnter={_handleMouseEnter}
      onMouseLeave={_handleMouseLeave}
      onClick={_handleClick}>
      <Paragraph className='category-item-text' variant='p3' color={foregroundColor}>{title}</Paragraph>
    </Container>
  )

}
