import React from 'react'

import styled, { useTheme } from 'styled-components'

import { Paragraph, Title } from '@atoms/typography'
import { ResponsivePXValue } from '@components/Theme'
import { PageMarkupSectionFragment } from '@hooks/api/index'
import { DeviceContainer } from '@utility/DeviceContainer'

const Container = styled.div`
  width: 100%;
  .markup-section-title {
    ${ResponsivePXValue('margin', '0')}
  }
  .markup-section-text {
    margin: 0;
  }
  .markup-section-text p {
    ${ResponsivePXValue('margin', '0 0 10px')}
  }
`
const Header = styled.div`

  ${ResponsivePXValue('margin-bottom', { mobile: '0', tablet: '8px', desktop: '8px' })};

  display: flex;
  align-items: center;
  justify-content: space-between;
  ${ResponsivePXValue('height', { mobile: '53px', tablet: 'auto', desktop: 'auto' })}


  
`

const MarkupContainer = styled.div``

export interface PageMarkupSectionProps {
  section: PageMarkupSectionFragment
}

export function PageMarkupSection({ section }: PageMarkupSectionProps): JSX.Element {

  const theme = useTheme()

  return (
    <Container>
      <If condition={!!section.title}>
        <Header>
          <DeviceContainer mobile>
            <Title variant='t5' className='markup-section-title' color={theme.colors.black.pureBlack}>{section.title}</Title>
          </DeviceContainer>
          <DeviceContainer desktop tablet>
            <Title variant='t8' className='markup-section-title' color={theme.colors.black.pureBlack}>{section.title}</Title>
          </DeviceContainer>
        </Header>
      </If>
      <Choose>
        <When condition={section.markupType === 'HTML'}>
          <Paragraph variant='p2' className='markup-section-text' color={theme.colors.green.bottleGreen}>
            <MarkupContainer dangerouslySetInnerHTML={{ __html: section.markup }} />
          </Paragraph>
        </When>
        <Otherwise>
          <Paragraph variant='p2' className='markup-section-text' color={theme.colors.green.bottleGreen}>
            <MarkupContainer style={{ whiteSpace: 'pre-line' }}>
              {section.markup}
            </MarkupContainer>
          </Paragraph>
        </Otherwise>
      </Choose>

    </Container>
  )

}
