
import React from 'react'
import { Svg } from './Svg'

export interface SortProps {
  color?: string
  hoverColor?: string
  className?: string
}

export function Sort({ color, hoverColor, className }: SortProps): JSX.Element {

  return (
    <Svg strokeColor={color} strokeHoverColor={hoverColor} fillColor={color} fillHoverColor={hoverColor} className={className} width="100%" height="100%" viewBox="0 0 18 20" version="1.1">
      <g transform="matrix(1.04273,0,0,1.04273,-3.51274,-2.51275)">
        <path className='fill' d="M19.679,10.167L17.555,3.527C17.541,3.483 17.514,3.444 17.476,3.417C17.439,3.389 17.393,3.375 17.347,3.375L15.533,3.375C15.436,3.375 15.354,3.436 15.324,3.527L13.184,10.167C13.177,10.188 13.172,10.212 13.172,10.235C13.172,10.355 13.271,10.453 13.39,10.453L14.712,10.453C14.811,10.453 14.895,10.387 14.923,10.294L15.333,8.85L17.419,8.85L17.825,10.291C17.85,10.385 17.937,10.451 18.036,10.451L19.47,10.451C19.493,10.451 19.515,10.448 19.536,10.441C19.592,10.423 19.636,10.385 19.665,10.334C19.69,10.282 19.695,10.223 19.679,10.167ZM15.547,7.629L16.315,4.889L16.463,4.889L17.215,7.629L15.547,7.629ZM18.91,19.181L15.872,19.181L15.872,19.172L18.98,14.745C19.006,14.707 19.02,14.665 19.02,14.618L19.02,13.765C19.02,13.645 18.922,13.547 18.802,13.547L14.021,13.547C13.901,13.547 13.803,13.645 13.803,13.765L13.803,14.773C13.803,14.892 13.901,14.991 14.021,14.991L16.894,14.991L16.894,15L13.775,19.427C13.749,19.464 13.735,19.509 13.735,19.554L13.735,20.407C13.735,20.527 13.833,20.625 13.953,20.625L18.908,20.625C19.027,20.625 19.125,20.527 19.125,20.407L19.125,19.399C19.126,19.371 19.12,19.342 19.11,19.316C19.099,19.29 19.083,19.266 19.063,19.245C19.043,19.225 19.019,19.209 18.993,19.198C18.967,19.187 18.938,19.181 18.91,19.181ZM9.75,16.453L7.969,16.453L7.969,4.031C7.969,3.928 7.885,3.844 7.782,3.844L6.469,3.844C6.366,3.844 6.282,3.928 6.282,4.031L6.282,16.453L4.5,16.453C4.343,16.453 4.254,16.636 4.353,16.758L6.978,20.084C6.995,20.106 7.018,20.124 7.043,20.137C7.069,20.149 7.097,20.156 7.125,20.156C7.154,20.156 7.182,20.149 7.208,20.137C7.233,20.124 7.256,20.106 7.273,20.084L9.898,16.758C9.994,16.636 9.907,16.453 9.75,16.453Z" />
      </g>
    </Svg>
  )

}
    