export const colors = {
  white: {
    pureWhite: '#FFFFFF',
    floralWhite: '#fffbf5',
    fantasy: '#FCFAF6', // Shell white
    pampas: '#f4f2ed', // Neutral - background
    romance: '#f4f1e4',
  },
  yellow: {
    cornSilk: '#fffae5',
    lemonChiffon: '#fff6cc', // Social
    dolly: '#f4ee7a', // Yellow
    gorse: '#ffdd32',
  },
  pink: {
    bridesmaid: '#fae1df',
  },
  brown: {
    fantasy: '#f1e7dd', // Neutral -sticky
  },
  purple: {
    plum: '#3C2432',
  },
  orange: {
    navajoWhite: '#ffe3ab',
    yellowOrange: '#FBAE42',
    casablanca: '#F8AF44', // Orange
    mySin: '#fbae42',
    piper: '#d46327', // Social text
  },
  green: {
    magicMint: '#c3f4d9', // Cactus
    swansDown: '#D9EDE7', // Soft tones 2
    skeptic: '#D2E9DA',
    surfCrest: '#BEDABC',
    snowFlurry: '#e3f4bd', // Soft tones 1
    tusk: '#EAF7C9', // Environment
    goldenrod: '#bbdf7e',
    sushi: '#8CC63F',
    deYork: '#7EC196',
    silverTree: '#64B69D', // Silver Tree
    lochinvar: '#4da499',
    viridian: '#3E8E7B', // Environment text
    watercourse: '#006654', // FtN green
    greenVogue: '#204b4a', // Aqua deep
    bottleGreen: '#031410', // Black green
  },
  blue: {
    tropicalBlue: '#D4EBF9', // Health
    tranquil: '#d9eae7',
    curiousBlue: '#458bc3', // Text link
    bahamaBlue: '#1a4170',
    prussianBlue: '#022345',
  },
  grey: {
    desertStorm: '#f7f6f5',
    pampas: '#F6F5F1', // Neutral - background
    athens: '#F3F4F8', // Gray 4
    gallery: '#EAEAEA', // Gray 3
    porcelain: '#E2E7E7',
    swirl: '#D6D1C8', // Swil
    silver: '#C2C2C2', // Grey 2
    gunSmoke: '#808987',
    stormDust: '#6b6b69', // Grey 1
  },
  black: {
    codGrey: '#191919',
    bottleGreen: '#031410',
    pureBlack: '#000',
  },
  red: {
    cinnabar: '#ea3e33', // Red
  },
  misc: {
    modalOverlay: 'rgba(0, 0, 0, 0.24)',
    facebookBlue: '#3B5998',
    googleRed: '#DB4437',
    transparent: 'RGBA(255, 255, 255, 0)',
  },
}
