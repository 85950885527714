import React from 'react'

import styled, { useTheme } from 'styled-components'

import { Icon, LocalIconEnums, Rating } from '@atoms/index'
import { ResponsivePXValue } from '@components/Theme'

const FooterReview = styled.div<{ mobile: boolean}>`
  display: ${(props) => props.mobile ? 'flex' : 'none'};
  justify-content: space-around;
  width: 100%;
  padding: 16px 0;
`

const ReviewLink = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`
const IconContainer = styled.div`
  flex-shrink: 0;
  ${ResponsivePXValue('margin-right', { mobile: '11px', tablet: '11px', desktop: '11px' })}
  ${ResponsivePXValue('width', { mobile: '15px', tablet: '15px', desktop: '15px' })}
  ${ResponsivePXValue('height', { mobile: '15px', tablet: '15px', desktop: '15px' })}
`

export function MobileFooterReviews(): JSX.Element {
  const theme = useTheme()
  const isMobile = theme.isMobile()

  const _handleLink = (platform: string) => {
    if (platform === 'Facebook') {
      window.open('https://www.facebook.com/FaithfulToNature/reviews', '_blank')
    } else if (platform === 'Google') {
      window.open('https://www.google.com/maps/place/Faithful+to+Nature+Muizenberg/@-34.0910403,18.4834972,17z/data=!3m1!4b1!4m6!3m5!1s0x1dcc6ae2a3bd1bab:0xf0e60b169818fce6!8m2!3d-34.0910403!4d18.4860775!16s%2Fg%2F11b6mpccpd?entry=ttu&g_ep=EgoyMDI0MTEwNS4wIKXMDSoASAFQAw%3D%3D', '_blank')
    }
  }

  return (
    <FooterReview mobile={isMobile}>
      <ReviewLink onClick={() => _handleLink('Facebook')}>
        <IconContainer>
          <Icon icon={LocalIconEnums.FACEBOOK} color={theme.colors.green.bottleGreen}/>
        </IconContainer>
        <Rating rating={5} size='large' />
      </ReviewLink>
      <ReviewLink onClick={() => _handleLink('Google')}>
        <IconContainer>
          <Icon icon={LocalIconEnums.GOOGLE} color={theme.colors.green.bottleGreen} />
        </IconContainer>
        <Rating rating={5} size='large' />
      </ReviewLink>
    </FooterReview>
  )
}
