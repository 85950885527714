import React from 'react'

import styled from 'styled-components'

import { IconCard } from '@atoms/layout'
import { Heading } from '@atoms/typography'
import { ResponsivePXValue } from '@components/Theme'
import { PageIconFragment, PageIconSectionFragment } from '@hooks/api/index'

const Container = styled.div`
  width: 100%; 
`

const HeadingContainer = styled.div`
  ${ResponsivePXValue('margin-bottom', { mobile: '8px', tablet: '40px', desktop: '40px' })}
  ${ResponsivePXValue('padding', '12px 0')}
  background-color: ${(props): string => props.theme.colors.white.fantasy};
`

const IconContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  ${ResponsivePXValue('gap', { mobile: '8px', tablet: '24px', desktop: '24px' })}
`
export interface PageIconSectionProps {
  section: PageIconSectionFragment
}

export function PageIconSection({ section }: PageIconSectionProps): JSX.Element {

  let icon!: PageIconFragment

  return (
    <Container>
      <HeadingContainer>
        <Heading variant='h2' align='center' >{section.title}</Heading>
      </HeadingContainer>
      <IconContainer>
        <For each='icon' of={section.icons}>
          <IconCard
            key={icon.id}
            title={icon.title}
            href={icon.href}
            image={icon.icon}
            titleColour={section.foregroundColor}
            backgroundColor={section.backgroundColor} />
        </For>
      </IconContainer>
    </Container>
  )

}
