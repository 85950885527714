import React, { useState, useEffect } from 'react'

import useEmblaCarousel from 'embla-carousel-react'
import update from 'react-addons-update'
import styled from 'styled-components'

import { APP_DEFAULT_STATE } from '@api/local/AppPlugin'
import { Button } from '@atoms/buttons'
import { LocalIconEnums } from '@atoms/images'
import { UltraContainer } from '@atoms/misc'
import { Heading } from '@atoms/typography'
import { ResponsivePXValue } from '@components/Theme'
import { AppFragment, useGetAppQuery, PageBlockSectionFragment, PageBlockFragment, PageDeviceIntValueFragment } from '@hooks/api/index'
import { PageBlock } from '@molecules/content'
import { DeviceTypeEnum } from '@uctypes/api/globalTypes'
import { AccordionProvider } from '@contexts/AccordionProvider'

const valueForSizeClass = (classes: PageDeviceIntValueFragment, appData: AppFragment): number => {
  switch (appData.deviceType) {
    case DeviceTypeEnum.DESKTOP:
      return classes.desktop
    case DeviceTypeEnum.ULTRA:
      return classes.desktop
    case DeviceTypeEnum.TABLET:
      return classes.tablet
    default:
      return classes.mobile
  }
}

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .section-title {
    ${ResponsivePXValue('margin', { mobile: '12px', tablet: '12px', desktop: '24px' })}
  }
  .more-button {
    ${ResponsivePXValue('width', { mobile: 'CALC(100% - 16px)' })}
    ${ResponsivePXValue('margin', { mobile: '16px 0', tablet: '16px 0', desktop: '32px 0' })}
  }
`

const Slider = styled(UltraContainer)`
  position: relative;
  width: 100%;
  margin-top: 0;

  .slider-slide {
    outline: none !important;
    cursor: default;
  }

  .previous-button {
    position: absolute;
    ${ResponsivePXValue('top', 'CALC(50% - 15px)')}
    ${ResponsivePXValue('left', { mobile: '5px', desktop: '30px' })}
  }

  .next-button {
    position: absolute;
    ${ResponsivePXValue('top', 'CALC(50% - 15px)')}
    ${ResponsivePXValue('right', { mobile: '5px', desktop: '30px' })}
  }
`

const Embla = styled.div`
  overflow: hidden;
`

const EmblaContainer = styled.div<{ gap: PageDeviceIntValueFragment }>`
  display: flex;
  ${(props): string => {
    return ResponsivePXValue('gap', {
      mobile: `${props.gap.mobile}px`,
      tablet: `${props.gap.tablet}px`,
      desktop: `${props.gap.desktop}px`,
    })
  }}
`

const EmblaSlide = styled.div<{ columns: PageDeviceIntValueFragment }>`
  ${(props): string => {
    return ResponsivePXValue('flex', {
      mobile: `0 0 ${100 / props.columns.mobile}%`,
      tablet: `0 0 ${100 / props.columns.tablet}%`,
      desktop: `0 0 ${100 / props.columns.desktop}%`,
    })
  }}
`

const Grid = styled.div<{ gap: PageDeviceIntValueFragment, columns: PageDeviceIntValueFragment }>`
  display: grid;
  grid-auto-rows: 1fr;
  width: 100%;
  ${(props): string => {
    return ResponsivePXValue('grid-template-columns', {
      mobile: `repeat(${props.columns.mobile}, minmax(0, 1fr))`,
      tablet: `repeat(${props.columns.tablet}, minmax(0, 1fr))`,
      desktop: `repeat(${props.columns.desktop}, minmax(0, 1fr))`,
    })
  }}
  ${(props): string => {
    return ResponsivePXValue('row-gap', {
      mobile: `${props.gap.mobile}px`,
      tablet: `${props.gap.tablet}px`,
      desktop: `${props.gap.desktop}px`,
    })
  }}
  ${(props): string => {
    return ResponsivePXValue('column-gap', {
      mobile: `${props.gap.mobile}px`,
      tablet: `${props.gap.tablet}px`,
      desktop: `${props.gap.desktop}px`,
    })
  }}
`

const CardContainer = styled.div<{ gap: PageDeviceIntValueFragment, columns: PageDeviceIntValueFragment }>`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  
  ${(props): string => {
    return ResponsivePXValue('gap', {
      mobile: `${props.gap.mobile}px`,
      tablet: `${props.gap.tablet}px`,
      desktop: `${props.gap.desktop}px`,
    })
  }}
`

const CardItem = styled.div<{ columns: PageDeviceIntValueFragment }>`
  width: 100%;
  
`

const Cell = styled.div<{ columns: PageDeviceIntValueFragment, rows: PageDeviceIntValueFragment, index: number }>`
  align-self: stretch;
  justify-self: stretch;
`

export interface PageBlockSectionProps {
  section: PageBlockSectionFragment
}

interface PageBlockSectionState {
  previousDisabled: boolean
  nextDisabled: boolean
  currentIndex: number
  disaplyButtons: boolean
  showingMore: boolean
  openBlockId: string | null
}

const DEFAULT_STATE: PageBlockSectionState = {
  previousDisabled: true,
  nextDisabled: false,
  currentIndex: 0,
  disaplyButtons: false,
  showingMore: false,
  openBlockId: null,
}

export function PageBlockSection({ section }: PageBlockSectionProps): JSX.Element {

  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()
  const columnCount = valueForSizeClass(section.columns, appData.app)
  const rowCount = valueForSizeClass(section.rows, appData.app)
  const showMore = rowCount > 1 && section.blocks.length > rowCount * columnCount
  const isMobile = appData.app.deviceType === DeviceTypeEnum.MOBILE
  const [state, setState] = useState<PageBlockSectionState>({ ...DEFAULT_STATE })
  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: false, slidesToScroll: columnCount })

  const _handlePrevious = () => {
    if (emblaApi.canScrollPrev()) {
      emblaApi.scrollPrev()
    }
  }

  const _handleNext = () => {
    if (emblaApi.canScrollNext()) {
      emblaApi.scrollNext()
    }
  }

  const _handleToggleShowMore = () => {
    setState((prevState) => update(prevState, {
      showingMore: {
        $set: !prevState.showingMore,
      },
    }))
  }

  const _handleOpenBlock = (blockId: string) => { 
    setState((prevState) => update(prevState, {
      openBlockId: {
        $set: prevState.openBlockId === blockId ? null : blockId,
      },
    }))
  }

  useEffect(() => {
    if (emblaApi) {
      emblaApi.on('select', () => {
        const previousDisabled = !emblaApi.canScrollPrev()
        const nextDisabled = !emblaApi.canScrollNext()
        const currentIndex = emblaApi.selectedScrollSnap()
        setState((prevState) => update(prevState, {
          previousDisabled: { $set: previousDisabled },
          nextDisabled: { $set: nextDisabled },
          currentIndex: { $set: currentIndex },
        }))
      })
    }
  }, [emblaApi])
  let block!: PageBlockFragment
  let blockIndex!: number

  const blocks: PageBlockFragment[] = showMore && !state.showingMore
    ? [...section.blocks].splice(0, rowCount * columnCount)
    : [...section.blocks]

  const renderBlock = () => (
    <Container>
      <If condition={!!section.title}>
        <Heading variant='h1' align='center' className='section-title'>{section.title}</Heading>
      </If>
      <Choose>
        <When condition={rowCount === 1 && blocks?.length > columnCount}>
          <Slider>
            <Embla ref={emblaRef}>
              <EmblaContainer gap={section.gap}>
                <For each='block' of={blocks}>
                  <EmblaSlide key={block.id} columns={section.columns}>
                    <PageBlock key={block.id} block={block} type={section.blockType} />
                  </EmblaSlide>
                </For>
              </EmblaContainer>
            </Embla>
            <Button
              variant='direction'
              shape='round'
              className='previous-button'
              disabled={state.previousDisabled}
              icon={LocalIconEnums.CHEVRON_LEFT}
              onClick={_handlePrevious} />
            <Button
              variant='direction'
              shape='round'
              className='next-button'
              disabled={state.nextDisabled}
              icon={LocalIconEnums.CHEVRON_RIGHT}
              onClick={_handleNext} />
          </Slider>
        </When>
        <Otherwise>
          <>
            <Choose>
              <When condition={section.blockType === 'CARD' && appData.app.deviceType === DeviceTypeEnum.MOBILE}>
                <CardContainer gap={section.gap} columns={section.columns}>
                  <For each='block' index='blockIndex' of={blocks}>
                    <CardItem key={block.id} columns={section.columns}>
                      <PageBlock key={block.id} block={block} type={section.blockType} />
                    </CardItem>
                  </For>
                </CardContainer>
              </When>   
              <Otherwise>
                <Grid gap={section.gap} columns={section.columns}>
                  <For each='block' index='blockIndex' of={blocks}>
                    <Cell key={block.id} columns={section.columns} rows={section.rows} index={blockIndex}>
                      <PageBlock key={block.id} block={block} type={section.blockType} />
                    </Cell>
                  </For>
                </Grid>
              </Otherwise>
            </Choose>
            <If condition={showMore}>
              <Button
                className='more-button'
                title={state.showingMore ? 'Show Less' : 'Show More'}
                onClick={_handleToggleShowMore} />
            </If>
          </>
        </Otherwise>
      </Choose>
    </Container>
  )

  if (section.blockType === 'CARD') {
    return (
      <AccordionProvider isMobile={isMobile}>
        {renderBlock()}
      </AccordionProvider>
    )
  }

  return renderBlock()

}
