import React from 'react'

import styled, { css, useTheme } from 'styled-components'

import { Button } from '@atoms/buttons'
import { ResponsiveImage } from '@atoms/images'
import { Heading, Paragraph } from '@atoms/typography'
import { ResponsivePXValue } from '@components/Theme'
import { PageBannerFragment } from '@hooks/api/index'
import { SiteHelper } from '@lib/SiteHelper'
import { PageBannerLayoutEnum, PageHorizontalAlignment, PageLink } from '@uctypes/api/globalTypes'
import { DeviceContainer } from '@utility/DeviceContainer'

import { HTML } from './HTML'

const Container = styled.div<{ layout: PageBannerLayoutEnum }>`
  width: 100%;
  ${ResponsivePXValue('height', { desktop: 'fit-content' })}
  display: flex;
  ${(props): string => ResponsivePXValue('flex-direction', {
    mobile: props.layout === PageBannerLayoutEnum.IMAGE_LEFT ? 'column' : 'column-reverse',
    tablet: props.layout === PageBannerLayoutEnum.IMAGE_LEFT ? 'column' : 'column-reverse',
    desktop: props.layout === PageBannerLayoutEnum.IMAGE_LEFT ? 'row' : 'row-reverse',
  })}
`

const ImageContainer = styled.div`
  ${ResponsivePXValue('width', { mobile: '100%', tablet: '100%', desktop: '50%' })}
  ${ResponsivePXValue('height', { mobile: '100%', tablet: '100%', desktop: 'auto' })}
`

const ContentContainer = styled.div<{ backgroundColor: string, foregroundColor: string, alignment: string }>`
  display: flex;
  flex-direction: column;
  align-items: ${({ alignment }): string => alignment === PageHorizontalAlignment.LEFT
    ? 'flex-start'
    : alignment === PageHorizontalAlignment.RIGHT
      ? 'flex-end'
      : 'center'};
  justify-content: center;
  ${ResponsivePXValue('width', { mobile: '100%', tablet: '100%', desktop: '50%' })}
  ${ResponsivePXValue('height', { mobile: '100%', tablet: '100%', desktop: 'auto' })}
  ${ResponsivePXValue('padding', { mobile: '24px 16px', tablet: '24px 16px', desktop: '32px' })}
  ${ResponsivePXValue('gap', '16px')}
  color: ${(props): string => props.foregroundColor};
  background-color: ${(props): string => props.backgroundColor};

  .align {
    ${ResponsivePXValue('line-height', { mobile: '36px', tablet: '44px', desktop: '44px' })}
  }
`

const SubTitleContainer = styled.div`

`

const ButtonContainer = styled.div`
  display: flex;
  ${ResponsivePXValue('align-items', { mobile: 'center', tablet: 'center', desktop: 'flex-start' })}
  justify-content: center;
  ${ResponsivePXValue('gap', '8px')}
`

export interface PageSplitLargeImageBannerProps {
  banner: PageBannerFragment
}

export function PageSplitLargeImageBanner({ banner }: PageSplitLargeImageBannerProps): JSX.Element {

  const theme = useTheme()
  const { title, titleAlignment, buttonStyle, layout, description, links, mobileImage, image, subTitle } = banner
  const foregroundColor = banner.foregroundColor || theme.colors.green.bottleGreen
  const backgroundColor = banner.backgroundColor || theme.colors.white.pureWhite

  const alignment = titleAlignment === PageHorizontalAlignment.LEFT
    ? 'left'
    : titleAlignment === PageHorizontalAlignment.RIGHT
      ? 'right'
      : 'center'

  const buttonColors = {
    color: buttonStyle === 'SOLID' ? backgroundColor : buttonStyle === 'INVERTED' ? foregroundColor : theme.colors.white.pureWhite,
    backgroundColor: buttonStyle === 'SOLID' ? foregroundColor : buttonStyle === 'INVERTED' ? backgroundColor : theme.colors.black.pureBlack,
    borderColor: buttonStyle === 'SOLID' ? foregroundColor : buttonStyle === 'INVERTED' ? foregroundColor : theme.colors.black.pureBlack,
    hoverColor: buttonStyle === 'SOLID' ? foregroundColor : buttonStyle === 'INVERTED' ? backgroundColor : theme.colors.white.pureWhite,
    hoverBackgroundColor: buttonStyle === 'SOLID' ? backgroundColor : buttonStyle === 'INVERTED' ? foregroundColor : SiteHelper.getOpaqueColor(theme.colors.green.bottleGreen, 0.88),
    hoverBorderColor: buttonStyle === 'SOLID' ? foregroundColor : buttonStyle === 'INVERTED' ? backgroundColor : SiteHelper.getOpaqueColor(theme.colors.green.bottleGreen, 0.88),
    disabledColor: buttonStyle === 'SOLID' ? backgroundColor : buttonStyle === 'INVERTED' ? foregroundColor : theme.colors.white.pureWhite,
    disabledBackgrondColor: buttonStyle === 'SOLID' ? SiteHelper.getOpaqueColor(foregroundColor, 0.45) : buttonStyle === 'INVERTED' ? SiteHelper.getOpaqueColor(backgroundColor, 0.45) : SiteHelper.getOpaqueColor(theme.colors.black.pureBlack, 0.45),
    disabledBorderColor: buttonStyle === 'SOLID' ? SiteHelper.getOpaqueColor(foregroundColor, 0.45) : buttonStyle === 'INVERTED' ? SiteHelper.getOpaqueColor(backgroundColor, 0.45) : SiteHelper.getOpaqueColor(theme.colors.black.pureBlack, 0.45),
  }

  let link: PageLink
  let index: number

  return (
    <Container layout={layout}>
      <ImageContainer>
        <DeviceContainer desktop tablet>
          <ResponsiveImage image={image} objectFit='cover' />
        </DeviceContainer>
        <DeviceContainer mobile>
          <ResponsiveImage image={mobileImage || image} objectFit='cover' />
        </DeviceContainer>
      </ImageContainer>
      <ContentContainer foregroundColor={foregroundColor} backgroundColor={backgroundColor} alignment={titleAlignment}>
        <Heading
          variant='h1'
          color={foregroundColor}
          align={alignment}>
          {title}
        </Heading>
        <If condition={!!subTitle}>
          <SubTitleContainer>
            <Paragraph
              variant='p1'>
              {subTitle}
            </Paragraph>
          </SubTitleContainer>
        </If>
        <HTML color={foregroundColor}>
          {description}
        </HTML>
        <If condition={!!links}>
          <ButtonContainer>
            <For each='link' index='index' of={links || []}>
              <Button title={link.title} href={link.url} key={index} colors={buttonColors} />
            </For>
          </ButtonContainer>
        </If>
      </ContentContainer>
    </Container>
  )

}
