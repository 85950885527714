import React from 'react'

import convert from 'color-convert'
import styled from 'styled-components'

import { Icon, LocalIconEnums, ResponsiveImage } from '@atoms/images'
import { Card } from '@atoms/layout/Card'
import { Paragraph } from '@atoms/typography'
import { ResponsivePXValue } from '@components/Theme'
import { useActiveWidgetsQuery } from '@hooks/api'
import { SiteHelper } from '@lib/SiteHelper'
import { HTML } from '@molecules/content/HTML'

const Container = styled.div`
${ResponsivePXValue('margin', '15px 0')}
  .card {
    ${ResponsivePXValue('padding', '12px')}
  }
`

const Content = styled.div`

  display: flex;
  align-items: center;
  ${ResponsivePXValue('gap', '12px')}

  .html {
    width: fit-content;
    max-width: 100%;
    p:last-child {
      margin-bottom: 0;
    }
  }

`

const IconContainer = styled.div`
  flex-shrink: 0;
  ${ResponsivePXValue('width', '35px')}
  ${ResponsivePXValue('height', '35px')}
`

export function UpSellWidget(): JSX.Element {

  const { data } = useActiveWidgetsQuery()

  return (
    <If condition={!!data?.activeWidgets?.productDetailsAddToCart}>
      <Container>
        <Card backgroundColor={data.activeWidgets.productDetailsAddToCart.contentBackgroundColor} className='card'>
          <Content>
            <IconContainer>
              <ResponsiveImage image={data.activeWidgets.productDetailsAddToCart.icon} />
            </IconContainer>
            <HTML className='html' color={data.activeWidgets.productDetailsAddToCart.contentForegroundColor}>{data.activeWidgets.productDetailsAddToCart.content}</HTML>
          </Content>
        </Card>
      </Container>
    </If>

  )

}
