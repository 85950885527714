import React from 'react'

import styled from 'styled-components'

import { ModalPlugin, GlobalModalTypeEnum } from '@api/local/ModalPlugin'
import { Heading, Button, ButtonColors } from '@atoms/index'
import { ResponsivePXValue, theme } from '@components/Theme'
import { useCustomerQuery } from '@hooks/api/index'
import { Form, useForm } from '@molecules/inputs/Form'
import { TextInput } from '@molecules/inputs/index'
import { CustomerTypeEnum } from '@uctypes/api/globalTypes'
import { DeviceContainer } from '@utility/DeviceContainer'

const JoinNowWrapper = styled.div`
  ${ResponsivePXValue('padding', { mobile: '12px', tablet: '12px', desktop: '12px' })}
  background-color: ${(props): string => props.theme.colors.green.watercourse};
`

const Container = styled.div`
  ${ResponsivePXValue('display', { mobile: '', tablet: 'flex', desktop: 'flex' })}
  ${ResponsivePXValue('width', { mobile: '', tablet: '1440px', desktop: '1440px' })}
  align-items: center;
  margin: 0 auto;

  .heading {
    padding: 0;
    text-align: center;
    ${ResponsivePXValue('line-height', { mobile: '13px', tablet: '24px', desktop: '24px' })}
    ${ResponsivePXValue('width', { mobile: 'auto', tablet: '520px', desktop: '334px' })}
    ${ResponsivePXValue('margin-bottom', { mobile: '12px', tablet: '0', desktop: '0' })}
  }
  
  .registerForm {
    display: flex;
    align-items: center;
    ${ResponsivePXValue('gap', { mobile: '12px', tablet: '16px', desktop: '16px' })}
  }

  .button{
    ${ResponsivePXValue('width', { mobile: 'auto', tablet: '141px', desktop: '141px' })}

  }

  .inputWrapper {
    margin: 0;
  }

  .input {
    ${ResponsivePXValue('height', { mobile: '32px', tablet: '40px', desktop: '40px' })}
    ${ResponsivePXValue('width', { mobile: '100%', tablet: '821px', desktop: '821px' })}

  }
`

export function JoinNowForm(): JSX.Element {

  const form = useForm()

  const { data: customerData } = useCustomerQuery()

  const _handleJoinNow = (): void => {
    ModalPlugin.shared().toggleGlobalModal(true, GlobalModalTypeEnum.SIGN_UP)
  }

  const buttonColors: ButtonColors = {
    color: theme.colors.white.pureWhite,
    backgroundColor: theme.colors.green.deYork,
    hoverColor: theme.colors.green.watercourse,
    hoverBackgroundColor: theme.colors.white.pureWhite,
    disabledColor: theme.colors.green.watercourse,
    disabledBackgrondColor: theme.colors.grey.desertStorm,
    borderColor: theme.colors.green.deYork,
  }

  return (
    <If condition={customerData?.currentCustomer?.customerType === CustomerTypeEnum.GUEST}>
      <JoinNowWrapper>
        <Container>
          <Heading variant='h5' className='heading' color={theme.colors.white.pureWhite}>Join our conscious shopping community</Heading>
          <Form form={form} className='registerForm' onFinish={_handleJoinNow}>
            <TextInput
              wrapperClassName='inputWrapper'
              className='input'
              variant='email'
              showLabel={false}
              name='email'
              placeholder='Enter your email address'
              rules={[{ required: true, message: 'Enter your email address' }]} />

            <DeviceContainer mobile>
              <Button className='button' variant='primary' size='medium' title='SIGN UP' colors={buttonColors} onClick={() => form.submit()} />
            </DeviceContainer>
            <DeviceContainer tablet desktop>
              <Button className='button' variant='primary' size='large' title='SIGN UP' colors={buttonColors} onClick={() => form.submit()} />
            </DeviceContainer>

          </Form>
        </Container>
      </JoinNowWrapper>
    </If>
  )
}
