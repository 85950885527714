import { DocumentNode, FieldFunctionOptions, Reference, TypePolicies, gql } from '@apollo/client'

import { CustomerFragment } from '@hooks/api/index'
import { ConfigPlugin } from '@lib/Config'

export class CustomerPlugin implements ConfigPlugin {

  typePolicies = (): TypePolicies => ({
    Customer: {
      fields: {
        wishlistId: {
          read(obj: CustomerFragment, options: FieldFunctionOptions): number | null {
            const wishlists = options.readField('wishlists') as Reference[]
            if (wishlists && wishlists[0]) {
              const wishlistId = options.readField('id', wishlists[0]) as string
              return parseInt(wishlistId)
            }
            return null
          },
        },
      },
    },
  })

  extensions = (): DocumentNode => gql`
    extend type Customer {
      wishlistId: Int
    }
  `

}
