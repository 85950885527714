
import React from 'react'
import { Svg } from './Svg'

export interface FacebookProps {
  color?: string
  hoverColor?: string
  className?: string
}

export function Facebook({ color, hoverColor, className }: FacebookProps): JSX.Element {

  return (
    <Svg strokeColor={color} strokeHoverColor={hoverColor} fillColor={color} fillHoverColor={hoverColor} className={className} width="100%" height="100%" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M15.5 8.04219C15.5 4.17657 12.3656 1.04219 8.5 1.04219C4.63438 1.04219 1.5 4.17657 1.5 8.04219C1.5 11.5359 4.05938 14.4319 7.40625 14.9575V10.0663H5.62844V8.04219H7.40625V6.5C7.40625 4.74594 8.45156 3.77625 10.0503 3.77625C10.8162 3.77625 11.6175 3.91313 11.6175 3.91313V5.63594H10.7344C9.86531 5.63594 9.59344 6.17532 9.59344 6.72969V8.04219H11.5347L11.2247 10.0663H9.59375V14.9581C12.9406 14.4328 15.5 11.5369 15.5 8.04219Z" className='fill' />
    </Svg>
  )

}
    