import React from 'react'

import { differenceInDays, differenceInWeeks, differenceInMonths, differenceInYears, differenceInMinutes, differenceInHours } from 'date-fns'
import styled, { useTheme } from 'styled-components'

import { GlobalModalTypeEnum, ModalPlugin } from '@api/local/ModalPlugin'
import { Icon, LocalIconEnums, Rating, SmallLoader } from '@atoms/index'
import { Card } from '@atoms/layout/Card'
import { Paragraph } from '@atoms/typography'
import { ResponsivePXValue } from '@components/Theme'
import { useCustomerQuery, ProductReviewFragment, useAddProductReviewVoteMutation } from '@hooks/api/index'
import { useSimpleToasts } from '@simple/toasts'
import { CustomerTypeEnum, ReviewVoteEnum } from '@uctypes/api/globalTypes'

const Container = styled.div`

  ${ResponsivePXValue('margin', '0 0 20px')}

`

const Header = styled.div`
  display: flex;
  align-items: center;
  ${ResponsivePXValue('gap', '5px')}
  ${ResponsivePXValue('margin-bottom', '18px')}

  .verified {
    font-style: italic;
    flex-shrink: 0;
  }
  .percent {
    flex-grow: 1;
    text-align: right;
  }

`

const Content = styled.div`

  .title {
    ${ResponsivePXValue('margin', '18px 0 0 0')}
  }
  .description {
    ${ResponsivePXValue('margin', '8px 0 0 0')}
  }
  
`

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  .reviewer {
    flex-grow: 1;
  }
`

const Helpful = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  width: fit-content;
  ${ResponsivePXValue('gap', '5px')}

  .helpful-text {
    margin: 0;
  }
  .thumbs-up path {
    fill: ${(props): string => props.theme.colors.green.greenVogue} !important;
  }

  &:hover {
    .thumbs-up path {
      fill: ${(props): string => props.theme.colors.blue.curiousBlue} !important;
    }
    .helpful-text {
      color: ${(props): string => props.theme.colors.blue.curiousBlue};
    }
  }
`

const IconContainer = styled.div`
  ${ResponsivePXValue('width', '20px')}
  ${ResponsivePXValue('height', '20px')}
`

export interface ProductReviewProps {
  review: ProductReviewFragment
  productId: number
  didUpdate: () => void
}

export function ProductReview({ review, productId, didUpdate }: ProductReviewProps): JSX.Element {

  const { data: customerData } = useCustomerQuery()
  const [addReviewVote, { loading: addVoteLoading }] = useAddProductReviewVoteMutation()
  const { addToast } = useSimpleToasts()
  const theme = useTheme()

  const _handleHelpful = async (): Promise<void> => {
    if (customerData?.currentCustomer?.customerType === CustomerTypeEnum.GUEST) {
      return ModalPlugin.shared().toggleGlobalModal(true, GlobalModalTypeEnum.LOG_IN)
    }
    try {
      await addReviewVote({
        context: {
          headers: { 'Clear-Cache': `ProductInterface:${productId}` },
        },
        variables: {
          reviewId: review.reviewId,
          vote: ReviewVoteEnum.UP,
        },
      })
      didUpdate()
      addToast({
        message: 'Vote successfully added',
        appearance: 'success',
      })
    } catch (e) {
      if (e instanceof Error) {
        addToast({
          message: e.message,
          appearance: 'error',
        })
      }
    }
  }

  const __getAge = (createdAt: string): string => {
    const now = new Date()
    const then = new Date(createdAt)
    const years = differenceInYears(now, then)
    if (years) {
      return `${years} ${years > 1 ? 'years' : 'year'}`
    }
    const months = differenceInMonths(now, then)
    if (months) {
      return `${months} ${months > 1 ? 'months' : 'month'}`
    }
    const weeks = differenceInWeeks(now, then)
    if (weeks) {
      return `${weeks} ${weeks > 1 ? 'weeks' : 'week'}`
    }
    const days = differenceInDays(now, then)
    if (days) {
      return `${days} ${days > 1 ? 'days' : 'day'}`
    }
    const hours = differenceInHours(now, then)
    if (hours) {
      return `${hours} ${hours > 1 ? 'hours' : 'hour'}`
    }
    const minutes = differenceInMinutes(now, then)
    if (minutes) {
      return `${minutes} ${minutes > 1 ? 'minutes' : 'minute'}`
    }
    return 'just now'
  }

  return (
    <Container>
      <Card>
        <Header>
          <Rating rating={(review.averageRating / 100) * 5} size='large' />
        </Header>
        <Content>
          <Paragraph variant='p2' className='description' color={theme.colors.green.bottleGreen}>{review.text}</Paragraph>
        </Content>
        <Footer>
          <Paragraph variant='p2' className='reviewer' color={theme.colors.grey.silver}>By {review.nickname}, {__getAge(review.createdAt)} ago</Paragraph>
          <Helpful onClick={_handleHelpful}>
            <Paragraph variant='p3' color={theme.colors.grey.stormDust}>({review.votes.upVotes})</Paragraph>
            <IconContainer>
              <Choose>
                <When condition={addVoteLoading}>
                  <SmallLoader />
                </When>
                <Otherwise>
                  <Icon className='thumbs-up' icon={LocalIconEnums.THUMBS_UP} color={customerData?.currentCustomer?.customerType === CustomerTypeEnum.REGISTERED ? theme.colors.green.greenVogue : theme.colors.grey.stormDust} />
                </Otherwise>
              </Choose>
            </IconContainer>
            <Paragraph variant='p3' color={theme.colors.grey.stormDust}>Was helpful</Paragraph>
          </Helpful>
        </Footer>

      </Card>
    </Container>

  )

}
