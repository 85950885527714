import React, { useEffect, useState } from 'react'

import Median from 'median-js-bridge'
import update from 'react-addons-update'

import { Button } from '@atoms/buttons'
import { LocalIconEnums } from '@atoms/index'
import { TokenResponse } from '@react-oauth/google'

interface GoogleLoginButtonProps {
  onError: (errorResponse: Pick<TokenResponse, 'error' | 'error_description' | 'error_uri'>) => void
  onSuccess: (token: string) => void
  forSignUp?: boolean
}

const NATIVE_SOCIAL_READY = {
  nativeLoading: true,
  token: '',
}

export function NativeFacebookLoginButton({ onSuccess, onError, forSignUp = false }: GoogleLoginButtonProps): JSX.Element {
  const [state, setState] = useState(NATIVE_SOCIAL_READY)

  useEffect(() => {
    Median.onReady(() => {
      setState((prevState) => update(prevState, {
        nativeLoading: { $set: false },
      }))
    })
  }, [])

  const _handleNativeFacebookLogin = async (): Promise<void> => {
    Median.socialLogin.facebook.login({
      callback: (response) => {
        if (response.accessToken !== '') {
          if (response.accessToken !== '') {
            onSuccess(response.accessToken)
          } else if (response.error) {
            onError({ error_description: response.error })
          }
        }
      },
    })
  }

  return (
    <Button
      variant='ghost'
      size='medium'
      className='signup-button form-button'
      title={forSignUp ? 'SIGN UP WITH' :'LOGIN WITH'}
      icon={LocalIconEnums.FACEBOOK}
      loading={state.nativeLoading}
      onClick={() => _handleNativeFacebookLogin()} />
  )
}
