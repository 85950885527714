import React, { useEffect, useState } from 'react'

import Convert from 'ansi-to-html'
import { FallbackProps } from 'react-error-boundary'
import { useLocation, useNavigate } from 'react-router'
import StackTracey from 'stacktracey'
import styled from 'styled-components'

import { APP_DEFAULT_STATE, AppPlugin } from '@api/index'
import { Button } from '@atoms/buttons'
import { Heading, Link, Paragraph } from '@atoms/typography'
import { ResponsivePXValue } from '@components/Theme'
import { useGetAppQuery, useGetStoreConfigQuery } from '@hooks/api'
import { DeviceTypeEnum, MobileOSTypeEnum } from '@uctypes/api/globalTypes'

const convert = new Convert({
  newline: true,
  escapeXML: true,
  fg: '#000',
})

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  ${ResponsivePXValue('gap', '16px')}
  ${ResponsivePXValue('margin', { mobile: '16px', tablet: '32px', desktop: '32px' })}
  ${ResponsivePXValue('padding', { mobile: '0 16px' })}
`

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${ResponsivePXValue('gap', '8px')}
  align-items: center;
  justify-content: center;
`

const ErrorMessage = styled.div`
  font-weight: bold;
  ${ResponsivePXValue('margin-bottom', '12px')}
`

const ErrorStack = styled.div`
  ${ResponsivePXValue('margin-bottom', '12px')}
`

export function PageFallbackComponent({ error, resetErrorBoundary }: FallbackProps): JSX.Element {
  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()
  const { data: configData } = useGetStoreConfigQuery()
  const [errorPath, setErrorPath] = useState<string>('')
  const [showDetails, setShowDetails] = useState<boolean>(false)
  const navigate = useNavigate()
  const location = useLocation()

  const stack = new StackTracey((error as Error)).withSources().clean()
  const msg = convert.toHtml((error as Error).message)

  const _handleHomBtn = () => {
    navigate('/', { replace: true })
    navigate(0)
    resetErrorBoundary()
  }

  useEffect(() => {
    if (appData.app.mobileOSType === MobileOSTypeEnum.NOT_NATIVE) {
      if (appData.app.deviceType === DeviceTypeEnum.MOBILE) {
        AppPlugin.shared().showBottomNav()
      } else if (appData.app.deviceType === DeviceTypeEnum.DESKTOP) {
        AppPlugin.shared().showBottomNav()
      }
      return () => {
        AppPlugin.shared().showBottomNav()
      }
    }
  }, [appData.app.deviceType])

  useEffect(() => {
    if (!errorPath) {
      setErrorPath(location.pathname)
    } else if (location.pathname !== errorPath) {
      resetErrorBoundary()
    }
  }, [location.pathname])

  return (
    <Container>
      <Heading variant='h1' align='center'>Oh no something went wrong...</Heading>
      <Paragraph variant='p1' align='center'>
        please give our support team a call on <Link href="tel:0217853268">021 785 3268</Link> or pop us an email: <Link href={`mailto:${configData?.storeConfig?.transEmailIdentSupportEmail}`}>{configData?.storeConfig?.transEmailIdentSupportEmail}</Link>
      </Paragraph>
      <DetailsContainer>
        <Choose>
          <When condition={showDetails}>
            <ErrorMessage style={{ whiteSpace: 'break-spaces' }} dangerouslySetInnerHTML={{ __html: msg }} />
            <ErrorStack>
              {stack.items.map((e, i) => {
                return (
                  <div key={i}>{e?.beforeParse}</div>
                )
              })}
            </ErrorStack>

            <Button size='small' title='HIDE DETAILS' onClick={() => setShowDetails(false)} />
          </When>
          <Otherwise>
            <Button size='small' title='SHOW DETAILS' onClick={() => setShowDetails(true)} />
          </Otherwise>
        </Choose>
        <Button size='small' title='HOME' onClick={_handleHomBtn} />
      </DetailsContainer>
    </Container>
  )
}
