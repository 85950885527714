import { DocumentNode, FieldPolicy, gql, makeVar } from '@apollo/client'

import Cookies from 'js-cookie'
import update from 'react-addons-update'
import { v4 } from 'uuid'

import { theme } from '@components/Theme'
import { AppFragment } from '@hooks/api/index'
import { ConfigPlugin } from '@lib/Config'
import { DeviceTypeEnum, MobileOSTypeEnum } from '@uctypes/api/globalTypes'

const CATEGORY_COOKIE_NAME = 'ftn-base-category'
const STORE_COOKIE_NAME = 'ftn-store'

export const APP_DEFAULT_STATE: AppFragment = {
  id: v4(),
  deviceType: DeviceTypeEnum.MOBILE,
  showHeaderContent: true,
  showBottomNav: true,
  showFooter: true,
  showSearch: true,
  baseCategoryId: null,
  storeId: null,
  isNativeApp: false,
  mobileOSType: MobileOSTypeEnum.NOT_NATIVE,
  magentoIsInMaintenace: false,
  __typename: 'App',
}

const isBrowser = (): boolean => {
  return (typeof window !== 'undefined')
}

const _data = makeVar<AppFragment>({ ...APP_DEFAULT_STATE })

export class AppPlugin implements ConfigPlugin {

  static instance: AppPlugin

  static shared(): AppPlugin {
    if (!this.instance) {
      this.instance = new AppPlugin()
    }
    return this.instance
  }

  setMaintenanceMode(magentoIsInMaintenace: boolean) {
    _data(update(_data(), {
      magentoIsInMaintenace: {
        $set: magentoIsInMaintenace,
      },
    }))
  }

  isApp(): boolean {
    let isApp = false
    if (isBrowser()) {
      isApp = window?.navigator?.userAgent?.includes('mobileApp')
    }
    return isApp
  }

  isIOSApp(): boolean {
    let isApp = false
    if (isBrowser()) {
      isApp = window?.navigator?.userAgent?.includes('mobileAppIOS')
    }
    return isApp
  }

  isAndroidApp(): boolean {
    let isApp = false
    if (isBrowser()) {
      isApp = window?.navigator?.userAgent?.includes('mobileAppAndroid')
    }
    return isApp
  }

  getIsNativeApp(): boolean {
    return _data().isNativeApp
  }

  setIsNativeApp(isNativeApp: boolean): void {
    _data(update(_data(), {
      isNativeApp: {
        $set: isNativeApp,
      },
    }))
  }

  getMobileOSType(): MobileOSTypeEnum {
    return _data().mobileOSType
  }

  setMobileOSType(mobileOSType: MobileOSTypeEnum): void {
    _data(update(_data(), {
      mobileOSType: {
        $set: mobileOSType,
      },
    }))
  }

  getBaseCategoryId(): string {
    return _data().baseCategoryId
  }

  getStoreId(): number {
    return _data().storeId
  }

  setBaseCategoryId(baseCategoryId: string): void {
    const cookieBaseCategoryId = this.getBaseCategoryIdFromLocalStorage()
    if (cookieBaseCategoryId !== baseCategoryId) {
      this.setBaseCategoryIdOnLocalStorage(baseCategoryId)
    }
    if (_data().baseCategoryId !== baseCategoryId) {
      this.setBaseCategoryIdOnReactiveVar(baseCategoryId)
    }
  }

  setStoreId(storeId: number): void {
    const cookieStoreId = this.getStoreIdFromLocalStorage()
    if (cookieStoreId !== storeId) {
      this.setStoreIdOnLocalStorage(storeId)
    }
    if (_data().storeId !== storeId) {
      this.setStoreIdOnReactiveVar(storeId)
    }
  }

  getBaseCategoryIdFromLocalStorage(): string | null {
    return Cookies.get(CATEGORY_COOKIE_NAME) || null
  }

  getStoreIdFromLocalStorage(): number | null {
    const value = Cookies.get(STORE_COOKIE_NAME)
    return value ? parseInt(value) : null
  }

  setBaseCategoryIdOnLocalStorage(baseCategoryId: string): void {
    Cookies.set(CATEGORY_COOKIE_NAME, baseCategoryId)
  }

  setStoreIdOnLocalStorage(storeId: number): void {
    Cookies.set(STORE_COOKIE_NAME, storeId + '')
  }

  clearBaseCategoryIdOnLocalStorage(): void {
    Cookies.remove(CATEGORY_COOKIE_NAME)
  }

  clearStoreIdOnLocalStorage(): void {
    Cookies.remove(STORE_COOKIE_NAME)
  }

  getBaseCategoryIdFromReactiveVar(): string | null {
    return _data().baseCategoryId || null
  }

  getStoreIdFromReactiveVar(): number | null {
    return _data().storeId || null
  }

  setBaseCategoryIdOnReactiveVar(baseCategoryId: string): void {
    _data(update(_data(), {
      baseCategoryId: {
        $set: baseCategoryId,
      },
    }))
  }

  setStoreIdOnReactiveVar(storeId: number): void {
    _data(update(_data(), {
      storeId: {
        $set: storeId,
      },
    }))
  }

  setDeviceType(deviceType: DeviceTypeEnum): void {
    _data(update(_data(), {
      deviceType: {
        $set: deviceType,
      },
    }))
  }

  showHeaderContent(): void {
    _data(update(_data(), {
      showHeaderContent: {
        $set: true,
      },
    }))
  }

  hideHeaderContent(): void {
    _data(update(_data(), {
      showHeaderContent: {
        $set: false,
      },
    }))
  }

  showBottomNav(): void {
    _data(update(_data(), {
      showBottomNav: {
        $set: true,
      },
    }))
  }

  hideBottomNav(): void {
    _data(update(_data(), {
      showBottomNav: {
        $set: false,
      },
    }))
  }

  showFooter(): void {
    _data(update(_data(), {
      showFooter: {
        $set: true,
      },
    }))
  }

  hideFooter(): void {
    _data(update(_data(), {
      showFooter: {
        $set: false,
      },
    }))
  }

  showSearchBar(): void {
    _data(update(_data(), {
      showSearch: {
        $set: true,
      },
    }))
  }

  hideSearchBar(): void {
    _data(update(_data(), {
      showSearch: {
        $set: false,
      },
    }))
  }

  async configure(): Promise<void> {
    const baseCategoryId = this.getBaseCategoryIdFromLocalStorage()
    if (baseCategoryId) {
      this.setBaseCategoryIdOnReactiveVar(baseCategoryId)
    }
    let deviceType = DeviceTypeEnum.MOBILE
    if (theme.isUltra()) {
      deviceType = DeviceTypeEnum.ULTRA
    } else if (theme.isDesktop()) {
      deviceType = DeviceTypeEnum.DESKTOP
    } else if (theme.isTablet()) {
      deviceType = DeviceTypeEnum.TABLET
    }
    if (this.isIOSApp()) {
      this.setMobileOSType(MobileOSTypeEnum.APPLE)
      this.setIsNativeApp(true)
    } else if (this.isAndroidApp()) {
      this.setMobileOSType(MobileOSTypeEnum.ANDROID)
      this.setIsNativeApp(true)
    }
    this.setDeviceType(deviceType)
  }

  fieldPolicies = (): { [k: string]: FieldPolicy } => ({
    app: {
      read(): AppFragment {
        let data = _data()
        if (typeof window !== 'undefined' && window.forcedRenderingDevice) {
          data = {
            ...data,
            deviceType: window.forcedRenderingDevice,
          }
        }
        return data as AppFragment
      },
    },
  })

  types = (): DocumentNode => gql`
    enum DeviceTypeEnum {
      "Mobile"
      MOBILE
      "Tablet"
      TABLET
      "Desktop"
      DESKTOP
      "Ultra"
      ULTRA
    }
    enum MobileOSTypeEnum {
      "Apple"
      APPLE
      "Android"
      ANDROID
      "Not Mobile"
      NOT_NATIVE
    }
    type App {
      id: ID!
      deviceType: DeviceTypeEnum!
      showHeaderContent: Boolean!
      showBottomNav: Boolean!
      showFooter: Boolean!
      showSearch: Boolean!
      baseCategoryId: String
      storeId: Int
      isNativeApp: Boolean!
      mobileOSType: MobileOSTypeEnum!
      magentoIsInMaintenace: Boolean!
    }
  `

  extensions = (): DocumentNode => gql`
    extend type Query {
      app: App!
    }
  `

  queries = (): DocumentNode => gql`
    fragment AppFragment on App {
      id
      deviceType
      showHeaderContent
      showBottomNav
      showFooter
      showSearch
      baseCategoryId
      storeId
      isNativeApp
      mobileOSType
      magentoIsInMaintenace
    }
    query GetApp {
      app @client {
        ... AppFragment
      }
    }
  `

}
