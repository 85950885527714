import React from 'react'

import styled, { useTheme } from 'styled-components'

import { Icon, LocalIconEnums } from '@atoms/images'
import { Paragraph, Title } from '@atoms/typography'
import { ResponsivePXValue } from '@components/Theme'
import { PaymentMethodFragment } from '@hooks/api/index'

const Container = styled.div<{ active: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  overflow-x: hidden;
  background-color: ${(props): string => props.active ? props.theme.colors.white.pampas : props.theme.colors.white.pureWhite};
  ${ResponsivePXValue('padding', { mobile: '24px', tablet: '24px', desktop: '24px' })}
  ${ResponsivePXValue('border-radius', { mobile: '0', tablet: '10px', desktop: '10px' })}
  ${ResponsivePXValue('gap', '8px')}
`

const CircleContainer = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  ${ResponsivePXValue('width', { mobile: '30px', tablet: '60px', desktop: '60px' })}
`

const Circle = styled.div<{ active: boolean }>`
  border-style: solid;
  cursor: pointer;
  ${ResponsivePXValue('width', { mobile: '16px', tablet: '20px', desktop: '20px' })}
  ${ResponsivePXValue('height', { mobile: '16px', tablet: '20px', desktop: '20px' })}
  ${ResponsivePXValue('border-radius', { mobile: '10px', tablet: '13px', desktop: '13px' })}
  ${(props): string => {
    if (props.active) {
      return `
        ${ResponsivePXValue('border-width', { mobile: '4px', tablet: '5px', desktop: '5px' })}
        border-color: ${props.theme.colors.green.greenVogue};
      `
    }
    return `
    ${ResponsivePXValue('border-width', { mobile: '1px', tablet: '2px', desktop: '2px' })}
    border-color: ${props.theme.colors.grey.silver};
  `
  }}
`

const ContentContainer = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: center;
  ${ResponsivePXValue('align-items', { mobile: 'flex-start', tablet: 'center', desktop: 'center' })}
  ${ResponsivePXValue('flex-direction', { mobile: 'column', tablet: 'row', desktop: 'row' })}
`

const InformationContainer = styled.div`
  flex-grow: 1;
  align-items: center;
  justify-content: center;
`

const LogosContainer = styled.div`
  display: flex;
  width: fit-content;
  height: 100%;
  align-items: center;
  ${ResponsivePXValue('gap', '8px')}
  ${ResponsivePXValue('margin-top', { mobile: '8px' })}
  .large-logo {
    ${ResponsivePXValue('height', '25px')};
  }
`

const LogoContainer = styled.div`
  ${ResponsivePXValue('height', '10px')};
  svg {
    width: auto;
  }
`

export interface PaymentMethodRowProps {
  paymentMethod: PaymentMethodFragment
  selected: boolean
  onSelect: () => void
}

export function PaymentMethodRow({ selected, paymentMethod, onSelect }: PaymentMethodRowProps): JSX.Element {

  const { colors } = useTheme()

  const paymentLogos = {
    creditCard: [{
      large: false,
      logo: LocalIconEnums.PAYMENT_VISA_COLOR,
    },
    {
      large: true,
      logo: LocalIconEnums.PAYMENT_MASTER_CARD_COLOR,
    },
    {
      large: true,
      logo: LocalIconEnums.PAYMENT_AMERICAN_EXPRESS_COLOR,
    }],
    ozow: [{
      large: true,
      logo: LocalIconEnums.OZOW,
    }],
    bankTransfer: [{
      large: true,
      logo: LocalIconEnums.PAYMENT_EFT_COLOR,
    }],
  }

  let index: number

  return (
    <Container active={selected}>
      <If condition={!!onSelect}>
        <CircleContainer onClick={onSelect} >
          <Circle active={selected} />
        </CircleContainer>
      </If>
      <ContentContainer onClick={onSelect}>
        <InformationContainer>
          <Title variant='t8'>
            {paymentMethod.title}
          </Title>
          <Paragraph color={colors.grey.stormDust}>
            <If condition={paymentMethod.id === 'peachpayments_s2s'}>
            Pay with a Visa, Mastercard or American Express credit or debit card.
            </If>
            <If condition={paymentMethod.id === 'ozow'}>
            Pay instantly using Ozow Instant EFT
            </If>
            <If condition={paymentMethod.id === 'banktransfer'}>
            Pay using bank transfer
            </If>
          </Paragraph>
        </InformationContainer>
        <LogosContainer>
          <If condition={paymentMethod.id === 'peachpayments_s2s'}>
            <For each='logo' index='index' of={paymentLogos.creditCard}>
              <LogoContainer key={index} className={paymentLogos.creditCard[index].large ? 'large-logo' : ''}>
                <Icon icon={paymentLogos.creditCard[index].logo} color={selected ? undefined : colors.grey.gunSmoke } />
              </LogoContainer>
            </For>
          </If>

          <If condition={paymentMethod.id === 'ozow'}>
            <For each='logo' index='index' of={paymentLogos.ozow}>
              <LogoContainer key={index} className={paymentLogos.ozow[index].large ? 'large-logo' : ''}>
                <Icon icon={paymentLogos.ozow[index].logo} color={selected ? undefined : colors.grey.gunSmoke } />
              </LogoContainer>
            </For>
          </If>

          <If condition={paymentMethod.id === 'banktransfer'}>
            <For each='logo' index='index' of={paymentLogos.bankTransfer}>
              <LogoContainer key={index} className={paymentLogos.bankTransfer[index].large ? 'large-logo' : ''}>
                <Icon icon={paymentLogos.bankTransfer[index].logo} color={selected ? undefined : colors.grey.gunSmoke } />
              </LogoContainer>
            </For>
          </If>
        </LogosContainer>
      </ContentContainer>
    </Container>
  )

}
