
import React from 'react'
import { Svg } from './Svg'

export interface HeartFilledProps {
  color?: string
  hoverColor?: string
  className?: string
}

export function HeartFilled({ color, hoverColor, className }: HeartFilledProps): JSX.Element {

  return (
    <Svg strokeColor={color} strokeHoverColor={hoverColor} fillColor={color} fillHoverColor={hoverColor} className={className} width="100%" height="100%" viewBox="0 0 20 19" version="1.1">
      <g transform="matrix(0.898976,0,0,0.898976,-0.787711,-1.68771)">
        <path className='fill' d="M18.717,3.539C17.412,2.892 15.913,2.828 14.478,3.345C13.435,3.733 12.587,4.38 11.935,5.22C11.283,4.38 10.435,3.733 9.392,3.345C8.087,2.828 6.588,2.892 5.218,3.539C3.784,4.186 2.74,5.35 2.284,6.837C1.893,8.066 1.697,10.006 3.132,12.528C4.631,15.18 6.848,17.184 11.544,20.871C11.674,20.935 11.804,21 12,21C12.13,21 12.326,20.935 12.457,20.871C17.217,17.12 19.434,15.18 20.869,12.528C22.303,10.006 22.107,8.001 21.716,6.837C21.195,5.35 20.151,4.186 18.717,3.539Z" />
      </g>
    </Svg>
  )

}
    