
import React from 'react'
import { Svg } from './Svg'

export interface SmallCrossProps {
  color?: string
  hoverColor?: string
  className?: string
}

export function SmallCross({ color, hoverColor, className }: SmallCrossProps): JSX.Element {

  return (
    <Svg strokeColor={color} strokeHoverColor={hoverColor} fillColor={color} fillHoverColor={hoverColor} className={className} width="100%" height="100%" viewBox="0 0 20 20" version="1.1">
      <g transform="matrix(1.50331,0,0,1.50331,0.980144,0.980144)">
        <path className='stroke' d="M11,11L1,1M11,1L1,11" />
      </g>
    </Svg>
  )

}
    